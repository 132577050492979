<!--/src/components/elements/ExternalSoftwareLinksMenu.vue-->
<template>
  <v-menu v-model="showMenu" offset-y>
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        v-if="showIcon() === ShowIcon.erpOneCustomer"
        @click.stop="openMenu"
      >
        <img
          :src="getAssetIconPath('icon-erp.svg')"
          class="zvoove-one-icon"
          alt="ERP Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Unternehmen in ERP One öffnen
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="showIcon() === ShowIcon.pdHubCustomer"
        @click.stop="openMenu"
      >
        <img
          :src="getAssetIconPath('icon-pd-hub.svg')"
          class="pd-hub-icon"
          alt="PD-Hub Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Optionen in PD-Hub
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="showIcon() === ShowIcon.erpEmployee"
        @click.stop="openMenu"
      >
        <img
          :src="getAssetIconPath('icon-erp.svg')"
          class="zvoove-one-icon"
          alt="zvoove One Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Mitarbeiter in ERP One öffnen
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="showIcon() === ShowIcon.pdHubEmployee"
        @click.stop="openMenu"
      >
        <img
          :src="getAssetIconPath('icon-pd-hub.svg')"
          class="pd-hub-icon"
          alt="PD-Hub Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Optionen in PD-Hub
        </v-tooltip>
      </div>
      <div
        v-bind="props"
        v-if="showIcon() === ShowIcon.atsRecruit"
        @click.stop="openMenu"
      >
        <img
          :src="getAssetIconPath('icon-ats.svg')"
          class="ats-icon"
          alt="ATS Icon"
        />
        <v-tooltip activator="parent" location="bottom">
          Kandidat in ATS Recruit öffnen
        </v-tooltip>
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";
import { getAssetIconPath } from "../../../helper/get-assets.helper";
import { IdPrefix } from "../../../enums/id-prefix.enum";
interface MenuItem {
  title: string;
  action: () => void;
}

enum ExternalSoftwareLinksMenuEmit {
  getRecruitData = "getRecruitData",
  getErpData = "getErpData",
  putErpData = "putErpData",
}
enum ShowIcon {
  erpOneCustomer = "erpOneCustomer",
  pdHubCustomer = "pdHubCustomer",
  erpEmployee = "erpEmployee",
  pdHubEmployee = "pdHubEmployee",
  atsRecruit = "atsRecruit",
}

export default defineComponent({
  emits: [
    ExternalSoftwareLinksMenuEmit.getRecruitData,
    ExternalSoftwareLinksMenuEmit.getErpData,
    ExternalSoftwareLinksMenuEmit.putErpData,
  ],
  props: {
    erpCustomerNumber: String as PropType<string>,
    erpEmployeeNumber: String as PropType<string>,
    atsRecruitUuid: String as PropType<string>,
    atsRecruitCurrentApplicationUuid: String as PropType<string>,
    hideUpdateMenuPoint: Boolean as PropType<boolean>,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      ExternalSoftwareLinksMenuEmit: ExternalSoftwareLinksMenuEmit,
      IdPrefix: IdPrefix,
      ShowIcon: ShowIcon,
      showMenu: false,
      menuItems: [] as MenuItem[],
      //helper methods
      getAssetIconPath,
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    getMenuItems() {
      let items = [];
      if (
        this.erpCustomerNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.putErpData),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.getErpData),
        });
      } else if (
        this.erpCustomerNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.putErpData),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.getErpData),
        });
      } else if (
        this.erpEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in ERP laden...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.putErpData),
        });
        items.push({
          title: "▼ Daten aus ERP aktualisieren...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.getErpData),
        });
      } else if (
        this.erpEmployeeNumber &&
        this.company.softwareIntegration.pdHub &&
        !this.hideUpdateMenuPoint
      ) {
        items.push({
          title: "▲ Daten in PD-Hub laden...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.putErpData),
        });
        items.push({
          title: "▼ Daten aus PD-Hub aktualisieren...",
          action: () => this.$emit(ExternalSoftwareLinksMenuEmit.getErpData),
        });
      } else if (this.atsRecruitUuid && !this.hideUpdateMenuPoint) {
        items.push({
          title: "▼ Daten aus ATS aktualisieren...",
          action: () =>
            this.$emit(ExternalSoftwareLinksMenuEmit.getRecruitData),
        });
      }

      if (
        this.erpCustomerNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Ansprechpartner",
          action: () => this.openExternalLink("ansprechpartner"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Aufträge",
          action: () => this.openExternalLink("auftraege"),
        });
        items.push({
          title: "Einsätze",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Verbundene Entleiher",
          action: () => this.openExternalLink("verbundeneentleiher"),
        });
        items.push({
          title: "Belege",
          action: () => this.openExternalLink("belege"),
        });
      } else if (
        this.erpEmployeeNumber &&
        this.company.softwareIntegration.zvooveOne
      ) {
        items.push({
          title: "Übersicht",
          action: () => this.openExternalLink("uebersicht"),
        });
        items.push({
          title: "Abrechnung",
          action: () => this.openExternalLink("abrechnung"),
        });
        items.push({
          title: "Lohnbuchhaltung",
          action: () => this.openExternalLink("lohnbuchhaltung"),
        });
        items.push({
          title: "Berufsdetails",
          action: () => this.openExternalLink("berufsdetails"),
        });
        items.push({
          title: "Einsätze/Fehlzeiten",
          action: () => this.openExternalLink("ueberlassungen"),
        });
        items.push({
          title: "Aktivitäten",
          action: () => this.openExternalLink("aktivitaeten"),
        });
        items.push({
          title: "Tätigkeiten Vorarbeitgeber",
          action: () => this.openExternalLink("taetigkeitenvorarbeitgeber"),
        });
        items.push({
          title: "Arbeitszeiten",
          action: () => this.openExternalLink("arbeitszeiten"),
        });
        items.push({
          title: "Vorsorgeuntersuchungen",
          action: () => this.openExternalLink("vorsorgeuntersuchungen"),
        });
        items.push({
          title: "Sanktionsprüfungen",
          action: () => this.openExternalLink("sanktionspruefungen"),
        });
      } else if (this.atsRecruitUuid) {
        items.push({
          title: "Historie",
          action: () => this.openExternalLink("dashboard"),
        });
        items.push({
          title: "Stammdaten",
          action: () => this.openExternalLink("stammdaten"),
        });
        items.push({
          title: "Werdegang",
          action: () => this.openExternalLink("vita"),
        });
        items.push({
          title: "Anhänge",
          action: () => this.openExternalLink("anhaenge"),
        });
        items.push({
          title: "Skills",
          action: () => this.openExternalLink("perfectmatch/skills"),
        });
        items.push({
          title: "Exposé",
          action: () => this.openExternalLink("profil"),
        });
      }
      return items;
    },
    isNumeric(value: string | null | undefined): boolean {
      return /^\d+$/.test(value ?? "");
    },
    openExternalLink(target: string) {
      let externalLink = "";
      if (this.erpCustomerNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/unternehmen/${this.erpCustomerNumber}/${target}`;
      } else if (this.erpEmployeeNumber) {
        externalLink = `${this.company.softwareIntegration.zvooveOneLink}/personal/${this.erpEmployeeNumber}/${target}`;
      } else if (this.atsRecruitUuid) {
        externalLink = `${this.company.softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/${this.atsRecruitUuid}/${target}?bewerbung=${this.atsRecruitCurrentApplicationUuid}`;
      }
      window.open(externalLink, "_blank", PopUpWindowSize.externalSoftware);
    },
    openMenu() {
      this.showMenu = true;
      this.menuItems = this.getMenuItems();
    },
    showIcon() {
      const integrationCase = {
        erpOneCustomer:
          this.erpCustomerNumber &&
          this.isNumeric(this.erpCustomerNumber) &&
          !this.erpCustomerNumber.startsWith(IdPrefix.google) &&
          !this.erpCustomerNumber.startsWith(IdPrefix.indexAnzeigendaten) &&
          this.company.softwareIntegration.zvooveOne,
        erpEmployee:
          this.erpEmployeeNumber &&
          this.isNumeric(this.erpEmployeeNumber) &&
          !this.erpEmployeeNumber.startsWith(IdPrefix.dispositioner) &&
          this.company.softwareIntegration.zvooveOne,
        pdHubCustomer:
          this.erpCustomerNumber &&
          this.isNumeric(this.erpCustomerNumber) &&
          !this.erpCustomerNumber.startsWith(IdPrefix.google) &&
          !this.erpCustomerNumber.startsWith(IdPrefix.indexAnzeigendaten) &&
          this.company.softwareIntegration.pdHub,
        pdHubEmployee:
          this.erpEmployeeNumber &&
          this.isNumeric(this.erpEmployeeNumber) &&
          !this.erpEmployeeNumber.startsWith(IdPrefix.dispositioner) &&
          this.company.softwareIntegration.pdHub,
        atsRecruit:
          this.atsRecruitUuid &&
          !this.atsRecruitUuid.startsWith(IdPrefix.dispositioner) &&
          this.company.softwareIntegration.zvooveRecruit,
      };

      switch (true) {
        case integrationCase.erpOneCustomer:
          return ShowIcon.erpOneCustomer;
        case integrationCase.pdHubCustomer:
          return ShowIcon.pdHubCustomer;
        case integrationCase.erpEmployee:
          return ShowIcon.erpEmployee;
        case integrationCase.pdHubEmployee:
          return ShowIcon.pdHubEmployee;
        case integrationCase.atsRecruit:
          return ShowIcon.atsRecruit;
        default:
          return null;
      }
    },
  },
});
</script>
<style scoped>
.zvoove-one-icon,
.pd-hub-icon,
.ats-icon {
  margin-left: var(--dispatcher-board-communication-icon-margin-left);
  margin-right: var(--dispatcher-board-communication-icon-margin-right);
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
.zvoove-one-icon:hover,
.pd-hub-icon:hover,
.ats-icon:hover {
  scale: var(--medium-scale-up);
}
</style>
