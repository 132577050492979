import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "drag-over-text mb-3" }
const _hoisted_3 = {
  key: 2,
  class: "d-flex ma-0"
}
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "item-collapsed" }
const _hoisted_7 = {
  key: 3,
  class: "hide-on-inactive-column"
}
const _hoisted_8 = {
  key: 5,
  class: "bottom-right-icon"
}
const _hoisted_9 = {
  key: 7,
  class: "communication-icons-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_TopRightButton = _resolveComponent("TopRightButton")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "ma-0 pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_hover, null, {
        default: _withCtx(({ isHovering, props }) => [
          (_ctx.isFullyCollapsed)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onDblclick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDoubleClickOnItem && _ctx.handleDoubleClickOnItem(...args))),
                onDragstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDragStart && _ctx.handleDragStart(...args))),
                onDrop: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args))),
                onDragover: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
                onContextmenu: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleContextMenu && _ctx.handleContextMenu(...args)), ["prevent"])),
                onDragend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleDragEnd && _ctx.handleDragEnd(...args))),
                draggable: "true"
              }, [
                _renderSlot(_ctx.$slots, "collapsed-view", {}, undefined, true)
              ], 32))
            : _createCommentVNode("", true),
          (!_ctx.isFullyCollapsed)
            ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({ key: 1 }, { ..._ctx.vStyle?.boardItem, ...props }, {
                color: isHovering ? _ctx.hoverColor : _ctx.color,
                class: {
            expanded: _ctx.isExpanded,
            'fully-expanded': _ctx.isFullyExpanded,
            'item pl-5 pr-2': _ctx.isDialog,
            'item pa-2 mb-2': !_ctx.isDialog,
          },
                "max-height": _ctx.getItemHeight(),
                onDblclick: _ctx.handleDoubleClickOnItem,
                onDragstart: _ctx.handleDragStart,
                onDrop: _ctx.handleDrop,
                onDragover: _ctx.handleDragOver,
                onDragleave: _ctx.handleDragLeave,
                onContextmenu: _withModifiers(_ctx.handleContextMenu, ["prevent"]),
                onDragend: _ctx.handleDragEnd,
                draggable: "true",
                onMousedown: _cache[8] || (_cache[8] = ($event: any) => ($event.currentTarget.style.cursor = 'grabbing')),
                onMouseup: _cache[9] || (_cache[9] = ($event: any) => ($event.currentTarget.style.cursor = 'grab'))
              }), {
                default: _withCtx(() => [
                  (_ctx.isDraggingOver)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", {
                          style: _normalizeStyle({ height: _ctx.vStyle.itemHeight.collapsed }),
                          class: "d-flex align-center"
                        }, [
                          (_ctx.dragOverIcon)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                class: "drag-over-icon ml-1 mb-3",
                                size: "2rem"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.dragOverIcon), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dragOverText), 1)
                        ], 4),
                        _createVNode(_component_v_divider, { class: "mb-4" })
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.isDialog)
                    ? (_openBlock(), _createBlock(_component_TopRightButton, {
                        key: 1,
                        isExpanded: _ctx.isExpanded,
                        isFullyExpanded: _ctx.isFullyExpanded,
                        parentRole: _ctx.parentRole,
                        onToggleExpansion: _ctx.toggleExpansion,
                        onToggleFullExpansion: _ctx.toggleFullExpansion,
                        onOpenContextMenu: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit(_ctx.GeneralItemEmit.openContextMenu, $event)))
                      }, null, 8, ["isExpanded", "isFullyExpanded", "parentRole", "onToggleExpansion", "onToggleFullExpansion"]))
                    : _createCommentVNode("", true),
                  (_ctx.isDialog)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit(_ctx.GeneralItemEmit.closeDialog))),
                          icon: "",
                          size: "s",
                          variant: "text",
                          density: "compact"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode("fa-solid fa-xmark")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.showMatchIcon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.getAssetIconPath('match-icon.svg'),
                          alt: "Match Icon",
                          class: "match-icon"
                        }, null, 8, _hoisted_5))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "item-collapsed", {}, undefined, true)
                    ])
                  ]),
                  (_ctx.isActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _renderSlot(_ctx.$slots, "hide-on-inactive-column", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.statusColor)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        class: _normalizeClass(["status", { blinking: _ctx.statusBlinking }]),
                        style: _normalizeStyle({ backgroundColor: _ctx.statusColor })
                      }, null, 6))
                    : _createCommentVNode("", true),
                  (!_ctx.isExpanded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _renderSlot(_ctx.$slots, "bottom-right-icon", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded)
                    ? (_openBlock(), _createBlock(_component_v_divider, {
                        key: 6,
                        class: "my-2"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _renderSlot(_ctx.$slots, "communication-icons-container", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? (_openBlock(), _createBlock(_component_v_divider, {
                        key: 8,
                        class: "my-2"
                      }))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "checklist-container", {}, undefined, true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? _renderSlot(_ctx.$slots, "tags-container", { key: 9 }, undefined, true)
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "dependencies-container", {}, undefined, true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? _renderSlot(_ctx.$slots, "info-container", { key: 10 }, undefined, true)
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? _renderSlot(_ctx.$slots, "payflow-container", { key: 11 }, undefined, true)
                    : _createCommentVNode("", true),
                  (_ctx.isExpanded || _ctx.isAllSlotsActive)
                    ? _renderSlot(_ctx.$slots, "timeline-container", { key: 12 }, undefined, true)
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["color", "class", "max-height", "onDblclick", "onDragstart", "onDrop", "onDragover", "onDragleave", "onContextmenu", "onDragend"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "append-container", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}