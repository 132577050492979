<!-- App.vue -->
<template>
  <div id="app">
    <div v-if="isLoggedIn">
      <HeartbeatService />
      <TopHeader
        v-if="showTopHeader"
        :isLoggedIn="isLoggedIn"
        :showVersion="showVersion()"
      ></TopHeader>
      <JobPoster />
      <RecurionMenu v-if="isRecurionChatActive" />
      <GameBar v-if="showGameBar" />
    </div>
    <router-view />
    <!-- Spinner Overlay -->
    <div v-if="isSpinnerVisible" class="spinner-overlay">
      <SpinnerGame></SpinnerGame>
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>
    <!-- Dialog Container -->
    <DialogContainer />
  </div>
</template>

<script lang="ts">
import "@fortawesome/fontawesome-free/css/all.css";
import { AppLayout, AppLayoutName } from "./enums/app-layout.enum";
import { defineComponent, ref } from "vue";
import { EventBusEvent, EventBusService } from "./services/event-bus.service";
import { mapGetters, mapState } from "vuex";
import { onBeforeMount } from "vue";
import { onMounted, onUnmounted } from "vue";
import { SpinnerEvent } from "./enums/spinner-events.enum";
import { updateVuetify } from "./main";
import { UserHelperService } from "./services/user-helper.service";
import DialogContainer from "@/components/dialog/DialogContainer.vue";
import GameBar from "@/components/slide-in-menus/Gamebar.vue";
import HeartbeatService from "@/helper/HeartbeatService.vue";
import JobPoster from "@/components/slide-in-menus/JobPosterMenu.vue";
import RecurionMenu from "@/components/slide-in-menus/RecurionMenu.vue";
import router from "@/router";
import SpinnerGame from "./components/spinner/SpinnerGame.vue";
import TopHeader from "@/components/header/TopHeader.vue";
import { appTitle } from "./helper/app-title.helper";

export default defineComponent({
  components: {
    TopHeader,
    HeartbeatService,
    JobPoster,
    SpinnerGame,
    RecurionMenu,
    GameBar,
    DialogContainer,
  },
  computed: {
    ...mapState({
      showGameBar: (state: any) => !state.perfState.isOffline,
    }),
    ...mapGetters({
      appLayout: "appLayout",
      getEnv: "getEnv",
      user: "user",
      isRecurionChatActive: "recurionChatIsActive",
    }),
    showTopHeader() {
      return (
        this.appLayout === AppLayout.classic ||
        this.appLayout === AppLayout.persontobusiness
      );
    },
  },
  data() {
    return {
      AppLayout: AppLayout,
    };
  },
  watch: {
    appLayout(newValue, oldValue) {
      if (oldValue !== undefined) {
        console.debug(`App layout changed to '${newValue}' - Updating theme`);
        updateVuetify(this.$store.getters.appLayout);
        this.setAppLayoutInLocalStorage(newValue);
      }
    },
  },
  mounted() {
    document.title = appTitle(); // Set global title
    this.setFavicon(); // Set the favicon
  },
  setup() {
    const isSpinnerVisible = ref(false);
    const isLoggedIn = ref(false);
    const userHelperService = UserHelperService.getInstance();
    const eventBusService = EventBusService.getInstance();

    const handleShowSpinner = () => {
      isSpinnerVisible.value = true;
    };

    const handleHideSpinner = () => {
      isSpinnerVisible.value = false;
    };

    const updateUser = (newValue: boolean) => {
      isLoggedIn.value = newValue;
    };

    const initEventBus = () => {
      userHelperService.updateIsLoggedIn(); // update logged in status of user
      router.push("/");
    };

    onBeforeMount(() => {
      userHelperService.onChange(updateUser);
      isLoggedIn.value = userHelperService.isLoggedIn;
      eventBusService.setEventCallback(
        EventBusEvent.userLoggedOut,
        initEventBus
      );
      eventBusService.notify(EventBusEvent.userLoggedOut);
    });

    onMounted(() => {
      document.addEventListener(SpinnerEvent.show, handleShowSpinner);
      document.addEventListener(SpinnerEvent.hide, handleHideSpinner);
    });

    onUnmounted(() => {
      userHelperService.removeFromListener(updateUser);
      document.removeEventListener(SpinnerEvent.show, handleShowSpinner);
      document.removeEventListener(SpinnerEvent.hide, handleHideSpinner);
    });

    return {
      isLoggedIn,
      isSpinnerVisible,
      userHelperService,
      eventBusService,
    };
  },
  methods: {
    showVersion(): boolean {
      return this.$store.getters.getEnv.NODE_ENV !== "production";
    },
    setAppLayoutInLocalStorage(appLayout: AppLayout) {
      localStorage.setItem(AppLayoutName.localStorage, appLayout);
    },
    setFavicon() {
      const link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/x-icon";
      link.href = `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.appLayout}/favicon/favicon.ico`;
      document.head.appendChild(link);

      const link32 = document.createElement("link");
      link32.rel = "icon";
      link32.type = "image/png";
      link32.setAttribute("sizes", "32x32");
      link32.href = `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.appLayout}/favicon/favicon-32x32.png`;
      document.head.appendChild(link32);

      const link16 = document.createElement("link");
      link16.rel = "icon";
      link16.type = "image/png";
      link16.setAttribute("sizes", "16x16");
      link16.href = `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.appLayout}/favicon/favicon-16x16.png`;
      document.head.appendChild(link16);

      const appleTouchIcon = document.createElement("link");
      appleTouchIcon.rel = "apple-touch-icon";
      appleTouchIcon.href = `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.appLayout}/favicon/apple-touch-icon.png`;
      document.head.appendChild(appleTouchIcon);
    },
  },
});
</script>

<style lang="scss">
@use "./scss/styles.scss";
</style>
