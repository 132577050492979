<template>
  <BaseDialog
    :show-dialog="showDialog"
    :width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
    @closeDialog="closeDialog"
  >
    <template #title> Gib deinen Link zum Kandidaten in ATS hier ein </template>
    <template #content>
      <v-card-text>
        <ExtractCandidateUuidFromAtsRecruitLinkForm
          @extractedUuid="extractedUuid"
        />
      </v-card-text>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import ExtractCandidateUuidFromAtsRecruitLinkForm from "./ExtractCandidateUuidFromAtsRecruitLinkForm.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum DialogExtractCandidateUuidFromAtsRecruitLinkEmits {
  closeDialog = "closeDialog",
  extractedUuid = "extractedUuid",
}

export default defineComponent({
  name: "DialogExtractCandidateUuidFromAtsRecruitLink",
  emits: [
    DialogExtractCandidateUuidFromAtsRecruitLinkEmits.closeDialog,
    DialogExtractCandidateUuidFromAtsRecruitLinkEmits.extractedUuid,
  ],
  components: {
    ExtractCandidateUuidFromAtsRecruitLinkForm,
    BaseDialog,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
    };
  },
  methods: {
    closeDialog() {
      this.$emit(DialogExtractCandidateUuidFromAtsRecruitLinkEmits.closeDialog);
    },
    extractedUuid(uuid: string) {
      this.$emit(
        DialogExtractCandidateUuidFromAtsRecruitLinkEmits.extractedUuid,
        uuid
      );
      this.closeDialog();
    },
  },
});
</script>
