import store from "../../store/store";
import { Candidate } from "@/models/candidate.model";
import { CompanyConfigService } from "./company-config.service";
import { ApiConfig } from "@/models/company-config.model";
import { Customer, CustomerList } from "@/models/customer.model";
import { EmployeeService } from "./employee.service";
import { CustomerService } from "./customer.service";
import {
  L1CustomerTimelineEntry,
  L1EmployeeTimelineEntry,
} from "@/models/timeline.model";
import { PdHubAdapter } from "@/adapter/pdhub.adapter";
import ToastService from "../toast.service";
import { Employee, EmployeeList } from "@/models/employee.model";
import { Assignment } from "@/models/assignment.model";
import { ErpFetchOption } from "@/enums/init-options.enum";
import { PdHubCustomerList } from "../../models/external/pd-hub.model";
import { PdHubServiceApi } from "./pd-hub-be.service";
import { SpinnerService } from "../spinner.service";
import { PdHubCall } from "../../models/pdhub.model";
import { ApiMethod } from "../../enums/api-method.enum";
import { HeaderSpinner, HeaderSpinnerSize } from "../../enums/app-layout.enum";
import { MutationType } from "../../enums/vuex-types.enum";

export class PdHubService {
  companyConfigService = CompanyConfigService.getInstance();
  apiKeys = {} as ApiConfig;

  private readonly pdHubBackendApi = new PdHubServiceApi();
  private static instance: PdHubService;

  public static getInstance(): PdHubService {
    if (!PdHubService.instance) {
      PdHubService.instance = new PdHubService();
    }
    return PdHubService.instance;
  }

  // Employee
  async getEmployeeList(
    x13cMandant?: string,
    filiale?: string
  ): Promise<EmployeeList[]> {
    let filialeAndMandantL1: { branchNumber: string; mandantL1: string }[] = [];

    if (!x13cMandant) {
      filialeAndMandantL1 = store.getters.mandantL1AndBranchNumber;
    } else {
      filialeAndMandantL1.push({
        branchNumber: filiale || "-1",
        mandantL1: x13cMandant,
      });
    }

    const expandAdresse = "$expand=Adresse($select=Strasse,Plz,Ort)";
    const selectQuery = "$select=PersonalNr,Nachname,Vorname,PersonalStatus";
    const queryParams = [selectQuery, expandAdresse].join("&");

    const endpoint = `/service/PDHub/personal/api/v1/Personal${
      queryParams ? `?${queryParams}` : ""
    }`;

    try {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.pdLoading
      );

      const results = await Promise.allSettled(
        filialeAndMandantL1.map(async ({ branchNumber, mandantL1 }) => {
          try {
            const pdHubCall = {
              endpoint: endpoint,
              method: ApiMethod.get,
              x13cMandant: mandantL1,
              x13cFiliale: branchNumber,
            } as PdHubCall;

            const response = await this.pdHubBackendApi.call(pdHubCall);
            return response.map((employeeData: any) =>
              PdHubAdapter.transformToEmployeeList(employeeData, mandantL1)
            );
          } catch (error) {
            ToastService.showError(
              `Fehler beim Laden der Mitarbeiterliste für Filiale ${branchNumber}, Mandant ${mandantL1}`
            );
            throw error;
          }
        })
      );

      // Sammle nur die erfolgreichen Ergebnisse
      const successfulResults = results
        .filter(
          (result): result is PromiseFulfilledResult<EmployeeList[]> =>
            result.status === "fulfilled"
        )
        .map((result) => result.value);

      return successfulResults.flat();
    } catch (error) {
      console.error("Error in getEmployeeList:", error);
      return [];
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  async getAllEmployees(
    x13cMandant?: string,
    filiale?: string,
    employeeStatus?: number
  ): Promise<Employee[]> {
    let filialeAndMandantL1: { branchNumber: string; mandantL1: string }[] = [];

    if (!x13cMandant) {
      filialeAndMandantL1 = store.getters.mandantL1AndBranchNumber;
    } else {
      filialeAndMandantL1.push({
        branchNumber: filiale || "-1",
        mandantL1: x13cMandant,
      });
    }

    const expands = ["Staatsangehoerigkeit", "Adresse", "Geburtsdaten"];
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";
    const selects = [
      "PersonalNr",
      "KandidatId",
      "Anlagedatum",
      "Anrede",
      "Austritt",
      "Eintritt",
      "ErlernterBeruf",
      "Familienstand",
      "FremdLohnPersonalNr",
      "Geburtsdatum",
      "Geschaeftsstelle",
      "Geschlecht",
      "Kostenstelle",
      "Vorname",
      "Nachname",
      "PersonalStatus",
      "Titel",
    ];
    const selectQuery = `$select=${selects.join(",")}`;

    let employeeStatusQuery = "";
    if (employeeStatus !== undefined) {
      employeeStatusQuery = `$filter=PersonalStatus eq '${employeeStatus}'`;
    }

    const queryParams = [expandQuery, selectQuery, employeeStatusQuery]
      .filter((q) => q !== "")
      .join("&");
    const endpoint = `/service/PDHub/personal/api/v1/Personal${
      queryParams ? `?${queryParams}` : ""
    }`;

    try {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.pdLoading
      );

      const results = await Promise.allSettled(
        filialeAndMandantL1.map(async ({ branchNumber, mandantL1 }) => {
          try {
            const pdHubCall = {
              endpoint: endpoint,
              method: ApiMethod.get,
              x13cMandant: mandantL1,
              x13cFiliale: branchNumber,
            } as PdHubCall;

            const response = await this.pdHubBackendApi.call(pdHubCall);
            return PdHubAdapter.transformToEmployeeModel(response, mandantL1);
          } catch (error) {
            ToastService.showError(
              `Fehler beim Laden der Mitarbeiterdaten für Filiale ${branchNumber}, Mandant ${mandantL1}`
            );
            throw error;
          }
        })
      );

      // Sammle nur die erfolgreichen Ergebnisse
      const successfulResults = results
        .filter(
          (result): result is PromiseFulfilledResult<Employee[]> =>
            result.status === "fulfilled"
        )
        .map((result) => result.value);

      return successfulResults.flat();
    } catch (error) {
      console.error("Error fetching employees:", error);
      return [];
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  async getByEmployeeNumber(
    employeeNumber: string,
    x13cMandant?: string,
    filiale?: string,
    fetchCareerSteps?: boolean
  ): Promise<any> {
    let allMandantL1 = undefined;
    if (!x13cMandant) allMandantL1 = store.getters.getAllMandantL1;
    if (!filiale) filiale = "-1";

    let expands = ["Staatsangehoerigkeit", "Adresse", "Geburtsdaten"];
    const expandsWithQualifications = [
      "Schulbildung",
      "Ausbildungen",
      "Weiterbildung",
      "Berufserfahrung",
      //"Berufe($expand=Qualifikationen)",
      "Fuehrerscheine",
      "Bewerbung",
      "EingestelltAls",
    ];
    if (fetchCareerSteps) expands = [...expands, ...expandsWithQualifications];
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";
    const selects = [
      "PersonalNr",
      "KandidatId",
      "Anlagedatum",
      "Anrede",
      "Austritt",
      "Eintritt",
      "ErlernterBeruf",
      "Familienstand",
      "FremdLohnPersonalNr",
      "Geburtsdatum",
      "Geschaeftsstelle",
      "Geschlecht",
      "Kostenstelle",
      "Vorname",
      "Nachname",
      "PersonalStatus",
      "Titel",
    ];
    const selectQuery = `$select=${selects.join(",")}`;
    const filterQuery = `$filter=PersonalNr eq ${employeeNumber}`;
    const queryParams = [expandQuery, selectQuery, filterQuery]
      .filter((q) => q !== "")
      .join("&");

    const endpoint = `/service/PDHub/personal/api/v1/Personal?${queryParams}`;
    const pdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.get,
      x13cMandant: allMandantL1 ? allMandantL1[0] : x13cMandant,
      x13cFiliale: filiale,
    } as PdHubCall;

    try {
      if (!allMandantL1 || allMandantL1.length === 0) {
        const response = await this.pdHubBackendApi.call(pdHubCall);
        if (response.length === 0) return null;

        const employees = PdHubAdapter.transformToEmployeeModel(response);
        return employees.length > 0 ? employees[0] : null;
      } else {
        const employeePromises = allMandantL1.map(async (mandantL1: string) => {
          pdHubCall.x13cMandant = mandantL1;
          const response = await this.pdHubBackendApi.call(pdHubCall);
          return PdHubAdapter.transformToEmployeeModel(response, mandantL1);
        });

        const allEmployeesArray = await Promise.all(employeePromises);
        const allEmployees = allEmployeesArray.flat();
        return allEmployees.length > 0 ? allEmployees : null;
      }
    } catch (error) {
      console.debug("Error fetching employee by number:", error);
      return null;
    }
  }

  async getPlacements(
    employeeOrCustomerNumber: string,
    mandantUuid: string,
    isCustomer?: boolean,
    isNoTimeFilter?: boolean
  ): Promise<Assignment[]> {
    const mandantL1 = store.getters.getMandantL1ByUuid(mandantUuid);
    const filiale = store.getters.getMandantBranchNumberByUuid(mandantUuid);

    let filterQuery = `$filter=PersonalNr eq ${employeeOrCustomerNumber}`;
    if (isCustomer) {
      filterQuery = `$filter=KundenNr eq ${employeeOrCustomerNumber}`;
    }

    const endpoint = `/service/PDHub/auftrag/api/v1/Einsaetze?${filterQuery}`;
    const pdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.get,
      x13cMandant: mandantL1,
      x13cFiliale: filiale,
    } as PdHubCall;

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      if (response.length === 0) return [];

      const assignments = PdHubAdapter.transformToAssignmentModel(
        response,
        isCustomer,
        isNoTimeFilter
      );
      return assignments;
    } catch (error) {
      console.debug("Error fetching placements by employee number:", error);
      return [];
    }
  }

  async createTimelineEntryEmployee(
    timelineEntry: L1EmployeeTimelineEntry,
    mandantUuid: string,
    filiale?: string
  ): Promise<void> {
    const mandantL1 = store.getters.getMandantL1ByUuid(mandantUuid);
    if (!filiale)
      filiale = store.getters.getMandantBranchNumberByUuid(mandantUuid);

    const endpoint = "/service/PDHub/personal/api/v1/Personal/KontaktAnlegen";
    const pdHubCall: PdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.post,
      x13cMandant: mandantL1,
      x13cFiliale: filiale ?? "-1",
      payload: timelineEntry,
    };

    try {
      await this.pdHubBackendApi.call(pdHubCall);
    } catch (error: any) {
      if (error.response?.data?.type === "KontaktAnlegenException") {
        ToastService.showError("Kontaktart nicht hinterlegt");
      } else {
        ToastService.showError("Fehler beim Anlegen des Kontakts");
      }
      throw new Error("Failed to create timeline entry for employee");
    }
  }

  async addCandidate(candidate: Candidate, mandantUuid: string): Promise<any> {
    const mandantL1 = store.getters.getMandantL1ByUuid(mandantUuid);
    const filiale = store.getters.getMandantBranchNumberByUuid(mandantUuid);

    const endpoint = "/service/PDHub/personal/api/v1/Personal/BewerberAnlegen";
    const pdHubCandidate = PdHubAdapter.candidateToPdHubCandidate(
      candidate,
      mandantUuid
    );

    const pdHubCall: PdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.post,
      x13cMandant: mandantL1,
      x13cFiliale: filiale,
      payload: pdHubCandidate,
    };

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      return response[0];
    } catch (error) {
      console.error("Error adding candidate:", error);
      return null;
    }
  }

  /* 
  WORKS ONLY WITH STATUS APPLICANT NOT WITH EMPLOYEES: maybe coming feature from PD-Hub
  async patchEmployee(employee: ZvooveEmployee): Promise<any> {
    const pdHubEmployee = PdHubAdapter.transformToPdHubEmployeeModel(employee);
    const headers = await this.header();
    if (!headers) return null;

    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal/BewerberAendern/${
      employee.id
    }`;

    try {
      const response = await axios.patch(url, pdHubEmployee, { headers });
      return response.data.Data;
    } catch (error) {
      console.error("Error patching employee in PD-Hub", error);
      throw error;
    }
  }
  */

  // Customer
  async getCustomerList(
    x13cMandant?: string,
    filiale?: string
  ): Promise<CustomerList[]> {
    let filialeAndMandantL1: { branchNumber: string; mandantL1: string }[] = [];

    if (!x13cMandant) {
      filialeAndMandantL1 = store.getters.mandantL1AndBranchNumber;
    } else {
      filialeAndMandantL1.push({
        branchNumber: filiale || "-1",
        mandantL1: x13cMandant,
      });
    }

    const expandAdressen =
      "$expand=Adressen($select=Strasse,Plz,Ort;$filter=Typ eq 'Postanschrift')";
    const selectQuery = "$select=KundenNr,KundenName";
    const queryParams = [selectQuery, expandAdressen].join("&");

    const endpoint = `/service/PDHub/kunden/api/v1/Kunden${
      queryParams ? `?${queryParams}` : ""
    }`;

    try {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.pdLoading
      );

      const results = await Promise.allSettled(
        filialeAndMandantL1.map(async ({ branchNumber, mandantL1 }) => {
          try {
            const pdHubCall = {
              endpoint: endpoint,
              method: ApiMethod.get,
              x13cMandant: mandantL1,
              x13cFiliale: branchNumber,
            } as PdHubCall;

            const response = await this.pdHubBackendApi.call(pdHubCall);
            return response.map((customerData: PdHubCustomerList) =>
              PdHubAdapter.transformToCustomerList(customerData, mandantL1)
            );
          } catch (error) {
            ToastService.showError(
              `Fehler beim Laden der Kundenliste für Filiale ${branchNumber}, Mandant ${mandantL1}`
            );
            throw error;
          }
        })
      );

      // Sammle nur die erfolgreichen Ergebnisse
      const successfulResults = results
        .filter(
          (result): result is PromiseFulfilledResult<CustomerList[]> =>
            result.status === "fulfilled"
        )
        .map((result) => result.value);

      return successfulResults.flat();
    } catch (error) {
      console.error("Error fetching customer list:", error);
      return [];
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  async getAllCustomers(
    x13cMandant?: string,
    filiale?: string,
    customerStatus?: number
  ): Promise<Customer[]> {
    let filialeAndMandantL1: { branchNumber: string; mandantL1: string }[] = [];

    if (!x13cMandant) {
      filialeAndMandantL1 = store.getters.mandantL1AndBranchNumber;
    } else {
      filialeAndMandantL1.push({
        branchNumber: filiale || "-1",
        mandantL1: x13cMandant,
      });
    }

    const expands = [
      "Geschaeftsstelle",
      "Adressen",
      customerStatus ? "Ansprechpartner" : undefined,
    ].filter(Boolean);

    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";

    let customerStatusQuery = "";
    if (customerStatus !== undefined) {
      customerStatusQuery = `$filter=kundenstatus eq '${customerStatus}'`;
    }

    const queryParams = [expandQuery, customerStatusQuery]
      .filter((q) => q !== "")
      .join("&");
    const endpoint = `/service/PDHub/kunden/api/v1/Kunden${
      queryParams ? `?${queryParams}` : ""
    }`;

    try {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.pdLoading
      );

      const results = await Promise.allSettled(
        filialeAndMandantL1.map(async ({ branchNumber, mandantL1 }) => {
          try {
            const pdHubCall = {
              endpoint: endpoint,
              method: ApiMethod.get,
              x13cMandant: mandantL1,
              x13cFiliale: branchNumber,
            } as PdHubCall;

            const response = await this.pdHubBackendApi.call(pdHubCall);
            return PdHubAdapter.transformToCustomerModel(response, mandantL1);
          } catch (error) {
            ToastService.showError(
              `Fehler beim Laden der Kundendaten für Filiale ${branchNumber}, Mandant ${mandantL1}`
            );
            throw error;
          }
        })
      );

      // Sammle nur die erfolgreichen Ergebnisse
      const successfulResults = results
        .filter(
          (result): result is PromiseFulfilledResult<Customer[]> =>
            result.status === "fulfilled"
        )
        .map((result) => result.value);

      return successfulResults.flat();
    } catch (error) {
      console.error("Error fetching customers:", error);
      return [];
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  async getByCustomerNumber(
    customerNumber: string,
    x13cMandant?: string,
    filiale?: string
  ): Promise<Customer[] | null> {
    let allMandantL1 = undefined;
    if (!x13cMandant) allMandantL1 = store.getters.getAllMandantL1;
    if (!filiale) filiale = "-1";

    const expands = [
      "Adressen($expand=Staat)",
      "Ansprechpartner",
      "Bundesland",
      "Geschaeftsstelle",
    ];
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";
    const filterQuery = `$filter=kundennr eq ${customerNumber}`;
    const queryParams = [expandQuery, filterQuery]
      .filter((q) => q !== "")
      .join("&");

    const endpoint = `/service/PDHub/kunden/api/v1/Kunden?${queryParams}`;
    const pdHubCall: PdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.get,
      x13cMandant:
        x13cMandant ?? store.state.company.softwareIntegration.mandantL1,
      x13cFiliale: filiale,
    };

    try {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.pdLoading
      );
      if (!allMandantL1 || allMandantL1.length === 0) {
        const response = await this.pdHubBackendApi.call(pdHubCall);
        if (response.length === 0) {
          return null; // No customer found
        }
        const customers = PdHubAdapter.transformToCustomerModel(response);
        return customers.length > 0 ? customers : null;
      } else {
        const customerPromises = allMandantL1.map(async (mandantL1: string) => {
          pdHubCall.x13cMandant = mandantL1;
          const response = await this.pdHubBackendApi.call(pdHubCall);
          return PdHubAdapter.transformToCustomerModel(response, mandantL1);
        });

        const allCustomersArray = await Promise.all(customerPromises);
        const allCustomers = allCustomersArray.flat();
        return allCustomers.length > 0 ? allCustomers : null;
      }
    } catch (error) {
      console.error("Error fetching customer by number:", error);
      return null;
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  async createCustomer(newCustomer: Customer): Promise<any> {
    if (
      !store.getters.getLoggedInMandantBranchNumbers ||
      store.getters.getLoggedInMandantBranchNumbers.length === 0
    ) {
      ToastService.showError(
        "Keine eingeloggte Niederlassung, um den Kunden anzulegen"
      );
      return Promise.resolve();
    }

    const firstLoggedInMandant = store.getters.getLoggedInMandantUuids[0];
    const mandantL1 = store.getters.getMandantL1ByUuid(firstLoggedInMandant);
    const pdHubCustomer = PdHubAdapter.transformToPdHubModel(newCustomer);

    const filiale =
      store.getters.getMandantBranchNumberByUuid(firstLoggedInMandant);

    const endpoint = `/service/PDHub/kunden/api/v1/Kunden/InteressentAnlegen`;
    const pdHubCall: PdHubCall = {
      endpoint: endpoint,
      method: ApiMethod.post,
      x13cMandant:
        mandantL1 || store.state.company.softwareIntegration.mandantL1,
      x13cFiliale: filiale || "-1",
      payload: pdHubCustomer,
    };

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      return response;
    } catch (error) {
      console.error("Error posting customer in PD-Hub", error);
      throw error;
    }
  }

  async createTimelineEntryCustomer(
    timelineEntry: L1CustomerTimelineEntry,
    mandantUuid: string,
    filiale?: string
  ): Promise<void> {
    const mandantL1 = store.getters.getMandantL1ByUuid(mandantUuid);

    if (!filiale) {
      filiale = store.getters.getMandantBranchNumberByUuid(mandantUuid);
    }

    const pdHubCall: PdHubCall = {
      endpoint: `/service/PDHub/kunden/api/v1/Kunden/KontaktAnlegen`,
      method: ApiMethod.post,
      x13cMandant:
        mandantL1 || store.state.company.softwareIntegration.mandantL1, // Standardwert für x13cMandant
      x13cFiliale: filiale ?? "-1",
      payload: timelineEntry,
    };

    try {
      await this.pdHubBackendApi.call(pdHubCall);
    } catch (error: any) {
      if (error.response?.data?.type === "KontaktAnlegenException") {
        ToastService.showError("Kontaktart nicht hinterlegt");
      } else {
        ToastService.showError("Fehler beim Anlegen des Kontakts");
      }
      console.error("Error creating timeline entry for customer:", error);
      throw new Error("Failed to create timeline entry for customer");
    }
  }

  async patchCustomer(customer: Customer): Promise<any> {
    const pdHubCustomer = PdHubAdapter.transformToPdHubCustomerModel(customer);
    const mandantL1 = store.getters.getMandantL1ByUuid(customer.mandants[0]);

    const pdHubCall: PdHubCall = {
      endpoint: `/service/PDHub/kunden/api/v1/Kunden/${customer.customerNumber}`,
      method: ApiMethod.patch,
      x13cMandant:
        mandantL1 || store.state.company.softwareIntegration.mandantL1,
      x13cFiliale: store.getters.getMandantBranchNumberByUuid(
        customer.mandants[0]
      ),
      payload: pdHubCustomer,
    };

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      await this.updateCustomerAddresses(customer, mandantL1);
      return response;
    } catch (error) {
      console.error("Error patching customer in PD-Hub", error);
      throw error;
    }
  }

  async updateCustomerAddresses(
    customer: Customer,
    mandantL1: string | null
  ): Promise<void> {
    const customerNumber = customer.customerNumber;
    const pdHubCall: PdHubCall = {
      endpoint: `/service/PDHub/kunden/api/v1/Kunden?$expand=Adressen&$filter=kundennr eq ${customerNumber}`,
      method: ApiMethod.get,
      x13cMandant:
        mandantL1 || store.state.company.softwareIntegration.mandantL1,
      x13cFiliale: "",
    };

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      const addresses = response[0].Adressen;
      const postAddress = addresses.find(
        (address: any) => address.Typ === "Postanschrift"
      );
      const invoiceAddress = addresses.find(
        (address: any) => address.Typ === "Rechnungsanschrift"
      );

      if (postAddress) {
        await this.patchCustomerAddress(
          customer,
          postAddress.Nummer,
          "Postanschrift",
          mandantL1
        );
      }
      if (invoiceAddress) {
        await this.patchCustomerAddress(
          customer,
          invoiceAddress.Nummer,
          "Rechnungsanschrift",
          mandantL1
        );
      }
    } catch (error) {
      console.error("Error fetching addresses for customer in PD-Hub", error);
    }
  }

  async patchCustomerAddress(
    customer: Customer,
    addressNumber: number,
    addressType: string,
    mandantL1?: string | null,
    filiale?: string
  ): Promise<any> {
    const customerNumber = customer.customerNumber;

    const addressData = {
      Name1: customer.generalData.name,
      Name2: customer.generalData.name2 ?? "",
      Name3: customer.generalData.name3 ?? "",
      Strasse: customer.addressAndCommunication.street ?? "",
      Plz: customer.addressAndCommunication.postalCode ?? "",
      Ort: customer.addressAndCommunication.city ?? "",
      Telefon1: customer.addressAndCommunication.phone1 ?? "",
      Telefon2: customer.addressAndCommunication.phone2 ?? "",
      Telefax: "",
      Email: customer.addressAndCommunication.email ?? "",
      Homepage: customer.addressAndCommunication.website ?? "",
    };

    const pdHubCall: PdHubCall = {
      endpoint: `/service/PDHub/kunden/api/v1/Kunden/${customerNumber}/adresse/${addressNumber}`,
      method: ApiMethod.patch,
      x13cMandant:
        mandantL1 || store.state.company.softwareIntegration.mandantL1,
      x13cFiliale:
        filiale ||
        store.getters.getMandantBranchNumberByUuid(customer.mandants[0]),
      payload: addressData,
    };

    try {
      const response = await this.pdHubBackendApi.call(pdHubCall);
      return response;
    } catch (error) {
      console.error(
        `Error patching ${addressType} address for customer in PD-Hub`,
        error
      );
      throw error;
    }
  }

  // ########################################################
  // Method to fetch and initialize PD-Hub data
  async fetchPdHubInitData(
    erpOptions: ErpFetchOption[],
    filiale?: string
  ): Promise<void> {
    let message = "";
    let errorMessage = "";
    let customerList: CustomerList[] = [];
    let employeeList: EmployeeList[] = [];
    let activeEmployees: Employee[] = [];
    let customers: Customer[] = [];
    let employees: Employee[] = [];

    try {
      if (
        erpOptions.includes(ErpFetchOption.CustomerList) ||
        erpOptions.includes(ErpFetchOption.Customers)
      ) {
        if (erpOptions.includes(ErpFetchOption.CustomerList)) {
          customerList = (await this.getCustomerList(undefined, filiale)) || [];
        }
        if (erpOptions.includes(ErpFetchOption.Customers)) {
          const response = await this.getAllCustomers(undefined, filiale, 2);
          customers = response || [];
        }
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      errorMessage += "Error fetching customers. ";
    }

    try {
      if (
        erpOptions.includes(ErpFetchOption.EmployeeList) ||
        erpOptions.includes(ErpFetchOption.Employees)
      ) {
        if (erpOptions.includes(ErpFetchOption.EmployeeList)) {
          employeeList = (await this.getEmployeeList(undefined, filiale)) || [];
        }
        if (erpOptions.includes(ErpFetchOption.Employees)) {
          const fetchedEmployees = await this.getAllEmployees(
            undefined,
            filiale,
            2
          );
          employees = fetchedEmployees || [];
          activeEmployees = employees.filter(
            (employee) => employee.employeeStatus === 2
          );
        }
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      errorMessage += "Error fetching employees. ";
    }

    try {
      const employeeService = new EmployeeService();
      const hasFetchedValidEmployeeList =
        erpOptions.includes(ErpFetchOption.EmployeeList) &&
        employeeList.length > 0;
      if (hasFetchedValidEmployeeList)
        await employeeService.saveList(employeeList);
      if (erpOptions.includes(ErpFetchOption.Employees))
        await employeeService.addAllEmployees(activeEmployees);
    } catch (error) {
      console.error("Error saving employees:", error);
      errorMessage += "Error saving employees. ";
    }

    try {
      const customerService = new CustomerService();
      const hasFetchedValidCustomerList =
        erpOptions.includes(ErpFetchOption.CustomerList) &&
        customerList.length > 0;
      if (hasFetchedValidCustomerList)
        await customerService.saveList(customerList as CustomerList[]);
      if (erpOptions.includes(ErpFetchOption.Customers))
        await customerService.addCustomers(customers);
    } catch (error) {
      console.error("Error saving customers:", error);
      errorMessage += "Error saving customers. ";
    }

    try {
      if (erpOptions.includes(ErpFetchOption.CustomerList))
        store.commit(MutationType.setZvooveCustomerList, customerList);
      if (erpOptions.includes(ErpFetchOption.EmployeeList))
        store.commit(MutationType.setZvooveEmployeeList, employeeList);
    } catch (error) {
      console.error("Error committing to store:", error);
      errorMessage += "Error committing to store. ";
    }

    message += `${customerList.length} Unternehmensdaten, `;
    message += `${employeeList.length} Personaldaten, `;
    message += `${customers.length} Kunden und `;
    message += `${employees.length} Mitarbeiter erhalten.`;

    ToastService.showReminder(message);
    if (errorMessage) ToastService.showError(errorMessage);
  }
}
