import { AppLayout, AppTitle } from "../enums/app-layout.enum";
import store from "../store/store";
export const appTitle = () => {
  const appLayout = store.getters.appLayout;
  switch (appLayout) {
    case AppLayout.classic:
      return AppTitle.classic;
    case AppLayout.dispositioner:
      return AppTitle.dispositioner;
    case AppLayout.pdmaster:
      return AppTitle.pdmaster;
    case AppLayout.persontobusiness:
      return AppTitle.persontobusiness;
    case AppLayout.liebeZeitarbeit:
      return AppTitle.liebeZeitarbeit;
    default:
      return AppTitle.dispositioner;
  }
};
