<template>
  <div class="email-buttons">
    <v-btn
      v-if="!office365AccessToken && !mailServer"
      v-bind="vStyle.btn"
      class="ml-2"
      @click="addMailserverConfig()"
      width="250"
      ><v-icon>fas fa-plus</v-icon>SMTP Mailserver</v-btn
    >
    <div v-if="mailServer" class="d-flex">
      <EmailServerConfigForm
        :modelValue="mailServer"
        @testResult="emailValidationResult"
      />
      <v-btn icon variant="text" @click="removeMailserverConfig"
        ><v-icon>fa-solid fa-xmark</v-icon></v-btn
      >
    </div>
    <v-divider
      v-if="!mailServer && !office365AccessToken"
      class="my-5"
    ></v-divider>
    <OfficeIntBtn
      v-if="!mailServer"
      class="ml-2"
      :width="250"
      :emailScope="emailScope"
      :mandantUuid="uuid"
      :isExistingAccessToken="office365AccessToken"
      @logout="office365Logout"
    ></OfficeIntBtn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import OfficeIntBtn from "@/components/email/office-int/OfficeIntBtn.vue";
import EmailServerConfigForm from "../../forms/EmailServerConfigForm.vue";
import ToastService from "../../../services/toast.service";
import { cloneDeep } from "lodash";
import { MailServer } from "../../../models/mailserver.model";
import { EmailScope } from "../../../enums/email-scope.enum";

export enum EmailConfigurationEmits {
  emailValidationResult = "emailValidationResult",
  office365Logout = "office365Logout",
  removeMailserverConfig = "removeMailserverConfig",
}

export default defineComponent({
  name: "EmailConfiguration",
  emits: [
    EmailConfigurationEmits.emailValidationResult,
    EmailConfigurationEmits.office365Logout,
    EmailConfigurationEmits.removeMailserverConfig,
  ],
  components: {
    EmailServerConfigForm,
    OfficeIntBtn,
  },
  props: {
    emailScope: {
      type: Number as PropType<EmailScope>,
      default: EmailScope.user,
    },
    office365AccessToken: {
      type: Boolean,
      default: false,
    },
    mailServerConfig: {
      type: Object,
    },
    office365Config: {
      type: Object,
    },
    uuid: {
      type: String,
    },
  },
  /*
  computed: {
    isExistingAccessToken() {
      const token = (this.office365Config?.accessToken as string) === "true";
      return token || false;
    },
  },
  */
  data() {
    const mailServer: undefined | MailServer = cloneDeep(
      this.mailServerConfig
    ) as MailServer;
    return {
      mailServer: mailServer as undefined | MailServer,
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    addMailserverConfig() {
      this.mailServer = {
        from: "",
        password: "",
        port: 0,
        secure: false,
        sender: "",
        server: "",
        username: "",
      };
    },
    emailValidationResult() {
      this.$emit(
        EmailConfigurationEmits.emailValidationResult,
        this.mailServer
      );
    },
    office365Logout() {
      this.$emit(EmailConfigurationEmits.office365Logout);
      ToastService.show("Office365 Berechtigung aufgehoben");
    },
    removeMailserverConfig() {
      this.mailServer = undefined as undefined;
      this.$emit(EmailConfigurationEmits.removeMailserverConfig);
    },
  },
});
</script>
<style lang="scss" scoped>
.email-buttons {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
