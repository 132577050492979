import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-header-menu" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "panels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.Routes.dispostion,
      "data-tid": "header-link-dashboard"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "logo",
          src: _ctx.appLogo,
          alt: "Logo"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_sheet, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.recruiting))),
        class: "mainbutton recruiting",
        color: _ctx.hover.a ? 'secondary' : 'primary',
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover.a = true)),
        onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hover.a = false)),
        "data-tid": "header-link-recruiting"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("i", { class: "fa-solid fa-people-arrows panel-icon" }, null, -1),
          _createElementVNode("p", null, "Recruiting", -1)
        ])),
        _: 1
      }, 8, ["color"]),
      _createVNode(_component_v_sheet, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.dispostion))),
        class: "mainbutton",
        color: _ctx.hover.b ? 'secondary' : 'primary',
        onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hover.b = true)),
        onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hover.b = false)),
        "data-tid": "header-link-disposition"
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("i", { class: "fa-solid fa-headset panel-icon" }, null, -1),
          _createElementVNode("p", null, "Disposition", -1)
        ])),
        _: 1
      }, 8, ["color"]),
      _createVNode(_component_v_sheet, {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.administration))),
        class: "mainbutton",
        color: _ctx.hover.c ? 'secondary' : 'primary',
        onMouseover: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hover.c = true)),
        onMouseleave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hover.c = false))
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createElementVNode("i", { class: "fa-regular fa-folder-open panel-icon" }, null, -1),
          _createElementVNode("p", null, "Verwaltung", -1)
        ])),
        _: 1
      }, 8, ["color"])
    ])
  ]))
}