import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.skillsDialog || _ctx.interviewDialog)
    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseDialog, {
            showDialog: _ctx.skillsDialog,
            showActions: true,
            onCloseDialog: _ctx.closeSkillsModal,
            width: 'var(--d-w-l)'
          }, {
            title: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Daten ergänzen ... ")
            ])),
            content: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle?.card)), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_subtitle, { class: "my-4" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("Kandidaten bearbeiten,")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                variant: "text",
                                block: "",
                                style: {"color":"var(--color-primary)"},
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.$emit(_ctx.DialogInterviewCompletedEmits.openEditCandidateDialog)
                  ))
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("Bearbeiten")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 16)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle?.card)), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_subtitle, { class: "my-4" }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" oder in ATS eintragen!")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                variant: "text",
                                block: "",
                                style: {"color":"var(--color-primary)"},
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openZvoove('stammdaten')))
                              }, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode("1. Stammdaten")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                variant: "text",
                                block: "",
                                style: {"color":"var(--color-primary)"},
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openZvoove('perfectmatch/skills')))
                              }, {
                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                  _createTextVNode("2. Skills")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                variant: "text",
                                block: "",
                                style: {"color":"var(--color-primary)"},
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openZvoove('vita')))
                              }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode("3. Lebenslauf")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_divider),
                              _createVNode(_component_v_btn, {
                                variant: "text",
                                block: "",
                                color: "success",
                                onClick: _ctx.handleAtsRecruitConfirm
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode("OK und aus ATS aktualisieren")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }, 16)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            actions: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "abort",
                onClick: _ctx.closeSkillsModal
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "success",
                onClick: _ctx.handleEditConfirm
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Weiter")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["showDialog", "onCloseDialog"]),
          _createVNode(_component_BaseDialog, {
            showDialog: _ctx.interviewDialog,
            showActions: true,
            onCloseDialog: _ctx.closeInterviewModal,
            width: '600px'
          }, {
            title: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Zusammenfassung des Vorstellungsgesprächs")
            ])),
            content: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle?.input, {
                    modelValue: _ctx.interviewSummary,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.interviewSummary) = $event)),
                    label: "Bitte gibt eine Zusammenfassung ein."
                  }), null, 16, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            actions: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "abort",
                onClick: _ctx.closeInterviewModal
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "success",
                style: {"color":"var(--color-primary)"},
                onClick: _ctx.handleInterviewConfirm
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode("OK")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["showDialog", "onCloseDialog"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}