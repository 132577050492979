<template>
  <div>
    <div class="d-flex justify-end">
      <v-select
        v-bind="vStyle.input"
        v-model="filters.mandants"
        label="Niederlassungen auswählen"
        :items="mandants"
        item-title="name"
        item-value="uuid"
        max-width="20rem"
        multiple
        @update:model-value="applyFilters"
      ></v-select>
      <v-btn
        v-if="isAnyFilterActive"
        class="ml-5"
        size="xlarge"
        density="compact"
        variant="text"
        icon
        @click="resetFilters"
      >
        <v-icon>fa-solid fa-filter-circle-xmark</v-icon>
        <v-tooltip activator="parent" location="top left"
          >Alle Filter zurücksetzen</v-tooltip
        >
      </v-btn>
    </div>

    <v-card v-bind="vStyle.card">
      <table id="feedTable">
        <thead>
          <tr>
            <th style="min-width: 13rem">
              <div class="d-flex justify-space-between">
                Disponent
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.user.length > 0 ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.user.length > 0
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5"
                      >Disponent auswählen</v-card-title
                    >

                    <v-card-text>
                      <v-checkbox
                        v-for="user in userOptions"
                        :key="user"
                        v-model="filters.user"
                        :label="user"
                        :value="user"
                        variant="outlined"
                        density="compact"
                        @change="applyFilters"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>

            <th style="min-width: 8rem">
              <div class="d-flex justify-space-between">
                Datum
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.endDate ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.startDate || filters.endDate
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5">Zeitraum anzeigen</v-card-title>

                    <v-card-text>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="filters.startDate"
                        label="Von"
                        density="compact"
                        type="date"
                        @change="applyFilters"
                      ></v-text-field>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="filters.endDate"
                        label="Bis"
                        density="compact"
                        type="date"
                        @change="applyFilters"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>

            <th style="min-width: 11rem">
              <div class="d-flex justify-space-between">
                Typ
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.type.length > 0 ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.type.length > 0
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5"
                      >Nach Art der Notiz filtern</v-card-title
                    >

                    <v-card-text>
                      <v-checkbox
                        v-for="type in typeOptions"
                        :key="type"
                        v-model="filters.type"
                        :label="type"
                        :value="type"
                        variant="outlined"
                        density="compact"
                        @change="applyFilters"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>

            <th style="min-width: 13rem">
              <div class="d-flex justify-space-between">
                Unternehmen
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.customer ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.customer
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5"
                      >Nach Unternehmen filtern</v-card-title
                    >

                    <v-card-text>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="filters.customer"
                        label="Suchbegriff"
                        density="compact"
                        @update:model-value="applyFilters"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>

            <th style="min-width: 13rem">
              <div class="d-flex justify-space-between">
                Personal
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.participant ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.participant
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5"
                      >Nach Kandidat / Mitarbeiter filtern</v-card-title
                    >

                    <v-card-text>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="filters.participant"
                        label="Suchbegriff"
                        density="compact"
                        @update:model-value="applyFilters"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>

            <th>
              <div class="d-flex justify-space-between">
                Notiz
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon
                      density="compact"
                      variant="text"
                      v-bind="props"
                      :color="filters.note ? 'tertiary' : 'primary'"
                    >
                      <v-icon size="xsmall">
                        {{
                          filters.note
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card v-bind="vStyle.card">
                    <v-card-title class="mb-5">Nach Notiz filtern</v-card-title>

                    <v-card-text>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="filters.note"
                        label="Suchbegriff"
                        density="compact"
                        @update:model-value="applyFilters"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in filteredTimelines" :key="index">
            <td>{{ entry.user }}</td>
            <td>{{ formatDate(entry.entryDate) }}</td>
            <td>{{ entry.entryType }}</td>
            <td>
              {{ entry.customer.name }}
              <div v-if="entry.customer.contact">
                Ansprechpartner: {{ entry.customer.contact }}
              </div>
            </td>
            <td>{{ entry.participant.name }}</td>
            <td v-html="formatNoteText(entry?.note)"></td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Timeline } from "@/models/timeline.model";
import { TimelineService } from "@/services/api/timeline.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "DokuFeed",
  data() {
    return {
      timelines: [] as Timeline[],
      filteredTimelines: [] as Timeline[],
      filters: {
        user: [] as string[],
        type: [] as string[],
        mandants: [] as string[],
        customer: "",
        participant: "",
        note: "",
        startDate: "",
        endDate: "",
      },
      userOptions: [] as string[],
      typeOptions: [] as string[],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      mandants: "reducedMandants",
    }),
    isAnyFilterActive() {
      return (
        this.filters.user.length > 0 ||
        this.filters.type.length > 0 ||
        this.filters.mandants.length > 0 ||
        this.filters.customer ||
        this.filters.participant ||
        this.filters.note ||
        this.filters.startDate ||
        this.filters.endDate
      );
    },
  },
  methods: {
    applyFilters() {
      this.filteredTimelines = this.timelines?.filter((entry) => {
        const matchesUser =
          this.filters.user.length === 0 ||
          this.filters.user.includes(entry.user);
        const matchesType =
          this.filters.type.length === 0 ||
          this.filters.type.includes(entry.entryType);
        const matchesMandants =
          this.filters.mandants.length === 0 ||
          this.filters.mandants.includes(entry.mandants[0]); // TODO Change when using multi mandants for timeline
        const matchesCustomer =
          !this.filters.customer ||
          entry.customer.name
            .toLowerCase()
            .includes(this.filters.customer.toLowerCase());
        const matchesParticipant =
          !this.filters.participant ||
          entry.participant.name
            .toLowerCase()
            .includes(this.filters.participant.toLowerCase());
        const matchesNote =
          !this.filters.note ||
          entry.note.toLowerCase().includes(this.filters.note.toLowerCase());
        const matchesDate =
          (!this.filters.startDate ||
            moment(entry.entryDate).isSameOrAfter(this.filters.startDate)) &&
          (!this.filters.endDate ||
            moment(entry.entryDate).isSameOrBefore(this.filters.endDate));

        return (
          matchesUser &&
          matchesType &&
          matchesMandants &&
          matchesCustomer &&
          matchesParticipant &&
          matchesNote &&
          matchesDate
        );
      });
    },
    formatDate(entryDate: string): string {
      const dateEntry = moment(entryDate).format("DD.MM.y");
      const timeEntry = moment(entryDate).format("HH:mm");
      return `${dateEntry} um ${timeEntry} Uhr`;
    },
    formatNoteText(note: string) {
      if (!note) return "";
      return note.replace(/\n/g, "<br>");
    },
    resetFilters() {
      this.filters = {
        user: [],
        type: [],
        mandants: [],
        customer: "",
        participant: "",
        note: "",
        startDate: "",
        endDate: "",
      };
      this.applyFilters();
    },
    extractFilterOptions() {
      this.userOptions = Array.from(
        new Set(this.timelines.map((entry) => entry.user))
      );
      this.typeOptions = Array.from(
        new Set(this.timelines.map((entry) => entry.entryType))
      );
      this.userOptions.sort((a: string, b: string) => a.localeCompare(b));
      this.typeOptions.sort((a: string, b: string) => a.localeCompare(b));
    },
  },
  async mounted() {
    const timelineService = new TimelineService();
    this.timelines = await timelineService.getTimeline();
    if (this.timelines) {
      this.timelines = this.timelines.toReversed();
      this.extractFilterOptions();
      this.applyFilters();
    }
  },
});
</script>

<style scoped>
h1 {
  padding: 1rem;
}

#feedTable {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--table-suface-light);
}

#feedTable th,
#feedTable td {
  padding: 10px;
  border: 1px solid var(--border-light);
  text-align: left;
}

#feedTable tbody tr:hover {
  background-color: var(--color-tableHover);
}
</style>
