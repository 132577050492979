<!-- src/components/disposition/elements/Reminder.vue-->
<template>
  <v-btn
    v-if="showReminderIcon"
    icon
    color="white"
    density="compact"
    variant="tonal"
    class="reminder-icon-btn"
    @click="showPrompt = true"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <v-icon class="reminder-icon" size="xs">fa-solid fa-bell</v-icon>
  </v-btn>
  <BaseDialog
    :showDialog="showPrompt"
    :persistent="true"
    :hideTitle="true"
    :width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
  >
    <template #content>
      <v-card-title class="d-flex"
        >{{ promptTitle }}
        <v-spacer></v-spacer>
        <v-btn
          class="longpress"
          icon
          variant="text"
          @click="setSnooze(2)"
          @contextmenu.prevent="openMenu($event)"
          @longpress="openMenu($event)"
        >
          <v-icon color="primary">fa-solid fa-bell-slash</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >Still für 2min / Rechtsklick = Snooze-Zeit wählen</v-tooltip
          >
        </v-btn>
      </v-card-title>
      <v-menu
        v-model="selectSnooze"
        :style="{ position: 'absolute', top: menuY + 'px', left: menuX + 'px' }"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item
            v-for="(item, index) in snoozeOptions"
            :key="index"
            @click="setSnooze(item.value)"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-card-text> {{ promptText }} </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn v-if="buttonSuccess" color="success" @click="clickSuccess">{{
          buttonSuccess
        }}</v-btn>
        <v-btn v-if="buttonResult" color="primary" @click="clickResult">{{
          buttonResult
        }}</v-btn>
        <v-btn v-if="buttonFailure" color="abort" @click="clickFailure">{{
          buttonFailure
        }}</v-btn>
      </v-card-actions>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { DialogResponse } from "@/enums/dialog.enum";
import { mapGetters } from "vuex";
import { TooltipService } from "@/services/tooltip.service";
import { useDisplay } from "vuetify";
import moment, { Moment } from "moment";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum ReminderEmit {
  clickSuccess = "clickSuccess",
  clickResult = "clickResult",
  clickFailure = "clickFailure",
}

export default defineComponent({
  name: "ReminderComponent",
  emits: [
    ReminderEmit.clickSuccess,
    ReminderEmit.clickResult,
    ReminderEmit.clickFailure,
  ],
  components: {
    BaseDialog,
  },
  props: {
    promptTitle: {
      type: String,
      default: "Title Prompt",
    },
    promptText: {
      type: String,
      default: "Text Prompt",
    },
    buttonSuccess: {
      type: String,
      default: DialogResponse.success,
    },
    buttonResult: {
      type: String,
      default: DialogResponse.result,
    },
    buttonFailure: {
      type: String,
      default: DialogResponse.failure,
    },
    openReminder: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      menuX: 0,
      menuY: 0,
      smAndDown,
      showReminderIcon: true,
      showPrompt: false,
      selectSnooze: false,
      snoozeUntil: null as Moment | null,
      // TODO refactor
      snoozeOptions: [
        { label: "5 Minuten", value: 5 },
        { label: "15 Minuten", value: 15 },
        { label: "30 Minuten", value: 30 },
        { label: "1 Stunde", value: 60 },
        { label: "3 Stunden", value: 180 },
        { label: "6 Stunden", value: 360 },
      ],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["reminderSettings"]),
  },
  mounted() {
    this.loadSnoozeFromSession();
  },
  methods: {
    clickSuccess() {
      this.$emit(ReminderEmit.clickSuccess);
      this.closePromptModal();
    },
    clickResult() {
      this.$emit(ReminderEmit.clickResult);
      this.closePromptModal();
    },
    clickFailure() {
      this.$emit(ReminderEmit.clickFailure);
      this.closePromptModal();
    },
    closePromptModal() {
      this.showPrompt = false;
    },
    loadSnoozeFromSession() {
      this.cleanupOldSnoozeEntries();
      const snoozeTime = localStorage.getItem(`snoozeUntil_${this.promptText}`);
      if (snoozeTime) {
        this.snoozeUntil = moment(snoozeTime);
      }
    },
    cleanupOldSnoozeEntries() {
      const yesterday = moment().subtract(1, "day").startOf("day");

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith("snoozeUntil_")) {
          const snoozeTime = moment(localStorage.getItem(key));
          if (snoozeTime.isBefore(yesterday)) {
            localStorage.removeItem(key);
          }
        }
      }
    },
    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
    async openMenu(event: MouseEvent) {
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      await nextTick();
      this.selectSnooze = true;
    },
    setSnooze(minutes: number) {
      const snoozeTime = moment().add(minutes, "minutes");
      this.snoozeUntil = snoozeTime;
      localStorage.setItem(
        `snoozeUntil_${this.promptText}`,
        snoozeTime.toISOString()
      );
      this.closePromptModal();
      if (this.$refs.menu) {
        (this.$refs.menu as any).close();
      }
      this.selectSnooze = false;
    },
    showPromptModal() {
      const now = moment();
      if (this.snoozeUntil && this.snoozeUntil.isAfter(now)) {
        return;
      }
      this.showPrompt = true;
    },
    showTooltip(event: any) {
      const htmlContent = this.openReminder.join("<hr>");
      TooltipService.showExpiredEvents(event, htmlContent);
    },
  },
});
</script>
<style scoped>
.reminder-icon-btn {
  position: absolute;
  top: -0.4rem;
  left: -0.4rem;
}
.reminder-icon {
  font-size: 0.6rem;
  animation: pulseAnimation 1s infinite, redBlinkAnimation 1s infinite,
    shakeAnimation 0.3s infinite;
  cursor: pointer;
}
</style>
