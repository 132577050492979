import { Employee } from "@/models/employee.model";
import {
  Candidate,
  CommunicationMeans,
  Application,
  CandidateFromCvParser,
} from "@/models/candidate.model";
import { CommunicationType } from "@/enums/communication-types.enum";
import { EmployeeStates } from "@/enums/employee-states.enum";
import store from "../store/store";
import { LinkingStatus } from "../enums/dependency.enum";
import { IdPrefix } from "../enums/id-prefix.enum";

export class EmployeeToCandidate {
  static employeeToCandidate(employee: Employee): Candidate {
    // Communication means mapping
    const communicationMeans: CommunicationMeans[] = [];
    if (employee.address.phone1) {
      communicationMeans.push({
        description: "Telefon 1",
        type: CommunicationType.Phone,
        value: employee.address.phone1,
      });
    }
    if (employee.address.phone2) {
      communicationMeans.push({
        description: "Telefon 2",
        type: CommunicationType.Mobile,
        value: employee.address.phone2,
      });
    }
    if (employee.email) {
      communicationMeans.push({
        description: "E-Mail",
        type: CommunicationType.Email,
        value: employee.email,
      });
    }

    let employeeLinkingStatus;
    switch (employee.employeeStatus) {
      case EmployeeStates.Employee:
        employeeLinkingStatus = LinkingStatus.hired;
        break;
      case EmployeeStates.Applicant:
        employeeLinkingStatus = LinkingStatus.interviewCompleted;
        break;
      default:
        employeeLinkingStatus = LinkingStatus.inboxApplication;
    }
    const defaultStatus = store.getters.getStatusValueByLinkingStatus(
      employeeLinkingStatus
    );

    // Default application
    const defaultApplication: Application = {
      applicationId: employee.employeeNumber || "",
      uuid: "",
      workingHours: 0,
      workingHoursUntil: 0,
      workingHoursPeriod: "",
      lastEditorName: "",
      applicationDate: employee.creationDate,
      appliedAs: employee.employedAsJobId,
      dataPrivacyDeadline: "",
      dataStorageDeclined: "",
      dataStorageGrantedBy: "",
      inputMedium: "",
      operationalRadius: 0,
      entryFrom: employee.entryDate || "",
      questions: [],
      salary: employee.salary,
      salaryCurrency: "EUR",
      salaryPeriod: "MONTH",
      mandantUuid: employee.mandants[0] || this.getDefaultMandantUuid(),
      source: "",
      ats: {
        statusUuid: "",
        status: "",
        internalStatusSystemName: "",
      },
      status: defaultStatus,
      documentsReturnShipping: false,
      availableUntil: "",
    };

    // Im transform:
    const { street, houseNumber } = this.extractHouseNumber(
      employee.address.street
    );

    return {
      uuid: employee.candidateUuid || this.getDispositionerUuid(),
      firstName: employee.firstName,
      lastName: employee.lastName,
      additionalAddresses: [],
      addressCity: employee.address.city,
      addressCountry: employee.address.countryId || "",
      addressHouseNumber: houseNumber,
      addressPostalCode: employee.address.postalCode,
      addressStreet: street,
      applications: [defaultApplication],
      avatar: { uuid: "", mimeType: "" },
      birthDate: employee.birthDate,
      birthName: employee.birthName,
      birthPlace: employee.birthPlace,
      careerLevel: [],
      careerSteps: employee.careerSteps || [],
      checklist: employee.checklist || [],
      communicationMeans,
      employeeObjectId: employee._id || "",
      employeeStatus: employee.employeeStatus as EmployeeStates,
      files: [],
      isEmbargoChecked: false,
      isNotInAts: employee.candidateUuid ? undefined : true,
      maritalStatus: employee.maritalStatus,
      mobility: null,
      mobilityIcon: null,
      mobilityRadius: 0,
      namePrefix: "",
      nameSuffix: "",
      nationality: employee.nationalityId,
      notes: { interviewResult: "", information: "" },
      otherFirstNames: "",
      profiles: [],
      residencePermitUntil: employee.residencePermitExpiration || "",
      salutationCatalogId: employee.gender,
      shiftIcon: null,
      shiftPreference: null,
      skills: [],
      tags: [],
      title: employee.title,
      workPermitUntil: employee.workPermitExpiration || "",
    };
  }
  private static extractHouseNumber(street: string): {
    street: string;
    houseNumber: string;
  } {
    const match = street.match(/^(.*?)\s+(\d+\s*[a-zA-Z]?)$/);
    return match
      ? { street: match[1].trim(), houseNumber: match[2].trim() }
      : { street: street, houseNumber: "" };
  }
  private static getDefaultMandantUuid(): string {
    const loggedInMandantUuids = store.getters.getLoggedInMandantUuids;
    let mandantUuid = store.getters.reducedMandants[0].uuid;
    if (loggedInMandantUuids[0]) {
      mandantUuid = loggedInMandantUuids[0];
    }
    return mandantUuid;
  }
  private static getDispositionerUuid(): string {
    return `${IdPrefix.dispositioner}${Date.now()}`;
  }
  private static getApplicationId(): string {
    const sixDigitNumber = Math.floor(Date.now() % 1000000);
    const paddedNumber = sixDigitNumber.toString().padStart(6, "0");
    return `${IdPrefix.dispositioner}${paddedNumber}`;
  }
  static parsedCvToCandidate(
    parsedCandidate: CandidateFromCvParser
  ): Candidate {
    // Default application
    const defaultApplication: Application = {
      applicationId: this.getApplicationId(),
      uuid: "",
      workingHours: 0,
      workingHoursUntil: 0,
      workingHoursPeriod: "",
      lastEditorName: "",
      applicationDate: new Date().toISOString(),
      appliedAs: "",
      dataPrivacyDeadline: "",
      dataStorageDeclined: "",
      dataStorageGrantedBy: "",
      inputMedium: "",
      operationalRadius: 0,
      entryFrom: "",
      questions: [],
      salary: 0,
      salaryCurrency: "EUR",
      salaryPeriod: "MONTH",
      mandantUuid: this.getDefaultMandantUuid(),
      source: "",
      ats: {
        statusUuid: "",
        status: "",
        internalStatusSystemName: "",
      },
      status: store.getters.getStatusValueByLinkingStatus(
        LinkingStatus.inboxApplication
      ),
      documentsReturnShipping: false,
      availableUntil: "",
    };

    return {
      uuid: this.getDispositionerUuid(),
      firstName: parsedCandidate.firstName || "",
      lastName: parsedCandidate.lastName || "",
      additionalAddresses: [],
      addressCity: parsedCandidate.addressCity || "",
      addressCountry: "",
      addressHouseNumber: parsedCandidate.addressHouseNumber || "",
      addressPostalCode: parsedCandidate.addressPostalCode || "",
      addressStreet: parsedCandidate.addressStreet || "",
      applications: [defaultApplication],
      avatar: { uuid: "", mimeType: "" },
      birthDate: parsedCandidate.birthDate || "",
      birthName: "",
      birthPlace: parsedCandidate.birthPlace || "",
      careerLevel: [],
      careerSteps: parsedCandidate.careerSteps || [],
      checklist: [],
      communicationMeans: parsedCandidate.communicationMeans || [],
      employeeStatus: null,
      files: [],
      isEmbargoChecked: false,
      isNotInAts: true,
      maritalStatus: null,
      mobility: null,
      mobilityIcon: null,
      mobilityRadius: 0,
      namePrefix: "",
      nameSuffix: "",
      nationality: parsedCandidate.nationality || "",
      notes: { interviewResult: "", information: "" },
      otherFirstNames: "",
      profiles: [],
      residencePermitUntil: "",
      salutationCatalogId: parsedCandidate.salutationCatalogId || 0,
      shiftIcon: null,
      shiftPreference: null,
      skills: parsedCandidate.skills || [],
      tags: [],
      title: parsedCandidate.title || "",
      workPermitUntil: "",
    };
  }
}
