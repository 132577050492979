<template>
  <v-container fluid>
    <v-row class="mt-5" justify="center">
      <v-col cols="12" sm="10" md="7" lg="5" xl="3">
        <h2>Melde dich an – Wir setzen uns mit dir in Verbindung</h2>
        <p>
          Bitte fülle das Formular aus, um mit uns in Kontakt zu treten. Sobald
          wir deine Angaben erhalten haben, prüfen wir diese und melden uns
          schnellstmöglich bei dir.
        </p>
        <br />

        <v-form ref="formRef" v-model="isValid" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="form.firstName"
                label="Vorname"
                :rules="[rules.required]"
                required
                v-bind="vStyle.input"
                data-cy="firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.lastName"
                label="Nachname"
                :rules="[rules.required]"
                required
                v-bind="vStyle.input"
                data-cy="lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.company"
                label="Unternehmen"
                :rules="[rules.required]"
                required
                v-bind="vStyle.input"
                data-cy="company"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.email"
                label="E-Mail"
                :rules="[rules.required, rules.email]"
                required
                v-bind="vStyle.input"
                data-cy="email"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.phone"
                label="Telefon"
                v-bind="vStyle.input"
                data-cy="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <v-checkbox
                v-model="form.whatsapp"
                label="Über WhatsApp erreichbar"
                :true-value="true"
                :false-value="false"
                v-bind="vStyle.input"
                data-cy="whatsapp"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-textarea
            v-model="form.details"
            label="Weitere Informationen (optional)"
            hint="Falls du weitere Informationen mit uns teilen möchtest."
            persistent-hint
            rows="3"
            auto-grow
            v-bind="vStyle.input"
          ></v-textarea>

          <br />
          <div class="d-flex justify-space-between">
            <v-btn color="success" @click="goToHome" data-cy="home">
              Zurück
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!isValid"
              @click="submitForm"
              v-bind="vStyle.btn"
              data-cy="emailSend"
            >
              Absenden
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";
import { VForm } from "vuetify/components";
import { RegistrationService } from "../services/api/registration.service";
import { FirstContactData } from "../models/first-contact-data.model";
import ToastService from "../services/toast.service";

export default defineComponent({
  name: "RegistrationView",
  setup() {
    const { executeRecaptcha } = useReCaptcha() as IReCaptchaComposition;
    const formRef = ref<VForm | null>(null);

    const form = ref<FirstContactData>({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      whatsapp: false,
      details: "",
      recaptcha: "",
    });

    const submitForm = async () => {
      if (formRef.value) {
        const isValid = await formRef.value.validate();
        if (!isValid) return;
      }

      try {
        form.value.recaptcha = await executeRecaptcha("register");
        const registrationService = new RegistrationService();
        const result = await registrationService.registerCompany(form.value);
        if (result?.error === "Invalid reCAPTCHA") {
          ToastService.showError("Ungültiges Recaptcha");
          return;
        } else {
          ToastService.showSuccess("Erfolgreich registriert!");
          form.value = {
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            recaptcha: "",
            phone: "",
            whatsapp: false,
            details: "",
          };
        }
        console.log("Gesendete Daten:", form.value);
      } catch (error) {
        console.error("ReCaptcha error:", error);
        alert("ReCaptcha Fehler. Bitte versuchen Sie es erneut.");
      }
    };

    return {
      formRef,
      form,
      submitForm,
    };
  },
  data() {
    return {
      isValid: false,
      rules: {
        required: (value: any) => !!value || "Pflichtfeld",
        email: (value: string) =>
          /.+@.+\..+/.test(value as string) ||
          "Bitte eine gültige E-Mail eingeben",
      },
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
});
</script>
