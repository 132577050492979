import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "main-container" }
const _hoisted_4 = {
  class: "editor-container editor-container_classic-editor",
  ref: "editorContainerElement"
}
const _hoisted_5 = { class: "editor-container__editor" }
const _hoisted_6 = { ref: "editorElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("fa-solid fa-user")
            ])),
            _: 1
          }),
          _cache[5] || (_cache[5] = _createTextVNode(" Prompts: Benutzerebene "))
        ]),
        (_ctx.user?.config?.aiData)
          ? (_openBlock(), _createBlock(_component_v_form, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.user.config.aiData.whatsAppStyle,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.config.aiData.whatsAppStyle) = $event)),
                          label: "Stil bzw. Beispiele für WhatsApp Nachrichten",
                          rows: "7",
                          class: "mb-4"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.user.config.aiData.mailStyle,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.config.aiData.mailStyle) = $event)),
                          label: "Stil bzw. Beispiele für Emails",
                          rows: "7",
                          class: "mb-4"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.user.config.aiData.profileStyle,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.config.aiData.profileStyle) = $event)),
                          label: "Stil für Kandidatenprofile",
                          rows: "7",
                          class: "mb-4"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", _hoisted_2, [
                          _createVNode(_component_v_icon, {
                            size: "small",
                            class: "mr-2"
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("fa-solid fa-signature")
                            ])),
                            _: 1
                          }),
                          _cache[7] || (_cache[7] = _createTextVNode(" Signatur E-Mail "))
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, [
                                (_ctx.isLayoutReady)
                                  ? (_openBlock(), _createBlock(_component_ckeditor, {
                                      key: 0,
                                      modelValue: _ctx.user.config.aiData.mailSignature,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.config.aiData.mailSignature) = $event)),
                                      editor: _ctx.editor,
                                      config: _ctx.config
                                    }, null, 8, ["modelValue", "editor", "config"]))
                                  : _createCommentVNode("", true)
                              ], 512)
                            ])
                          ], 512)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.updateUserConfig
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}