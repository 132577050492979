<!--src/components/dispostion/elements/Timeline.vue-->
<template>
  <v-container>
    <v-btn v-bind="vStyle.btn" color="primary" @click="showModal = true"
      >Historie hinzufügen</v-btn
    >
    <v-timeline side="end" density="compact">
      <v-timeline-item
        v-for="(entry, index) in timelines"
        :key="index"
        color="primary"
        fill-dot
      >
        <template v-slot:icon>
          <span style="color: var(--color-on-primary)"
            ><i class="fa-solid fa-comment-dots"></i
          ></span>
        </template>
        <v-card v-bind="vStyle.card" class="elevation-3">
          <v-card-title>{{ entry?.entryType }}</v-card-title>
          <v-card-subtitle>{{
            formatEntryDate(entry?.entryDate)
          }}</v-card-subtitle>
          <v-card-text v-html="formatNoteText(entry?.note)"> </v-card-text>
          <v-card-text>
            <v-chip class="ma-2" color="primary" label small>{{
              entry?.user
            }}</v-chip>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <!-- Modal for adding a timeline entry -->
    <BaseDialog
      :showDialog="showModal"
      :showActions="true"
      @closeDialog="closeModal"
      :width="'var(--d-w-m)'"
    >
      <template #title>Eintrag hinzufügen</template>
      <template #content>
        <v-card-text>
          <v-select
            v-bind="vStyle.input"
            v-model="selectedEntryType"
            :items="timelineEntryOptions"
            label="Eintragstyp"
          ></v-select>
          <v-textarea
            v-bind="vStyle.input"
            v-model="noteText"
            label="Notiz"
            rows="4"
          ></v-textarea>
        </v-card-text>
      </template>
      <template #actions>
        <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitEntry">Dokumentieren</v-btn>
      </template>
    </BaseDialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { TimelineService } from "@/services/api/timeline.service";
import { Timeline } from "@/models/timeline.model";
import moment from "moment";
import { Customer } from "@/models/customer.model";
import { CandidateList } from "@/models/candidate-list.model";
import ToastService from "@/services/toast.service";
import { Employee } from "@/models/employee.model";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default defineComponent({
  name: "TimelineComponent",
  components: {
    BaseDialog,
  },
  props: {
    candidate: {
      type: Object as () => CandidateList | null,
      default: null,
    },
    candidateName: {
      type: String,
      default: "",
    },
    customer: {
      type: Object as () => Customer | null,
      default: null,
    },
    employee: {
      type: Object as () => Employee | null,
      default: null,
    },
    contact: String,
    mandant: String,
  },
  computed: {
    ...mapGetters(["company"]),
    timelineEntryTypes() {
      return this.company.timelineEntryTypes;
    },
    timelineEntryOptions() {
      const types = this.timelineEntryTypes;
      return [
        types.note.description,
        types.addition.description,
        types.meetingInhouse.description,
        types.meetingOutside.description,
      ];
    },
  },
  data() {
    return {
      timelines: [] as Timeline[],
      showModal: false,
      noteText: "",
      selectedEntryType: "",
      timelineService: new TimelineService(),
      timelineHelperService: new TimelineHelperService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    formatEntryDate(entryDate: string) {
      if (!entryDate) return "";
      const dateEntry = moment(entryDate).format("DD.MM.y");
      const timeEntry = moment(entryDate).format("HH:mm");
      return `${dateEntry} um ${timeEntry}`;
    },
    formatNoteText(note: string) {
      if (!note) return "";
      return note.replace(/\n/g, "<br>");
    },
    closeModal() {
      this.showModal = false;
    },

    getTimeline() {
      if (this.candidate) {
        this.getTimelineCandidate();
      } else if (this.customer && this.customer._id) {
        this.getTimelineCustomer();
      } else if (this.employee && this.employee._id) {
        this.getTimelineEmployee();
      }
    },

    async getTimelineCandidate() {
      const uuid = this.candidate?.candidateUuid as string;
      const timeLineArray = await this.timelineService.getTimelineByUuid(uuid);
      if (timeLineArray) this.timelines = timeLineArray.toReversed();
    },

    async getTimelineCustomer() {
      const customerId = this.customer?._id as string;
      const timeLineArray = await this.timelineService.getTimelineByCustomerId(
        customerId
      );
      if (timeLineArray) this.timelines = timeLineArray.toReversed();
    },

    async getTimelineEmployee() {
      const employeeId = this.employee?._id as string;
      const timeLineArray = await this.timelineService.getTimelineByEmployeeId(
        employeeId
      );
      if (timeLineArray) this.timelines = timeLineArray.toReversed();
    },

    async submitEntry() {
      if (!this.noteText.trim()) {
        ToastService.showError("Bitte erst Notiz eingeben!");
        return;
      }
      let participant = "";
      if (this.candidate) {
        participant = this.candidateName;
      } else if (this.employee) {
        participant = `${this.employee?.firstName} ${this.employee.lastName}`;
      }
      try {
        await this.timelineHelperService.timelineAutoDocu({
          note: this.noteText,
          entryType: this.selectedEntryType,
          mandant: this.customer?.mandants[0] ?? this.mandant,
          customer: {
            name: this.customer?.generalData?.name,
            contact: this.contact || "Kein Ansprechpartner angegeben",
            customerId: this.customer?._id || "",
          },
          participant: {
            name: participant,
            uuid: this.candidate?.candidateUuid || "",
            employeeId: this.employee?._id,
          },
        });
        this.getTimeline(); // Refresh the timeline view
      } catch (error) {
        console.error("Error submitting timeline entry:", error);
      }
      this.closeModal();
    },
  },
  mounted() {
    this.getTimeline();
    this.selectedEntryType = this.timelineEntryTypes.note.description;
  },
});
</script>
