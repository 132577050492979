import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_scroll_y_transition = _resolveComponent("v-scroll-y-transition")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        icon: "",
        variant: "text",
        onClick: _ctx.toggleFilters
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { size: "xlarge" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.showFilters
            ? "fa-solid fa-arrow-up filter-icon filter-close"
            : "fa-solid fa-sliders filter-icon"), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_scroll_y_transition, {
        class: _normalizeClass(["filter-menu", 
        _ctx.layout === _ctx.AppLayout.classic || _ctx.layout === _ctx.AppLayout.persontobusiness
          ? 'filter-menu-top-classic'
          : 'filter-menu-top'
      ])
      }, {
        default: _withCtx(() => [
          (_ctx.showFilters)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "rounded-b-lg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "d-flex" }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createTextVNode(" Filtern nach ... ")),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        onClick: _ctx.toggleFilters
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("fa-solid fa-xmark")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "mt-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "6",
                            class: "pl-5 pt-2 pb-2"
                          }, {
                            default: _withCtx(() => [
                              _renderSlot(_ctx.$slots, "filter-menu-left-column", {}, undefined, true)
                            ]),
                            _: 3
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "6",
                            class: "pr-5 pt-2 pb-2"
                          }, {
                            default: _withCtx(() => [
                              _renderSlot(_ctx.$slots, "filter-menu-right-column", {}, undefined, true)
                            ]),
                            _: 3
                          })
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }))
}