<!--src/components/dispostion/AppointmentCalendar.vue-->
<template>
  <v-select
    v-bind="vStyle.input"
    density="compact"
    v-model="selectedStatuses"
    width="32rem"
    :items="statusOptions"
    item-title="label"
    item-value="value"
    multiple
    label="Terminarten filtern"
    outlined
    dense
    clearable
    class="mt-5"
    @update:model-value="fetchAppointments()"
  ></v-select>

  <vue-cal
    locale="de"
    :time-from="6 * 60"
    :time-to="20 * 60"
    :time-step="60"
    :events="filteredAppointments"
    view="week"
    hide-view-selector
    hide-weekends
    @event-click="eventClicked"
  >
  </vue-cal>
  <BaseDialog
    :showDialog="showCandidateItem"
    :hideTitle="true"
    @closeDialog="showCandidateItem = false"
    :width="'var(--d-w-l)'"
  >
    <template #content>
      <CandidateItem
        :candidate="clickedCandidate"
        :candidateDataInput="clickedCandidateData"
        :interComponentMessage="interComponentMessage"
        :isActive="false"
        :isFullyCollapsed="false"
        :softwareIntegration="softwareIntegration"
        :lastUpdateTimeline="lastUpdateTimeline"
        :user="user"
        @closeDialog="showCandidateItem = false"
      />
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueCal from "vue-cal";
import { CalendarObject } from "@/models/linking.model";
import { mapGetters } from "vuex";
import { LinkingStatus } from "@/enums/dependency.enum";
import { SpinnerService } from "@/services/spinner.service";
import { CandidateService } from "../../services/api/candidate.service";
import { CandidateList } from "../../models/candidate-list.model";
import { Candidate } from "../../models/candidate.model";
import CandidateItem from "./CandidateItem.vue";
import { useDisplay } from "vuetify";
import ToastService from "../../services/toast.service";
import { CandidateDataHelper } from "../../helper/candidate-data.helper";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

interface EventOption {
  eventName: string;
  candidateStatus?: LinkingStatus;
}

export default defineComponent({
  name: "AppointmentCalendar",
  components: {
    BaseDialog,
    CandidateItem,
    VueCal,
  },
  props: {
    selectedDatesTimes: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      appointments: [] as CalendarObject[],
      clickedCandidate: {} as CandidateList,
      clickedCandidateData: {} as Candidate,
      selectedStatuses: [] as string[],
      showCandidateItem: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters([
      "company",
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
      "followUpEvents",
      "getLoggedInMandantUuids",
      "softwareIntegration",
      "user",
      "lastUpdateTimeline",
      "interComponentMessage",
    ]),
    eventMap(): Record<string, LinkingStatus | undefined> {
      const allEvents: EventOption[] = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ];
      const eventStatusMap: Record<string, LinkingStatus | undefined> = {};
      allEvents.forEach((event) => {
        if (event.candidateStatus) {
          eventStatusMap[event.eventName] = event.candidateStatus;
        }
      });
      return eventStatusMap;
    },
    displayStatuses(): Set<LinkingStatus> {
      return new Set([
        LinkingStatus.contractDate,
        LinkingStatus.interviewSuggestionExternal,
        LinkingStatus.trailWorkSuggestionExternal,
        LinkingStatus.interviewExternal,
        LinkingStatus.trailWorkExternal,
        LinkingStatus.interview,
        LinkingStatus.followUp,
        LinkingStatus.followUpPrio,
      ]);
    },
    statusOptions() {
      const statuses = [
        LinkingStatus.contractDate,
        LinkingStatus.interviewSuggestionExternal,
        LinkingStatus.trailWorkSuggestionExternal,
        LinkingStatus.interviewExternal,
        LinkingStatus.trailWorkExternal,
        LinkingStatus.interview,
        LinkingStatus.followUp,
        LinkingStatus.followUpPrio,
      ];
      return statuses.map((status) => ({
        label: this.getStatusLabel(status),
        value: status,
      }));
    },
    filteredAppointments() {
      return this.appointments.filter((appointment) => {
        const status = appointment.class;
        return (
          !status ||
          this.selectedStatuses.length === 0 ||
          this.selectedStatuses.includes(status.toString())
        );
      });
    },
  },
  watch: {
    selectedDatesTimes: {
      deep: true,
      handler() {
        this.addSelectedDatesToCalendar();
      },
    },
    getLoggedInMandantUuids: {
      immediate: false,
      handler() {
        this.fetchAppointments();
      },
    },
    lastUpdateTimeline() {
      this.fetchAppointments();
    },
  },
  mounted() {
    this.fetchAppointments();
  },
  methods: {
    async fetchAppointments() {
      try {
        SpinnerService.showSpinner();

        const fetchedAppointments =
          this.$store.getters.getLinkingAppointmentsForCalendar;

        this.appointments = fetchedAppointments
          .filter((appointment: CalendarObject) => {
            const status = appointment.class
              ? this.eventMap[appointment.class]
              : undefined;
            return status && this.displayStatuses.has(status);
          })
          .map((appointment: CalendarObject) => {
            const candidateId = appointment.candidateId;
            const startDate = new Date(appointment.start);
            const endDate = new Date(appointment.end);
            const candidateStatus = this.getStatusForClass(
              appointment.class ?? ""
            );

            return {
              start: startDate,
              end: endDate,
              title: appointment.title,
              class: candidateStatus,
              content: candidateId,
            };
          });
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },

    getStatusForClass(className: string) {
      const allEvents = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ];
      const event = allEvents.find((event) => event.eventName === className);
      return event ? event.candidateStatus : undefined;
    },
    getStatusLabel(status: string) {
      const labels: Record<string, string> = {
        contractDate: "Vertragsdatum",
        interviewSuggestionExternal: "Vorschlag Vorstellungstermin",
        trailWorkSuggestionExternal: "Vorschlag Probearbeit",
        interviewExternal: "Vorstellungstermin (Kunde)",
        trailWorkExternal: "Probearbeiten",
        interview: "Vorstellungstermin in der Geschäftsstelle",
        followUp: "Wiedervorlage",
        followUpPrio: "priorisierte Wiedervorlage",
      };
      return labels[status] || "Unbekannter Status";
    },
    addSelectedDatesToCalendar() {
      this.appointments = this.appointments.filter(
        (appointment) => appointment.class !== "Placeholder"
      );
      this.selectedDatesTimes.forEach((dateTime) => {
        const startDate = new Date(dateTime);
        const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

        this.appointments.push({
          start: startDate,
          end: endDate,
          title: "Neuer Termin",
          class: "Placeholder",
        });
      });
    },
    async eventClicked(event: any) {
      if (event.content) {
        const candidateId = event.content as string;
        this.openCandidate(candidateId);
      }
    },
    async openCandidate(candidateId: string) {
      try {
        SpinnerService.showSpinner();
        const candidateService = new CandidateService();
        const candidateData = (await candidateService.getCandidateById(
          candidateId
        )) as Candidate;
        this.clickedCandidateData = candidateData;
        const applicationsIndex = candidateData.applications.length - 1;

        this.clickedCandidate =
          CandidateDataHelper.candidateToReducedCandidate(
            candidateData,
            applicationsIndex
          ) ?? ({} as CandidateList);
        this.showCandidateItem = true;
      } catch (error) {
        ToastService.showError("Fehler beim Laden des Kandidaten");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
  },
});
</script>
