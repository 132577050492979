import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "ml-x font-weight-light text-caption text-medium-emphasis" }
const _hoisted_2 = { class: "pa-0 text-body-2" }
const _hoisted_3 = { class: "ml-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_5 = { style: {"font-size":"0.7rem"} }
const _hoisted_6 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_GeneralItem = _resolveComponent("GeneralItem")!

  return (_openBlock(), _createBlock(_component_GeneralItem, {
    ref: "generalItemComponent",
    color: _ctx.item.color,
    dragOverIcon: _ctx.dragOver.icon,
    dragOverText: _ctx.dragOver.text,
    hoverColor: _ctx.item.hoverColor,
    isFullyCollapsed: _ctx.isFullyCollapsed,
    isAllSlotsActive: true,
    parentRole: _ctx.Role.demand,
    showMatchIcon: _ctx.candidatesTagsMatching,
    onDragOver: _ctx.handleDragOver,
    onDragStart: _ctx.handleDragStart,
    onDrop: _ctx.handleDrop,
    onIsExpanded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkIfShouldGenerateTags())),
    onOpenContextMenu: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit(_ctx.DemandItemEmits.openContextMenu)))
  }, {
    "collapsed-view": _withCtx(() => [
      _createVNode(_component_v_sheet, {
        class: "ma-0 pt-1 px-1",
        color: _ctx.item.colorList
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex pa-0" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDemandStateInfo(_ctx.demand?.demandState as DemandState).label), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("i", {
                  class: _normalizeClass(`mr-1 fa-solid ${
                    _ctx.getDemandStateInfo(_ctx.demand?.demandState as DemandState).icon
                  }`)
                }, null, 2)
              ]),
              _createElementVNode("span", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.demand?.demands[0]?.quantity) + "x " + _toDisplayString(_ctx.demand?.demands[0]?.position) + " ", 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.demand?.customer), 1)
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.demand?.demands[0]?.location?.city), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, { class: "mt-1" })
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    "item-collapsed": _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "pa-0 text-body-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.demand?.demands[0]?.quantity) + "x ", 1),
          _createElementVNode("strong", null, _toDisplayString(_ctx.demand?.demands[0]?.position), 1),
          _createElementVNode("span", _hoisted_5, " (" + _toDisplayString(_ctx.demand?.demands[0]?.location?.postcode) + " " + _toDisplayString(_ctx.demand?.demands[0]?.location?.city) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.demand?.customer), 1)
        ]),
        _: 1
      })
    ]),
    "bottom-right-icon": _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getDemandStateInfo(_ctx.demand?.demandState as DemandState).label), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_icon, {
          class: "text-medium-emphasis",
          size: "x-small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getDemandStateInfo(_ctx.demand?.demandState as DemandState).icon), 1)
          ]),
          _: 1
        })
      ])
    ]),
    "communication-icons-container": _withCtx(() => [
      (_ctx.hasLinking(_ctx.demand._id))
        ? (_openBlock(), _createBlock(_component_Dependencies, {
            key: 0,
            ref: "dependenciesComponent",
            demand_id: _ctx.demand._id
          }, null, 8, ["demand_id"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_container, { class: "ma-0 px-0 d-flex" }, {
        default: _withCtx(() => [
          (
            _ctx.demand?.demandContactPerson?.firstName ||
            _ctx.demand?.demandContactPerson?.lastName
          )
            ? (_openBlock(), _createBlock(_component_v_chip, {
                key: 0,
                "prepend-icon": "fa-solid fa-address-book",
                class: "text-body-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.demand?.demandContactPerson?.firstName) + " " + _toDisplayString(_ctx.demand?.demandContactPerson?.lastName) + " ", 1),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.demand?.demandContactPerson?.position), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer),
          (_ctx.demand?.demandContactPerson?.contactDetails?.phoneNumber)
            ? (_openBlock(), _createBlock(_component_PhoneClient, {
                key: 1,
                phoneNumbers: _ctx.filteredPhoneNumbers
              }, null, 8, ["phoneNumbers"]))
            : _createCommentVNode("", true),
          (_ctx.demand?.demandContactPerson?.contactDetails?.email)
            ? (_openBlock(), _createBlock(_component_MailClient, {
                key: 2,
                emailAddresses: _ctx.filteredEmail,
                AiMessageType: _ctx.AiMessageType?.mailCustomer,
                emailObject: _ctx.email,
                signature: _ctx.signatureText(),
                onEditContacts: _ctx.editContact
              }, null, 8, ["emailAddresses", "AiMessageType", "emailObject", "signature", "onEditContacts"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "tags-container": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demand.tags, (tag, index) => {
        return (_openBlock(), _createBlock(_component_v_chip, {
          key: index,
          class: "dispatcher-board-icon-tag",
          color: "primary",
          "onClick:close": ($event: any) => (_ctx.removeTag(index))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tag) + " ", 1),
            _createVNode(_component_v_icon, {
              small: "",
              onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
              class: "fa fa-times"
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["onClick:close"]))
      }), 128)),
      _createVNode(_component_v_text_field, {
        variant: "outlined",
        class: "mt-2",
        label: "Neuer Tag",
        style: {"max-width":"10rem"},
        rounded: "lg",
        density: "compact",
        modelValue: _ctx.newTag,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTag) = $event)),
        onKeyup: _withKeys(_ctx.addTag, ["enter"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onKeyup"]),
      _createVNode(_component_v_btn, {
        icon: "",
        class: "mt-3",
        variant: "text",
        density: "compact",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.generateAndSetDemandTags()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { size: "small" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("fas fa-arrows-rotate")
            ])),
            _: 1
          }),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Tags anhand der Anfragen neu generieren")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    "info-container": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demand.demands, (detail, index) => {
        return (_openBlock(), _createBlock(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, {
          class: "mb-4",
          key: index
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(detail.quantity) + "x " + _toDisplayString(detail.position), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_chip, {
              color: "primary",
              density: "compact",
              class: "ml-4 mt-2",
              "prepend-icon": "fa-solid fa-location-dot"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(detail?.location?.postcode) + " " + _toDisplayString(detail?.location?.city), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_chip, {
              color: "var(--color-tertiary)",
              density: "compact",
              class: "ml-4 mt-2",
              "prepend-icon": "fa-solid fa-calendar-xmark"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatApplicationDeadline(detail?.applicationDeadline)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_chip, {
              color: "var(--color-success)",
              density: "compact",
              class: "ml-4 mt-2",
              "prepend-icon": "fa-solid fa-coins"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(detail?.budget?.amount) + " " + _toDisplayString(detail?.budget?.currency), 1)
              ]),
              _: 2
            }, 1024),
            (detail?.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _cache[7] || (_cache[7] = _createTextVNode("Beschreibung ")),
                      _createVNode(_component_v_divider)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(detail?.description), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true),
            (detail?.additionalInformation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createTextVNode("Weitere Informationen ")),
                      _createVNode(_component_v_divider)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(detail?.additionalInformation), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true),
            (detail?.requirements?.length > 0)
              ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 2 }, {
                  default: _withCtx(() => [
                    _cache[9] || (_cache[9] = _createTextVNode("Vorraussetzungen ")),
                    _createVNode(_component_v_divider)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_container, { class: "mx-1" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detail.requirements, (requirement, rIndex) => {
                  return (_openBlock(), _createBlock(_component_v_chip, {
                    key: `detail-${index}-requirement-${rIndex}`,
                    density: "compact",
                    class: "ma-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(requirement), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040))
      }), 128))
    ]),
    _: 1
  }, 8, ["color", "dragOverIcon", "dragOverText", "hoverColor", "isFullyCollapsed", "parentRole", "showMatchIcon", "onDragOver", "onDragStart", "onDrop"]))
}