import { Skill } from "../models/candidate.model";

export class ArrayUtils {
  /**
   * Removes duplicate values from an array of strings.
   * @param array - An array of strings that may contain duplicates
   * @returns An array without duplicates
   */
  static removeDuplicates(array: string[]): string[] {
    return Array.from(new Set(array));
  }

  /**
   * Removes duplicates in specified properties of an object.
   * @param object - The object in which specified properties will be deduplicated
   * @param properties - The list of properties whose values should be deduplicated
   */
  static deduplicateProperties(object: any, properties: string[]): void {
    properties.forEach((prop) => {
      if (object[prop]) {
        object[prop] = ArrayUtils.removeDuplicates(object[prop]);
      }
    });
  }
  static getUniqueSkillsDescriptionsArray(skills: Skill[]): string[] {
    const uniqueSkills: string[] = [];
    const seen = new Set<string>();

    for (const skill of skills) {
      const lowerSkill = skill.description.trim().toLowerCase();
      if (!seen.has(lowerSkill)) {
        seen.add(lowerSkill);
        uniqueSkills.push(skill.description.trim());
      }
    }
    return uniqueSkills;
  }
}
