<template>
  <v-btn
    :variant="variant ?? undefined"
    :width="width ?? undefined"
    :height="height ?? undefined"
    @click="login"
    v-bind="vStyle?.btn"
    color="primary"
  >
    <v-icon class="mr-2" icon="fa-brands fa-windows" />
    {{ isExistingAccessToken ? "Email entfernen" : "Microsoft Office365" }}
  </v-btn>
</template>

<script lang="ts">
import { PropType } from "vue";
import { defineComponent } from "vue";
import { EmailScope } from "@/enums/email-scope.enum";
import ToastService from "@/services/toast.service";
import { Office365Service } from "@/services/api/office365.service";
import { ApiResponse } from "@/models/api-response.model";

enum OfficeIntBtnEmit {
  logout = "logout",
}

export default defineComponent({
  name: "OfficeIntBtn",
  emits: [OfficeIntBtnEmit.logout],
  props: {
    isExistingAccessToken: Boolean,
    emailScope: {
      type: Number as PropType<EmailScope>,
      default: EmailScope.user,
    },
    mandantUuid: String,
    variant: {
      type: String as PropType<string>,
      required: false,
    },
    height: {
      type: Number as PropType<number>,
      required: false,
    },
    width: {
      type: Number as PropType<number>,
      required: false,
    },
  },
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    login() {
      if (this.isExistingAccessToken) {
        const office365Service = new Office365Service();
        if (this.mandantUuid) {
          office365Service
            .office365Logout(this.emailScope.toString(), this.mandantUuid)
            .then((apiResponse: ApiResponse) => {
              if (apiResponse.response === "Logout successful") {
                this.$emit(OfficeIntBtnEmit.logout);
              }
            });
        } else {
          office365Service
            .office365Logout(this.emailScope.toString())
            .then((apiResponse: ApiResponse) => {
              if (apiResponse.response === "Logout successful") {
                this.$emit(OfficeIntBtnEmit.logout);
              }
            });
        }
      } else {
        try {
          window.location.href = this.getHref(this.emailScope) as string;
        } catch (error) {
          ToastService.showError("Fehler beim Office365 Login");
        }
      }
    },
    getHref(emailScope: EmailScope) {
      let uri = "";
      const backendUrl = this.$store.getters.getEnv.VUE_APP_BACKEND_URI;

      switch (emailScope) {
        case EmailScope.company:
          uri = "?emailScope=company";
          break;
        case EmailScope.mandant:
          uri = `?emailScope=mandant&mandantUuid=${this.mandantUuid}`;
          break;
        default:
          uri = "?emailScope=user";
      }
      return `${backendUrl}/office365/login${uri}`;
    },
  },
});
</script>
