<!-- src/components/whatsapp/WhatsAppMessenger.vue -->
<template>
  <v-form class="mt-2" ref="form" @submit.prevent="sendMessage">
    <ChatMessages
      v-if="showChatMessages"
      :messages="chatMessages"
      :wabNumber="wabNumber"
    />
    <v-textarea
      v-bind="vStyle.input"
      v-model="localMessage"
      label="Nachricht"
      density="compact"
      :rows="softwareIntegration.whatsApp ? 6 : 8"
      :rules="[requiredRule]"
    ></v-textarea>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ChatMessages from "../whatsapp/ChatMessages.vue";
import { WhatsAppService } from "@/services/api/whatsApp.service";
import { mapGetters } from "vuex";
import { WabMessage } from "../../models/wab/wab-chat-message-model";
import { SpinnerService } from "../../services/spinner.service";
import ToastService from "../../services/toast.service";
import { ApiResponse } from "../../models/api-response.model";

export default defineComponent({
  name: "SendMessages",
  emits: ["chatsEmpty", "checkTimeline", "closeDialog"],
  components: {
    ChatMessages,
  },
  props: {
    showChatMessages: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    wabNumber: {
      type: String as PropType<string>,
      default: "",
    },
    message: {
      type: String as PropType<string>,
    },
    username: {
      type: String as PropType<string>,
    },
    mandantUuid: {
      type: String as PropType<string>,
    },
    selectedMandant: {
      type: String as PropType<string>,
    },
    sendMessageEvent: {
      type: Boolean as PropType<boolean>,
    },
    whatsAppConfig: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    return {
      chatMessages: [],
      form: null as any,
      localMessage: this.message,
      phoneId: "",
      vStyle: this.$store.state.vStyle,
      whatsAppService: new WhatsAppService(),
    };
  },
  mounted() {
    this.getChatMessages(this.wabNumber);
  },
  computed: {
    ...mapGetters({
      loggedInMandantBranchNumbers: "getLoggedInMandantBranchNumbers",
      softwareIntegration: "softwareIntegration",
    }),
  },
  watch: {
    sendMessageEvent(newVal: string) {
      if (newVal) this.sendMessage();
    },

    message(newVal: string) {
      this.localMessage = newVal;
    },

    wabNumber(newValue: string) {
      this.getChatMessages(newValue);
    },
  },

  methods: {
    getChatMessages(wabNumber: string) {
      if (!this.softwareIntegration.whatsApp) return;
      try {
        SpinnerService.showSpinner();
        this.whatsAppService
          .getWhatsAppBusinessChats(
            wabNumber,
            this.$store.state.company.loggedInUser.config.loggedInMandants
          )
          .then((chat: ApiResponse) => {
            if (chat.response && chat.response.length > 0) {
              this.chatMessages = chat.response[0].messages;
              this.phoneId = chat.response[0].phoneId;
              if (this.chatMessages && this.chatMessages.length === 0) {
                this.$emit("chatsEmpty", true);
              } else {
                this.$emit("chatsEmpty", false);
              }
            } else {
              this.$emit("chatsEmpty", true);
            }
          })
          .catch(() => {
            ToastService.showError("Fehler beim Laden der Nachrichten:");
          });
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    requiredRule(value: any) {
      return !!value || "Dieses Feld ist erforderlich";
    },
    sendByWhatsAppMeLink() {
      if (this.message) {
        const whatsappUrl = `https://wa.me/${
          this.wabNumber
        }?text=${encodeURIComponent(this.message)}`;
        window.open(whatsappUrl, "_blank");
      }
    },
    async sendMessage() {
      const isValid =
        this.$refs.form && (await (this.$refs.form as any).validate());
      if (isValid.valid) {
        switch (this.whatsAppConfig) {
          case false:
            this.sendByWhatsAppMeLink();
            break;
          case true:
            this.sendWabMessage();
        }
        this.$emit("closeDialog", this.localMessage);
      }
    },
    async sendWabMessage() {
      const wabMessage: WabMessage = {
        message: this.localMessage as string,
        mandantUuid: this.selectedMandant ?? "",
        phoneId: this.phoneId,
        to: this.wabNumber,
      };
      if (this.$store.state.company.softwareIntegration.whatsApp) {
        try {
          if (this.selectedMandant) {
            await this.whatsAppService.sendWhatsAppBusinessMessage(wabMessage);
          } else {
            ToastService.showError("Es wurde kein Mandant angegeben");
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
});
</script>
