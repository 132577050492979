import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    "show-dialog": _ctx.showDialog,
    "show-actions": true,
    width: 'var(--d-w-xs)',
    onCloseDialog: _ctx.closeDialog
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Ergebnisse aus ATS: ")
    ])),
    content: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultsCandidateSearch, (result, index) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: index,
              onClick: ($event: any) => (_ctx.selectCandidateSearchResult(result))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(result.firstName) + " " + _toDisplayString(result.lastName), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_list_item_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(result.addressStreet) + " " + _toDisplayString(result.addressHouseNumber) + ", " + _toDisplayString(result.addressPostalCode) + " " + _toDisplayString(result.addressCity), 1)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "abort",
        onClick: _ctx.closeDialog
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Abbrechen ")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["show-dialog", "onCloseDialog"]))
}