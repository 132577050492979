<template>
  <BaseDialog
    :show-dialog="showDialog"
    :width="'var(--d-w-l)'"
    @closeDialog="closeDialog"
  >
    <template #title
      >Es existiert bereits ein Kandidat mit diesen dieser externen Id</template
    >
    <template #content>
      <v-card-text>
        <v-icon class="text-medium-emphasis mr-1"
          >fa-solid fa-circle-question</v-icon
        >
        <span class="text-body-2">
          Die Kandidaten werden zusammengeführt. Lebenslaufdaten und Skills
          bleiben von beiden erhalten. Von welchem Datensatz sollen die
          restlichen Daten (Name, Adresse, etc.) übernommen werden?
        </span>
      </v-card-text>
      <v-row class="pa-4">
        <v-col cols="6">
          <v-card
            v-if="candidateExisting"
            v-bind="vStyle?.card"
            class="pa-4 h-100 candidate-card"
            hover
            @click="selectExisting()"
          >
            <v-card-title
              style="color: var(--color-primary)"
              class="text-h6 mb-2"
            >
              aus Datenbank
            </v-card-title>
            <v-card-text>
              <div class="text-body-1">
                {{ candidateExisting?.firstName }}
                {{ candidateExisting?.lastName }}
              </div>
              <div class="text-body-1 mb-2">
                {{ candidateExisting?.addressStreet }}
                {{ candidateExisting?.addressHouseNumber }}
              </div>
              <div class="text-body-1 mb-2">
                {{ candidateExisting?.addressPostalCode }}
                {{ candidateExisting?.addressCity }}
              </div>
              <div class="text-body-2 text-medium-emphasis">
                Geburtsdatum:
                {{ FormatDate.germanStandard(candidateExisting?.birthDate) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            v-if="candidateCurrent"
            v-bind="vStyle?.card"
            class="pa-4 h-100 candidate-card"
            hover
            @click="selectCurrent()"
          >
            <v-card-title
              style="color: var(--color-secondary)"
              class="text-h6 mb-2"
            >
              Aktuell
            </v-card-title>
            <v-card-text>
              <div class="text-body-1">
                {{ candidateCurrent?.firstName }}
                {{ candidateCurrent?.lastName }}
              </div>
              <div class="text-body-1 mb-2">
                {{ candidateCurrent?.addressStreet }}
                {{ candidateCurrent?.addressHouseNumber }}
              </div>
              <div class="text-body-1 mb-2">
                {{ candidateCurrent?.addressPostalCode }}
                {{ candidateCurrent?.addressCity }}
              </div>
              <div class="text-body-2 text-medium-emphasis">
                Geburtsdatum:
                {{ FormatDate.germanStandard(candidateCurrent?.birthDate) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" color="error" @click="closeDialog">
          Abbrechen
        </v-btn>
      </v-card-actions>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Candidate } from "../../../../models/candidate.model";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { FormatDate } from "../../../../helper/format-date.helper";

enum DialogCompareCandidatesEmits {
  closeDialog = "closeDialog",
  selectExisting = "selectExisting",
  selectCurrent = "selectCurrent",
}

export default defineComponent({
  name: "DialogCompareCandidates",
  components: {
    BaseDialog,
  },
  emits: [
    DialogCompareCandidatesEmits.closeDialog,
    DialogCompareCandidatesEmits.selectExisting,
    DialogCompareCandidatesEmits.selectCurrent,
  ],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    candidateExisting: {
      type: Object as PropType<Candidate | undefined>,
      required: true,
    },
    candidateCurrent: {
      type: Object as PropType<Candidate | undefined>,
      required: true,
    },
  },
  data() {
    return {
      FormatDate: FormatDate,
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    closeDialog() {
      this.$emit(DialogCompareCandidatesEmits.closeDialog);
    },
    selectExisting() {
      this.$emit(DialogCompareCandidatesEmits.selectExisting);
      this.closeDialog();
    },
    selectCurrent() {
      this.$emit(DialogCompareCandidatesEmits.selectCurrent);
      this.closeDialog();
    },
  },
});
</script>

<style scoped>
.candidate-card {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.candidate-card:hover {
  transform: scale(1.02);
}
</style>
