<template>
  <v-card
    style="position: fixed; bottom: 0.5rem; right: 0.5rem; width: 12rem"
    v-bind="vStyle.card"
    variant="flat"
    class="pa-2 d-flex justify-space-around"
  >
    <v-btn
      v-bind="vStyle.btn"
      type="submit"
      color="success"
      @click="submit"
      block
      >Speichern<v-icon class="ml-2">fa-solid fa-floppy-disk</v-icon></v-btn
    >
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum SaveButtonEmit {
  save = "save",
}
export default defineComponent({
  name: "ConfigSaveButton",
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    submit() {
      this.$emit(SaveButtonEmit.save);
    },
  },
});
</script>
