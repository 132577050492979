<template>
  <div
    class="column"
    @drop="handleDrop"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent
  >
    <div
      v-if="!isActive"
      :class="
        mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      "
    ></div>
    <CvDropField
      :showDropField="showDropField"
      :parentRole="Role.demand"
      :fieldText="dropFieldText"
      @handleDrop="handleDrop"
    />
    <div v-show="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="status-options">
              <v-checkbox
                density="compact"
                v-for="(label, key) in demandStates"
                :key="key"
                v-model="selectedStatuses"
                :label="label"
                :value="String(key)"
                class="ma-0 pa-0"
                @update:model-value="updateStatusFilter(selectedStatuses)"
              ></v-checkbox>
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
                class="ma-0 pa-0"
                @update:model-value="updateMandantsFilter(selectedMandants)"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <div>
        <DialogAddEditDemand
          :modalMode="modalAddOrEditMode"
          @loadDemands="loadDemands()"
          @insertUpdatedDemand="insertUpdatedDemand"
          ref="dialogAddEditDemandComponent"
        ></DialogAddEditDemand>
      </div>
    </div>
    <div v-if="demands.length === 0" @drop.prevent @dragover.prevent>
      <div class="dispatcher-board-empty-column-spacer"></div>
      <v-card v-bind="vStyle.card" border="dashed">
        <v-card-title>Keine Anfragen </v-card-title>
        <v-card-subtitle>unter diesem Filter</v-card-subtitle>
        <v-card-text v-if="!mdAndDown">
          Datei (*.pdf, *.jpg, *.png) auf dieses Feld ziehen um eine Anfrage
          anzulegen
        </v-card-text>
        <v-card-text v-else>
          Anfragen erstellen von Dateien auf Mobilgeräten derzeit nicht
          verfügbar
          <!-- TODO: Add file input AI creation of demands -->
        </v-card-text>
      </v-card>
    </div>
    <v-infinite-scroll
      :height="columnHeight - DispatcherBoardColumnHeight.iLoaderSubtract"
      :distance="DispatcherBoardColumnHeight.iLoaderDistance"
      disabled
      class="infinite-scroll-hidden-scrollbar ma-0 pa-0"
    >
      <div class="ma-0 pa-0 dispatcher-board-draggable">
        <DemandItem
          v-for="element in demands"
          class="longpress"
          :demand="element"
          :ref="
            (el: any) => {
              if (el) demandItemRefs.push(el);
            }
          "
          :key="element._id"
          :isFullyCollapsed="minimizeAllItems"
          :lastUpdateTimeline="lastUpdateTimeline"
          :interComponentMessage="interComponentMessage"
          @contextmenu.prevent="openContextMenu($event, element)"
          @longpress="openContextMenu($event, element)"
          @loadDemands="loadDemands()"
          @delete="deleteDemand"
          @openContextMenu="openContextMenu($event, element)"
          @editContact="editDemand"
        />
      </div>
      <template v-slot:loading
        ><!--Placeholder for message in pagination logic--></template
      >
    </v-infinite-scroll>
  </div>
  <v-menu
    v-model="showContextMenu"
    :style="{
      top: contextMenuPosition.y + 'px',
      left: contextMenuPosition.x + 'px',
    }"
  >
    <v-list dense>
      <v-list-item @click="postJobList(demand)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-table-list
        </v-icon>
        Stellen auf JobList setzen
      </v-list-item>
      <v-list-item @click="editDemand(demand)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-pen-to-square
        </v-icon>
        bearbeiten
      </v-list-item>
      <v-list-item @click="showMatchDialog = true">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-heart </v-icon
        >Matching</v-list-item
      >
      <v-list-item
        v-if="$store.state.isActiveCustomerOrDemandMatching"
        @click="clearMatchingOject()"
      >
        <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
          fa-solid fa-heart-circle-xmark </v-icon
        >Matches bei Kandidaten/Mitarbeitern entfernen</v-list-item
      >
      <v-list-item @click="confirmDeleteDemand(demand)">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          fa-solid fa-xmark
        </v-icon>
        löschen
      </v-list-item>
      <v-list-item @click="toggleItemsMinimized()">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          {{
            minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"
          }}
        </v-icon>
        {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
      </v-list-item>
    </v-list>
  </v-menu>
  <DialogMatchFromCustomerOrDemand
    :postcode="clickedDemandPostcode ?? ''"
    :tags="demand?.tags ?? []"
    :showMatchDialog="showMatchDialog"
    @closeDialog="showMatchDialog = false"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters, mapMutations } from "vuex";
import FilterMenu from "./elements/FilterMenu.vue";
import DemandItem from "@/components/disposition/DemandItem.vue";
import DialogService from "@/services/dialog.service";
import DialogAddEditDemand from "@/components/disposition/elements/DialogAddEditDemand.vue";
import { User } from "@/models/user.model";
import { ModalMode } from "@/enums/dialog.enum";
import { Demand } from "@/models/demand.model";
import { Mandant } from "@/models/mandant.model";
import { UserService } from "@/services/api/user.service";
import { DemandService } from "@/services/api/demand.service";
import { UserHelperService } from "@/services/user-helper.service";
import { SoftwareIntegration } from "@/models/company-config.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { debounce } from "lodash";
import { DispatcherBoardColumnHeight } from "../../enums/app-layout.enum";
import { AiService } from "../../services/ai.service";
import ToastService from "../../services/toast.service";
import { Role } from "../../enums/dependency.enum";
import { Customer } from "../../models/customer.model";
import { LinkingService } from "../../services/api/linking.service";
import DialogMatchFromCustomerOrDemand from "./elements/DialogMatchFromCustomerOrDemand.vue";
import { ActionType, MutationType } from "../../enums/vuex-types.enum";
import {
  UserConfigFilterBoard,
  UserConfigFilterType,
} from "../../enums/board-actions.enum";
import { useDisplay } from "vuetify";
import CvDropField from "./elements/drag-and-drop/DropField.vue";
import { DragEventSpec } from "../../enums/drag-event-spec.enum";
import { VuetifyColor } from "../../plugins/vuetify";
import { RecurionSpinnerService } from "../../services/recurion-spinner.service";

enum DemandColumnEmit {
  setDemandPostcodeList = "setDemandPostcodeList",
}

export default defineComponent({
  name: "DemandColumn",
  emits: [DemandColumnEmit.setDemandPostcodeList],
  components: {
    CvDropField,
    DemandItem,
    DialogAddEditDemand,
    DialogMatchFromCustomerOrDemand,
    FilterMenu,
  },
  props: {
    columnHeight: {
      type: Number,
      default: DispatcherBoardColumnHeight.standard,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      clickedDemandPostcode: "",
      contextMenuPosition: { x: 0, y: 0 },
      demand: {} as Demand,
      demandItemRefs: [] as InstanceType<typeof DemandItem>[],
      demands: [] as Demand[],
      demandService: new DemandService(),
      DispatcherBoardColumnHeight,
      drag: false,
      dragOverTimer: null as number | null,
      dropFieldText: {
        icon: "",
        title: "",
        subtitle: "",
        text: "",
      },
      minimizeAllItems: false,
      modalAddOrEditMode: ModalMode.add,
      Role,
      selectedMandants:
        this.user?.config?.dispatcherBoard?.columnCustomer?.filterMandants,
      selectedStatuses:
        this.user?.config?.dispatcherBoard?.columnCustomer?.filterStatus,
      showContextMenu: false,
      showDropField: false,
      showFilters: false,
      showMatchDialog: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      demandStates: "demandStates",
    }),
  },
  created() {
    this.getFiltersettingsFromStore().then(() => {
      this.loadDemands();
    });
  },
  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
  },
  beforeUpdate() {
    this.demandItemRefs = [];
  },
  beforeUnmount() {
    this.demandItemRefs = [];
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      clearICM: MutationType.clearICM,
      setICM: MutationType.setICM,
    }),
    addDemandFromAI(demand: Demand) {
      this.modalAddOrEditMode = ModalMode.add;
      if (this.$refs.dialogAddEditDemandComponent) {
        (
          this.$refs.dialogAddEditDemandComponent as InstanceType<
            typeof DialogAddEditDemand
          >
        ).addDemandFromAI(demand);
      }
    },
    addDemandFromCustomer(customer: Customer) {
      this.modalAddOrEditMode = ModalMode.add;
      if (this.$refs.dialogAddEditDemandComponent) {
        (
          this.$refs.dialogAddEditDemandComponent as InstanceType<
            typeof DialogAddEditDemand
          >
        ).addDemandFromCustomer(customer);
      }
    },
    async confirmDeleteDemand(demand: Demand) {
      if (this.demand) {
        const confirmed = await DialogService.confirm(
          "Die Anfrage wird unwiderruflich gelöscht!",
          "Behalten",
          "Löschen",
          "Anfrage löschen?",
          VuetifyColor.primary,
          VuetifyColor.error
        );

        if (confirmed && demand._id) {
          this.deleteDemand(demand._id);
        }
      }
      this.closeContextMenu();
    },
    async clearMatchingOject() {
      this.setICM({
        message: InterComponentMessage.matchMeFromCustomerOrDemand,
        payload: {
          postcodes: "",
          tags: "",
          threshold: 0.7,
        },
      });
      this.$store.state.isActiveCustomerOrDemandMatching = false;
      this.closeContextMenu();
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    async deleteDemand(demandId: string) {
      const linkingService = new LinkingService();
      try {
        if (this.$store.getters.hasLinkingForDemand(demandId)) {
          const responseLinking =
            await linkingService.deleteLinkingsByTypeAndId(
              Role.demand,
              demandId
            );
          if (responseLinking.error) {
            ToastService.showError(
              "Fehler beim Löschen der Anfrage: Verlinkungen konnten nicht entfernt werden!" +
                responseLinking.error
            );
            return;
          }
        }

        this.demandService.removeDemand(demandId).then(() => {
          this.loadDemands();
        });
      } catch (error) {
        console.error(error);
      }
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    editDemand(demand: Demand) {
      this.modalAddOrEditMode = ModalMode.edit;
      if (this.$refs.dialogAddEditDemandComponent) {
        (
          this.$refs.dialogAddEditDemandComponent as InstanceType<
            typeof DialogAddEditDemand
          >
        ).editDemand(demand);
      }
    },
    extractAndAddPostcodeList() {
      const postcodes = this.demands.flatMap((demand) =>
        demand.demands.map((item) => item.location.postcode)
      );
      this.$emit(DemandColumnEmit.setDemandPostcodeList, postcodes);
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadDemands();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ") ?? "";

      const filteredDemands = this.demands.filter((demand) => {
        const matches = terms.every((term) =>
          this.searchAllFields(demand, term)
        );

        return matches;
      });

      this.demands = filteredDemands;
    },
    async getFiltersettingsFromStore(): Promise<void> {
      return new Promise((resolve) => {
        this.selectedMandants =
          this.user.config.dispatcherBoard.columnDemand.filterMandants;
        this.selectedStatuses =
          this.user.config.dispatcherBoard.columnDemand.filterStatus;
        resolve();
      });
    },
    handleDragOver() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.handleShowDropField();
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleDragLeave() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleShowDropField() {
      const originComponent = this.$store.state.dragOriginComponent;
      if (
        originComponent === Role.candidate ||
        originComponent === Role.demand ||
        originComponent === Role.employee
      ) {
        this.showDropField = false;
      } else if (originComponent === Role.customer) {
        this.dropFieldText = {
          icon: "fa-solid fa-industry",
          title: "Anfrage anlegen",
          subtitle: "Unternehmensdaten aus Karte extrahieren",
          text: "Die Karte muss dann noch per Drag & Drop mit einem Kunden verlinkt werden, dass Profile generiert werden können",
        };
        this.showDropField = true;
      } else if (
        originComponent === Role.jobLead ||
        originComponent === Role.jobAd
      ) {
        this.dropFieldText = {
          icon: "fa-solid fa-rectangle-ad",
          title: "Anfrage anlegen",
          subtitle: "aus Anzeigendaten extrahieren",
          text: "Die Karte muss dann noch per Drag & Drop mit einem Kunden verlinkt werden, dass Profile generiert werden können",
        };
        this.showDropField = true;
      } else {
        this.dropFieldText = {
          icon: "fa-solid fa-file-circle-plus",
          title: "Anfragedaten aus Datei extrahieren",
          subtitle: "Dateiformat *.docx *.pdf *.png *.jpg möglich",
          text: "oder einen Text markieren und auf per Drag & Drop dieses Feld ziehen",
        };
        this.showDropField = true;
      }
    },
    async handleDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          DragEventSpec.originComponent
        );

        if (originComponent === Role.customer) {
          const draggedCustomer =
            this.$store.state.isDraggingItem.customer ?? undefined;
          this.addDemandFromCustomer(draggedCustomer);
          return;
        } else if (
          originComponent === Role.demand ||
          originComponent === Role.candidate ||
          originComponent === Role.employee
        ) {
          this.$nextTick().then(() => {
            this.$store.commit(MutationType.clearDraggingItem);
          });
          return;
        }
      }
      const file = event.dataTransfer?.files[0];
      const text = event.dataTransfer?.getData("text");
      const aiService = new AiService();
      try {
        RecurionSpinnerService.setSpinner();
        ToastService.showReminder(
          "AI Erkennung der Anfrage wird gestartet ..."
        );
        if (file) {
          const response = await aiService.extractDemand(file);
          this.addDemandFromAI(response[0]);
          ToastService.showSuccess("Anfrage aus Datei anlegen");
        } else if (text) {
          const response = await aiService.extractDemand(undefined, text);
          this.addDemandFromAI(response[0]);
          ToastService.showSuccess("Anfrage aus Text anlegen");
        }
      } catch (error) {
        ToastService.showError("AI hat keine Anfrage erkannt");
        console.error(error);
      } finally {
        RecurionSpinnerService.clearSpinner();
      }
    },
    insertUpdatedDemand(updatedDemand: Demand) {
      const index = this.demands.findIndex(
        (demand) => demand._id === updatedDemand._id
      );
      if (index !== -1) {
        this.demands[index] = updatedDemand;
        this.$nextTick(() => {
          const demandItemRef = this.demandItemRefs.find(
            (ref) => ref.demand._id === updatedDemand._id
          );
          if (demandItemRef) {
            demandItemRef.updateFilteredEmail();
          }
        });
      }
    },
    async loadDemands() {
      this.modalAddOrEditMode = ModalMode.add;
      await this.$nextTick();
      let filteredMandants = [] as string[];
      let filteredStatus = [] as string[];
      if (this.selectedMandants.length > 0) {
        filteredMandants = this.selectedMandants;
      }
      if (this.selectedStatuses.length > 0) {
        filteredStatus = this.selectedStatuses;
      }

      try {
        let result: Demand[];
        if (filteredMandants.length > 0) {
          result = await this.demandService.getAllFiltered(
            filteredMandants,
            filteredStatus
          );
        } else {
          result = await this.demandService.getAllDemands();
        }
        if (!result || !Array.isArray(result) || result.length === 0) {
          this.demands = [];
        } else {
          this.demands = result;
        }
      } catch (error) {
        this.demands = [];
      } finally {
        this.extractAndAddPostcodeList();
      }
    },
    openContextMenu(event: MouseEvent, demand: Demand) {
      this.demand = demand;
      this.clickedDemandPostcode = demand.demands[0].location.postcode ?? "";
      event.preventDefault();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuWidth = 200;
      const menuHeight = 180;

      let xPos = event.clientX;
      let yPos = event.clientY;

      if (xPos + menuWidth > windowWidth) {
        xPos = windowWidth - menuWidth;
      }

      if (yPos + menuHeight > windowHeight) {
        yPos = windowHeight - menuHeight;
      }

      if (xPos < 0) {
        xPos = 0;
      }
      this.contextMenuPosition = { x: xPos, y: yPos };
      this.showContextMenu = true;
    },
    async postJobList(demand: Demand) {
      const confirmed = await DialogService.confirm(
        "Aufgaben und Anforderungen der Anfrage extrahieren und zum Ausschreiben auf die JobList setzen?",
        "Abbrechen",
        "extrahieren",
        "Stellen extrahieren?",
        VuetifyColor.abort,
        VuetifyColor.success
      );
      if (confirmed) {
        if (this.$refs.dialogAddEditDemandComponent) {
          (
            this.$refs.dialogAddEditDemandComponent as InstanceType<
              typeof DialogAddEditDemand
            >
          ).postJobList(demand);
        }
      }
    },
    searchAllFields(object: Demand, term: string): boolean {
      return Object.values(object).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        } else if (typeof value === "object" && value !== null) {
          return this.searchAllFields(value, term);
        } else if (Array.isArray(value)) {
          return value.some((item) => this.searchAllFields(item, term));
        }
        return false;
      });
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.mandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCustomer.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.uuid);
      }
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
    updateStatusFilter: debounce(function (this: any, newValue: string[]) {
      this.loadDemands();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnDemand,
          property: UserConfigFilterType.filterStatus,
          value: newValue,
          validArray: Object.keys(this.demandStates),
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company.loggedInUser.config.dispatcherBoard
          );
        });
    }, 1500),
    updateMandantsFilter: debounce(function (this: any, newValue: string[]) {
      this.loadDemands();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnDemand,
          property: UserConfigFilterType.filterMandants,
          value: newValue,
          validArray: this.$store.getters.mandantUuids,
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company.loggedInUser.config.dispatcherBoard
          );
        });
    }, 1500),
  },
});
</script>

<style scoped>
.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
