import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertDialog = _resolveComponent("AlertDialog")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_PromptDialog = _resolveComponent("PromptDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dialogState?.activeType === _ctx.DialogType.alert)
      ? (_openBlock(), _createBlock(_component_AlertDialog, {
          key: 0,
          message: _ctx.dialogState?.props?.message ?? '',
          "button-text": _ctx.dialogState?.props?.buttonText ?? '',
          "btn-color": _ctx.dialogState?.props?.btnColor ?? '',
          onConfirm: _ctx.handleAlertConfirm
        }, null, 8, ["message", "button-text", "btn-color", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.dialogState?.activeType === _ctx.DialogType.confirm)
      ? (_openBlock(), _createBlock(_component_ConfirmDialog, {
          key: 1,
          question: _ctx.dialogState?.props?.question ?? '',
          "btn-abort-text": _ctx.dialogState?.props?.btnAbortText,
          "btn-confirm-text": _ctx.dialogState?.props?.btnConfirmText,
          title: _ctx.dialogState?.props?.title,
          "btn-abort-color": _ctx.dialogState?.props?.btnAbortColor,
          "btn-confirm-color": _ctx.dialogState?.props?.btnConfirmColor,
          onConfirm: _ctx.handleConfirmResponse
        }, null, 8, ["question", "btn-abort-text", "btn-confirm-text", "title", "btn-abort-color", "btn-confirm-color", "onConfirm"]))
      : _createCommentVNode("", true),
    (_ctx.dialogState?.activeType === _ctx.DialogType.prompt)
      ? (_openBlock(), _createBlock(_component_PromptDialog, {
          key: 2,
          title: _ctx.dialogState?.props?.title ?? '',
          "prompt-question": _ctx.dialogState?.props?.promptQuestion ?? '',
          "input-label-text": _ctx.dialogState?.props?.inputLabelText ?? '',
          "btn-abort-text": _ctx.dialogState?.props?.btnAbortText ?? '',
          "btn-confirm-text": _ctx.dialogState?.props?.btnConfirmText ?? '',
          "btn-abort-color": _ctx.dialogState?.props?.btnAbortColor,
          "btn-confirm-color": _ctx.dialogState?.props?.btnConfirmColor,
          onConfirm: _ctx.handlePromptResponse
        }, null, 8, ["title", "prompt-question", "input-label-text", "btn-abort-text", "btn-confirm-text", "btn-abort-color", "btn-confirm-color", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}