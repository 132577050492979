<template>
  <AlertDialog
    v-if="dialogState?.activeType === DialogType.alert"
    :message="dialogState?.props?.message ?? ''"
    :button-text="dialogState?.props?.buttonText ?? ''"
    :btn-color="dialogState?.props?.btnColor ?? ''"
    @confirm="handleAlertConfirm"
  />
  <ConfirmDialog
    v-if="dialogState?.activeType === DialogType.confirm"
    :question="dialogState?.props?.question ?? ''"
    :btn-abort-text="dialogState?.props?.btnAbortText"
    :btn-confirm-text="dialogState?.props?.btnConfirmText"
    :title="dialogState?.props?.title"
    :btn-abort-color="dialogState?.props?.btnAbortColor"
    :btn-confirm-color="dialogState?.props?.btnConfirmColor"
    @confirm="handleConfirmResponse"
  />
  <PromptDialog
    v-if="dialogState?.activeType === DialogType.prompt"
    :title="dialogState?.props?.title ?? ''"
    :prompt-question="dialogState?.props?.promptQuestion ?? ''"
    :input-label-text="dialogState?.props?.inputLabelText ?? ''"
    :btn-abort-text="dialogState?.props?.btnAbortText ?? ''"
    :btn-confirm-text="dialogState?.props?.btnConfirmText ?? ''"
    :btn-abort-color="dialogState?.props?.btnAbortColor"
    :btn-confirm-color="dialogState?.props?.btnConfirmColor"
    @confirm="handlePromptResponse"
  />
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import PromptDialog from "@/components/dialog/PromptDialog.vue";
import DialogService from "@/services/dialog.service";
import { DialogType } from "@/enums/dialog.enum";
import { DialogServiceProps } from "@/models/dialog.model";

export default defineComponent({
  name: "DialogContainer",
  components: {
    AlertDialog,
    ConfirmDialog,
    PromptDialog,
  },
  setup() {
    const dialogState = reactive({
      activeType: DialogType.none,
      queue: [],
      props: {
        resolve: null as ((value: any) => void) | null,
      } as DialogServiceProps,
    });

    const handleAlertConfirm = () => {
      dialogState.props.resolve?.(true);
      DialogService.completeDialog();
    };

    const handleConfirmResponse = (result: boolean) => {
      dialogState.props.resolve?.(result);
      DialogService.completeDialog();
    };

    const handlePromptResponse = (result: string) => {
      dialogState.props.resolve?.(result);
      DialogService.completeDialog();
    };

    // Init singleton
    DialogService.init(dialogState);

    return {
      DialogType,
      dialogState,
      handleAlertConfirm,
      handleConfirmResponse,
      handlePromptResponse,
    };
  },
});
</script>
