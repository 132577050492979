import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "header-dispo-column" }
const _hoisted_2 = { class: "status-options" }
const _hoisted_3 = { class: "mandants-options" }
const _hoisted_4 = { class: "ma-0 pa-0 dispatcher-board-draggable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CvDropField = _resolveComponent("CvDropField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_DialogAddEditDemand = _resolveComponent("DialogAddEditDemand")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_DemandItem = _resolveComponent("DemandItem")!
  const _component_v_infinite_scroll = _resolveComponent("v-infinite-scroll")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_DialogMatchFromCustomerOrDemand = _resolveComponent("DialogMatchFromCustomerOrDemand")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "column",
      onDrop: [
        _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args))),
        _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
      ],
      onDragover: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
      onDragleave: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)))
    }, [
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(
        _ctx.mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      )
          }, null, 2))
        : _createCommentVNode("", true),
      _createVNode(_component_CvDropField, {
        showDropField: _ctx.showDropField,
        parentRole: _ctx.Role.demand,
        fieldText: _ctx.dropFieldText,
        onHandleDrop: _ctx.handleDrop
      }, null, 8, ["showDropField", "parentRole", "fieldText", "onHandleDrop"]),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_FilterMenu, null, {
            "filter-menu-left-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demandStates, (label, key) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: key,
                    modelValue: _ctx.selectedStatuses,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatuses) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateStatusFilter(_ctx.selectedStatuses)))
                    ],
                    label: label,
                    value: String(key),
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            "filter-menu-right-column": _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                  return (_openBlock(), _createBlock(_component_v_checkbox, {
                    density: "compact",
                    key: mandant.uuid,
                    modelValue: _ctx.selectedMandants,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                      _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMandantsFilter(_ctx.selectedMandants)))
                    ],
                    label: mandant.name,
                    value: mandant.uuid,
                    class: "ma-0 pa-0"
                  }, null, 8, ["modelValue", "label", "value"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DialogAddEditDemand, {
            modalMode: _ctx.modalAddOrEditMode,
            onLoadDemands: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadDemands())),
            onInsertUpdatedDemand: _ctx.insertUpdatedDemand,
            ref: "dialogAddEditDemandComponent"
          }, null, 8, ["modalMode", "onInsertUpdatedDemand"])
        ])
      ], 512), [
        [_vShow, _ctx.isActive]
      ]),
      (_ctx.demands.length === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onDrop: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "dispatcher-board-empty-column-spacer" }, null, -1)),
            _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { border: "dashed" }), {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Keine Anfragen ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_subtitle, null, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("unter diesem Filter")
                  ])),
                  _: 1
                }),
                (!_ctx.mdAndDown)
                  ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode(" Datei (*.pdf, *.jpg, *.png) auf dieses Feld ziehen um eine Anfrage anzulegen ")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode(" Anfragen erstellen von Dateien auf Mobilgeräten derzeit nicht verfügbar ")
                      ])),
                      _: 1
                    }))
              ]),
              _: 1
            }, 16)
          ], 32))
        : _createCommentVNode("", true),
      _createVNode(_component_v_infinite_scroll, {
        height: _ctx.columnHeight - _ctx.DispatcherBoardColumnHeight.iLoaderSubtract,
        distance: _ctx.DispatcherBoardColumnHeight.iLoaderDistance,
        disabled: "",
        class: "infinite-scroll-hidden-scrollbar ma-0 pa-0"
      }, {
        loading: _withCtx(() => _cache[25] || (_cache[25] = [])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demands, (element) => {
              return (_openBlock(), _createBlock(_component_DemandItem, {
                class: "longpress",
                demand: element,
                ref_for: true,
                ref: 
            (el) => {
              if (el) _ctx.demandItemRefs.push(el);
            }
          ,
                key: element._id,
                isFullyCollapsed: _ctx.minimizeAllItems,
                lastUpdateTimeline: _ctx.lastUpdateTimeline,
                interComponentMessage: _ctx.interComponentMessage,
                onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, element)), ["prevent"]),
                onLongpress: ($event: any) => (_ctx.openContextMenu($event, element)),
                onLoadDemands: _cache[7] || (_cache[7] = ($event: any) => (_ctx.loadDemands())),
                onDelete: _ctx.deleteDemand,
                onOpenContextMenu: ($event: any) => (_ctx.openContextMenu($event, element)),
                onEditContact: _ctx.editDemand
              }, null, 8, ["demand", "isFullyCollapsed", "lastUpdateTimeline", "interComponentMessage", "onContextmenu", "onLongpress", "onDelete", "onOpenContextMenu", "onEditContact"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["height", "distance"])
    ], 32),
    _createVNode(_component_v_menu, {
      modelValue: _ctx.showContextMenu,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.showContextMenu) = $event)),
      style: _normalizeStyle({
      top: _ctx.contextMenuPosition.y + 'px',
      left: _ctx.contextMenuPosition.x + 'px',
    })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dense: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.postJobList(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode(" fa-solid fa-table-list ")
                  ])),
                  _: 1
                }),
                _cache[27] || (_cache[27] = _createTextVNode(" Stellen auf JobList setzen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.editDemand(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode(" fa-solid fa-pen-to-square ")
                  ])),
                  _: 1
                }),
                _cache[29] || (_cache[29] = _createTextVNode(" bearbeiten "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showMatchDialog = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createTextVNode(" fa-solid fa-heart ")
                  ])),
                  _: 1
                }),
                _cache[31] || (_cache[31] = _createTextVNode("Matching"))
              ]),
              _: 1
            }),
            (_ctx.$store.state.isActiveCustomerOrDemandMatching)
              ? (_openBlock(), _createBlock(_component_v_list_item, {
                  key: 0,
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.clearMatchingOject()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1 mr-2",
                      size: "xs"
                    }, {
                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                        _createTextVNode(" fa-solid fa-heart-circle-xmark ")
                      ])),
                      _: 1
                    }),
                    _cache[33] || (_cache[33] = _createTextVNode("Matches bei Kandidaten/Mitarbeitern entfernen"))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_list_item, {
              onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.confirmDeleteDemand(_ctx.demand)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createTextVNode(" fa-solid fa-xmark ")
                  ])),
                  _: 1
                }),
                _cache[35] || (_cache[35] = _createTextVNode(" löschen "))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, {
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.toggleItemsMinimized()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis mr-1",
                  size: "xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"), 1)
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.minimizeAllItems ? "Karten normal" : "Karten minimieren"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "style"]),
    _createVNode(_component_DialogMatchFromCustomerOrDemand, {
      postcode: _ctx.clickedDemandPostcode ?? '',
      tags: _ctx.demand?.tags ?? [],
      showMatchDialog: _ctx.showMatchDialog,
      onCloseDialog: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showMatchDialog = false))
    }, null, 8, ["postcode", "tags", "showMatchDialog"])
  ], 64))
}