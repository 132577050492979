import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "editor-container editor-container_classic-editor",
  ref: "editorContainerElement"
}
const _hoisted_2 = { class: "editor-container__editor" }
const _hoisted_3 = { ref: "editorElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      density: "compact",
      variant: "text",
      icon: "",
      class: "communication-container-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMailClient()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, { size: "1.5rem" }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode("fa-solid fa-envelope")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Email öffnen")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showModal,
      showActions: true,
      onCloseDialog: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showModal = false)),
      width: 'var(--d-w-l)'
    }, {
      title: _withCtx(() => [
        _createVNode(_component_v_icon, { class: "mx-2" }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createTextVNode("fa-solid fa-envelope")
          ])),
          _: 1
        }),
        _cache[18] || (_cache[18] = _createTextVNode("E-Mail senden "))
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
              modelValue: _ctx.senderModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.senderModel) = $event)),
              items: _ctx.senderMailAddresses,
              "item-title": "email",
              "item-value": "data",
              "prepend-inner-icon": "fa-solid fa-user",
              "return-object": "",
              label: "Absender"
            }), null, 16, ["modelValue", "items"]),
            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
              modelValue: _ctx.selectedEmailObject,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEmailObject) = $event)),
                _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateFullMessage()))
              ],
              items: _ctx.emailAddresses,
              label: "Empfänger wählen",
              "item-title": "mailaddress",
              "return-object": "",
              "prepend-inner-icon": "fa-solid fa-building-user"
            }), {
              "append-inner": _withCtx(() => [
                _createVNode(_component_v_icon, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit(_ctx.MailClientEmit.editContacts)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectedEmailObject.mailaddress === _ctx.MessageContent.noMailAddress
                  ? "fa-solid fa-plus"
                  : "fa-solid fa-pencil"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "top left"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectedEmailObject.mailaddress === _ctx.MessageContent.noMailAddress
                  ? "Kontakt hinzufügen"
                  : "Kontakte bearbeiten"), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16, ["modelValue", "items"]),
            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
              modelValue: _ctx.mailSubject,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.mailSubject) = $event)),
              label: "Betreff"
            }), null, 16, ["modelValue"]),
            _createVNode(_component_v_file_input, _mergeProps(_ctx.vStyle.input, {
              accept: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpg, image/jpeg",
              "prepend-icon": "fa-solid fa-paperclip",
              label: "Dokumentanhang (*.pdf, *.doc, *.docx, *.png, *.jpg, *.jpeg)",
              modelValue: _ctx.file,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.file) = $event)),
              chips: "",
              multiple: ""
            }), null, 16, ["modelValue"]),
            _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "main-container" }), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      (_ctx.isLayoutReady)
                        ? (_openBlock(), _createBlock(_component_ckeditor, {
                            key: 0,
                            modelValue: _ctx.fullMessage,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fullMessage) = $event)),
                            editor: _ctx.editor,
                            config: _ctx.config
                          }, null, 8, ["modelValue", "editor", "config"]))
                        : _createCommentVNode("", true)
                    ], 512)
                  ])
                ], 512)
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "abort",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [
            _createTextVNode("Abbrechen")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          icon: "",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showAiModelMenu($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("fa-solid fa-rocket")
              ])),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode("Mail mit AI umformulieren")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.menuAiModelVisible,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.menuAiModelVisible) = $event)),
          class: "ai-menu",
          style: _normalizeStyle({
            left: `${_ctx.menuPosition.x}px`,
            top: `${_ctx.menuPosition.y}px`,
          }),
          ref: "aiMenu"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aiOptions, (aiOption) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: aiOption.value,
                    onClick: ($event: any) => (_ctx.handleAiOptions(aiOption))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(aiOption.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "style"]),
        _createVNode(_component_v_btn, {
          class: "longpress",
          icon: "",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showLanguageMenu($event))),
          onContextmenu: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.showAiModelMenu($event, true)), ["prevent"])),
          onLongpress: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showAiModelMenu($event, true)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("fa-solid fa-comments")
              ])),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode("mit AI in eine andere Sprache übersetzen")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.menuTranslationVisible,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.menuTranslationVisible) = $event)),
          class: "ai-menu",
          style: _normalizeStyle({
            left: `${_ctx.menuPosition.x}px`,
            top: `${_ctx.menuPosition.y}px`,
          }),
          ref: "aiMenu"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: language,
                    onClick: ($event: any) => (_ctx.selectLanguageAndTranslate(language))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(language), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "style"]),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "success",
          onClick: _ctx.sendMessage,
          disabled: 
            !(_ctx.senderMailAddresses && _ctx.selectedEmailObject && _ctx.mailSubject)
          
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [
            _createTextVNode("Senden")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      _: 1
    }, 8, ["showDialog"])
  ]))
}