<template>
  <v-sheet
    v-if="showDropField"
    color="surface-bright"
    rounded="lg"
    class="drop-field my-15"
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
    @drop.prevent="handleDrop"
  >
    <div
      style="
        border: 0.2rem dashed var(--color-primary-darken-1);
        border-radius: var(--border-radius-dialog);
      "
      class="pa-4 d-flex justify-center align-center h-100"
    >
      <div
        class="d-flex flex-column justify-center align-center text-center"
        style="height: 100%"
      >
        <v-icon v-if="fieldText.icon" class="mb-5 field-text-icon" size="3rem">
          {{ fieldText.icon }}
        </v-icon>
        <p class="text-h5 mb-5 field-text-title">
          {{ fieldText.title }}
        </p>
        <p class="text-h6 mb-5 field-text-subtitle">
          {{ fieldText.subtitle }}
        </p>
        <p class="text-body-1 field-text">
          {{ fieldText.text }}
        </p>
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Role } from "../../../../enums/dependency.enum";
import mammoth from "mammoth";
import ToastService from "../../../../services/toast.service";
import { AiService } from "../../../../services/ai.service";
import {
  CandidateFromCvParser,
  CareerStep,
} from "../../../../models/candidate.model";
import { SpinnerService } from "../../../../services/spinner.service";
import { DragEventSpec } from "../../../../enums/drag-event-spec.enum";
import { DataType } from "../../../../enums/data-types.enum";

enum CvDropFieldEmit {
  parsedCandidate = "parsedCandidate",
  parsedCareerSteps = "parsedCareerSteps",
  handleDrop = "handleDrop",
}

export default defineComponent({
  name: "CvDropField",
  emits: [
    CvDropFieldEmit.parsedCandidate,
    CvDropFieldEmit.parsedCareerSteps,
    CvDropFieldEmit.handleDrop,
  ],

  props: {
    parseWithPersonalData: {
      type: Boolean,
      default: false,
    },
    parentRole: {
      type: String as PropType<Role>,
    },
    showDropField: {
      type: Boolean,
      default: false,
    },
    fieldText: {
      type: Object as PropType<{
        icon: string;
        title: string;
        subtitle: string;
        text: string;
      }>,
      default: () => ({
        icon: "",
        title: "",
        subtitle: "",
        text: "",
      }),
    },
  },
  data() {
    return {
      cvParserText: {
        title: "Lebenslauf wird analysiert...",
        subtitle: "Bitte warten Sie, bis der Lebenslauf analysiert wurde.",
      },
    };
  },
  methods: {
    async handleDrop(event: DragEvent) {
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          DragEventSpec.originComponent
        );
        if (!this.parentRole) {
          if (
            originComponent === Role.candidate ||
            originComponent === Role.employee ||
            originComponent === Role.demand ||
            originComponent === Role.customer ||
            originComponent === Role.jobLead ||
            originComponent === Role.jobAd
          ) {
            event.preventDefault();
          } else {
            this.parseCv(event);
            event.preventDefault();
          }
        } else if (this.parentRole === Role.candidate) {
          if (
            originComponent === Role.candidate ||
            originComponent === Role.demand ||
            originComponent === Role.customer ||
            originComponent === Role.jobLead ||
            originComponent === Role.jobAd
          ) {
            event.preventDefault();
          } else if (originComponent === Role.employee) {
            this.$emit(CvDropFieldEmit.handleDrop, event);
            event.preventDefault();
          } else {
            this.parseCv(event);
            event.preventDefault();
          }
        }
      }
    },
    async handleParsedCandidate(
      result: CandidateFromCvParser | CareerStep[] | null,
      file: File | null
    ) {
      if (result === null) return;
      if (this.parseWithPersonalData) {
        this.$emit(CvDropFieldEmit.parsedCandidate, result, file);
      } else {
        this.$emit(CvDropFieldEmit.parsedCareerSteps, result, file);
      }
    },
    async parseCv(event: DragEvent) {
      try {
        ToastService.showReminder("Lebenslauf wird analysiert...");
        SpinnerService.showSpinner();
        const draggedFile = event.dataTransfer?.files[0];
        const draggedText = event.dataTransfer?.getData("text");
        let file = null;
        let text = null;
        if (draggedFile) {
          const validatedFile = await this.validateFile(draggedFile);
          if (validatedFile?.file) {
            file = validatedFile.file;
          } else if (validatedFile?.text) {
            text = validatedFile?.text;
          }
        } else if (draggedText) {
          text = draggedText;
        }
        const aiService = new AiService();
        let response = null;
        if (file) {
          response = await aiService.cvParser(this.parseWithPersonalData, file);
        } else if (text) {
          response = await aiService.cvParser(
            this.parseWithPersonalData,
            undefined,
            text
          );
        }
        if (response !== null) {
          this.handleParsedCandidate(response, draggedFile ?? null);
        }
      } catch (error) {
        ToastService.showError("Fehler beim Parsen des Lebenslaufs");
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async validateFile(file: File | null) {
      if (!file) return null;

      const maxSize = 5 * 1024 * 1024; // 5MB
      const allowedTypes = [
        DataType.docx as string,
        DataType.pdf as string,
        DataType.png as string,
        DataType.jpg as string,
        DataType.jpeg as string,
      ];

      if (file.size > maxSize) {
        ToastService.showError("Die Datei darf nicht größer als 5MB sein");
        return null;
      }

      if (!allowedTypes.includes(file.type)) {
        ToastService.showError(
          "Nur .docx, .pdf, .png oder .jpg Dateien sind erlaubt"
        );
        return null;
      }
      let text = null;
      if (file.type === DataType.docx) {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const result = await mammoth.extractRawText({ arrayBuffer });
          if (result.value) {
            text = result.value;
            file = null;
          }
        } catch (error) {
          ToastService.showError("Fehler beim Lesen der .docx Datei");
          console.error(error);
          return null;
        }
      }

      return { file, text };
    },
  },
});
</script>

<style scoped>
.drop-field {
  position: relative;
  top: 0.3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 65vh;
  width: 98%;
  opacity: 0.5;
  z-index: 999;
}
.field-text-icon {
  text-shadow: 0 0 0.1rem var(--color-primary);
  animation: popIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
.field-text-title,
.field-text-subtitle,
.field-text {
  text-wrap: balance;
  overflow: hidden;
  opacity: 0;
}
.field-text-title {
  animation: fadeIn 0.4s ease forwards;
  animation-delay: 0.1s;
}
.field-text-subtitle {
  animation: fadeIn 0.4s ease forwards;
  animation-delay: 0.2s;
}
.field-text {
  animation: fadeIn 0.4s ease forwards;
  animation-delay: 0.3s;
}
@keyframes popIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
