<template>
  <v-form ref="form" v-model="isValid">
    <div class="d-flex">
      <v-text-field
        v-bind="vStyle?.input"
        density="compact"
        label="Link zum Kandidaten in ATS"
        v-model="atsRecruitLink"
        :rules="[linkRules]"
        :hint="`z.B.: ${$store.getters.softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/c1bca247-c432-4162-900c-fffeaa0416aa`"
        persistent-hint
      />
      <v-btn
        icon
        @click="extractUuid"
        v-bind="vStyle?.btn"
        color="success"
        class="ml-2"
        :disabled="!isValid"
        variant="text"
      >
        <v-icon size="2.5rem">fa-solid fa-check</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

enum ExtractCandidateUuidFromAtsRecruitLinkForm {
  extractedUuid = "extractedUuid",
}

export default defineComponent({
  name: "ExtractCandidateUuidFromAtsRecruitLinkForm",
  emits: [ExtractCandidateUuidFromAtsRecruitLinkForm.extractedUuid],

  data() {
    return {
      atsRecruitLink: "",
      isValid: false,
      vStyle: this.$store.state.vStyle,
    };
  },

  computed: {
    linkRules() {
      return (value: string) => {
        const baseUrl =
          this.$store.getters.softwareIntegration.zvooveRecruitLink;
        const uuidPattern =
          "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";
        const pattern = new RegExp(
          `^${baseUrl.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
          )}/recruiting/bewerber/${uuidPattern}(/.*)?$`
        );

        if (!value) return "Link ist erforderlich";
        if (!pattern.test(value)) return "Ungültiges Link-Format";
        return true;
      };
    },
  },

  methods: {
    extractUuid() {
      const match = this.atsRecruitLink.match(/\/bewerber\/([0-9a-f-]+)/);
      if (match && match[1]) {
        this.$emit(
          ExtractCandidateUuidFromAtsRecruitLinkForm.extractedUuid,
          match[1]
        );
      }
    },
  },
});
</script>
