<!--src/components/dispostion/elements/SendCandidateToWP.vue-->
<template>
  <BaseDialog
    @closeDialog="closeModal(true)"
    :showDialog="dialog"
    :showActions="true"
    :width="'var(--d-w-m)'"
  >
    <template #title>Kandidat auf die Homepage stellen?</template>
    <template #content>
      <v-card-text>
        <v-form>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.title"
            label="Titel"
            density="compact"
            required
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.profileId"
            label="Profil ID"
            density="compact"
            required
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.gender"
            label="Geschlecht"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.age"
            label="Alter"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.residence"
            label="Wohnort"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.mobility"
            label="Mobilität"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.shift"
            label="Schicht"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.qualification"
            label="Qualifikation"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.licenses"
            label="Lizenzen"
            density="compact"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="wpCandidateData.experience"
            label="Erfahrung"
            density="compact"
          ></v-text-field>
        </v-form>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeModal(true)"
        >nicht Veröffentlichen</v-btn
      >
      <v-spacer></v-spacer>
      <v-icon :elevation="5" class="ai-icon" @click="generateAiProfile">
        fa-solid fa-rocket
      </v-icon>
      <v-tooltip activator="parent" location="bottom"
        >Kandidateninformationen für die Webseite mit AI
        zusammstellen</v-tooltip
      >
      <v-spacer></v-spacer>
      <v-btn color="success" @click="postCandidateToWP"
        >auf Webseite stellen</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { WordPressCandidateData } from "@/models/wordpress-plugin.model";
import { WordPressPluginService } from "@/services/api/wordpress-plugin.service";
import ToastService from "@/services/toast.service";
import { DialogResponse } from "@/enums/dialog.enum";
import { AiService } from "@/services/ai.service";
import { SpinnerService } from "@/services/spinner.service";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
export default defineComponent({
  name: "SendCandidateToWP",
  components: {
    BaseDialog,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      dialog: false,
      vStyle: this.$store.state.vStyle,
      wpCandidateData: {
        title: "",
        profileId: "",
        gender: "",
        age: "",
        residence: "",
        mobility: "",
        shift: "",
        qualification: "",
        licenses: "",
        experience: "",
      } as WordPressCandidateData,
      wpService: new WordPressPluginService(),
    };
  },

  methods: {
    openModal(wpCandidateData?: WordPressCandidateData) {
      if (wpCandidateData) {
        this.wpCandidateData = wpCandidateData;
      }
      this.dialog = true;
    },
    closeModal(emit = false) {
      if (emit) {
        this.$emit(DialogResponse.aborted);
      }
      this.dialog = false;
    },
    async generateAiProfile() {
      SpinnerService.showSpinner();
      try {
        const anonymizedResume = this.wpCandidateData.anonymizedResume;
        const aiService = new AiService();
        const response = await aiService.generateWpPluginProfile(
          anonymizedResume
        );

        this.wpCandidateData.title = response.title;
        this.wpCandidateData.licenses = response.licenses;
        this.wpCandidateData.qualification = response.qualification;
        this.wpCandidateData.experience = response.experience;
      } catch (error) {
        console.error("Fehler beim Generieren des AI-Profils:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async postCandidateToWP() {
      try {
        const exists =
          await this.wpService.checkIfExistCandidateInWordPressPlugin(
            this.wpCandidateData.profileId
          );
        if (exists) {
          await this.wpService.deleteCandidateFromWordPressPlugin(
            this.wpCandidateData.profileId
          );
        }

        this.wpService
          .postCandidateToWordPressPlugin(this.wpCandidateData)
          .then(() => {
            this.dialog = false;
            ToastService.showSuccess(
              "Kandidat erfolgreich auf Webseite gestellt!"
            );
          });
      } catch (error) {
        ToastService.show("Fehler beim Senden des Kandidaten: " + error);
      }
    },
    async deleteCandidateFromWP(profileId: string) {
      try {
        const exists =
          await this.wpService.checkIfExistCandidateInWordPressPlugin(
            profileId
          );
        if (exists) {
          this.wpService
            .deleteCandidateFromWordPressPlugin(profileId)
            .then(() => {
              ToastService.showSuccess(
                "Kandidat erfolgreich von der Webseite entfernt!"
              );
            });
        } else {
          ToastService.show(
            `Kandidat mit der Id ${profileId} nicht auf der Webseite vorhanden!`
          );
        }
      } catch (error) {
        ToastService.show("Fehler beim Löschen des Kandidaten: " + error);
      }
    },
  },
});
</script>

<style scoped>
.ai-icon {
  color: var(--color-primary);
  transition: all 0.3s ease;
}
.ai-icon:hover {
  color: var(--color-tertiary);
  scale: var(--medium-scale-up);
}
</style>
