<template>
  <v-card v-bind="vStyle.card" class="mb-5" border="dashed">
    <v-card-text>
      <v-row class="mt-3">
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle?.input"
                v-model="newEntry.from"
                label="Von"
                type="date"
                density="compact"
                class="my-0"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle?.input"
                v-model="newEntry.until"
                label="Bis"
                type="date"
                density="compact"
                class="my-0"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div style="height: 8.5rem"></div>
              <v-btn
                v-bind="vStyle?.btn"
                block
                color="success"
                @click="addEntry"
              >
                <v-icon class="mr-2">fa-solid fa-calendar-plus</v-icon>
                Neuer Eintrag hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8">
          <v-row noGutters>
            <v-col cols="12" md="8">
              <v-text-field
                v-bind="vStyle?.input"
                v-model="newEntry.title"
                label="Titel"
                density="compact"
                class="my-0 text-h6"
                style="font-weight: 600"
              />
            </v-col>
            <v-col cols="12" md="4">
              <div class="d-flex">
                <v-select
                  v-bind="vStyle?.input"
                  v-model="newEntry.type"
                  :items="employmentTypes"
                  item-title="description"
                  item-value="systemName"
                  label="Typ"
                  density="compact"
                  class="my-0 ml-2"
                  :max-width="250"
                />
              </div>
            </v-col>
          </v-row>
          <v-text-field
            v-bind="vStyle?.input"
            v-model="newEntry.at"
            label="Bei"
            density="compact"
            class="my-0"
          />
          <v-textarea
            v-bind="vStyle?.input"
            v-model="newEntry.details"
            label="Details"
            rows="5"
            density="compact"
            class="my-0"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { CareerStep, CareerStepType } from "@/models/candidate.model";
import { defineComponent, PropType } from "vue";
import {
  EmploymentType,
  EmploymentTypeSystemName,
} from "../../../../enums/employment-types.enum";

enum FormNewCarreerStepEmits {
  addEntry = "addEntry",
}

export default defineComponent({
  name: "FormNewCarreerStep",
  emits: [FormNewCarreerStepEmits.addEntry],
  props: {
    employmentTypes: {
      type: Array as PropType<CareerStepType[]>,
      required: true,
    },
  },
  data() {
    return {
      newEntry: this.emptyEntry(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    addEntry() {
      this.$emit(FormNewCarreerStepEmits.addEntry, this.newEntry);
      this.newEntry = this.emptyEntry();
    },
    emptyEntry() {
      return {
        title: "",
        at: "",
        type: {
          description: "Anstellung",
          systemName: EmploymentTypeSystemName.employed,
          sortOrder: EmploymentType.employed,
        },
        from: "",
        until: "",
        details: "",
        degree: "",
      } as CareerStep;
    },
  },
});
</script>
