import { Role } from "@/models/role.model";
import { Api } from "./api.class";
import { ApiResponse } from "../../models/api-response.model";

export class RoleService {
  private static instance: RoleService;
  private api = Api.getInstance();
  private urlPath = "/role";

  public static getInstance(): RoleService {
    if (!RoleService.instance) {
      RoleService.instance = new RoleService();
    }
    return RoleService.instance;
  }

  async getRoles(): Promise<Role[]> {
    const response = await this.api.getBackendApi().get(`${this.urlPath}`);
    return response.data.response;
  }

  async getRoleOptions(): Promise<Partial<Role[]>> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/options`);
    return response.data.response;
  }

  async createRole(name: string): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}`, { name });
    return response.data;
  }

  async removeRole(id: string): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .delete<ApiResponse>(`${this.urlPath}/${id}`);
    return response.data;
  }

  async removePermission(id: string, permission: string): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .delete<ApiResponse>(`${this.urlPath}/${id}/${permission}`);
    return response.data;
  }

  async addPermission(id: string, permission: string): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}/${id}/${permission}`);
    return response.data;
  }
}
