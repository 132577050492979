import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_EmailConfiguration = _resolveComponent("EmailConfiguration")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasConfigEmailAccess)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, [
              _createVNode(_component_v_icon, {
                size: "small",
                class: "mr-2"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("fa-solid fa-envelope")
                ])),
                _: 1
              }),
              _cache[1] || (_cache[1] = _createTextVNode("Email-Konfiguration "))
            ]),
            _createVNode(_component_EmailConfiguration, {
              office365AccessToken: _ctx.isExistingAccessToken,
              mailServerConfig: _ctx.localConfig.mailServer,
              office365Config: _ctx.isExistingAccessToken,
              emailScope: _ctx.EmailScope.company,
              onEmailValidationResult: _ctx.handleEmailTest,
              onOffice365Logout: _ctx.office365Logout,
              onRemoveMailserverConfig: _ctx.removeMailserverConfig
            }, null, 8, ["office365AccessToken", "mailServerConfig", "office365Config", "emailScope", "onEmailValidationResult", "onOffice365Logout", "onRemoveMailserverConfig"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isTestSuccessful)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 1,
          onSave: _ctx.handleSaveConfig
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}