<!--src/components/subscription/feature-overview.vue-->
<template>
  <v-row>
    <v-col cols="12" md="3">
      <v-card v-bind="vStyle.card" flat>
        <v-card-title class="my-4">Features</v-card-title>
        <v-list density="compact">
          <v-list-item
            class="my-4 mx-0 features__item"
            v-for="(feature, index) in features"
            :key="index"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ feature }}</span>
              </template>
              <span v-html="descriptions[index]"></span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="3"
      v-for="(subscription, index) in subscriptions"
      :key="index"
    >
      <v-card
        v-bind="vStyle.card"
        :class="{ active: selected === subscription.name }"
      >
        <v-card-title class="my-4 d-flex justify-center">{{
          subscription.name
        }}</v-card-title>
        <v-list density="compact" class="no-background">
          <v-list-item
            class="my-4 d-flex justify-center features__item"
            v-for="(feature, index) in subscription.features"
            :key="index"
          >
            <v-tooltip bottom :text="features[index]">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <div :class="feature ? 'tick' : 'cross'">
                    {{
                      feature
                        ? typeof feature === "string"
                          ? feature
                          : "✔"
                        : "✘"
                    }}
                  </div>
                </span>
              </template>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FeatureOverview",
  props: {
    features: {
      type: Array,
      required: true,
    },
    subscriptions: {
      type: Array,
      required: true,
    },
    descriptions: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
});
</script>

<style scoped>
.features__item {
  height: 2.5rem;
  overflow: visible;
  cursor: help;
}
.no-background {
  background-color: transparent !important;
}
.active {
  background-color: var(--color-secondary);
}
.cross {
  color: red;
}
.tick {
  color: green;
}
</style>
