<template>
  <div class="reminder-bell" @click="openDialog">
    <v-badge
      v-if="globalExpiredReminders.length > 0"
      color="abort"
      :content="globalExpiredReminders.length"
      overlap
      offset-x="0"
    >
      <div>
        <v-icon size="small" @mouseover="showTooltip" @mouseleave="hideTooltip">
          fa-solid fa-bell
        </v-icon>
      </div>
    </v-badge>

    <v-icon v-if="globalExpiredReminders.length === 0" size="small">
      fa-solid fa-bell
    </v-icon>
  </div>
  <BaseDialog
    @closeDialog="closeDialog"
    :showDialog="showDialog"
    :width="'var(--d-w-l)'"
  >
    <template #title>
      <v-icon class="text-medium-emphasis mr-2">fa-solid fa-bell</v-icon>Offene
      Ereignisse
    </template>
    <template #content>
      <v-divider class="mx-4 mt-10"></v-divider>
      <v-list>
        <v-list-item
          v-for="(reminder, index) in globalExpiredReminders"
          :key="index"
        >
          <v-list-item-title class="d-flex justify-space-between">
            <div>
              {{ getReminderListTitle(reminder) }}
              <strong>({{ reminder.message }})</strong>
            </div>
            <div>
              <v-btn
                v-if="reminder.linkingCore?.customer"
                icon
                density="compact"
                variant="text"
                @click="openCustomer(reminder.linkingCore?.customer)"
                ><v-icon>fa-solid fa-building</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Kundenkarte öffnen</v-tooltip
                ></v-btn
              >
              <v-btn
                v-if="reminder.linkingCore?.candidate"
                icon
                density="compact"
                variant="text"
                @click="openCandidate(reminder.linkingCore?.candidate)"
                ><v-icon>fa-solid fa-id-badge</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Kandidatenkarte öffnen</v-tooltip
                ></v-btn
              >
            </div>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ reminder.event.eventType }}
            {{ formatDate(reminder.event.eventDate[0]) }} abgelaufen
            {{ reminder.expiredSince }}
          </v-list-item-subtitle>

          <v-divider class="mt-2"></v-divider>
        </v-list-item>
      </v-list>
    </template>
  </BaseDialog>
  <BaseDialog
    :showDialog="showCandidateItem"
    :width="'var(--d-w-l)'"
    :hideTitle="true"
    @closeDialog="closeCandidateOrCustomerItem"
  >
    <template #content>
      <CandidateItem
        :candidate="clickedCandidate"
        :candidateDataInput="clickedCandidateData"
        :interComponentMessage="interComponentMessage"
        :isActive="false"
        :isFullyCollapsed="false"
        :softwareIntegration="softwareIntegration"
        :lastUpdateTimeline="lastUpdateTimeline"
        :user="user"
        @closeDialog="closeCandidateOrCustomerItem"
      />
    </template>
  </BaseDialog>
  <BaseDialog
    :showDialog="showCustomerItem"
    :width="'var(--d-w-l)'"
    :hideTitle="true"
    @closeDialog="closeCandidateOrCustomerItem"
  >
    <template #content>
      <CustomerItem
        :customer="clickedCustomer"
        :interComponentMessage="interComponentMessage"
        :isActive="false"
        :isFullyCollapsed="false"
        :isOpenedAsDialog="true"
        :lastUpdateTimeline="lastUpdateTimeline"
        :loggedInMandantUuids="loggedInMandantUuids"
        :mandants="mandants"
        :softwareIntegration="softwareIntegration"
        :user="user"
        @closeDialog="closeCandidateOrCustomerItem"
      />
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TooltipService } from "@/services/tooltip.service";
import { mapGetters } from "vuex";
import { useDisplay } from "vuetify";
import { ExpiredReminder } from "../../models/linking.model";
import ToastService from "../../services/toast.service";
import { SpinnerService } from "../../services/spinner.service";
import { CandidateList } from "../../models/candidate-list.model";
import { Candidate } from "../../models/candidate.model";
import { Customer } from "../../models/customer.model";
import { CandidateService } from "../../services/api/candidate.service";
import CandidateItem from "../disposition/CandidateItem.vue";
import CustomerItem from "../disposition/CustomerItem.vue";
import { CustomerService } from "../../services/api/customer.service";
import moment from "moment";
import { CandidateDataHelper } from "../../helper/candidate-data.helper";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default defineComponent({
  name: "GlobalReminder",
  components: {
    BaseDialog,
    CustomerItem,
    CandidateItem,
  },
  emits: [""],

  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      clickedCandidate: {} as CandidateList,
      clickedCandidateData: {} as Candidate,
      clickedCustomer: {} as Customer,
      showCandidateItem: false,
      showCustomerItem: false,
      showDialog: false,
      tooltipTimeout: null,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      getExpiredLinkingReminders: "getExpiredLinkingReminders",
      interComponentMessage: "interComponentMessage",
      lastUpdateTimeline: "lastUpdateTimeline",
      loggedInMandantUuids: "getLoggedInMandantUuids",
      mandants: "reducedMandants",
      softwareIntegration: "softwareIntegration",
      user: "user",
    }),
    globalExpiredReminders(): ExpiredReminder[] {
      return this.getExpiredLinkingReminders;
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    closeCandidateOrCustomerItem() {
      this.showCandidateItem = false;
      this.showCustomerItem = false;
      this.$nextTick(() => {
        this.showDialog = true;
      });
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY [um] HH:mm");
    },
    getReminderListTitle(reminder: ExpiredReminder) {
      const candidateName = reminder.description
        ? `${
            reminder.description.firstName
              ? reminder.description.firstName + " "
              : ""
          } ${
            reminder.description.lastName ? reminder.description.lastName : ""
          }`
        : "";

      const customerName = reminder.description?.customerName
        ? reminder.description.customerName
        : "";

      const title = `${candidateName} ${
        (reminder.description?.firstName || reminder.description?.lastName) &&
        customerName
          ? "bei"
          : ""
      } ${customerName}`;
      return title;
    },
    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
    async openCandidate(candidateId: string) {
      try {
        SpinnerService.showSpinner();
        const candidateService = new CandidateService();
        const candidateData = (await candidateService.getCandidateById(
          candidateId
        )) as Candidate;
        this.clickedCandidateData = candidateData;
        const applicationsIndex = candidateData.applications.length - 1;

        this.clickedCandidate =
          CandidateDataHelper.candidateToReducedCandidate(
            candidateData,
            applicationsIndex
          ) ?? ({} as CandidateList);
        this.openCandidateItem();
      } catch (error) {
        ToastService.showError("Fehler beim Laden des Kandidaten");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async openCustomer(customerId: string) {
      try {
        SpinnerService.showSpinner();
        const customerService = new CustomerService();
        const customer = (await customerService.getById(
          customerId
        )) as Customer;
        this.clickedCustomer = customer;
        this.openCustomerItem();
      } catch (error) {
        ToastService.showError("Fehler beim Laden des Kunden");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    openCandidateItem() {
      this.showCustomerItem = false;
      this.showDialog = false;
      this.$nextTick(() => {
        this.showCandidateItem = true;
      });
    },
    openCustomerItem() {
      this.showCandidateItem = false;
      this.showDialog = false;
      this.$nextTick(() => {
        this.showCustomerItem = true;
      });
    },
    showTooltip(event: MouseEvent) {
      const messageCounts = this.globalExpiredReminders.reduce(
        (acc: { [x: string]: number }, reminder: { message: any }) => {
          const message = reminder.message;
          if (!acc[message]) {
            acc[message] = 0;
          }
          acc[message]++;
          return acc;
        },
        {}
      );

      const htmlContent = Object.entries(messageCounts)
        .map(
          ([message, count]) => `<p><strong>${count}x </strong>${message}</p>`
        )
        .join("");

      if (htmlContent) {
        TooltipService.showExpiredEvents(event, htmlContent);
      }
    },
  },
});
</script>

<style scoped>
.reminder-bell {
  cursor: pointer;
}
</style>
