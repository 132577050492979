<template>
  <v-card class="mx-auto">
    <v-container fluid style="background-color: #a8a8a8">
      <v-row dense>
        <v-col cols="6" v-for="template in templates" :key="template?.name">
          <div
            class="wabTemplate"
            @click="selectTemplate(template)"
            :class="{ selected: template.click }"
          >
            <div v-if="template?.imageLink">
              <img :src="template?.imageLink" class="templateImg" />
            </div>
            {{ template.text }}
          </div>
          <div v-if="template?.params">
            <div v-for="param in template?.params" :key="param">
              <v-text-field
                type="text"
                class="w-100"
                :label="param"
                :model-value="param === 'name' ? receiverName : param"
                @input="onInput(template?.name, param, $event)"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { WhatsAppService } from "../../../../services/api/whatsApp.service";
import { ApiResponse } from "../../../../models/api-response.model";
import ToastService from "../../../../services/toast.service";
import { WabTemplate } from "../../../../models/wab/wab-templatemodel";
export default defineComponent({
  name: "WabTemplates",
  emits: ["closeDialog"],
  props: {
    receiverName: {
      type: String,
    },
    wabNumber: {
      type: String,
    },
    wabSenderNumber: {
      type: String,
    },
    selectedMandant: {
      type: String,
    },
    sendTemplateEvent: {
      type: Boolean,
    },
  },
  watch: {
    sendTemplateEvent(newVal: boolean) {
      if (newVal) this.sendTemplate();
    },
  },
  data() {
    return {
      originalTemplates: [],
      templates: this.getWabTemplates(),
      whatsAppService: new WhatsAppService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    onHover(event: any) {
      console.log("Event ", event);
    },
    onLeave(event: any) {
      console.log("Event ", event);
    },
    changeTemplateParameter(
      templateName: string,
      paramName: string,
      value: string
    ) {
      const index = this.templates?.findIndex(
        (t: any) => t.name === templateName
      );

      if (index !== undefined && index !== -1 && this.templates) {
        const originalTemplate = this.originalTemplates.find(
          (t: any) => t.name === templateName
        );

        if (originalTemplate) {
          const template = JSON.parse(JSON.stringify(originalTemplate));

          if (value?.length > 0 && template?.text) {
            template.text = template.text.replace(`{{${paramName}}}`, value);
          }

          this.templates[index] = template;
        }
      }
    },
    fillParams(template: WabTemplate) {
      const parameters: Array<any> = [];
      if (template?.params) {
        template.params.forEach((param: any) => {
          parameters.push({
            type: "text",
            text: this.receiverName as string,
            parameter_name: param,
          });
        });
      }
      template?.parameters ?? parameters;
    },
    async getWabTemplates() {
      new WhatsAppService().getTemplates().then((result: ApiResponse) => {
        this.templates = result.response;
        this.templates.forEach((template: any) => {
          template.click = false;
        });
        this.originalTemplates = JSON.parse(JSON.stringify(result.response));
        this.substituteNames(this.templates);
      });
    },
    onInput(templateName: string, paramName: string, event: any) {
      this.changeTemplateParameter(templateName, paramName, event.target.value);
    },
    selectTemplate(template: any) {
      this.templates.forEach((t: any) => {
        t.click = false;
      });
      template.click = true;
    },
    sendTemplate() {
      if (this.whatsAppService) {
        const selectedTemplate: WabTemplate = this.templates.find(
          (t: any) => t.click
        );
        if (selectedTemplate) {
          this.fillParams(selectedTemplate);
          selectedTemplate.from = this.wabSenderNumber as string;
          selectedTemplate.to = this.wabNumber as string;
          selectedTemplate.mandantUuid = this.selectedMandant as string;
          this.whatsAppService.sendTemplate(selectedTemplate).then(
            () => {
              this.$emit("closeDialog", selectedTemplate.name);
            },
            () => {
              ToastService.showError("Fehler beim Senden der Vorlage");
            }
          );
        } else {
          ToastService.showError("Keine Vorlage gefunden");
        }
      }
    },
    substituteNames(templates: any) {
      templates.forEach((template: any) => {
        if (template.params) {
          this.changeTemplateParameter(
            template.name,
            "name",
            this.receiverName as string
          );
        }
      });
    },
  },
});
</script>

<style scoped>
.whatsapp-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.whatsapp-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-menu {
  position: fixed;
  z-index: 200;
}

.wabTemplate {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid black;
  cursor: pointer;
  padding: 1rem;
}

.templateImg {
  width: 100%;
}

.selected {
  background-color: var(--color-tertiary);
}
</style>
