<template>
  <AdminMenu />
  <AppHeader
    v-if="layout !== AppLayout.classic && layout !== AppLayout.persontobusiness"
  ></AppHeader>
  <v-container :fluid="true">
    <v-row>
      <v-col cols="12" md="3">
        <v-img
          class="mb-2"
          :width="142"
          aspect-ratio="1/1"
          cover
          :src="settingsImage"
        ></v-img>
        <v-card v-bind="vStyle.card">
          <v-card-title>Recurion.ai ist eine fortschrittliche KI</v-card-title>
          <v-card-subtitle>
            die die Ihr Unternehmen revolutioniert!
          </v-card-subtitle>
          <v-card-text>
            Sie übernimmt die Erstellung Nachrichten die speziell auf Ihr
            Unternehmen eingestellt sind, betreut somit Kandidaten, Kunden und
            Zeitarbeiter, generiert Angebote und Mitarbeiterprofile, schreibt
            Stellen aus und rekrutiert damit passende Kandidaten.
          </v-card-text>
          <v-card-text>
            Als digitaler Vertriebsassistent entlastet Recurion
            Vertriebsdisponenten von administrativen Aufgaben, sodass diese sich
            auf die Kommunikation mit Kunden und Kandidaten konzentrieren
            können. Dadurch werden Effizienz und Qualität in der erheblich
            gesteigert.
          </v-card-text>
          <v-card-subtitle></v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="{ ...vStyle.btn, ...props }"
                ><v-icon>fa-solid fa-bars</v-icon>Einstellungen</v-btn
              >
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in filteredItems"
                :key="index"
                @click="setActiveComponent(item.component)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          v-if="
            activeConfigComponent === CompanyConfigComponentTypes.RecurionChat
          "
        >
          <div style="height: 4.43rem"></div>
          <v-card v-bind="vStyle.card">
            <v-card-title class="mr-2 mt-1">
              <v-icon class="mr-2">fa-solid fa-robot</v-icon>
              Recurion
            </v-card-title>
            <RecurionChat />
          </v-card>
        </div>
        <component
          v-else
          :is="activeConfigComponent"
          v-model="aiData"
          @save="updateCompanyConfig"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import AiConfigEmailTemplates from "./AiConfigEmailTemplates.vue";
import AiConfigCompanyPrompts from "./AiConfigCompanyPrompts.vue";
import AiConfigUserPrompts from "./AiConfigUserPrompts.vue";
import AiConfigJobAdTemplate from "./AiConfigJobAdTemplate.vue";
import AiConfigUserCoaching from "./AiConfigUserCoaching.vue";
import { CompanyConfigComponentTypes } from "@/enums/company-config-components.enum";
import { CompanyConfig } from "@/models/company-config.model";
import RecurionChat from "@/components/slide-in-menus/RecurionChat.vue";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { UserHelperService } from "@/services/user-helper.service";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import { MutationType } from "../../../enums/vuex-types.enum";
import ToastService from "../../../services/toast.service";
import { Role } from "../../../models/role.model";
import { AccessRule } from "../../../enums/access-rule.enum";

export default defineComponent({
  name: "ConfigAi",
  components: {
    AdminMenu,
    AiConfigEmailTemplates,
    AiConfigJobAdTemplate,
    AiConfigCompanyPrompts,
    AiConfigUserPrompts,
    AiConfigUserCoaching,
    AppHeader,
    RecurionChat,
  },
  data() {
    const userRole = this.getUserRole();

    return {
      apiService: new CompanyConfigService(),
      items: [
        {
          title: "User Coaching",
          component: CompanyConfigComponentTypes.AiConfigUserCoaching,
          accessRule: AccessRule.configAiUserCoaching,
        },
        {
          title: "Nachrichten Vorlagen",
          component: CompanyConfigComponentTypes.AiConfigEmailTemplates,
          accessRule: AccessRule.configAiEmailTemplates,
        },
        {
          title: "Vorlage Stellenanzeige",
          component: CompanyConfigComponentTypes.AiConfigJobAdTemplate,
          accessRule: AccessRule.configAiJobAdTemplate,
        },
        {
          title: "Prompts: Unternehmensebene",
          component: CompanyConfigComponentTypes.AiConfigCompanyPrompts,
          accessRule: AccessRule.configAiCompanyPrompts,
        },
        {
          title: "Prompts: Benutzerebene",
          component: CompanyConfigComponentTypes.AiConfigUserPrompts,
          accessRule: AccessRule.configAiUserPrompt,
        },
      ],
      companyConfigModel: {} as CompanyConfig,
      CompanyConfigComponentTypes: CompanyConfigComponentTypes,
      activeConfigComponent: CompanyConfigComponentTypes.RecurionChat,
      AppLayout: AppLayout,
      layout: this.$store.getters.appLayout,
      settingsImage: `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.$store.getters.appLayout}/settings.svg`,
      userRole: userRole,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    aiData: {
      get() {
        return this.companyConfigModel.aiData;
      },
      set(value: any) {
        this.companyConfigModel.aiData = value;
      },
    },
    filteredItems() {
      return this.items.filter((item) =>
        this.userRole.permissions.includes(item.accessRule)
      );
    },
  },
  created() {
    this.apiService.getModel().then((companyConfig: CompanyConfig) => {
      this.companyConfigModel = companyConfig;
    });
    this.getUserRole();
  },
  methods: {
    getUserRole(): Role {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },
    setActiveComponent(component: CompanyConfigComponentTypes) {
      this.activeConfigComponent = component;
    },
    updateCompanyConfig() {
      this.apiService
        .putCompanyConfig(this.companyConfigModel)
        .then(() => {
          this.$store.commit(
            MutationType.updateCompanyConfig,
            this.companyConfigModel
          );
          ToastService.showSuccess("AI Konfiguration gespeichert.");
        })
        .catch((err) => {
          console.error(err);
          ToastService.showError(
            "AI Konfiguration konnte nicht gespeichert werden."
          );
        });
    },
  },
});
</script>

<style scoped>
.v-row {
  padding: 2rem 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
}
</style>
