import { AtsRecruitCareerStepType } from "../models/external/zvoove-candidate.model";

// src/enums/employment-types.enum.ts
export enum EmploymentType {
  employed = 1,
  professional_training = 2,
  further_training = 3,
  study = 4,
  public_service = 5,
  schooling = 6,
  miscellaneous = 7,
  course = 8,
}

export enum EmploymentTypeSystemName {
  employed = "employed",
  professional_training = "professional_training",
  further_training = "further_training",
  study = "study",
  public_service = "public_service",
  schooling = "schooling",
  miscellaneous = "miscellaneous",
  course = "course",
}

//Helper
export const employmentTypeToSystemName = (
  employmentType: EmploymentType
): string => {
  return EmploymentTypeSystemName[
    employmentType as unknown as keyof typeof EmploymentTypeSystemName
  ];
};

//Adapter
export const employmentTypeToAtsRecruitCareerStepTypeObject = (
  employmentType: EmploymentType,
  atsRecruitCareerStepTypes: AtsRecruitCareerStepType[]
): AtsRecruitCareerStepType => {
  const systemName = employmentTypeToSystemName(employmentType);
  const atsRecruitCareerStepType = atsRecruitCareerStepTypes.find(
    (atsRecruitCareerStepType) =>
      atsRecruitCareerStepType.SystemName === systemName
  );
  if (!atsRecruitCareerStepType) {
    throw new Error(`Employment type ${employmentType} not found`);
  }
  return atsRecruitCareerStepType;
};
