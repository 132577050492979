<template>
  <div
    v-if="softwareIntegration.gameBar"
    @mouseover="
      isClosed = false;
      updateUserScore();
    "
    @mouseleave="isClosed = true"
    class="open-gamebar-container-hover"
  ></div>
  <v-container
    class="gamebar-container ma-0 pa-0"
    :class="{ 'is-hidden': !isClosed }"
  >
    <v-card
      color="primary-darken-1"
      class="ma-0 pr-4 rounded-b-lg"
      :elevation="1"
    >
      <v-card-text>
        <transition-group name="score-float" tag="div">
          <div
            v-for="(score, index) in floatingScores"
            :key="index"
            class="floating-score"
          >
            +{{ score }}
          </div>
        </transition-group>
        <div class="ml-1">Level {{ todayLevel }}</div>
        <div class="progress-bar-container mt-0">
          <div class="score-display">
            <span>Score: {{ userScore }}</span>
          </div>
          <div
            class="progress-bar"
            :style="{
              width: progressBarWidth + '%',
              backgroundColor: getProgressBarColor,
            }"
            data-tid="gamebar-progress-bar"
            :class="{ growing: isGrowing }"
          >
            <div
              v-for="bubble in bubbles"
              :key="bubble.id"
              :style="bubble.style"
              class="bubble"
            ></div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <transition-group name="message-float" tag="div">
      <div v-if="floatingMessage" class="floating-message">
        {{ floatingMessage }}
      </div>
    </transition-group>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { Timeline } from "../../models/timeline.model";
import {
  PhoneCallOption,
  TimelineEntryType,
} from "../../enums/timeline-entry-types.enum";
import { LinkingStatus } from "../../enums/dependency.enum";
import moment from "moment";
import { User } from "../../models/user.model";
import {
  PerfInsight,
  PerfEntryPattern,
  UserTriggerSalesActivity,
} from "@/models/perf.model";
import { MutationType } from "../../enums/vuex-types.enum";
import { UserService } from "../../services/api/user.service";
import ToastService from "../../services/toast.service";
import { AiUserCoaching } from "@/models/company-config.model";
import { ArrayUtils } from "../../helper/remove-duplicates.helper";
import {
  RecurionAnswerHandles,
  RecurionChatbotType,
} from "../../enums/recurion-chatbot-types.enum";
import { RecurionChatMenu } from "../../models/slide-in-menus.model";

export default defineComponent({
  name: "GameBar",
  data() {
    return {
      testscore: 50,
      bubbles: [] as { id: number; style: any }[],
      failureCount: {
        rejectionCandidate: 0,
        rejection: 0,
        rejectionPlacement: 0,
        notReached: 0,
      },
      floatingMessage: "",
      floatingScores: [] as number[],
      isClosed: true,
      isGrowing: false,
      maxScore: 200,
      nextEntryNoScore: false,
      perfInsight: {} as PerfInsight,
      showFloatingScore: false,
      timelines: [] as Timeline[],
      todayLevel: 1,
      userScore: 0,
    };
  },
  computed: {
    ...mapState({
      aiUserCoaching: (state: any) =>
        state.company?.aiData?.prompt?.aiUserCoaching as AiUserCoaching,
      hasAiCoaching: (state: any) =>
        state.company?.loggedInUser?.config?.hasAiCoaching,
      perfState: (state: any) => state.perfState,
      perfDaily: (state: any) =>
        state.company?.loggedInUser?.insightsBundle?.perfDaily,
    }),
    ...mapGetters({
      user: "user",
      softwareIntegration: "softwareIntegration",
    }),
    progressBarWidth() {
      return (this.userScore / this.maxScore) * 100;
    },
    getProgressBarColor() {
      switch (this.todayLevel) {
        case 1:
          return "var(--color-error)";
        case 2:
          return "var(--color-warning)";
        case 3:
          return "var(--color-success)";
        case 4:
          return "var(--color-secondary)";
        case 5:
          return "#13ACDC";
        default:
          return "#FFD700"; // Gold for Level > 5
      }
    },
  },
  watch: {
    perfState: {
      handler(newVal) {
        this.handlerNewTimelineEntry(newVal);
      },
      deep: true,
    },
    userScore(newVal) {
      if (newVal > this.maxScore) {
        this.todayLevel++;
        ToastService.showSuccess(`Super Aktivität: Level ${this.todayLevel}`);
        this.maxScore = this.todayLevel * this.todayLevel * 200;
      }
    },
  },
  mounted() {
    this.getOrCreateTodayPerfInsights().then((insight) => {
      this.perfInsight = insight;
    });
  },
  methods: {
    checkIfIncrementFailureCount(
      entryType: TimelineEntryType | LinkingStatus,
      entryDescription: string
    ) {
      let message = "";
      let extraPoints = 0;
      let multiplier = false;

      switch (entryType) {
        case TimelineEntryType.phoneCallCandidate:
        case TimelineEntryType.phoneCallCustomer:
          if (entryDescription === PhoneCallOption.notReached) {
            this.failureCount.rejectionCandidate++;
            message = `Bleib jetzt dran! +${this.failureCount.rejectionCandidate} extra Punkte!`;
            extraPoints = this.failureCount.rejectionCandidate;
            multiplier = false;
          }
          break;

        case LinkingStatus.noneProfile:
          this.failureCount.rejectionPlacement++;
          message = `Einsatz klappt nicht! Kopf hoch: x${this.failureCount.rejectionPlacement} Punkte!`;
          extraPoints = this.failureCount.rejectionPlacement;
          multiplier = true;
          break;

        case LinkingStatus.rejectedCandidate:
          this.failureCount.rejectionCandidate++;
          message = `Absage vom Kandidat! Kein Beinbruch! x${this.failureCount.rejectionCandidate} Punkte`;
          extraPoints = this.failureCount.rejectionCandidate;
          multiplier = true;
          break;

        case LinkingStatus.rejected:
        case LinkingStatus.rejectedUnattractive:
          this.failureCount.rejection++;
          message = `Aufgeräumt! x${this.failureCount.rejection} Punkte`;
          extraPoints = this.failureCount.rejection;
          multiplier = true;
          break;

        case LinkingStatus.linkingSwitchedBack:
        case LinkingStatus.linkingRemoved:
          this.nextEntryNoScore = true;
          break;

        default:
          break;
      }

      return { message, extraPoints, multiplier };
    },
    createBubbles(count: number) {
      this.bubbles = [];
      for (let i = 0; i < count; i++) {
        const size = Math.random() * 10 + 3;
        const left = Math.random() * 100;
        const delay = Math.random() * 3;

        this.bubbles.push({
          id: i,
          style: {
            width: `${size}px`,
            height: `${size}px`,
            left: `${left}%`,
            animationDelay: `${delay}s`,
          },
        });
      }
    },
    fadeOutBubbles() {
      const interval = setInterval(() => {
        if (this.bubbles.length > 0) {
          this.bubbles.shift();
        } else {
          clearInterval(interval);
        }
      }, 100);
      setTimeout(() => {
        if (!this.isClosed) this.toggleDrawer();
      }, 5000);
    },
    async getOrCreateTodayPerfInsights() {
      const today = moment().format("YYYY-MM-DD");
      let insight = undefined;
      if (this.perfDaily && this.perfDaily.length > 0) {
        insight = this.perfDaily.find(
          (insight: PerfInsight) => insight.date === today
        );
      }

      if (!insight) {
        insight = { date: today, value: 0, entries: [] } as PerfInsight;
      }

      return insight;
    },
    async handlerNewTimelineEntry(newVal: any) {
      if (this.nextEntryNoScore) {
        this.nextEntryNoScore = false;
        return;
      }
      const newTimeline =
        newVal.recentTimelines[newVal.recentTimelines.length - 1];
      const newScore = newVal.recentScore[newVal.recentScore.length - 1];
      const newEntryType =
        newVal.recentLinkingStatusOrTimelineEntryType[
          newVal.recentLinkingStatusOrTimelineEntryType.length - 1
        ];
      const newEntryDescription =
        newVal.recentDescription[newVal.recentDescription.length - 1];

      if (this.isClosed) this.toggleDrawer();

      const { message, extraPoints, multiplier } =
        this.checkIfIncrementFailureCount(
          newEntryType.entryType,
          newEntryDescription
        );

      this.perfInsight = await this.getOrCreateTodayPerfInsights();
      this.updateUserScore();
      let score = newScore;
      if (multiplier) {
        score = newScore * extraPoints;
      } else if (message) {
        score = newScore + extraPoints;
      }
      if (!score) score = 0;
      this.perfInsight.value = this.perfInsight.value + parseInt(score);
      this.perfInsight.entries.push(newEntryType);

      if (
        newTimeline.entryType !== RecurionAnswerHandles.timelineEntryType &&
        this.user.config?.hasAiCoaching
      ) {
        this.checkForAiCoachingReasons();
      } else {
        this.saveTodayPerfInsight();
      }
      if (this.softwareIntegration.gameBar) {
        setTimeout(() => {
          this.floatingMessage = newTimeline.entryType;
          if (message) this.floatingMessage = message;
          this.increaseScore(score);
          this.timelines.push(newTimeline);

          setTimeout(() => {
            this.floatingMessage = "";
          }, 3000);
        }, 1000);
      }
    },
    async increaseScore(amount: number) {
      this.isGrowing = true;
      this.createBubbles(this.progressBarWidth + this.todayLevel * 20);

      if (amount < 0) {
        this.userScore += amount;
      } else {
        const parts = this.splitScore(amount);
        parts.forEach((part, index) => {
          setTimeout(() => {
            this.floatingScores.push(part);
            this.userScore += part;
            setTimeout(() => {
              this.floatingScores.shift();
            }, 3000);
          }, index * 500);
        });
      }

      setTimeout(() => {
        this.isGrowing = false;
        this.fadeOutBubbles();
      }, Math.max(this.splitScore(amount).length * 500 + 1000, 1000));
    },
    toggleDrawer() {
      if (this.softwareIntegration.gameBar) {
        this.isClosed = !this.isClosed;
      } else {
        this.isClosed = true;
      }
    },
    async saveTodayPerfInsight() {
      const userService = new UserService();
      const updatedUser = (await userService.updatePerfInsights(
        this.user._id,
        this.perfInsight
      )) as unknown as User;
      this.$store.commit(
        MutationType.setUserPerfInsights,
        updatedUser?.insightsBundle?.perfDaily
      );
    },
    splitScore(amount: number): number[] {
      const parts = [];
      let randomRange;

      if (amount > 100) {
        randomRange = 20;
      } else if (amount > 50) {
        randomRange = 10;
      } else if (amount > 20) {
        randomRange = 5;
      } else {
        randomRange = 2;
      }

      while (amount > 0) {
        const part = Math.min(
          amount,
          Math.floor(Math.random() * randomRange) + 1
        );
        parts.push(part);
        amount -= part;
      }
      return parts;
    },
    async updateUserScore() {
      if (!this.perfInsight.value)
        this.perfInsight = await this.getOrCreateTodayPerfInsights();
      this.userScore = this.perfInsight.value;
    },
    checkTriggerPattern(pattern: PerfEntryPattern): boolean {
      if (!this.perfInsight?.entries || !pattern.triggerPattern?.length) {
        return false;
      }

      const objectEntries = this.perfInsight.entries.filter(
        (entry) => typeof entry === "object" && entry !== null
      );

      if (objectEntries.length < pattern.triggerPattern.length) {
        return false;
      }

      const lastEntries = objectEntries.slice(-pattern.triggerPattern.length);

      const matches = pattern.triggerPattern.every((patternEntry, index) => {
        const entry = lastEntries[index];
        const match =
          entry.entryType === patternEntry.entryType &&
          entry.hasPhoneCallBefore === patternEntry.hasPhoneCallBefore;
        return match;
      });

      return matches;
    },

    checkTriggerCountedActivity(activity: UserTriggerSalesActivity): boolean {
      if (!this.perfInsight?.entries || !activity.time || !activity.entryType) {
        return false;
      }

      const targetTime = moment(activity.time, "HH:mm");
      const currentTime = moment();

      if (!currentTime.isAfter(targetTime)) {
        return false;
      }

      const matchingCount = this.perfInsight.entries.filter((entry) => {
        if (typeof entry === "string") {
          return entry === activity.entryType;
        }
        return entry.entryType === activity.entryType;
      }).length;

      return activity.isSmallerThanCountForTrigger
        ? matchingCount < activity.count
        : matchingCount >= activity.count;
    },

    async checkForAiCoachingReasons() {
      if (!this.user.config?.hasAiCoaching) return;
      const coachingPrompts: { title: string; prompt: string }[] = [];

      if (this.aiUserCoaching?.perfEntryPatterns) {
        this.aiUserCoaching.perfEntryPatterns.forEach((pattern) => {
          if (this.checkTriggerPattern(pattern)) {
            coachingPrompts.push({
              title: pattern.title,
              prompt: pattern.coachingPrompt,
            });
          }
        });
      }

      if (this.aiUserCoaching?.userTriggerSalesActivity) {
        this.aiUserCoaching.userTriggerSalesActivity.forEach((activity) => {
          if (this.checkTriggerCountedActivity(activity)) {
            coachingPrompts.push({
              title: activity.title,
              prompt: activity.coachingPrompt,
            });
          }
        });
      }

      if (!this.perfInsight?.aiCoachings) {
        this.perfInsight.aiCoachings = [];
      }

      const filteredPrompts = coachingPrompts.filter(
        (prompt) =>
          !this.perfInsight ||
          !this.perfInsight.aiCoachings?.includes(prompt.title)
      );
      // only one coaching per reason an day
      if (this.perfInsight?.aiCoachings) {
        const uniqueCoachings = ArrayUtils.removeDuplicates(
          coachingPrompts.map((coaching) => coaching.title)
        );
        this.perfInsight.aiCoachings = uniqueCoachings;
      }
      let prompt = undefined;

      // add titles for today's ai coachings to prevent multiple coachings
      filteredPrompts.forEach((prompt) => {
        if (
          this.perfInsight?.aiCoachings &&
          !this.perfInsight.aiCoachings.includes(prompt.title)
        ) {
          this.perfInsight.aiCoachings.push(prompt.title);
        }
      });
      this.saveTodayPerfInsight();

      if (filteredPrompts.length > 0) {
        prompt = JSON.stringify(filteredPrompts);
      }

      if (prompt) {
        const userService = new UserService();
        let userConfig = this.user.config;
        userConfig.hasOpenAiCoachingPrompt = prompt;

        await userService.updateUserConfig(userConfig);

        this.$store.commit(MutationType.setRecurionChat, {
          isActive: true,
          isClosed: false,
          chatbotType: RecurionChatbotType.coach,
          initPrompt: prompt,
        } as RecurionChatMenu);
      }
    },
  },
});
</script>

<style scoped>
.gamebar-container {
  cursor: pointer;
  top: -1rem;
  right: 0;
  z-index: 9000;
  transform: translateY(-100%);
  position: fixed;
  width: 30rem;
  max-height: 30rem;
  transition: transform 0.5s ease-in-out;
}

.gamebar-container.is-hidden {
  transform: translateY(0%);
}

.open-gamebar-container-hover {
  cursor: pointer;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 5rem;
  height: 1rem;
}

.progress-bar-container {
  background-color: var(--color-progress-bar-bg);
  border: 2px solid var(--border-medium);
  width: 100%;
  height: 2rem;
  border-radius: var(--border-radius-dialog);
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 100%;
  border-radius: var(--border-radius-elements);
  transition: width 0.5s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar.growing {
  animation: pulse 0.4s infinite alternate;
}

.bubble {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: bubble 3s infinite ease-in-out;
}

@keyframes bubble {
  0% {
    transform: translateY(-10%) scale(0);
    opacity: 0;
  }
  5% {
    transform: translateY(0) scale(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-150%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-200%) scale(0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.score-display {
  font-size: 1.2rem;
  color: white;
  z-index: 1;
  position: absolute;
  left: 50%;
  text-shadow: 1px 1px 2px var(--color-primary-darken-1),
    -1px -1px 2px var(--color-primary-darken-1),
    1px -1px 2px var(--color-primary-darken-1),
    -1px 1px 2px var(--color-primary-darken-1);
  transform: translateX(-50%);
}

.floating-score {
  position: absolute;
  z-index: 10;
  left: 63%;
  transform: translateX(-50%) translateY(50%);
  font-size: 3rem;
  animation: float 1s ease-out forwards, glow 1s infinite alternate;
}

.floating-message {
  max-width: 80%;
  position: absolute;
  z-index: 9999;
  left: 0;
  transform: translateX(-50%) translateY(50%);
  font-size: 2rem;
  animation: float-message 10s ease-out forwards, glow 10s infinite alternate;
}

@keyframes float {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(120%, -3rem) scale(1.5);
    opacity: 0;
  }
}

@keyframes float-message {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(120%, 3rem) scale(1.5);
    opacity: 0;
  }
}

@keyframes glow {
  0% {
    color: var(--color-surface);
    text-shadow: 1px 1px 2px var(--color-primary-darken-1),
      -1px -1px 2px var(--color-primary-darken-1),
      1px -1px 2px var(--color-primary-darken-1),
      -1px 1px 2px var(--color-primary-darken-1);
  }
  100% {
    color: var(--color-surface-bright);
    text-shadow: 1px 1px 2px var(--color-surface),
      -1px -1px 2px var(--color-surface), 1px -1px 2px var(--color-surface),
      -1px 1px 2px var(--color-surface);
  }
}
</style>
