<template>
  <div class="map-container">
    <div id="map" ref="mapContainer" class="map"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, h, render } from "vue";
import type { Map as LMap, Marker, Icon, LatLngTuple } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { JobAdLead } from "../../../models/job-ad-lead.model";
import JobAdLeadPopup from "./JobAdLeadPopup.vue";

export default defineComponent({
  name: "JobAdLeadsMap",
  props: {
    jobAdLeads: {
      type: Array as PropType<JobAdLead[]>,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      pinIcon: `${this.$store.getters.getEnv.assetsUrl}/icons/joblead-pin.svg`,
      map: null as LMap | null,
      markers: [] as Marker[],
    };
  },

  computed: {
    customIcon(): Icon {
      return L.icon({
        iconUrl: this.pinIcon,
        iconSize: [64, 64],
        iconAnchor: [32, 64],
        popupAnchor: [0, -64],
      });
    },
  },

  mounted() {
    this.initMap();
  },
  watch: {
    jobAdLeads: {
      handler() {
        this.addJobAdLeadsMarkers();
      },
      deep: true,
    },
  },

  beforeUnmount() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },

  methods: {
    initMap() {
      if (!this.map) {
        const map = L.map("map");
        this.map = map;

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);

        this.addJobAdLeadsMarkers();
      }
    },

    addJobAdLeadsMarkers() {
      const map = this.map;
      if (!map) return;

      if (this.markers && this.markers.length) {
        this.markers.forEach((marker: { remove: () => any }) =>
          marker.remove()
        );
      }
      this.markers = [];

      const validCoordinates: LatLngTuple[] = this.jobAdLeads
        .filter((lead) => lead.company?.latitude && lead.company?.longitude)
        .map(
          (lead) =>
            [lead.company?.latitude, lead.company?.longitude] as LatLngTuple
        );

      if (validCoordinates.length === 0) return;

      const bounds = L.latLngBounds(validCoordinates);

      this.jobAdLeads.forEach((lead: JobAdLead) => {
        if (lead.company?.latitude && lead.company?.longitude) {
          const vNode = h(JobAdLeadPopup, { lead });
          const container = document.createElement("div");
          render(vNode, container);
          const popupContent = container.innerHTML;

          const marker = L.marker(
            [lead.company.latitude, lead.company.longitude] as LatLngTuple,
            { icon: this.customIcon }
          );

          marker.addTo(map as LMap).bindPopup(popupContent);
          this.markers.push(marker);
        }
      });

      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 15,
        animate: true,
      });
    },
  },
});
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.map {
  width: 100%;
  height: var(--job-ad-lead-map-height);
  border-radius: var(--border-radius-elements);
  border: 1px solid var(--color-border);
  margin-top: 1rem;
  cursor: grab;
}

.map:active {
  cursor: grabbing;
}

/* Leaflet Marker Icon Fix */
:global(.leaflet-default-icon-path) {
  background-image: url("leaflet/dist/images/marker-icon.png");
}

:global(.leaflet-default-shadow-path) {
  background-image: url("leaflet/dist/images/marker-shadow.png");
}
</style>
