<template>
  <!--TODO: Make it vuetifyful:-->
  <div class="popup-content">
    <h3>
      <a
        v-if="lead.link"
        :href="lead.link"
        target="_blank"
        class="document-link"
      >
        {{ lead.jobTitle }}
      </a>
      <template v-else>{{ lead.jobTitle }}</template>
    </h3>
    <hr />

    <div class="document-links">
      <template v-if="lead.internLinkTxt">
        <a :href="lead.internLinkTxt" target="_blank" class="document-icon">
          <i class="far fa-file-lines"></i>
        </a>
        Text öffnen
      </template>
      &nbsp;
      <template v-if="lead.internLinkPdf">
        <a :href="lead.internLinkPdf" target="_blank" class="document-icon">
          <i class="far fa-file-pdf"></i>
        </a>
        PDF öffnen
      </template>
    </div>

    <hr />

    <table class="info-table">
      <tbody>
        <tr>
          <td><strong>Firma:</strong></td>
          <td>{{ lead.company?.name || "N/A" }}</td>
        </tr>
        <tr>
          <td><strong>Ort:</strong></td>
          <td>{{ lead.company?.postalCode }} {{ lead.company?.city }}</td>
        </tr>
        <tr v-if="lead.company?.address">
          <td><strong>Adresse:</strong></td>
          <td>{{ lead.company?.address }} {{ lead.company?.addressNumber }}</td>
        </tr>
        <tr v-if="lead.company?.phone">
          <td><strong>Tel:</strong></td>
          <td>{{ lead.company?.phone }}</td>
        </tr>
        <tr v-if="lead.company?.email">
          <td><strong>Email:</strong></td>
          <td>{{ lead.company?.email }}</td>
        </tr>
        <tr v-if="lead.company?.internet">
          <td><strong>Web:</strong></td>
          <td>
            <a :href="lead.company?.internet" target="_blank">{{
              lead.company?.internet
            }}</a>
          </td>
        </tr>
        <tr v-if="lead.company?.employees">
          <td><strong>Mitarbeiter:</strong></td>
          <td>{{ lead.company?.employees }}</td>
        </tr>
        <tr v-if="lead.branche">
          <td><strong>Branche:</strong></td>
          <td>{{ lead.branche }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { JobAdLead } from "@/models/job-ad-lead.model";

export default defineComponent({
  name: "JobAdLeadPopup",
  props: {
    lead: {
      type: Object as PropType<JobAdLead>,
      required: true,
    },
  },
});
</script>

<style scoped>
.popup-content {
  padding: 0.8rem;
  max-width: 20rem;
}

.popup-content h3 {
  margin: 0 0 10px 0;
  color: var(--color-primary);
  font-size: 1.1em;
}

.document-links {
  margin: 0.5rem 0;
}

.document-icon {
  color: var(--color-primary);
  font-size: 1.5rem;
  transition: ease all 0.3s;
}

.document-icon:hover {
  color: var(--color-secondary);
}

.document-link {
  color: var(--color-primary);
  text-decoration: none;
  transition: ease all 0.3s;
}

.document-link:hover {
  color: var(--color-secondary);
  text-decoration: none;
}

.info-table {
  border-collapse: separate;
  border-spacing: 0.5rem 0;
  width: 100%;
  margin: 0.5rem 0;
}

.info-table td {
  padding: 0.2rem 0;
  vertical-align: top;
}

.info-table td:first-child {
  width: 6rem;
  text-align: right;
}

.info-table a {
  color: var(--color-primary);
  text-decoration: none;
}

.info-table a:hover {
  text-decoration: underline;
}
</style>
