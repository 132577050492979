import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-end" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.showDialog,
    showActions: true,
    onCloseDialog: _ctx.closeModal,
    width: 'var(--d-w-xl)'
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "mr-2",
          src: _ctx.logoPayFlow,
          alt: "PayFlow Logo",
          style: {"width":"4rem"}
        }, null, 8, _hoisted_2),
        _createTextVNode(" - " + _toDisplayString(_ctx.isEdit ? "bearbeiten" : "hinzufügen"), 1)
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, { ref: "form" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_divider, { class: "mb-5" }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.localPayFlow.employeeNumber,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPayFlow.employeeNumber) = $event)),
                        label: "Personalnummer"
                      }), null, 16, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.localPayFlow.firstName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localPayFlow.firstName) = $event)),
                        label: "Vorname"
                      }), null, 16, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.localPayFlow.lastName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localPayFlow.lastName) = $event)),
                        label: "Nachname"
                      }), null, 16, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.localPayFlow.infoType,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localPayFlow.infoType) = $event)),
                        items: _ctx.payrollInfoTypeOptions,
                        "item-title": "label",
                        "item-value": "label",
                        label: "Art"
                      }), null, 16, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  (_ctx.isMiscellaneous)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.miscellaneous,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localPayFlow.miscellaneous) = $event)),
                            label: "Beschreibung"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasMonetaryAmount)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 1,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.monetaryAmount,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localPayFlow.monetaryAmount) = $event)),
                            label: "Betrag",
                            type: "number"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasHours)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 2,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.hours,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localPayFlow.hours) = $event)),
                            label: "Stunden",
                            type: "number"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasDateRange)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 3,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.dateFrom,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localPayFlow.dateFrom) = $event)),
                            label: "von Datum",
                            type: "date"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasDateRange)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 4,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.dateTo,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localPayFlow.dateTo) = $event)),
                            label: "bis Datum",
                            type: "date"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.localPayFlow.monthly)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 5,
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                            items: _ctx.payrollMonths,
                            modelValue: _ctx.localPayFlow.payrollMonth,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localPayFlow.payrollMonth) = $event)),
                            label: "Abrechnungsmonat"
                          }), null, 16, ["items", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        modelValue: _ctx.localPayFlow.monthly,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localPayFlow.monthly) = $event)),
                        color: "primary",
                        label: "monatlich",
                        variant: "outlined"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.localPayFlow.info,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localPayFlow.info) = $event)),
                        label: "Info"
                      }), null, 16, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.hasBankAccountData && _ctx.localPayFlow.bankAccount)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "5"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.bankAccount.iban,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localPayFlow.bankAccount.iban) = $event)),
                            label: "IBAN"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.bankAccount.bank.name,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localPayFlow.bankAccount.bank.name) = $event)),
                            label: "Bank Name"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.bankAccount.bank.bic,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localPayFlow.bankAccount.bank.bic) = $event)),
                            label: "BIC"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                            modelValue: _ctx.localPayFlow.bankAccount.differentAccountHolder,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localPayFlow.bankAccount.differentAccountHolder) = $event)),
                            label: "Abweichender Kontoinhaber"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.getBankDataFromErp(_ctx.localPayFlow.employeeNumber))),
                            icon: "",
                            variant: "text",
                            density: "compact"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode(" fa-solid fa-cloud-arrow-down ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "abort",
        onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.closeModal()))
      }, {
        default: _withCtx(() => _cache[19] || (_cache[19] = [
          _createTextVNode("Abbrechen")
        ])),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        color: "success",
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? "Speichern" : "Hinzufügen"), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}