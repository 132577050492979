import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onContextmenu", "onLongpress"]
const _hoisted_2 = {
  class: "px-1",
  style: {"width":"15%"}
}
const _hoisted_3 = { class: "text-medium-emphasis font-weight-medium" }
const _hoisted_4 = {
  class: "px-1",
  style: {"width":"15%"}
}
const _hoisted_5 = {
  class: "px-1",
  style: {"width":"20%"}
}
const _hoisted_6 = {
  class: "px-1",
  style: {"width":"20%"}
}
const _hoisted_7 = {
  class: "px-1",
  style: {"width":"10%"}
}
const _hoisted_8 = {
  class: "px-1",
  style: {"width":"15%"}
}
const _hoisted_9 = {
  class: "px-1",
  style: {"width":"5%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { class: "my-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          sm: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createTextVNode("Stellen hinzufügen")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "form",
                      modelValue: _ctx.valid,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.valid) = $event)),
                      onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.title,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentJob.title) = $event)),
                                  label: "Stellenbezeichnung",
                                  placeholder: "Stellenbezeichnung eingeben",
                                  density: "compact",
                                  rules: [_ctx.rules.required],
                                  "data-tid": "new-form-job-title"
                                }), null, 16, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentLocation.postalCode,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentLocation.postalCode) = $event)),
                                  label: "PLZ",
                                  type: "number",
                                  placeholder: "PLZ",
                                  density: "compact",
                                  "data-tid": "new-form-postal-code",
                                  rules: [_ctx.rules.postalCode, _ctx.rules.required]
                                }), null, 16, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "7" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentLocation.city,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentLocation.city) = $event)),
                                  label: "Ort",
                                  placeholder: "Ort",
                                  density: "compact",
                                  "data-tid": "new-form-city",
                                  rules: [_ctx.rules.city, _ctx.rules.required]
                                }), null, 16, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.tasks,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentJob.tasks) = $event)),
                                  label: "Aufgaben",
                                  placeholder: "Aufgaben beschreiben",
                                  rows: "3",
                                  "auto-grow": "",
                                  density: "compact",
                                  "data-tid": "new-form-tasks"
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.profile,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentJob.profile) = $event)),
                                  label: "Profil",
                                  placeholder: "Profil beschreiben",
                                  rows: "3",
                                  "auto-grow": "",
                                  density: "compact",
                                  "data-tid": "new-form-profile"
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.salaryFrom,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentJob.salaryFrom) = $event)),
                                  label: "Gehalt von",
                                  type: "number",
                                  density: "compact",
                                  "data-tid": "new-form-salary-from"
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.salaryTo,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentJob.salaryTo) = $event)),
                                  label: "Gehalt bis",
                                  type: "number",
                                  density: "compact",
                                  "data-tid": "new-form-salary-to"
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentJob.salaryPeriod,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentJob.salaryPeriod) = $event)),
                                  label: "Währung / Zeitraum",
                                  placeholder: "z.B. EUR/Jahr",
                                  density: "compact",
                                  "data-tid": "new-form-salary-period"
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  modelValue: _ctx.currentJob.socialMedia,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentJob.socialMedia) = $event)),
                                  label: "Social Media",
                                  density: "compact",
                                  "data-tid": "new-form-socialmedia",
                                  disabled: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "9" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_autocomplete, _mergeProps(_ctx.vStyle.input, {
                                  chips: "",
                                  label: "Bundesagentur für Arbeit Berufsbezeichnungen",
                                  modelValue: _ctx.currentJob.categoriesBfA,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currentJob.categoriesBfA) = $event)),
                                  items: _ctx.allCategoriesBfA,
                                  multiple: "",
                                  density: "compact",
                                  "data-tid": "edit-form-bfa-categories",
                                  "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                                  "onClick:prependInner": _cache[10] || (_cache[10] = ($event: any) => (
                    _ctx.checkForBfAJobCategories(_ctx.currentJob.title)
                  ))
                                }), null, 16, ["modelValue", "items"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  modelValue: _ctx.currentJob.supportedBfAJobAd,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentJob.supportedBfAJobAd) = $event)),
                                  label: "Betreuung",
                                  density: "compact",
                                  "data-tid": "new-form-bfa-support",
                                  disabled: _ctx.currentJob.categoriesBfA.length === 0
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.currentLocation.mandants[0],
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentLocation.mandants[0]) = $event)),
                                  items: _ctx.mandanten,
                                  "item-title": "name",
                                  "item-value": "uuid",
                                  label: "Niederlassung wählen",
                                  placeholder: "Niederlassung wählen",
                                  density: "compact",
                                  "data-tid": "new-form-mandant",
                                  rules: [_ctx.rules.required]
                                }), null, 16, ["modelValue", "items", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
                                  type: "submit",
                                  color: "success",
                                  "data-tid": "new-form-submit",
                                  disabled: !_ctx.valid
                                }), {
                                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                                    _createTextVNode(" Auf Liste setzen ")
                                  ])),
                                  _: 1
                                }, 16, ["disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onSubmit"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider),
                _createVNode(_component_v_container, { class: "d-flex my-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.publishOnHomepage,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.publishOnHomepage) = $event)),
                      label: "Direkt auf allen eigenen Stellenbörsen veröffentlichen",
                      density: "compact",
                      "data-tid": "new-form-publish-on-homepage"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_v_checkbox, {
                      modelValue: _ctx.publishOnBfA,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.publishOnBfA) = $event)),
                      label: "Auf Bundesagentur für Arbeit veröffentlichen",
                      density: "compact",
                      style: {"font-size":"0.9em"},
                      "data-tid": "new-form-publish-on-bfa"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "d-flex mt-0 pt-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
                      onClick: _ctx.postJobList,
                      color: "warning",
                      "data-tid": "new-form-post-joblist"
                    }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { class: "mr-2" }, {
                          default: _withCtx(() => _cache[37] || (_cache[37] = [
                            _createTextVNode("fa-solid fa-rocket")
                          ])),
                          _: 1
                        }),
                        _cache[38] || (_cache[38] = _createTextVNode(" Liste ausschreiben! "))
                      ]),
                      _: 1
                    }, 16, ["onClick"]),
                    _createVNode(_component_v_spacer)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          sm: "8"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
              default: _withCtx(() => [
                _createVNode(_component_v_table, null, {
                  default: _withCtx(() => [
                    _cache[39] || (_cache[39] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Stellenbezeichnung"),
                        _createElementVNode("th", null, "Ort"),
                        _createElementVNode("th", null, "Aufgaben"),
                        _createElementVNode("th", null, "Profil"),
                        _createElementVNode("th", null, "Gehalt von bis"),
                        _createElementVNode("th", null, "Mandant"),
                        _createElementVNode("th", null, "Status")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobAds, (jobAd) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: "longpress",
                          key: jobAd._id,
                          onContextmenu: _withModifiers(($event: any) => (_ctx.openContextMenu($event, jobAd)), ["prevent"]),
                          onLongpress: ($event: any) => (_ctx.openContextMenu($event, jobAd))
                        }, [
                          _createElementVNode("td", _hoisted_2, [
                            _createTextVNode(_toDisplayString(jobAd.title) + " ", 1),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(jobAd.supportedBfAJobAd
                    ? "- betreute Stelle Bundesagentur für Arbeit"
                    : ""), 1)
                          ]),
                          _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getLocationById(jobAd.locationId)?.postalCode) + " " + _toDisplayString(_ctx.getLocationById(jobAd.locationId)?.city), 1),
                          _createElementVNode("td", _hoisted_5, _toDisplayString(jobAd.tasks), 1),
                          _createElementVNode("td", _hoisted_6, _toDisplayString(jobAd.profile), 1),
                          _createElementVNode("td", _hoisted_7, _toDisplayString(jobAd.salaryFrom) + " - " + _toDisplayString(jobAd.salaryTo) + " " + _toDisplayString(jobAd.salaryPeriod), 1),
                          _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.getMandantNameById(jobAd.locationId)), 1),
                          _createElementVNode("td", _hoisted_9, [
                            (jobAd.status === _ctx.PostingStatus.requested)
                              ? (_openBlock(), _createBlock(_component_v_icon, {
                                  key: 0,
                                  class: "fas fa-newspaper"
                                }))
                              : (jobAd.status === _ctx.PostingStatus.posted)
                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                    key: 1,
                                    class: "fas fa-square-check"
                                  }))
                                : _createCommentVNode("", true)
                          ])
                        ], 40, _hoisted_1))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.showContextMenu,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showContextMenu) = $event)),
          style: _normalizeStyle({
        top: _ctx.contextMenuPosition.y + 'px',
        left: _ctx.contextMenuPosition.x + 'px',
      })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "px-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    (_ctx.selectedJob.uuid && _ctx.softwareIntegration.zvooveRecruit)
                      ? (_openBlock(), _createBlock(_component_v_list, {
                          key: 0,
                          class: "px-2 context-menu-item",
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.openJodAdInAtsRecruit(_ctx.selectedJob.uuid))),
                          id: "context-menu-open-ats"
                        }, {
                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                            _createTextVNode(" in ATS Recruit ansehen ")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_list, {
                      class: "px-2 context-menu-item",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.editJob(_ctx.selectedJob))),
                      "data-tid": "context-menu-edit"
                    }, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode(" Eintrag bearbeiten ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_list, {
                      class: "px-2 context-menu-item",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.deleteJob((_ctx.selectedJob as JobAd)._id))),
                      "data-tid": "context-menu-delete"
                    }, {
                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                        _createTextVNode(" Eintrag löschen ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_list, {
                      class: "px-2 context-menu-item",
                      onClick: _ctx.removeAllPosted,
                      "data-tid": "context-menu-remove-posted"
                    }, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode(" Alle Veröffentlichten entfernen ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_list, {
                      class: "px-2 context-menu-item",
                      onClick: _ctx.removeAllEntries,
                      "data-tid": "context-menu-remove-all"
                    }, {
                      default: _withCtx(() => _cache[44] || (_cache[44] = [
                        _createTextVNode(" Alle Einträge löschen ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "style"])
      ]),
      _: 1
    }),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showModal,
      showActions: true,
      width: 'var(--d-w-l)',
      onCloseDialog: _ctx.closeModal
    }, {
      title: _withCtx(() => _cache[45] || (_cache[45] = [
        _createTextVNode("Bearbeiten")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "form",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.valid) = $event)),
              onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                  label: "Stellenbezeichnung",
                  modelValue: _ctx.currentJob.title,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.currentJob.title) = $event)),
                  placeholder: "Stellenbezeichnung eingeben",
                  density: "compact",
                  rules: [_ctx.rules.required],
                  "data-tid": "edit-form-job-title"
                }), null, 16, ["modelValue", "rules"]),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          label: "PLZ",
                          modelValue: _ctx.currentLocation.postalCode,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.currentLocation.postalCode) = $event)),
                          type: "number",
                          placeholder: "PLZ",
                          density: "compact",
                          "data-tid": "edit-form-postal-code",
                          rules: [_ctx.rules.postalCode, _ctx.rules.required]
                        }), null, 16, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          label: "Ort",
                          modelValue: _ctx.currentLocation.city,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.currentLocation.city) = $event)),
                          placeholder: "Ort",
                          density: "compact",
                          "data-tid": "edit-form-city",
                          rules: [_ctx.rules.city, _ctx.rules.required]
                        }), null, 16, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                  label: "Mandant auswählen",
                  modelValue: _ctx.currentJob.mandants[0],
                  "onUpdate:modelValue": [
                    _cache[23] || (_cache[23] = ($event: any) => ((_ctx.currentJob.mandants[0]) = $event)),
                    _cache[24] || (_cache[24] = ($event: any) => (_ctx.updateLocationMandant(_ctx.currentJob.mandants[0])))
                  ],
                  items: _ctx.mandanten,
                  "item-title": "name",
                  "item-value": "uuid",
                  placeholder: "Mandant auswählen",
                  density: "compact",
                  "data-tid": "edit-form-mandant",
                  rules: [_ctx.rules.required]
                }), null, 16, ["modelValue", "items", "rules"]),
                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                  label: "Aufgaben",
                  modelValue: _ctx.currentJob.tasks,
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.currentJob.tasks) = $event)),
                  placeholder: "Beschreiben Sie die Aufgaben",
                  rows: "3",
                  "auto-grow": "",
                  density: "compact",
                  "data-tid": "edit-form-tasks"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                  label: "Profil",
                  modelValue: _ctx.currentJob.profile,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.currentJob.profile) = $event)),
                  placeholder: "Profil beschreiben",
                  rows: "3",
                  "auto-grow": "",
                  density: "compact",
                  "data-tid": "edit-form-profile"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_autocomplete, _mergeProps(_ctx.vStyle.input, {
                  chips: "",
                  label: "Bundesagentur für Arbeit Berufsbezeichnungen",
                  modelValue: _ctx.currentJob.categoriesBfA,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.currentJob.categoriesBfA) = $event)),
                  items: _ctx.allCategoriesBfA,
                  multiple: "",
                  density: "compact",
                  "data-tid": "edit-form-bfa-categories",
                  "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                  "onClick:prependInner": _cache[28] || (_cache[28] = ($event: any) => (_ctx.checkForBfAJobCategories(_ctx.currentJob.title)))
                }), null, 16, ["modelValue", "items"]),
                _createVNode(_component_v_checkbox, {
                  modelValue: _ctx.currentJob.supportedBfAJobAd,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.currentJob.supportedBfAJobAd) = $event)),
                  label: "in Betreuung bei der Bundesagentur für Arbeit geben",
                  density: "compact",
                  "data-tid": "new-form-bfa-support",
                  disabled: _ctx.currentJob.categoriesBfA.length === 0
                }, null, 8, ["modelValue", "disabled"]),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          label: "Gehalt von",
                          modelValue: _ctx.currentJob.salaryFrom,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.currentJob.salaryFrom) = $event)),
                          type: "number",
                          placeholder: "Gehalt von",
                          density: "compact",
                          "data-tid": "edit-form-salary-from"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          label: "Gehalt bis",
                          modelValue: _ctx.currentJob.salaryTo,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.currentJob.salaryTo) = $event)),
                          type: "number",
                          placeholder: "Gehalt bis",
                          density: "compact",
                          "data-tid": "edit-form-salary-to"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          label: "Währung / Zeitraum",
                          modelValue: _ctx.currentJob.salaryPeriod,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.currentJob.salaryPeriod) = $event)),
                          placeholder: "z.B. EUR/Jahr",
                          density: "compact",
                          "data-tid": "edit-form-salary-period"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_checkbox, {
                  label: "Social Media",
                  modelValue: _ctx.currentJob.socialMedia,
                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.currentJob.socialMedia) = $event)),
                  "data-tid": "edit-form-socialmedia",
                  disabled: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue", "onSubmit"])
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "abort",
          onClick: _ctx.cancelEdit
        }, {
          default: _withCtx(() => _cache[46] || (_cache[46] = [
            _createTextVNode("Abbrechen")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          disabled: !_ctx.valid,
          color: "success",
          onClick: _ctx.handleSubmit,
          "data-tid": "edit-form-submit"
        }, {
          default: _withCtx(() => _cache[47] || (_cache[47] = [
            _createTextVNode(" Speichern ")
          ])),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["showDialog", "onCloseDialog"])
  ], 64))
}