import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "d-flex align-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("fa-solid fa-user-graduate")
            ])),
            _: 1
          }),
          _cache[12] || (_cache[12] = _createTextVNode("User Coaching Bedingungen "))
        ]),
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { style: {"position":"sticky","top":"0","z-index":"1000"} }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      variant: "flat",
                      rounded: _ctx.vStyle?.card?.rounded,
                      border: _ctx.vStyle?.card?.border,
                      class: "mb-4 pa-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Verfügbare Aktivitäten")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_chip_group, {
                              column: "",
                              class: "d-flex flex-wrap"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userActionTypes, (action) => {
                                  return (_openBlock(), _createBlock(_component_v_chip, {
                                    key: action.value,
                                    class: "ma-1 pa-2",
                                    draggable: "",
                                    variant: "tonal",
                                    density: "compact",
                                    size: "small",
                                    onDragstart: ($event: any) => (_ctx.handleDragStart($event, action))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(action.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onDragstart"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["rounded", "border"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "pa-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, {
                          class: "border-dashed mb-8",
                          onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                          onDrop: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleDropNew($event)))
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("Neues Trigger Pattern")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode("Aktionen hierher ziehen")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                (_ctx.newPerfEntryPattern.triggerPattern.length === 0)
                                  ? (_openBlock(), _createBlock(_component_v_chip, {
                                      key: 0,
                                      color: "error",
                                      class: "mb-2 pa-3",
                                      density: "compact"
                                    }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createTextVNode("Bitte zuerst eine oder mehrere Aktivitäten auf die Karte ziehen")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_v_chip_group, {
                                  column: "",
                                  class: "d-flex flex-wrap mb-6",
                                  mandatory: false
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPerfEntryPattern.triggerPattern, (entry, index) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                        _createVNode(_component_v_chip, {
                                          closeable: "",
                                          class: "ma-1 pa-3",
                                          density: "compact",
                                          size: "small",
                                          style: {"background-color":"var(--color-primary)","color":"var(--color-on-primary)","opacity":"0.8"},
                                          "onClick:close": ($event: any) => (_ctx.removeNewEntry(index))
                                        }, {
                                          close: _withCtx(() => [
                                            _createElementVNode("div", null, [
                                              _createVNode(_component_v_icon, null, {
                                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                  _createTextVNode("fa-solid fa-xmark")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_tooltip, {
                                                activator: "parent",
                                                location: "top"
                                              }, {
                                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                  _createTextVNode(" Aktiviät aus Prozesskette löschen! ")
                                                ])),
                                                _: 1
                                              })
                                            ])
                                          ]),
                                          default: _withCtx(() => [
                                            _createElementVNode("div", null, [
                                              _createVNode(_component_v_icon, {
                                                size: "small",
                                                class: "mr-2",
                                                onClick: _withModifiers(($event: any) => (_ctx.toggleNewPhoneCall(index)), ["stop"])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(entry.hasPhoneCallBefore
                              ? "fa-solid fa-phone"
                              : "fa-solid fa-phone-slash"), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]),
                                              _createVNode(_component_v_tooltip, {
                                                activator: "parent",
                                                location: "top"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(entry.hasPhoneCallBefore
                              ? "Telefonat vorher"
                              : "ohne Klick auf Telefonbutton vorher"), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _createTextVNode(" " + _toDisplayString(_ctx.getActionLabel(
                          entry.entryType as TimelineEntryType | LinkingStatus
                        )) + " ", 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick:close"]),
                                        (
                        index < _ctx.newPerfEntryPattern.triggerPattern.length - 1
                      )
                                          ? (_openBlock(), _createBlock(_component_v_icon, {
                                              key: 0,
                                              class: "ma-2",
                                              color: "primary"
                                            }, {
                                              default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                _createTextVNode(" fa-solid fa-arrow-right ")
                                              ])),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.newPerfEntryPattern.title,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPerfEntryPattern.title) = $event)),
                                  label: "Titel",
                                  density: "compact"
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.newPerfEntryPattern.coachingPrompt,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPerfEntryPattern.coachingPrompt) = $event)),
                                  label: "Coaching Prompt",
                                  rows: "6"
                                }), null, 16, ["modelValue"]),
                                _cache[21] || (_cache[21] = _createElementVNode("div", { style: {"height":"0.49rem"} }, null, -1)),
                                _createVNode(_component_v_btn, {
                                  color: "primary",
                                  variant: "text",
                                  onClick: _ctx.addNewPattern,
                                  disabled: !_ctx.canAddNewPattern
                                }, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode(" Hinzufügen ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 16),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAiData?.prompt?.aiUserCoaching
                ?.perfEntryPatterns, (pattern, index) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            cols: "12",
                            class: "pa-0",
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, {
                                class: "mb-4",
                                onDragover: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
                                onDrop: ($event: any) => (_ctx.handleDrop($event, index))
                              }), {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_title, { class: "mt-4 d-flex justify-space-between align-top" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: pattern.title,
                                        "onUpdate:modelValue": ($event: any) => ((pattern.title) = $event),
                                        density: "compact",
                                        label: "Titel",
                                        class: "mr-5"
                                      }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                      _createVNode(_component_v_btn, {
                                        icon: "",
                                        density: "compact",
                                        variant: "text",
                                        onClick: ($event: any) => (_ctx.removePattern(index))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_icon, null, {
                                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                                              _createTextVNode("fa-solid fa-xmark")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_card_text, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_label, null, {
                                        default: _withCtx(() => _cache[23] || (_cache[23] = [
                                          _createTextVNode("Neue Aktionen reinziehen um zu ergänzen:")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_divider, { class: "mt-1 mb-3" }),
                                      _createVNode(_component_v_chip_group, {
                                        column: "",
                                        class: "d-flex flex-wrap mb-6",
                                        mandatory: false,
                                        "selected-class": ''
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pattern.triggerPattern, (entry, entryIndex) => {
                                            return (_openBlock(), _createElementBlock(_Fragment, { key: entryIndex }, [
                                              _createVNode(_component_v_chip, {
                                                closeable: "",
                                                class: "ma-1 pa-3",
                                                density: "compact",
                                                size: "small",
                                                style: {"background-color":"var(--color-primary)","color":"var(--color-on-primary)","opacity":"0.8"},
                                                "onClick:close": ($event: any) => (_ctx.removeEntry(index, entryIndex))
                                              }, {
                                                close: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createVNode(_component_v_icon, null, {
                                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                        _createTextVNode("fa-solid fa-xmark")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_tooltip, {
                                                      activator: "parent",
                                                      location: "top"
                                                    }, {
                                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                                        _createTextVNode(" Aktiviät aus Prozesskette löschen! ")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ])
                                                ]),
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createVNode(_component_v_icon, {
                                                      size: "small",
                                                      class: "mr-2",
                                                      onClick: _withModifiers(($event: any) => (_ctx.togglePhoneCall(index, entryIndex)), ["stop"])
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(entry.hasPhoneCallBefore
                                ? "fa-solid fa-phone"
                                : "fa-solid fa-phone-slash"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["onClick"]),
                                                    _createVNode(_component_v_tooltip, {
                                                      activator: "parent",
                                                      location: "top"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(entry.hasPhoneCallBefore
                                ? "Telefonat vorher"
                                : "ohne Klick auf Telefonbutton vorher"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.getActionLabel(
                            entry.entryType as TimelineEntryType | LinkingStatus
                          )) + " ", 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick:close"]),
                                              (entryIndex < pattern.triggerPattern.length - 1)
                                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                                    key: 0,
                                                    class: "ma-2",
                                                    color: "primary"
                                                  }, {
                                                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                      _createTextVNode(" fa-solid fa-arrow-right ")
                                                    ])),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_textarea, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: pattern.coachingPrompt,
                                        "onUpdate:modelValue": ($event: any) => ((pattern.coachingPrompt) = $event),
                                        label: "Coaching Prompt",
                                        rows: "3"
                                      }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1040, ["onDrop"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "pa-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, {
                          class: "border-dashed mb-8",
                          onDragover: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"])),
                          onDrop: _ctx.handleDropNewActivity
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createTextVNode("Neue Trigger Aktivität")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => _cache[28] || (_cache[28] = [
                                _createTextVNode("Aktion hierher ziehen um zu erstellen / überschreiben")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                (_ctx.newActivity.entryType)
                                  ? (_openBlock(), _createBlock(_component_v_chip, {
                                      key: 0,
                                      closeable: "",
                                      class: "mb-10 pa-3",
                                      density: "compact",
                                      style: {"background-color":"var(--color-secondary)","color":"var(--color-on-secondary)","opacity":"0.8"},
                                      "onClick:close": _ctx.clearNewActivityType
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getActionLabel(_ctx.newActivity.entryType)) + " ", 1),
                                        _createVNode(_component_v_tooltip, {
                                          activator: "parent",
                                          location: "top"
                                        }, {
                                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                                            _createTextVNode(" Zum Überschreiben neue Aktivität draufziehen! ")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["onClick:close"]))
                                  : (_openBlock(), _createBlock(_component_v_chip, {
                                      key: 1,
                                      color: "error",
                                      class: "mb-10 pa-3",
                                      density: "compact"
                                    }, {
                                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                                        _createTextVNode("Bitte zuerst eine Aktivität auf die Karte ziehen")
                                      ])),
                                      _: 1
                                    })),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.newActivity.title,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newActivity.title) = $event)),
                                  label: "Titel",
                                  density: "compact"
                                }), null, 16, ["modelValue"]),
                                _createElementVNode("div", _hoisted_2, [
                                  _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                    modelValue: _ctx.newActivity.time,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newActivity.time) = $event)),
                                    label: "Zeit",
                                    class: "mr-3",
                                    density: "compact",
                                    type: "time",
                                    width: "50%"
                                  }), null, 16, ["modelValue"]),
                                  _createVNode(_component_v_btn, {
                                    icon: "",
                                    variant: "text",
                                    onClick: _ctx.toggleNewIsSmallerThanCountForTrigger
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.newActivity.isSmallerThanCountForTrigger
                        ? "fa-solid fa-angle-left"
                        : "fa-solid fa-angle-right"), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_tooltip, {
                                        activator: "parent",
                                        location: "top"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.newActivity.isSmallerThanCountForTrigger
                          ? "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit kleiner ist als bei 'Anzahl' angegeben"
                          : "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit größer ist als bei 'Anzahl' angegeben"), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"]),
                                  _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                    modelValue: _ctx.newActivity.count,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newActivity.count) = $event)),
                                    modelModifiers: { number: true },
                                    label: 
                      _ctx.newActivity.isSmallerThanCountForTrigger
                        ? 'Anzahl kleiner als'
                        : 'Anzahl größer als'
                    ,
                                    type: "number",
                                    class: "ml-3",
                                    density: "compact",
                                    width: "50%"
                                  }), null, 16, ["modelValue", "label"])
                                ]),
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.newActivity.coachingPrompt,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newActivity.coachingPrompt) = $event)),
                                  label: "Coaching Prompt",
                                  class: "mb-4",
                                  rows: "3"
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_btn, {
                                  color: "primary",
                                  variant: "text",
                                  onClick: _ctx.addNewActivity,
                                  disabled: !_ctx.canAddNewActivity
                                }, {
                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                    _createTextVNode(" Hinzufügen ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 16, ["onDrop"])
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAiData?.prompt?.aiUserCoaching
              ?.userTriggerSalesActivity, (activity, index) => {
                      return (_openBlock(), _createBlock(_component_v_col, {
                        cols: "12",
                        class: "pa-0",
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, {
                            class: "mb-4",
                            onDragover: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"])),
                            onDrop: ($event: any) => (_ctx.handleDropActivity($event, index))
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, { class: "mt-4 d-flex justify-space-between align-top" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                    modelValue: activity.title,
                                    "onUpdate:modelValue": ($event: any) => ((activity.title) = $event),
                                    density: "compact",
                                    label: "Titel",
                                    class: "mr-5"
                                  }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_v_btn, {
                                    density: "compact",
                                    icon: "",
                                    variant: "text",
                                    onClick: ($event: any) => (_ctx.removeActivity(index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                                          _createTextVNode("fa-solid fa-xmark")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_label, null, {
                                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                                      _createTextVNode("Zum Überschreiben neue Aktivität reinziehen:")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mt-1 mb-3" }),
                                  (activity.entryType)
                                    ? (_openBlock(), _createBlock(_component_v_chip, {
                                        key: 0,
                                        closeable: "",
                                        class: "mb-8 pa-3",
                                        density: "compact",
                                        "onClick:close": ($event: any) => (_ctx.clearActivityType(index)),
                                        style: {"background-color":"var(--color-secondary)","color":"var(--color-on-secondary)","opacity":"0.8"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getActionLabel(activity.entryType)) + " ", 1),
                                          _createVNode(_component_v_tooltip, {
                                            activator: "parent",
                                            location: "top"
                                          }, {
                                            default: _withCtx(() => _cache[34] || (_cache[34] = [
                                              _createTextVNode(" Zum Überschreiben neue Aktivität draufziehen! ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick:close"]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                      modelValue: activity.time,
                                      "onUpdate:modelValue": ($event: any) => ((activity.time) = $event),
                                      density: "compact",
                                      width: "50%",
                                      label: "Uhrzeit",
                                      class: "mr-2",
                                      type: "time"
                                    }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_v_btn, {
                                      icon: "",
                                      variant: "text",
                                      onClick: ($event: any) => (_ctx.toggleIsSmallerThanCountForTrigger(index))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(activity.isSmallerThanCountForTrigger
                        ? "fa-solid fa-angle-left"
                        : "fa-solid fa-angle-right"), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_tooltip, {
                                          activator: "parent",
                                          location: "top"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(activity.isSmallerThanCountForTrigger
                          ? "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit kleiner ist als bei 'Anzahl' angegeben"
                          : "wird ausgelöst wenn die Anzahl der Aktivitäten nach der angegebenen Uhrzeit größer ist als bei 'Anzahl' angegeben"), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                      modelValue: activity.count,
                                      "onUpdate:modelValue": ($event: any) => ((activity.count) = $event),
                                      modelModifiers: { number: true },
                                      density: "compact",
                                      width: "50%",
                                      label: 
                      activity.isSmallerThanCountForTrigger
                        ? 'Anzahl kleiner als'
                        : 'Anzahl größer als'
                    ,
                                      type: "number",
                                      class: "ml-2"
                                    }), null, 16, ["modelValue", "onUpdate:modelValue", "label"])
                                  ]),
                                  _createVNode(_component_v_textarea, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                    modelValue: activity.coachingPrompt,
                                    "onUpdate:modelValue": ($event: any) => ((activity.coachingPrompt) = $event),
                                    label: "Coaching Prompt"
                                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1040, ["onDrop"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}