<!--src/components/config/software-integration/ConfigProfileTemplate.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-id-badge</v-icon
      >Profilvorlage
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-row>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4">Logos & Links</v-card-title>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Link zu Logo hell"
                  v-model="localConfig.profileTemplate.logo"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Link zu Logo dunkel"
                  v-model="localConfig.profileTemplate.logoDark"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Button 'weitere Kandidaten' Text"
                  v-model="localConfig.profileTemplate.candidatesButtonText"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Button Link"
                  v-model="localConfig.profileTemplate.candidatesButtonLink"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4"
                  >Überschriften linker Streifen</v-card-title
                >
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Titel Kandidatendaten"
                  v-model="localConfig.profileTemplate.titles.background"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Titel Schichtbereitschaft"
                  v-model="localConfig.profileTemplate.titles.shiftReadiness"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Titel Führerscheine"
                  v-model="localConfig.profileTemplate.titles.licences"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Titel Kontakt"
                  v-model="localConfig.profileTemplate.titles.contact"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-card-title class="mb-4"
                  >Überschriften Hauptteil</v-card-title
                >
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Skills"
                  v-model="localConfig.profileTemplate.titles.skills"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Schul- und Ausbildung"
                  v-model="localConfig.profileTemplate.titles.education"
                >
                </v-text-field>
                <v-text-field
                  v-bind="vStyle.input"
                  density="compact"
                  label="Werdegang"
                  v-model="localConfig.profileTemplate.titles.cv"
                ></v-text-field>
                <v-card-subtitle>(Nur für nicht-AI-Profile)</v-card-subtitle>
              </v-col>
            </v-row>
            <v-card-title class="mb-10">Aufteilung der Seite</v-card-title>
            <v-range-slider
              v-model="widthTblMid"
              step="1"
              thumb-label="always"
            ></v-range-slider>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-card-title>UberAdmin Profilgenerator Config</v-card-title>
            <v-row>
              <v-col cols="12" md="7">
                <v-textarea
                  v-bind="vStyle.input"
                  density="compact"
                  label="Markdown Template"
                  rows="17"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateMarkdownTemplate
                  "
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="5">
                <v-textarea
                  v-bind="vStyle.input"
                  density="compact"
                  label="Allgemeine Anweisungen"
                  rows="3"
                  v-model="localConfig.aiData.prompt.company.profileCandidate"
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  v-bind="vStyle.input"
                  density="compact"
                  label="Regeln für Beschreibung"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateDescriptionRules
                  "
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  v-bind="vStyle.input"
                  density="compact"
                  label="Regeln für Überschrift"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateHeadlineRules
                  "
                ></v-textarea>
                <div class="profile-template-prompts-textarea-spacer"></div>
                <v-textarea
                  v-bind="vStyle.input"
                  density="compact"
                  label="Regeln für Sprachkenntnisse"
                  rows="3"
                  v-model="
                    localConfig.aiData.prompt.company
                      .profileCandidateLanguageRules
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-card-title>Primärfarbe</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.colorPrimary"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-card-title>Sekundärfarbe</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.colorSecondary"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-card-title>linker Streifen</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.background"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-bind="vStyle.card" class="mb-4 px-4">
            <v-card-title>Schriftfarbe Hell</v-card-title>
            <v-color-picker
              v-model="localConfig.profileTemplate.fontLight"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <BaseDialog
    v-if="showProfileEditorPreview"
    :rounded="vStyle?.card?.rounded"
    color="secondary"
    :showDialog="showProfileEditorPreview"
    :width="'var(--d-w-xl)'"
    @closeDialog="closeProfileEditorPreview()"
  >
    <template #content>
      <ProfileEditor />
    </template>
  </BaseDialog>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
  <v-card
    variant="tonal"
    class="pa-1"
    style="position: fixed; top: 14rem; right: 0.5rem; z-index: 1000"
    @click="previewProfileTemplate"
  >
    <v-icon>fa-solid fa-eye</v-icon>
    <v-tooltip location="top" activator="parent">
      Profilvorlage Vorschau
    </v-tooltip>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import ProfileEditor from "@/components/disposition/elements/ProfileEditor.vue";
import { useDisplay } from "vuetify";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";
import { MutationType } from "../../../../enums/vuex-types.enum";
type CandidateStatusColor = {
  [key: string]: string;
};

export default defineComponent({
  name: "ConfigStatusColor",
  components: {
    BaseDialog,
    ConfigSaveButton,
    ProfileEditor,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      isDirty: false,
      localConfig: cloneDeep(this.modelValue),
      showProfileEditorPreview: false,
      widthTblMid: [2, 24],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    filteredCandidateStatusColor(): CandidateStatusColor {
      const candidateStatusColor = this.localConfig
        .candidateStatusColor as CandidateStatusColor;
      return Object.keys(candidateStatusColor)
        .filter((status) => status !== "_id")
        .reduce((acc: CandidateStatusColor, status: string) => {
          acc[status] = candidateStatusColor[status];
          return acc;
        }, {});
    },
  },
  watch: {
    modelValue: {
      handler(newValue: CompanyConfig) {
        this.localConfig = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localConfig: {
      handler(newValue: CompanyConfig) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
    widthTblMid: {
      handler(newValue) {
        const mid = newValue[1] - newValue[0];
        const left = newValue[0];
        const right = 100 - mid - left;

        this.localConfig.profileTemplate.tblLeft = `${left}%`;
        this.localConfig.profileTemplate.tblMid = `${mid}%`;
        this.localConfig.profileTemplate.tblRight = `${right}%`;
      },
      deep: true,
    },
  },
  mounted() {
    this.calculateSliderValues();
  },
  methods: {
    calculateSliderValues() {
      const left = parseInt(
        this.localConfig.profileTemplate.tblLeft?.replace("%", "") || "0"
      );
      const mid = parseInt(
        this.localConfig.profileTemplate.tblMid?.replace("%", "") || "0"
      );

      this.widthTblMid = [left, left + mid];

      const right = 100 - mid - left;
      this.localConfig.profileTemplate.tblLeft = `${left}%`;
      this.localConfig.profileTemplate.tblMid = `${mid}%`;
      this.localConfig.profileTemplate.tblRight = `${right}%`;
    },
    closeProfileEditorPreview() {
      this.showProfileEditorPreview = false;
      // reset the store value
      this.$store.commit(
        MutationType.setProfileTemplate,
        this.modelValue?.profileTemplate
      );
    },
    previewProfileTemplate() {
      this.$store.commit(
        MutationType.setProfileTemplate,
        this.localConfig?.profileTemplate
      );
      this.$nextTick().then(() => {
        this.showProfileEditorPreview = true;
      });
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>

<style scoped>
.profile-template-prompts-textarea-spacer {
  height: 2px;
}
</style>
