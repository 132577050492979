import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic && _ctx.layout !== _ctx.AppLayout.persontobusiness)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card_title, { class: "my-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "1.5rem",
          class: "mr-1"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("fa-solid fa-screwdriver-wrench")
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.AppPageAndMenuTitle.appConfig), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, _mergeProps({ class: "mx-4" }, _ctx.vStyle?.card), {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_subtitle, { class: "mb-2" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Sorted alphabetically primitive values ")
                      ])),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedConfigData, (value, key) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                        (!_ctx.isObject(value) && String(key) !== '_id')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                  modelValue: _ctx.configData[key],
                                  "onUpdate:modelValue": [($event: any) => ((_ctx.configData[key]) = $event), _ctx.setIsDirty],
                                  label: _ctx.formatLabel(key),
                                  density: "compact",
                                  rows: _ctx.calculateRows(_ctx.configData[key]),
                                  disabled: Boolean(String(key).includes('Timestamp'))
                                }), null, 16, ["modelValue", "onUpdate:modelValue", "label", "rows", "disabled"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "7"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, _mergeProps({ class: "mx-4" }, _ctx.vStyle?.card), {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panels, {
                  variant: "accordion",
                  multiple: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedConfigData, (value, key) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                        (_ctx.isObject(value) && String(key) !== '_id')
                          ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panel_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatLabel(key)), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_expansion_panel_text, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (subValue, subKey) => {
                                          return (_openBlock(), _createBlock(_component_v_col, {
                                            key: subKey,
                                            cols: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              (String(subKey) !== '_id')
                                                ? (_openBlock(), _createBlock(_component_v_textarea, _mergeProps({
                                                    key: 0,
                                                    ref_for: true
                                                  }, _ctx.vStyle?.input, {
                                                    modelValue: _ctx.configData[key][subKey],
                                                    "onUpdate:modelValue": [($event: any) => ((_ctx.configData[key][subKey]) = $event), _ctx.setIsDirty],
                                                    label: _ctx.formatLabel(String(subKey)),
                                                    density: "compact",
                                                    rows: _ctx.calculateRows(_ctx.configData[key][subKey])
                                                  }), null, 16, ["modelValue", "onUpdate:modelValue", "label", "rows"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.hasAppConfigSaveAccess && _ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 1,
          onSave: _ctx.saveConfig
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}