import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "text-caption" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "job-ad" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "job-ad-list px-0 mx-0",
        onMouseenter: _ctx.cancelClose,
        onMouseleave: _ctx.scheduleClose,
        style: _normalizeStyle(_ctx.windowPosition)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_v_btn, {
                onClick: _ctx.allAdsPostJobList,
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _cache[10] || (_cache[10] = _createTextVNode(" alle ( ")),
                  _createVNode(_component_v_icon, { class: "mr-1" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("fas fa-square-plus")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_icon, { class: "mr-1" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("fas fa-rectangle-ad")
                    ])),
                    _: 1
                  }),
                  _cache[11] || (_cache[11] = _createTextVNode(") extrahieren ")),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("AI extrahiert die Aufgaben und Anforderungen aller Stellen und speichert es in Recruting - JobList")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_v_switch, {
              class: "ml-4",
              density: "compact",
              color: "primary",
              modelValue: _ctx.supportedBfAJobAd,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.supportedBfAJobAd) = $event))
            }, {
              label: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, [
                  _cache[13] || (_cache[13] = _createTextVNode("in BA-Betreuung")),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Bei den Anzeigen auf der JobList automatisch den Haken bei BA-Betreuung setzen")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_spacer),
            (_ctx.softwareIntegration?.indexAnzeigendaten)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.fetchIndexAnzeigendaten,
                    variant: "text"
                  }, {
                    default: _withCtx(() => [
                      _cache[16] || (_cache[16] = _createTextVNode(" Neu laden  ")),
                      _createElementVNode("img", {
                        src: 
              _ctx.$store.getters.getEnv.assetsUrl +
              '/icons/icon-index-anzeigendaten.png'
            ,
                        class: "mr-1",
                        style: {"width":"1rem","height":"1rem"}
                      }, null, 8, _hoisted_4),
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("fas fa-arrows-rotate")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "bottom"
                      }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("Stellenanzeigen von Anzeigendaten neu abrufen")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_v_divider, { class: "my-3" }),
          _createVNode(_component_v_list, { class: "ma-0 pa-0" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueAndLatestJobAds, (jobAd) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  class: "ma-0 pa-0",
                  key: jobAd.adId,
                  onDragstart: ($event: any) => (_ctx.handleDragStart($event, jobAd)),
                  onMouseenter: _cache[4] || (_cache[4] = ($event: any) => ($event.currentTarget.style.cursor = 'grab')),
                  onMousedown: _cache[5] || (_cache[5] = ($event: any) => ($event.currentTarget.style.cursor = 'grabbing')),
                  onMouseup: _cache[6] || (_cache[6] = ($event: any) => ($event.currentTarget.style.cursor = 'grab')),
                  style: {"cursor":"grab"},
                  draggable: "true"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "bottom"
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("Tip: um eine Anfrage zu generieren: einfach per Drag & Drop die Stelle in die Anfrage-Spalte ziehen")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("a", {
                          onDragstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
                          class: "mx-2 text-subtitle-1 job-ad-title",
                          href: jobAd.link,
                          target: "_blank"
                        }, _toDisplayString(jobAd.jobTitle), 41, _hoisted_7),
                        (_ctx.softwareIntegration?.indexAnzeigendaten)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              icon: "",
                              size: "x-small",
                              density: "compact",
                              variant: "text",
                              class: "ma-1",
                              href: jobAd.internLinkPdf,
                              target: "_blank",
                              onDragstart: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                    _createTextVNode("PDF von Anzeigendaten öffnen")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode("fa-solid fa-file-pdf job-ad-icon")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["href"]))
                          : _createCommentVNode("", true),
                        (_ctx.softwareIntegration?.indexAnzeigendaten)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 1,
                              icon: "",
                              size: "x-small",
                              density: "compact",
                              variant: "text",
                              class: "ma-1",
                              href: jobAd.internLinkTxt,
                              target: "_blank",
                              onDragstart: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                                    _createTextVNode(" fa-solid fa-file-lines job-ad-icon")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                                    _createTextVNode("Textdatei von Anzeigendaten öffnen")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["href"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          size: "medium",
                          variant: "text",
                          class: "job-ad-icon mx-2",
                          onClick: ($event: any) => (_ctx.adPostJobList(jobAd))
                        }, {
                          default: _withCtx(() => [
                            _cache[23] || (_cache[23] = _createElementVNode("i", { class: "fa-solid fa-square-plus" }, null, -1)),
                            _cache[24] || (_cache[24] = _createElementVNode("i", { class: "fa-solid fa-rectangle-ad" }, null, -1)),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("Aufgaben & Anforderungen extrahieren und Stelle in Joblist speichern")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_v_card_subtitle, { class: "mx-2 pa-0" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(jobAd.date) + ": " + _toDisplayString(jobAd.location) + " " + _toDisplayString(jobAd.sourceText) + "   ", 1),
                            _createElementVNode("span", {
                              innerHTML: jobAd.listPrice
                            }, null, 8, _hoisted_9)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createVNode(_component_v_divider)
                    ])
                  ]),
                  _: 2
                }, 1032, ["onDragstart"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onMouseenter", "onMouseleave", "style"]))
    : _createCommentVNode("", true)
}