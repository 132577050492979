<template>
  <AdminMenuComponent></AdminMenuComponent>
  <AppHeader
    v-if="
      appLayout !== AppLayout.classic &&
      appLayout !== AppLayout.persontobusiness
    "
  ></AppHeader>
  <v-row>
    <v-col class="ma-10" cols="12" md="auto">
      <v-card v-bind="vStyle.card">
        <v-card-title>
          {{ isUberAdmin ? "DEBUG Info" : "Aktuelle Softwareversion" }}
        </v-card-title>
        <v-card-text>
          <p>Alpha {{ getVersion() }}</p>
          <p v-if="isUberAdmin">Environment: {{ getEnvironment() }}</p>
          <p v-if="isUberAdmin">Backend URL: {{ getBackendURI() }}</p>
          <v-btn
            class="mt-10"
            color="primary"
            variant="outlined"
            :href="'https://www.youtube.com/playlist?list=PLjAx46HKPM5txs1szztyT6uLyE__xMLVh'"
            target="_blank"
          >
            <v-icon class="mr-1">fa-brands fa-youtube</v-icon>
            Changelog
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AdminMenuComponent from "../menus/admin-menu.component.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import AppHeader from "../header/Header.vue";
import { UserRoleHelper } from "../../helper/user-role.helper";

export default defineComponent({
  name: "DebugInfo",
  components: {
    AdminMenuComponent,
    AppHeader,
  },
  data() {
    return {
      appLayout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEnv"]),
    isUberAdmin(): boolean {
      return UserRoleHelper.isUberAdmin(this.$store.getters.user);
    },
  },
  methods: {
    getBackendURI() {
      return this.$store.getters.getEnv.VUE_APP_BACKEND_URI;
    },
    getEnvironment() {
      return this.$store.getters.getEnv.NODE_ENV;
    },
    getVersion() {
      return this.$store.getters.getEnv.VUE_APP_VERSION;
    },
  },
});
</script>
