<template>
  <AdminMenu />
  <AppHeader
    v-if="layout !== AppLayout.classic && layout !== AppLayout.persontobusiness"
  ></AppHeader>
  <v-card-title class="my-4">
    <v-icon size="1.5rem" class="mr-1">fa-solid fa-screwdriver-wrench</v-icon>
    {{ AppPageAndMenuTitle.appConfig }}
  </v-card-title>
  <v-row>
    <v-col cols="12" md="5">
      <v-card class="mx-4" v-bind="vStyle?.card">
        <v-card-text>
          <v-card-subtitle class="mb-2">
            Sorted alphabetically primitive values
          </v-card-subtitle>
          <template v-for="(value, key) in sortedConfigData" :key="key">
            <v-col v-if="!isObject(value) && String(key) !== '_id'" cols="12">
              <v-textarea
                v-bind="vStyle?.input"
                v-model="configData[key]"
                :label="formatLabel(key)"
                density="compact"
                :rows="calculateRows(configData[key])"
                :disabled="Boolean(String(key).includes('Timestamp'))"
                @update:model-value="setIsDirty"
              />
            </v-col>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <v-card class="mx-4" v-bind="vStyle?.card">
        <v-expansion-panels variant="accordion" multiple>
          <!-- Sorted alphabetically -->
          <template v-for="(value, key) in sortedConfigData" :key="key">
            <v-expansion-panel v-if="isObject(value) && String(key) !== '_id'">
              <v-expansion-panel-title>{{
                formatLabel(key)
              }}</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row>
                  <v-col
                    v-for="(subValue, subKey) in value"
                    :key="subKey"
                    cols="12"
                  >
                    <v-textarea
                      v-bind="vStyle?.input"
                      v-if="String(subKey) !== '_id'"
                      v-model="configData[key][subKey]"
                      :label="formatLabel(String(subKey))"
                      density="compact"
                      :rows="calculateRows(configData[key][subKey])"
                      @update:model-value="setIsDirty"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
  <ConfigSaveButton
    v-if="hasAppConfigSaveAccess && isDirty"
    @save="saveConfig"
  />
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { AppConfigService } from "@/services/api/app-config.service";
import { defineComponent } from "vue";
import ToastService from "@/services/toast.service";
import { AppConfig } from "@/models/app-config.model";
import { AppLayout, AppPageAndMenuTitle } from "@/enums/app-layout.enum";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import AppHeader from "@/components/header/Header.vue";
import { UserRoleHelper } from "../../../helper/user-role.helper";
import { AccessRule } from "../../../enums/access-rule.enum";
import ConfigSaveButton from "@/components/config/elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "AppConfigView",
  components: {
    AdminMenu,
    AppHeader,
    ConfigSaveButton,
  },
  data() {
    return {
      appConfigService: AppConfigService.getInstance(),
      AppLayout: AppLayout,
      AppPageAndMenuTitle: AppPageAndMenuTitle,
      configData: {} as Record<string, any>,
      originalConfigData: {} as Record<string, any>,
      isDirty: false,
      layout: this.$store.getters.appLayout,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEnv"]),

    hasAppConfigSaveAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.saveAppConfig
      );
    },

    // sort keys alphabetically: first objects then strings
    sortedConfigData(): Record<string, any> {
      if (!this.configData) return {};

      const sortedKeys = Object.keys(this.configData)
        .filter((key) => key !== "_id")
        .sort((a, b) => a.localeCompare(b));

      const sortedObject: Record<string, any> = {};

      // add objects
      sortedKeys.forEach((key) => {
        if (this.isObject(this.configData[key])) {
          sortedObject[key] = this.configData[key];
        }
      });

      // add primitive values
      sortedKeys.forEach((key) => {
        if (!this.isObject(this.configData[key])) {
          sortedObject[key] = this.configData[key];
        }
      });

      return sortedObject;
    },
  },
  async created() {
    try {
      this.configData = await this.appConfigService.getBackend();
      this.originalConfigData = cloneDeep(this.configData);
    } catch (error) {
      console.error("❌ Fehler beim Laden der Konfiguration:", error);
    }
  },
  methods: {
    calculateRows(value: any): number {
      if (!value) return 1;
      const stringValue = String(value);
      const lines = stringValue.split("\n").length;
      return Math.min(Math.max(lines, 1), 10);
    },

    formatLabel(key: string) {
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str: string) => str.toUpperCase());
    },
    isObject(value: any) {
      return typeof value === "object" && value !== null;
    },
    async saveConfig() {
      const result = await this.appConfigService.save(
        this.configData as AppConfig
      );
      if (result) {
        ToastService.showSuccess("Einstellungen gespeichert!");
        this.originalConfigData = cloneDeep(this.configData);
        this.isDirty = false;
      }
    },
    setIsDirty() {
      this.isDirty =
        JSON.stringify(this.configData) !==
        JSON.stringify(this.originalConfigData);
    },
  },
});
</script>

<style scoped>
.v-container {
  max-width: 900px;
  margin: auto;
}
</style>
