<template>
  <!-- Bug Icon Button -->
  <v-btn v-if="hasBugReportAccess" variant="text" icon density="compact">
    <v-icon class="bug-icon" @click="dialog = true" size="small">
      fa-solid fa-bug
    </v-icon>
    <v-tooltip activator="parent" location="bottom">Bug melden</v-tooltip>
  </v-btn>

  <!-- Report Bug Dialog -->
  <v-dialog
    v-model="dialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          REPORT-A-BUG: Bugs / Ideen / Features anfragen
        </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-select
            v-bind="vStyle.input"
            v-model="issueType"
            :items="issueTypes"
            label="Select Issue Type"
            :rules="[(v) => !!v || 'Issue type is required']"
            required
          ></v-select>
          <v-text-field
            v-bind="vStyle.input"
            v-model="title"
            label="Title"
            :rules="[(v) => !!v || 'Title is required']"
            required
          ></v-text-field>
          <v-textarea
            v-bind="vStyle.input"
            v-model="description"
            label="Description"
            :rules="[(v) => !!v || 'Description is required']"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-text class="d-flex">
        <v-btn v-bind="vStyle.btn" color="abort" @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-bind="vStyle.btn"
          color="success"
          @click="submitBugReport"
          :disabled="!valid"
        >
          Submit
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GitLabService } from "@/services/api/gitlab.service";
import { DialogResponse } from "@/enums/dialog.enum";
import { IssueType } from "@/enums/issue-type.enum";
import ToastService from "@/services/toast.service";
import { useDisplay } from "vuetify";
import { mapGetters } from "vuex";
import { AiService } from "@/services/ai.service";
import { SpinnerService } from "@/services/spinner.service";
import { UserRoleHelper } from "../../helper/user-role.helper";
import { GitLabLabels } from "../../enums/gitlab-labels.enum";
import { AccessRule } from "../../enums/access-rule.enum";

export default defineComponent({
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      dialog: false,
      valid: false,
      gitLabService: new GitLabService(),
      issueType: "",
      title: "",
      description: "",
      responseMessage: "",
      responseType: "" as any,
      issueTypes: [
        GitLabLabels.bug,
        GitLabLabels.criticalBug,
        GitLabLabels.urgentCustomerRequest,
        GitLabLabels.refactoring,
        GitLabLabels.idea,
        GitLabLabels.featureRequest,
      ],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    hasBugReportAccess(): boolean {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.bugReport
      );
    },
  },
  methods: {
    async submitBugReport() {
      SpinnerService.showSpinner();
      let labels: string[] = [];
      let issueType = IssueType.issue;

      if (this.issueType === GitLabLabels.bug) {
        labels = [
          GitLabLabels.bug,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.incident;
      } else if (this.issueType === GitLabLabels.criticalBug) {
        labels = [
          GitLabLabels.criticalBug,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.incident;
      } else if (this.issueType === GitLabLabels.urgentCustomerRequest) {
        labels = [
          GitLabLabels.urgentCustomerRequest,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.incident;
      } else if (this.issueType === GitLabLabels.refactoring) {
        labels = [
          GitLabLabels.refactoring,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.incident;
      } else if (this.issueType === GitLabLabels.idea) {
        labels = [
          GitLabLabels.idea,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.test_case;
      } else if (this.issueType === GitLabLabels.featureRequest) {
        labels = [
          GitLabLabels.featureRequest,
          `${this.user.forename} ${this.user.lastname}`,
        ];
        issueType = IssueType.issue;
      }
      ToastService.showReminder(
        "Ich übersetze das Ticket gerade in Englisch ..."
      );
      const aiService = new AiService();
      const translatedBugReport = await aiService.translateBugReport(
        this.title,
        this.description,
        this.issueType
      );
      ToastService.showSuccess(
        `Sende nun das Ticket: ${translatedBugReport.title}- ${translatedBugReport.text}`
      );

      try {
        await this.gitLabService.createBugReport(
          `[${this.issueType}] ${translatedBugReport.title}`,
          translatedBugReport.text,
          labels,
          issueType
        );

        this.responseMessage = `Issue created successfully!`;
        this.responseType = DialogResponse.success;
        ToastService.show(this.responseMessage);
        this.clearForm();
      } catch (error: any) {
        if (error.response) {
          this.responseMessage = error.response.data.message;
        } else {
          this.responseMessage = error.message;
        }
        this.responseType = DialogResponse.failure;
      }
      SpinnerService.removeSpinner();
    },
    clearForm() {
      this.issueType = "";
      this.title = "";
      this.description = "";
      this.valid = false;

      const form = this.$refs.form as HTMLFormElement;
      if (form && form.reset) {
        form.reset();
      }
      this.dialog = false;
    },
  },
});
</script>

<style scoped>
.v-alert {
  margin-top: 20px;
}
.bug-icon:hover {
  transition: all 0.3s ease;
  scale: 1.2;
}
</style>
