import Fuse from "fuse.js";

// If you only handle plain strings, you can simply use { tag: string }.
// For more advanced typing, adjust this interface as needed.
interface TagItem {
  tag: string;
}

/**
 * Merges two arrays of Fuse results using OR-logic:
 * - If an item appears in both arrays, keep the one with the lower (better) score.
 */
export function unionResults(
  arrA: import("fuse.js").FuseResult<TagItem>[],
  arrB: import("fuse.js").FuseResult<TagItem>[]
): import("fuse.js").FuseResult<TagItem>[] {
  const map = new Map<string, import("fuse.js").FuseResult<TagItem>>();

  // Insert arrA into the map
  for (const r of arrA) {
    // For plain strings, use r.item.tag as the key.
    // If you have real candidate data with an ID, use that here instead.
    const key = r.item.tag;
    map.set(key, r);
  }

  // Add arrB or update if a better (lower) score is found
  for (const r of arrB) {
    const key = r.item.tag;
    const existing = map.get(key);
    if (!existing || (r.score ?? 1) < (existing.score ?? 1)) {
      map.set(key, r);
    }
  }

  return Array.from(map.values());
}

/**
 * Performs fuzzy matching similar to the backend:
 * 1) Create a Fuse instance using candidateTags
 * 2) For each tag in payloadTags -> perform a search, then union the results
 * 3) Filter the final set by `score <= threshold`
 * 4) Returns a boolean indicating whether at least one match was found
 *    (you could also return the final result array instead if desired)
 */
export function checkTagsMatching(
  payloadTags: string[],
  candidateTags: string[],
  threshold = 0.3 // default threshold can be adjusted
): boolean {
  // Convert candidateTags into objects { tag } so that Fuse can search using keys: ['tag']
  const fuseData = candidateTags.map((tag) => ({ tag }));
  const fuse = new Fuse(fuseData, {
    keys: ["tag"],
    includeScore: true,
    threshold,
    ignoreLocation: true,
    shouldSort: true,
    useExtendedSearch: true,
    minMatchCharLength: 3,
    distance: 50,
  });

  // Collect all results (OR-logic)
  let allResults: import("fuse.js").FuseResult<TagItem>[] = [];

  // For each payloadTag -> Fuse search -> union results
  for (const userTag of payloadTags) {
    const searchResults = fuse.search(userTag);
    allResults = unionResults(allResults, searchResults);
  }

  // Only keep results with score <= threshold
  const finalResults = allResults.filter((r) => (r.score ?? 1) <= threshold);

  // Example: return true if at least one tag matched
  return finalResults.length > 0;
}

/**
 * Matches a job title with the official categories of the German Federal Employment Agency (BfA)
 * and returns the top N most similar categories.
 *
 * @param jobTitle The job title to be matched
 * @param bfaCategories Array of all BfA categories
 * @param limit Number of top matches to return (default: 3)
 * @param threshold Optional: Score threshold for valid matches (default: none)
 * @returns Array of best matching BfA categories
 */
export function matchBfaJobCategoriesStrings(
  jobTitle: string,
  bfaCategories: string[],
  limit = 3,
  threshold?: number
): string[] {
  // Clean the job title from gender specifications like (m/w/d)
  const cleanedJobTitle = jobTitle.replace(/\s*\([mwfd/]+\)\s*/g, "").trim();

  // Extract search terms with different priorities
  const searchTerms = extractSearchTerms(cleanedJobTitle);

  // Base Fuse options
  const baseFuseOptions = {
    includeScore: true,
    shouldSort: true,
    isCaseSensitive: false,
    findAllMatches: true,
  };

  // Store all search results with their scores
  const allResults: Array<{ item: string; score: number }> = [];

  // STEP 1: EXACT MATCHES - Search for exact title or job name matches
  // This has the highest priority
  if (searchTerms.exactTerms.length > 0) {
    const exactOptions = {
      ...baseFuseOptions,
      threshold: 0.2, // Very strict matching
      distance: 0, // No character transpositions allowed
      ignoreLocation: false,
      useExtendedSearch: false,
      minMatchCharLength: 3,
    };

    const exactFuse = new Fuse(bfaCategories, exactOptions);

    for (const term of searchTerms.exactTerms) {
      const results = exactFuse.search(term);

      for (const result of results) {
        // Give very high priority to exact matches
        if (result.item.toLowerCase().includes(term.toLowerCase())) {
          allResults.push({
            item: result.item,
            score: (result.score || 0.5) * 0.1, // Boost exact matches significantly
          });
        } else {
          allResults.push({
            item: result.item,
            score: result.score || 0.5,
          });
        }
      }
    }
  }

  // STEP 2: CORE TERMS - Search with core job terms
  // These are terms like "reinigung" from "reinigungskraft"
  const coreOptions = {
    ...baseFuseOptions,
    threshold: 0.3, // Relatively strict
    distance: 20, // Some flexibility in matching
    ignoreLocation: false,
    useExtendedSearch: true,
    minMatchCharLength: 3,
    tokenize: true,
  };

  const coreFuse = new Fuse(bfaCategories, coreOptions);

  for (const term of searchTerms.coreTerms) {
    const results = coreFuse.search(term);

    for (const result of results) {
      // Boost matches that clearly contain this core term
      if (result.item.toLowerCase().includes(term.toLowerCase())) {
        allResults.push({
          item: result.item,
          score: (result.score || 0.5) * 0.3, // Major boost
        });
      } else {
        allResults.push({
          item: result.item,
          score: result.score || 0.5,
        });
      }
    }
  }

  // STEP 3: SUPPLEMENTARY TERMS - More general matching
  if (allResults.length < limit * 3) {
    const supplementaryOptions = {
      ...baseFuseOptions,
      threshold: 0.4, // More permissive
      distance: 50, // More flexible matching
      ignoreLocation: true,
      useExtendedSearch: true,
      minMatchCharLength: 3,
    };

    const supplementaryFuse = new Fuse(bfaCategories, supplementaryOptions);

    for (const term of searchTerms.supplementaryTerms) {
      const results = supplementaryFuse.search(term);
      for (const result of results) {
        allResults.push({
          item: result.item,
          score: result.score || 0.6, // No special boost
        });
      }
    }
  }

  // STEP 4: FALLBACK - If we still don't have enough results, try the full title
  if (allResults.length < limit) {
    const fallbackOptions = {
      ...baseFuseOptions,
      threshold: 0.6, // Very permissive
      distance: 100, // Large distance allowed
      ignoreLocation: true,
      useExtendedSearch: true,
    };

    const fallbackFuse = new Fuse(bfaCategories, fallbackOptions);
    const results = fallbackFuse.search(cleanedJobTitle);

    for (const result of results) {
      allResults.push({
        item: result.item,
        score: result.score || 0.8, // Lower priority than other strategies
      });
    }
  }

  // Remove duplicates and keep best scores
  const uniqueResults = new Map<string, number>();

  for (const result of allResults) {
    const existingScore = uniqueResults.get(result.item);
    if (existingScore === undefined || result.score < existingScore) {
      uniqueResults.set(result.item, result.score);
    }
  }

  // Convert to array and sort by score
  const sortedResults = Array.from(uniqueResults.entries())
    .map(([item, score]) => ({ item, score }))
    .sort((a, b) => a.score - b.score);

  // Apply threshold if specified and get top results
  const filteredResults = threshold
    ? sortedResults.filter((r) => r.score <= threshold)
    : sortedResults;

  const topResults = filteredResults.slice(0, limit);

  return topResults.map((result) => result.item);
}

/**
 * Extracts search terms with different priorities from a job title
 * for more effective matching.
 */
function extractSearchTerms(jobTitle: string): {
  exactTerms: string[];
  coreTerms: string[];
  supplementaryTerms: string[];
} {
  const exactTerms: string[] = [];
  const coreTerms: string[] = [];
  const supplementaryTerms: string[] = [];

  // Common German job title suffixes
  // TODD: this should come from appConfig
  const suffixes = [
    "kraft",
    "helfer",
    "personal",
    "arbeiter",
    "fachkraft",
    "meister",
    "techniker",
    "leiter",
    "assistent",
    "manager",
    "berater",
    "händler",
    "kaufmann",
    "kauffrau",
    "verkäufer",
    "wissenschaftler",
    "spezialist",
    "fahrer",
    "führer",
    "wirt",
  ];

  // Add the complete job title for exact matching
  if (jobTitle.trim().length > 3) {
    exactTerms.push(jobTitle.toLowerCase().trim());
  }

  // Split the job title into words
  const words = jobTitle
    .toLowerCase()
    .split(/[\s\-/().,]+/)
    .filter((w) => w.length >= 3);

  // Add multi-word components as exact terms
  if (words.length > 1) {
    for (let i = 0; i < words.length - 1; i++) {
      const twoWordTerm = words[i] + " " + words[i + 1];
      exactTerms.push(twoWordTerm);
    }
  }

  // Process each word for core terms (root words)
  for (const word of words) {
    // First, add the word itself if it's substantive enough
    if (word.length >= 4) {
      supplementaryTerms.push(word);
    }

    // Check for suffix-based job titles
    for (const suffix of suffixes) {
      if (word.endsWith(suffix) && word.length > suffix.length + 2) {
        // Get the root of the word (e.g., "reinigung" from "reinigungskraft")
        const root = word.slice(0, word.length - suffix.length);

        if (root.length >= 3) {
          // Add root as core term
          coreTerms.push(root);

          // Special handling for cleaning jobs
          if (root.includes("reinig")) {
            coreTerms.push("reinigung");
            coreTerms.push("reinig");
          }
        }
      }
    }
  }

  // Add individual words if they're likely job descriptors
  // TODD: this should come from appConfig
  const jobDescriptors = [
    "reinig",
    "pflege",
    "betreu",
    "bau",
    "technik",
    "verkauf",
    "service",
    "logistik",
    "kund",
    "büro",
    "versicher",
    "finanz",
    "produkt",
    "entwickl",
    "fahrer",
    "transport",
    "leiter",
    "führung",
  ];

  for (const word of words) {
    for (const descriptor of jobDescriptors) {
      if (word.includes(descriptor)) {
        // This word contains a key job function - add it as a core term
        coreTerms.push(word);
        coreTerms.push(descriptor);
        break;
      }
    }
  }

  // For single word job titles, extract potential meaningful parts
  if (words.length === 1 && words[0].length >= 8) {
    // Try to find substrings that might be meaningful
    const word = words[0];
    for (let i = 3; i <= word.length - 3; i++) {
      supplementaryTerms.push(word.substring(0, i));
      supplementaryTerms.push(word.substring(i));
    }
  }

  // Add special case handling for common job patterns
  // TODO: general method which gets a mapping from appConfig
  if (jobTitle.toLowerCase().includes("reinig")) {
    coreTerms.push("reinigung");
    coreTerms.push("gebäudereinig");
  }

  return {
    exactTerms: [...new Set(exactTerms)],
    coreTerms: [...new Set(coreTerms)],
    supplementaryTerms: [...new Set(supplementaryTerms)],
  };
}
