//src/ai-options.enum.ts
export enum AiOptions {
  recurion = "Recurion (DSGVO konform)",
  chatGPT = "ChatGPT (Datenverarbeitung außerhalb Europas)",
}

export enum AiAnonymizedName {
  unisex = "Huber-Maier",
}

export enum AiCore {
  GDPRtrust = "GDPRtrust",
  GDPRsave = "GDPRsave",
  perform = "perform",
}

export enum AiModel {
  mini = "mini",
  basic = "basic",
  large = "large",
  vision = "vision",
  visionHigh = "visionHigh",
}

export enum AiMessageType {
  mailCandidate = "mailCandidate",
  mailCustomer = "mailCustomer",
  mailEmployee = "mailEmployee",
  whatsAppCandidate = "whatsAppCandidate",
  whatsAppCustomer = "whatsAppCustomer",
  whatsAppEmployee = "whatsAppEmployee",
}

export enum AiOrder {
  chatWithHandbook = "chatWithHandbook",
  chatWithDispatcherCoach = "chatWithDispatcherCoach",
  commuteSearch = "commuteSearch",
  cvParser = "cvParser",
  extractCareerSteps = "extractCareerSteps",
  extractCustomerContacts = "extractCustomerContacts",
  extractCustomerData = "extractCustomerData",
  extractDemand = "extractDemand",
  extractJobAdData = "extractJobAdData",
  extractWorktimes = "extractWorktimes",
  formatCv = "formatCv",
  generateCandidateTags = "generateCandidateTags",
  generateCustomerTags = "generateCustomerTags",
  generateDashboardText = "generateDashboardText",
  generateEmail = "generateEmail",
  generateEmailforProfile = "generateEmailforProfile",
  generateEmailGDPRsave = "generateEmailGDPRsave",
  generateFunnelQuestions = "generateFunnelQuestions",
  generateJobAdLeadsQuery = "generateJobAdLeadsQuery",
  generateMandantFormContent = "generateMandantFormContent",
  generateMessage = "generateMessage",
  generateMessageGDPRsave = "generateMessageGDPRsave",
  generateProfile = "generateProfile",
  generateWpPluginProfile = "generateWpPluginProfile",
  placementWebSearch = "placementWebSearch",
  translateBugReport = "translateBugReport",
  translateMessage = "translateMessage",
  translateMessageGDPRsave = "translateMessageGDPRsave",
}
