<template>
  <v-form v-model="valid" ref="form">
    <v-row noGutters>
      <v-col cols="12">
        <v-text-field
          v-bind="vStyle.input"
          label="Suchbegriff"
          v-model="localSearchTerm"
          class="mt-1"
          :rules="[rules.required]"
          hint="* Erforderlich"
          persistent-hint
          @click.stop
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-bind="vStyle.input"
          label="Postleitzahl"
          v-model="postcodeCenter"
          class="mt-1 mr-3"
          :rules="[rules.required, rules.postalCode]"
          hint="* Erforderlich"
          persistent-hint
          @click.stop
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-label class="d-flex justify-center">Umkreis in km</v-label>
        <v-slider
          v-model="postcodeRadius"
          :max="100"
          :min="0"
          :step="0.5"
          thumb-label
          @click.stop
        ></v-slider>
      </v-col>
      <v-col cols="6">
        <v-select
          v-bind="vStyle.input"
          :items="mobilityOptions"
          item-title="label"
          item-value="label"
          label="Mobilität"
          v-model="candidateDetailSearchObject.mobility"
          multiple
          class="mr-3"
          @click.stop
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-bind="vStyle.input"
          :items="shiftPreferencesOptions"
          item-title="label"
          item-value="label"
          label="Schichtbereitschaft"
          v-model="candidateDetailSearchObject.shiftPreference"
          multiple
          @click.stop
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          v-bind="vStyle.input"
          :items="statusOptionsRecruit"
          item-title="text"
          item-value="value"
          label="Status"
          v-model="candidateDetailSearchObject.selectedStatuses"
          :rules="[rules.required]"
          hint="* Erforderlich"
          persistent-hint
          multiple
          @click.stop
        ></v-select>
      </v-col>
      <v-col cols="6"
        ><v-checkbox
          @click.stop
          v-model="candidateDetailSearchObject.deepSearch"
          label="Suche in Lebenslauf"
        ></v-checkbox
      ></v-col>
      <v-col cols="12"
        ><v-btn
          :disabled="!valid"
          block
          color="primary"
          @click="startBackendSearch()"
          >Detailsuche starten</v-btn
        ></v-col
      >
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { CandidateSearchCriteria } from "../../models/candidate.model";
import { defineComponent } from "vue";
import { formRules } from "../../helper/form-rules.helper";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "../../enums/inter-component-messagin.enum";
import { mapGetters, mapMutations } from "vuex";
import { MobilitySelectable } from "../../enums/mobilities.enum";
import { MutationType } from "../../enums/vuex-types.enum";
import { ShiftPreferences } from "../../enums/shift-preferences.enum";
import { SpinnerService } from "../../services/spinner.service";
import { ZipCodeService } from "../../services/api/zip-code.service";

export default defineComponent({
  name: "CandidateDetailSearchForm",
  emits: ["closeDialog"],
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      candidateDetailSearchObject: {} as CandidateSearchCriteria,
      localSearchTerm: this.searchTerm,
      postcodeCenter: "",
      postcodeRadius: 30,
      rules: formRules,
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
    mobilityOptions() {
      return getEnumOptions(MobilitySelectable);
    },
    shiftPreferencesOptions() {
      return getEnumOptions(ShiftPreferences);
    },
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    async startBackendSearch() {
      try {
        SpinnerService.showSpinner();
        const zipcodeService = new ZipCodeService();
        this.candidateDetailSearchObject.searchstring = this.localSearchTerm;
        this.candidateDetailSearchObject.postcodes =
          (await zipcodeService.getZipcodesWithinRadius(
            this.postcodeCenter,
            this.postcodeRadius
          )) as unknown as string[];
        this.$emit("closeDialog");
        this.setICM({
          message: InterComponentMessage.deepSearchCandidate,
          payload: {
            candidateDetailSearchObject: this.candidateDetailSearchObject,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
  },
});
</script>
