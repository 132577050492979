<template>
  <BaseDialog
    :show-dialog="showDialog"
    :show-actions="true"
    :width="'var(--d-w-xs)'"
    @closeDialog="closeDialog"
  >
    <template #title> Ergebnisse aus ATS: </template>
    <template #content>
      <v-list>
        <v-list-item
          v-for="(result, index) in resultsCandidateSearch"
          :key="index"
          @click="selectCandidateSearchResult(result)"
        >
          <div>
            <v-list-item-title
              >{{ result.firstName }} {{ result.lastName }}</v-list-item-title
            >
            <v-list-item-subtitle>
              {{ result.addressStreet }} {{ result.addressHouseNumber }},
              {{ result.addressPostalCode }} {{ result.addressCity }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeDialog"> Abbrechen </v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Candidate } from "../../../models/candidate.model";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum DialogSelectAtsCandidatesEmits {
  abort = "abort",
  closeDialog = "closeDialog",
  selectCandidateSearchResult = "selectCandidateSearchResult",
}

export default defineComponent({
  name: "DialogSelectAtsCandidates",
  components: {
    BaseDialog,
  },
  emits: [
    DialogSelectAtsCandidatesEmits.abort,
    DialogSelectAtsCandidatesEmits.closeDialog,
    DialogSelectAtsCandidatesEmits.selectCandidateSearchResult,
  ],
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    resultsCandidateSearch: {
      type: Array as PropType<Candidate[]>,
      required: true,
    },
  },
  methods: {
    closeDialog(abort?: boolean) {
      this.$emit(DialogSelectAtsCandidatesEmits.closeDialog);
      if (abort) {
        this.$emit(DialogSelectAtsCandidatesEmits.abort);
      }
    },
    selectCandidateSearchResult(result: Candidate) {
      this.$emit(
        DialogSelectAtsCandidatesEmits.selectCandidateSearchResult,
        result
      );
      this.closeDialog();
    },
  },
});
</script>
