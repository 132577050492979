import { AllAtsAdapter } from "../adapter/all-ats.adapter";
import { Employee } from "../models/employee.model";
import { AllErpAdapter } from "../adapter/all-erp.adapter";
import { Candidate } from "../models/candidate.model";
import { CandidateService } from "./api/candidate.service";
import { EmployeeService } from "./api/employee.service";
import ToastService from "./toast.service";
export class EmployeeCandidateLinkService {
  private allAtsAdapter: AllAtsAdapter;
  private allErpAdapter: AllErpAdapter;

  constructor() {
    this.allAtsAdapter = new AllAtsAdapter();
    this.allErpAdapter = new AllErpAdapter();
  }

  async getCandidateFromAtsAndLinkToEmployee(employee: Employee) {
    if (!employee.candidateUuid || !employee._id) return;
    ToastService.show(
      `Suche Kandidatendaten von ${employee.firstName} ${employee.lastName} in ATS...`
    );
    try {
      const candidateRequest = {
        candidateUuid: employee.candidateUuid,
      };
      let candidate = (await AllAtsAdapter.getCandidateData(
        candidateRequest
      )) as Candidate;
      candidate.employeeObjectId = employee._id ?? "";
      const newCandidate = await new CandidateService().postCandidate(
        candidate
      );
      if (newCandidate) {
        ToastService.show(
          `Kandidatendaten von ${employee.firstName} ${employee.lastName} in ATS gefunden, verknüpft und gespeichert ...`
        );
        employee.candidateObjectId = newCandidate._id ?? null;
        candidate = newCandidate;

        const updatedEmployee = await new EmployeeService().editEmployee(
          employee
        );
        if (updatedEmployee) employee = updatedEmployee;
      }

      return {
        candidate: candidate,
        employee: employee,
      };
    } catch (error) {
      ToastService.show(`Fehler beim Suchen von Kandidatendaten in ATS.`);
    }
  }
}
