// analog in backend
export const BackendMessage = {
  employee: {
    employeeNotFound: "Employee not found",
    candidateNotFound: "CandidateUuid not found",
    employeeAndCandidateLinked: "Employee and Candidate linked successfully",
    noUpdatesNeeded: "No updates needed for candidate employee link",
  },
  candidate: {
    candidateUuidChangedAndUpdatedInLinkedEmployee:
      "candidateUuid changed and updated in linked employee",
    candidateUuidChangedAndLinkedEmployeeNotFound:
      "candidateUuid changed but linked employee not found",
    candidateUuidChangedAndLinkedEmployeeUpdateFailed:
      "candidateUuid changed but update in linked employee failed",
  },
} as const;
