// src/enums/dependency.enum.ts
export enum EventType {
  state = "state",
  action = "action",
}

export enum Role {
  customer = "customer",
  candidate = "candidate",
  mandant = "mandant",
  employee = "employee",
  demand = "demand",
  user = "user",
  jobAd = "jobAd",
  jobLead = "jobLead",
}

export enum DateSelectable {
  single = "single",
  multiple = "multiple",
}

export enum LinkingStatus {
  checklistCreated = "checklistCreated",
  checklistDone = "checklistDone",
  contractDate = "contractDate",
  demand = "demand",
  demandCreated = "demandCreated",
  demandLinkedToCustomer = "demandLinkedToCustomer",
  followUp = "followUp",
  followUpPrio = "followUpPrio",
  generateProfile = "generateProfile",
  hired = "hired",
  inactive = "inactive",
  inboxApplication = "inboxApplication",
  interview = "interview",
  interviewCanceled = "interviewCanceled",
  interviewCompleted = "interviewCompleted",
  interviewExternal = "interviewExternal",
  interviewNotShownUp = "interviewNotShownUp",
  interviewSuggestionExternal = "interviewSuggestionExternal",
  jobAdExtractedFromCustomer = "jobAdExtractedFromCustomer",
  jobAdExtractedFromDemand = "jobAdExtractedFromDemand",
  jobAdPublishedFromJobsList = "jobAdPublishedFromJobsList",
  jobAdPublishedFromJobsMatrix = "jobAdPublishedFromJobsMatrix",
  jobofferAcceptedExternal = "jobofferAcceptedExternal",
  jobofferExternal = "jobofferExternal",
  landingPageCreated = "landingPageCreated",
  linkingRemoved = "linkingRemoved",
  linkingSwitchedBack = "linkingSwitchedBack",
  noneProfile = "noneProfile",
  openProfile = "openProfile",
  personnelPlacement = "personnelPlacement",
  pool = "pool",
  rejected = "rejected",
  rejectedCandidate = "rejectedCandidate",
  rejectedUnattractive = "rejectedUnattractive",
  spinnerGamePlayed = "spinnerGamePlayed",
  tempPlacement = "tempPlacement",
  trailWorkExternal = "trailWorkExternal",
  trailWorkSuggestionExternal = "trailWorkSuggestionExternal",
}
