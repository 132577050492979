export enum DragEventSpec {
  originComponent = "application/originComponent",
  openCustomer = "application/openCustomer",
  addCustomerFromErp = "application/addCustomerFromErp",
  addCustomerFromIndexPostalCode = "application/addCustomerFromIndexPostalCode",
  addCustomerFromIndexName = "application/addCustomerFromIndexName",
  addJobAdFromJobLead = "application/addJobAdFromJobLead",
  addJobAdFromJobAd = "application/addJobAdFromJobAd",
  hasLinking = "application/hasLinking",
}
