<template>
  <!-- Modal for changing pwd -->
  <BaseDialog
    :showDialog="showDialog"
    :showActions="true"
    :width="'var(--d-w-xs)'"
    @closeDialog="closeDialog()"
  >
    <template #title> Passwort vergessen </template>
    <template #content>
      <v-card-subtitle class="mt-5 ml-2"
        >Bitte Email Adresse eingeben</v-card-subtitle
      >
      <v-card-text>
        <v-text-field
          v-bind="vStyle.input"
          v-model="emailInput"
          label="Email Adresse"
          type="email"
          required
        ></v-text-field>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="$emit(PasswordForgottenDialogEmit.cancel)"
        >Abbrechen</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="green-darken-4" @click="sendPassword">Senden</v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { PasswordChange } from "@/models/password-change.model";
import { defineComponent } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import ToastService from "../../../../services/toast.service";
import { UserService } from "../../../../services/api/user.service";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum PasswordForgottenDialogEmit {
  cancel = "cancel",
  sent = "sent",
}

export default defineComponent({
  name: "PasswordForgottenDialog",
  components: {
    BaseDialog,
  },
  emits: [PasswordForgottenDialogEmit.cancel, PasswordForgottenDialogEmit.sent],
  props: {
    requestError: String,
    showDialog: Boolean,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      emailInput: "",
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      } as PasswordChange,
      PasswordForgottenDialogEmit: PasswordForgottenDialogEmit,
      smAndDown,
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    closeDialog() {
      this.$emit(PasswordForgottenDialogEmit.cancel);
    },
    sendPassword() {
      this.userService.forgotPassword(this.emailInput).then(
        () => {
          this.$emit(PasswordForgottenDialogEmit.sent);
          ToastService.showSuccess("Neues Passwort gesendet");
        },
        () => {
          this.$emit(PasswordForgottenDialogEmit.sent);
          ToastService.showSuccess("Neues Passwort gesendet");
        }
      );
    },
  },
});
</script>
