import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "text-subtitle-2 text-medium-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showModal,
      showActions: true,
      onCloseDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal())),
      width: 'var(--d-w-s)'
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, {
          class: _normalizeClass([{
          'is-expanded': _ctx.filteredResults.length > 0,
          'is-contracted': _ctx.filteredResults.length === 0,
        }, "mb-5"])
        }, {
          default: _withCtx(() => [
            (_ctx.addCustomerModalLabel1)
              ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle?.input, {
                  modelValue: _ctx.localInput1,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localInput1) = $event)),
                  label: _ctx.addCustomerModalLabel1
                }), null, 16, ["modelValue", "label"]))
              : _createCommentVNode("", true),
            (_ctx.addCustomerModalLabel2)
              ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 1 }, _ctx.vStyle?.input, {
                  modelValue: _ctx.localInput2,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localInput2) = $event)),
                  label: _ctx.addCustomerModalLabel2
                }), null, 16, ["modelValue", "label"]))
              : _createCommentVNode("", true),
            (_ctx.showSearchResultsFromErp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.filteredResults.length > 0)
                    ? (_openBlock(), _createBlock(_component_v_label, { key: 0 }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("In ERP gefunden:")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_list, {
                    dense: "",
                    class: "scrollable-list"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredResults, (result, index) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          key: index,
                          class: "clickable",
                          onClick: ($event: any) => (_ctx.displayCustomerNumber(result.customerNumber))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_v_list_item_title, { class: "d-flex justify-space-between" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(result.name) + " ", 1),
                                  _createElementVNode("div", _hoisted_2, _toDisplayString(result.mandantL1
                        ? ` Mandant ${result.mandantL1}`
                        : undefined), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_list_item_subtitle, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(result.address), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "abort",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Abbrechen")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "success",
          onClick: _ctx.submitModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modalActionButtonText), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["showDialog"]),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.resultsModal,
      showActions: true,
      onCloseDialog: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeResultsModal())),
      width: 'var(--d-w-m)'
    }, {
      title: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode("Ergebnisse auswählen")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.selectResult(result))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(result.generalData.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_list_item_subtitle, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(result.addressAndCommunication?.street) + ", " + _toDisplayString(result.addressAndCommunication?.postalCode) + ", " + _toDisplayString(result.addressAndCommunication?.city), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "abort",
          onClick: _ctx.closeResultsModal
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Schließen")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["showDialog"])
  ], 64))
}