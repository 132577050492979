import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.dialog,
    showActions: true,
    width: 'var(--d-w-xxl)',
    onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog()))
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode("Icon auswählen")
    ])),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon, index) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  key: index,
                  cols: "12",
                  sm: "1",
                  md: "1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      density: "compact",
                      variant: "tonal",
                      onClick: ($event: any) => (_ctx.selectIcon(icon))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: _normalizeClass(icon)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(icon), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "abort",
        onClick: _ctx.closeDialog
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Abbrechen")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["showDialog"]))
}