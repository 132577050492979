import store from "../store/store";
import { EventBusEvent, EventBusService } from "./event-bus.service";

export class AutoLogoutService {
  private static instance: AutoLogoutService;
  private timer: ReturnType<typeof setTimeout> | null = null;
  private timeout: number; // Timeout in milli seconds
  private store;

  constructor() {
    this.store = store;
    this.timeout = this.getTimeoutFromStore();
  }

  public static getInstance(): AutoLogoutService {
    if (!AutoLogoutService.instance) {
      AutoLogoutService.instance = new AutoLogoutService();
    }
    return AutoLogoutService.instance;
  }

  // Load timeout value from the store
  private getTimeoutFromStore(): number {
    const minutes =
      parseInt(this.store?.state?.env?.jwtConfig.expiresIn.slice(0, -1)) || 15; // Default to 15 minutes
    return minutes * 60 * 1000; // Convert minutes to milliseconds
  }

  // Start the logout timer
  startTimer() {
    this.clearTimer();

    this.timer = setTimeout(() => {
      EventBusService.getInstance().publish(EventBusEvent.userLoggedOut);
    }, this.timeout);
  }

  // Clear the timer
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  // Dynamically set timeout value and restart timer
  async setTimeoutFromStore() {
    this.timeout = await this.getTimeoutFromStore(); // Update timeout value
    this.startTimer(); // Restart the timer with the new value
  }

  // Method to trigger timer extension
  extendTimer() {
    this.startTimer();
  }
}

export default AutoLogoutService;
