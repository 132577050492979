import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "d-flex" }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createTextVNode("Vorschau Job-Anzeige ")),
          _createVNode(_component_v_spacer),
          (_ctx.isShowSendTemplateToAtsButton())
            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, _ctx.vStyle.btn, {
                color: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickSendTemplateToAts()))
              }), {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createTextVNode("Exportieren")),
                  _createVNode(_component_v_icon, { class: "ml-2" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("fa-solid fa-arrow-right-to-bracket")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 16))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("iframe", {
            ref: "previewFrame",
            srcdoc: _ctx.getHtmlTemplate,
            style: {"width":"100%","border":"none","height":"150rem"}
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}