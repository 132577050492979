import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createSlots as _createSlots, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 2,
  style: {"width":"100%"}
}
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ExtractCandidateUuidFromAtsRecruitLinkForm = _resolveComponent("ExtractCandidateUuidFromAtsRecruitLinkForm")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_CvDropField = _resolveComponent("CvDropField")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_FormNewCarreerStep = _resolveComponent("FormNewCarreerStep")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.showModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          style: {"min-height":"var(--d-h-full)"},
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_sheet, {
                color: "surface",
                style: {"z-index":"1000","position":"fixed","top":"0","width":"99%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        rounded: _ctx.vStyle.card.rounded
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tabs, {
                            modelValue: _ctx.activeTab,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
                            "bg-color": "primary",
                            "slider-color": "on-primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickOnTab(_ctx.activeTab)))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                                return (_openBlock(), _createBlock(_component_v_tab, {
                                  key: index,
                                  value: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(tab), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128)),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_tab, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, { size: "x-large" }, {
                                    default: _withCtx(() => _cache[37] || (_cache[37] = [
                                      _createTextVNode("fa-solid fa-circle-xmark")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["rounded"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "dialog-edit-item-before-menu-shadow dialog-edit-item-top-shadow" }, null, -1))
            ]),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs_window, {
                  modelValue: _ctx.activeTab,
                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.activeTab) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                      return (_openBlock(), _createBlock(_component_v_tabs_window_item, {
                        key: index,
                        value: index,
                        class: "mt-15"
                      }, {
                        default: _withCtx(() => [
                          (index === 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createTextVNode("Allgemeine Daten")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_divider, { class: "mb-3" }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "5"
                                    }, {
                                      default: _withCtx(() => [
                                        (
                        _ctx.localCandidate.applications &&
                        _ctx.localCandidate.applications.length > 0
                      )
                                          ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({
                                              key: 0,
                                              ref_for: true
                                            }, _ctx.vStyle?.input, {
                                              modelValue: 
                        _ctx.localCandidate.applications[
                          _ctx.localCandidate.applications.length - 1
                        ].appliedAs
                      ,
                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
                        _ctx.localCandidate.applications[
                          _ctx.localCandidate.applications.length - 1
                        ].appliedAs
                      ) = $event)),
                                              density: "compact",
                                              label: "Überschrift / Beworben als"
                                            }), null, 16, ["modelValue"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "2"
                                    }, {
                                      default: _withCtx(() => [
                                        (
                        _ctx.localCandidate.applications &&
                        _ctx.localCandidate.applications.length > 0
                      )
                                          ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({
                                              key: 0,
                                              ref_for: true
                                            }, _ctx.vStyle?.input, {
                                              modelValue: 
                        _ctx.localCandidate.applications[
                          _ctx.localCandidate.applications.length - 1
                        ].applicationId
                      ,
                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((
                        _ctx.localCandidate.applications[
                          _ctx.localCandidate.applications.length - 1
                        ].applicationId
                      ) = $event)),
                                              density: "compact",
                                              label: "Profil Id"
                                            }), null, 16, ["modelValue"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "5"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                          modelValue: _ctx.localCandidate.uuid,
                                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localCandidate.uuid) = $event)),
                                          label: "ID / UUID im ATS",
                                          density: "compact",
                                          readonly: ""
                                        }), _createSlots({ _: 2 }, [
                                          (_ctx.$store.getters.softwareIntegration.zvooveRecruit)
                                            ? {
                                                name: "append-inner",
                                                fn: _withCtx(() => [
                                                  _createVNode(_component_v_icon, null, {
                                                    default: _withCtx(() => _cache[40] || (_cache[40] = [
                                                      _createTextVNode("fa-solid fa-link")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_menu, { activator: "parent" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_card, { class: "py-5" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                                                            default: _withCtx(() => _cache[41] || (_cache[41] = [
                                                              _createTextVNode(" Kandidat im ATS Recruit ")
                                                            ])),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_card_text, null, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_ExtractCandidateUuidFromAtsRecruitLinkForm, {
                                                                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
                                                                onExtractedUuid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.localCandidate.uuid = $event))
                                                              })
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                key: "0"
                                              }
                                            : undefined
                                        ]), 1040, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "2"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_divider, { class: "mb-6" }),
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_label, { class: "mb-4" }, {
                                                  default: _withCtx(() => _cache[42] || (_cache[42] = [
                                                    _createTextVNode("Personendaten")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { "no-gutters": "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.salutationCatalogId,
                                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localCandidate.salutationCatalogId) = $event)),
                                                          items: _ctx.salutations,
                                                          "item-title": "value",
                                                          "item-value": "label",
                                                          density: "compact",
                                                          label: "Anrede",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue", "items"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.title,
                                                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localCandidate.title) = $event)),
                                                          density: "compact",
                                                          label: "Titel",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.firstName,
                                                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localCandidate.firstName) = $event)),
                                                          density: "compact",
                                                          label: "Vorname",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.lastName,
                                                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localCandidate.lastName) = $event)),
                                                          density: "compact",
                                                          label: "Nachname",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.formattedBirthDate,
                                                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formattedBirthDate) = $event)),
                                                          type: "date",
                                                          density: "compact",
                                                          label: "Geburtsdatum",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.birthPlace,
                                                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localCandidate.birthPlace) = $event)),
                                                          label: "Geburtsort",
                                                          density: "compact",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.birthName,
                                                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localCandidate.birthName) = $event)),
                                                          label: "Geburtsname",
                                                          density: "compact",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.maritalStatus,
                                                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localCandidate.maritalStatus) = $event)),
                                                          items: _ctx.maritalStatus,
                                                          "item-title": "value",
                                                          "item-value": "label",
                                                          density: "compact",
                                                          label: "Familienstand",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue", "items"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.nationality,
                                                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localCandidate.nationality) = $event)),
                                                          label: "Nationalität",
                                                          density: "compact",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "6"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.residencePermitUntil,
                                                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localCandidate.residencePermitUntil) = $event)),
                                                          label: "Aufenthaltserlaubnis bis",
                                                          density: "compact",
                                                          class: "mr-4",
                                                          type: "date",
                                                          clearable: ""
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16),
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_label, { class: "mb-4" }, {
                                                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                                                    _createTextVNode(" Schichtbereitschaft ")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                  modelValue: _ctx.localCandidate.shiftPreference,
                                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localCandidate.shiftPreference) = $event)),
                                                  items: _ctx.shiftPreferencesOption,
                                                  "item-title": "label",
                                                  "item-value": "label",
                                                  density: "compact",
                                                  label: _ctx.localCandidate?.shiftIcon ?? ''
                                                }), null, 16, ["modelValue", "items", "label"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_label, { class: "mb-4" }, {
                                                  default: _withCtx(() => _cache[44] || (_cache[44] = [
                                                    _createTextVNode(" Adresse ")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, { noGutters: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "8"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.addressStreet,
                                                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.localCandidate.addressStreet) = $event)),
                                                          density: "compact",
                                                          label: "Straße",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "4"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.addressHouseNumber,
                                                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.localCandidate.addressHouseNumber) = $event)),
                                                          density: "compact",
                                                          label: "Hausnummer",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "4"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.addressPostalCode,
                                                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.localCandidate.addressPostalCode) = $event)),
                                                          density: "compact",
                                                          label: "PLZ",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "8"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          modelValue: _ctx.localCandidate.addressCity,
                                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.localCandidate.addressCity) = $event)),
                                                          density: "compact",
                                                          label: "Ort",
                                                          class: "mr-4"
                                                        }), null, 16, ["modelValue"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16),
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_label, { class: "mb-4" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("Mobilität " + _toDisplayString(_ctx.localCandidate?.mobilityRadius) + " km", 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createElementVNode("div", _hoisted_2, [
                                                  _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                    modelValue: _ctx.localCandidate.mobility,
                                                    "onUpdate:modelValue": [
                                                      _cache[23] || (_cache[23] = ($event: any) => ((_ctx.localCandidate.mobility) = $event)),
                                                      _cache[24] || (_cache[24] = ($event: any) => (
                              _ctx.onMobilityChange(
                                _ctx.localCandidate?.mobility as Mobility
                              )
                            ))
                                                    ],
                                                    items: _ctx.mobilities,
                                                    label: "Beförderungsmittel",
                                                    density: "compact",
                                                    "max-width": "200",
                                                    class: "mr-4"
                                                  }), null, 16, ["modelValue", "items"]),
                                                  _createVNode(_component_v_slider, {
                                                    class: "mt-5",
                                                    modelValue: _ctx.localCandidate.mobilityRadius,
                                                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.localCandidate.mobilityRadius) = $event)),
                                                    max: 100,
                                                    min: 0,
                                                    step: 0.5,
                                                    "thumb-label": "always"
                                                  }, null, 8, ["modelValue"])
                                                ]),
                                                _cache[45] || (_cache[45] = _createElementVNode("div", { style: {"height":"1.5rem"} }, null, -1))
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[46] || (_cache[46] = [
                                        _createTextVNode("Kommunikationsmittel")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_divider, { class: "mb-3" }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCandidate?.communicationMeans, (item, index) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        cols: "12",
                                        md: "3",
                                        key: item?._id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_select, _mergeProps({
                                                    density: "compact",
                                                    ref_for: true
                                                  }, _ctx.vStyle?.input, {
                                                    modelValue: item.type,
                                                    "onUpdate:modelValue": ($event: any) => ((item.type) = $event),
                                                    items: _ctx.communicationTypes,
                                                    "item-title": "label",
                                                    "item-value": "value",
                                                    label: "Typ",
                                                    class: "mt-9 mr-7"
                                                  }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                                                  _createVNode(_component_v_btn, {
                                                    variant: "text",
                                                    density: "compact",
                                                    icon: "",
                                                    onClick: ($event: any) => (_ctx.removeCommunicationMethod(index))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_icon, null, {
                                                        default: _withCtx(() => _cache[47] || (_cache[47] = [
                                                          _createTextVNode("fa-solid fa-xmark")
                                                        ])),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"])
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_v_card_text, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_text_field, _mergeProps({
                                                    density: "compact",
                                                    ref_for: true
                                                  }, _ctx.vStyle?.input, {
                                                    modelValue: item.description,
                                                    "onUpdate:modelValue": ($event: any) => ((item.description) = $event),
                                                    label: "Beschreibung"
                                                  }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                                  _createVNode(_component_v_text_field, _mergeProps({
                                                    density: "compact",
                                                    ref_for: true
                                                  }, _ctx.vStyle?.input, {
                                                    modelValue: item.value,
                                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                                    label: "Wert"
                                                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1040)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128)),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      md: "3"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, {
                                          class: "mb-4",
                                          border: "dashed"
                                        }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_select, _mergeProps({
                                                  density: "compact",
                                                  ref_for: true
                                                }, _ctx.vStyle?.input, {
                                                  modelValue: _ctx.newCommunicationMethod.type,
                                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.newCommunicationMethod.type) = $event)),
                                                  items: _ctx.communicationTypes,
                                                  "item-title": "label",
                                                  "item-value": "value",
                                                  label: "Typ"
                                                }), null, 16, ["modelValue", "items"]),
                                                _createVNode(_component_v_text_field, _mergeProps({
                                                  density: "compact",
                                                  ref_for: true
                                                }, _ctx.vStyle?.input, {
                                                  modelValue: _ctx.newCommunicationMethod.description,
                                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.newCommunicationMethod.description) = $event)),
                                                  label: "Beschreibung"
                                                }), null, 16, ["modelValue"]),
                                                _createVNode(_component_v_text_field, _mergeProps({
                                                  density: "compact",
                                                  ref_for: true
                                                }, _ctx.vStyle?.input, {
                                                  modelValue: _ctx.newCommunicationMethod.value,
                                                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.newCommunicationMethod.value) = $event)),
                                                  label: "Wert"
                                                }), null, 16, ["modelValue"]),
                                                _createVNode(_component_v_btn, _mergeProps({ ref_for: true }, _ctx.vStyle?.btn, {
                                                  color: "success",
                                                  onClick: _ctx.addCommunicationMethod,
                                                  block: ""
                                                }), {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, null, {
                                                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                                                        _createTextVNode("fa-solid fa-plus")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[49] || (_cache[49] = _createTextVNode(" Hinzufügen "))
                                                  ]),
                                                  _: 1
                                                }, 16, ["onClick"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : (index === 1)
                              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                                        _createTextVNode("Skills")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_divider, { class: "mb-3" }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skillGroups, (group) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        cols: "12",
                                        key: group.groupDescription
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { class: "mb-4" }), {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(group.groupDescription) + " ", 1),
                                                  _createVNode(_component_v_spacer),
                                                  _createVNode(_component_v_btn, {
                                                    icon: "",
                                                    variant: "text",
                                                    onClick: ($event: any) => (_ctx.removeSkillGroup(group))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_icon, null, {
                                                        default: _withCtx(() => _cache[51] || (_cache[51] = [
                                                          _createTextVNode("fa-solid fa-xmark")
                                                        ])),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"])
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_v_card_text, null, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_col, { cols: "12" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_chip_group, {
                                                            modelValue: group.selectedSkills,
                                                            "onUpdate:modelValue": ($event: any) => ((group.selectedSkills) = $event),
                                                            multiple: "",
                                                            column: ""
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.skills, (skill, index) => {
                                                                return (_openBlock(), _createBlock(_component_v_chip, {
                                                                  key: index,
                                                                  color: "primary",
                                                                  "model-value": true,
                                                                  "close-icon": "fa-solid fa-xmark",
                                                                  "prepend-icon": 
                                _ctx.getAuspraegungIcon(skill.level, skill.type)
                              ,
                                                                  closable: "",
                                                                  "onClick:close": ($event: any) => (_ctx.removeSkill(group, index)),
                                                                  onClick: ($event: any) => (_ctx.toggleAuspraegung(skill))
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(skill.description), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["prepend-icon", "onClick:close", "onClick"]))
                                                              }), 128)),
                                                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                                label: "Neuer Skill",
                                                                rounded: "pill",
                                                                density: "compact",
                                                                modelValue: group.newSkill,
                                                                "onUpdate:modelValue": ($event: any) => ((group.newSkill) = $event),
                                                                style: {"max-width":"12rem"},
                                                                onKeyup: _withKeys(($event: any) => (_ctx.addSkill(group)), ["enter"])
                                                              }), null, 16, ["modelValue", "onUpdate:modelValue", "onKeyup"])
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["modelValue", "onUpdate:modelValue"])
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1040)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128)),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, { border: "dashed" }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_title, null, {
                                              default: _withCtx(() => [
                                                _cache[52] || (_cache[52] = _createTextVNode(" Neue Skill-Gruppe ")),
                                                _createVNode(_component_v_spacer)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "5" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          label: "Skill-Gruppenbezeichnung",
                                                          modelValue: _ctx.newSkillGroup.groupDescription,
                                                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.newSkillGroup.groupDescription) = $event)),
                                                          error: !_ctx.newSkillGroup?.groupDescription,
                                                          "error-messages": "Erforderlich"
                                                        }), null, 16, ["modelValue", "error"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, { cols: "5" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                          label: "Erster Skill",
                                                          modelValue: _ctx.newSkillGroup.newSkill,
                                                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.newSkillGroup.newSkill) = $event)),
                                                          error: !_ctx.newSkillGroup?.newSkill,
                                                          "error-messages": "Erforderlich"
                                                        }), null, 16, ["modelValue", "error"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_col, { cols: "2" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_btn, _mergeProps({ ref_for: true }, _ctx.vStyle?.btn, {
                                                          block: "",
                                                          color: "success",
                                                          onClick: _ctx.addSkillGroup,
                                                          disabled: 
                              !_ctx.newSkillGroup?.groupDescription ||
                              !_ctx.newSkillGroup?.newSkill
                            
                                                        }), {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_icon, null, {
                                                              default: _withCtx(() => _cache[53] || (_cache[53] = [
                                                                _createTextVNode("fa-solid fa-plus")
                                                              ])),
                                                              _: 1
                                                            }),
                                                            _cache[54] || (_cache[54] = _createTextVNode(" Hinzufügen "))
                                                          ]),
                                                          _: 1
                                                        }, 16, ["onClick", "disabled"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 16)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1024))
                              : (index === 2)
                                ? (_openBlock(), _createBlock(_component_v_row, {
                                    key: 2,
                                    onDragover: _cache[31] || (_cache[31] = ($event: any) => (_ctx.handleCvDragOver())),
                                    onDragleave: _cache[32] || (_cache[32] = ($event: any) => (_ctx.handleCvDragLeave()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_CvDropField, {
                                        parseWithPersonalData: false,
                                        onParsedCareerSteps: _ctx.aiReceivedCareerSteps,
                                        showDropField: _ctx.isCvDragOver,
                                        fieldText: {
                    icon: 'fa-solid fa-file-circle-plus',
                    title: 'Lebenslauf hier ablegen',
                    subtitle: 'Dateiformat *.docx *.pdf *.png *.jpg möglich',
                    text: 'oder einen Text markieren und auf per Drag & Drop dieses Feld ziehen',
                  }
                                      }, null, 8, ["onParsedCareerSteps", "showDropField"]),
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => _cache[55] || (_cache[55] = [
                                          _createTextVNode("Lebenslauf")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_divider, { class: "mb-3" }),
                                      _createVNode(_component_v_col, { cols: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_container, { class: "d-flex ma-0 pa-0" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                onClick: _ctx.sortCvAsc,
                                                icon: "",
                                                size: "small",
                                                variant: "text"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_icon, null, {
                                                    default: _withCtx(() => _cache[56] || (_cache[56] = [
                                                      _createTextVNode(" fa-solid fa-arrow-up ")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_tooltip, {
                                                    activator: "parent",
                                                    location: "bottom"
                                                  }, {
                                                    default: _withCtx(() => _cache[57] || (_cache[57] = [
                                                      _createTextVNode("Lebenslauf aufsteigend sortieren")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"]),
                                              _createVNode(_component_v_btn, {
                                                onClick: _ctx.sortCvDesc,
                                                icon: "",
                                                size: "small",
                                                variant: "text"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_icon, null, {
                                                    default: _withCtx(() => _cache[58] || (_cache[58] = [
                                                      _createTextVNode(" fa-solid fa-arrow-down ")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_tooltip, {
                                                    activator: "parent",
                                                    location: "bottom"
                                                  }, {
                                                    default: _withCtx(() => _cache[59] || (_cache[59] = [
                                                      _createTextVNode("Lebenslauf absteigend sortieren")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"]),
                                              _createVNode(_component_v_spacer),
                                              _createVNode(_component_v_btn, {
                                                onClick: _ctx.reloadCv,
                                                icon: "",
                                                size: "small",
                                                variant: "text"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_icon, null, {
                                                    default: _withCtx(() => _cache[60] || (_cache[60] = [
                                                      _createTextVNode(" fa-solid fa-rotate-left ")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_tooltip, {
                                                    activator: "parent",
                                                    location: "bottom"
                                                  }, {
                                                    default: _withCtx(() => _cache[61] || (_cache[61] = [
                                                      _createTextVNode("Lebenslauf neu laden")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"]),
                                              _createVNode(_component_v_btn, {
                                                onClick: _ctx.aiFormatCv,
                                                icon: "",
                                                size: "small",
                                                variant: "text"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_icon, null, {
                                                    default: _withCtx(() => _cache[62] || (_cache[62] = [
                                                      _createTextVNode(" fa-solid fa-rocket ")
                                                    ])),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_tooltip, {
                                                    activator: "parent",
                                                    location: "bottom"
                                                  }, {
                                                    default: _withCtx(() => _cache[63] || (_cache[63] = [
                                                      _createTextVNode("Lebenslauf mit AI vervollständigen")
                                                    ])),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["onClick"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      (_ctx.isCvDesc)
                                        ? (_openBlock(), _createBlock(_component_v_col, {
                                            key: 0,
                                            cols: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_FormNewCarreerStep, {
                                                employmentTypes: _ctx.employmentTypes,
                                                onAddEntry: _ctx.addEntry
                                              }, null, 8, ["employmentTypes", "onAddEntry"])
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCandidate.careerSteps, (entry, index) => {
                                        return (_openBlock(), _createBlock(_component_v_col, {
                                          cols: "12",
                                          key: entry._id,
                                          class: "py-0 ma-0"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card, {
                                              variant: "flat",
                                              class: "pa-0"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_spacer)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "4"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_row, { noGutters: "" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: "plain",
                                                                  value: 
                                entry.from
                                  ? _ctx.FormatDate.standard(entry.from)
                                  : ''
                              ,
                                                                  label: "Von",
                                                                  type: "date",
                                                                  density: "compact",
                                                                  class: "my-0",
                                                                  onChange: 
                              (event) =>
                                _ctx.updateVonDate(entry, event.target.value)
                            
                                                                }, null, 8, ["value", "onChange"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: "plain",
                                                                  value: 
                                entry.until
                                  ? _ctx.FormatDate.standard(entry.until)
                                  : ''
                              ,
                                                                  label: "Bis",
                                                                  type: "date",
                                                                  density: "compact",
                                                                  class: "my-0",
                                                                  onChange: 
                              (event) =>
                                _ctx.updateBisDate(entry, event.target.value)
                            
                                                                }, null, 8, ["value", "onChange"])
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_col, {
                                                      cols: "12",
                                                      md: "8"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_row, { noGutters: "" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "8"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  variant: "plain",
                                                                  modelValue: entry.title,
                                                                  "onUpdate:modelValue": ($event: any) => ((entry.title) = $event),
                                                                  label: "Titel",
                                                                  density: "compact",
                                                                  class: "my-0 text-h6",
                                                                  style: {"font-weight":"600"}
                                                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "4"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("div", _hoisted_3, [
                                                                  _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                                                    modelValue: entry.type,
                                                                    "onUpdate:modelValue": ($event: any) => ((entry.type) = $event),
                                                                    items: _ctx.employmentTypes,
                                                                    "item-title": "description",
                                                                    "item-value": "systemName",
                                                                    label: "Typ",
                                                                    density: "compact",
                                                                    class: "my-0 ml-2",
                                                                    "max-width": 250
                                                                  }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                                                                  _createVNode(_component_v_btn, {
                                                                    icon: "",
                                                                    variant: "text",
                                                                    class: "ml-4",
                                                                    density: "compact",
                                                                    onClick: ($event: any) => (_ctx.removeEntry(index))
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_v_icon, null, {
                                                                        default: _withCtx(() => _cache[64] || (_cache[64] = [
                                                                          _createTextVNode("fa-solid fa-xmark")
                                                                        ])),
                                                                        _: 1
                                                                      })
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["onClick"])
                                                                ])
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_text_field, {
                                                          variant: "plain",
                                                          modelValue: entry.at,
                                                          "onUpdate:modelValue": ($event: any) => ((entry.at) = $event),
                                                          label: "Bei",
                                                          density: "compact",
                                                          class: "my-0"
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                                        _createVNode(_component_v_textarea, {
                                                          variant: "plain",
                                                          modelValue: entry.details,
                                                          "onUpdate:modelValue": ($event: any) => ((entry.details) = $event),
                                                          label: "Details",
                                                          rows: "5",
                                                          density: "compact",
                                                          class: "my-0"
                                                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128)),
                                      (!_ctx.isCvDesc)
                                        ? (_openBlock(), _createBlock(_component_v_col, {
                                            key: 1,
                                            cols: "12"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_FormNewCarreerStep, {
                                                employmentTypes: _ctx.employmentTypes,
                                                onAddEntry: _ctx.addEntry
                                              }, null, 8, ["employmentTypes", "onAddEntry"])
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (index === 3)
                                  ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_title, { class: "mb-0" }, {
                                          default: _withCtx(() => _cache[65] || (_cache[65] = [
                                            _createElementVNode("h2", null, [
                                              _createElementVNode("i", { class: "fa-solid fa-users-viewfinder" }),
                                              _createTextVNode(" JOB Leads ")
                                            ], -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "d-flex" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, null, {
                                                  default: _withCtx(() => _cache[66] || (_cache[66] = [
                                                    _createTextVNode("Stellenanzeigen aus Anzeigendaten")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_spacer),
                                                _createVNode(_component_v_btn, {
                                                  icon: "",
                                                  variant: "text",
                                                  onClick: _ctx.fetchJobAdLeads
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("img", {
                                                      src: 
                          _ctx.$store.getters.getEnv.assetsUrl +
                          '/icons/icon-index-anzeigendaten.png'
                        ,
                                                      class: "button-icon"
                                                    }, null, 8, _hoisted_4),
                                                    _createVNode(_component_v_tooltip, {
                                                      activator: "parent",
                                                      location: "bottom"
                                                    }, {
                                                      default: _withCtx(() => _cache[67] || (_cache[67] = [
                                                        _createTextVNode("neu von Anzeigendaten laden")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }, 8, ["onClick"]),
                                                _createVNode(_component_v_btn, {
                                                  icon: "",
                                                  variant: "text",
                                                  onClick: _cache[33] || (_cache[33] = ($event: any) => (
                        _ctx.$emit(_ctx.DialogEditCandidateEmit.generateAiSearchterm)
                      ))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_tooltip, {
                                                      activator: "parent",
                                                      location: "bottom"
                                                    }, {
                                                      default: _withCtx(() => _cache[68] || (_cache[68] = [
                                                        _createTextVNode("AI Suche starten")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_icon, null, {
                                                      default: _withCtx(() => _cache[69] || (_cache[69] = [
                                                        _createTextVNode("fa-solid fa-rocket")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_btn, {
                                                  icon: "",
                                                  variant: "text",
                                                  onClick: _cache[34] || (_cache[34] = ($event: any) => (
                        _ctx.$emit(_ctx.DialogEditCandidateEmit.manualAnzeigendatenSearch)
                      ))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_tooltip, {
                                                      activator: "parent",
                                                      location: "bottom"
                                                    }, {
                                                      default: _withCtx(() => _cache[70] || (_cache[70] = [
                                                        _createTextVNode("manuelle Suche")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_v_icon, null, {
                                                      default: _withCtx(() => _cache[71] || (_cache[71] = [
                                                        _createTextVNode("fa-solid fa-magnifying-glass")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_divider, { class: "mb-3" }),
                                            _createVNode(_component_v_list, null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCandidate.jobAdLeads, (jobAd, index) => {
                                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                                    key: jobAd.adId,
                                                    class: "job-ad ma-0 pa-0",
                                                    onClick: ($event: any) => (_ctx.openLink(jobAd.link ?? ''))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_container, { class: "ma-0 pa-0 d-flex justify-space-between align-center" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_container, { class: "ma-0 pa-0" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_list_item_title, null, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(jobAd.jobTitle), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024),
                                                              _createVNode(_component_v_list_item_subtitle, null, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(jobAd.date) + ": " + _toDisplayString(jobAd.location) + " - " + _toDisplayString(jobAd.sourceText) + " ", 1),
                                                                  _createElementVNode("span", {
                                                                    innerHTML: jobAd.listPrice
                                                                  }, null, 8, _hoisted_5)
                                                                ]),
                                                                _: 2
                                                              }, 1024)
                                                            ]),
                                                            _: 2
                                                          }, 1024),
                                                          _createVNode(_component_v_btn, {
                                                            icon: "",
                                                            variant: "text",
                                                            onClick: _withModifiers(($event: any) => (_ctx.removeJobAd(index)), ["stop"])
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_icon, null, {
                                                                default: _withCtx(() => _cache[72] || (_cache[72] = [
                                                                  _createTextVNode("fa-solid fa-xmark")
                                                                ])),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["onClick"])
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (index === 4)
                                    ? (_openBlock(), _createBlock(_component_v_row, { key: 4 }, {
                                        default: _withCtx(() => [
                                          (
                    !_ctx.localCandidate?.employeeObjectId && !_ctx.linkedEmployee?._id
                  )
                                            ? (_openBlock(), _createBlock(_component_v_alert, {
                                                key: 0,
                                                class: "ma-4 pa-4",
                                                variant: "tonal",
                                                border: "start",
                                                color: "secondary",
                                                title: "Kein ERP Personaldatensatz mit diesem Kandidaten verknüpft",
                                                icon: "fa-solid fa-link-slash",
                                                closable: ""
                                              }, {
                                                default: _withCtx(() => _cache[73] || (_cache[73] = [
                                                  _createTextVNode(" Falls der Kandidat noch nicht in die Personal (ERP)-Spalte übertragen wurde, kann dieser einfach per Drag & Drop auf die Personal (ERP)-Spalte gezogen werden. "),
                                                  _createElementVNode("br", null, null, -1),
                                                  _createTextVNode(" Die Verknüpfung wird dann in der Regel automatisch hergestellt kann jedoch auch manuell über die Mitarbeiterkarte erfolgen. ")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          (
                    _ctx.localCandidate?.employeeObjectId && !_ctx.linkedEmployee?._id
                  )
                                            ? (_openBlock(), _createBlock(_component_v_alert, {
                                                key: 1,
                                                class: "ma-4 pa-4",
                                                variant: "tonal",
                                                border: "start",
                                                color: "error",
                                                title: "Fehler beim Laden des verknüften Personaldatensatzes",
                                                icon: "fa-solid fa-circle-xmark",
                                                closable: ""
                                              }, {
                                                default: _withCtx(() => [
                                                  _cache[76] || (_cache[76] = _createTextVNode(" Der verknüpfte ERP Personaldatensatz konnte in der Datenbank nicht gefunden werden und ist eventuell gelöscht worden! ")),
                                                  _createVNode(_component_v_btn, {
                                                    block: "",
                                                    class: "mt-15",
                                                    color: "error",
                                                    variant: "text",
                                                    onClick: _ctx.unlinkEmployee
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                        default: _withCtx(() => _cache[74] || (_cache[74] = [
                                                          _createTextVNode("fa-solid fa-link-slash")
                                                        ])),
                                                        _: 1
                                                      }),
                                                      _cache[75] || (_cache[75] = _createTextVNode(" Personaldaten trennen "))
                                                    ]),
                                                    _: 1
                                                  }, 8, ["onClick"])
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          (_ctx.linkedEmployee?._id)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                                _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", null, [
                                                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                        default: _withCtx(() => _cache[77] || (_cache[77] = [
                                                          _createTextVNode("fa-solid fa-link")
                                                        ])),
                                                        _: 1
                                                      }),
                                                      _cache[78] || (_cache[78] = _createTextVNode(" verlinkt mit Personaldaten ")),
                                                      _createElementVNode("strong", null, _toDisplayString(_ctx.linkedEmployee?.firstName) + " " + _toDisplayString(_ctx.linkedEmployee?.lastName), 1),
                                                      _createTextVNode(" (" + _toDisplayString(_ctx.linkedEmployee?.employeeNumber) + ") ", 1)
                                                    ]),
                                                    _createVNode(_component_v_spacer),
                                                    _createVNode(_component_v_btn, {
                                                      color: "error",
                                                      variant: "text",
                                                      onClick: _ctx.unlinkEmployee
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                          default: _withCtx(() => _cache[79] || (_cache[79] = [
                                                            _createTextVNode("fa-solid fa-link-slash")
                                                          ])),
                                                          _: 1
                                                        }),
                                                        _cache[80] || (_cache[80] = _createTextVNode(" Personaldaten trennen "))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["onClick"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_card_text, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(" Geburtsdatum: " + _toDisplayString(_ctx.linkedEmployee?.birthDate
                        ? _ctx.FormatDate.germanStandard(_ctx.linkedEmployee.birthDate)
                        : "unbekannt"), 1),
                                                    _cache[82] || (_cache[82] = _createElementVNode("br", null, null, -1)),
                                                    _createElementVNode("div", _hoisted_7, [
                                                      _cache[81] || (_cache[81] = _createElementVNode("p", null, "Adresse:", -1)),
                                                      _createElementVNode("p", null, _toDisplayString(_ctx.linkedEmployee?.address?.street), 1),
                                                      _createElementVNode("p", null, _toDisplayString(_ctx.linkedEmployee?.address?.postalCode) + " " + _toDisplayString(_ctx.linkedEmployee?.address?.city), 1)
                                                    ]),
                                                    (_ctx.linkedEmployee?.address?.phone1)
                                                      ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Telefon 1: " + _toDisplayString(_ctx.linkedEmployee?.address?.phone1), 1))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.linkedEmployee?.address?.phone2)
                                                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, " Telefon 2: " + _toDisplayString(_ctx.linkedEmployee?.address?.phone2), 1))
                                                      : _createCommentVNode("", true),
                                                    (_ctx.linkedEmployee?.address?.email)
                                                      ? (_openBlock(), _createElementBlock("p", _hoisted_10, " Email: " + _toDisplayString(_ctx.linkedEmployee?.address?.email), 1))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  _: 1
                                                })
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _cache[83] || (_cache[83] = _createElementVNode("div", { style: {"height":"2rem"} }, null, -1))
              ]),
              _: 1
            }),
            _cache[86] || (_cache[86] = _createElementVNode("div", { class: "dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow" }, null, -1)),
            _createVNode(_component_v_card_actions, { class: "dialog-edit-item-action-buttons" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => _cache[84] || (_cache[84] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _ctx.submitModal
                }, {
                  default: _withCtx(() => _cache[85] || (_cache[85] = [
                    _createTextVNode("Speichern")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}