import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: _normalizeClass(["d-flex align-center", _ctx.mdAndDown ? 'flex-row' : 'flex-column']),
    width: _ctx.mdAndDown ? '100vw' : '5rem',
    height: _ctx.mdAndDown ? '3rem' : '100%',
    color: "primary-darken-1"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          class: _normalizeClass(["menu-icon", _ctx.mdAndDown ? 'flex-row ml-3 mr-5' : 'mt-6 mb-15']),
          src: _ctx.chipIcon,
          style: _normalizeStyle({
          width: _ctx.mdAndDown ? '2.3rem' : _ctx.getDiameters().imageSize,
          height: _ctx.mdAndDown ? '2.3rem' : _ctx.getDiameters().imageSize,
          objectFit: 'contain',
        })
        }, null, 14, _hoisted_1),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "left"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Recurion")
          ])),
          _: 1
        }),
        _createVNode(_component_v_menu, { activator: "parent" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openKnowledgeBase()))
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("fa-brands fa-youtube")
                      ])),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Grundkurs: Videos")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openRecurionChat()))
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("fa-brands fa-rocketchat")
                      ])),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Recurion Chat")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'ml-10 mr-5' : 'mt-10 mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : _ctx.getDiameters().sheetHeight,
        color: 
        _ctx.$route.path === _ctx.Routes.dashboard ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.dashboard)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : _ctx.getDiameters().iconSize
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode("fas fa-tachometer-alt")
            ])),
            _: 1
          }, 8, ["size"]),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "left"
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("Vertriebskennzahlen / Dashboard")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : _ctx.getDiameters().sheetHeight,
        color: 
        _ctx.$route.path === _ctx.Routes.recruiting ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.recruiting)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : _ctx.getDiameters().iconSize
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("fas fa-people-arrows")
            ])),
            _: 1
          }, 8, ["size"]),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "left"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Zum Recruiting")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : _ctx.getDiameters().sheetHeight,
        color: 
        _ctx.$route.path === _ctx.Routes.dispostion ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.dispostion)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : _ctx.getDiameters().iconSize
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("fas fa-headset")
            ])),
            _: 1
          }, 8, ["size"]),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "left"
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [
              _createTextVNode("Zur Disposition")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : _ctx.getDiameters().sheetHeight,
        color: 
        _ctx.$route.path === _ctx.Routes.administration ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.administration)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : _ctx.getDiameters().iconSize
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("far fa-folder-open")
            ])),
            _: 1
          }, 8, ["size"]),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "left"
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("Verwaltung / Dokumentation")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      (_ctx.$store.state.company.softwareIntegration?.workTime)
        ? (_openBlock(), _createBlock(_component_v_sheet, {
            key: 0,
            class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
            width: _ctx.mdAndDown ? '5rem' : '100%',
            height: _ctx.mdAndDown ? '100%' : _ctx.getDiameters().sheetHeight,
            color: 
        _ctx.$route.path === _ctx.Routes.worktime ? 'secondary' : 'primary-darken-1'
      ,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.worktime)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                class: "menu-icon",
                size: _ctx.mdAndDown ? 'large' : _ctx.getDiameters().iconSize
              }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode("far fa-clock")
                ])),
                _: 1
              }, 8, ["size"]),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "left"
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode("WorkTime: AI Erkennung von Arbeitszeiten")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class", "width", "height", "color"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "width", "height"]))
}