<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
    >
      <v-card style="min-height: var(--d-h-full)" class="pa-0">
        <div>
          <v-sheet
            color="surface"
            style="z-index: 1000; position: fixed; top: 0; width: 99%"
          >
            <v-card-title>
              <v-card :rounded="vStyle.card.rounded">
                <v-tabs
                  v-model="activeTab"
                  bg-color="primary"
                  slider-color="on-primary"
                  @click="clickOnTab(activeTab)"
                >
                  <v-tab
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :value="index"
                  >
                    {{ tab }}
                  </v-tab>
                  <v-spacer></v-spacer>
                  <v-tab @click="closeModal()"
                    ><v-icon size="x-large"
                      >fa-solid fa-circle-xmark</v-icon
                    ></v-tab
                  >
                </v-tabs>
              </v-card>
            </v-card-title>
          </v-sheet>
          <div
            class="dialog-edit-item-before-menu-shadow dialog-edit-item-top-shadow"
          ></div>
        </div>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
              class="mt-15"
            >
              <!--Tab general data + address-->
              <div v-if="index === 0">
                <v-row>
                  <v-card-title>Allgemeine Daten</v-card-title>
                  <v-divider class="mb-3"></v-divider>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-bind="vStyle?.input"
                      v-if="
                        localCandidate.applications &&
                        localCandidate.applications.length > 0
                      "
                      v-model="
                        localCandidate.applications[
                          localCandidate.applications.length - 1
                        ].appliedAs
                      "
                      density="compact"
                      label="Überschrift / Beworben als"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-bind="vStyle?.input"
                      v-if="
                        localCandidate.applications &&
                        localCandidate.applications.length > 0
                      "
                      v-model="
                        localCandidate.applications[
                          localCandidate.applications.length - 1
                        ].applicationId
                      "
                      density="compact"
                      label="Profil Id"
                    />
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-bind="vStyle?.input"
                      v-model="localCandidate.uuid"
                      label="ID / UUID im ATS"
                      density="compact"
                      readonly
                    >
                      <template
                        #append-inner
                        v-if="$store.getters.softwareIntegration.zvooveRecruit"
                      >
                        <v-icon>fa-solid fa-link</v-icon>
                        <v-menu activator="parent">
                          <v-card class="py-5">
                            <v-card-title class="mb-5">
                              Kandidat im ATS Recruit
                            </v-card-title>
                            <v-card-text>
                              <ExtractCandidateUuidFromAtsRecruitLinkForm
                                @click.stop
                                @extractedUuid="localCandidate.uuid = $event"
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="2"> </v-col>
                </v-row>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <!--Personal data-->
                    <v-card v-bind="vStyle?.card" class="mb-4">
                      <v-card-text>
                        <v-label class="mb-4">Personendaten</v-label>
                        <v-row no-gutters>
                          <v-col cols="12" md="6">
                            <v-select
                              v-bind="vStyle?.input"
                              v-model="localCandidate.salutationCatalogId"
                              :items="salutations"
                              item-title="value"
                              item-value="label"
                              density="compact"
                              label="Anrede"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.title"
                              density="compact"
                              label="Titel"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.firstName"
                              density="compact"
                              label="Vorname"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.lastName"
                              density="compact"
                              label="Nachname"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="formattedBirthDate"
                              type="date"
                              density="compact"
                              label="Geburtsdatum"
                              class="mr-4"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.birthPlace"
                              label="Geburtsort"
                              density="compact"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.birthName"
                              label="Geburtsname"
                              density="compact"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-bind="vStyle?.input"
                              v-model="localCandidate.maritalStatus"
                              :items="maritalStatus"
                              item-title="value"
                              item-value="label"
                              density="compact"
                              label="Familienstand"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.nationality"
                              label="Nationalität"
                              density="compact"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.residencePermitUntil"
                              label="Aufenthaltserlaubnis bis"
                              density="compact"
                              class="mr-4"
                              type="date"
                              clearable
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!--Shift-->
                    <v-card v-bind="vStyle?.card" class="mb-4">
                      <v-card-text>
                        <v-label class="mb-4"> Schichtbereitschaft </v-label>
                        <v-select
                          v-bind="vStyle?.input"
                          v-model="localCandidate.shiftPreference"
                          :items="shiftPreferencesOption"
                          item-title="label"
                          item-value="label"
                          density="compact"
                          :label="localCandidate?.shiftIcon ?? ''"
                        ></v-select>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!--Address-->
                    <v-card v-bind="vStyle?.card" class="mb-4">
                      <v-card-text>
                        <v-label class="mb-4"> Adresse </v-label>
                        <v-row noGutters>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.addressStreet"
                              density="compact"
                              label="Straße"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.addressHouseNumber"
                              density="compact"
                              label="Hausnummer"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.addressPostalCode"
                              density="compact"
                              label="PLZ"
                              class="mr-4"
                            />
                          </v-col>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-bind="vStyle?.input"
                              v-model="localCandidate.addressCity"
                              density="compact"
                              label="Ort"
                              class="mr-4"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <!--Mobility-->
                    <v-card v-bind="vStyle?.card" class="mb-4">
                      <v-card-text>
                        <v-label class="mb-4"
                          >Mobilität
                          {{ localCandidate?.mobilityRadius }} km</v-label
                        >
                        <div class="d-flex align-center">
                          <v-select
                            v-bind="vStyle?.input"
                            v-model="localCandidate.mobility"
                            @update:model-value="
                              onMobilityChange(
                                localCandidate?.mobility as Mobility
                              )
                            "
                            :items="mobilities"
                            label="Beförderungsmittel"
                            density="compact"
                            max-width="200"
                            class="mr-4"
                          ></v-select>
                          <v-slider
                            class="mt-5"
                            v-model="localCandidate.mobilityRadius"
                            :max="100"
                            :min="0"
                            :step="0.5"
                            thumb-label="always"
                          ></v-slider>
                        </div>
                        <div style="height: 1.5rem"></div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-card-title>Kommunikationsmittel</v-card-title>
                  <v-divider class="mb-3"></v-divider>

                  <v-col
                    cols="12"
                    md="3"
                    v-for="(item, index) in localCandidate?.communicationMeans"
                    :key="item?._id"
                  >
                    <v-card v-bind="vStyle?.card" class="mb-4">
                      <v-card-title class="d-flex">
                        <v-select
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="item.type"
                          :items="communicationTypes"
                          item-title="label"
                          item-value="value"
                          label="Typ"
                          class="mt-9 mr-7"
                        />
                        <v-btn
                          variant="text"
                          density="compact"
                          icon
                          @click="removeCommunicationMethod(index)"
                        >
                          <v-icon>fa-solid fa-xmark</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="item.description"
                          label="Beschreibung"
                        />
                        <v-text-field
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="item.value"
                          label="Wert"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-card v-bind="vStyle?.card" class="mb-4" border="dashed">
                      <v-card-text>
                        <v-select
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="newCommunicationMethod.type"
                          :items="communicationTypes"
                          item-title="label"
                          item-value="value"
                          label="Typ"
                        />
                        <v-text-field
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="newCommunicationMethod.description"
                          label="Beschreibung"
                        />
                        <v-text-field
                          density="compact"
                          v-bind="vStyle?.input"
                          v-model="newCommunicationMethod.value"
                          label="Wert"
                        />
                        <v-btn
                          v-bind="vStyle?.btn"
                          color="success"
                          @click="addCommunicationMethod"
                          block
                        >
                          <v-icon>fa-solid fa-plus</v-icon>
                          Hinzufügen
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <!--Tab Skills-->
              <v-row v-else-if="index === 1">
                <v-card-title>Skills</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col
                  cols="12"
                  v-for="group in skillGroups"
                  :key="group.groupDescription"
                >
                  <v-card v-bind="vStyle?.card" class="mb-4">
                    <v-card-title class="d-flex">
                      {{ group.groupDescription }}
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        variant="text"
                        @click="removeSkillGroup(group)"
                      >
                        <v-icon>fa-solid fa-xmark</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-chip-group
                            v-model="group.selectedSkills"
                            multiple
                            column
                          >
                            <v-chip
                              v-for="(skill, index) in group.skills"
                              :key="index"
                              color="primary"
                              :model-value="true"
                              close-icon="fa-solid fa-xmark"
                              :prepend-icon="
                                getAuspraegungIcon(skill.level, skill.type)
                              "
                              closable
                              @click:close="removeSkill(group, index)"
                              @click="toggleAuspraegung(skill)"
                            >
                              {{ skill.description }}
                            </v-chip>
                            <v-text-field
                              v-bind="vStyle?.input"
                              label="Neuer Skill"
                              rounded="pill"
                              density="compact"
                              v-model="group.newSkill"
                              style="max-width: 12rem"
                              @keyup.enter="addSkill(group)"
                            ></v-text-field>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card v-bind="vStyle?.card" border="dashed">
                    <v-card-title>
                      Neue Skill-Gruppe
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="5">
                          <v-text-field
                            v-bind="vStyle?.input"
                            label="Skill-Gruppenbezeichnung"
                            v-model="newSkillGroup.groupDescription"
                            :error="!newSkillGroup?.groupDescription"
                            error-messages="Erforderlich"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            v-bind="vStyle?.input"
                            label="Erster Skill"
                            v-model="newSkillGroup.newSkill"
                            :error="!newSkillGroup?.newSkill"
                            error-messages="Erforderlich"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            v-bind="vStyle?.btn"
                            block
                            color="success"
                            @click="addSkillGroup"
                            :disabled="
                              !newSkillGroup?.groupDescription ||
                              !newSkillGroup?.newSkill
                            "
                          >
                            <v-icon>fa-solid fa-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab Lebenslauf-->
              <v-row
                v-else-if="index === 2"
                @dragover="handleCvDragOver()"
                @dragleave="handleCvDragLeave()"
              >
                <CvDropField
                  :parseWithPersonalData="false"
                  @parsedCareerSteps="aiReceivedCareerSteps"
                  :showDropField="isCvDragOver"
                  :fieldText="{
                    icon: 'fa-solid fa-file-circle-plus',
                    title: 'Lebenslauf hier ablegen',
                    subtitle: 'Dateiformat *.docx *.pdf *.png *.jpg möglich',
                    text: 'oder einen Text markieren und auf per Drag & Drop dieses Feld ziehen',
                  }"
                />
                <v-card-title>Lebenslauf</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12">
                  <v-container class="d-flex ma-0 pa-0">
                    <v-btn @click="sortCvAsc" icon size="small" variant="text">
                      <v-icon> fa-solid fa-arrow-up </v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Lebenslauf aufsteigend sortieren</v-tooltip
                      >
                    </v-btn>
                    <v-btn @click="sortCvDesc" icon size="small" variant="text">
                      <v-icon> fa-solid fa-arrow-down </v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Lebenslauf absteigend sortieren</v-tooltip
                      >
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="reloadCv" icon size="small" variant="text">
                      <v-icon> fa-solid fa-rotate-left </v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Lebenslauf neu laden</v-tooltip
                      >
                    </v-btn>
                    <v-btn @click="aiFormatCv" icon size="small" variant="text">
                      <v-icon> fa-solid fa-rocket </v-icon>
                      <v-tooltip activator="parent" location="bottom"
                        >Lebenslauf mit AI vervollständigen</v-tooltip
                      >
                    </v-btn>
                  </v-container>
                </v-col>
                <v-col cols="12" v-if="isCvDesc">
                  <FormNewCarreerStep
                    :employmentTypes="employmentTypes"
                    @addEntry="addEntry"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-for="(entry, index) in localCandidate.careerSteps"
                  :key="entry._id"
                  class="py-0 ma-0"
                >
                  <v-card variant="flat" class="pa-0">
                    <v-card-title class="d-flex">
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-row noGutters>
                          <v-col cols="12" md="6">
                            <v-text-field
                              variant="plain"
                              :value="
                                entry.from
                                  ? FormatDate.standard(entry.from)
                                  : ''
                              "
                              label="Von"
                              type="date"
                              density="compact"
                              class="my-0"
                              @change="
                              (event: any) =>
                                updateVonDate(entry, event.target.value)
                            "
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              variant="plain"
                              :value="
                                entry.until
                                  ? FormatDate.standard(entry.until)
                                  : ''
                              "
                              label="Bis"
                              type="date"
                              density="compact"
                              class="my-0"
                              @change="
                              (event: any) =>
                                updateBisDate(entry, event.target.value)
                            "
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-row noGutters>
                          <v-col cols="12" md="8">
                            <v-text-field
                              variant="plain"
                              v-model="entry.title"
                              label="Titel"
                              density="compact"
                              class="my-0 text-h6"
                              style="font-weight: 600"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <div class="d-flex">
                              <v-select
                                v-bind="vStyle?.input"
                                v-model="entry.type"
                                :items="employmentTypes"
                                item-title="description"
                                item-value="systemName"
                                label="Typ"
                                density="compact"
                                class="my-0 ml-2"
                                :max-width="250"
                              />
                              <v-btn
                                icon
                                variant="text"
                                class="ml-4"
                                density="compact"
                                @click="removeEntry(index)"
                              >
                                <v-icon>fa-solid fa-xmark</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                        <v-text-field
                          variant="plain"
                          v-model="entry.at"
                          label="Bei"
                          density="compact"
                          class="my-0"
                        />

                        <v-textarea
                          variant="plain"
                          v-model="entry.details"
                          label="Details"
                          rows="5"
                          density="compact"
                          class="my-0"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="!isCvDesc">
                  <FormNewCarreerStep
                    :employmentTypes="employmentTypes"
                    @addEntry="addEntry"
                  />
                </v-col>
              </v-row>
              <!--Tab JOB Leads-->
              <v-row v-else-if="index === 3">
                <v-card-title class="mb-0">
                  <h2>
                    <i class="fa-solid fa-users-viewfinder"></i> JOB Leads
                  </h2>
                </v-card-title>
                <v-col cols="12" md="12">
                  <v-card-text class="d-flex">
                    <v-card-title
                      >Stellenanzeigen aus Anzeigendaten</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon variant="text" @click="fetchJobAdLeads">
                      <img
                        :src="
                          $store.getters.getEnv.assetsUrl +
                          '/icons/icon-index-anzeigendaten.png'
                        "
                        class="button-icon"
                      />
                      <v-tooltip activator="parent" location="bottom"
                        >neu von Anzeigendaten laden</v-tooltip
                      >
                    </v-btn>
                    <v-btn
                      icon
                      variant="text"
                      @click="
                        $emit(DialogEditCandidateEmit.generateAiSearchterm)
                      "
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >AI Suche starten</v-tooltip
                      >
                      <v-icon>fa-solid fa-rocket</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      variant="text"
                      @click="
                        $emit(DialogEditCandidateEmit.manualAnzeigendatenSearch)
                      "
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >manuelle Suche</v-tooltip
                      >
                      <v-icon>fa-solid fa-magnifying-glass</v-icon>
                    </v-btn>
                  </v-card-text>
                  <v-divider class="mb-3"></v-divider>
                  <v-list>
                    <v-list-item
                      v-for="(jobAd, index) in localCandidate.jobAdLeads"
                      :key="jobAd.adId"
                      class="job-ad ma-0 pa-0"
                      @click="openLink(jobAd.link ?? '')"
                    >
                      <v-container
                        class="ma-0 pa-0 d-flex justify-space-between align-center"
                      >
                        <v-container class="ma-0 pa-0">
                          <v-list-item-title>
                            {{ jobAd.jobTitle }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ jobAd.date }}: {{ jobAd.location }} -
                            {{ jobAd.sourceText }}
                            <span v-html="jobAd.listPrice"></span>
                          </v-list-item-subtitle>
                        </v-container>
                        <v-btn
                          icon
                          variant="text"
                          @click.stop="removeJobAd(index)"
                        >
                          <v-icon>fa-solid fa-xmark</v-icon>
                        </v-btn>
                      </v-container>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <!--Tab Personal (ERP)-->
              <v-row v-else-if="index === 4">
                <v-alert
                  class="ma-4 pa-4"
                  v-if="
                    !localCandidate?.employeeObjectId && !linkedEmployee?._id
                  "
                  variant="tonal"
                  border="start"
                  color="secondary"
                  title="Kein ERP Personaldatensatz mit diesem Kandidaten verknüpft"
                  icon="fa-solid fa-link-slash"
                  closable
                >
                  Falls der Kandidat noch nicht in die Personal (ERP)-Spalte
                  übertragen wurde, kann dieser einfach per Drag & Drop auf die
                  Personal (ERP)-Spalte gezogen werden.
                  <br />
                  Die Verknüpfung wird dann in der Regel automatisch hergestellt
                  kann jedoch auch manuell über die Mitarbeiterkarte erfolgen.
                </v-alert>
                <v-alert
                  class="ma-4 pa-4"
                  v-if="
                    localCandidate?.employeeObjectId && !linkedEmployee?._id
                  "
                  variant="tonal"
                  border="start"
                  color="error"
                  title="Fehler beim Laden des verknüften Personaldatensatzes"
                  icon="fa-solid fa-circle-xmark"
                  closable
                >
                  Der verknüpfte ERP Personaldatensatz konnte in der Datenbank
                  nicht gefunden werden und ist eventuell gelöscht worden!
                  <v-btn
                    block
                    class="mt-15"
                    color="error"
                    variant="text"
                    @click="unlinkEmployee"
                  >
                    <v-icon class="mr-1">fa-solid fa-link-slash</v-icon>
                    Personaldaten trennen
                  </v-btn>
                </v-alert>
                <div style="width: 100%" v-if="linkedEmployee?._id">
                  <v-card-title class="d-flex">
                    <div>
                      <v-icon class="mr-1">fa-solid fa-link</v-icon>
                      verlinkt mit Personaldaten
                      <strong
                        >{{ linkedEmployee?.firstName }}
                        {{ linkedEmployee?.lastName }}</strong
                      >
                      ({{ linkedEmployee?.employeeNumber }})
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="error" variant="text" @click="unlinkEmployee">
                      <v-icon class="mr-1">fa-solid fa-link-slash</v-icon>
                      Personaldaten trennen
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    Geburtsdatum:
                    {{
                      linkedEmployee?.birthDate
                        ? FormatDate.germanStandard(linkedEmployee.birthDate)
                        : "unbekannt"
                    }}<br />
                    <div class="my-2">
                      <p>Adresse:</p>
                      <p>{{ linkedEmployee?.address?.street }}</p>
                      <p>
                        {{ linkedEmployee?.address?.postalCode }}
                        {{ linkedEmployee?.address?.city }}
                      </p>
                    </div>
                    <p v-if="linkedEmployee?.address?.phone1">
                      Telefon 1: {{ linkedEmployee?.address?.phone1 }}
                    </p>
                    <p v-if="linkedEmployee?.address?.phone2">
                      Telefon 2: {{ linkedEmployee?.address?.phone2 }}
                    </p>
                    <p v-if="linkedEmployee?.address?.email">
                      Email: {{ linkedEmployee?.address?.email }}
                    </p>
                  </v-card-text>
                </div>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
          <div style="height: 2rem"></div>
        </v-card-text>
        <div
          class="dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow"
        ></div>
        <v-card-actions class="dialog-edit-item-action-buttons">
          <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submitModal">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {
  Candidate,
  CareerStep,
  CareerStepType,
  CommunicationMeans,
  Skill,
} from "@/models/candidate.model";
import { CandidateService } from "@/services/api/candidate.service";
import { CommunicationType } from "@/enums/communication-types.enum";
import { defineComponent, PropType } from "vue";
import { EmploymentType } from "@/enums/employment-types.enum";
import { AiService } from "@/services/ai.service";
import { Mobility } from "@/enums/mobilities.enum";
import { Salutation } from "@/enums/salutation.enum";
import {
  ShiftPreferences,
  ShiftPreferencesIcon,
} from "@/enums/shift-preferences.enum";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import moment from "moment";
import ToastService from "@/services/toast.service";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { changeMobilityIcon } from "../../../helper/mobility-icon.helper";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";
import { CandidateDataHelper } from "../../../helper/candidate-data.helper";
import ExtractCandidateUuidFromAtsRecruitLinkForm from "./ats-recruit-helper/ExtractCandidateUuidFromAtsRecruitLinkForm.vue";
import { Employee } from "../../../models/employee.model";
import { EmployeeService } from "../../../services/api/employee.service";
import { FormatDate } from "../../../helper/format-date.helper";
import CvDropField from "./drag-and-drop/DropField.vue";
import FormNewCarreerStep from "./candidate/FormNewCarreerStep.vue";

enum DialogEditCandidateEmit {
  submit = "submit",
  submitCandidateObjekt = "submitCandidateObjekt",
  fetchJobAdLeads = "fetchJobAdLeads",
  generateAiSearchterm = "generateAiSearchterm",
  manualAnzeigendatenSearch = "manualAnzeigendatenSearch",
}
enum DialogEditCandidateTabs {
  General = "Allgemein",
  Skills = "Skills",
  CareerSteps = "Lebenslauf",
  JobAdLeads = "JOB Leads",
  Employee = "Personal (ERP)",
}

export default defineComponent({
  name: "EditCandidateModal",
  emits: [
    DialogEditCandidateEmit.submit,
    DialogEditCandidateEmit.submitCandidateObjekt,
    DialogEditCandidateEmit.fetchJobAdLeads,
    DialogEditCandidateEmit.generateAiSearchterm,
    DialogEditCandidateEmit.manualAnzeigendatenSearch,
  ],
  components: {
    CvDropField,
    ExtractCandidateUuidFromAtsRecruitLinkForm,
    FormNewCarreerStep,
  },
  props: {
    candidate: Object as PropType<Candidate>,
    emitOnlyCandidateOnSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown,
      activeTab: 0,
      cvFile: null as File | null,
      dragOverTimer: null as number | null,
      DialogEditCandidateEmit: DialogEditCandidateEmit,
      FormatDate,
      employmentTypes:
        (CandidateDataHelper.careerStepTypeObjects as CareerStepType[]) ??
        ([] as CareerStepType[]),
      isCvDragOver: false,
      isCvDesc: false,
      linkedEmployee: undefined as Employee | undefined,
      localCandidate: {} as Candidate,
      mobilities: [Mobility.OwnCar, Mobility.PublicTransport] as string[],
      mobilityDistance: 0,
      newSkillGroup: {
        groupDescription: "",
        newSkill: "",
      } as any,
      newCommunicationMethod: {
        type: null,
        description: "",
        value: "",
      },
      salutations: getEnumOptions(Salutation),
      shiftPreferences: [
        ShiftPreferences.TwoShift,
        ShiftPreferences.ThreeShift,
        ShiftPreferences.FourShift,
        ShiftPreferences.FiveShift,
        ShiftPreferences.Morning,
        ShiftPreferences.Afternoon,
        ShiftPreferences.DayShift,
        ShiftPreferences.PermanentNightShift,
      ],
      showModal: false,
      tabs: [
        DialogEditCandidateTabs.General,
        DialogEditCandidateTabs.Skills,
        DialogEditCandidateTabs.CareerSteps,
        DialogEditCandidateTabs.JobAdLeads,
        DialogEditCandidateTabs.Employee,
      ],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    formattedBirthDate: {
      get() {
        return this.localCandidate.birthDate
          ? moment(this.localCandidate.birthDate).format("YYYY-MM-DD")
          : "";
      },
      set(newValue: any) {
        this.localCandidate.birthDate = newValue;
      },
    },
    maritalStatus() {
      return getEnumOptions(MaritalStatus);
    },
    shiftPreferencesOption() {
      return getEnumOptions(ShiftPreferences);
    },
    communicationTypes() {
      const labels: { [key in CommunicationType]: string } = {
        [CommunicationType.Phone]: "Telefon",
        [CommunicationType.Mobile]: "Mobiltelefon",
        [CommunicationType.Fax]: "Fax",
        [CommunicationType.Email]: "E-Mail",
        [CommunicationType.Facebook]: "Facebook",
        [CommunicationType.LinkedIn]: "LinkedIn",
        [CommunicationType.Twitter]: "Twitter",
        [CommunicationType.Xing]: "Xing",
        [CommunicationType.Website]: "Webseite",
        [CommunicationType.Skype]: "Skype",
        [CommunicationType.WorkMobile]: "Diensthandy",
        [CommunicationType.WhatsApp]: "WhatsApp",
        [CommunicationType.EmergencyContact]: "Notfallkontakt",
      };

      return Object.keys(CommunicationType)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => ({
          label: labels[key as unknown as CommunicationType],
          value: Number(key),
        }));
    },
    skillGroups() {
      const groups = this.localCandidate.skills.reduce(
        (acc: any, skill: Skill) => {
          if (
            skill.groupDescription !== "Mobilitäten" &&
            skill.groupDescription !== "Schichtbereitschaft"
          ) {
            const group = acc.find(
              (g: any) => g.groupDescription === skill.groupDescription
            );
            if (group) {
              group.skills.push(skill);
            } else {
              acc.push({
                groupDescription: skill.groupDescription,
                skills: [skill],
                newSkill: "",
              });
            }
          }
          return acc;
        },
        []
      );
      return groups;
    },
  },
  watch: {
    candidate: {
      handler(newVal) {
        this.localCandidate = { ...newVal } as Candidate;
        if (
          !this.localCandidate.careerSteps ||
          this.localCandidate.careerSteps.length === 0
        )
          return;
        this.localCandidate.careerSteps.forEach((entry) => {
          entry.from = entry.from
            ? moment(entry.from).format("YYYY-MM-DD")
            : "";
          entry.until = entry.until
            ? moment(entry.until).format("YYYY-MM-DD")
            : "";
        });
      },
      immediate: true,
      deep: true,
    },
    "localCandidate.shiftPreference": {
      handler(newVal) {
        this.localCandidate.shiftIcon = this.getShiftIcon(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    addCommunicationMethod() {
      if (
        this.newCommunicationMethod.type !== null &&
        this.newCommunicationMethod.description &&
        this.newCommunicationMethod.value
      ) {
        this.localCandidate.communicationMeans.push({
          description: this.newCommunicationMethod.description,
          type: this.newCommunicationMethod.type as CommunicationType,
          value: this.newCommunicationMethod.value,
        } as CommunicationMeans);
        this.newCommunicationMethod = {
          type: null,
          description: "",
          value: "",
        };
      }
    },
    addEntry(newEntry: CareerStep) {
      if (newEntry?.title && newEntry?.at) {
        const selectedType = newEntry?.type;

        this.localCandidate.careerSteps.push({
          degree: newEntry?.degree,
          title: newEntry?.title,
          at: newEntry?.at,
          type: {
            description: selectedType?.description,
            systemName: this.getEnumKeyByEnumValue(
              EmploymentType,
              selectedType?.systemName
            ),
            sortOrder: selectedType?.sortOrder,
          },
          from: FormatDate.toISOString(newEntry?.from),
          until: FormatDate.toISOString(newEntry?.until),
          details: newEntry?.details,
        } as CareerStep);
      }
    },
    addNewSkill(group: any, groupDescription: string, skillName: string) {
      this.newSkillGroup.groupDescription = groupDescription;
      this.newSkillGroup.newSkill = skillName;
      this.addSkill(group);
    },
    addSkill(group: any) {
      if (group.newSkill) {
        const newSkill: Skill = {
          type: 2,
          level: 5,
          description: group.newSkill,
          groupDescription: group.groupDescription,
        };
        this.localCandidate.skills.push(newSkill);
        group.skills.push(newSkill);
        group.newSkill = "";
      }
    },
    addSkillGroup() {
      if (this.newSkillGroup.groupDescription && this.newSkillGroup.newSkill) {
        const newGroup = {
          groupDescription: this.newSkillGroup.groupDescription,
          skills: [
            {
              type: 2,
              level: 5,
              description: this.newSkillGroup.newSkill,
              groupDescription: this.newSkillGroup.groupDescription,
            },
          ],
          newSkill: "",
        };
        this.skillGroups.push(newGroup);
        this.localCandidate.skills.push({
          type: 2,
          level: 5,
          description: this.newSkillGroup.newSkill,
          groupDescription: this.newSkillGroup.groupDescription,
        });
        this.newSkillGroup = {
          groupDescription: "",
          newSkill: "",
        };
      }
    },
    async aiFormatCv() {
      SpinnerService.showSpinner();
      try {
        const birthdate = this.localCandidate.birthDate;
        const cvData = this.localCandidate.careerSteps;
        const aiService = new AiService();
        const newCvData = await aiService.formatCv(birthdate, cvData);
        this.aiReceivedCareerSteps(newCvData, null);
      } catch (error) {
        ToastService.showError("KI Verarbeitung hat nicht geklappt");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    aiReceivedCareerSteps(careerSteps: CareerStep[] | any, file: File | null) {
      this.localCandidate.careerSteps = careerSteps;
      if (file) {
        this.cvFile = file;
      }
    },
    clickOnTab(index: number) {
      if (index === 4) {
        if (this.localCandidate.employeeObjectId) {
          this.getLinkedEmployee();
        }
      }
    },
    closeModal() {
      this.showModal = false;
    },
    fetchJobAdLeads() {
      this.$emit(DialogEditCandidateEmit.fetchJobAdLeads);
    },
    getAuspraegungIcon(auspraegung: number | null, art: number | null): string {
      if (art === 1) {
        if (auspraegung === 1) return "fa-solid fa-user-xmark";
        if (auspraegung === 2) return "fa-solid fa-user-check";
        if (auspraegung === 3) return "fa-solid fa-user-graduate";
      } else if (art === 2) {
        if (auspraegung === 4) return "fa-solid fa-xmark";
        if (auspraegung === 5) return "fa-solid fa-check";
      }
      return "";
    },
    getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
      return keys.length > 0 ? keys[0] : "";
    },
    getShiftIcon(shiftPreference: ShiftPreferences) {
      switch (shiftPreference) {
        case ShiftPreferences.TwoShift:
          return ShiftPreferencesIcon.TwoShift;
        case ShiftPreferences.ThreeShift:
          return ShiftPreferencesIcon.ThreeShift;
        case ShiftPreferences.FourShift:
          return ShiftPreferencesIcon.FourShift;
        case ShiftPreferences.FiveShift:
          return ShiftPreferencesIcon.FiveShift;
        case ShiftPreferences.Morning:
          return ShiftPreferencesIcon.Morning;
        case ShiftPreferences.Afternoon:
          return ShiftPreferencesIcon.Afternoon;
        case ShiftPreferences.DayShift:
          return ShiftPreferencesIcon.DayShift;
        case ShiftPreferences.PermanentNightShift:
          return ShiftPreferencesIcon.PermanentNightShift;
        default:
          return ShiftPreferencesIcon.None;
      }
    },
    async getLinkedEmployee() {
      if (!this.localCandidate.employeeObjectId) return;
      const employeeService = new EmployeeService();
      try {
        SpinnerService.showSpinner();
        const linkedEmployee = await employeeService.getEmployee(
          this.localCandidate.employeeObjectId
        );
        this.linkedEmployee = linkedEmployee;
      } catch (error) {
        ToastService.showError("Fehler beim Abrufen der Personaldaten");
        return undefined;
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    handleCvDragOver() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.isCvDragOver = true;
      this.dragOverTimer = window.setTimeout(() => {
        this.isCvDragOver = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleCvDragLeave() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.dragOverTimer = window.setTimeout(() => {
        this.isCvDragOver = false;
        this.dragOverTimer = null;
      }, 500);
    },
    onMobilityChange(newMobility: Mobility) {
      this.localCandidate.mobilityIcon = changeMobilityIcon(newMobility);
    },
    openLink(link: string) {
      if (link) {
        window.open(link, "_blank", PopUpWindowSize.externalSoftware);
      }
    },
    openModal() {
      this.activeTab = 0;
      this.showModal = true;
    },
    reloadCv() {
      if (this.candidate && this.candidate?.careerSteps)
        this.localCandidate.careerSteps = this.candidate?.careerSteps;
    },
    removeCommunicationMethod(index: number) {
      this.localCandidate.communicationMeans.splice(index, 1);
    },
    removeEntry(index: number) {
      this.localCandidate.careerSteps.splice(index, 1);
    },
    removeJobAd(index: number) {
      if (this.localCandidate.jobAdLeads) {
        this.localCandidate.jobAdLeads.splice(index, 1);
      }
    },
    removeSkill(group: any, index: number) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.description !== group.skills[index].description
      );
      group.skills.splice(index, 1);
    },
    removeSkillGroup(group: any) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.groupDescription !== group.groupDescription
      );
      this.skillGroups = this.skillGroups.filter(
        (g: any) => g.groupDescription !== group.groupDescription
      );
    },
    async saveCandidate() {
      const apiService = new CandidateService();
      if (this.localCandidate) {
        await apiService.updateCandidate(this.localCandidate);
      }
    },
    sortCvAsc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : Infinity;
        this.isCvDesc = false;
        return dateA - dateB;
      });
    },
    sortCvDesc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : -Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : -Infinity;
        this.isCvDesc = true;
        return dateB - dateA;
      });
    },
    submitWithoutClose() {
      this.saveCandidate().then(() => {
        this.$emit(DialogEditCandidateEmit.submit, this.localCandidate);
      });
    },
    async submitModal() {
      if (this.emitOnlyCandidateOnSubmit) {
        this.$emit(
          DialogEditCandidateEmit.submitCandidateObjekt,
          this.localCandidate,
          this.cvFile
        );
      } else {
        this.saveCandidate().then(() => {
          this.$emit(DialogEditCandidateEmit.submit, this.localCandidate);
        });
      }
      this.closeModal();
    },
    toggleAuspraegung(skill: any) {
      if (skill.level === undefined || skill.level === null) {
        skill.level = 1;
        skill.type = 1;
      } else {
        skill.level++;
        if (skill.level > 5) {
          skill.level = 1;
        }
        if (skill.level >= 1 && skill.level <= 3) {
          skill.type = 1;
        } else if (skill.level >= 4 && skill.level <= 5) {
          skill.type = 2;
        }
      }
    },
    unlinkEmployee() {
      this.linkedEmployee = undefined;
      this.localCandidate.employeeObjectId = null;
      this.submitModal();
    },
    updateBisDate(entry: any, date: string) {
      entry.until = date ? FormatDate.toISOString(date) : "";
    },
    updateVonDate(entry: any, date: string) {
      entry.from = date ? FormatDate.toISOString(date) : "";
    },
  },
});
</script>

<style scoped>
.job-ad {
  cursor: pointer;
}

.job-ad:hover {
  background-color: var(--v-list-item-hover-background-color);
}

.button-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
