// store/store.ts
import {
  DateSelectable,
  EventType,
  Role,
  LinkingStatus,
} from "@/enums/dependency.enum";
import { JobposterState } from "@/enums/jobposterstate.enum";
import {
  CustomerStates,
  getCustomerStates,
} from "@/enums/customer-states.enum";
import {
  createStore,
  useStore as baseUseStore,
  Store as VuexStore,
} from "vuex";
import { CustomerList } from "@/models/customer.model";
import { ZvooveDashboardData } from "@/models/external/zvoove-one.model";
import { EmployeeList } from "@/models/employee.model";
import { CustomerService } from "@/services/api/customer.service";
import { MandantService } from "@/services/api/mandant.service";
import { EmployeeService } from "@/services/api/employee.service";
import {
  EmployeeStates,
  getEmployeeStates,
} from "@/enums/employee-states.enum";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { getDemandStates } from "@/enums/demand-states.enum";
import { UserService } from "@/services/api/user.service";
import { Mandant } from "@/models/mandant.model";
import { InjectionKey } from "vue";
import {
  ExpiredReminder,
  Linking,
  LinkingCore,
  LinkingDescription,
} from "@/models/linking.model";
import { CandidateService } from "@/services/api/candidate.service";
import { LinkingService } from "@/services/api/linking.service";
import {
  ProfileTemplate,
  SalaryAdjustment,
  WorkTimePrompt,
} from "@/models/company-config.model";
import moment from "../plugins/moment-config";
import { AppLayout } from "../enums/app-layout.enum";
import { MutationType } from "../enums/vuex-types.enum";
import { RecurionChatbotType } from "../enums/recurion-chatbot-types.enum";
import { Timeline } from "../models/timeline.model";
import { DashboardText, User } from "../models/user.model";
import { PerfEntry, PerfInsight } from "@/models/perf.model";
import { LastPhoneCallFrom } from "../models/phone-call.model";
import { RecurionChatMenu } from "../models/slide-in-menus.model";
import { vStyle } from "../models/app-layout.model";
import { CandidateAddressForCommuteWebSearch } from "../models/candidate.model";

const candidateRejectedByCustomer = "Absage Firma";
const candidateNotShownUp = "Vorstellungstermin geplatzt";
const interviewCompleted = "Vorstellungstermin durchgeführt";
const applicationRejection = "Absage von uns";
let candidateSuggestionDescription = "Profil verschickt";

const baseDialogMaxQueueSize = 10;
const baseDialogQueueTimeout = 1 * 15 * 1000; // 15 sec
export interface State {
  config: Record<string, any>;
}
export const key: InjectionKey<VuexStore<State>> = Symbol();

/**
 * Recursively merges the properties of the `source` object into the `target` object.
 *
 * - If a property in `source` is an object (but not an array), it merges it deeply into the corresponding `target` property.
 * - If a property is an array:
 *   - It ensures the `target` property is also an array.
 *   - It loops through each item in the source array:
 *     - If the item is an object and contains a `reminderFor` key:
 *       - It tries to find a matching item in the target array by `reminderFor`.
 *       - If found, it merges the source object into the existing target object at that index.
 *       - If not found, it adds the source item to the target array.
 *     - If the item is a primitive (not an object), it simply adds it to the array.
 * - If the property is neither an object nor array, it overrides the value in the target.
 *
 * This function returns a Promise that resolves after the merge is complete.
 *
 * Note: Although implemented as an async-looking function using Promise, it's synchronous in nature.
 *
 * @param target - The target object to be merged into.
 * @param source - The source object containing new data to merge into the target.
 * @returns A Promise that resolves when the merge is finished.
 */

function recursiveMerge(target: any, source: any): Promise<void> {
  return new Promise((resolve) => {
    for (const key of Object.keys(source)) {
      if (
        source[key] &&
        typeof source[key] === "object" &&
        !Array.isArray(source[key])
      ) {
        if (!target[key]) {
          target[key] = {};
        }
        recursiveMerge(target[key], source[key]);
      } else if (Array.isArray(source[key])) {
        if (!Array.isArray(target[key])) {
          target[key] = [];
        }

        const targetArray = target[key];
        const sourceArray = source[key];

        sourceArray.forEach((sourceItem: any) => {
          if (typeof sourceItem === "object") {
            const index = targetArray.findIndex((targetItem: any) => {
              if (sourceItem && sourceItem.reminderFor) {
                return targetItem.reminderFor === sourceItem.reminderFor;
              }
            });

            if (index > -1) {
              targetArray[index] = { ...targetArray[index], ...sourceItem };
            } else {
              targetArray.push(sourceItem);
            }
          } else {
            targetArray.push(sourceItem);
          }
        });
      } else {
        target[key] = source[key];
      }
    }
    resolve();
  });
}

export const initialState = {
  activeRequestsForLimiter: 0,
  baseDialogQueue: [] as { id: string; props: any; timestamp: number }[],
  vStyle: {
    card: {
      variant: "outlined",
      rounded: "lg",
      border: "md",
    },
    boardItem: {
      variant: undefined,
      rounded: "lg",
      border: "md",
      elevation: "0",
    },
    input: {
      variant: "outlined",
      rounded: "lg",
      baseColor: "primary",
      color: "primary-darken-1",
    },
    btn: {
      variant: undefined,
      rounded: "lg",
      border: "primary-darken-1 md",
    },
    itemHeight: {
      collapsed: "3.8rem",
      expanded: "30vh",
      fullyExpanded: "70vh",
    },
  } as vStyle,
  storeHasToInit: true, // when is initialized the loadStoreInitialData() actions sets it: false
  zorstAlive: "",
  candidateAddressForCommuteWebSearch:
    {} as CandidateAddressForCommuteWebSearch,
  company: {
    name: "",
    domain: "",
    loggedInUser: {
      role: 15,
      company: "",
      config: {
        aiData: {
          mailStyle: "",
          whatsAppStyle: "",
          mailSignature: "",
        },
        mandants: [] as any[],
        loggedInMandants: [] as any[],
        dispatcherBoard: {
          columnDemand: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnCustomer: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnCandidate: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnEmployee: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
        },
        hasAiCoaching: false,
      },
      email: "",
      forename: "",
      insightsBundle: {
        perfDaily: [] as PerfInsight[],
        dashboardText: {
          date: "",
          dashboardAnalysis: {
            salutation: "",
            motivation: "",
            analysisText: "",
            tips: "",
          },
        },
      },
      lastname: "",
      mobilePhone: "",
      salutation: "",
      tel: "",
      zvooveNextLevelApi: "",
      zvooveOneUuid: "",
    },
    mandants: [
      {
        uuid: "INIT-Data-UUID",
        name: "Mandanten nicht geladen",
        zvoovename: "Mandanten nicht geladen",
        branchNumber: "11",
        branchInitials: "Error",
        contact: "Error",
        whatsApp: {
          phoneNumber: "",
        },
        EmailEingangskontoId: "Error",
        plzs: [],
      },
    ],
    // From company config:
    mailServer: null,
    salaryAdjustments: [] as SalaryAdjustment[],
    softwareIntegration: {
      atsAutoDocu: false,
      atsDeterminesStatus: false,
      dashboardAiKpiAnalysis: false,
      erpAutoDocu: false,
      gameBar: false,
      indexAnzeigendaten: false,
      indexJobAdAgeLimitDays: 90,
      indexJobAdRefreshInterval: 14,
      indexJobLeadsAgeLimitDays: 30,
      indexJobLeadsMaxAds: 100,
      jobListAutoBfaSupportedAd: false,
      payFlow: false,
      pdHub: false,
      lastAutoUpdateFromErp: "",
      whatsApp: false,
      workTime: false,
      wordPressPlugin: false,
      zorst: false,
      zvooveOne: false,
      zvooveOneLink: "",
      zvooveRecruit: false,
      zvooveRecruitLink: "",
    },
    apiKeys: {
      baseURLIndexAds: "https://proxy.dispositioner.de/indexV4.php", //Proxy to: https://db.advertsdata.com/rest_api/post_anz_filter_search_v4.cfm?user=ad_api
      baseURLIndexAuth: "https://proxy.dispositioner.de/indexAuth.php",
      baseUrlIndexCustomers: "https://proxy.dispositioner.de/indexV3.php", //Proxy to: https://db.advertsdata.com/rest_api/post_company_filter_search_v3.cfm?user=ad_api
      baseURLZvooveOne: "",
      indexAnzeigendaten: [
        {
          creditalsForUsersOnly: ["all"],
          creditalsForMandantsOnly: ["all"],
          EXPIRE: "Store noch loaded",
          TOKEN: "",
          LOGIN: "",
        },
      ],
      wpPlugin: "",
      wpPluginBaseUrl: "",
      xCoreClientIdZvooveOne: "",
      zvooveNextLevel: "",
      zvooveOpenBewerber: "",
      zvooveOpenStelle: "",
    },
    statusOptionsRecruit: [
      {
        text: "Neu",
        value: "0 Neu",
        slider: 0,
        status: [
          LinkingStatus.inboxApplication,
          LinkingStatus.interviewCanceled,
          LinkingStatus.interviewNotShownUp,
        ],
      },
      {
        text: "Terminiert",
        value: "1 Terminiert",
        slider: 1,
        status: [LinkingStatus.interview],
      },
      {
        text: "Disposition",
        value: "2 Disposition",
        slider: 2,
        status: [LinkingStatus.interviewCompleted],
      },
      {
        text: "Vermittlung",
        value: "2 Disposition Vermittlung",
        slider: 3,
        status: [LinkingStatus.personnelPlacement],
      },
      {
        text: "Vertragsangebot",
        value: "3 Vertragsangebot",
        status: [LinkingStatus.contractDate],
      },
      {
        text: "Pool",
        value: "4 Pool",
        slider: 4,
        status: [LinkingStatus.pool],
      },
      {
        text: "Eingestellt",
        value: "4 Eingestellt",
        status: [LinkingStatus.hired],
      },
      {
        text: "Absage",
        value: "4 Absage",
        status: [
          LinkingStatus.rejectedCandidate,
          LinkingStatus.rejected,
          LinkingStatus.rejectedUnattractive,
        ],
      },
      {
        text: "Inaktiv",
        value: "0 Inaktiv",
        status: [LinkingStatus.inactive],
      },
    ],
    candidateStatusColor: {
      noneProfile: "#E63946", //company admin can set
      openProfile: "#DBC056",
      interviewExternal: "#E5EA00",
      trailWorkExternal: "#C0CA53",
      jobofferExternal: "#5BAF45",
      jobofferAcceptedExternal: "#33CC66",
      pool: "#13acdc",
      rejected: "#ff3131",
      interview: "#008b8b",
      interviewCanceled: "#1c365e",
      interviewNotShownUp: "#eb8c2f",
      interviewCompleted: "#a6a6a6",
    },
    //TODO: make it configurable in companyconfig:
    employeeStatusColor: {
      noAssignment: "#BB0000",
      assignmentExceeded: "#E63946",
      assignmentNearExpiring: "#FF6D4D",
      assignmentExpiring: "#FFDE59",
      assignmentAttention: "#C6DF50",
      assignmentActive: "#08BA6A",
      applicant: "#FFDE59",
      designatedEmployee: "#13ACDC",
      rejectedApplicant: "#AA0000",
      retiredEmployee: "#A6A6A6",
    },
    //TODO: make it configurable in companyconfig:
    employeeAssignmentExpiration: {
      nearExpiringBlink: 4,
      nearExpiring: 7,
      expiringBlink: 9,
      expiring: 14,
      attentionBlink: 19,
      attention: 28,
    },
    timelineEntryTypes: {
      phoneCallCandidate: {
        description: "Telefonat Kandidat", //company admin can set
        artZvoove: "TM", //company admin can set
      },
      phoneCallCustomer: {
        description: "Telefonat Firma",
        artZvoove: "TM",
      },
      phoneCallEmployee: {
        description: "Telefonat Mitarbeiter",
        artZvoove: "TM",
      },
      emailCandidate: {
        description: "E-Mail Kandidat",
        artZvoove: "MA",
      },
      eMailCustomer: {
        description: "E-Mail Firma",
        artZvoove: "MA",
      },
      eMailEmployee: {
        description: "E-Mail Mitarbeiter",
        artZvoove: "MA",
      },
      whatsAppCandidate: {
        description: "WhatsApp Kandidat",
        artZvoove: "WA",
      },
      whatsAppEmployee: {
        description: "WhatsApp Mitarbeiter",
        artZvoove: "WA",
      },
      note: {
        description: "Notiz",
        artZvoove: "NA",
      },
      addition: {
        description: "Nachtrag",
        artZvoove: "NA",
      },
      meetingOutside: {
        description: "Außendienstbesuch",
        artZvoove: "AD",
      },
      meetingInhouse: {
        description: "Gespräch i. d. Geschäftsstelle",
        artZvoove: "GM",
      },
      candidateSuggestion: {
        description: candidateSuggestionDescription,
        artZvoove: "KV",
      },
    },
    reminderSettings: [
      {
        reminderFor: LinkingStatus.followUp,
        hoursAfter: 0,
        daysAfter: 0,
        showPrompt: false,
        needToContact: true,
        buttonSuccess: "Anrufen",
        message: `Wiedervorlage`,
      },
      {
        reminderFor: LinkingStatus.followUpPrio,
        hoursAfter: 0,
        daysAfter: 0,
        showPrompt: true,
        needToContact: true,
        buttonSuccess: "Anrufen",
        message: `❗Wiedervorlage`,
      },
      {
        reminderFor: LinkingStatus.openProfile,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: `Profil ist noch offen`,
      },
      {
        reminderFor: LinkingStatus.interviewExternal,
        hoursAfter: 2,
        daysAfter: 0,
        showPrompt: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "nicht erschienen",
        needToContact: true,
        message: "Wie lief das Vorstellungespräch beim Kunden?",
      },
      {
        reminderFor: LinkingStatus.trailWorkExternal,
        hoursAfter: 5,
        daysAfter: 0,
        showPrompt: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "nicht erschienen",
        needToContact: true,
        message: "Wie lief das Probearbeiten beim Kunden?",
      },
      {
        reminderFor: LinkingStatus.interview,
        hoursAfter: 1,
        daysAfter: 0,
        showPrompt: true,
        buttonSuccess: "Ja",
        buttonFailure: "Nein",
        needToContact: true,
        message: "Ist der Kandidat erschienen?",
      },
      {
        reminderFor: LinkingStatus.interviewCanceled,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: "VT Abgesagt: Alternativtermin ausmachen!",
      },
      {
        reminderFor: LinkingStatus.interviewNotShownUp,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: "VT geplatzt: Alternativtermin ausmachen!",
      },
    ],
    aiData: {
      template: {
        jobAd: {
          Bezeichnung: "",
          StellenzielHeader: "",
          Stellenziel: "",
          AufgabenHeader: "",
          Aufgaben: "",
          PerspektivenHeader: "",
          Perspektiven: "",
          UnternehmensbedeutungHeader: "",
          Unternehmensbedeutung: "",
          FachlicheAnforderungenHeader: "",
          FachlicheAnforderungen: "",
          PersoenlicheAnforderungenHeader: "",
          PersoenlicheAnforderungen: "",
          ArbeitgeberleistungHeader: "",
          Arbeitgeberleistung: "",
        },
        mail: [
          {
            candidateStatus: LinkingStatus.interview,
            subject: "Einladung zum Vorstellungstermin",
            text1: "wir laden Sie zum Vorstellungstermin ein.",
            text2: "Ich freue mich sehr Sie kennenzulernen.",
          },
          {
            candidateStatus: LinkingStatus.interviewCanceled,
            subject: "Neuer Termin?",
            text1: "sie hatten Ihren Vorstellungstermin bei uns abgesagt.",
            text2: "Können wir einen neuen Termin vereinbaren?",
          },
          {
            candidateStatus: LinkingStatus.interviewNotShownUp,
            subject: "Schade, Termin verpasst ...",
            text1:
              "sie hatten Ihren Vorstellungstermin verpasst und vergessen uns abzusagen.",
            text2: "Können wir einen neuen Termin vereinbaren?",
          },
          {
            candidateStatus: LinkingStatus.interviewCompleted,
            subject: "Vielen Dank für Ihre Zeit",
            text1: "danke für die Einblicke beim Ihrem Vorstellungstermin.",
            text2: "Wir suchen Ihnen eine Stelle nach Ihren Wünschen.",
          },
          {
            candidateStatus: LinkingStatus.personnelPlacement,
            subject: "Wir sind beindruckt von Ihren Qualifikationen",
            text1: "wir sehen Sie als Vermittlungskandidat.",
            text2: "Und suchen Ihnen einen Arbeitgeber der wirklich passt.",
          },
          {
            candidateStatus: LinkingStatus.contractDate,
            subject: "Vertragstermin",
            text1: "wir möchten Sie zum Vertragstermin einladen:",
            text2: "Bringen Sie mit:<br>SteuerId<br>Sozialversicherungsnummer",
          },
          {
            candidateStatus: LinkingStatus.hired,
            subject: "Herzlich Willkommen",
            text1: "wir möchten Sie als Teil unseres Teams begrüßen.",
            text2: "Und heißen Sie herzlich willkommen bei uns.",
          },
          {
            candidateStatus: LinkingStatus.pool,
            subject: "Aufnahme in den Bewerberpool",
            text1: "leider haben wir derzeit kein konkretes Arbeitsangebot.",
            text2: "Wir nehmen Sie aber gerne in den Bewerberpool auf.",
          },
          {
            candidateStatus: LinkingStatus.rejected,
            subject: "Absage zu Ihrer Bewerbung",
            text1: "leider müssen wir Ihre Bewerbung absagen.",
            text2: "Bewerben Sie sich jederzeit auf andere Stellen bei uns.",
          },
          {
            candidateStatus: LinkingStatus.rejectedCandidate,
            subject: "Wir wünschen Ihnen alle Gute",
            text1: "vielen Dank für Ihre rechtzeitige Absage bei uns",
            text2: "und wir wünschen Ihnen alles Gute für Ihren Neustart.",
          },
          {
            candidateStatus: LinkingStatus.rejectedUnattractive,
            subject: "Absage zu Ihrer Bewerbung",
            text1: "leider können wir kein Visum für Sie beantragen!",
            text2: "Das ist für Zeitarbeit in Deutschland nicht möglich.",
          },
        ],
      },
      prompt: {
        company: {
          cvParser: `
Halte dich Exakt an die Daten im Lebenslauf des Kandidaten und erfinde keine Daten die nicht im Lebenslauf stehen!
Sortiere die Stationen so, dass die neueste Beschäftigung oben steht!
          `,
          mailCandidate:
            "Du schreibst eine Email an einen Kandidaten, den wir gerne an einen Kunden vermitteln würden",
          mailCustomer:
            "Du schreibst eine Email an ein Unternehmen and das wir gerne Kandidaten als Zeitarbeiter verleihen würden oder gegen Honarar vermitteln würden",
          mailEmployee:
            "Du schreibst eine Email an unseren Mitarbeiter, der als Zeitarbeiter bei einem Kunden eingesetzt ist!",
          profileCandidate: `
Anweisung: Fasse beim Werdegang bitte die Beschäftigungen, die für die Stelle nicht relevant
sind oder lange in der Vergangenheit liegen, so zusammen, dass alles auf eine DinA4-Seite passt.
Dennoch soll die Information über die Art der Beschäftigung nicht verloren gehen. Du schreibst
unter dem Werdegang noch eine kurze Zusammenfassung der älteren oder nicht relevanten Beschäftigungen.

Die neueste Beschäftigung schreibst Du immer ausformuliert in das Profil und du sortierst die
Beschäftigungen so, dass die neueste Beschäftigung oben steht!`,

          profileCandidateMarkdownTemplate: `
### KERNKOMPETENZEN
**Skill1**: kurze Beschreibung<br>
**Skill2**: kurze Beschreibung<br>
**Skill3**: kurze Beschreibung<br>

###  SOFT SKILLS
**Softskill1**, **Softskill2**, **Softskill3**

### AUSBILDUNG
- 01.09.2000 bis 30.06.2009 **Beispielschulabschluss**<br>bei Bespielschule in 54321 Musterstadt
  - Detail

### WERDEGANG
- 01.07.2011 bis 31.12.2019 **Beispielberuf**<br>bei Betrieb in 54321 Musterstadt
  - Detail 1
  - Detail 2
  - Detail 3
- 01.07.2009 bis 30.06.2011 **Beispielberuf**<br>bei Betrieb in 54321 Musterstadt
  - Detail 1
  - Detail 2

---

### Zusammenfassung älterer oder irrelevanter Stationen
Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.`,
          profileCandidateLanguageRules: `
`,
          profileCandidateDescriptionRules: `
Anweisung: In der Beschreibung des Kandidaten argumentierst Du immer warum der Kandidat
auf die Stelle passt bzw. warum er für das Unternehmen interessant ist. Was das Unternehmen
sucht ist in den Tags vom Unternehmen zu finden.`,
          profileCandidateHeadlineRules: `
Anweisung: Bei der Überschrift des Kandidatenprofils schreibst du immer die Berufsbezeichnung
des Kandidaten, die zur Stelle passt oder zu den Unternehmenstags passt.`,

          tagsCandidate:
            "Schreib mir die 4 bis maximal 7 konkreten Berufsbezeichnungen oder Jobs für die der Kandidat in Frage käme. Wichtig: Benutze nur die deutschen Berufsbezeichnungen und gibt als möglichst kurzen Stichpunkt aus!",
          tagsCompany:
            "Schreib mir die alle konkreten Berufsbezeichnungen oder Jobs die bei dieser Firma arbeiten können. Wichtig: Benutze nur die deutschen Berufsbezeichnungen auch wenn die in den Anzeigen englisch sind und gibt diese als möglichst kurzen Stichpunkt aus!",
          tagsMatchingThreshold: 0.7,
          weAre:
            "Wir sind ein Personaldienstleister und suchen passende Mitarbeiter für Kundenunternehmen, die wir dann entweder im Rahmen der Direktvermittlung an den Kunden vermitteln oder in dem wir die Bewerber selbst einstellen und im Rahmen der Arbeitnehmerüberlassung im Kundenbetrieb einsetzen.",
          weAreShort:
            "Wir sind ein Personaldienstleister und suchen passende Mitarbeiter für Kunden, die wir dann direkt vermitteln oder als Zeitarbeiter verleihen!",
          whatsAppCandidate:
            "Du schreibst eine WhatsApp an einen Kandidaten, für den wir gerne einen Job finden würden.",
          whatsAppCustomer:
            "Du schreibst eine WhatsApp an einen Ansprechpartner von einem Kundenunternehmen",
          whatsAppEmployee:
            "Du schreibst eine WhatsApp-Nachricht an unseren Mitarbeiter, der als Zeitarbeiter bei einem Kunden eingesetzt ist!",
        },
      },
      languages: [
        "Englisch",
        "Türkisch",
        "Polnisch",
        "Russisch",
        "Arabisch",
        "Rumänisch",
        "Bulgarisch",
        "Spanisch",
        "Französisch",
        "Italienisch",
        "Portugiesisch",
        "Chinesisch (Mandarin)",
        "Hindi",
        "Persisch (Farsi)",
        "Serbisch",
        "Bosnisch",
        "Kroatisch",
        "Albanisch",
      ],
      workTimePrompts: [] as WorkTimePrompt[],
    },
    profileTemplate: {
      candidatesButtonLink: "",
      candidatesButtonText: "weitere Kandidaten",
      fontXS: "0.6rem",
      fontS: "0.8rem",
      fontM: "0.9rem",
      logo: "",
      logoDark: "",
      tblLeft: "1%",
      tblMid: "24%",
      tblRight: "75%;",
      titles: {
        background: "HINTERGRUND",
        shiftReadiness: "SCHICHTBEREITSCHAFT",
        licences: "FÜHRERSCHEINE",
        languages: "SPRACHEN",
        contact: "Ansprechpartner",
        skills: "KERNKOMPETENZEN",
        softSkills: "SOFT SKILLS",
        education: "AUSBILDUNG",
        cv: "WERDEGANG",
      },
      background: "#e3e6de",
      fontLight: "#fff",
      colorPrimary: "#244578",
      colorSecondary: "#eb8c2f",
    },
    whatsApp: {
      phoneId: "",
      phoneNumber: "",
      wabId: "",
    },
  },

  customerList: [] as CustomerList[],
  employeeList: [] as EmployeeList[],
  dashboardData: {} as ZvooveDashboardData,

  customerStates: getCustomerStates(CustomerStates),
  employeeStates: getEmployeeStates(EmployeeStates),
  demandStates: getDemandStates(),
  demandExpiryDefault: 30,

  linkings: [] as Linking[],

  globalReminders: [] as { sourceId: string; reminders: any[] }[],

  lastUpdateTimeline: "",

  lastPhoneCallFrom: {
    id: "init_id",
    role: "" as Role,
    outgoing: false,
  },

  reminderDSGVO: {
    eMail: {
      lastReminder: "",
      reminderText:
        "Anrede und Signatur wurden anonymisiert! Der restliche Text wird mit ChatGPT verarbeitet. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
    whatsApp: {
      lastReminder: "",
      reminderText:
        "Die Signatur wurde anonymisiert! Die Anrede und der Text wird mit ChatGPT verarbeitet. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
    translation: {
      lastReminder: "",
      reminderText:
        "Die Signatur wurde anonymisiert! Die Anrede und der Text wird mit ChatGPT übersetzt. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
  },

  headerLogo: true,

  headerSpinner: {
    size: 0,
    gif: "",
  },

  // For slide-in-menus/Jobposter.vue
  jobPosterState: {
    status: JobposterState.offline,
    jobAdPublishHomepage: false,
    jobAdPublishOnBfA: false,
    postingIsPaused: false,
    postingIsCancelled: false,
    totalJobAds: 0,
    postedJobAds: 0,
  },

  // For slide-in-menus/Gamebar.vue
  perfState: {
    isOffline: true,
    recentTimelines: [] as Timeline[],
    recentScore: [] as number[],
    recentLinkingStatusOrTimelineEntryType: [] as PerfEntry[],
    recentDescription: [] as string[],
  },

  // For slide-in-menus/Recurion.vue
  recurionState: {
    currentRemoteRecurionMessage: undefined,
    chatbotType: RecurionChatbotType.handbook,
    initPrompt: "",
    isActive: false,
    isClosed: false,
  } as RecurionChatMenu,

  dragOriginComponent: null,

  isDraggingItem: {
    demand: {},
    customer: {},
    candidate: {},
    employee: {},
  },

  dragOverItem: null as Role | null,

  isActiveCandidateMatching: false,
  isActiveCustomerOrDemandMatching: false,

  interComponentMessage: {
    message: "",
    payload: {},
  },

  postcodesCandidates: [] as string[],
  postcodesCustomers: [] as string[],

  reminderActions: {
    interviewFailure: candidateNotShownUp,
    interviewSuccess: interviewCompleted,
    applicationRejection: applicationRejection,
    interviewExternalFailure: candidateRejectedByCustomer,
    trailWorkExternalFailure: candidateRejectedByCustomer,
  },
  candidateToMandantEvents: [
    {
      candidateStatus: LinkingStatus.interview,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin in Geschäftsstelle", //MAYBE: company admin can set
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interview,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin Videocall",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewCanceled,
      eventName: "Vorstellungstermin abgesagt",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewNotShownUp,
      eventName: candidateNotShownUp,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewCompleted,
      eventName: interviewCompleted,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.personnelPlacement,
      eventName: "Vermittlungskandidat",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.pool,
      eventName: "Bewerberpool",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.contractDate,
      eventName: "Vertragstermin",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.hired,
      eventName: "Einstellung",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejectedCandidate,
      eventName: "Absage Bewerber",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejectedUnattractive,
      eventName: "Absage Bewerber Drittstatt",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejected,
      eventName: applicationRejection,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
  ],

  candidateToCustomerEvents: [
    {
      eventName: "Profil generieren und verschicken", //MAYBE: company admin can set
      candidateStatus: LinkingStatus.generateProfile,
      showOn: [Role.candidate, Role.customer],
      eventType: EventType.action,
    },
    {
      candidateStatus: LinkingStatus.noneProfile,
      eventName: "Absage Kandidat",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.noneProfile,
      eventName: candidateRejectedByCustomer,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.openProfile,
      eventName: candidateSuggestionDescription,
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.openProfile,
      eventName: "Profil weiter offen, Snooze",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.interviewSuggestionExternal,
      dateSelectable: DateSelectable.multiple,
      eventName: "Vorschlag Vorstellungstermin",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.interviewExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.trailWorkSuggestionExternal,
      dateSelectable: DateSelectable.multiple,
      eventName: "Vorschlag Probearbeiten",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.trailWorkExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Probearbeiten",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.jobofferExternal,
      eventName: "Zusage",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.jobofferAcceptedExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Startet",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
  ],
  demandToCustomerEvents: [
    {
      candidateStatus: LinkingStatus.demand,
      eventName: "Anfrage",
      eventType: EventType.state,
      showOn: [Role.demand],
    },
  ],
  employeeToCustomerEvents: [
    {
      candidateStatus: LinkingStatus.tempPlacement,
      eventName: "Einsatz",
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer],
    },
  ],
  followUpEvents: [
    {
      candidateStatus: LinkingStatus.followUpPrio,
      eventName: "❗Wiedervorlage",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer, Role.candidate, Role.demand],
    },
    {
      candidateStatus: LinkingStatus.followUp,
      eventName: "Wiedervorlage",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer, Role.candidate, Role.demand],
    },
  ],
  userToMandantEvents: [
    {
      candidateStatus: LinkingStatus.jobAdExtractedFromCustomer,
      eventName: "Stelle extrahiert von Unternehmen",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.jobAdExtractedFromDemand,
      eventName: "Stelle extrahiert von Anfrage",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.jobAdPublishedFromJobsList,
      eventName: "Stelle veröffentlicht: Jobs Liste",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.jobAdPublishedFromJobsMatrix,
      eventName: "Stelle veröffentlicht: Jobs Matrix",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.landingPageCreated,
      eventName: "Landingpage erstellt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.demandCreated,
      eventName: "Anfrage erstellt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.checklistCreated,
      eventName: "Checklisteneintrag erstellt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.checklistDone,
      eventName: "Checklisteneintrag erledigt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.demandLinkedToCustomer,
      eventName: "Anfrage mit Firma verlinkt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.linkingRemoved,
      eventName: "Kartenverlinkung entfernt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.linkingSwitchedBack,
      eventName: "Verlinkung um ein Event zurückgesetzt",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
    {
      candidateStatus: LinkingStatus.spinnerGamePlayed,
      eventName: "Affe gefüttert",
      eventType: EventType.state,
      showOn: [Role.user, Role.mandant],
    },
  ],
  zorstDebugging: false,
};
// Plugin to save state to sessionStorage
export const vuexSessionStorage = (store: any) => {
  store.subscribe((mutation: any, state: any) => {
    sessionStorage.setItem("vuexState", JSON.stringify(state));
  });
};

// Function to load state from sessionStorage
export const loadState = () => {
  const state = sessionStorage.getItem("vuexState") as any;
  return state ? JSON.parse(state) : initialState;
};

// Function to clear state from sessionStorage
export const clearState = () => {
  sessionStorage.removeItem("vuexState");
};

export default createStore({
  state: loadState(),
  mutations: {
    ADD_TO_DIALOG_QUEUE(
      state,
      dialog: { id: string; props: any; timestamp: number }
    ) {
      // Remove old dialogs
      const now = Date.now();
      state.baseDialogQueue = state.baseDialogQueue.filter(
        (d: { timestamp: number }) => now - d.timestamp < baseDialogQueueTimeout
      );

      // If queue is full, remove oldest dialog
      if (state.baseDialogQueue.length >= baseDialogMaxQueueSize) {
        state.baseDialogQueue.shift();
      }

      state.baseDialogQueue.push(dialog);
    },
    ADD_LINKINGS(state, allLinkings) {
      state.linkings = allLinkings;
    },
    ADD_POSTCODE_CANDIDATES(state, postcode: string) {
      if (!state.postcodesCandidates.includes(postcode)) {
        state.postcodesCandidates.push(postcode);
      }
    },
    ADD_PERF_RECENT_ENTRIES(
      state,
      payload: {
        timeline: Timeline;
        score: number;
        description: string;
        entryType: PerfEntry;
      }
    ) {
      state.perfState.recentTimelines.push(payload.timeline);
      state.perfState.recentScore.push(payload.score);
      state.perfState.recentLinkingStatusOrTimelineEntryType.push(
        payload.entryType
      );
      state.perfState.recentDescription.push(payload.description);
    },
    ADD_TO_CUSTOMER_LIST(
      state: { customerList: CustomerList[] },
      customerReduced: CustomerList
    ) {
      if (Array.isArray(state.customerList)) {
        state.customerList.push(customerReduced);
      } else {
        console.error("customerList is not an array", state.customerList);
      }
    },
    ADD_USER_PERF_INSIGHT(state, perfInsight: PerfInsight) {
      state.company.loggedInUser.insightsBundle.perfDaily.push(perfInsight);
    },
    CLEAR_DRAGGING_ITEM(state) {
      state.isDraggingItem = {
        demand: {},
        customer: {},
        candidate: {},
        employee: {},
      };
    },
    CLEAR_HEADER_SPINNER_ELEMENT(state) {
      state.headerSpinner.size = 0;
      state.headerSpinner.gif = "";
    },
    CLEAR_INTER_COMPONENT_MESSAGE: (state) => {
      state.interComponentMessage.message = "";
      state.interComponentMessage.payload = {};
    },
    CLEAR_POSTCODES_CANDIDATES(state) {
      state.postcodesCandidates = [];
    },
    DELETE_CANDIDATE_ADDRESS_FOR_COMMUTE_WEB_SEARCH(state) {
      state.candidateAddressForCommuteWebSearch =
        {} as CandidateAddressForCommuteWebSearch;
    },
    DELETE_LINKING(state, linkingId) {
      state.linkings = state.linkings.filter(
        (link: { _id: any }) => link._id !== linkingId
      );
    },
    DELETE_WORKTIME_PROMPT(state, workTimePromptToDelete: WorkTimePrompt) {
      if (workTimePromptToDelete._id) {
        state.company.aiData.workTimePrompts =
          state.company.aiData.workTimePrompts.filter(
            (workTimePrompt: WorkTimePrompt) =>
              workTimePrompt._id !== workTimePromptToDelete._id
          );
      }
    },
    EDIT_WORKTIME_PROMPT(state, updatedWorkTimePrompt: WorkTimePrompt) {
      if (updatedWorkTimePrompt._id) {
        const index = state.company.aiData.workTimePrompts.findIndex(
          (workTimePrompt: WorkTimePrompt) =>
            workTimePrompt._id === updatedWorkTimePrompt._id
        );

        if (index !== -1) {
          state.company.aiData.workTimePrompts.splice(
            index,
            1,
            updatedWorkTimePrompt
          );
        }
      }
    },
    POSTED_JOB_ADS(state, count: number) {
      state.jobPosterState.postedJobAds = count;
    },
    REMOVE_FROM_DIALOG_QUEUE(state, dialogId: string) {
      state.baseDialogQueue = state.baseDialogQueue.filter(
        (dialog: { id: string }) => dialog.id !== dialogId
      );
    },
    RESET_JOBPOSTER(state) {
      state.jobPosterState = {
        status: JobposterState.offline,
        jobAdPublishHomepage: false,
        jobAdPublishOnBfA: false,
        postingIsPaused: false,
        postingIsCancelled: false,
        totalJobAds: 0,
        postedJobAds: 0,
      };
    },
    RESET_POSTED_JOB_ADS(state) {
      state.jobPosterState.postedJobAds = 0;
    },
    RESET_STORE(state) {
      Object.assign(state, initialState);
      clearState();
    },
    SET_ACTIVE_REQUESTS(state, value) {
      state.activeRequestsForLimiter = value < 0 ? 0 : value;
    },
    SET_CANDIDATE_ADDRESS_FOR_COMMUTE_WEB_SEARCH(
      state,
      candidateAddressForCommuteWebSearch: CandidateAddressForCommuteWebSearch
    ) {
      state.candidateAddressForCommuteWebSearch =
        candidateAddressForCommuteWebSearch;
    },
    SET_CONFIG(state, config) {
      state.env = config;
    },
    SET_COMPANY_CONFIG(state, companyConfig) {
      recursiveMerge(state.company, companyConfig).then(() => {
        this.commit(MutationType.updatePartialCompanyConfig, {
          key: "statusOptionsRecruit",
          value: companyConfig.statusOptionsRecruit,
        });
        this.commit(MutationType.updatePartialCompanyConfig, {
          key: "salaryAdjustments",
          value: companyConfig.salaryAdjustments,
        });
        this.commit(MutationType.updatePartialCompanyConfig, {
          key: "reminderSettings",
          value: companyConfig.reminderSettings,
        });
      });
    }, //for API Init
    SET_DRAG_ORIGIN_COMPONENT(state, component) {
      state.dragOriginComponent = component;
    },
    SET_DRAGGING_ITEM(state, { type, data }) {
      state.isDraggingItem = { ...state.isDraggingItem, [type]: data };
    }, // Send payload while Dragging/ write in demand / customer / candidate / employee
    SET_HEADER_SPINNER_ELEMENT(state, { size, gif }) {
      state.headerSpinner.size = size;
      state.headerSpinner.gif = gif;
    },
    SET_INTER_COMPONENT_MESSAGE: (state, { message, payload }) => {
      state.interComponentMessage.message = message;
      state.interComponentMessage.payload = payload;
    },
    SET_JOB_AD_PUBLISH_HOMEPAGE(state, newValue) {
      state.jobPosterState.jobAdPublishHomepage = newValue;
    },
    SET_JOB_AD_PUBLISH_ON_BFA(state, newValue) {
      state.jobPosterState.jobAdPublishOnBfA = newValue;
    },
    SET_LAST_UPDATE_ERPDATA(state, lastUpdateErpData: string) {
      state.company.softwareIntegration.lastAutoUpdateFromErp =
        lastUpdateErpData;
    },
    SET_LAST_UPDATE_TIMELINE(state, lastUpdateTimeline: string) {
      state.company.lastUpdateTimeline = lastUpdateTimeline;
    },
    SET_LAST_PHONE_CALL_FROM(state, lastPhoneCallFrom: LastPhoneCallFrom) {
      state.lastPhoneCallFrom = lastPhoneCallFrom;
    },
    SET_MANDANTS(state, mandants) {
      state.company.mandants = mandants;
    },
    SET_POSTCODE_CUSTOMERS(state, postcodes: string[]) {
      state.postcodesCustomers = postcodes;
    },
    SET_PROFILE_TEMPLATE(state, profileTemplate: ProfileTemplate) {
      state.company.profileTemplate = profileTemplate;
    },
    SET_RECURION_CHAT(state, recurionChatMenu: RecurionChatMenu) {
      if (recurionChatMenu?.chatbotType)
        state.recurionState.chatbotType = recurionChatMenu?.chatbotType;
      if (recurionChatMenu?.initPrompt)
        state.recurionState.initPrompt = recurionChatMenu?.initPrompt;
      if (recurionChatMenu?.currentRemoteUserMessage)
        state.recurionState.currentRemoteUserMessage =
          recurionChatMenu?.currentRemoteUserMessage;
      if (recurionChatMenu?.currentRemoteRecurionMessage)
        state.recurionState.currentRemoteRecurionMessage =
          recurionChatMenu?.currentRemoteRecurionMessage;
      if (recurionChatMenu?.isActive)
        state.recurionState.isActive = recurionChatMenu?.isActive;
      if (recurionChatMenu?.isClosed)
        state.recurionState.isClosed = recurionChatMenu?.isClosed;
    },
    SET_RECURION_CHAT_ACTIVE(state) {
      state.recurionState.isActive = true;
    },
    SET_RECURION_CHAT_CLOSED(state) {
      state.recurionState.isClosed = true;
    },
    SET_RECURION_CHAT_INACTIVE(state) {
      state.recurionState.isActive = false;
    },
    SET_RECURION_CHAT_OPEN(state) {
      state.recurionState.isClosed = false;
    },
    SET_SCOREBOARD_ACTIVE(state) {
      state.perfState.isOffline = false;
    },
    SET_STATUS(state, payload: JobposterState) {
      if (Object.keys(JobposterState).includes(payload)) {
        state.jobPosterState.status = payload;
      } else {
        console.error("Ungültiger Status: " + payload);
      }
    },
    SET_STORE_HAS_TO_INIT(state, value) {
      state.storeHasToInit = value;
    }, //for API Init
    SET_TOTAL_JOB_ADS(state, payload) {
      state.jobPosterState.totalJobAds = payload;
      state.jobPosterState.postedJobAds = 0;
    },

    SET_USER(state, user) {
      state.company.loggedInUser = user;
    },
    SET_USER_CONFIG(state, userConfig) {
      state.company.loggedInUser.config = userConfig;
    },
    SET_USER_DASHBOARD_ANALYSIS(state, dashboardText: DashboardText) {
      if (!state.company.loggedInUser.insightsBundle)
        state.company.loggedInUser.insightsBundle = {};
      if (!state.company.loggedInUser.insightsBundle.dashboardText)
        state.company.loggedInUser.insightsBundle.dashboardText = {};
      state.company.loggedInUser.insightsBundle.dashboardText = dashboardText;
    },
    SET_USER_PERF_INSIGHTS(state, perfInsights: PerfInsight[]) {
      if (!state.company.loggedInUser.insightsBundle)
        state.company.loggedInUser.insightsBundle = {};
      if (!state.company.loggedInUser.insightsBundle.perfDaily)
        state.company.loggedInUser.insightsBundle.perfDaily = [];
      state.company.loggedInUser.insightsBundle.perfDaily = perfInsights;
    },
    SET_USER_PROFILE(state, userProfileData) {
      state.company.loggedInUser = {
        ...state.company.loggedInUser,
        ...userProfileData,
      };
    },
    SET_VSTYLE(state, vStyle: vStyle) {
      state.vStyle = vStyle;
    },
    SET_WORKTIME_PROMPT(state, newWorkTimePrompts: WorkTimePrompt[]) {
      state.company.aiData.workTimePrompts = newWorkTimePrompts;
    },
    SET_ZVOOVE_CUSTOMER_LIST(state, customerList) {
      state.customerList = customerList;
    },
    SET_ZVOOVE_DASHBOARD_DATA(state, dashboardData) {
      state.dashboardData = dashboardData;
    },
    SET_ZVOOVE_EMPLOYEE_LIST(state, employeeList) {
      state.employeeList = employeeList;
    },
    TOGGLE_POSTING_IS_CANCELLED(state) {
      state.jobPosterState.postingIsCancelled =
        !state.jobPosterState.postingIsCancelled;
    },
    TOGGLE_POSTING_IS_PAUSED(state) {
      state.jobPosterState.postingIsPaused =
        !state.jobPosterState.postingIsPaused;
    },
    TOGGLE_RECURION_MENU(state) {
      state.recurionState.isClosed = !state.recurionState.isClosed;
    },
    TOGGLE_ZORST_DEBUGGING(state) {
      state.zorstDebugging = !state.zorstDebugging;
    },
    UPDATE_COMPANY_CONFIG(state, payload) {
      Object.assign(state.company, payload);
    },
    UPDATE_PARTIAL_COMPANY_CONFIG(state, { key, value }) {
      if (value && Array.isArray(value) && value.length > 0) {
        state.company[key] = [...value];
      }
    },
    UPDATE_DISPATCHER_BOARD_FILTER(
      state: any,
      payload: {
        columnName: keyof typeof state.company.loggedInUser.config.dispatcherBoard;
        property: keyof (typeof state.company.loggedInUser.config.dispatcherBoard)["columnDemand"];
        value: string[];
      }
    ) {
      const column =
        state.company.loggedInUser.config.dispatcherBoard[payload.columnName];
      if (
        column &&
        Object.prototype.hasOwnProperty.call(column, payload.property)
      ) {
        (column as any)[payload.property] = payload.value;
      }
    },
    UPDATE_LAST_AUTO_UPDATE_FROM_ERP(state, currentTime) {
      state.company.softwareIntegration.lastAutoUpdateFromErp = currentTime;
    },
    UPDATE_LOGGEDIN_MANDANTS(state, newMandantUuids) {
      state.company.loggedInUser.config.loggedInMandants = newMandantUuids;
    },
    SET_WHATSAPP(state, whatsapp) {
      state.company.whatsApp = whatsapp;
    },
  },
  actions: {
    async loadConfig({ commit }, config) {
      try {
        commit(MutationType.setConfig, config);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    },
    async addCustomerReducedToCustomerList(
      { commit },
      customerReduced: CustomerList
    ) {
      try {
        const customerService = CustomerService.getInstance();
        await customerService.addCustomerReducedToList(customerReduced);

        commit(MutationType.addToCustomerList, customerReduced);
      } catch (error) {
        console.error("Error adding customer reduced:", error);
      }
    },
    async loadLinkingsAndAddMissingDescriptions({ getters }) {
      const candidateService = new CandidateService();
      const customerService = new CustomerService();
      const mandants = getters.allSelectedMandants;
      const linkingService = new LinkingService();

      try {
        const matchingLinkings = await linkingService.getAllLinkings(mandants);

        for (const linking of matchingLinkings) {
          let isLinkingUpdated = false;

          if (!linking.description) {
            linking.description = {} as LinkingDescription;
          }

          if (linking.linkingCore.candidate && !linking.description.firstName) {
            try {
              const candidateDetails = await candidateService.getCandidateById(
                linking.linkingCore.candidate
              );
              if (candidateDetails) {
                Object.assign(linking.description, {
                  firstName: candidateDetails.firstName,
                  lastName: candidateDetails.lastName,
                  postCode: candidateDetails.addressPostalCode,
                  city: candidateDetails.addressCity,
                  uuid: candidateDetails.uuid,
                });
                isLinkingUpdated = true;
              }
            } catch (error) {
              console.error("Error fetching candidatedetails: ", error);
            }
          }

          if (
            linking.linkingCore.customer &&
            !linking.description.customerName
          ) {
            try {
              const customerDetails = await customerService.getById(
                linking.linkingCore.customer
              );
              if (customerDetails && customerDetails.generalData) {
                Object.assign(linking.description, {
                  customerName: customerDetails.generalData.name,
                  customerPostCode:
                    customerDetails.addressAndCommunication.postalCode,
                  customerCity: customerDetails.addressAndCommunication.city,
                });
                isLinkingUpdated = true;
              } else {
                console.error(
                  "customerDetails oder customerDetails.generalData ist undefined"
                );
              }
            } catch (error) {
              console.error("Error fetching customerdetails: ", error);
            }
          }

          if (isLinkingUpdated) {
            try {
              await linkingService.updateLinking(linking);
            } catch (error) {
              console.error(
                `Error updating linking with ID ${linking._id}: `,
                error
              );
            }
          }
        }

        this.state.linkings = matchingLinkings;
      } catch (error) {
        console.error("Error loading linking: ", error);
      }
    },
    async loadStoreInitialData({ commit }) {
      clearState();
      try {
        const companyConfigService = CompanyConfigService.getInstance();
        const mandantService = new MandantService();
        const userService = new UserService();
        const companyConfig = await companyConfigService.getModel();
        const mandantsResult = await mandantService.getMandants();
        const userResult = await userService.getModel();

        if (companyConfig) {
          // companyConfig.profileTemplate.colorPrimary to scss
          const profileColorPrimary =
            companyConfig.profileTemplate.colorPrimary;
          document.documentElement.style.setProperty(
            "--color-profile-primary",
            profileColorPrimary
          );
          candidateSuggestionDescription = companyConfig.timelineEntryTypes
            ? companyConfig.timelineEntryTypes.candidateSuggestion.description
            : ""; //important to set the same string for this state
          commit(MutationType.setCompanyConfig, companyConfig);
          commit(MutationType.setWhatsapp, companyConfig.whatsApp);
        }
        if (mandantsResult) {
          commit(MutationType.setMandants, mandantsResult);
        }
        commit(MutationType.setStoreHasToInit, false); //That components know the store has the backend-data
        if (userResult) {
          commit(MutationType.setUser, userResult);
        }
      } catch (error) {
        console.error("Error loading initial data: ", error);
      }
    },
    async loadCustomerAndEmployeeBackgroundData({ commit }) {
      try {
        const customerService = CustomerService.getInstance();
        const employeeService = new EmployeeService();
        const customerList = await customerService.getList();
        const employeeList = await employeeService.getList();
        if (customerList) {
          commit(MutationType.setZvooveCustomerList, customerList);
        }
        if (employeeList) {
          commit(MutationType.setZvooveEmployeeList, employeeList);
        }
      } catch (error) {
        console.error("Error loading initial data: ", error);
      }
    },
    resetStore({ commit }) {
      clearState();
      commit(MutationType.resetStore);
    },
    updateDispatcherBoardFilter(
      { commit }: { commit: any },
      payload: {
        columnName: string;
        property: string;
        value: string | string[];
        validArray?: string[];
      }
    ): Promise<void> {
      return new Promise<void>((resolve) => {
        const valuesArray = Array.isArray(payload.value)
          ? payload.value
          : [payload.value];

        let filteredValues = valuesArray;
        if (payload.validArray && Array.isArray(payload.validArray)) {
          filteredValues = valuesArray.filter((item) =>
            payload.validArray?.includes(item)
          );
        }

        const uniqueValues = Array.from(new Set(filteredValues));
        const newPayload = {
          ...payload,
          value: uniqueValues,
        };

        commit(MutationType.updateDispatcherBoardFilter, newPayload);
        resolve();
      });
    },

    updateLastUpdateTimeline({ commit }, lastUpdateTimeline: string) {
      commit(MutationType.setLastUpdateTimeline, lastUpdateTimeline);
    },
  },
  getters: {
    allSelectedMandants(state) {
      if (state.company?.loggedInUser?.config?.dispatcherBoard) {
        const { columnDemand, columnCustomer, columnCandidate } =
          state.company?.loggedInUser?.config?.dispatcherBoard;
        const { loggedInMandants } = state.company?.loggedInUser?.config;

        const allMandants = [
          ...(columnDemand?.filterMandants || []),
          ...(columnCustomer?.filterMandants || []),
          ...(columnCandidate?.filterMandants || []),
          ...(loggedInMandants || []),
        ];

        const uniqueMandants = [...new Set(allMandants)];

        return uniqueMandants;
      }
    },
    allZvooveOneGeschaeftsstelleIds: (state) => {
      const ids = state.company.mandants.map((mandant: Mandant) =>
        parseInt(mandant.branchNumber || "0")
      );
      return [...new Set(ids)].sort((a: any, b: any) => a - b);
    },
    appLayout: (state): AppLayout => {
      return (
        state.company?.appLayout || state.env?.appLayout || "defaultLayout"
      );
    },
    candidateAddressForCommuteWebSearch: (state) =>
      state.candidateAddressForCommuteWebSearch,
    candidateToCustomerEvents: (state) => state.candidateToCustomerEvents,
    candidateToMandantEvents: (state) => state.candidateToMandantEvents,
    currentBaseDialog: (state) => {
      // Remove expired dialogs when fetching
      const now = Date.now();
      if (state.baseDialogQueue.length > 0) {
        const currentDialog = state.baseDialogQueue[0];
        if (now - currentDialog.timestamp > baseDialogQueueTimeout) {
          state.baseDialogQueue.shift();
          return null;
        }
      }
      return state.baseDialogQueue[0] || null;
    },
    demandToCustomerEvents: (state) => state.demandToCustomerEvents,
    employeeToCustomerEvents: (state) => state.employeeToCustomerEvents,
    company: (state) => state.company,
    config: (state) => state.config,
    customerStates: (state) => state.customerStates,
    customerList: (state) => state.customerList,
    employeeStates: (state) => state.employeeStates,
    employeeList: (state) => state.employeeList,
    followUpEvents: (state) => state.followUpEvents,
    funnelMandants: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        uuid: mandant.uuid,
        name: mandant.zvoovename,
        plzs: mandant.postalcodes,
      })),
    demandStates: (state) => state.demandStates,
    getActiveRequests: (state) => state.activeRequestsForLimiter,
    getAllMandantL1: (state) => {
      const mandantL1s = state.company.mandants
        .map((mandant: Mandant) => mandant.mandantL1)
        .filter(
          (mandantL1: string | null) => mandantL1 != null && mandantL1 !== ""
        );
      return Array.from(new Set(mandantL1s));
    },
    getAllMandantUuidAndBranchInitials: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        uuid: mandant.uuid,
        branchInitials: mandant.branchInitials,
      })),
    getCustomerNameByNumberFromCustomerList:
      (state) => (customerNumber: string) => {
        const customer = state.customerList.find(
          (cust: any) => cust.customerNumber === customerNumber
        );
        return customer ? customer.name : null;
      },
    getCustomerNumberByNameFromCustomerList:
      (state) => (customerName: string) => {
        const customer = state.customerList.find(
          (cust: any) => cust.name === customerName
        );
        return customer ? customer.customerNumber : null;
      },
    getEnv: (state) => state.env,
    getEmployeeNameByNumberFromEmployeeList:
      (state) => (employeeNumber: string) => {
        const employee = state.employeeList.find(
          (emp: any) => emp.employeeNumber === employeeNumber
        );
        if (!employee) {
          return null;
        }

        const nameParts = employee.name.split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];
        return {
          firstName,
          lastName,
        };
      },
    getEventNameByCandidateStatus: (state) => (status: string) => {
      const allEvents = [
        ...state.candidateToCustomerEvents,
        ...state.candidateToMandantEvents,
      ];
      const event = allEvents.find((event) => event.candidateStatus === status);
      return event ? event.eventName : null;
    },
    getEventNameByLinkingStatus: (state) => (linkingStatus: LinkingStatus) => {
      const allEvents = [
        ...state.candidateToMandantEvents,
        ...state.candidateToCustomerEvents,
        ...state.demandToCustomerEvents,
        ...state.employeeToCustomerEvents,
        ...state.followUpEvents,
      ];

      const matchingEvent = allEvents.find(
        (event) => event.candidateStatus === linkingStatus
      );

      return matchingEvent ? matchingEvent.eventName : undefined;
    },
    getExpiredLinkingReminders: (state, getters) => {
      const expiredReminders = [] as ExpiredReminder[];

      if (
        !state.company.reminderSettings ||
        state.company.reminderSettings.length === 0
      ) {
        return expiredReminders;
      }

      const loggedInMandants =
        state.company?.loggedInUser?.config?.loggedInMandants;

      return state.linkings.reduce(
        (acc: ExpiredReminder[], linking: Linking) => {
          const latestEvent = linking.events[linking.events.length - 1];

          if (latestEvent && latestEvent.eventDate.length > 0) {
            const eventDate = moment(
              latestEvent.eventDate[0],
              "YYYY-MM-DD HH:mm"
            );
            const translatedEventType = getters.getLinkingstatusFromEventName(
              latestEvent.eventType
            );

            if (translatedEventType) {
              const reminderConfig = state.company.reminderSettings.find(
                (reminder: { reminderFor: string }) =>
                  reminder.reminderFor === translatedEventType
              );

              if (reminderConfig) {
                const expirationDate = eventDate
                  .clone()
                  .add(reminderConfig.daysAfter, "Tage")
                  .add(reminderConfig.hoursAfter, "Stunden");

                if (expirationDate.isBefore(moment())) {
                  if (
                    loggedInMandants.length === 0 ||
                    linking.mandants.some((mandant) =>
                      loggedInMandants.includes(mandant)
                    )
                  ) {
                    acc.push({
                      event: latestEvent,
                      linkingCore: linking.linkingCore,
                      description: linking.description,
                      expiredSince: expirationDate.from(moment()),
                      needToContact: reminderConfig.needToContact,
                      showPrompt: reminderConfig.showPrompt,
                      buttonResult: reminderConfig.buttonResult,
                      buttonFailure: reminderConfig.buttonFailure,
                      buttonSuccess: reminderConfig.buttonSuccess,
                      message: reminderConfig.message,
                      reminderFor: reminderConfig.reminderFor,
                    });
                  }
                }
              }
            }
          }

          return acc as ExpiredReminder[];
        },
        []
      );
    },
    getExpiredReminderForLinkingCore:
      (state, getters) => (linkingCore: Partial<LinkingCore>) => {
        const linking = state.linkings.find((link: Linking) => {
          return (
            (!linkingCore.mandant ||
              link.linkingCore.mandant === linkingCore.mandant) &&
            (!linkingCore.candidate ||
              link.linkingCore.candidate === linkingCore.candidate) &&
            (!linkingCore.customer ||
              link.linkingCore.customer === linkingCore.customer) &&
            (!linkingCore.demand ||
              link.linkingCore.demand === linkingCore.demand) &&
            (!linkingCore.employee ||
              link.linkingCore.employee === linkingCore.employee) &&
            (!linkingCore.user || link.linkingCore.user === linkingCore.user)
          );
        });

        if (!linking || linking.events.length === 0) {
          return null;
        }

        const latestEvent = linking.events[linking.events.length - 1];

        if (!latestEvent || latestEvent.eventDate.length === 0) {
          return null;
        }

        const eventDate = moment(latestEvent.eventDate[0], "YYYY-MM-DD HH:mm");
        const translatedEventType = getters.getLinkingstatusFromEventName(
          latestEvent.eventType
        );

        if (!translatedEventType) {
          return null;
        }

        const reminderConfig = state.company.reminderSettings.find(
          (reminder: { reminderFor: string }) =>
            reminder.reminderFor === translatedEventType
        );

        if (!reminderConfig) {
          return null;
        }

        const expirationDate = eventDate
          .clone()
          .add(reminderConfig.daysAfter, "days")
          .add(reminderConfig.hoursAfter, "hours");

        if (expirationDate.isBefore(moment())) {
          return {
            event: latestEvent,
            description: linking.description,
            expiredSince: expirationDate.from(moment()),
            needToContact: reminderConfig.needToContact,
            showPrompt: reminderConfig.showPrompt,
            buttonResult: reminderConfig.buttonResult,
            buttonFailure: reminderConfig.buttonFailure,
            buttonSuccess: reminderConfig.buttonSuccess,
            message: reminderConfig.message,
            reminderFor: reminderConfig.reminderFor,
          };
        }

        return null;
      },
    getBranchNumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchNumber : null;
    },
    getInitialsByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchInitials : null;
    },
    getLoggedInMandantBranchNumbers: (state, getters) => {
      const loggedInUser = state.company.loggedInUser;
      const config = loggedInUser.config;
      const loggedInMandants = config && config.loggedInMandants;

      if (!loggedInMandants) return [];

      return state.company.loggedInUser.config.loggedInMandants
        .map((uuid: string) => {
          const id = parseInt(getters.getBranchNumberByUuid(uuid), 10);
          return isNaN(id) ? null : id;
        })
        .filter((id: string) => id !== null);
    },
    getLoggedInMandantL1Numbers: (state, getters) => {
      const loggedInUser = state.company.loggedInUser;
      const config = loggedInUser.config;
      const loggedInMandants = config && config.loggedInMandants;

      if (!loggedInMandants) return [];

      return state.company.loggedInUser.config.loggedInMandants
        .map((uuid: string) => {
          const id = parseInt(getters.getMandantL1NumberByUuid(uuid), 10);
          return isNaN(id) ? null : id;
        })
        .filter((id: string) => id !== null);
    },
    getLinkingAppointmentsForCalendar: (state, getters) => {
      const loggedInMandants = getters.getLoggedInMandantUuids;

      return state.linkings
        .filter((linking: Linking) => {
          const isValidMandant = linking.mandants.some((uuid) =>
            loggedInMandants.includes(uuid)
          );
          return isValidMandant && linking.events.length > 0;
        })
        .flatMap((linking: Linking) => {
          const lastEvent = linking.events[linking.events.length - 1];

          if (
            !lastEvent ||
            !lastEvent.eventDate ||
            lastEvent.eventDate.length === 0
          ) {
            return [];
          }

          return lastEvent.eventDate.map((dateString) => {
            const startDate = moment(dateString, [
              "YYYY-MM-DDTHH:mm:ss.sssZ",
              "YYYY-MM-DD HH:mm",
            ]).toDate();
            const endDate = moment(startDate).add(1, "hour").toDate();

            const candidateName = linking.description
              ? `${
                  linking.description.firstName
                    ? linking.description.firstName + " "
                    : ""
                } ${
                  linking.description.lastName
                    ? linking.description.lastName + " bei "
                    : ""
                }`
              : "";

            const candidateId = linking.linkingCore.candidate
              ? linking.linkingCore.candidate
              : undefined;

            const customerName = linking.description?.customerName
              ? linking.description.customerName
              : "";

            const mandantIds = linking.mandants
              .map((uuid) => getters.getMandantByUuid(uuid)?.branchNumber)
              .filter(Boolean)
              .join(" und ");

            return {
              start: startDate,
              end: endDate,
              title:
                `${candidateName} ${customerName} (NL${mandantIds})`.trim(),
              class: lastEvent.eventType,
              candidateId: candidateId,
            };
          });
        });
    },
    getLinkingDescriptionOrEventNameFromType: (state) => (type: string) => {
      const timelineEntry = state.company.timelineEntryTypes[type];
      if (
        timelineEntry &&
        typeof timelineEntry === "object" &&
        "description" in timelineEntry
      ) {
        return timelineEntry.description;
      }

      const event = [
        ...state.candidateToCustomerEvents,
        ...state.candidateToMandantEvents,
        ...state.followUpEvents,
        ...state.demandToCustomerEvents,
        ...state.employeeToCustomerEvents,
        ...state.userToMandantEvents,
      ].find((event) => event.candidateStatus === type);

      return event ? event.eventName : undefined;
    },
    getLinkingstatusFromEventName: (state) => (eventType: string) => {
      const allEvents = [
        ...state.candidateToCustomerEvents,
        ...state.candidateToMandantEvents,
        ...state.followUpEvents,
        ...state.demandToCustomerEvents,
        ...state.employeeToCustomerEvents,
        ...state.userToMandantEvents,
      ];

      const event = allEvents.find((event) => event.eventName === eventType);
      return event ? event.candidateStatus : null;
    },
    getLinkingstatusOrTimelineEntryTypeFromEventName:
      (state) => (searchString: string) => {
        const timelineEntry = Object.entries(
          state.company.timelineEntryTypes
        ).find(
          (entry): entry is [string, { description: string }] =>
            typeof entry[1] === "object" &&
            entry[1] !== null &&
            "description" in entry[1] &&
            typeof entry[1].description === "string" &&
            entry[1].description === searchString
        );
        if (timelineEntry) {
          return timelineEntry[0];
        }

        const event = [
          ...state.candidateToCustomerEvents,
          ...state.candidateToMandantEvents,
          ...state.followUpEvents,
          ...state.demandToCustomerEvents,
          ...state.employeeToCustomerEvents,
          ...state.userToMandantEvents,
        ].find((event) => event.eventName === searchString);

        return event ? (event.candidateStatus as LinkingStatus) : undefined;
      },
    getLinkings: (state) => state.linkings,
    getLinkingsBySearchString: (state) => (searchString: string) => {
      return state.linkings.filter((linking: any) => {
        return Object.values(linking.linkingCore).some(
          (value) => value === searchString
        );
      });
    },
    getLoggedInMandantUuids: (state) =>
      state.company.loggedInUser.config.loggedInMandants,
    getMailServersForLoggedInMandants: (state, getters) => {
      const loggedInMandantUuids = getters.getLoggedInMandantUuids || [];

      return loggedInMandantUuids
        .map((uuid: string) => getters.getMandantByUuid(uuid))
        .filter((mandant: Mandant | undefined) => mandant && mandant.mailServer)
        .map((mandant: Mandant) => ({
          mailServer: mandant.mailServer,
          uuid: mandant.uuid,
        }));
    },
    getMandantByUuid: (state) => (uuid: string) => {
      return state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
    },
    getMandantByBranchNumber: (state) => (branchNumber: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.branchNumber === branchNumber
      );
      return mandant ? mandant.uuid : null;
    },
    getMandantByBranchNumberAndMandantL1:
      (state) => (branchNumber: string, mandantL1: string) => {
        const mandant = state.company.mandants.find(
          (mandant: Mandant) =>
            mandant.branchNumber === branchNumber &&
            mandant.mandantL1 === mandantL1
        );
        return mandant ? mandant.uuid : null;
      },
    getMandantBranchNumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchNumber : "Unbekannter Mandant";
    },
    getMandantL1ByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.mandantL1 : "Unbekannter Mandant";
    },
    getMandantL1NumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.mandantL1 : null;
    },
    getMandantNameByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.name : "Unbekannter Mandant";
    },
    getMandantUuidByZvoovename: (state) => (zvoovename: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.zvoovename === zvoovename
      );
      return mandant ? mandant.uuid : "Unbekannte UUID";
    },
    getMandantZvoovenameByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.zvoovename : "Unbekannter Mandant";
    },
    getSalaryAdjustment: (state) => (payrollInfoType: string) => {
      const matchedSalaryAdjustment = state.company.salaryAdjustments.find(
        (salaryAdjustment: SalaryAdjustment) =>
          salaryAdjustment.payrollInfoType === payrollInfoType
      );
      return matchedSalaryAdjustment;
    },
    getStatusByValue: (state) => (value: string) => {
      const option = state.company.statusOptionsRecruit.find(
        (option: any) => option.value === value
      );
      return option ? option.status : [];
    },
    getStatusTextByValue: (state) => (value: string) => {
      const exactMatch = state.company.statusOptionsRecruit.find(
        (option: any) => option.value === value
      );
      if (exactMatch) return exactMatch.text;

      const partialMatch = state.company.statusOptionsRecruit.find(
        (option: any) => value.startsWith(option.value)
      );
      return partialMatch ? partialMatch.text : "";
    },
    getStatusValueByLinkingStatus:
      (state) => (linkingStatus: LinkingStatus) => {
        const option = state.company.statusOptionsRecruit.find((option: any) =>
          option.status.includes(linkingStatus)
        );
        return option ? option.value : "";
      },
    getStatusByEmployeeNumber: (state) => (employeeNumber: string) => {
      const employee = state.employeeList.find(
        (emp: EmployeeList) => emp.employeeNumber === employeeNumber
      );
      return employee ? employee.status : null;
    },
    /**
     * Converts the `statusOptionsRecruit` array from the state into a mapping
     * of slider numbers to their corresponding text labels.
     *
     * Input:
     * - `state.company.statusOptionsRecruit`: An array of objects, where each object
     *   contains a `slider` property (number, null, or undefined) and a `text` property (string).
     *
     * Example Input:
     * state.company.statusOptionsRecruit = [
     *   { slider: 1, text: "Pending" },
     *   { slider: 2, text: "In Progress" },
     *   { slider: null, text: "Rejected" },
     *   { slider: 3, text: "Completed" }
     * ];
     *
     * Output:
     * - A Record<number, string> object where the keys are slider numbers (only those
     *   that are valid numbers) and the values are their corresponding `text` values.
     *
     * Example Output:
     * {
     *   1: "Pending",
     *   2: "In Progress",
     *   3: "Completed"
     * }
     *
     * @param {Object} state - The application state object.
     * @returns {Record<number, string>} A mapping of slider numbers to text labels.
     */
    getSliderCandidateLabels: (state) => {
      const labels: Record<number, string> = {};

      (state.company.statusOptionsRecruit || []).forEach(
        (item: { slider: number | null | undefined; text: string }) => {
          if (typeof item.slider === "number") {
            labels[item.slider] = item.text;
          }
        }
      );

      return labels;
    },
    getSliderNumberByValue:
      (state) =>
      (value: string): number | null => {
        const option = state.company.statusOptionsRecruit.find(
          (item: { value: string }) => item.value === value
        );
        return option?.slider ?? null;
      },
    globalReminders: (state) => state.globalReminders,
    hasLinkingForCandidate: (state) => (candidateId: string) => {
      return state.linkings.some(
        (linking: Linking) => linking.linkingCore.candidate === candidateId
      );
    },
    hasLinkingForCustomer: (state) => (customerId: string) => {
      return state.linkings.some(
        (linking: Linking) => linking.linkingCore.customer === customerId
      );
    },
    hasLinkingForDemand: (state) => (demandId: string) => {
      return state.linkings.some(
        (linking: Linking) => linking.linkingCore.demand === demandId
      );
    },
    headerLogo: (state) => state.headerLogo,
    interComponentMessage: (state) => state.interComponentMessage,
    isJobAdPublishOnBfA: (state) => state.jobPosterState.jobAdPublishOnBfA,
    jobPosterState: (state) => state.jobPosterState,
    lastUpdateTimeline: (state) => state.lastUpdateTimeline,
    mandants: (state) => state.company.mandants,
    mandantL1AndBranchNumber: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        branchNumber: mandant.branchNumber,
        mandantL1: mandant.mandantL1,
      })),
    mandantUuids: (state) =>
      state.company.mandants.map((mandant: Mandant) => mandant.uuid),
    mailTemplates: (state) => state.company.aiData.template.mail,
    pdHubToken: (state) => state.company.apiKeys.pdHubToken,
    recurionChatIsActive: (state) => state.recurionState.isActive,
    reducedMandants: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        uuid: mandant.uuid,
        name: mandant.name,
        zvoovename: mandant.zvoovename,
        branchNumber: mandant.branchNumber,
        branchInitials: mandant.branchInitials,
      })),
    reminderActions: (state) => state.reminderActions,
    reminderSettings: (state) => state.company.reminderSettings,
    softwareIntegration: (state) => state.company.softwareIntegration,
    statusOptionsRecruit: (state) => state.company.statusOptionsRecruit,
    storeHasToInit: (state) => state.storeHasToInit,
    timelineEntryTypes: (state) => state.company.timelineEntryTypes,
    user: (state) => state.company.loggedInUser as User,
    workTimePrompts: (state) => state.company.aiData.workTimePrompts,
    zvooveCustomers: (state) => state.customers,
    zvooveDashboardData: (state) => state.dashboardData,
    zvooveEmployeeList: (state) => state.employeeList,
    isJobAdPublishOnHomepage: (state) =>
      state.jobPosterState.jobAdPublishHomepage,
  },

  modules: {
    // Space to add additional modules
  },
  plugins: [vuexSessionStorage],
});

export function useStore() {
  return baseUseStore(key);
}
