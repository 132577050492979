import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_menu, {
      modelValue: _ctx.menu,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menu) = $event)),
      activator: "parent",
      "close-on-content-click": false,
      "max-width": "300"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, _mergeProps({ variant: "underlined" }, _ctx.vStyle.input, {
                  modelValue: _ctx.searchQuery,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                  label: "Mitarbeiter suchen",
                  onInput: _ctx.filterEmployees,
                  dense: "",
                  clearable: ""
                }), null, 16, ["modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEmployees, (employee, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.selectEmployee(employee))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(employee.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}