import { AccessRule } from "../enums/access-rule.enum";
import { User } from "../models/user.model";

export class UserRoleHelper {
  static isUberAdmin(user: User): boolean {
    if (!user) return false;
    return user.role?.permissions?.includes(AccessRule.UBER);
  }

  static hasAccess(
    user: User,
    permissions: AccessRule | AccessRule[]
  ): boolean {
    if (!(user && permissions)) return false;
    if (Array.isArray(permissions))
      return permissions.some((p) => user.role?.permissions?.includes(p));
    return user?.role?.permissions?.includes(permissions) ?? false;
  }
}
