import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.showMatchDialog,
    onCloseDialog: _ctx.closeMatchDialog,
    width: 'var(--d-w-s)',
    class: "fill-height",
    onMouseenter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.preloadAllCandidates()))
  }, {
    title: _withCtx(() => [
      _createTextVNode("Umkreissuche " + _toDisplayString(_ctx.postcodeRadius) + "km", 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
            ref: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_slider, {
                max: 100,
                min: 0,
                step: 1,
                class: "my-2",
                modelValue: _ctx.postcodeRadius,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.postcodeRadius) = $event)),
                  _ctx.savePostcodeRadius
                ],
                "thumb-label": ""
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _createVNode(_component_v_btn, {
                block: "",
                color: "secondary",
                disabled: !_ctx.valid,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startMatching()))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Suchen")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}