export const fontFamilies = [
  { name: "Arial Unicode MS", value: "Arial Unicode MS, sans-serif" },
  { name: "Arial", value: "Arial, sans-serif" },
  { name: "Bookman", value: "Bookman, serif" },
  { name: "Comic Sans MS", value: "Comic Sans MS, cursive" },
  { name: "Garamond", value: "Garamond, serif" },
  { name: "Geneva", value: "Geneva, sans-serif" },
  { name: "Georgia", value: "Georgia, serif" },
  { name: "Helvetica", value: "Helvetica, sans-serif" },
  { name: "Impact", value: "Impact, sans-serif" },
  { name: "Lucida Console", value: "Lucida Console, monospace" },
  { name: "Lucida Sans Unicode", value: "Lucida Sans Unicode, sans-serif" },
  { name: "Lucida Sans", value: "Lucida Sans, sans-serif" },
  { name: "Monaco", value: "Monaco, monospace" },
  { name: "Palatino", value: "Palatino, serif" },
  { name: "Roboto", value: "Roboto, sans-serif" },
  { name: "Tahoma", value: "Tahoma, sans-serif" },
  { name: "Times New Roman", value: "Times New Roman, serif" },
  { name: "Trebuchet MS", value: "Trebuchet MS, sans-serif" },
  { name: "Verdana", value: "Verdana, sans-serif" },
];
