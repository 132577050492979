<template>
  <div v-if="isStatusInterview">
    <v-btn v-bind="vStyle.btn" @click="openWelcomeVideo()" block
      >elektronischer Bewerberbogen</v-btn
    >
    <v-divider></v-divider>
  </div>
  <v-dialog
    v-model="showWelcomeVideo"
    persistent
    max-width="100%"
    class="fill-height"
    fullscreen
  >
    <v-card class="d-flex justify-center">
      <div height="100vh" class="d-flex justify-center">
        <v-card v-bind="vStyle.card" max-width="100vh">
          <WelcomeVideo
            v-if="showWelcomeVideo"
            :candidateName="`${localCandidate.firstName} ${localCandidate.lastName}`"
            @videoClick="openModal()"
          ></WelcomeVideo>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="100%"
      class="fill-height"
      :fullscreen="!isDispatcherMode"
    >
      <v-card style="height: var(--d-h-full)" class="pa-0">
        <v-card-title>
          <v-card :rounded="vStyle.card.rounded">
            <v-tabs
              v-model="activeTab"
              bg-color="primary"
              style="color: var(--color-on-primary)"
              slider-color="primary"
            >
              <v-tab v-for="(tab, index) in tabs" :key="index" :value="index">
                {{ tab }}
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab v-if="isDispatcherMode" @click="closeModal()"
                ><v-icon size="x-large">fa-solid fa-circle-xmark</v-icon></v-tab
              >
            </v-tabs>
          </v-card>
          <div v-if="activeTab < 3" class="mt-3 d-flex justify-end">
            <v-spacer></v-spacer>
            <v-select
              max-width="10rem"
              :items="languages"
              density="compact"
              variant="plain"
              label="select language"
              v-model="language"
              item-title="label"
              item-value="label"
              @update:model-value="updateLanguage(language)"
            ></v-select>
          </div>
        </v-card-title>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
            >
              <!--Tab general data + address-->
              <v-row v-if="index === 0">
                <v-col cols="12" md="9">
                  <v-card-title>{{
                    labels.tab1.generalDataHeadline
                  }}</v-card-title>
                </v-col>
                <v-col class="d-flex flex-row-reverse" cols="12" md="3">
                  <v-btn
                    v-bind="vStyle.btn"
                    color="success"
                    :disabled="!isStepOneCompleted()"
                    @click="completeStepOne()"
                  >
                    {{ labels.buttons.completeStepOne }}
                    <v-icon class="ml-2">fa-solid fa-circle-arrow-right</v-icon>
                  </v-btn>
                </v-col>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="2">
                  <v-select
                    v-bind="vStyle.input"
                    v-model="localCandidate.salutationCatalogId"
                    :items="salutations"
                    item-title="value"
                    item-value="label"
                    :label="labels.tab1.salutation"
                  />
                </v-col>
                <v-col cols="12" md="8"></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.firstName"
                    :label="labels.tab1.firstName"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.lastName"
                    :label="labels.tab1.lastName"
                  />
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    :label="labels.tab1.birthDate"
                    v-model="formattedBirthDate"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.birthPlace"
                    :label="labels.tab1.birthPlace"
                  />
                </v-col>
                <v-col cols="12" md="4"></v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-bind="vStyle.input"
                    v-model="localCandidate.maritalStatus"
                    :items="maritalStatus"
                    item-title="value"
                    item-value="label"
                    :label="labels.tab1.maritalStatus"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.birthName"
                    :label="labels.tab1.birthName"
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <v-card-subtitle>{{ labels.tab1.address }}</v-card-subtitle>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.addressStreet"
                    :label="labels.tab1.street"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.addressHouseNumber"
                    :label="labels.tab1.houseNumber"
                  />
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.addressPostalCode"
                    :label="labels.tab1.postalCode"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localCandidate.addressCity"
                    :label="labels.tab1.city"
                  />
                </v-col>
                <v-col cols="12" md="4"></v-col>
                <v-card-title>{{ labels.tab1.contactHeadline }}</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col
                  cols="12"
                  md="6"
                  v-for="(item, index) in localCandidate.communicationMeans"
                  :key="item._id"
                >
                  <div class="mt-4 d-flex">
                    <v-select
                      v-bind="vStyle.input"
                      v-model="item.type"
                      :items="communicationTypes"
                      item-title="label"
                      item-value="value"
                      :label="labels.tab1.type"
                      max-width="40%"
                      class="mr-4"
                    />
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="item.value"
                      :label="labels.tab1.contactValue"
                    />
                    <v-btn
                      variant="text"
                      icon
                      @click="removeCommunicationMethod(index)"
                    >
                      <v-icon>fa-solid fa-xmark</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12" md="12">
                  <v-card v-bind="vStyle.card" class="mb-4" border="dashed">
                    <v-card-subtitle class="mt-4">{{
                      labels.tab1.addCommunication
                    }}</v-card-subtitle>
                    <v-card-text class="d-flex">
                      <v-select
                        v-bind="vStyle.input"
                        v-model="newCommunicationMethod.type"
                        :items="communicationTypes"
                        item-title="label"
                        item-value="value"
                        :label="labels.tab1.type"
                      />
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="newCommunicationMethod.value"
                        :label="labels.tab1.contactValue"
                        class="mx-4"
                      />
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="newCommunicationMethod.description"
                        :label="labels.tab1.description"
                      />
                      <v-btn
                        icon
                        class="mt-1 ml-2"
                        @click="addCommunicationMethod"
                        variant="text"
                      >
                        <v-icon size="large">fas fa-circle-plus</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab job wish -->
              <v-row v-else-if="index === 1">
                <v-col cols="12" md="9">
                  <v-card-title>{{ labels.tab2.newJobHeadline }}</v-card-title>
                </v-col>
                <v-col class="d-flex flex-row-reverse" cols="12" md="3">
                  <v-btn
                    v-bind="vStyle.btn"
                    color="success"
                    :disabled="!isStepTwoCompleted()"
                    @click="completeStepTwo()"
                  >
                    {{ labels.buttons.completeStepTwo }}
                    <v-icon class="ml-2">fa-solid fa-circle-arrow-right</v-icon>
                  </v-btn>
                </v-col>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-if="
                      localCandidate.applications &&
                      localCandidate.applications.length > 0
                    "
                    v-model="
                      localCandidate.applications[
                        localCandidate.applications.length - 1
                      ].appliedAs
                    "
                    :label="labels.tab2.workAsQuestion"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-if="
                      localCandidate.applications &&
                      localCandidate.applications.length > 0
                    "
                    v-model="
                      localCandidate.applications[
                        localCandidate.applications.length - 1
                      ].salary
                    "
                    :label="labels.tab2.expectedSalary"
                    type="number"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-if="
                      localCandidate.applications &&
                      localCandidate.applications.length > 0
                    "
                    v-model="
                      localCandidate.applications[
                        localCandidate.applications.length - 1
                      ].salaryCurrency
                    "
                    :label="labels.tab2.salaryCurrency"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-if="
                      localCandidate.applications &&
                      localCandidate.applications.length > 0
                    "
                    v-model="
                      localCandidate.applications[
                        localCandidate.applications.length - 1
                      ].salaryPeriod
                    "
                    :label="labels.tab2.salaryPeriod"
                  />
                </v-col>
                <v-card-title>{{
                  labels.tab2.mobilityAndShiftsHeadline
                }}</v-card-title>
                <v-divider class="mb-3"></v-divider>

                <v-col cols="12" md="8">
                  <v-card v-bind="vStyle.card" class="mb-4">
                    <v-card-title>
                      {{ labels.tab2.mobilityRadiusQuestion }}</v-card-title
                    >
                    <v-card-text>
                      <v-slider
                        class="mt-10"
                        v-model="localCandidate.mobilityRadius"
                        :max="100"
                        :min="0"
                        :step="0.5"
                        thumb-label="always"
                      ></v-slider>
                      <v-select
                        v-bind="vStyle.input"
                        v-model="localCandidate.mobility"
                        @update:model-value="
                          onMobilityChange(localCandidate.mobility as Mobility)
                        "
                        :items="mobilities"
                        :label="labels.tab2.mobilityMeans"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card v-bind="vStyle.card" class="mb-4">
                    <v-card-title class="mb-3">
                      {{ labels.tab2.workPermitHeadline }}</v-card-title
                    >
                    <v-card-text>
                      <v-text-field
                        v-bind="vStyle.input"
                        v-model="localCandidate.nationality"
                        :label="labels.tab2.nationality"
                      >
                      </v-text-field>
                      <v-combobox
                        v-bind="vStyle.input"
                        v-model="localCandidate.workPermitUntil"
                        :items="labels.tab2.workPermitOptions"
                        :label="labels.tab2.workPermitUntil"
                      >
                      </v-combobox>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="8">
                  <v-card v-bind="vStyle.card" class="mb-4">
                    <v-card-title>
                      {{ labels.tab2.shiftPreference }}
                    </v-card-title>
                    <v-card-text class="mt-5">
                      <v-select
                        v-bind="vStyle.input"
                        v-model="localCandidate.shiftPreference"
                        :items="shiftPreferencesOption"
                        item-title="label"
                        item-value="label"
                        :label="localCandidate.shiftIcon"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab job wish -->
              <v-row v-else-if="index === 2">
                <v-col cols="12" md="9">
                  <v-card-title>{{
                    labels.tab3.licensesHeadline
                  }}</v-card-title>
                </v-col>
                <v-col class="d-flex flex-row-reverse" cols="12" md="3">
                  <v-btn
                    v-bind="vStyle.btn"
                    color="success"
                    :disabled="!isStepThreeCompleted"
                    @click="completeStepThree()"
                  >
                    {{ labels.buttons.completeStepThree }}
                    <v-icon class="ml-2">fa-solid fa-circle-arrow-right</v-icon>
                  </v-btn>
                </v-col>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="6">
                  <v-card v-bind="vStyle.card" class="mb-4">
                    <v-card-title>{{ labels.tab3.licensesTitle }}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          v-for="license in driverLicenses"
                          :key="license.name"
                          cols="12"
                        >
                          <v-switch
                            v-model="license.selected"
                            :label="license.name"
                            @change="toggleSkill('Führerscheine', license)"
                            color="primary"
                            density="compact"
                            inset
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card v-bind="vStyle.card" class="mb-4">
                    >
                    <v-card-title>
                      {{ labels.tab3.otherSkillsTitle }}</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <v-col
                          v-for="misc in miscSkills"
                          :key="misc.name"
                          cols="12"
                        >
                          <v-switch
                            v-model="misc.selected"
                            :label="misc.name"
                            @change="
                              toggleSkill('Sonstiges aus Bewerberbogen', misc)
                            "
                            color="primary"
                            density="compact"
                            inset
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Tab information -->
              <v-row v-else-if="index === 3">
                <v-container
                  class="fill-height d-flex justify-center align-center"
                >
                  <v-card
                    v-bind="vStyle.card"
                    class="pa-10 text-center"
                    max-width="90%"
                  >
                    >
                    <v-card-title>
                      <h1 class="display-1 font-weight-bold">Vielen Dank</h1>
                    </v-card-title>
                    <v-card-title>
                      <h2 class="display-2 font-weight-medium">
                        {{ $store.state.company.loggedInUser.forename }}
                        {{ $store.state.company.loggedInUser.lastname }}
                      </h2>
                    </v-card-title>
                    <v-card-title>
                      <h2 class="subtitle-1">
                        wird mit Ihnen den Kennenlerntermin durchführen
                      </h2>
                    </v-card-title>
                  </v-card>
                </v-container>
              </v-row>
              <!--Tab skills & cv-->
              <v-row v-else-if="index === 4">
                <v-col cols="12">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-bind="vStyle.btn"
                      color="success"
                      @click="submitModal"
                      >Speichern & Schließen</v-btn
                    >
                  </v-card-actions>
                </v-col>
                <v-col cols="12" md="7">
                  <v-card-title class="d-flex"
                    >Lebenslauf <v-spacer></v-spacer>
                    <v-btn
                      @click="sortCvAsc"
                      density="compact"
                      size="small"
                      variant="text"
                    >
                      <v-icon> fa-solid fa-arrow-up </v-icon>
                    </v-btn>
                    <v-btn
                      @click="sortCvDesc"
                      icon
                      density="compact"
                      size="small"
                      variant="text"
                    >
                      <v-icon> fa-solid fa-arrow-down </v-icon>
                    </v-btn></v-card-title
                  >
                  <v-divider class="mb-3"></v-divider>
                  <v-col
                    cols="12"
                    v-for="(entry, index) in localCandidate.careerSteps"
                    :key="entry._id"
                  >
                    <v-card v-bind="vStyle.card" class="mb-4">
                      <v-card-title class="d-flex">
                        {{ entry.title }}
                        <v-spacer></v-spacer>
                        <v-btn icon variant="text" @click="removeEntry(index)">
                          <v-icon>fa-solid fa-xmark</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="entry.title"
                              label="Titel"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-select
                              v-bind="vStyle.input"
                              v-model="entry.type"
                              :items="employmentTypes"
                              item-title="description"
                              item-value="systemName"
                              label="Typ"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="entry.at"
                              label="Bei"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-bind="vStyle.input"
                              :value="
                                entry.from ? formatDateDisplay(entry.from) : ''
                              "
                              label="Von"
                              type="date"
                              density="compact"
                              class="my-0"
                              @change="
                              (event: any) =>
                                updateVonDate(entry, event.target.value)
                            "
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-bind="vStyle.input"
                              :value="
                                entry.until
                                  ? formatDateDisplay(entry.until)
                                  : ''
                              "
                              label="Bis"
                              type="date"
                              density="compact"
                              class="my-0"
                              @change="
                              (event: any) =>
                                updateBisDate(entry, event.target.value)
                            "
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-bind="vStyle.input"
                              v-model="entry.details"
                              label="Details"
                              rows="5"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          v-bind="vStyle.btn"
                          color="success"
                          @click="submitWithoutClose"
                          >Speichern</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card v-bind="vStyle.card" border="dashed">
                      <v-card-title>
                        Neuer Eintrag
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="newEntry.title"
                              label="Titel"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-select
                              v-bind="vStyle.input"
                              v-model="newEntry.type"
                              :items="employmentTypes"
                              item-title="description"
                              item-value="systemName"
                              label="Typ"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="newEntry.at"
                              label="Bei"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="newEntry.from"
                              label="Von"
                              type="date"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-bind="vStyle.input"
                              v-model="newEntry.until"
                              label="Bis"
                              type="date"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-bind="vStyle.input"
                              v-model="newEntry.details"
                              label="Details"
                              rows="5"
                              density="compact"
                              class="my-0"
                            />
                          </v-col>
                        </v-row>
                        <v-btn
                          v-bind="vStyle.btn"
                          block
                          color="success"
                          @click="addEntry"
                        >
                          >
                          <v-icon>fa-solid fa-calendar-plus</v-icon>
                          Neuer Eintrag hinzufügen
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="12" md="5">
                  <v-card-title>Skills</v-card-title>
                  <v-divider class="mb-3"></v-divider>

                  <v-col
                    cols="12"
                    v-for="group in skillGroups"
                    :key="group.groupDescription"
                  >
                    <v-card v-bind="vStyle.card" class="mb-4">
                      <v-card-title class="d-flex">
                        {{ group.groupDescription }}
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          variant="text"
                          @click="removeSkillGroup(group)"
                        >
                          <v-icon>fa-solid fa-xmark</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-chip-group
                              v-model="group.selectedSkills"
                              multiple
                              column
                            >
                              <v-chip
                                v-for="(skill, index) in group.skills"
                                :key="index"
                                color="primary"
                                :model-value="true"
                                close-icon="fa-solid fa-xmark"
                                :prepend-icon="
                                  getAuspraegungIcon(skill.level, skill.type)
                                "
                                closable
                                @click:close="removeSkill(group, index)"
                                @click="toggleAuspraegung(skill)"
                              >
                                {{ skill.description }}
                              </v-chip>
                              <v-text-field
                                label="Neuer Skill"
                                rounded="pill"
                                density="compact"
                                v-model="group.newSkill"
                                style="max-width: 12rem"
                                @keyup.enter="addSkill(group)"
                              ></v-text-field>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-card v-bind="vStyle.card" border="dashed">
                      <v-card-title>
                        Neue Skill-Gruppe
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          v-bind="vStyle.input"
                          label="Skill-Gruppenbezeichnung"
                          v-model="newSkillGroup.groupDescription"
                        ></v-text-field>

                        <v-text-field
                          v-bind="vStyle.input"
                          label="Erster Skill"
                          v-model="newSkillGroup.newSkill"
                        ></v-text-field>

                        <v-btn
                          v-bind="vStyle.btn"
                          block
                          color="success"
                          @click="addSkillGroup"
                          :disabled="
                            !newSkillGroup.groupDescription ||
                            !newSkillGroup.newSkill
                          "
                        >
                          <v-icon>fa-solid fa-plus</v-icon>
                          Hinzufügen
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {
  Candidate,
  CareerStep,
  CareerStepType,
  CommunicationMeans,
  Skill,
} from "@/models/candidate.model";
import { CandidateService } from "@/services/api/candidate.service";
import { CommunicationType } from "@/enums/communication-types.enum";
import { defineComponent, PropType } from "vue";
import {
  EmploymentType,
  EmploymentTypeSystemName,
} from "@/enums/employment-types.enum";
import { Mobility } from "@/enums/mobilities.enum";
import { Salutation } from "@/enums/salutation.enum";
import {
  ShiftPreferences,
  ShiftPreferencesIcon,
} from "@/enums/shift-preferences.enum";
import moment from "moment";
import ToastService from "@/services/toast.service";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import WelcomeVideo from "./WelcomeVideo.vue";
import { Languages } from "@/enums/languages.enum";
import { Routes } from "@/enums/routes.enum";
import { changeMobilityIcon } from "../../../helper/mobility-icon.helper";
import { CandidateDataHelper } from "../../../helper/candidate-data.helper";

export default defineComponent({
  name: "ApplicationForm",
  emits: ["submit"],
  components: {
    WelcomeVideo,
  },
  props: {
    candidate: {
      type: Object as PropType<Candidate>,
    },
    isStatusInterview: {
      type: Boolean,
    },
  },
  data() {
    return {
      labels: {
        buttons: {
          completeStepOne: "Speichern & weiter",
          completeStepTwo: "Speichern & weiter",
          completeStepThree: "Speichern & weiter",
        },
        tabnames: [
          "Allgemein",
          "Ihre neue Beschäftigung",
          "Führerscheine & Sonstiges",
          "Über uns",
          "Skills & Lebenslauf",
        ],
        tab1: {
          generalDataHeadline: "Allgemeine Daten",
          salutation: "Anrede",
          firstName: "Vorname",
          lastName: "Nachname",
          birthDate: "Geburtsdatum",
          birthPlace: "Geburtsort",
          maritalStatus: "Familienstand",
          birthName: "Geburtsname",
          address: "Adresse",
          street: "Straße",
          houseNumber: "Hausnummer",
          postalCode: "PLZ",
          city: "Ort",
          contactHeadline: "Wie können wir Sie erreichen?",
          type: "Typ",
          contactValue: "Nummer / Adresse",
          description: "Beschreibung",
          addCommunication: "Neue Nummer oder Adresse hinzufügen",
        },
        tab2: {
          mobilityAndShiftsHeadline: "Mobilität & Schichtbereitschaft",
          workPermitHeadline: "Dürfen Sie arbeiten?",
          nationality: "Nationalität",
          workPermitUntil: "Arbeitserlaubnis bis",
          workPermitOptions: [
            "nicht erforderlich",
            "unbefristed",
            "befristed bis [Datum]",
          ],
          newJobHeadline: "Ihr Wunsch ist unser Auftrag ...",
          workAsQuestion: "Als was möchten Sie arbeiten?",
          expectedSalary: "realistischer Verdienst",
          salaryCurrency: "Währung",
          salaryPeriod: "Zeitraum",
          mobilityRadiusQuestion: "Wie weit würden Sie fahren?",
          mobilityMeans: "mit was würden Sie fahren?",
          shiftPreference: "Welche Schichten würden Sie arbeiten?",
        },
        tab3: {
          licensesAndOthers: "Führerscheine & Sonstiges",
          licensesHeadline: "Was bringen Sie sonst noch mit?",
          licensesTitle: "Führerscheine und Lizenzen",
          otherSkillsTitle: "Sonstiges",
        },
        misc: {
          licenseName: "Lizenz",
          miscName: "Sonstiges",
        },
      },
      language: Languages.German,
      activeTab: 0,
      isDispatcherMode: false,
      showModal: false,
      showWelcomeVideo: false,
      tabs: [
        "Allgemein",
        "Ihre neue Beschäftigung",
        "Führerscheine & Sonstiges",
        "Über uns",
        "Skills & Lebenslauf",
      ],
      localCandidate: {} as Candidate,
      driverLicenses: [
        { name: "Klasse B", selected: false },
        { name: "Klasse C", selected: false },
        { name: "Klasse CE", selected: false },
        { name: "Personenbeförderungsschein", selected: false },
        { name: "Gabelstaplerschein", selected: false },
        { name: "Kranschein", selected: false },
        { name: "Schweißerpass", selected: false },
      ],
      miscSkills: [
        { name: "Möchte im Team arbeiten", selected: false },
        { name: "Möchte Verantwortung übernehmen", selected: false },
        { name: "Ausbilderschein (ADA)", selected: false },
        { name: "Ersthelfer", selected: false },
        { name: "Brandschutzbeauftragter", selected: false },
        { name: "Vorstrafen", selected: false },
        { name: "Schwerbehindert", selected: false },
      ],
      employmentTypes:
        (CandidateDataHelper.careerStepTypeObjects as CareerStepType[]) ??
        ([] as CareerStepType[]),
      newEntry: {
        title: "",
        at: "",
        type: {
          description: "Anstellung",
          systemName: EmploymentTypeSystemName.employed,
          sortOrder: EmploymentType.employed,
        },
        from: "",
        until: "",
        details: "",
      },
      newSkillGroup: {
        groupDescription: "",
        newSkill: "",
      } as any,
      newCommunicationMethod: {
        type: null,
        description: "",
        value: "",
      },
      mobilityDistance: 0,
      shiftPreferences: [
        ShiftPreferences.TwoShift,
        ShiftPreferences.ThreeShift,
        ShiftPreferences.FourShift,
        ShiftPreferences.FiveShift,
        ShiftPreferences.Morning,
        ShiftPreferences.Afternoon,
        ShiftPreferences.DayShift,
        ShiftPreferences.PermanentNightShift,
      ],
      mobilities: [Mobility.OwnCar, Mobility.PublicTransport] as string[],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    formattedBirthDate: {
      get() {
        return this.localCandidate.birthDate
          ? moment(this.localCandidate.birthDate).format("YYYY-MM-DD")
          : "";
      },
      set(newValue: any) {
        this.localCandidate.birthDate = newValue;
      },
    },
    languages() {
      return getEnumOptions(Languages);
    },
    maritalStatus() {
      return getEnumOptions(MaritalStatus);
    },
    salutations() {
      return getEnumOptions(Salutation);
    },
    shiftPreferencesOption() {
      return getEnumOptions(ShiftPreferences);
    },
    communicationTypes() {
      const labels: { [key in CommunicationType]: string } = {
        [CommunicationType.Phone]: "Telefon",
        [CommunicationType.Mobile]: "Mobiltelefon",
        [CommunicationType.Fax]: "Fax",
        [CommunicationType.Email]: "E-Mail",
        [CommunicationType.Facebook]: "Facebook",
        [CommunicationType.LinkedIn]: "LinkedIn",
        [CommunicationType.Twitter]: "Twitter",
        [CommunicationType.Xing]: "Xing",
        [CommunicationType.Website]: "Webseite",
        [CommunicationType.Skype]: "Skype",
        [CommunicationType.WorkMobile]: "Diensthandy",
        [CommunicationType.WhatsApp]: "WhatsApp",
        [CommunicationType.EmergencyContact]: "Notfallkontakt",
      };

      return Object.keys(CommunicationType)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => ({
          label: labels[key as unknown as CommunicationType],
          value: Number(key),
        }));
    },
    skillGroups() {
      const groups = this.localCandidate.skills.reduce(
        (acc: any, skill: Skill) => {
          if (
            skill.groupDescription !== "Mobilitäten" &&
            skill.groupDescription !== "Schichtbereitschaft" &&
            skill.groupDescription !== "Führerscheine" &&
            skill.groupDescription !== "Sonstiges aus Bewerberbogen"
          ) {
            const group = acc.find(
              (g: any) => g.groupDescription === skill.groupDescription
            );
            if (group) {
              group.skills.push(skill);
            } else {
              acc.push({
                groupDescription: skill.groupDescription,
                skills: [skill],
                newSkill: "",
              });
            }
          }
          return acc;
        },
        []
      );
      return groups;
    },
    skillGroupsWithRequired() {
      const groups: {
        groupDescription: string;
        skills: Skill[];
        newSkill: string;
      }[] = [];

      const licencesSkills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.groupDescription === "Führerscheine"
      );

      let licencesGroup = {
        groupDescription: "Führerscheine",
        skills: licencesSkills,
        newSkill: "",
      };
      groups.push(licencesGroup);

      const miscSkills = this.localCandidate.skills.filter(
        (skill: Skill) =>
          skill.groupDescription === "Sonstiges aus Bewerberbogen"
      );

      let miscGroup = {
        groupDescription: "Sonstiges aus Bewerberbogen",
        skills: miscSkills,
        newSkill: "",
      };
      groups.push(miscGroup);

      return groups;
    },
  },
  watch: {
    candidate: {
      handler(newVal) {
        this.localCandidate = { ...newVal } as Candidate;
        if (!this.localCandidate.communicationMeans) {
          this.localCandidate.communicationMeans = [];
        }

        const communicationMeansTypes =
          this.localCandidate.communicationMeans.map((item) => item.type);

        if (!communicationMeansTypes.includes(CommunicationType.Email)) {
          this.localCandidate.communicationMeans.push({
            type: CommunicationType.Email,
            description: "E-Mail",
            value: "",
          });
        }

        if (!communicationMeansTypes.includes(CommunicationType.Mobile)) {
          this.localCandidate.communicationMeans.push({
            type: CommunicationType.Mobile,
            description: "Mobiltelefon",
            value: "",
          });
        }
        this.initializeSwitches();

        if (
          !this.localCandidate.careerSteps ||
          this.localCandidate.careerSteps.length === 0
        )
          return;

        this.localCandidate.careerSteps.forEach((entry) => {
          entry.from = entry.from
            ? moment(entry.from).format("YYYY-MM-DD")
            : "";
          entry.until = entry.until
            ? moment(entry.until).format("YYYY-MM-DD")
            : "";
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    addCommunicationMethod() {
      if (
        this.newCommunicationMethod.type !== null &&
        this.newCommunicationMethod.description &&
        this.newCommunicationMethod.value
      ) {
        this.localCandidate.communicationMeans.push({
          description: this.newCommunicationMethod.description,
          type: this.newCommunicationMethod.type as CommunicationType,
          value: this.newCommunicationMethod.value,
        } as CommunicationMeans);
        this.newCommunicationMethod = {
          type: null,
          description: "",
          value: "",
        };
      }
    },
    addEntry() {
      if (this.newEntry.title && this.newEntry.at) {
        const selectedType = this.newEntry.type;

        this.localCandidate.careerSteps.push({
          degree: "",
          title: this.newEntry.title,
          at: this.newEntry.at,
          type: {
            description: selectedType.description,
            systemName: this.getEnumKeyByEnumValue(
              EmploymentType,
              selectedType.systemName
            ),
            sortOrder: selectedType.sortOrder,
          },
          from: moment(this.newEntry.from).format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]"
          ),
          until: moment(this.newEntry.until).format(
            "YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]"
          ),
          details: this.newEntry.details,
        } as CareerStep);

        this.newEntry = {
          title: "",
          at: "",
          type: this.employmentTypes[0],
          from: "",
          until: "",
          details: "",
        };
      }
    },
    addSkill(group: any) {
      if (group.newSkill) {
        const newSkill: Skill = {
          type: 2,
          level: 5,
          description: group.newSkill,
          groupDescription: group.groupDescription,
        };
        this.localCandidate.skills.push(newSkill);
        group.skills.push(newSkill);
        group.newSkill = "";
      }
    },
    addSkillGroup() {
      if (this.newSkillGroup.groupDescription && this.newSkillGroup.newSkill) {
        const newGroup = {
          groupDescription: this.newSkillGroup.groupDescription,
          skills: [
            {
              type: 2,
              level: 5,
              description: this.newSkillGroup.newSkill,
              groupDescription: this.newSkillGroup.groupDescription,
            },
          ],
          newSkill: "",
        };
        this.skillGroups.push(newGroup);
        this.localCandidate.skills.push({
          type: 2,
          level: 5,
          description: this.newSkillGroup.newSkill,
          groupDescription: this.newSkillGroup.groupDescription,
        });
        this.newSkillGroup = {
          groupDescription: "",
          newSkill: "",
        };
      }
    },
    closeModal() {
      this.showModal = false;
      if (!this.isDispatcherMode) this.$router.push(Routes.logout);
    },
    completeStepOne() {
      this.activeTab = 1;
      ToastService.showSuccess("Schritt 1 von 3 abgeschlossen");
      this.submitWithoutClose();
    },
    completeStepTwo() {
      this.activeTab = 2;
      ToastService.showSuccess("Schritt 2 von 3 abgeschlossen");
      this.submitWithoutClose();
    },
    completeStepThree() {
      this.activeTab = 3;
      ToastService.showSuccess("Schritt 3 von 3 abgeschlossen");
      this.submitWithoutClose();
    },
    formatDateDisplay(date: string): string {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    getAuspraegungIcon(auspraegung: number | null, art: number | null): string {
      if (art === 1) {
        if (auspraegung === 1) return "fa-solid fa-user-xmark";
        if (auspraegung === 2) return "fa-solid fa-user-check";
        if (auspraegung === 3) return "fa-solid fa-user-graduate";
      } else if (art === 2) {
        if (auspraegung === 4) return "fa-solid fa-xmark";
        if (auspraegung === 5) return "fa-solid fa-check";
      }
      return "";
    },
    getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
      let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
      return keys.length > 0 ? keys[0] : "";
    },
    getShiftIcon(shiftPreference: ShiftPreferences) {
      switch (shiftPreference) {
        case ShiftPreferences.TwoShift:
          return ShiftPreferencesIcon.TwoShift;
        case ShiftPreferences.ThreeShift:
          return ShiftPreferencesIcon.ThreeShift;
        case ShiftPreferences.FourShift:
          return ShiftPreferencesIcon.FourShift;
        case ShiftPreferences.FiveShift:
          return ShiftPreferencesIcon.FiveShift;
        case ShiftPreferences.Morning:
          return ShiftPreferencesIcon.Morning;
        case ShiftPreferences.Afternoon:
          return ShiftPreferencesIcon.Afternoon;
        case ShiftPreferences.DayShift:
          return ShiftPreferencesIcon.DayShift;
        case ShiftPreferences.PermanentNightShift:
          return ShiftPreferencesIcon.PermanentNightShift;
        default:
          return ShiftPreferencesIcon.None;
      }
    },
    initializeSwitches() {
      if (!this.localCandidate._id) return;
      this.driverLicenses.forEach((license) => {
        const exists = this.localCandidate.skills.find(
          (skill: Skill) =>
            skill.description === license.name &&
            skill.groupDescription === "Führerscheine"
        );
        license.selected = !!exists;
      });

      this.miscSkills.forEach((misc) => {
        const exists = this.localCandidate.skills.find(
          (skill: Skill) =>
            skill.description === misc.name &&
            skill.groupDescription === "Sonstiges aus Bewerberbogen"
        );
        misc.selected = !!exists;
      });
    },
    isStepOneCompleted() {
      if (
        this.localCandidate.salutationCatalogId &&
        this.localCandidate.firstName &&
        this.localCandidate.lastName &&
        this.localCandidate.birthDate &&
        this.maritalStatus &&
        this.localCandidate.addressStreet &&
        this.localCandidate.addressHouseNumber &&
        this.localCandidate.addressPostalCode &&
        this.localCandidate.addressCity &&
        this.localCandidate.communicationMeans[0].value
      ) {
        return true;
      } else {
        return false;
      }
    },
    isStepTwoCompleted() {
      if (
        this.localCandidate.applications[
          this.localCandidate.applications.length - 1
        ].appliedAs &&
        this.localCandidate.applications[
          this.localCandidate.applications.length - 1
        ].salary &&
        this.localCandidate.mobility &&
        this.localCandidate.mobilityRadius &&
        this.localCandidate.shiftPreference &&
        this.localCandidate.nationality &&
        this.localCandidate.workPermitUntil
      ) {
        return true;
      } else {
        return false;
      }
    },
    isStepThreeCompleted() {
      return true;
    },
    onMobilityChange(newMobility: Mobility) {
      this.localCandidate.mobilityIcon = changeMobilityIcon(newMobility);
    },
    openModal(dispatcherMode?: boolean) {
      this.showWelcomeVideo = false;
      if (dispatcherMode) {
        this.isDispatcherMode = true;
      } else {
        this.isDispatcherMode = false;
      }
      this.showModal = true;
    },
    openWelcomeVideo() {
      this.showWelcomeVideo = true;
    },
    removeCommunicationMethod(index: number) {
      this.localCandidate.communicationMeans.splice(index, 1);
    },
    removeEntry(index: number) {
      this.localCandidate.careerSteps.splice(index, 1);
    },
    removeSkill(group: any, index: number) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.description !== group.skills[index].description
      );
      group.skills.splice(index, 1);
    },
    removeSkillGroup(group: any) {
      this.localCandidate.skills = this.localCandidate.skills.filter(
        (skill: Skill) => skill.groupDescription !== group.groupDescription
      );
      this.skillGroups = this.skillGroups.filter(
        (g: any) => g.groupDescription !== group.groupDescription
      );
    },
    async saveCandidate() {
      const apiService = new CandidateService();
      if (this.localCandidate) {
        await apiService.updateCandidate(this.localCandidate);
      }
    },
    sortCvAsc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : Infinity;
        return dateA - dateB;
      });
    },
    sortCvDesc() {
      this.localCandidate.careerSteps.sort((a, b) => {
        const dateA = a.from ? new Date(a.from).getTime() : -Infinity;
        const dateB = b.from ? new Date(b.from).getTime() : -Infinity;
        return dateB - dateA;
      });
    },
    submitWithoutClose() {
      this.saveCandidate().then(() => {
        this.$emit("submit");
      });
    },
    async submitModal() {
      this.saveCandidate().then(() => {
        this.$emit("submit");
        this.closeModal();
      });
    },
    toggleAuspraegung(skill: any) {
      if (skill.level === undefined || skill.level === null) {
        skill.level = 1;
        skill.type = 1;
      } else {
        skill.level++;
        if (skill.level > 5) {
          skill.level = 1;
        }
        if (skill.level >= 1 && skill.level <= 3) {
          skill.type = 1;
        } else if (skill.level >= 4 && skill.level <= 5) {
          skill.type = 2;
        }
      }
    },
    toggleSkill(
      groupDescription: string,
      skillItem: { name: string; selected: boolean }
    ) {
      const existingSkill = this.localCandidate.skills.find(
        (skill: Skill) =>
          skill.description === skillItem.name &&
          skill.groupDescription === groupDescription
      );

      if (skillItem.selected && !existingSkill) {
        this.localCandidate.skills.push({
          description: skillItem.name,
          groupDescription: groupDescription,
          type: 2,
          level: 5,
        } as Skill);
      } else if (!skillItem.selected && existingSkill) {
        this.localCandidate.skills = this.localCandidate.skills.filter(
          (skill: Skill) => skill.description !== skillItem.name
        );
      }
    },
    updateBisDate(entry: any, date: string) {
      entry.until = date
        ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]")
        : "";
    },
    updateVonDate(entry: any, date: string) {
      entry.from = date
        ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]")
        : "";
    },
    updateLanguage(language: Languages) {
      switch (language) {
        case Languages.English:
          this.labels = {
            buttons: {
              completeStepOne: "Save & continue",
              completeStepTwo: "Save & continue",
              completeStepThree: "Save & continue",
            },
            tabnames: [
              "General",
              "Your new job",
              "Licenses & Others",
              "About us",
              "Skills & Resume",
            ],
            tab1: {
              generalDataHeadline: "General Information",
              salutation: "Salutation",
              firstName: "First Name",
              lastName: "Last Name",
              birthDate: "Date of Birth",
              birthPlace: "Place of Birth",
              maritalStatus: "Marital Status",
              birthName: "Birth Name",
              address: "Address",
              street: "Street",
              houseNumber: "House Number",
              postalCode: "Postal Code",
              city: "City",
              contactHeadline: "How can we reach you?",
              type: "Type",
              contactValue: "Number / Address",
              description: "Description",
              addCommunication: "Add new number or address",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobility & Shift Preferences",
              workPermitHeadline: "Do you have a work permit?",
              nationality: "Nationality",
              workPermitUntil: "Work permit valid until",
              workPermitOptions: [
                "Not required",
                "Unlimited",
                "Limited until [date]",
              ],
              newJobHeadline: "Your wish is our command...",
              workAsQuestion: "What position are you applying for?",
              expectedSalary: "Expected Salary",
              salaryCurrency: "Currency",
              salaryPeriod: "Period",
              mobilityRadiusQuestion: "How far would you travel?",
              mobilityMeans: "How would you travel?",
              shiftPreference: "What shifts would you work?",
            },
            tab3: {
              licensesAndOthers: "Licenses & Others",
              licensesHeadline: "What other qualifications do you have?",
              licensesTitle: "Driver's Licenses & Certifications",
              otherSkillsTitle: "Other Skills",
            },
            misc: {
              licenseName: "License",
              miscName: "Other",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Turkish:
          this.labels = {
            buttons: {
              completeStepOne: "Kaydet & devam et",
              completeStepTwo: "Kaydet & devam et",
              completeStepThree: "Kaydet & devam et",
            },
            tabnames: [
              "Genel",
              "Yeni işiniz",
              "Ehliyetler ve Diğerleri",
              "Hakkımızda",
              "Beceriler ve Özgeçmiş",
            ],
            tab1: {
              generalDataHeadline: "Genel Bilgiler",
              salutation: "Hitap",
              firstName: "Ad",
              lastName: "Soyad",
              birthDate: "Doğum Tarihi",
              birthPlace: "Doğum Yeri",
              maritalStatus: "Medeni Durum",
              birthName: "Doğum Adı",
              address: "Adres",
              street: "Sokak",
              houseNumber: "Ev Numarası",
              postalCode: "Posta Kodu",
              city: "Şehir",
              contactHeadline: "Size nasıl ulaşabiliriz?",
              type: "Tür",
              contactValue: "Numara / Adres",
              description: "Açıklama",
              addCommunication: "Yeni numara veya adres ekle",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobilite & Vardiya Tercihleri",
              workPermitHeadline: "Çalışma izniniz var mı?",
              nationality: "Milliyet",
              workPermitUntil: "Çalışma izni geçerli olduğu tarih",
              workPermitOptions: [
                "Gerekli değil",
                "Süresiz",
                "[Tarih] kadar geçerli",
              ],
              newJobHeadline: "İsteğiniz bizim için emirdir...",
              workAsQuestion: "Hangi pozisyon için başvuruyorsunuz?",
              expectedSalary: "Beklenen Maaş",
              salaryCurrency: "Para Birimi",
              salaryPeriod: "Dönem",
              mobilityRadiusQuestion: "Ne kadar uzağa gidebilirsiniz?",
              mobilityMeans: "Nasıl seyahat edersiniz?",
              shiftPreference: "Hangi vardiyalarda çalışabilirsiniz?",
            },
            tab3: {
              licensesAndOthers: "Ehliyetler ve Diğerleri",
              licensesHeadline: "Başka hangi niteliklere sahipsiniz?",
              licensesTitle: "Sürücü Belgeleri ve Sertifikalar",
              otherSkillsTitle: "Diğer Beceriler",
            },
            misc: {
              licenseName: "Ehliyet",
              miscName: "Diğer",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Romanian:
          this.labels = {
            buttons: {
              completeStepOne: "Salvează și continuă",
              completeStepTwo: "Salvează și continuă",
              completeStepThree: "Salvează și continuă",
            },
            tabnames: [
              "General",
              "Noua ta slujbă",
              "Permise & Altele",
              "Despre noi",
              "Abilități & CV",
            ],
            tab1: {
              generalDataHeadline: "Informații generale",
              salutation: "Salut",
              firstName: "Prenume",
              lastName: "Nume",
              birthDate: "Data nașterii",
              birthPlace: "Locul nașterii",
              maritalStatus: "Stare civilă",
              birthName: "Nume de naștere",
              address: "Adresă",
              street: "Stradă",
              houseNumber: "Număr casă",
              postalCode: "Cod poștal",
              city: "Oraș",
              contactHeadline: "Cum te putem contacta?",
              type: "Tip",
              contactValue: "Număr / Adresă",
              description: "Descriere",
              addCommunication: "Adaugă un nou număr sau adresă",
            },
            tab2: {
              mobilityAndShiftsHeadline:
                "Mobilitate & Preferințe pentru schimburi",
              workPermitHeadline: "Ai un permis de muncă?",
              nationality: "Naționalitate",
              workPermitUntil: "Permis de muncă valabil până la",
              workPermitOptions: [
                "Nu este necesar",
                "Nelimitat",
                "Limitat până la [dată]",
              ],
              newJobHeadline: "Dorinta ta este porunca noastră...",
              workAsQuestion: "Ce poziție dorești să ocupi?",
              expectedSalary: "Salariu așteptat",
              salaryCurrency: "Monedă",
              salaryPeriod: "Perioadă",
              mobilityRadiusQuestion: "Cât de departe ai călători?",
              mobilityMeans: "Cu ce ai călători?",
              shiftPreference: "Ce schimburi ai prefera să lucrezi?",
            },
            tab3: {
              licensesAndOthers: "Permise & Altele",
              licensesHeadline: "Ce alte calificări ai?",
              licensesTitle: "Permise de conducere & Certificări",
              otherSkillsTitle: "Alte abilități",
            },
            misc: {
              licenseName: "Permis",
              miscName: "Altele",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Russian:
          this.labels = {
            buttons: {
              completeStepOne: "Сохранить и продолжить",
              completeStepTwo: "Сохранить и продолжить",
              completeStepThree: "Сохранить и продолжить",
            },
            tabnames: [
              "Общее",
              "Ваша новая работа",
              "Лицензии и другое",
              "О нас",
              "Навыки и резюме",
            ],
            tab1: {
              generalDataHeadline: "Общая информация",
              salutation: "Приветствие",
              firstName: "Имя",
              lastName: "Фамилия",
              birthDate: "Дата рождения",
              birthPlace: "Место рождения",
              maritalStatus: "Семейное положение",
              birthName: "Имя при рождении",
              address: "Адрес",
              street: "Улица",
              houseNumber: "Номер дома",
              postalCode: "Почтовый индекс",
              city: "Город",
              contactHeadline: "Как с вами связаться?",
              type: "Тип",
              contactValue: "Номер / Адрес",
              description: "Описание",
              addCommunication: "Добавить новый номер или адрес",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Мобильность и смены",
              workPermitHeadline: "Есть ли у вас разрешение на работу?",
              nationality: "Национальность",
              workPermitUntil: "Разрешение на работу действительно до",
              workPermitOptions: [
                "Не требуется",
                "Бессрочное",
                "Ограничено до [даты]",
              ],
              newJobHeadline: "Ваше желание - наш закон...",
              workAsQuestion: "На какую должность вы претендуете?",
              expectedSalary: "Ожидаемая зарплата",
              salaryCurrency: "Валюта",
              salaryPeriod: "Период",
              mobilityRadiusQuestion: "Как далеко вы готовы ездить?",
              mobilityMeans: "Чем вы будете добираться?",
              shiftPreference: "Какие смены вы готовы работать?",
            },
            tab3: {
              licensesAndOthers: "Лицензии и другое",
              licensesHeadline: "Какие еще у вас есть квалификации?",
              licensesTitle: "Водительские права и сертификаты",
              otherSkillsTitle: "Другие навыки",
            },
            misc: {
              licenseName: "Лицензия",
              miscName: "Другое",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Ukrainian:
          this.labels = {
            buttons: {
              completeStepOne: "Зберегти і продовжити",
              completeStepTwo: "Зберегти і продовжити",
              completeStepThree: "Зберегти і продовжити",
            },
            tabnames: [
              "Загальні відомості",
              "Ваша нова робота",
              "Ліцензії та інше",
              "Про нас",
              "Навички та резюме",
            ],
            tab1: {
              generalDataHeadline: "Загальна інформація",
              salutation: "Вітання",
              firstName: "Ім'я",
              lastName: "Прізвище",
              birthDate: "Дата народження",
              birthPlace: "Місце народження",
              maritalStatus: "Сімейний стан",
              birthName: "Ім'я при народженні",
              address: "Адреса",
              street: "Вулиця",
              houseNumber: "Номер будинку",
              postalCode: "Поштовий індекс",
              city: "Місто",
              contactHeadline: "Як з вами зв'язатися?",
              type: "Тип",
              contactValue: "Номер / Адреса",
              description: "Опис",
              addCommunication: "Додати новий номер або адресу",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Мобільність і зміни",
              workPermitHeadline: "Чи маєте ви дозвіл на роботу?",
              nationality: "Національність",
              workPermitUntil: "Дозвіл на роботу дійсний до",
              workPermitOptions: [
                "Не потрібно",
                "Безстроковий",
                "Обмежений до [дата]",
              ],
              newJobHeadline: "Ваше бажання - наш наказ...",
              workAsQuestion: "На яку посаду ви претендуєте?",
              expectedSalary: "Очікувана заробітна плата",
              salaryCurrency: "Валюта",
              salaryPeriod: "Період",
              mobilityRadiusQuestion: "Як далеко ви готові їздити?",
              mobilityMeans: "Як ви будете пересуватися?",
              shiftPreference: "Які зміни ви готові працювати?",
            },
            tab3: {
              licensesAndOthers: "Ліцензії та інше",
              licensesHeadline: "Які ще у вас є кваліфікації?",
              licensesTitle: "Водійські права та сертифікати",
              otherSkillsTitle: "Інші навички",
            },
            misc: {
              licenseName: "Ліцензія",
              miscName: "Інше",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Spanish:
          this.labels = {
            buttons: {
              completeStepOne: "Guardar y continuar",
              completeStepTwo: "Guardar y continuar",
              completeStepThree: "Guardar y continuar",
            },
            tabnames: [
              "General",
              "Tu nuevo trabajo",
              "Licencias y otros",
              "Sobre nosotros",
              "Habilidades y currículum",
            ],
            tab1: {
              generalDataHeadline: "Información general",
              salutation: "Saludo",
              firstName: "Nombre",
              lastName: "Apellido",
              birthDate: "Fecha de nacimiento",
              birthPlace: "Lugar de nacimiento",
              maritalStatus: "Estado civil",
              birthName: "Nombre de nacimiento",
              address: "Dirección",
              street: "Calle",
              houseNumber: "Número de casa",
              postalCode: "Código postal",
              city: "Ciudad",
              contactHeadline: "¿Cómo podemos contactarte?",
              type: "Tipo",
              contactValue: "Número / Dirección",
              description: "Descripción",
              addCommunication: "Añadir nuevo número o dirección",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Movilidad y turnos",
              workPermitHeadline: "¿Tienes permiso de trabajo?",
              nationality: "Nacionalidad",
              workPermitUntil: "Permiso de trabajo válido hasta",
              workPermitOptions: [
                "No requerido",
                "Ilimitado",
                "Limitado hasta [fecha]",
              ],
              newJobHeadline: "Tu deseo es nuestra orden...",
              workAsQuestion: "¿Para qué puesto estás aplicando?",
              expectedSalary: "Salario esperado",
              salaryCurrency: "Moneda",
              salaryPeriod: "Período",
              mobilityRadiusQuestion:
                "¿Hasta dónde estarías dispuesto a viajar?",
              mobilityMeans: "¿Cómo viajarías?",
              shiftPreference: "¿Qué turnos estarías dispuesto a trabajar?",
            },
            tab3: {
              licensesAndOthers: "Licencias y otros",
              licensesHeadline: "¿Qué otras cualificaciones tienes?",
              licensesTitle: "Licencias de conducir y certificaciones",
              otherSkillsTitle: "Otras habilidades",
            },
            misc: {
              licenseName: "Licencia",
              miscName: "Otro",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Italian:
          this.labels = {
            buttons: {
              completeStepOne: "Salva e continua",
              completeStepTwo: "Salva e continua",
              completeStepThree: "Salva e continua",
            },
            tabnames: [
              "Generale",
              "Il tuo nuovo lavoro",
              "Patenti e altri",
              "Su di noi",
              "Competenze e curriculum",
            ],
            tab1: {
              generalDataHeadline: "Informazioni generali",
              salutation: "Saluto",
              firstName: "Nome",
              lastName: "Cognome",
              birthDate: "Data di nascita",
              birthPlace: "Luogo di nascita",
              maritalStatus: "Stato civile",
              birthName: "Nome alla nascita",
              address: "Indirizzo",
              street: "Via",
              houseNumber: "Numero civico",
              postalCode: "Codice postale",
              city: "Città",
              contactHeadline: "Come possiamo contattarti?",
              type: "Tipo",
              contactValue: "Numero / Indirizzo",
              description: "Descrizione",
              addCommunication: "Aggiungi nuovo numero o indirizzo",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobilità e turni",
              workPermitHeadline: "Hai un permesso di lavoro?",
              nationality: "Nazionalità",
              workPermitUntil: "Permesso di lavoro valido fino a",
              workPermitOptions: [
                "Non richiesto",
                "Illimitato",
                "Limitato fino a [data]",
              ],
              newJobHeadline: "Il tuo desiderio è il nostro comando...",
              workAsQuestion: "Per quale posizione stai facendo domanda?",
              expectedSalary: "Salario previsto",
              salaryCurrency: "Valuta",
              salaryPeriod: "Periodo",
              mobilityRadiusQuestion:
                "Fino a dove saresti disposto a viaggiare?",
              mobilityMeans: "Con quale mezzo viaggeresti?",
              shiftPreference: "Quali turni saresti disposto a lavorare?",
            },
            tab3: {
              licensesAndOthers: "Patenti e altri",
              licensesHeadline: "Quali altre qualifiche possiedi?",
              licensesTitle: "Patenti di guida e certificazioni",
              otherSkillsTitle: "Altre competenze",
            },
            misc: {
              licenseName: "Patente",
              miscName: "Altro",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.French:
          this.labels = {
            buttons: {
              completeStepOne: "Sauvegarder et continuer",
              completeStepTwo: "Sauvegarder et continuer",
              completeStepThree: "Sauvegarder et continuer",
            },
            tabnames: [
              "Général",
              "Votre nouveau travail",
              "Permis & autres",
              "À propos de nous",
              "Compétences & CV",
            ],
            tab1: {
              generalDataHeadline: "Informations générales",
              salutation: "Salutation",
              firstName: "Prénom",
              lastName: "Nom de famille",
              birthDate: "Date de naissance",
              birthPlace: "Lieu de naissance",
              maritalStatus: "État civil",
              birthName: "Nom de naissance",
              address: "Adresse",
              street: "Rue",
              houseNumber: "Numéro de maison",
              postalCode: "Code postal",
              city: "Ville",
              contactHeadline: "Comment pouvons-nous vous joindre?",
              type: "Type",
              contactValue: "Numéro / Adresse",
              description: "Description",
              addCommunication: "Ajouter un nouveau numéro ou une adresse",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobilité & Préférences de travail",
              workPermitHeadline: "Avez-vous un permis de travail?",
              nationality: "Nationalité",
              workPermitUntil: "Permis de travail valable jusqu'au",
              workPermitOptions: [
                "Non requis",
                "Illimité",
                "Limité jusqu'à [date]",
              ],
              newJobHeadline: "Votre souhait est notre commande...",
              workAsQuestion: "Pour quel poste postulez-vous?",
              expectedSalary: "Salaire attendu",
              salaryCurrency: "Devise",
              salaryPeriod: "Période",
              mobilityRadiusQuestion: "Jusqu'où seriez-vous prêt à voyager?",
              mobilityMeans: "Comment vous déplaceriez-vous?",
              shiftPreference: "Quelles équipes seriez-vous prêt à travailler?",
            },
            tab3: {
              licensesAndOthers: "Permis & autres",
              licensesHeadline: "Quelles autres qualifications avez-vous?",
              licensesTitle: "Permis de conduire & Certifications",
              otherSkillsTitle: "Autres compétences",
            },
            misc: {
              licenseName: "Permis",
              miscName: "Autre",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Arabic:
          this.labels = {
            buttons: {
              completeStepOne: "احفظ وتابع",
              completeStepTwo: "احفظ وتابع",
              completeStepThree: "احفظ وتابع",
            },
            tabnames: [
              "عام",
              "وظيفتك الجديدة",
              "التراخيص وغيرها",
              "حولنا",
              "المهارات والسيرة الذاتية",
            ],
            tab1: {
              generalDataHeadline: "معلومات عامة",
              salutation: "التحية",
              firstName: "الاسم الأول",
              lastName: "اسم العائلة",
              birthDate: "تاريخ الميلاد",
              birthPlace: "مكان الميلاد",
              maritalStatus: "الحالة الاجتماعية",
              birthName: "الاسم عند الولادة",
              address: "العنوان",
              street: "الشارع",
              houseNumber: "رقم المنزل",
              postalCode: "الرمز البريدي",
              city: "المدينة",
              contactHeadline: "كيف يمكننا التواصل معك؟",
              type: "النوع",
              contactValue: "الرقم / العنوان",
              description: "الوصف",
              addCommunication: "أضف رقمًا جديدًا أو عنوانًا",
            },
            tab2: {
              mobilityAndShiftsHeadline: "التنقل وتفضيلات الورديات",
              workPermitHeadline: "هل لديك تصريح عمل؟",
              nationality: "الجنسية",
              workPermitUntil: "تصريح العمل صالح حتى",
              workPermitOptions: [
                "غير مطلوب",
                "غير محدود",
                "محدود حتى [تاريخ]",
              ],
              newJobHeadline: "أمنيتك هي أوامرنا...",
              workAsQuestion: "ما الوظيفة التي تتقدم لها؟",
              expectedSalary: "الراتب المتوقع",
              salaryCurrency: "العملة",
              salaryPeriod: "الفترة",
              mobilityRadiusQuestion: "إلى أي مدى ترغب في السفر؟",
              mobilityMeans: "كيف ستسافر؟",
              shiftPreference: "ما هي الورديات التي ترغب في العمل بها؟",
            },
            tab3: {
              licensesAndOthers: "التراخيص وغيرها",
              licensesHeadline: "ما المؤهلات الأخرى التي لديك؟",
              licensesTitle: "رخص القيادة والشهادات",
              otherSkillsTitle: "مهارات أخرى",
            },
            misc: {
              licenseName: "الرخصة",
              miscName: "أخرى",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        case Languages.Albanian:
          this.labels = {
            buttons: {
              completeStepOne: "Ruaj & vazhdo",
              completeStepTwo: "Ruaj & vazhdo",
              completeStepThree: "Ruaj & vazhdo",
            },
            tabnames: [
              "Të përgjithshme",
              "Puna juaj e re",
              "Lejet & të tjera",
              "Rreth nesh",
              "Aftësitë & CV-ja",
            ],
            tab1: {
              generalDataHeadline: "Të dhëna të përgjithshme",
              salutation: "Përshëndetje",
              firstName: "Emri",
              lastName: "Mbiemri",
              birthDate: "Data e lindjes",
              birthPlace: "Vendi i lindjes",
              maritalStatus: "Gjendja civile",
              birthName: "Emri i lindjes",
              address: "Adresa",
              street: "Rruga",
              houseNumber: "Numri i shtëpisë",
              postalCode: "Kodi postar",
              city: "Qyteti",
              contactHeadline: "Si mund të kontaktojmë?",
              type: "Lloji",
              contactValue: "Numri / Adresa",
              description: "Përshkrimi",
              addCommunication: "Shto numër ose adresë të re",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobiliteti & Ndërrimet",
              workPermitHeadline: "A keni leje pune?",
              nationality: "Kombësia",
              workPermitUntil: "Leja e punës deri",
              workPermitOptions: [
                "Nuk nevojitet",
                "E pakufizuar",
                "E kufizuar deri më [data]",
              ],
              newJobHeadline: "Dëshira juaj është urdhri ynë...",
              workAsQuestion: "Për çfarë pozicioni po aplikoni?",
              expectedSalary: "Paga e pritur",
              salaryCurrency: "Valuta",
              salaryPeriod: "Periudha",
              mobilityRadiusQuestion: "Sa larg do të udhëtonit?",
              mobilityMeans: "Si do të udhëtonit?",
              shiftPreference: "Cilat ndërrime do të punonit?",
            },
            tab3: {
              licensesAndOthers: "Lejet & të tjera",
              licensesHeadline: "Çfarë kualifikimesh të tjera keni?",
              licensesTitle: "Leje Drejtimi & Certifikata",
              otherSkillsTitle: "Aftësi të tjera",
            },
            misc: {
              licenseName: "Leja",
              miscName: "Të tjera",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
        default:
          this.labels = {
            buttons: {
              completeStepOne: "Speichern & weiter",
              completeStepTwo: "Speichern & weiter",
              completeStepThree: "Speichern & weiter",
            },
            tabnames: [
              "Allgemein",
              "Ihre neue Beschäftigung",
              "Führerscheine & Sonstiges",
              "Über uns",
              "Skills & Lebenslauf",
            ],
            tab1: {
              generalDataHeadline: "Allgemeine Daten",
              salutation: "Anrede",
              firstName: "Vorname",
              lastName: "Nachname",
              birthDate: "Geburtsdatum",
              birthPlace: "Geburtsort",
              maritalStatus: "Familienstand",
              birthName: "Geburtsname",
              address: "Adresse",
              street: "Straße",
              houseNumber: "Hausnummer",
              postalCode: "PLZ",
              city: "Ort",
              contactHeadline: "Wie können wir Sie erreichen?",
              type: "Typ",
              contactValue: "Nummer / Adresse",
              description: "Beschreibung",
              addCommunication: "Neue Nummer oder Adresse hinzufügen",
            },
            tab2: {
              mobilityAndShiftsHeadline: "Mobilität & Schichtbereitschaft",
              workPermitHeadline: "Dürfen Sie arbeiten?",
              nationality: "Nationalität",
              workPermitUntil: "Arbeitserlaubnis bis",
              workPermitOptions: [
                "nicht erforderlich",
                "unbefristed",
                "befristed bis [Datum]",
              ],
              newJobHeadline: "Ihr Wunsch ist unser Auftrag ...",
              workAsQuestion: "Als was möchten Sie arbeiten?",
              expectedSalary: "realistischer Verdienst",
              salaryCurrency: "Währung",
              salaryPeriod: "Zeitraum",
              mobilityRadiusQuestion: "Wie weit würden Sie fahren?",
              mobilityMeans: "mit was würden Sie fahren?",
              shiftPreference: "Welche Schichten würden Sie arbeiten?",
            },
            tab3: {
              licensesAndOthers: "Führerscheine & Sonstiges",
              licensesHeadline: "Was bringen Sie sonst noch mit?",
              licensesTitle: "Führerscheine und Lizenzen",
              otherSkillsTitle: "Sonstiges",
            },
            misc: {
              licenseName: "Lizenz",
              miscName: "Sonstiges",
            },
          };
          this.tabs = this.labels.tabnames;
          break;
      }
    },
  },
});
</script>

<style scoped>
.job-ad {
  cursor: pointer;
}

.job-ad:hover {
  background-color: var(--v-list-item-hover-background-color);
}

.button-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.fill-height {
  min-height: 90vh;
}
</style>
