import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-caption text-grey--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_autoscroll = _resolveDirective("autoscroll")!

  return (_ctx.chatMessages && _ctx.chatMessages.length > 0)
    ? _withDirectives((_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        class: "mx-0 pa-0 mb-1 chat-messages",
        fluid: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "ma-0 pa-0 border-md rounded-md chat-card" }), {
            default: _withCtx(() => [
              _createVNode(_component_v_card_subtitle, null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Chat Nachrichten ")
                ])),
                _: 1
              }),
              (_ctx.chatMessages.length === 0)
                ? (_openBlock(), _createBlock(_component_v_card_text, {
                    key: 0,
                    class: "spinner-container scrollable-messages"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "primary",
                        size: "300",
                        width: "16",
                        class: "slow-spinner"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatMessages, (msg) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: msg._id,
                      class: "message-container"
                    }, [
                      _createVNode(_component_v_card, {
                        class: _normalizeClass({
              'message-from-me': _ctx.isFromMe(msg),
              'message-from-them': !_ctx.isFromMe(msg),
            })
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_subtitle, null, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(msg.message), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("small", _hoisted_1, _toDisplayString(_ctx.formatTimestamp(msg.timestamp as string)), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      })), [
        [_directive_autoscroll, '.v-card']
      ])
    : _createCommentVNode("", true)
}