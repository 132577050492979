import moment from "moment";

export class FormatDate {
  static germanStandard(date: string) {
    return date ? moment(date).format("DD.MM.YYYY") : "";
  }
  static germanStandardWithTime(date: string) {
    return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
  }
  static germanShort(date: string) {
    return date ? moment(date).format("DD.MM.YY") : "";
  }
  static standard(date: string) {
    return date ? moment(date).format("YYYY-MM-DD") : "";
  }
  static toISOString(date: string) {
    return date ? moment(date).toISOString() : "";
  }
}
