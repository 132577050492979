// services/zorst.service.ts

import {
  ZorstMessage,
  ZorstStandardResponse,
  ZvooveRecruitAddDocu,
} from "@/models/zorst.model";
import ToastService from "./toast.service";
import store from "../store/store";
import DialogService from "./dialog.service";
import { VuetifyColor } from "../plugins/vuetify";
import { ZorstErrorType, ZorstMessageType } from "../enums/zorst.enum";
const zorstDeactivatedMessage = "ZORST ist ausgeschaltet";
const zorstInaktiveMessage =
  "ZORST Erweiterung ist in diesem Browser nicht aktiv";

export class ZorstService {
  private static instance: ZorstService;

  // Singleton-Instanz
  public static getInstance(): ZorstService {
    if (!ZorstService.instance) {
      ZorstService.instance = new ZorstService();
    }
    return ZorstService.instance;
  }
  private checkUseZorst(): boolean {
    if (!store.state.company.softwareIntegration.zorst) {
      ToastService.show(zorstDeactivatedMessage);
      return false;
    } else if (!store.state.zorstAlive) {
      //ToastService.show(zorstInaktiveMessage);
      return false;
    }
    return true;
  }

  private handleZorstResponse(
    event: MessageEvent,
    resolve: (value: any) => void,
    reject: (reason: any) => void,
    timeoutId: NodeJS.Timeout,
    message: ZorstMessage,
    handleResponse: (event: MessageEvent) => void
  ): void {
    if (event.data.type === ZorstMessageType.answer) {
      const response: ZorstStandardResponse = event.data;
      if (response.error) {
        if (response.error.includes(ZorstErrorType.loginNotFound)) {
          DialogService.confirm(
            "Du bist nicht im Drittanbieter System eingeloggt. Logge Dich zuerst ein bevor wir weiter machen.",
            "Abbrechen",
            "Ich bin jetzt eingeloggt",
            "Login im Drittanbieter System erforderlich",
            VuetifyColor.abort,
            VuetifyColor.success
          ).then((confirmed) => {
            if (confirmed) {
              window.removeEventListener("message", handleResponse);
              window.addEventListener("message", handleResponse);
              window.postMessage(message, "*");
              return;
            } else {
              reject({ error: "Login ins ATS System abgebrochen" });
              window.removeEventListener("message", handleResponse);
              clearTimeout(timeoutId);
              return;
            }
          });
          return;
        } else {
          reject(response);
        }
      } else if (response.success) {
        ToastService.show(response.success);
        resolve(response);
      } else if (response.payload) {
        resolve(response);
      }

      clearTimeout(timeoutId);
      window.removeEventListener("message", handleResponse);
    }
  }
  public async ZorstChangeRecruitCandidateStatus(
    candidateLink: string,
    newStatus: string
  ): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }

    return new Promise((resolve, reject) => {
      const message: ZorstMessage = {
        type: ZorstMessageType.changeStatus,
        candidateLink: candidateLink,
        targetStatus: newStatus,
        debugMode: store.state.zorstDebugging,
      };

      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Drittanbieter Software";
        reject({ error: errorMsg });
      }, 30000);
    });
  }
  public ZorstAddZvooveRecruitDocu(
    message: ZvooveRecruitAddDocu
  ): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Drittanbieter Software";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 45000);
    });
  }
  public ZorstPublishJobAdInZvooveRecruit(
    jobAdData: {
      openJobAdLink: string;
      categoriesBfA: string[];
      salary: string;
      placement: string;
      collectiveAgreement: string;
      timemodel?: any;
    },
    supportedJobAd: boolean
  ): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const categoriesBfA = jobAdData.categoriesBfA.map((category) =>
        category.toString()
      );
      const message = {
        type: ZorstMessageType.publishAtsRecruitJobAd,
        openJobAdLink: jobAdData.openJobAdLink,
        categoriesBfA,
        salary: jobAdData.salary,
        placement: jobAdData.placement,
        collectiveAgreement: jobAdData.collectiveAgreement,
        supportedJobAd,
        debugMode: store.state.zorstDebugging,
        ...(jobAdData.timemodel && { timemodel: jobAdData.timemodel }),
      };

      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Veröffentlichen der Stellenausschreibung in der Drittanbieter Software";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000);
    });
  }
  public ZorstOpenSiteAndScrapeText(
    targetLink: string
  ): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message = {
        type: ZorstMessageType.scrapeSite,
        targetLink: targetLink,
        debugMode: store.state.zorstDebugging,
      };

      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 30000);
    });
  }
  public ZorstOneApiCall(apiData: {
    openApiSiteLink: string;
    apiEndpointLink: string;
    apiMethod: string;
    body?: any;
    apiKey?: string;
    xCoreClientId?: string;
  }): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message: ZorstMessage = {
        type: ZorstMessageType.oneApiCall,
        openApiSiteLink: apiData.openApiSiteLink,
        apiEndpointLink: apiData.apiEndpointLink,
        apiMethod: apiData.apiMethod,
        body: apiData.body,
        debugMode: store.state.zorstDebugging,
        ...(apiData.apiKey && { apiKey: apiData.apiKey }),
        ...(apiData.xCoreClientId && { xCoreClientId: apiData.xCoreClientId }),
      };

      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Daten der Drittanbieter Software";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000);
    });
  }
  public ZorstMultiApiCall(
    openApiSiteLink: string,
    apiRequests: Array<{
      endpointLink: string;
      method: string;
      body?: any;
      xCoreClientId?: string;
    }>
  ): Promise<ZorstStandardResponse> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message = {
        type: ZorstMessageType.multiAPiCall,
        apiRequests: apiRequests,
        openApiSiteLink: openApiSiteLink,
        debugMode: store.state.zorstDebugging,
      };

      const handleResponse = (event: MessageEvent) => {
        this.handleZorstResponse(
          event,
          resolve,
          reject,
          timeoutId,
          message,
          handleResponse
        );
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Daten der Drittanbieter Software";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000);
    });
  }
}
