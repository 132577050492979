import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.showDialog,
    showActions: true,
    width: 'var(--d-w-xs)',
    onCloseDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog()))
  }, {
    title: _withCtx(() => _cache[3] || (_cache[3] = [
      _createTextVNode(" Passwort vergessen ")
    ])),
    content: _withCtx(() => [
      _createVNode(_component_v_card_subtitle, { class: "mt-5 ml-2" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Bitte Email Adresse eingeben")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
            modelValue: _ctx.emailInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailInput) = $event)),
            label: "Email Adresse",
            type: "email",
            required: ""
          }), null, 16, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "abort",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit(_ctx.PasswordForgottenDialogEmit.cancel)))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Abbrechen")
        ])),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        color: "green-darken-4",
        onClick: _ctx.sendPassword
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Senden")
        ])),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["showDialog"]))
}