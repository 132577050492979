<!--src/components/disposition/elements/InterviewCompletedForm.vue-->
<template>
  <v-container v-if="skillsDialog || interviewDialog">
    <BaseDialog
      :showDialog="skillsDialog"
      :showActions="true"
      @closeDialog="closeSkillsModal"
      :width="'var(--d-w-l)'"
    >
      <template #title>Daten ergänzen ... </template>
      <template #content>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-card v-bind="vStyle?.card">
                <v-card-subtitle class="my-4"
                  >Kandidaten bearbeiten,</v-card-subtitle
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="
                    $emit(DialogInterviewCompletedEmits.openEditCandidateDialog)
                  "
                  >Bearbeiten</v-btn
                >
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card v-bind="vStyle?.card">
                <v-card-subtitle class="my-4">
                  oder in ATS eintragen!</v-card-subtitle
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('stammdaten')"
                  >1. Stammdaten</v-btn
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('perfectmatch/skills')"
                  >2. Skills</v-btn
                >
                <v-btn
                  variant="text"
                  block
                  style="color: var(--color-primary)"
                  @click="openZvoove('vita')"
                  >3. Lebenslauf</v-btn
                >
                <v-divider></v-divider>
                <v-btn
                  variant="text"
                  block
                  color="success"
                  @click="handleAtsRecruitConfirm"
                  >OK und aus ATS aktualisieren</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template #actions>
        <v-btn color="abort" @click="closeSkillsModal">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="handleEditConfirm">Weiter</v-btn>
      </template>
    </BaseDialog>

    <!-- Zweites Modal für die Zusammenfassung des Vorstellungsgesprächs -->
    <BaseDialog
      :showDialog="interviewDialog"
      :showActions="true"
      @closeDialog="closeInterviewModal"
      :width="'600px'"
    >
      <template #title>Zusammenfassung des Vorstellungsgesprächs</template>
      <template #content>
        <v-card-text>
          <v-textarea
            v-bind="vStyle?.input"
            v-model="interviewSummary"
            label="Bitte gibt eine Zusammenfassung ein."
          ></v-textarea>
        </v-card-text>
      </template>
      <template #actions>
        <v-btn color="abort" @click="closeInterviewModal">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          style="color: var(--color-primary)"
          @click="handleInterviewConfirm"
          >OK</v-btn
        >
      </template>
    </BaseDialog>
  </v-container>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum DialogInterviewCompletedEmits {
  openEditCandidateDialog = "openEditCandidateDialog",
  fetchRecruitData = "fetchRecruitData",
  saveResumeInterview = "saveResumeInterview",
}

export default defineComponent({
  name: "MultiStepForm",
  emits: [
    DialogInterviewCompletedEmits.openEditCandidateDialog,
    DialogInterviewCompletedEmits.fetchRecruitData,
    DialogInterviewCompletedEmits.saveResumeInterview,
  ],
  components: {
    BaseDialog,
  },
  props: {
    atsRecruitUuid: String as PropType<string>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      DialogInterviewCompletedEmits,
      skillsDialog: false,
      interviewDialog: false,
      interviewSummary: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    openSkillsModal() {
      this.skillsDialog = true;
    },
    closeSkillsModal() {
      this.skillsDialog = false;
    },
    handleAtsRecruitConfirm() {
      this.skillsDialog = false;
      this.$emit(DialogInterviewCompletedEmits.fetchRecruitData);
      this.openInterviewModal();
    },
    handleEditConfirm() {
      this.skillsDialog = false;
      this.openInterviewModal();
    },
    openInterviewModal() {
      this.interviewDialog = true;
    },
    closeInterviewModal() {
      this.interviewDialog = false;
    },
    handleInterviewConfirm() {
      this.interviewDialog = false;
      this.$emit(
        DialogInterviewCompletedEmits.saveResumeInterview,
        this.interviewSummary
      );
    },
    openZvoove(target: string) {
      const externalLink = `${this.$store.state.company.softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/${this.atsRecruitUuid}/${target}`;
      window.open(externalLink, "_blank", PopUpWindowSize.externalSoftware);
    },
  },
});
</script>
