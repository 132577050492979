<!--src/components/disposition/CandidateItem.vue-->
<template>
  <GeneralItem
    ref="generalItemComponent"
    :color="item.color"
    :dragOverIcon="dragOver.icon"
    :dragOverText="dragOver.text"
    :hoverColor="item.hoverColor"
    :isActive="isActive"
    :isDialog="isOpenedAsDialog()"
    :isFullyCollapsed="isFullyCollapsed"
    :parentRole="Role.candidate"
    :statusBlinking="needToContact || prioNeedToContact"
    :statusColor="statusColor"
    :showMatchIcon="customerOrDemandTagsMatching"
    @closeDialog="$emit(CandidateItemEmit.closeDialog)"
    @dragOver="handleDragOver"
    @dragStart="handleDragStart"
    @isExpanded="handleItemExpansion(true)"
    @isNotExpanded="handleItemExpansion(false)"
    @openContextMenu="openContextMenu($event)"
  >
    <template #collapsed-view>
      <v-sheet class="ma-0 pt-1 px-1" :color="item.colorList">
        <v-card-title class="d-flex justify-space-between pa-0">
          <v-btn
            :rounded="vStyle?.btn?.rounded"
            :border="vStyle?.btn?.border"
            :loading="isLoadingFromAts"
            @click="loadCandidate(ExternalSoftware.atsRecruit)"
            variant="tonal"
            density="compact"
            size="small"
            v-if="
              !candidate.firstName &&
              !candidate.lastName &&
              !candidateData?.firstName &&
              !candidateData?.lastName
            "
          >
            <v-icon class="mr-2" size="small"
              >fa-solid fa-cloud-arrow-down</v-icon
            >
            #{{ candidate.applicationId }}
            <v-tooltip activator="parent" location="top left"
              >Kandidatendaten vollständig aus dem ATS laden ...</v-tooltip
            >
          </v-btn>
          <span class="pa-0 text-body-2">
            {{
              candidateData?.firstName
                ? candidateData.firstName
                : candidate.firstName
            }}
            {{
              candidateData?.lastName
                ? candidateData.lastName
                : candidate.lastName
            }}
            <span v-if="candidateAge && candidateAge > 0">
              ({{ candidateAge }})</span
            >
            {{
              candidateData?.shiftIcon
                ? candidateData.shiftIcon
                : candidate.shiftIcon
            }}
            <span
              style="color: black"
              v-if="
                (candidateData &&
                  candidateData.mobilityRadius &&
                  candidateData.mobilityRadius > 0) ||
                (candidate &&
                  candidate?.mobilityRadius &&
                  candidate?.mobilityRadius > 0)
              "
            >
              ({{
                candidateData?.mobilityRadius
                  ? candidateData.mobilityRadius
                  : candidate.mobilityRadius
              }}
              km
              <v-icon size="xsmall">{{
                candidateData?.mobilityIcon
                  ? candidateData.mobilityIcon
                  : candidate.mobilityIcon
              }}</v-icon>
              )
            </span>
          </span>
          <span
            class="mx-1 text-caption font-weight-light text-medium-emphasis"
          >
            {{
              candidateData?.addressCity
                ? candidateData.addressCity
                : candidate.addressCity
            }}
          </span>
          <div
            class="status-list"
            :style="{ backgroundColor: statusColor }"
            :class="{ blinking: needToContact || prioNeedToContact }"
          ></div>
        </v-card-title>
        <v-divider class="my-1"></v-divider>
      </v-sheet>
    </template>
    <template #item-collapsed>
      <div class="d-flex">
        <v-avatar v-if="avatar" size="2.5rem" class="mr-1">
          <v-img alt="Avatar" :src="avatar"></v-img>
        </v-avatar>
        <div>
          <v-card-title class="d-flex pa-0 text-body-2">
            <v-btn
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              :loading="isLoadingFromAts"
              @click="loadCandidate(ExternalSoftware.atsRecruit)"
              variant="tonal"
              v-if="
                !candidate.firstName &&
                !candidate.lastName &&
                !candidateData?.firstName &&
                !candidateData?.lastName
              "
            >
              <v-icon class="mr-2" size="small"
                >fa-solid fa-cloud-arrow-down</v-icon
              >
              #{{ candidate.applicationId }}
              <v-tooltip activator="parent" location="top left"
                >Kandidatendaten vollständig aus dem ATS laden ...</v-tooltip
              >
            </v-btn>
            <v-btn
              icon
              variant="text"
              density="compact"
              v-if="isAtsStatusUnknown && !isAtsStatusChangedFromUnknown"
              @click="updateComponent"
            >
              <v-icon size="small">fa-regular fa-question-circle</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >ATS Status ist unbekannt! Kandidat aktualisieren und neuen
                Status abrufen?</v-tooltip
              >
            </v-btn>
            {{
              candidateData?.firstName
                ? candidateData?.firstName
                : candidate?.firstName
            }}
            {{
              candidateData?.lastName
                ? candidateData?.lastName
                : candidate?.lastName
            }}
            <span class="mx-1" v-if="candidateAge && candidateAge > 0">
              ({{ candidateAge }})</span
            >
            {{
              candidateData?.shiftIcon
                ? candidateData?.shiftIcon
                : candidate?.shiftIcon
            }}
            <span
              class="mx-1 text-caption text-medium-emphasis"
              v-if="isAtsStatusChangedFromUnknown"
            >
              (verschoben nach {{ matchedApplication.ats?.status }})
            </span>
          </v-card-title>
          <v-card-subtitle class="pl-0">
            {{
              candidateData?.addressPostalCode
                ? candidateData?.addressPostalCode
                : candidate?.addressPostalCode
            }}
            {{
              candidateData?.addressCity
                ? candidateData?.addressCity
                : candidate?.addressCity
            }}
            <span
              style="color: black"
              v-if="
                (candidateData &&
                  candidateData?.mobilityRadius &&
                  candidateData?.mobilityRadius > 0) ||
                (candidate &&
                  candidate?.mobilityRadius &&
                  candidate?.mobilityRadius > 0)
              "
            >
              ({{
                candidateData?.mobilityRadius
                  ? candidateData?.mobilityRadius
                  : candidate?.mobilityRadius
              }}
              km
              <v-icon size="xsmall">{{
                candidateData?.mobilityIcon
                  ? candidateData?.mobilityIcon
                  : candidate?.mobilityIcon
              }}</v-icon>
              )
            </span>
          </v-card-subtitle>
        </div>
      </div>
    </template>
    <template #hide-on-inactive-column>
      <span style="font-size: 0.7em"
        ><b
          >#{{ candidate?.applicationId }}
          {{
            matchedApplication?.appliedAs
              ? matchedApplication.appliedAs
              : candidate.appliedAs
          }}</b
        ></span
      ><br />
      <span style="font-size: 0.8em"
        >{{ matchedMandantName }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(mandant, index) in mandants"
              :key="index"
              :value="index"
            >
              <v-list-item-title @click="changeMandant(mandant.uuid)">{{
                mandant.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu></span
      >
    </template>
    <template #communication-icons-container>
      <div class="d-flex">
        <div class="ma-0 pa-0">
          <div class="communication-icons-container">
            <v-btn
              v-if="candidateData && candidateData?._id"
              icon
              density="compact"
              variant="text"
              @click="createNewMandantAppointmentOnDependencies"
              class="appointment-icon"
            >
              <v-icon>fa-solid fa-building</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Statuswechsel mit Automatisierungen z.B. Termine mit
                Niederlassung
              </v-tooltip>
            </v-btn>
            <v-btn
              variant="text"
              class="appointment-icon"
              icon
              density="compact"
            >
              <v-icon class="text-medium-emphasis" color="secondary"
                >fa-solid fa-screwdriver-wrench</v-icon
              >
              <v-menu activator="parent" offset-y>
                <v-list>
                  <v-list-item
                    v-for="option in statusOptionsRecruit"
                    :key="option.value"
                    @click="changeStatus(option.value)"
                  >
                    <v-list-item-title>{{ option.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip activator="parent" location="bottom"
                >Manueller Statuswechsel ohne automatisierungen wie Mailvorlage,
                Kalendereintrag oder Dokumentation etc.</v-tooltip
              >
            </v-btn>
          </div>
          <v-label
            style="
              font-size: x-small;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100%;
            "
            class="font-weight-thin ma-0 pa-0"
          >
            {{ getDispositionerStatus() }}
          </v-label>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="communication-icons-container">
        <AtsRecruitLinkMenu
          v-if="!candidateData?.isNotInAts"
          :atsRecruitUuid="candidateData?.uuid ?? candidate?.candidateUuid"
          :atsRecruitCurrentApplicationUuid="candidate?.applicationUuid"
          @getRecruitData="loadCandidate(ExternalSoftware?.atsRecruit)"
        />
        <div>
          <img
            v-if="showAtsConnectionOptions()"
            :src="getAssetIconPath('icon-ats-disconnected.svg')"
            alt="ATS disconnected"
            class="communication-container-img-icon"
            @click="searchForCandidateDataInAts()"
          />
          <v-tooltip activator="parent" location="bottom"
            >Keine Verbindung zu eine ATS Kandidatendatensatz! Verbindung mit
            einem existierenden Kandidaten aus dem ATS herstellen?</v-tooltip
          >
        </div>
        <div>
          <img
            v-if="showAtsConnectionOptions()"
            :src="getAssetIconPath('icon-ats-upload.svg')"
            alt="ATS disconnected"
            class="communication-container-img-icon"
            @click="sendCandidateToAts()"
          />
          <v-tooltip activator="parent" location="bottom"
            >Keine Verbindung zu eine ATS Kandidatendatensatz! Soll der Kandidat
            an das ATS System gesendet werden?</v-tooltip
          >
        </div>
        <v-btn
          v-if="showExistingProfileIcon()"
          icon
          variant="text"
          density="compact"
          class="communication-container-icon"
        >
          <v-icon size="var(--dispatcher-board-communication-icon-size)"
            >fa-solid fa-image-portrait</v-icon
          >
          <v-menu activator="parent" offset-y>
            <ExistingProfiles
              :profiles="candidateData?.profiles || []"
              :showDeleteButton="true"
              @getExistingProfile="showExistingProfile"
              @deleteProfile="deleteProfile"
            />
          </v-menu>
          <v-tooltip activator="parent" location="bottom">Profile</v-tooltip>
        </v-btn>
        <v-btn
          v-if="showGdprConsentIcon()"
          icon
          variant="text"
          density="compact"
          class="communication-container-icon"
          :color="showGdprConsentCred()?.color ?? undefined"
        >
          <v-badge
            :color="showGdprConsentCred()?.badgeColor ?? undefined"
            :content="showGdprConsentCred()?.badgeContent ?? undefined"
          >
            <v-icon size="xsmall">fa-solid fa-fingerprint</v-icon>
          </v-badge>
          <v-tooltip activator="parent" location="bottom">
            <template v-slot:default>
              <div
                v-html="showGdprConsentCred()?.tooltip ?? 'Datenschutz'"
              ></div>
            </template>
          </v-tooltip>
        </v-btn>
        <v-btn
          v-if="!isStatusInterview()"
          icon
          class="communication-container-icon"
          variant="text"
          density="compact"
          @click="openApplicationFormDispatcherMode()"
        >
          <v-icon size="xsmall">fa-solid fa-id-card</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >Zum Bewerberbogen</v-tooltip
          >
        </v-btn>
        <PhoneClient
          ref="phoneClientComponent"
          :candidate="candidate"
          :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
          :phoneNumbers="filteredPhoneNumbers"
          @addFollowUpEvent="addFollowUpOnDependencies"
          @collapseParentItem="collapseItem"
          @isDialingNumber="phoneClientIsDialing"
          @setAppointmentEvent="setAppointmentOnDependencies"
        ></PhoneClient>
        <WhatsAppClient
          :AiMessageType="AiMessageType?.whatsAppCandidate"
          :candidate="candidate"
          :linkingStatuses="linkingStatuses"
          :message="message.body"
          :phoneNumbers="filteredPhoneNumbers"
          :receiverName="`${candidateData?.firstName} ${candidateData?.lastName}`"
          :salutation="salutationText"
          :signature="signatureWhatsApp"
          @collapseParentItem="collapseItem"
        ></WhatsAppClient>
        <MailClient
          ref="mailClientComponent"
          :AiMessageType="AiMessageType.mailCandidate"
          :candidate="candidate"
          :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
          :emailAddresses="filteredEmail"
          :emailObject="message"
          :linkingStatuses="linkingStatuses"
          :signature="signatureMail"
          @collapseParentItem="collapseItem"
          @editContacts="openEditCandidateModal"
        ></MailClient>
      </div>
      <DialogApplicationForm
        ref="dialogApplicationFormComponent"
        :candidate="candidateData"
        :isStatusInterview="isStatusInterview()"
        @submit="updateCandidateDataFromEditDialog"
      ></DialogApplicationForm>
    </template>
    <template #checklist-container>
      <Checklist
        :checklist="candidateData?.checklist || []"
        :candidate="candidate"
        @updateChecklist="updateChecklist"
      ></Checklist>
    </template>
    <template #tags-container>
      <v-chip
        v-for="(tag, index) in candidateData?.tags"
        :key="index"
        class="dispatcher-board-icon-tag"
        color="primary"
        @click:close="removeTag(index)"
      >
        {{ tag }}
        <v-icon
          small
          @click.stop="removeTag(index)"
          class="fa fa-times"
        ></v-icon>
      </v-chip>
      <v-text-field
        v-bind="vStyle?.input"
        class="mt-2"
        label="Neuer Tag"
        style="max-width: 10rem"
        density="compact"
        v-model="newTag"
        @keyup.enter="addTag"
      >
        <v-tooltip activator="parent" location="bottom"
          >mit "Enter" neuen Tag hinzufügen</v-tooltip
        >
      </v-text-field>
      <v-divider class="mb-4"></v-divider>
    </template>
    <template #dependencies-container>
      <Dependencies
        v-if="
          candidate &&
          (hasLinking(candidate?.parentObjectid) || isDependenciesActive)
        "
        ref="dependenciesComponent"
        :mandant="[matchedMandantUuid || '']"
        :candidate_id="candidate?.parentObjectid"
        :linkingDescription="linkingDescription"
        @updateCandidateStatus="handleCandidateStatusUpdate"
        @candidateToMandantLinked="setCandidateToMandantFromDependencies"
        @needToContact="prioNeedToContact = true"
        @notNeedToContact="prioNeedToContact = false"
        @openPhoneClient="openPhoneClient()"
      />
      <v-divider class="mb-4"></v-divider>
    </template>
    <template #info-container>
      <v-textarea
        class="mt-2 pr-1"
        v-model="interviewResultEdit"
        label="Zusammenfassung Vorstellungsgespräch / Notizen:"
        variant="solo"
        rows="4"
        @update:modelValue="saveInterviewResultDebounced"
      ></v-textarea>
      <v-container class="ma-0 pa-0 d-flex">
        <v-checkbox
          v-if="
            candidateData &&
            softwareIntegration?.wordPressPlugin &&
            isCandidateActiveForPlacement()
          "
          density="compact"
          v-model="candidateData.isPublishedOnWebsite"
          :label="`Kandidat auf die Webseite stellen (WordPress Plugin)`"
          @change="handlePublishedOnWebseiteChange"
        ></v-checkbox>
        <v-spacer></v-spacer>
      </v-container>
      <p v-if="getCareerLevelDescription()">
        {{ getCareerLevelDescription() }}
      </p>
      <v-divider></v-divider>
      <SendCandidateToWp
        v-if="softwareIntegration?.wordPressPlugin"
        ref="sendCandidateToWpComponent"
        @aborted="sendCandidateToWpAborted()"
      ></SendCandidateToWp>
    </template>
    <template #timeline-container>
      <Timeline
        :candidate="candidate"
        :candidateName="`${candidateData?.firstName} ${candidateData?.lastName}`"
        :mandant="matchedMandantUuid || ''"
      ></Timeline>
    </template>
    <template #append-container>
      <Teleport to="body">
        <v-menu
          v-model="showContextMenu"
          :style="{
            top: contextMenuPosition.y + 'px',
            left: contextMenuPosition.x + 'px',
          }"
        >
          <v-list dense>
            <v-list-item @click="openEditCandidateModal()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-pen-to-square </v-icon
              >bearbeiten</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="askCustomerItemsForMatch()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-heart </v-icon
              >Matching</v-list-item
            >
            <v-list-item
              v-if="$store.state?.isActiveCandidateMatching"
              @click="clearMatchingOject()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-heart-circle-xmark </v-icon
              >Matches bei Unternehmen/Anfragen entfernen</v-list-item
            >
            <v-list-item @click="placementWebSearch()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-brands fa-google </v-icon
              >Jobsuche<v-icon class="text-medium-emphasis ml-1" size="0.8rem">
                fa-solid fa-rocket
              </v-icon></v-list-item
            >
            <v-list-item @click="setCandidateAddressForCommuteWebSearch()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-location-dot </v-icon
              >Arbeitsweg Recherche<v-icon
                class="text-medium-emphasis ml-1"
                size="0.8rem"
              >
                fa-solid fa-rocket
              </v-icon></v-list-item
            >
            <v-list-item @click="deleteCandidate()">
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-xmark </v-icon
              >Kandidat vom Dispoboard löschen</v-list-item
            >
            <v-list-item
              v-if="hasFullCandidatesAccess"
              @click="deleteCandidate(candidate?.candidateUuid)"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-user-xmark </v-icon
              >ADMIN: Kandidat anhand uuid löschen</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitLoadCandidatesFull()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-regular fa-id-card </v-icon
              >alle vollständig laden</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitLoadCandidatesFromAts()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
                fa-solid fa-cloud-arrow-down </v-icon
              >alle aus ATS überschreiben</v-list-item
            >
            <v-list-item
              v-if="!isOpenedAsDialog()"
              @click="emitToggleItemsMinimized()"
            >
              <v-icon class="text-medium-emphasis ml-1 mr-1" size="xs">
                {{
                  isFullyCollapsed
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-chevron-down"
                }}
              </v-icon>
              {{ isFullyCollapsed ? "Karten normal" : "Karten minimieren" }}
            </v-list-item>
          </v-list>
        </v-menu>
        <JobAdLeadsCandidate
          v-if="
            isExpanded &&
            candidateData?.jobAdLeads &&
            isCandidateActiveForPlacement() &&
            !isOpenedAsDialog()
          "
          ref="jobAdListComponent"
          :jobAds="candidateData?.jobAdLeads as JobAdLead[] || []"
          :indexCareerLevel="candidateData?.careerLevel"
          :mandant="matchedMandantUuid || ''"
          @generateAiSearchterm="fetchJobAdLeads(true)"
          @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
          @updateCareerLevels="updateCareerLevels"
        />
        <DialogInterviewCompleted
          v-if="isDialogInterviewCompletedActive"
          ref="dialogInterviewCompletedComponent"
          :atsRecruitUuid="candidate.candidateUuid"
          @saveResumeInterview="handleSaveResumeInterview"
          @fetchRecruitData="loadCandidate(ExternalSoftware.atsRecruit)"
          @openEditCandidateDialog="openEditCandidateModal"
        ></DialogInterviewCompleted>
        <DialogManualAnzeigendatenSearch
          v-if="isDialogManualAnzeigendatenSearchActive"
          :dataForQuery="prepareDataForJobAdLeadsQuery()"
          ref="dialogManualAnzeigendatenSearchComponent"
          @searchAndReplace="searchAnzeigendatenAndReplace"
          @searchAndAdd="searchAnzeigendatenAndAdd"
        ></DialogManualAnzeigendatenSearch>
        <DialogCompareCandidates
          :showDialog="showDialogCompareCandidates"
          @closeDialog="showDialogCompareCandidates = false"
          :candidateExisting="candidateToCompareExisting"
          :candidateCurrent="candidateToCompareCurrent"
          @selectExisting="selectComparedCandidate(false)"
          @selectCurrent="selectComparedCandidate(true)"
        ></DialogCompareCandidates>
        <DialogEditCandidate
          v-if="isDialogEditCandidateActive"
          ref="dialogEditCandidateComponent"
          :candidate="candidateData"
          @submit="updateCandidateDataFromEditDialog"
          @fetchJobAdLeads="fetchJobAdLeads()"
          @generateAiSearchterm="fetchJobAdLeads(true)"
          @manualAnzeigendatenSearch="openAnzeigendatenManualSearch()"
        ></DialogEditCandidate>
        <DialogExtractCandidateUuidFromAtsRecruitLink
          :showDialog="showDialogExtractCandidateUuidFromAtsRecruitLink"
          @closeDialog="
            showDialogExtractCandidateUuidFromAtsRecruitLink = false
          "
          @extractedUuid="searchForCandidateDataInAtsReceiveCandidateUuid"
        ></DialogExtractCandidateUuidFromAtsRecruitLink>
        <DialogSelectAtsCandidates
          :showDialog="showDialogSelectAtsCandidates"
          @closeDialog="showDialogSelectAtsCandidates = false"
          :resultsCandidateSearch="resultsCandidateSearch"
          @selectCandidateSearchResult="
            selectCandidateToUpdateAndCheckForDuplicates
          "
        ></DialogSelectAtsCandidates>
      </Teleport>
    </template>
  </GeneralItem>
</template>

<script lang="ts">
import { AiMessageType } from "../../enums/ai-options.enum";
import { AiService } from "@/services/ai.service";
import { AllAtsAdapter } from "@/adapter/all-ats.adapter";
import {
  AllLinkingsStatusObject,
  LinkingDescription,
  LinkingEvent,
} from "@/models/linking.model";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import {
  Candidate,
  CandidateAddressForCommuteWebSearch,
  CommunicationMeans,
} from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";
import { CandidateService } from "@/services/api/candidate.service";
import { CareerLevel, IaCareerLevel } from "@/enums/anzeigendaten.enum";
import { CheckList } from "@/models/checklist.model";
import { CommunicationType } from "@/enums/communication-types.enum";
import {
  DataForJobAdLeadsQuery,
  JodAdLeadsQuery,
} from "@/models/external/index-anzeigendaten.model";
import { defineComponent, PropType } from "vue";
import { ExternalSoftware } from "@/enums/external/external-software.enum";
import { Gender } from "@/enums/gender.enum";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { JobAdLead } from "@/models/job-ad-lead.model";
import { LinkingService } from "@/services/api/linking.service";
import { LinkingStatus, Role } from "@/enums/dependency.enum";
import { mapGetters, mapMutations } from "vuex";
import { MessageContent } from "@/enums/empty-message.enum";
import { MutationType } from "@/enums/vuex-types.enum";
import { RecruitStatusOption } from "@/models/status-candidate.model";
import { Salutation, getSalutationText } from "@/enums/salutation.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { StatusResponse } from "@/enums/dialog.enum";
import { User } from "@/models/user.model";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { ZipCodeDistance } from "@/models/zipcode.model";
import { ZipCodeService } from "@/services/api/zip-code.service";
import Checklist from "./elements/Checklist.vue";
import Dependencies from "@/components/disposition/elements/Dependencies.vue";
import DialogApplicationForm from "./elements/DialogApplicationForm.vue";
import DialogEditCandidate from "@/components/disposition/elements/DialogEditCandidate.vue";
import DialogInterviewCompleted from "@/components/disposition/elements/DialogInterviewCompleted.vue";
import DialogManualAnzeigendatenSearch from "./elements/DialogManualAnzeigendatenSearch.vue";
import DialogService from "@/services/dialog.service";
import ExistingProfiles from "./elements/ExistingProfiles.vue";
import JobAdLeadsCandidate from "@/components/disposition/elements/JobAdLeadsCandidate.vue";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import SendCandidateToWp from "./elements/SendCandidateToWp.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import WhatsAppClient from "./elements/wab/WhatsAppClient.vue";
import AtsRecruitLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import { AllItemUpdate } from "../../enums/board-actions.enum";
import { Profile } from "../../models/candidate.model";
import { AtsRecruitService } from "../../services/api/api-integration-one.service";
import { PrivacyPolicyStatus } from "../../enums/privacy-policy.enum";
import { PopUpWindowSize } from "../../enums/app-layout.enum";
import GeneralItem from "./elements/GeneralItem.vue";
import { checkTagsMatching } from "../../helper/fuse-match-tags.helper";
import { ItemDragOverInfo } from "../../models/app-messages.model";
import { CandidateDataHelper } from "../../helper/candidate-data.helper";
import { DragEventSpec } from "../../enums/drag-event-spec.enum";
import { DataType } from "../../enums/data-types.enum";
import { getAssetIconPath } from "../../helper/get-assets.helper";
import DialogSelectAtsCandidates from "./elements/DialogSelectAtsCandidates.vue";
import { BackendMessage } from "../../enums/backend-messages.enum";
import DialogCompareCandidates from "./elements/candidate/DialogCompareCandidates.vue";
import DialogExtractCandidateUuidFromAtsRecruitLink from "./elements/ats-recruit-helper/DialogExtractCandidateUuidFromAtsRecruitLink.vue";
import { VuetifyColor } from "../../plugins/vuetify";
import { debounce } from "lodash";
import { IdPrefix } from "../../enums/id-prefix.enum";
import { AccessRule } from "../../enums/access-rule.enum";
import { RecurionChatbotType } from "../../enums/recurion-chatbot-types.enum";
import { RecurionChatMenu } from "../../models/slide-in-menus.model";

enum CandidateItemEmit {
  closeDialog = "closeDialog",
  updatePostcode = "updatePostcode",
  updateCandidate = "updateCandidate",
  loadCandidates = "loadCandidates",
  loadCandidatesFull = "loadCandidatesFull",
  loadCandidatesFromAts = "loadCandidatesFromAts",
  sendCandidateToAts = "sendCandidateToAts",
  toggleItemsMinimized = "toggleItemsMinimized",
}

export default defineComponent({
  name: "CandidatItem",
  emits: [
    CandidateItemEmit.closeDialog,
    CandidateItemEmit.updatePostcode,
    CandidateItemEmit.updateCandidate,
    CandidateItemEmit.loadCandidates,
    CandidateItemEmit.loadCandidatesFull,
    CandidateItemEmit.loadCandidatesFromAts,
    CandidateItemEmit.sendCandidateToAts,
    CandidateItemEmit.toggleItemsMinimized,
  ],
  components: {
    AtsRecruitLinkMenu,
    Checklist,
    Dependencies,
    DialogApplicationForm,
    DialogCompareCandidates,
    DialogEditCandidate,
    DialogExtractCandidateUuidFromAtsRecruitLink,
    DialogInterviewCompleted,
    DialogManualAnzeigendatenSearch,
    DialogSelectAtsCandidates,
    ExistingProfiles,
    GeneralItem,
    JobAdLeadsCandidate,
    MailClient,
    PhoneClient,
    SendCandidateToWp,
    Timeline,
    WhatsAppClient,
  },
  props: {
    candidate: {
      type: Object as PropType<CandidateList>,
      required: true,
    },
    candidateDataInput: {
      type: Object as PropType<Candidate>,
      required: false,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      hasLinking: "hasLinkingForCandidate",
      mandants: "mandants",
      mailTemplates: "mailTemplates",
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
    hasFullCandidatesAccess(): boolean {
      return UserRoleHelper.hasAccess(this.user, AccessRule.getAllCandidates);
    },
    isAtsStatusUnknown() {
      if (this.softwareIntegration.atsStatusIgnored) return false;
      return this.candidate.atsStatus === StatusResponse.unknown;
    },
    matchedApplication() {
      return CandidateDataHelper.matchedApplication(
        this.candidate,
        this.candidateData
      );
    },
    matchedMandantName() {
      return this.$store.getters.getMandantNameByUuid(
        this.matchedMandantUuid || ""
      );
    },
    matchedMandantUuid() {
      let uuid = this.candidate?.mandants[0];
      if (this.matchedApplication?.mandantUuid) {
        uuid = this.matchedApplication?.mandantUuid;
      }
      return uuid ? uuid : undefined;
    },
    salutationText() {
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const salutation = getSalutationText(
          this.candidateData.salutationCatalogId
        );
        return `Guten Tag ${salutation} ${this.candidateData.firstName} ${this.candidateData.lastName},`;
      }
      return "";
    },
    signatureMail() {
      const user = this.user;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    signatureWhatsApp() {
      const user = this.user;
      return `Viele Grüße\n${Salutation[user.salutation]} ${user.forename} ${
        user.lastname
      }\n${this.$store.state.company.name}`;
    },
  },
  data() {
    return {
      anzeigedatenService: AnzeigedatenService.getInstance(),
      avatar: "",
      AiMessageType,
      candidateAge: null as number | null,
      candidateData: {} as Candidate | undefined,
      CandidateItemEmit: CandidateItemEmit,
      candidateService: new CandidateService(),
      candidateToCompareExisting: {} as Candidate | undefined,
      candidateToCompareCurrent: {} as Candidate | undefined,
      careerLevel: [] as Array<keyof typeof IaCareerLevel>,
      customerOrDemandTagsMatching: false,
      contextMenuPosition: { x: 0, y: 0 },
      dragOver: {} as ItemDragOverInfo,
      ExternalSoftware: ExternalSoftware,
      filteredEmail: [] as any,
      filteredPhoneNumbers: [] as any,
      interviewResultEdit: "",
      isAtsStatusChangedFromUnknown: false,
      isDependenciesActive: false,
      isDialogEditCandidateActive: false,
      isDialogInterviewCompletedActive: false,
      isDialogManualAnzeigendatenSearchActive: false,
      isExpanded: false,
      isHided: false,
      isLoadingFromAts: false,
      item: {
        border: this.$store.state.vStyle.boardItem.border || undefined,
        color: this.isOpenedAsDialog() ? undefined : "card",
        elevation: 2,
        hoverColor: "cardHover",
        colorList: undefined as undefined | string,
      },
      licences: "",
      linkingDescription: {} as LinkingDescription,
      linkingStatuses: [] as LinkingEvent[],
      message: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      },
      needToContact: false,
      newTag: "",
      prioNeedToContact: false,
      Role,
      resultsCandidateSearch: [] as Candidate[],
      selectedStatus: this.candidate?.status,
      showContextMenu: false,
      showDialogCompareCandidates: false,
      showDialogExtractCandidateUuidFromAtsRecruitLink: false,
      showDialogSelectAtsCandidates: false,
      skillsForJobAdLeads: [] as string[],
      statusColor: "",
      vStyle: this.$store.state.vStyle,
      zipCodeService: new ZipCodeService(),
      // helper methods
      getAssetIconPath,
    };
  },
  watch: {
    candidate: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.initializeComponent().then(() => {
            this.loadAvatarFromAtsRecruit();
          });
        }
      },
      deep: true,
    },
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).loadLinkingsFromStore();
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).checkForReminders();
      }
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterCandidate(newVal.payload.searchTerm);
            break;
          case InterComponentMessage.matchMeFromCustomerOrDemand:
            this.handleCheckMatch(newVal.payload);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initializeComponent().then(() => {
      this.loadAvatarFromAtsRecruit();
    });
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    actionsFromStatusChange(statusValue: string) {
      const candidateStatus = this.$store.getters.getStatusByValue(
        statusValue
      ) as string[];
      candidateStatus.forEach((status) => {
        switch (status) {
          case LinkingStatus.rejectedCandidate:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.rejected:
            this.deleteCandidateFromWpPlugin();
            break;
          case LinkingStatus.hired:
            this.deleteCandidateFromWpPlugin();
            break;
          default:
            break;
        }
      });
    },
    addFollowUpOnDependencies() {
      this.isDependenciesActive = true;
      this.$nextTick(() => {
        if (this.candidateData && this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).linkFollowUp(this.candidateData._id || null, null);
        }
      });
    },
    addTag() {
      const trimmedTag = this.newTag.trim();
      if (this.candidateData && trimmedTag !== "") {
        if (!this.candidateData.tags) {
          this.candidateData.tags = [];
        }
        this.candidateData.tags.push(trimmedTag);

        const candidate = this.candidate;
        if (!candidate.tags) candidate.tags = [];

        candidate.tags?.push(trimmedTag);
        this.$emit(CandidateItemEmit.updateCandidate, candidate);

        this.newTag = "";

        this.updateCandidateData().then(() => {
          ToastService.show("Tag hinzugefügt!");
        });
      }
    },
    async askCustomerItemsForMatch() {
      if (!this.candidateData || !this.candidateData.tags) {
        return;
      }
      SpinnerService.showSpinner();
      this.setICM({
        message: InterComponentMessage.preloadDispatcherBoardColumn,
        payload: {
          candidateColumn: true,
        },
      });
      this.$nextTick(() => {
        this.getCustomerPostcodesInMobilityRange().then((customersInRange) => {
          this.setICM({
            message: InterComponentMessage.matchMeFromCandidate,
            payload: {
              postcodes: customersInRange,
              tags: this.candidateData?.tags ?? "",
            },
          });
          this.$store.state.isActiveCandidateMatching = true;
          SpinnerService.removeSpinner();
          this.closeContextMenu();
        });
      });
    },
    async autoCheckForJobAdLeads() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        this.candidateData.tags &&
        this.candidateData.tags.length > 0 &&
        this.candidateData.careerSteps &&
        this.candidateData.careerSteps.length > 0 &&
        this.softwareIntegration.indexAnzeigendaten
      ) {
        let isFetchAds = false;
        if (this.candidateData.jobAdLeads?.length === 0) {
          isFetchAds = true;
        } else {
          const firstAdLead: JobAdLead | undefined =
            this.candidateData.jobAdLeads?.[0];
          if (firstAdLead && firstAdLead.retrievalDate) {
            const adRetrievalDate = moment(
              firstAdLead.retrievalDate,
              "DD.MM.YYYY"
            );
            const currentDate = moment();
            const daysSinceLastRetrieval = currentDate.diff(
              adRetrievalDate,
              "days"
            );
            isFetchAds =
              daysSinceLastRetrieval >
              this.softwareIntegration.indexJobAdRefreshInterval;
          } else {
            isFetchAds = true;
          }
        }

        if (isFetchAds) {
          try {
            const needsAiFetching = await this.fetchJobAdLeads();

            if (needsAiFetching) {
              this.fetchJobAdLeads(true).then(() =>
                ToastService.show(
                  `Job Leads von ${this.candidateData?.firstName} ${this.candidateData?.lastName}abgerufen!`
                )
              );
            }
          } catch (error) {
            console.error("Fehler beim Abrufen neuer Job-Leads:", error);
          }
        }
      }
    },
    async allItemUpdate(methode: AllItemUpdate): Promise<void> {
      try {
        switch (methode) {
          case AllItemUpdate.checkForFullLoad:
            await this.checkForFullLoad();
            break;
          case AllItemUpdate.loadCandidateFromAts:
            await this.loadCandidate(ExternalSoftware.atsRecruit);
            break;
          default:
            console.warn(`Methode ${methode} ist nicht implementiert.`);
            break;
        }
        return Promise.resolve();
      } catch (error) {
        console.error(
          `Fehler beim Ausführen der Methode ${methode} für Kandidat ${this.candidate.lastName}:`,
          error
        );
        return Promise.reject(error);
      }
    },
    async changeAtsStatus(targetStatus: string): Promise<string | null> {
      let result = await AllAtsAdapter.changeAtsStatus(
        this.candidate.candidateUuid,
        this.candidate.applicationUuid,
        targetStatus
      );

      if (result === StatusResponse.manual) {
        result = await this.changeAtsStatusManualWithLink();
      }

      return result;
    },
    async changeAtsStatusManualWithLink() {
      const candidateLink = AllAtsAdapter.atsCandidateLink(
        this.candidate.candidateUuid,
        this.candidate.applicationUuid
      );
      const confirmed = await DialogService.confirm(
        `Statuswechsel für #${this.candidate.applicationId}: ${this.candidateData?.firstName} ${this.candidateData?.lastName} manuell durchführen?`,
        "Nein",
        "Kandidat in ATS öffnen",
        "Manueller Statuswechsel erforderlich",
        VuetifyColor.primary,
        VuetifyColor.success
      );
      if (confirmed) {
        window.open(candidateLink, "_blank", PopUpWindowSize.externalSoftware);
        return StatusResponse.atsSuccess;
      } else {
        return null;
      }
    },
    changeMandant(uuid: string) {
      const candidate = this.candidate;
      if (candidate) {
        candidate.mandants = [uuid];
        this.$emit(CandidateItemEmit.updateCandidate, candidate);
        this.changeMatchedApplicationMandant(uuid);
      }
    },
    changeMatchedApplicationMandant(uuid: string) {
      if (!this.candidateData?.applications) return;
      const application = this.candidateData.applications.find(
        (app) => app.uuid === this.candidate.applicationUuid
      );
      if (!application) return;
      application.mandantUuid = uuid;
      this.updateCandidateData();
    },
    changeMatchedApplicationStatus(
      newStatus: string,
      atsStatusUuid?: string,
      atsNewStatus?: string,
      atsInternalStatusSystemName?: string
    ) {
      const application = this.candidateData?.applications.find(
        (application) => application.uuid === this.candidate.applicationUuid
      );
      if (application) {
        application.status = newStatus;
        if (atsNewStatus) application.ats.status = atsNewStatus;
        if (atsStatusUuid) application.ats.statusUuid = atsStatusUuid;
        if (atsInternalStatusSystemName)
          application.ats.internalStatusSystemName =
            atsInternalStatusSystemName;
        this.updateCandidateData();
      }
      const candidate = this.candidate;
      if (candidate) {
        candidate.atsStatus = newStatus;
        this.$emit(CandidateItemEmit.updateCandidate, candidate);
      }
    },
    changeStatus(targetStatus: string) {
      if (this.candidate.status === targetStatus) {
        ToastService.show("Status ist bereits: " + targetStatus);
        return;
      }
      if (
        this.softwareIntegration.atsDeterminesStatus &&
        AllAtsAdapter.isAtsSystem() &&
        !this.candidateData?.uuid.startsWith(IdPrefix.dispositioner)
      ) {
        this.changeAtsStatus(targetStatus).then((result) => {
          if (result === StatusResponse.atsSuccess) {
            this.changeMatchedApplicationStatus(
              targetStatus,
              undefined,
              targetStatus
            );
            this.actionsFromStatusChange(targetStatus);
          } else {
            ToastService.showError(
              "Statuswechsel in ATS System hat nicht geklappt..."
            );
          }
        });
        return;
      } else if (
        AllAtsAdapter.isAtsSystem() &&
        !this.softwareIntegration.atsStatusIgnored &&
        !this.candidateData?.uuid.startsWith(IdPrefix.dispositioner)
      ) {
        this.changeAtsStatus(targetStatus);
      }
      this.changeMatchedApplicationStatus(targetStatus);
      this.actionsFromStatusChange(targetStatus);
    },
    async checkAtsStatus() {
      if (
        !AllAtsAdapter.isAtsSystem() ||
        this.softwareIntegration.atsStatusIgnored
      )
        return;
      const atsStatus = this.candidate?.atsStatus;
      const status = this.candidate?.status;
      let externalAtsStatus = {
        statusUuid: "",
        status: "",
        internalStatusSystemName: "",
      };
      // Get new ATS status if atsStatus is unknown
      if (atsStatus === StatusResponse.unknown && AllAtsAdapter.isAtsSystem()) {
        ToastService.show(
          `ATS Status von ${this.candidate?.firstName} ${this.candidate?.lastName} passt nicht zum gespeicherten Status - rufe neue Daten ab!`
        );
        if (!this.candidateData?._id) await this.loadCandidate();

        const { error, externalAtsStatus } =
          await AllAtsAdapter.getAtsStatusForCandidate(
            this.candidate,
            this.candidate.applicationUuid
          );

        if (externalAtsStatus) {
          ToastService.showReminder(
            `Ändere Status '${this.candidate?.status}' von ${this.candidate?.firstName} ${this.candidate?.lastName} auf den ATS Recruit Status '${externalAtsStatus?.status}'`
          );
          this.isAtsStatusChangedFromUnknown = true;
          this.changeMatchedApplicationStatus(
            externalAtsStatus.status,
            externalAtsStatus.statusUuid,
            externalAtsStatus.status,
            externalAtsStatus.internalStatusSystemName
          );
          return;
        } else if (error) {
          ToastService.showError(
            "Fehler beim Abrufen des Kandidaten im ATS System!"
          );
          return;
        }
      }

      if (
        this.softwareIntegration?.atsDeterminesStatus &&
        externalAtsStatus.status
      ) {
        this.changeMatchedApplicationStatus(
          externalAtsStatus.status,
          externalAtsStatus.statusUuid,
          externalAtsStatus.status,
          externalAtsStatus.internalStatusSystemName
        );
        return;
      }

      if (
        this.softwareIntegration?.atsDeterminesStatus &&
        status !== atsStatus &&
        atsStatus
      ) {
        this.changeMatchedApplicationStatus(atsStatus);
      } else if (status !== atsStatus && atsStatus) {
        const confirmed = await DialogService.confirm(
          `Der Kandidat #${this.candidate?.applicationId}: ${this.candidate?.firstName} ${this.candidate?.lastName} hat im ATS System den Status ${atsStatus} statt ${status}.`,
          "Beibehalten",
          "Status in ATS ändern!",
          "Status weicht vom ATS ab",
          VuetifyColor.primary,
          VuetifyColor.secondary
        );
        if (confirmed) this.changeAtsStatus(status);
      }
    },
    checkForCandidateDataInput() {
      if (this.candidateDataInput) {
        this.candidateData = this.candidateDataInput;
        (
          this.$refs.generalItemComponent as InstanceType<typeof GeneralItem>
        ).setExpansion(true);
        (
          this.$refs.generalItemComponent as InstanceType<typeof GeneralItem>
        ).setFullyExpansion(true);
        this.prepareFilteredPhoneNumbers(this.candidateDataInput);
        this.prepareFilteredEmail(this.candidateDataInput);
      }
    },
    async checkForFullLoad(noSpinner?: boolean) {
      if (!this.candidate.lastName && !this.candidateData?.lastName) {
        await this.loadCandidate(ExternalSoftware.atsRecruit, noSpinner);
      } else if (!this.candidateData?.lastName) {
        await this.loadCandidate(undefined, noSpinner);
      }
    },
    async checkIfHasToFetchFromAts() {
      if (!AllAtsAdapter.isAtsSystem()) return;
      if (
        !this.candidate?.firstName &&
        !this.candidate?.lastName &&
        !this.candidateData?.firstName &&
        !this.candidateData?.lastName &&
        !this.candidate?.candidateUuid.startsWith(IdPrefix.dispositioner)
      )
        this.loadCandidate(ExternalSoftware.atsRecruit);
    },
    async clearMatchingOject() {
      this.setICM({
        message: InterComponentMessage.matchMeFromCandidate,
        payload: {
          postcodes: "",
          tags: "",
        },
      });
      this.$store.state.isActiveCandidateMatching = false;
      this.closeContextMenu();
    },
    collapseItem() {
      (
        this.$refs.generalItemComponent as InstanceType<typeof GeneralItem>
      ).collapseItem();
    },
    createNewMandantAppointmentOnDependencies() {
      this.isDependenciesActive = true;
      const mandantName = this.$store.getters.getMandantNameByUuid(
        this.matchedApplication.mandantUuid
      );
      this.linkingDescription = {
        firstName: this.candidateData?.firstName,
        lastName: this.candidateData?.lastName,
        postCode: this.candidateData?.addressPostalCode,
        city: this.candidateData?.addressCity,
        customerName: mandantName,
        uuid: this.candidateData?.uuid,
      } as LinkingDescription;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).createNewAppointment();
        }
      });
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    deactivateSubcomponents() {
      this.isDialogEditCandidateActive = false;
      this.isDialogInterviewCompletedActive = false;
      this.isDialogManualAnzeigendatenSearchActive = false;
    },
    async deleteCandidate(uuid?: string) {
      let id = this.candidate.parentObjectid as string;
      if (this.candidateData && this.candidateData._id)
        id = this.candidateData._id;
      const confirmed = await DialogService.confirm(
        `#${this.candidate.applicationId}: <strong>${this.candidateData?.firstName} ${this.candidateData?.lastName}</strong>`,
        "Abbrechen",
        "Löschen",
        "Kandidat wirklich löschen?",
        VuetifyColor.primary,
        VuetifyColor.error
      );
      if (confirmed && this.candidateData && this.candidateData._id) {
        if (this.$store.getters.hasLinkingForCandidate(id)) {
          const linkingService = new LinkingService();
          const responseLinking =
            await linkingService.deleteLinkingsByTypeAndId(Role.candidate, id);
          if (responseLinking.error) {
            ToastService.showError(
              "Fehler beim Löschen des Kunden: Verlinkungen konnten nicht entfernt werden!" +
                responseLinking.error
            );
            return;
          }
        }
        if (uuid) {
          this.candidateService.deleteCandidateByUuid(uuid).then(() => {
            this.$emit(CandidateItemEmit.loadCandidates);
          });
          return;
        }
        this.candidateService
          .removeCandidate(this.candidateData._id)
          .then(() => {
            this.$emit(CandidateItemEmit.loadCandidates);
          });
      }
    },
    deleteCandidateFromWpPlugin() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (this.$refs.sendCandidateToWpComponent) {
        (
          this.$refs.sendCandidateToWpComponent as InstanceType<
            typeof SendCandidateToWp
          >
        ).deleteCandidateFromWP(this.candidate.applicationId);
      }
      if (this.candidateData && this.candidateData.isPublishedOnWebsite) {
        this.candidateData.isPublishedOnWebsite = false;
      }
      this.updateCandidateData;
    },
    deleteProfile(profileId: string) {
      DialogService.confirm(
        `Das gespeicherte Profil wirklich löschen?`,
        "Behalten",
        "Löschen",
        "Profil löschen",
        VuetifyColor.primary,
        VuetifyColor.error
      ).then((confirmed) => {
        if (confirmed && this.candidateData && this.candidateData.profiles) {
          this.candidateData.profiles = this.candidateData.profiles.filter(
            (profile) => profile._id !== profileId
          );
          this.updateCandidateData();
        }
      });
    },
    emitToggleItemsMinimized() {
      this.$emit(CandidateItemEmit.toggleItemsMinimized);
      this.closeContextMenu();
    },
    emitLoadCandidatesFull() {
      this.$emit(CandidateItemEmit.loadCandidatesFull);
      this.closeContextMenu();
    },
    async emitLoadCandidatesFromAts() {
      const confirmed = await DialogService.confirm(
        `Die komplette Spalte mit den Kandiaten aus dem ATS überschreiben?`,
        "Behalten",
        "Überschreiben",
        "Alle Kandidaten überschreiben?",
        VuetifyColor.success,
        VuetifyColor.error
      );
      if (confirmed) {
        this.$emit(CandidateItemEmit.loadCandidatesFromAts);
      }
      this.closeContextMenu();
    },
    async fetchJobAdLeads(ai?: boolean) {
      if (!this.candidateData || !this.softwareIntegration.indexAnzeigendaten) {
        return;
      }
      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;
      const query = {
        POSTAL_CODE: this.candidateData.addressPostalCode,
      } as JodAdLeadsQuery;
      if (mobilityRadius > 0) {
        query.SURROUNDING_REGION = mobilityRadius.toString();
      }

      if (this.careerLevel && this.careerLevel.length) {
        query.CAREER_LEVEL = this.careerLevel.join(",");
      }

      if (this.candidateData && ai) {
        SpinnerService.showSpinner();
        const anonymizedResume = CandidateDataHelper.prepareAnonymizedResume(
          this.candidateData
        );
        const aiService = new AiService();
        const response = await aiService.generateJobAdLeadsQuery(
          anonymizedResume
        );
        query.JOB_TITLE = response.jobTitle;
        query.JOB_POSTING = response.searchstrings;
      } else {
        if (this.skillsForJobAdLeads.length > 5) {
          query.JOB_POSTING = this.skillsForJobAdLeads.join(" OR ");
        }

        if (
          this.candidateData &&
          this.candidateData.tags &&
          this.candidateData.tags.length
        ) {
          query.JOB_TITLE = this.candidateData.tags.join(" OR ");
        }
      }

      try {
        const mandant = this.matchedApplication.mandantUuid;
        const leads = await this.anzeigedatenService.getLeads(query, mandant);
        if (this.candidateData && leads) {
          this.candidateData.jobAdLeads = leads;
        } else if (this.candidateData) {
          const retrievalDate = moment().format("DD.MM.YYYY");
          this.candidateData.jobAdLeads = [
            {
              adId: "notFound",
              retrievalDate: retrievalDate,
              jobTitle: "keine Anzeigen gefunden",
              jobAdText: "",
            },
          ];
        }
        this.updateCandidateData();
        SpinnerService.removeSpinner();
        if (
          this.candidateData &&
          this.candidateData.jobAdLeads &&
          this.candidateData.jobAdLeads[0].adId === "notFound" &&
          !ai
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error fetching job leads:", error);
        SpinnerService.removeSpinner();
      }
    },
    async fetchJobAdLeadsManual(query: DataForJobAdLeadsQuery) {
      SpinnerService.showSpinner();
      const anzeigendatenQuery = {
        JOB_TITLE: query.tags.join(" OR "),
        POSTAL_CODE: query.postcode,
        SURROUNDING_REGION: query.radius,
        JOB_POSTING: query.skills.join(" OR "),
        CAREER_LEVEL: query.careerLevel ? query.careerLevel.join(",") : "",
      } as unknown as JodAdLeadsQuery;
      const differentPeriod = query.period;

      try {
        const mandant = this.matchedApplication.mandantUuid;
        const response = await this.anzeigedatenService.getLeads(
          anzeigendatenQuery,
          mandant,
          differentPeriod
        );
        return response;
      } catch (error) {
        ToastService.showError("Fehler beim abrufen der Anzeigendaten");
        console.error("Error fetching Index anzeigendaten:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    filterCandidate(searchTerm: string) {
      if (searchTerm === "") {
        this.showCandidate();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");
      const candidate = this.candidateData;
      if (candidate) {
        const matches = terms.every(
          (term) =>
            this.searchCandidateObject(candidate, term) ||
            this.searchCandidateAllFields(this.candidate, term)
        );

        if (!matches) {
          this.hideCandidate();
        }
      }
    },
    //helping methods
    searchCandidateAllFields(candidate: CandidateList, term: string) {
      return Object.values(candidate).some((value) => {
        return (
          typeof value === "string" &&
          value !== null &&
          value.toLowerCase().includes(term)
        );
      });
    },
    searchCandidateObject(candidate: Candidate, term: string) {
      return (
        this.searchField(candidate.addressHouseNumber, term) ||
        (candidate.additionalAddresses &&
          candidate.additionalAddresses.some(
            (address) =>
              this.searchField(address.houseNumber, term) ||
              this.searchField(address.city, term) ||
              this.searchField(address.postalCode, term) ||
              this.searchField(address.street, term)
          )) ||
        this.searchField(candidate.addressCity, term) ||
        this.searchField(candidate.addressPostalCode, term) ||
        this.searchField(candidate.addressStreet, term) ||
        (candidate.communicationMeans &&
          candidate.communicationMeans.some(
            (communicationMean) =>
              this.searchField(communicationMean.description, term) ||
              this.searchField(communicationMean.value, term)
          )) ||
        this.searchField(candidate.lastName, term) ||
        (candidate.skills &&
          candidate.skills.some((skill) =>
            this.searchField(skill.description, term)
          )) ||
        this.searchField(candidate.firstName, term) ||
        (candidate.careerSteps &&
          candidate.careerSteps.some(
            (careerStep) =>
              this.searchField(careerStep.at, term) ||
              this.searchField(careerStep.details, term) ||
              this.searchField(careerStep.title, term)
          )) ||
        (candidate.tags &&
          candidate.tags.some((tag) => this.searchField(tag, term)))
      );
    },
    searchField(field: any, term: string) {
      return field && field.toLowerCase().includes(term);
    },
    findStatusOptionByStatus(
      statusToFind: string
    ): RecruitStatusOption | undefined {
      return this.statusOptionsRecruit.find((option: RecruitStatusOption) =>
        Array.isArray(option.status)
          ? option.status.includes(statusToFind)
          : option.status === statusToFind
      );
    },
    async generateAndSetCandidateTags() {
      if (this.candidateData) {
        const anonymizedResume = CandidateDataHelper.prepareAnonymizedResume(
          this.candidateData
        );
        try {
          const aiService = new AiService();
          const tags = await aiService.generateCandidateTags(anonymizedResume);
          this.candidateData.tags = tags;
          await this.updateCandidateData();
        } catch (error) {
          console.error("Error generating candidate tags:", error);
        }
      }
    },
    async getCandidateDataFromATS() {
      try {
        const atsCandidate = await AllAtsAdapter.getCandidateData(
          this.candidate
        );

        if (atsCandidate) {
          const mergedCandidate = this.candidateService.mergeCandidateData(
            this.candidateData as Candidate,
            atsCandidate
          );

          const response = await this.candidateService.postCandidate(
            mergedCandidate
          );
          return response;
        } else {
          ToastService.showError(
            "Kandidatendaten vom ATS konnten nicht abgerufen werden."
          );
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der Kandidaten-Daten vom ATS."
        );
        throw error;
      }
    },
    async getCandidateData() {
      try {
        let response: Candidate | undefined;
        const candidateId =
          this.candidateData?._id ?? this.candidate.parentObjectid;
        const candidateUuid =
          this.candidateData?.uuid ?? this.candidate.candidateUuid;
        if (candidateId) {
          response = await this.candidateService.getCandidateById(candidateId);
        } else if (candidateUuid) {
          response = await this.candidateService.getCandidateByUuid(
            this.candidate.candidateUuid
          );
        }
        if (response?._id && !candidateId) {
          const candidate = this.candidate;
          candidate.parentObjectid = response._id;
          candidate.candidateUuid = response.uuid;
          this.$emit(CandidateItemEmit.updateCandidate, candidate);
        }
        return response as Candidate;
      } catch (error) {
        console.error(
          `failed loading candidate #${this.candidate?.applicationId}`,
          error
        );
        throw error;
      }
    },
    getCareerLevelDescription() {
      if (!this.candidateData || Object.keys(this.candidateData).length === 0) {
        return;
      }
      if (
        !this.candidateData.careerLevel ||
        this.candidateData.careerLevel.length === 0
      ) {
        return null;
      }

      const firstLevelIndex = this.candidateData.careerLevel[0];
      const lastLevelIndex =
        this.candidateData.careerLevel[
          this.candidateData.careerLevel.length - 1
        ];

      const firstLevel = CareerLevel[firstLevelIndex];
      const lastLevel = CareerLevel[lastLevelIndex];

      if (firstLevelIndex === lastLevelIndex) {
        return `${firstLevel}`;
      } else {
        return `Karrierestufen von ${firstLevel} bis ${lastLevel}`;
      }
    },
    async getCustomerPostcodesInMobilityRange() {
      if (!this.candidateData || !this.candidateData.addressPostalCode) {
        console.error("AdressePlz ist nicht definiert.");
        return [];
      }

      const baseZipcode = this.candidateData.addressPostalCode;
      const targetZipcodes = this.$store.state.postcodesCustomers;

      try {
        const distances: ZipCodeDistance[] =
          await this.zipCodeService.getDistances(baseZipcode, targetZipcodes);
        let mobilityRadius = this.candidate.mobilityRadius ?? 0;
        if (this.candidateData && this.candidateData.mobilityRadius)
          mobilityRadius = this.candidateData.mobilityRadius;
        const filteredZipcodes = distances
          .filter(
            (distance: ZipCodeDistance) => distance.distance <= mobilityRadius
          )
          .map((distance: ZipCodeDistance) => distance.targetZipcode);

        const uniqueZipcodes = [...new Set(filteredZipcodes)];
        return uniqueZipcodes;
      } catch (error) {
        console.error("Fehler beim Abrufen der Distanzen: ", error);
        return [];
      }
    },
    getDispositionerStatus() {
      const status = this.matchedApplication?.status;
      const statusText = this.$store.getters.getStatusTextByValue(status ?? "");
      return statusText;
    },
    handleCheckMatch(payload: {
      postcodes: string[];
      tags: string[];
      threshold: number;
    }) {
      const candidatePostalCode = this.candidate.addressPostalCode;

      if (
        candidatePostalCode &&
        payload.postcodes.includes(candidatePostalCode)
      ) {
        const perfectmatch = (this.customerOrDemandTagsMatching =
          checkTagsMatching(
            payload.tags || [],
            this.candidate.tags || [],
            this.$store.state.company.aiData.prompt.company
              .tagsMatchingThreshold ?? 0.7
          ));
        if (perfectmatch) {
          this.item.border = "secondary lg";
          this.item.color = "cardPerfectMatch";
          this.item.colorList = "cardPerfectMatch";
          this.item.hoverColor = "perfectMatchHover";
        } else {
          this.item.border = "primary lg";
          this.item.color = "cardMatch";
          this.item.colorList = "cardMatch";
          this.item.hoverColor = "matchHover";
        }
        this.$store.state.isActiveCustomerOrDemandMatching = true;
      } else {
        this.item.elevation = 2;
        this.item.border = this.vStyle.boardItem.border;
        this.item.color = "card";
        this.item.colorList = undefined;
        this.item.hoverColor = "cardHover";
        this.customerOrDemandTagsMatching = false;
      }
    },
    handleDragStart(event: DragEvent) {
      if (event.dataTransfer) {
        event.dataTransfer.setData(
          DragEventSpec.originComponent,
          Role.candidate as string
        );
        this.sendCandidateToGenerateProfile();
      }
    },
    handleDragOver(event: DragEvent) {
      event.preventDefault();
      const originComponent = this.$store.state.dragOriginComponent;

      switch (originComponent) {
        case Role.demand:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "Verlinkung Anfrage - Kandidat nicht möglich",
          };
          break;

        case Role.customer:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "Um ein Profil zu generieren\nbitte Kandidat auf Firma ziehen",
          };
          break;

        default:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "nicht möglich",
          };
          break;
      }
    },
    handleCandidateStatusUpdate(statusObject: AllLinkingsStatusObject) {
      this.linkingStatuses = statusObject.lastEvents;
      this.updateStatusAndColor(statusObject.highestPriorityStatus);
    },
    handleItemExpansion(isExpanded: boolean) {
      if (isExpanded) {
        this.isExpanded = isExpanded;
        this.updateComponent();
      } else {
        this.isExpanded = false;
        this.deactivateSubcomponents();
      }
    },
    handlePublishedOnWebseiteChange() {
      if (!this.softwareIntegration.wordPressPlugin) {
        return;
      }
      if (
        this.candidateData &&
        this.candidateData.salutationCatalogId !== undefined
      ) {
        const gender = Gender[this.candidateData.salutationCatalogId] || "";
        let qualification = "";
        if (
          this.candidateData.careerLevel &&
          Object.keys(this.candidateData.careerLevel).length > 0
        ) {
          qualification = this.getCareerLevelDescription() ?? "";
        }
        if (this.candidateData.isPublishedOnWebsite) {
          let mobilityRadius = this.candidate.mobilityRadius ?? 0;
          if (this.candidateData && this.candidateData.mobilityRadius)
            mobilityRadius = this.candidateData.mobilityRadius;
          const wpCandidateData = {
            title: this.matchedApplication.appliedAs,
            profileId: this.candidate.applicationId,
            gender: gender,
            age: this.candidateAge?.toString() ?? "",
            residence: this.candidateData.addressCity,
            mobility: `${
              this.candidateData.mobility
                ? this.candidateData.mobility + ": "
                : ""
            }${mobilityRadius ? mobilityRadius + " km" : ""}`,
            shift: this.candidateData.shiftPreference ?? "",
            qualification: qualification,
            licenses: this.licences.replace(/<br>/g, ", "),
            experience: this.candidateData.tags.join(", "),
            anonymizedResume: CandidateDataHelper.prepareAnonymizedResume(
              this.candidateData
            ),
          };

          if (this.$refs.sendCandidateToWpComponent) {
            (
              this.$refs.sendCandidateToWpComponent as InstanceType<
                typeof SendCandidateToWp
              >
            ).openModal(wpCandidateData);
          }
          this.candidateData.isPublishedOnWebsite = true;
          this.updateCandidateData();
        } else if (this.candidateData.isPublishedOnWebsite === false) {
          this.deleteCandidateFromWpPlugin();
        }
      }
    },
    async handleSaveResumeInterview(resumeText: string) {
      if (!this.candidateData) return;
      await this.saveInterviewResult(resumeText);
      await this.loadCandidate();
      this.candidateData.isPublishedOnWebsite = true;
      await this.updateCandidateData();
      this.handlePublishedOnWebseiteChange();
    },
    handleSendCandidateToGenerateProfile() {
      if (!this.candidateData?._id) {
        this.loadCandidate().then(() => {
          this.sendCandidateToGenerateProfile();
        });
      } else {
        this.sendCandidateToGenerateProfile();
      }
    },
    hideCandidate() {
      this.isHided = true;
    },
    async initializeComponent() {
      this.checkForCandidateDataInput();
      this.setDefaultStatus();
      this.autoCheckForJobAdLeads();
      if (
        this.candidateData &&
        this.candidateData.notes &&
        this.candidateData.notes.interviewResult
      ) {
        this.interviewResultEdit = this.candidateData.notes.interviewResult;
      } else {
        this.interviewResultEdit = "";
      }
      if (this.candidate.birthDate)
        this.candidateAge = CandidateDataHelper.calculateAge(
          this.candidate.birthDate
        );
      this.$nextTick().then(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).loadLinkingsFromStore();
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).checkForReminders();
        }
        this.checkIfHasToFetchFromAts().then(() => {
          this.checkAtsStatus();
        });
      });
    },
    isCandidateActiveForPlacement(): boolean {
      if (!this.candidateData) {
        return false;
      }

      if (this.candidateData.isPublishedOnWebsite === undefined) {
        this.candidateData.isPublishedOnWebsite = false;
      }

      if (
        this.candidate.status === this.statusOptionsRecruit[0].value ||
        this.candidate.status === this.statusOptionsRecruit[1].value
      ) {
        return false;
      }

      return true;
    },
    isOpenedAsDialog(): boolean {
      return !!this.candidateDataInput;
    },
    isStatusInterview() {
      const statusArray = this.$store.getters.getStatusByValue(
        this.selectedStatus
      );
      return statusArray.includes(LinkingStatus.interview);
    },
    loadAvatarFromAtsRecruit() {
      if (
        this.candidate &&
        this.$store.state.company.apiKeys.zvooveNextLevel &&
        this.candidate.avatarUuid
      ) {
        AllAtsAdapter.getCandidateAvatar(this.candidate.avatarUuid).then(
          (response) => {
            if (response) {
              this.avatar = URL.createObjectURL(response);
            } else {
              this.avatar = "";
              DialogService.alert(
                `<i class="fa-solid fa-ban"></i> Ups, anscheinend hast Du Daten aus dem ATS-System entfernt: ich konnte den <strong>Avatar von ${this.candidate.firstName} ${this.candidate.lastName} ID: #${this.candidate.applicationId}</strong> nicht laden!<br><br>Ich werde das nun bei mir im System anpassen!`,
                "OK"
              );
              this.removeAvatarFromCandidate();
            }
          }
        );
      } else {
        this.avatar = "";
      }
    },
    async loadCandidate(sourceCandidate?: string, noSpinner?: boolean) {
      let showSpinner = true;
      if (noSpinner) showSpinner = false;
      try {
        if (sourceCandidate) {
          this.isLoadingFromAts = true;
        } else {
          if (showSpinner) SpinnerService.showSpinner();
        }
        let data = {} as Candidate | undefined;
        if (sourceCandidate === ExternalSoftware.atsRecruit) {
          data = await this.getCandidateDataFromATS();
          ToastService.show(
            `Recruit Daten von ${data?.firstName} ${data?.lastName} aktualisiert!`
          );
        } else {
          data = await this.getCandidateData();
          if (!data?._id) {
            data = await this.getCandidateDataFromATS();
          }
        }
        if (data) this.prepareFilteredPhoneNumbers(data);
        if (data) this.prepareFilteredEmail(data);
        if (data) {
          const { licences, skills } =
            CandidateDataHelper.extractLicensesAndSkillOfCandidate(data);
          this.licences = licences;
          this.skillsForJobAdLeads = skills;
        }
        if (data && data.birthDate) {
          this.candidateAge = CandidateDataHelper.calculateAge(data.birthDate);
        }
        if (data)
          this.$emit(CandidateItemEmit.updatePostcode, data.addressPostalCode);
        this.candidateData = data;
        if (data && data.notes?.interviewResult)
          this.interviewResultEdit = data.notes.interviewResult;
      } catch (error: any) {
        console.error("Fehler beim Abrufen der Bewerberdaten:", error);
      } finally {
        if (sourceCandidate) {
          this.isLoadingFromAts = false;
        } else {
          if (showSpinner) SpinnerService.removeSpinner();
        }
      }
    },
    openAnzeigendatenManualSearch() {
      this.isDialogManualAnzeigendatenSearchActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogManualAnzeigendatenSearchComponent) {
          (
            this.$refs.dialogManualAnzeigendatenSearchComponent as InstanceType<
              typeof DialogManualAnzeigendatenSearch
            >
          ).openModal();
        }
      });
    },
    openApplicationFormDispatcherMode() {
      if (this.$refs.dialogApplicationFormComponent) {
        (
          this.$refs.dialogApplicationFormComponent as InstanceType<
            typeof DialogApplicationForm
          >
        ).openModal(true);
      }
    },
    openContextMenu(event: MouseEvent) {
      if (!this.candidateData?._id) this.loadCandidate();
      event.preventDefault();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuWidth = 250;
      const menuHeight = 280;

      let xPos = event.clientX;
      let yPos = event.clientY;

      if (xPos + menuWidth > windowWidth) {
        xPos = windowWidth - menuWidth;
      }

      if (yPos + menuHeight > windowHeight) {
        yPos = windowHeight - menuHeight;
      }

      if (xPos < 0) {
        xPos = 0;
      }
      this.contextMenuPosition = { x: xPos, y: yPos };
      this.showContextMenu = true;
    },
    openEditCandidateModal() {
      this.isDialogEditCandidateActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.dialogEditCandidateComponent) {
          (
            this.$refs.dialogEditCandidateComponent as InstanceType<
              typeof DialogEditCandidate
            >
          ).openModal();
        }
      });
      this.closeContextMenu();
    },
    openPhoneClient() {
      (
        this.$refs.generalItemComponent as InstanceType<typeof GeneralItem>
      ).setExpansion(true);
      this.$nextTick().then(() => {
        this.updateComponent().then(() => {
          if (this.$refs.phoneClientComponent) {
            (
              this.$refs.phoneClientComponent as InstanceType<
                typeof PhoneClient
              >
            ).handleOutgoingCall();
          }
        });
      });
    },
    phoneClientIsDialing() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).waitForPhoneClientDialing();
      }
    },
    async placementWebSearch() {
      if (!this.candidateData) return;
      try {
        SpinnerService.showSpinner();
        const aiService = new AiService();
        const response = await aiService.placementWebSearch(
          CandidateDataHelper.prepareAnonymizedResume(this.candidateData),
          (this.candidateData?.mobilityRadius?.toString() ?? "10") + " km",
          this.candidateData?.addressPostalCode ?? ""
        );
        this.$store.commit(MutationType.setRecurionChat, {
          currentRemoteRecurionMessage: response,
          isActive: true,
          isClosed: false,
          chatbotType: RecurionChatbotType.handbook,
          initPrompt: "",
        } as RecurionChatMenu);
        this.$store.commit(MutationType.setRecurionChatOpen);
      } catch (error) {
        console.error("Error in placementWebSearch:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    prepareDataForJobAdLeadsQuery(): DataForJobAdLeadsQuery {
      if (!this.candidateData || !this.candidateData.skills) {
        return {
          tags: [],
          postcode: "",
          radius: 0,
          period: this.softwareIntegration.indexJobLeadsAgeLimitDays ?? 30,
          skills: [],
          careerLevel: [],
        };
      }
      const skills = this.candidateData.skills
        .filter(
          (skill: any) =>
            skill.groupDescription !== "Schichtbereitschaft" &&
            skill.groupDescription !== "Mobilitäten"
        )
        .map((skill: any) => skill.description);

      let mobilityRadius = this.candidate.mobilityRadius ?? 0;
      if (this.candidateData && this.candidateData.mobilityRadius)
        mobilityRadius = this.candidateData.mobilityRadius;

      const dataForJobAdLeadsQuery = {
        tags: this.candidateData.tags ?? [],
        postcode: this.candidateData.addressPostalCode ?? "",
        radius: mobilityRadius,
        period:
          this.$store.state.company.softwareIntegration
            .indexJobLeadsAgeLimitDays ?? 30,
        skills: skills,
        careerLevel: this.candidateData.careerLevel ?? [],
      } as DataForJobAdLeadsQuery;

      return dataForJobAdLeadsQuery;
    },
    prepareFilteredEmail(data: Candidate) {
      this.filteredEmail =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) => c.type === CommunicationType.Email
          )
          .map((c: CommunicationMeans) => {
            const salutationText = getSalutationText(data.salutationCatalogId);
            return {
              mailaddress: c.value,
              salutation: `Guten Tag ${salutationText} ${data.firstName} ${data.lastName},`,
            };
          }) || [];
    },
    prepareFilteredPhoneNumbers(data: Candidate) {
      this.filteredPhoneNumbers =
        data?.communicationMeans
          ?.filter(
            (c: CommunicationMeans) =>
              c.type === CommunicationType.Phone ||
              c.type === CommunicationType.Mobile ||
              c.type === CommunicationType.WorkMobile ||
              c.type === CommunicationType.WhatsApp
          )
          .map((c: CommunicationMeans) => ({
            label: c.description,
            value: c.value.replace(/ /g, ""), // delete "space" between the numbers
          })) || [];
    },
    async removeTag(index: number) {
      if (!this.candidateData) return;
      this.candidateData.tags.splice(index, 1);
      await this.updateCandidateData();
    },
    saveInterviewResultDebounced: debounce(function (
      this: any,
      resumeText: string
    ) {
      this.saveInterviewResult(resumeText);
    },
    10000),
    async saveInterviewResult(resumeText: string) {
      if (!this.candidateData) return;
      if (!this.candidateData.notes) {
        this.candidateData.notes = {
          interviewResult: "",
          information: "",
        };
      }
      this.candidateData.notes.interviewResult = resumeText;
      await this.updateCandidateData();
      ToastService.show("Ergebnis Vorstellungstermin gespeichert!");
    },
    async searchAnzeigendatenAndAdd(query: DataForJobAdLeadsQuery) {
      if (!this.candidateData) return;

      try {
        const response = await this.fetchJobAdLeadsManual(query);

        if (!this.candidateData.jobAdLeads) {
          this.candidateData.jobAdLeads = [];
        }

        const existingAdIds = new Set(
          this.candidateData.jobAdLeads.map((ad: JobAdLead) => ad.adId)
        );

        const newJobAds = (response || []).filter((ad: JobAdLead) => {
          return !existingAdIds.has(ad.adId);
        });

        this.candidateData.jobAdLeads = [
          ...this.candidateData.jobAdLeads,
          ...newJobAds,
        ];

        await this.updateCandidateData();

        ToastService.showSuccess("Neue Job Leads hinzugefügt!");
      } catch (error) {
        console.error("Error adding job ads:", error);
      }
    },
    async searchAnzeigendatenAndReplace(query: DataForJobAdLeadsQuery) {
      if (this.candidateData) {
        const response = await this.fetchJobAdLeadsManual(query);
        this.candidateData.jobAdLeads = response;
        await this.updateCandidateData();
      }
    },
    async searchForCandidateDataInAts() {
      if (!this.candidateData) return;
      try {
        SpinnerService.showSpinner();
        const result = await AllAtsAdapter.getCandidateDataFromCandidate(
          this.candidateData
        );
        if (result?.error === BackendMessage?.employee?.candidateNotFound) {
          this.showDialogExtractCandidateUuidFromAtsRecruitLink = true;
          return;
        } else if (result?.response?.length > 0) {
          this.resultsCandidateSearch = result.response;
          this.showDialogSelectAtsCandidates = true;
        }
      } catch (error) {
        console.error("Error searching for candidate data in ATS:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    searchForCandidateDataInAtsReceiveCandidateUuid(newUuid: string) {
      if (!newUuid) return;
      const candidate = { ...this.candidateData } as Candidate;
      candidate.uuid = newUuid;
      candidate.isNotInAts = false;
      this.selectCandidateToUpdateAndCheckForDuplicates(candidate);
    },
    async sendCandidateToGenerateProfile() {
      this.$store.commit(MutationType.clearDraggingItem);
      this.checkForFullLoad(true).then(() => {
        if (!this.candidateData) return;

        this.$store.commit(MutationType.setDraggingItem, {
          type: Role.candidate,
          data: {
            candidateData: this.candidateData,
            candidate: this.candidate,
          },
        });
      });
    },
    sendCandidateToWpAborted() {
      if (this.candidateData) {
        this.candidateData.isPublishedOnWebsite = false;
      }
    },
    sendCandidateToAts() {
      if (this.candidateData) {
        this.$emit(CandidateItemEmit.sendCandidateToAts, this.candidateData);
      }
    },
    setAppointmentOnDependencies() {
      if (!this.hasLinking(this.candidate.parentObjectid)) {
        this.isDependenciesActive = true;
        this.$nextTick(() => {
          if (
            this.$refs.dependenciesComponent &&
            this.candidateData?._id &&
            this.matchedMandantUuid
          ) {
            (
              this.$refs.dependenciesComponent as InstanceType<
                typeof Dependencies
              >
            ).linkCandidateToMandant(
              this.candidateData?._id,
              this.matchedMandantUuid
            );
          }
        });
      } else {
        if (this.$refs.dependenciesComponent && this.candidateData?._id) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).openAppointmentDialog();
        }
      }
    },
    setCandidateToMandantFromDependencies(data: {
      status: string;
      dates?: string[];
    }): void {
      const statusMatch = this.findStatusOptionByStatus(data.status);
      let statusDates = "";
      if (data.dates && data.dates.length > 0) {
        statusDates = data.dates.join(", ");
      }
      this.message.body = `${status} ${statusDates}`;

      if (statusMatch) {
        this.changeStatus(statusMatch.value);
        const formattedAppointments = data.dates
          ? data.dates
              .map((date) => moment(date).format("DD.MM.YYYY [um] HH:mm [Uhr]"))
              .join(", ")
          : "";
        this.setMessageTemplate(data.status, formattedAppointments);
        if (statusMatch.status[0] === LinkingStatus.interviewCompleted) {
          this.loadCandidate();
          (this.isDialogInterviewCompletedActive = true),
            this.$nextTick().then(() => {
              // We do that explicit NOT in this.actionsFromStatusChange() it has to start ONLY in this case
              if (this.$refs.dialogInterviewCompletedComponent) {
                (
                  this.$refs.dialogInterviewCompletedComponent as InstanceType<
                    typeof DialogInterviewCompleted
                  >
                ).openSkillsModal();
              }
            });
        }
      } else {
        ToastService.showError("Kein entsprechender Status gefunden.");
      }
    },
    setDefaultStatus() {
      const defaultStatus = LinkingStatus.noneProfile;
      this.updateStatusAndColor(defaultStatus);
    },
    setMessageTemplate(candidateStatus: string, appointments?: string) {
      let subjectTemplate = "";
      let messageTemplate = "";

      const templates = this.mailTemplates;
      const matchingTemplate = templates.find(
        (template: {
          candidateStatus: string;
          subject: string;
          text1: string;
          text2: string;
        }) => template.candidateStatus === candidateStatus
      );

      if (matchingTemplate) {
        const mandant = this.$store.getters.getMandantByUuid(
          this.matchedMandantUuid
        );
        const mandantAddress = mandant
          ? mandant.contact.replace(/<br>/g, "\n")
          : "Adresse nicht verfügbar";
        if (
          matchingTemplate.candidateStatus === LinkingStatus.interview ||
          matchingTemplate.candidateStatus === LinkingStatus.contractDate
        ) {
          subjectTemplate = `${matchingTemplate.subject} am ${appointments}`;
          messageTemplate = `\n${matchingTemplate.text1}\n\nam ${appointments}\n\n${mandant.name}\n${mandantAddress}\n\n${matchingTemplate.text2}`;
        } else {
          subjectTemplate = matchingTemplate.subject;
          messageTemplate = `\n${matchingTemplate.text1}\n${matchingTemplate.text2}`;
        }
      } else {
        console.error(
          "Keine passende E-Mail Vorlage gefunden für den Status: ",
          candidateStatus
        );
      }
      this.message = {
        subject: subjectTemplate,
        body: messageTemplate,
      };

      if (this.$refs.mailClientComponent) {
        (
          this.$refs.mailClientComponent as InstanceType<typeof MailClient>
        ).openModal();
      }

      ToastService.show(
        `Eine Nachricht ist im Messenger für Versand vorbereitet: Betreff: ${subjectTemplate}`
      );
    },
    async selectCandidateToUpdateAndCheckForDuplicates(
      selectedCandidate: Candidate
    ) {
      let mergedCandidate = this.candidateService.mergeCandidateData(
        this.candidateData as Candidate,
        selectedCandidate
      );

      mergedCandidate.isNotInAts = false;

      const existingCandidate = await this.candidateService.getCandidateByUuid(
        mergedCandidate.uuid
      );

      if (existingCandidate) {
        this.candidateToCompareExisting = existingCandidate;
        this.candidateToCompareCurrent = mergedCandidate;
        this.showDialogCompareCandidates = true;
        return;
      }

      const response = await this.candidateService.updateCandidate(
        mergedCandidate
      );
      if (response.error) {
        ToastService.showError(response.error);
      } else {
        this.candidateData = response.response;
      }
    },
    async selectComparedCandidate(current: boolean) {
      if (!this.candidateToCompareExisting || !this.candidateToCompareCurrent)
        return;
      let candidatToDelete = this.candidateToCompareCurrent;
      let mergedCandidate = this.candidateService.mergeCandidateData(
        this.candidateToCompareCurrent as Candidate,
        this.candidateToCompareExisting as Candidate
      );
      if (current) {
        mergedCandidate = this.candidateService.mergeCandidateData(
          this.candidateToCompareExisting as Candidate,
          this.candidateToCompareCurrent as Candidate
        );
        candidatToDelete = this.candidateToCompareExisting;
      }

      try {
        SpinnerService.showSpinner();
        const response = await this.candidateService.updateCandidate(
          mergedCandidate
        );

        if (response._id && candidatToDelete._id) {
          this.updateCandidateData(response);
          this.candidateService.removeCandidate(candidatToDelete._id);
        }
      } catch (error) {
        console.error("Error selecting compared candidate:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    setCandidateAddressForCommuteWebSearch() {
      if (this.candidateData) {
        const nameLabel = `${this.candidateData.firstName} ${
          this.candidateData.lastName
        } ${
          this.candidate.applicationId
            ? " #" + this.candidate.applicationId
            : ""
        }`;
        this.$store.commit(
          MutationType.setCandidateAddressForCommuteWebSearch,
          {
            nameLabel: nameLabel,
            mobility: this.candidateData.mobility,
            street: this.candidateData.addressStreet,
            number: this.candidateData.addressHouseNumber,
            postalCode: this.candidateData.addressPostalCode,
            city: this.candidateData.addressCity,
          } as CandidateAddressForCommuteWebSearch
        );
        DialogService.alert(
          `Ich habe mir ${nameLabel} gemerkt!<br><br>Ich kann den Arbeitsweg recherchieren:<br><span style="font-size: 1rem;">Klicke dazu mit der rechten Maustaste auf einen Kunden und wähle "Arbeitsweg von ${nameLabel}" aus.</span>`,
          "Verstanden"
        );
      }
    },
    showAtsConnectionOptions() {
      return this.candidateData?.isNotInAts && AllAtsAdapter.isAtsSystem();
    },
    showCandidate() {
      this.isHided = false;
    },
    async showExistingProfile(profile: Profile) {
      // = ATS Profile
      if (profile.uuid) {
        try {
          SpinnerService.showSpinner();
          const atsRecruitService = new AtsRecruitService();
          const profilePdf = await atsRecruitService.getProfileAsPdf(
            profile.uuid
          );
          const pdfBlob = new Blob([profilePdf], { type: DataType.pdf });
          const blobUrl = URL.createObjectURL(pdfBlob);

          window.open(blobUrl, "_blank", PopUpWindowSize.profile);
        } catch (error) {
          console.error("Error getting profile as pdf:", error);
        } finally {
          SpinnerService.removeSpinner();
        }
      } else if (profile.html) {
        const popupWindow = window.open("", "_blank", PopUpWindowSize.profile);
        if (popupWindow) {
          popupWindow.document.write(profile.html);
          popupWindow.document.close();
        } else {
          ToastService.showError("Profil konnte nicht geöffnet werden.");
        }
      }
    },
    showExistingProfileIcon() {
      return (
        this.candidateData &&
        this.candidateData.profiles &&
        this.candidateData.profiles.length > 0
      );
    },
    showGdprConsentIcon() {
      return !!this.candidateData?.applications?.[
        this.candidateData.applications.length - 1
      ]?.dataPrivacyDeadline;
    },
    showGdprConsentCred() {
      if (!this.showGdprConsentIcon()) return;
      const lastApplication =
        this.candidateData?.applications?.[
          this.candidateData.applications.length - 1
        ];
      if (!lastApplication || !lastApplication.dataPrivacyDeadline) return;

      const deadline = moment(lastApplication.dataPrivacyDeadline);
      const dataStorageDeclinedDate = lastApplication.dataStorageDeclined
        ? moment(lastApplication.dataStorageDeclined)
        : null;
      const dataStorageGrantedByDate = lastApplication.dataStorageGrantedBy
        ? moment(lastApplication.dataStorageGrantedBy)
        : null;

      const dataStorageDeclined = dataStorageDeclinedDate
        ? `wurde abgelehnt ${dataStorageDeclinedDate.format("DD.MM.YYYY")}`
        : "";
      const dataStorageGrantedBy = dataStorageGrantedByDate
        ? `wurde akzeptiert am: ${dataStorageGrantedByDate.format(
            "DD.MM.YYYY"
          )}`
        : "";

      const formattedDeadline = deadline.format("DD.MM.YYYY");
      const currentDate = moment();
      const twoMonthsFromNow = moment().add(2, "months");

      let color;
      let tooltip = `<h4>Datenschutz `;
      let badgeColor = undefined;
      let badgeContent = "!";
      let privacyPolicy = PrivacyPolicyStatus.pending;

      if (
        dataStorageGrantedByDate &&
        (!dataStorageDeclinedDate ||
          dataStorageGrantedByDate.isAfter(dataStorageDeclinedDate))
      ) {
        badgeColor = "success";
        badgeContent = "✔";
        tooltip += "✅";
        privacyPolicy = PrivacyPolicyStatus.granted;
      } else if (dataStorageDeclinedDate) {
        badgeColor = "error";
        badgeContent = "✗";
        tooltip += "❌";
        privacyPolicy = PrivacyPolicyStatus.declined;
      }

      tooltip += `</h4>gültig bis: <strong>${formattedDeadline}</strong>${
        dataStorageGrantedBy ? "<br>" + dataStorageGrantedBy : ""
      }${dataStorageDeclined ? "<br>" + dataStorageDeclined : ""}`;

      if (deadline.isBefore(currentDate)) {
        color = "error";
      } else if (deadline.isBefore(twoMonthsFromNow)) {
        color = "warning";
      }

      const options = {
        badgeColor,
        badgeContent,
        color,
        privacyPolicy,
        tooltip,
      };
      return options;
    },
    async removeAvatarFromCandidate() {
      if (!this.candidateData?._id) await this.loadCandidate();
      this.$nextTick(() => {
        const candidate = { ...this.candidateData } as Candidate;
        candidate.avatar.uuid = "";
        this.updateCandidateData(candidate);
      });
    },
    updateCareerLevels(data: IaCareerLevel[]) {
      if (this.candidateData) {
        this.candidateData.careerLevel = data;
        this.updateCandidateData();
      }
    },
    async updateComponent() {
      SpinnerService.showSpinner();
      try {
        this.loadCandidate().then(() => {
          this.checkAtsStatus().then(() => {
            if (
              this.candidateData &&
              (!this.candidateData.tags ||
                this.candidateData.tags.length === 0 ||
                this.candidateData.careerSteps.length === 0)
            ) {
              if (
                this.candidateData.careerSteps &&
                this.candidateData.careerSteps.length > 0
              ) {
                this.generateAndSetCandidateTags();
              }
            }
            if (this.$refs.dependenciesComponent) {
              (
                this.$refs.dependenciesComponent as InstanceType<
                  typeof Dependencies
                >
              ).loadLinkingsFromStore();
            }
            this.autoCheckForJobAdLeads();
          });
        });
      } catch (error) {
        console.error("Error updating CandidateItem:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    updateStatusAndColor(status: LinkingStatus) {
      const statusColors = this.$store.state.company.candidateStatusColor;
      let color = "";

      switch (status) {
        case LinkingStatus.interviewSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.interviewExternal];
          break;
        case LinkingStatus.trailWorkSuggestionExternal:
          this.needToContact = true;
          color = statusColors[LinkingStatus.trailWorkExternal];
          break;
        case LinkingStatus.jobofferExternal:
          this.needToContact = true;
          color = statusColors[status];
          break;
        default:
          this.needToContact = false;
          color = statusColors[status] || "#244578";
      }

      this.statusColor = color;
      // for sort option in column
      const candidate = this.candidate;
      candidate.linkingStatus = status;
      this.$emit(CandidateItemEmit.updateCandidate, candidate);
    },
    async updateCandidateData(updatedCandidate?: Candidate) {
      const candidateData = updatedCandidate ?? this.candidateData;
      if (candidateData) {
        this.candidateService
          .updateCandidate(candidateData)
          .then((candidateDataFromBackend) => {
            this.candidateData = candidateDataFromBackend;
            this.prepareFilteredPhoneNumbers(candidateData);
            this.prepareFilteredEmail(candidateData);
            const { licences, skills } =
              CandidateDataHelper.extractLicensesAndSkillOfCandidate(
                candidateData
              );
            this.licences = licences;
            this.skillsForJobAdLeads = skills;
          });
      }
    },
    updateCandidateDataFromEditDialog(candidate: Candidate) {
      this.candidateData = candidate;
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.candidateData) return;
      this.candidateData.checklist = checklist;
      this.updateCandidateData();
    },
  },
});
</script>

<style scoped>
.appointment-icon {
  margin-right: var(--dispatcher-board-communication-icon-margin-right);
  cursor: pointer;
  transition: all 0.3s ease;
}
.appointment-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.save-interview-result {
  font-size: 3rem;
  cursor: pointer;
  top: -2.4rem;
  right: 1rem;
  color: var(--color-primary);
  transition: all 0.3s ease;
}

.save-interview-result:hover {
  color: var(--color-tertiary);
}

.zvoove-recuit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.8rem;
  height: 1.8rem;
}
.zvoove-recuit-icon:hover {
  scale: var(--medium-scale-up);
}
.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
