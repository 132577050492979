import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!

  return (_openBlock(), _createBlock(_component_v_tabs, {
    modelValue: _ctx.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    "align-tabs": "start",
    "bg-color": "primary-darken-1",
    "slider-color": "primary-darken-1",
    height: "30"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_tab, { class: "text-none text-subtitle-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { class: "mr-1" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("fas fa-gear")
            ])),
            _: 1
          }),
          _cache[2] || (_cache[2] = _createTextVNode("Einstellungen"))
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
        return (_openBlock(), _createBlock(_component_v_tab, {
          class: "text-none text-caption",
          key: item,
          value: item,
          onClick: ($event: any) => (_ctx.navigateTo(item))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item), 1)
          ]),
          _: 2
        }, 1032, ["value", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}