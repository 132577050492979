<template>
  <BaseDialog
    :showDialog="show"
    :hideTitle="true"
    @closeDialog="handleConfirm"
    :width="'var(--d-w-sm)'"
  >
    <template #content>
      <v-card-text class="pa-4 mt-2 mb-5 text-h6 text-center">
        <span v-html="message"></span>
      </v-card-text>
      <div class="mb-5 d-flex justify-center">
        <v-btn
          v-bind="vStyle.btn"
          min-width="100"
          :color="btnColor"
          @click="handleConfirm"
          >{{ buttonText }}</v-btn
        >
      </div>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseDialog from "./BaseDialog.vue";
import { SpinnerService } from "../../services/spinner.service";

export enum AlertDialogEmit {
  confirm = "confirm",
}

export default defineComponent({
  name: "AlertDialog",
  components: {
    BaseDialog,
  },
  emits: [AlertDialogEmit.confirm],
  props: {
    message: { type: String, required: true },
    buttonText: { type: String, default: "OK" },
    btnColor: { type: String, default: "primary" },
  },
  data() {
    return {
      show: true,
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    SpinnerService.removeSpinner();
  },
  methods: {
    handleConfirm() {
      this.show = false;
      this.$emit(AlertDialogEmit.confirm);
    },
  },
});
</script>
