export enum ZorstMessageType {
  check = "ZORST_CHECK",
  load = "ZORST_LOAD",
  answer = "ZORST_ANSWER",
  changeStatus = "ZORST_CHANGE_STATUS",
  scrapeSite = "ZORST_SCRAPE_SITE",
  adAtsRecruitTimelineEntry = "ZORST_ZVOOVE_AD_ENTRY",
  publishAtsRecruitJobAd = "PUBLISH_ATS_RECRUIT_JOB_AD",
  oneApiCall = "ONE_API_CALL",
  multiAPiCall = "ZORST_MULTI_API_CALL",
}

export enum ZorstErrorType {
  loginNotFound = "login not found",
}
