import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_OfficeIntBtn = _resolveComponent("OfficeIntBtn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_ErrorChip = _resolveComponent("ErrorChip")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_PasswordDialog = _resolveComponent("PasswordDialog")!
  const _component_PasswordForgottenDialog = _resolveComponent("PasswordForgottenDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      variant: "text",
      icon: "",
      density: "compact",
      class: "mr-2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mainModal = true)),
          class: "mx-3 clickable"
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode(" fa-solid fa-user ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("User Profil ändern")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.mainModal,
      width: 'var(--d-w-sm)',
      onCloseDialog: _cache[11] || (_cache[11] = ($event: any) => (_ctx.mainModal = false))
    }, {
      title: _withCtx(() => _cache[17] || (_cache[17] = [
        _createTextVNode(" Profil bearbeiten ")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_subtitle, { class: "ml-2 mt-5 mb-1" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("fa-solid fa-user")
              ])),
              _: 1
            }),
            _cache[19] || (_cache[19] = _createTextVNode(" Allgemeine Daten "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "form",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.valid) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { noGutters: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps({ density: "compact" }, _ctx.vStyle?.input, {
                          modelValue: _ctx.user.forename,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.forename) = $event)),
                          label: "Vorname",
                          class: "mr-2"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps({
                          class: "ml-2",
                          density: "compact"
                        }, _ctx.vStyle?.input, {
                          modelValue: _ctx.user.lastname,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastname) = $event)),
                          label: "Nachname"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps({
                          class: "mr-2",
                          density: "compact"
                        }, _ctx.vStyle?.input, {
                          modelValue: _ctx.user.tel,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.tel) = $event)),
                          label: "Telefonnummer"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps({
                          class: "ml-2",
                          density: "compact"
                        }, _ctx.vStyle?.input, {
                          modelValue: _ctx.user.mobilePhone,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.mobilePhone) = $event)),
                          label: "Handynummer"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps({
                          class: "mr-2",
                          density: "compact"
                        }, _ctx.vStyle?.input, {
                          modelValue: _ctx.user.email,
                          "onUpdate:modelValue": [
                            _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.email) = $event)),
                            _ctx.resetError
                          ],
                          label: "E-Mail",
                          rules: [_ctx.rules.required, _ctx.rules.email]
                        }), null, 16, ["modelValue", "rules", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      class: "d-flex justify-end align-top",
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.user?.config?.hasMailaddress)
                          ? (_openBlock(), _createBlock(_component_OfficeIntBtn, {
                              key: 0,
                              isExistingAccessToken: _ctx.checkExistingAccessToken(),
                              variant: 'outlined',
                              height: _ctx.button.height,
                              width: _ctx.button.width,
                              onLogout: _cache[6] || (_cache[6] = ($event: any) => (_ctx.office365Logout()))
                            }, null, 8, ["isExistingAccessToken", "height", "width"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_divider, { class: "mt-5" })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_subtitle, { class: "ml-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("fa-solid fa-lock")
              ])),
              _: 1
            }),
            _cache[21] || (_cache[21] = _createTextVNode(" Passwort und Sicherheit "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle?.btn, {
                  color: "primary",
                  width: _ctx.button.width,
                  height: _ctx.button.height,
                  onClick: _ctx.showPasswordModal,
                  "onUpdate:modelValue": _ctx.resetError
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "mr-1" }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode("fa-solid fa-key")
                      ])),
                      _: 1
                    }),
                    _cache[23] || (_cache[23] = _createTextVNode("ändern"))
                  ]),
                  _: 1
                }, 16, ["width", "height", "onClick", "onUpdate:modelValue"]),
                _createVNode(_component_ErrorChip, { label: _ctx.requestError }, null, 8, ["label"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle?.btn, {
                  color: "abort",
                  width: _ctx.button.width,
                  height: _ctx.button.height,
                  onClick: _ctx.showPasswordForgottenModal
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "mr-1" }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("fa-solid fa-arrow-rotate-left")
                      ])),
                      _: 1
                    }),
                    _cache[25] || (_cache[25] = _createTextVNode("Zurücksetzen"))
                  ]),
                  _: 1
                }, 16, ["width", "height", "onClick"])
              ])
            ]),
            _createVNode(_component_v_divider, { class: "mt-8" })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_subtitle, { class: "ml-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("fa-solid fa-gear")
              ])),
              _: 1
            }),
            _cache[27] || (_cache[27] = _createTextVNode(" Sonstiges "))
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            (_ctx.softwareIntegration.pdHub)
              ? (_openBlock(), _createBlock(_component_v_divider, {
                  key: 0,
                  class: "my-5"
                }))
              : _createCommentVNode("", true),
            (_ctx.user?.config?.hideTip?.onDispatcherBoard)
              ? (_openBlock(), _createBlock(_component_v_switch, {
                  key: 1,
                  color: "primary",
                  density: "compact",
                  modelValue: _ctx.user.config.hideTip.onDispatcherBoard,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.config.hideTip.onDispatcherBoard) = $event)),
                  label: "Spaltenüberschriften auf Dispoboard verbergen"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_switch, {
              modelValue: _ctx.themeLight,
              "onUpdate:modelValue": [
                _cache[9] || (_cache[9] = ($event: any) => ((_ctx.themeLight) = $event)),
                _ctx.setTheme
              ],
              label: `Theme: ${_ctx.themeLight ? 'Light' : 'Dark'}`,
              color: "primary",
              density: "compact",
              "hide-details": ""
            }, null, 8, ["modelValue", "label", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "red-darken-3",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.mainModal = false))
        }, {
          default: _withCtx(() => _cache[28] || (_cache[28] = [
            _createTextVNode("Abbrechen")
          ])),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "green-darken-4",
          onClick: _ctx.save
        }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode("Speichern")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["showDialog"]),
    _createVNode(_component_PasswordDialog, {
      showDialog: _ctx.passwordModal,
      requestError: _ctx.passwordRequestError,
      onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.passwordModal = false)),
      onChangePassword: _ctx.changePassword,
      "onClick:outside": _ctx.handlePasswordBackgroundClick,
      onDirty: _ctx.resetPasswordError
    }, null, 8, ["showDialog", "requestError", "onChangePassword", "onClick:outside", "onDirty"]),
    _createVNode(_component_PasswordForgottenDialog, {
      showDialog: _ctx.passwordForgottenModal,
      requestError: _ctx.passwordRequestError,
      onCancel: _cache[13] || (_cache[13] = ($event: any) => (_ctx.passwordForgottenModal = false)),
      onSent: _cache[14] || (_cache[14] = ($event: any) => (_ctx.passwordForgottenModal = false)),
      "onClick:outside": _ctx.handlePasswordBackgroundClick
    }, null, 8, ["showDialog", "requestError", "onClick:outside"])
  ], 64))
}