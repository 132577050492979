<!-- views/recruiting-view.vue -->
<template>
  <div
    v-if="layout === AppLayout.classic || layout === AppLayout.persontobusiness"
  >
    <MainHeader
      @tab-selected="handleTabSelection"
      :showRecruitTabs="true"
    ></MainHeader>
    <JobMatrix v-if="currentTab === HeaderTab.JobMatrix"></JobMatrix>
    <JobList v-if="currentTab === HeaderTab.JobList"></JobList>
    <JobFunnels v-if="currentTab === HeaderTab.Funnels"></JobFunnels>
  </div>
  <v-row v-else>
    <v-col cols="12" md="auto">
      <LeftHeader></LeftHeader>
    </v-col>
    <v-col cols="12" md="11">
      <AppHeader
        @tab-selected="handleTabSelection"
        :showRecruitTabs="true"
      ></AppHeader>
      <JobMatrix v-if="currentTab === HeaderTab.JobMatrix"></JobMatrix>
      <JobList v-if="currentTab === HeaderTab.JobList"></JobList>
      <JobFunnels v-if="currentTab === HeaderTab.Funnels"></JobFunnels>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { HeaderTab } from "@/enums/header-tabs.enum";
import JobFunnels from "@/components/recruiting/JobFunnels.vue";
import JobList from "@/components/recruiting/JobList.vue";
import JobMatrix from "@/components/recruiting/JobMatrix.vue";
import LeftHeader from "@/components/header/LeftHeader.vue";
import MainHeader from "@/components/header/ClassicHeader.vue";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import { OpenAiCoachingHelper } from "../helper/open-ai-coaching.helper";

export default defineComponent({
  name: "RecruitingView",
  components: {
    MainHeader,
    JobMatrix,
    JobList,
    JobFunnels,
    LeftHeader,
    AppHeader,
  },
  data() {
    return {
      currentTab: HeaderTab.JobList as string,
      HeaderTab: HeaderTab,
      layout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.checkUserForOpenAiCoaching();
  },
  methods: {
    checkUserForOpenAiCoaching() {
      this.$nextTick(() => {
        OpenAiCoachingHelper.checkUserForOpenAiCoaching(
          this.$store,
          this.user.config
        );
      });
    },
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>

<style scoped>
/* Stile hier */
</style>
