import { ApiBase } from "./api-base.class";

export class ApiPublic extends ApiBase {
  private static instance: ApiPublic;

  public static getInstance(): ApiPublic {
    if (!ApiPublic.instance) {
      ApiPublic.instance = new ApiBase(false);
    }
    return ApiPublic.instance;
  }
}
