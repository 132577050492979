import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withKeys as _withKeys, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_2 = { class: "tags-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      icon: "",
      variant: "text",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "large",
          class: "filter-icon"
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("fa-solid fa-circle-plus")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "top left"
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode("neue Anfrage / neuen Auftrag anlegen")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          style: {"min-height":"var(--d-h-full)"},
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_sheet, {
                color: "surface",
                style: {"z-index":"1000","position":"fixed","top":"0","width":"99%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "d-flex pa-5" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mx-2" }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode("fa-solid fa-file-signature")
                        ])),
                        _: 1
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.modalMode === _ctx.ModalMode.add
                  ? "Anfrage/Auftrag anlegen"
                  : "Anfrage/Auftrag ändern") + " ", 1),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_icon, {
                        onClick: _ctx.closeModal,
                        class: "close-icon"
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode(" fa-solid fa-xmark ")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[17] || (_cache[17] = _createElementVNode("div", {
                class: "dialog-edit-item-before-menu-shadow dialog-edit-item-top-shadow",
                style: {"height":"6rem"}
              }, null, -1))
            ]),
            _createVNode(_component_v_form, {
              ref: "form",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.valid) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _cache[23] || (_cache[23] = _createElementVNode("div", { style: {"height":"6rem"} }, null, -1)),
                    _createVNode(_component_v_row, { class: "d-flex ma-0 pa-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle?.input, {
                              items: _ctx.mandants,
                              "item-value": "uuid",
                              "item-title": "name",
                              label: "Niederlassung",
                              modelValue: _ctx.demand.mandants,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.demand.mandants) = $event)),
                              id: "select-mandant",
                              density: "compact",
                              multiple: "",
                              rules: [_ctx.rules.required]
                            }), null, 16, ["items", "modelValue", "rules"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle?.input, {
                              items: _ctx.formattedDemandStates,
                              "item-value": "value",
                              "item-title": "title",
                              label: "Status wählen",
                              modelValue: _ctx.demand.demandState,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.demand.demandState) = $event)),
                              id: "select-demand",
                              density: "compact"
                            }), null, 16, ["items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.demand.customer,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.demand.customer) = $event)),
                              label: "Unternehmen",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.demand.demandContactPerson.firstName,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.demand.demandContactPerson.firstName) = $event)),
                              label: "Ansprechperson Vorname",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.demand.demandContactPerson.lastName,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.demand.demandContactPerson.lastName) = $event)),
                              label: "Ansprechperson Nachname",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: 
                    _ctx.demand.demandContactPerson.contactDetails.phoneNumber
                  ,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((
                    _ctx.demand.demandContactPerson.contactDetails.phoneNumber
                  ) = $event)),
                              label: "Telefon",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "pt-0 pb-6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.demand.demandContactPerson.contactDetails.email,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.demand.demandContactPerson.contactDetails.email) = $event)),
                              label: "Email",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "6",
                          class: "py-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.demand.demandContactPerson.position,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.demand.demandContactPerson.position) = $event)),
                              label: "Ansprechperson Position",
                              density: "compact"
                            }), null, 16, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        (_ctx.modalMode === _ctx.ModalMode.edit)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "6",
                              class: "py-0"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle?.btn, {
                                  variant: "outlined",
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.postJobList(_ctx.demand)))
                                }), {
                                  default: _withCtx(() => [
                                    _cache[19] || (_cache[19] = _createTextVNode("Stellen auf JobList setzen ")),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                                        _createTextVNode("AI extrahiert die Aufgaben und Anforderungen aus den Anfragen bzw. Aufträgen und speichert es in Recruting - JobList")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 16)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_container, { fluid: "" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.demand.demands, (item, index) => {
                          return (_openBlock(), _createBlock(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle?.card, {
                            key: index,
                            class: "mt-2 demand-card"
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, { class: "d-flex mb-5" }, {
                                default: _withCtx(() => [
                                  _cache[21] || (_cache[21] = _createTextVNode(" Anfrage / Auftrag ")),
                                  (item.position)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(item.quantity) + "x \"" + _toDisplayString(item.position) + "\" ", 1))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_v_spacer),
                                  _createVNode(_component_v_icon, {
                                    onClick: ($event: any) => (_ctx.deleteDemand(index)),
                                    class: "delete-icon"
                                  }, {
                                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                                      _createElementVNode("i", { class: "fa-solid fa-xmark" }, null, -1)
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_card_text, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "2"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.quantity,
                                            "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                            label: "Menge",
                                            rules: [_ctx.rules.number, _ctx.rules.required],
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue", "rules"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "5"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.position,
                                            "onUpdate:modelValue": ($event: any) => ((item.position) = $event),
                                            label: "Position",
                                            rules: [_ctx.rules.required],
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue", "rules"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "3"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.budget.amount,
                                            "onUpdate:modelValue": ($event: any) => ((item.budget.amount) = $event),
                                            label: "Budget",
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "2"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.budget.currency,
                                            "onUpdate:modelValue": ($event: any) => ((item.budget.currency) = $event),
                                            label: "Währung",
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_textarea, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                    modelValue: item.description,
                                    "onUpdate:modelValue": ($event: any) => ((item.description) = $event),
                                    label: "Beschreibung",
                                    density: "compact",
                                    rows: "3"
                                  }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "12" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            density: "compact",
                                            modelValue: _ctx.newRequirement,
                                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newRequirement) = $event)),
                                            onKeyup: _withKeys(($event: any) => (_ctx.addRequirement(index)), ["enter"]),
                                            label: "Skill / Voraussetzung hinzufügen"
                                          }), null, 16, ["modelValue", "onKeyup"]),
                                          _createElementVNode("div", _hoisted_2, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.requirements, (requirement, reqIndex) => {
                                              return (_openBlock(), _createBlock(_component_v_chip, {
                                                key: reqIndex,
                                                class: "customer-tag",
                                                color: "primary",
                                                "text-color": "var(--color-on-primary)",
                                                "onClick:close": ($event: any) => (_ctx.removeRequirement(index, reqIndex))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(requirement) + " ", 1),
                                                  _createVNode(_component_v_icon, {
                                                    small: "",
                                                    onClick: _withModifiers(($event: any) => (_ctx.removeRequirement(index, reqIndex)), ["stop"]),
                                                    class: "fa fa-times"
                                                  }, null, 8, ["onClick"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick:close"]))
                                            }), 128))
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "3"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.location.postcode,
                                            "onUpdate:modelValue": ($event: any) => ((item.location.postcode) = $event),
                                            label: "PLZ",
                                            rules: [_ctx.rules.postalCode, _ctx.rules.required],
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue", "rules"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, {
                                        cols: "12",
                                        sm: "5"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            modelValue: item.location.city,
                                            "onUpdate:modelValue": ($event: any) => ((item.location.city) = $event),
                                            label: "Ort",
                                            density: "compact"
                                          }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, {
                                        class: "d-flex flex-column",
                                        cols: "12",
                                        sm: "4"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                            label: "Gültig bis",
                                            type: "date",
                                            rules: [_ctx.rules.required],
                                            density: "compact",
                                            modelValue: item.applicationDeadline,
                                            "onUpdate:modelValue": ($event: any) => ((item.applicationDeadline) = $event)
                                          }), null, 16, ["rules", "modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_textarea, _mergeProps({ ref_for: true }, _ctx.vStyle?.input, {
                                    modelValue: item.additionalInformation,
                                    "onUpdate:modelValue": ($event: any) => ((item.additionalInformation) = $event),
                                    label: "weitere Information",
                                    density: "compact",
                                    rows: "2"
                                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1040))
                        }), 128)),
                        _createVNode(_component_v_btn, {
                          class: "add-demand-btn",
                          onClick: _ctx.addDemand,
                          icon: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode(" fa-solid fa-plus ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _cache[24] || (_cache[24] = _createElementVNode("div", { style: {"height":"2rem"} }, null, -1))
                  ]),
                  _: 1
                }),
                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow" }, null, -1)),
                _createVNode(_component_v_card_actions, { class: "dialog-edit-item-action-buttons" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "abort",
                      onClick: _ctx.closeModal
                    }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("Abbrechen")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "success",
                      onClick: _ctx.saveDemand,
                      disabled: !_ctx.valid
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.modalMode === _ctx.ModalMode.add ? "Anlegen" : "Speichern"), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}