/**
 * Toast Service for displaying notifications on the right side next to the left header sidebar

 * Usage:
  - ToastService.show("Simple message")
  - ToastService.showSuccess("Success message")
  - ToastService.showError("Error message")
  - ToastService.showReminder("Reminder message")
  - ToastService.showWarning("Warning message")
 
 * Longer timeout:
  - ToastService.show("Simple message", 10000)
 
 * Toasts will pause their timeout when hovered with the mouse.
 */

import store from "../store/store";

interface ToastStyles {
  bgColor: string;
  textColor?: string;
  borderColor?: string;
  icon?: string;
  iconSize?: string;
  iconColor?: string;
}

class ToastManager {
  private static instance: ToastManager;
  private toasts: HTMLElement[] = [];
  private queuedToasts: Array<{ element: HTMLElement; timeout: number }> = [];
  private queueToast: HTMLElement | null = null;
  private toastRemovalQueue: HTMLElement[] = [];
  private isRemovingToast = false;
  private showingQueuedToasts = false;
  private readonly toastSpacing = 10;
  private readonly initialTop = "6rem";
  private readonly toastBorderWidth = "1px";
  public readonly iconSize = "1.5rem";
  private timeoutIds: Map<HTMLElement, number> = new Map();

  private constructor() {
    this.addGlobalStyles();
  }

  private ensureToastSummaryExists() {
    if (!this.queueToast) {
      this.createToastSummary();
    }
  }

  static getInstance(): ToastManager {
    if (!ToastManager.instance) {
      ToastManager.instance = new ToastManager();
    }
    return ToastManager.instance;
  }

  /**
   * Creates the toast Summary
   */
  private createToastSummary() {
    const toast = document.createElement("div");
    toast.className = "toast queue-toast";
    toast.style.display = "none";

    toast.style.backgroundColor = "var(--color-surface-bright)";
    toast.style.color = "var(--color-primary-darken-1)";
    toast.style.border = `${this.toastBorderWidth} solid var(--color-primary-darken-1)`;
    toast.style.display = "flex";
    toast.style.justifyContent = "space-between";

    const messageSpan = document.createElement("span");
    messageSpan.className = "message";
    messageSpan.textContent = "... und 0 weitere";
    toast.appendChild(messageSpan);

    const showMoreBtn = document.createElement("button");
    showMoreBtn.className =
      "v-btn v-btn--density-compact v-btn--variant-outlined text-none";
    showMoreBtn.setAttribute("density", "compact");
    showMoreBtn.setAttribute("variant", "outlined");
    showMoreBtn.textContent = "Anzeigen";
    showMoreBtn.onclick = () => this.handleShowMoreClick();
    toast.appendChild(showMoreBtn);

    document.body.appendChild(toast);
    this.queueToast = toast;
  }

  /**
   * Adds global styles
   */
  private addGlobalStyles() {
    const style = document.createElement("style");
    style.textContent = `
      .toast {
        position: fixed;
        left: 6rem;
        opacity: 0.95;
        padding: 1rem;
        border-radius: var(--border-radius-elements);
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        display: flex;
        align-items: center;
        min-height: 5rem;
        min-width: 25rem;
        max-width: 25rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.4);
        transition: all 0.3s ease-in-out;
      }
      .toast-enter {
        animation: pop-in 0.3s ease-out;
      }
      .toast-exit {
        animation: fade-out 0.3s ease-in;
      }
      @keyframes pop-in {
        0% { transform: scale(0.8); opacity: 0; }
        100% { transform: scale(1); opacity: 1; }
      }
      @keyframes fade-out {
        0% { opacity: 1; transform: translateX(0); }
        100% { opacity: 0; transform: translateX(-100px); }
      }
      .close-icon-toast {
        margin-left: auto;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s;
      }
      .close-icon-toast:hover {
        opacity: 1;
      }
      .message {
        margin: 0 0.75rem;
      }
      .toast .v-btn {
        background-color: var(--color-surface-bright);
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        color: var(--color-primary-darken-1);
        border: ${this.toastBorderWidth} solid var(--color-primary-darken-1);
        cursor: pointer;
        margin-left: 1rem;
        font-weight: bold;
      }
    `;
    document.head.appendChild(style);
  }

  /**
   * Updates positions of all visible toasts
   */
  private updateToastPositions() {
    let currentTop = parseInt(this.initialTop);

    const visibleNormalToasts = this.toasts.filter(
      (t) => t.style.display !== "none"
    );

    const queueToastVisible =
      this.queueToast && this.queueToast.style.display !== "none";

    visibleNormalToasts.forEach((toast) => {
      toast.style.top = `${currentTop}px`;
      const height = toast.getBoundingClientRect().height;
      currentTop += height + this.toastSpacing;
    });

    if (queueToastVisible && this.queueToast) {
      this.queueToast.style.top = `${currentTop}px`;
    }
  }

  /**
   * Creates a toast element
   */
  createToastElement(message: string, styles: ToastStyles): HTMLElement {
    const toast = document.createElement("div");
    toast.className = "toast toast-enter";

    toast.style.backgroundColor = styles.bgColor;
    toast.style.color = styles.textColor || "#000";
    toast.style.border = `${this.toastBorderWidth} solid ${
      styles.borderColor || styles.bgColor
    }`;

    if (styles.icon) {
      const icon = document.createElement("i");
      icon.className = styles.icon;
      icon.style.marginRight = "0";
      icon.style.fontSize = styles.iconSize || "1.2rem";
      icon.style.color = styles.iconColor || styles.textColor || "#000";
      toast.appendChild(icon);
    }

    const messageSpan = document.createElement("span");
    messageSpan.className = "message";
    messageSpan.textContent = message;
    toast.appendChild(messageSpan);

    const closeIcon = document.createElement("i");
    closeIcon.className = "fa-solid fa-xmark close-icon-toast";
    closeIcon.onclick = () => this.scheduleToastRemoval(toast);
    toast.appendChild(closeIcon);

    // Add mouse hover events to pause/resume timeout
    toast.addEventListener("mouseenter", () => this.pauseToastTimeout(toast));
    toast.addEventListener("mouseleave", () => this.resumeToastTimeout(toast));

    return toast;
  }

  /**
   * Pauses the timeout for a toast when mouse hovers over it
   */
  private pauseToastTimeout(toast: HTMLElement) {
    const timeoutId = this.timeoutIds.get(toast);
    if (timeoutId) {
      clearTimeout(timeoutId);
      this.timeoutIds.delete(toast);

      // Store the remaining time as a data attribute
      const startTime = parseInt(toast.dataset.startTime || "0");
      const timeout = parseInt(toast.dataset.timeout || "0");
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, timeout - elapsedTime);

      toast.dataset.remainingTime = remainingTime.toString();
      toast.dataset.pauseTime = Date.now().toString();
    }
  }

  /**
   * Resumes the timeout for a toast when mouse leaves
   */
  private resumeToastTimeout(toast: HTMLElement) {
    if (toast.dataset.pauseTime && toast.dataset.remainingTime) {
      const remainingTime = parseInt(toast.dataset.remainingTime);
      const currentTime = Date.now();

      toast.dataset.startTime = currentTime.toString();

      // Set new timeout with remaining time
      const newTimeoutId = window.setTimeout(
        () => this.scheduleToastRemoval(toast),
        remainingTime
      );

      this.timeoutIds.set(toast, newTimeoutId);
    }
  }

  /**
   * Adds a new toast
   */
  addToast(toast: HTMLElement, timeout: number) {
    const maxToastCount = store.getters.getEnv.toastMaxCount;
    if (this.toasts.length < maxToastCount - 1) {
      document.body.appendChild(toast);
      this.toasts.push(toast);

      if (timeout > 0) {
        // Store initial timeout information
        const startTime = Date.now();
        toast.dataset.startTime = startTime.toString();
        toast.dataset.timeout = timeout.toString();
        toast.dataset.remainingTime = timeout.toString();

        const timeoutId = window.setTimeout(
          () => this.scheduleToastRemoval(toast),
          timeout
        );
        this.timeoutIds.set(toast, timeoutId);
      }
    } else {
      this.ensureToastSummaryExists();
      this.queuedToasts.push({ element: toast, timeout });
      this.showingQueuedToasts = false;
      this.updateQueueToastStatus();
    }

    this.updatePositions();
  }

  /**
   * Updates queue toast status
   */
  private updateQueueToastStatus() {
    if (!this.queueToast) return;
    const defaultTimeout = store.getters.getEnv.toastTimeout;
    const queueCount = this.queuedToasts.length;

    if (queueCount > 0 && !this.showingQueuedToasts) {
      const messageEl = this.queueToast.querySelector(".message");
      if (messageEl) {
        messageEl.textContent = `... und ${queueCount} weitere ${
          queueCount === 1 ? "Nachricht" : "Nachrichten"
        }`;
      }

      if (this.queueToast.style.display === "none") {
        this.queueToast.style.display = "flex";
        this.queueToast.classList.add("toast-enter");
        this.queueToast.classList.remove("toast-exit");
      }
      clearTimeout(this.queueToast.dataset.hideTimeoutId as unknown as number);
      const timeoutId = setTimeout(() => {
        this.hideQueueToastAndClearQueue();
      }, defaultTimeout);

      this.queueToast.dataset.hideTimeoutId = timeoutId.toString();

      this.updatePositions();
    } else {
      this.hideQueueToast();
    }
  }

  /**
   * Hides the queue toast
   */
  private hideQueueToast() {
    if (!this.queueToast || this.queueToast.style.display === "none") return;

    this.queueToast.classList.remove("toast-enter");
    this.queueToast.classList.add("toast-exit");

    setTimeout(() => {
      if (this.queueToast) {
        this.queueToast.classList.remove("toast-exit");
        this.queueToast.style.display = "none";
        this.updatePositions();
      }
    }, 300);
  }

  /**
   * Hides queue toast and clears all queued toasts
   */
  private hideQueueToastAndClearQueue() {
    if (!this.showingQueuedToasts) {
      this.queuedToasts = [];
    }
    this.hideQueueToast();
  }

  /**
   * Handles click on "show more" button
   */
  private handleShowMoreClick() {
    if (this.queuedToasts.length === 0) return;

    this.showingQueuedToasts = true;
    this.hideQueueToast();
    this.showNextQueuedToast();
  }

  /**
   * Shows next toast from queue if space available
   */
  private showNextQueuedToast() {
    const maxToastCount = store.getters.getEnv.maxToastCount;
    const defaultTimeout = store.getters.getEnv.toastTimeout;
    if (
      this.toasts.length >= maxToastCount - 1 ||
      this.queuedToasts.length === 0
    ) {
      if (this.queuedToasts.length > 0 && !this.showingQueuedToasts) {
        this.updateQueueToastStatus();
      }
      return;
    }

    const nextToast = this.queuedToasts.shift();
    if (!nextToast) return;

    document.body.appendChild(nextToast.element);
    this.toasts.push(nextToast.element);

    this.updatePositions();

    const timeout = nextToast.timeout || defaultTimeout;

    // Set up timeout with hover pause capability
    const startTime = Date.now();
    nextToast.element.dataset.startTime = startTime.toString();
    nextToast.element.dataset.timeout = timeout.toString();
    nextToast.element.dataset.remainingTime = timeout.toString();

    const timeoutId = window.setTimeout(
      () => this.scheduleToastRemoval(nextToast.element),
      timeout
    );
    this.timeoutIds.set(nextToast.element, timeoutId);

    setTimeout(() => {
      if (this.showingQueuedToasts) {
        this.showNextQueuedToast();
      } else {
        this.updateQueueToastStatus();
      }
    }, 150);
  }

  /**
   * Schedules a toast for removal
   */
  private scheduleToastRemoval(toast: HTMLElement) {
    if (toast.classList.contains("toast-exit")) return;

    // Clear any existing timeout
    const timeoutId = this.timeoutIds.get(toast);
    if (timeoutId) {
      clearTimeout(timeoutId);
      this.timeoutIds.delete(toast);
    }

    this.toastRemovalQueue.push(toast);

    if (!this.isRemovingToast) {
      this.processToastRemovalQueue();
    }
  }

  /**
   * Processes the queue of toasts to be removed
   */
  private processToastRemovalQueue() {
    if (this.toastRemovalQueue.length === 0) {
      this.isRemovingToast = false;
      return;
    }

    this.isRemovingToast = true;
    const toastToRemove = this.toastRemovalQueue.shift();

    if (toastToRemove && document.body.contains(toastToRemove)) {
      toastToRemove.classList.remove("toast-enter");
      toastToRemove.classList.add("toast-exit");

      setTimeout(() => {
        this.removeToast(toastToRemove);

        setTimeout(() => {
          this.processToastRemovalQueue();
        }, 300);
      }, 300);
    } else {
      this.processToastRemovalQueue();
    }
  }

  /**
   * Removes a toast from DOM and management
   */
  private removeToast(toast: HTMLElement) {
    const index = this.toasts.indexOf(toast);
    if (index === -1) return;

    this.toasts.splice(index, 1);
    toast.remove();

    this.updatePositions();

    if (this.showingQueuedToasts) {
      this.showNextQueuedToast();
    } else if (this.queuedToasts.length > 0) {
      this.updateQueueToastStatus();
    }
  }

  /**
   * Updates all toast positions
   */
  private updatePositions() {
    requestAnimationFrame(() => this.updateToastPositions());
  }
}

const ToastService = {
  toastManager: ToastManager.getInstance(),

  createToast(message: string, styles: ToastStyles, duration?: number) {
    const timeout = duration || store.getters.getEnv.toastTimeout;
    const toast = this.toastManager.createToastElement(message, styles);
    this.toastManager.addToast(toast, timeout);
  },

  show(message: string, duration?: number) {
    this.createToast(
      message,
      {
        bgColor: "var(--color-surface-bright)",
        borderColor: "var(--color-primary-darken-1)",
        textColor: "var(--color-toast)",
      },
      duration
    );
  },

  showSuccess(message: string, duration?: number) {
    this.createToast(
      message,
      {
        bgColor: "var(--color-surface-bright)",
        borderColor: "var(--color-success)",
        textColor: "var(--color-success)",
        icon: "fas fa-check-circle",
        iconSize: this.toastManager.iconSize,
        iconColor: "var(--color-success)",
      },
      duration
    );
  },

  showError(message: string, duration?: number) {
    this.createToast(
      message,
      {
        bgColor: "var(--color-surface-bright)",
        borderColor: "var(--color-error)",
        textColor: "var(--color-error-darken-1)",
        icon: "fas fa-circle-exclamation",
        iconSize: this.toastManager.iconSize,
        iconColor: "var(--color-error)",
      },
      duration
    );
  },

  showReminder(message: string, duration?: number) {
    this.createToast(
      message,
      {
        bgColor: "var(--color-surface-bright)",
        borderColor: "var(--color-info)",
        textColor: "var(--color-info-darken-1)",
        icon: "fas fa-circle-info",
        iconSize: this.toastManager.iconSize,
        iconColor: "var(--color-info)",
      },
      duration
    );
  },

  showWarning(message: string, duration?: number) {
    this.createToast(
      message,
      {
        bgColor: "var(--color-surface-bright)",
        borderColor: "var(--color-warning)",
        textColor: "var(--color-warning-darken-1)",
        icon: "fas fa-exclamation-triangle",
        iconSize: this.toastManager.iconSize,
        iconColor: "var(--color-warning-darken-1)",
      },
      duration
    );
  },
};

export default ToastService;
