export enum RecurionChatbotType {
  handbook,
  coach,
  profiler,
}

export enum RecurionAnswerHandles {
  appUnlockKey = "SET_DISPOSITIONER_UNLOCK",
  createTicket = "CREATE_TICKET",
  bugReport = "BUG_REPORT",
  featureRequest = "FEATURE_REQUEST",
  requestToAddChatbotKnowledge = "REQUEST_TO_ADD_CHATBOT_KNOWNLEDGE",
  timelineEntryType = "Recurion Coaching",
  timelineEntryPseudoMandant = "only-for-admins-visible",
}

export interface RecurionCoachingTimelineEntry {
  title: string;
  resume: string;
}
