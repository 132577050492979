import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "form",
    modelValue: _ctx.isValid,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValid) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
          density: "compact",
          label: "Link zum Kandidaten in ATS",
          modelValue: _ctx.atsRecruitLink,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.atsRecruitLink) = $event)),
          rules: [_ctx.linkRules],
          hint: `z.B.: ${_ctx.$store.getters.softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/c1bca247-c432-4162-900c-fffeaa0416aa`,
          "persistent-hint": ""
        }), null, 16, ["modelValue", "rules", "hint"]),
        _createVNode(_component_v_btn, _mergeProps({
          icon: "",
          onClick: _ctx.extractUuid
        }, _ctx.vStyle?.btn, {
          color: "success",
          class: "ml-2",
          disabled: !_ctx.isValid,
          variant: "text"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { size: "2.5rem" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("fa-solid fa-check")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 16, ["onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}