<template>
  <v-dialog
    v-model="showDialogComputed"
    :max-width="dialogWidth"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title v-if="!hideTitle" class="d-flex align-start">
        <div :class="titleClass">
          <slot name="title"></slot>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!persistent"
          @click="closeDialog()"
          icon
          size="s"
          variant="text"
          density="compact"
        >
          <v-icon>fa-solid fa-xmark close-icon</v-icon>
        </v-btn>
      </v-card-title>
      <slot name="content"></slot>
      <div v-if="showActions" style="min-height: 2.5rem"></div>
      <div
        v-if="showActions"
        class="dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow"
      ></div>
      <v-card-actions
        v-if="showActions"
        class="dialog-edit-item-action-buttons"
      >
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { MutationType } from "../../enums/vuex-types.enum";

enum BaseDialogEmits {
  closeDialog = "closeDialog",
  submit = "submit",
}

export default defineComponent({
  name: "BaseDialog",
  emits: [BaseDialogEmits.closeDialog, BaseDialogEmits.submit],
  props: {
    mobileWidth: {
      type: String,
      default: "var(--d-w-sd)",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    showActions: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },
    titleClass: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "var(--d-w-s)",
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      dialogId: this.generateDialogId(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    showDialogComputed: {
      get(): boolean {
        const currentDialog = this.$store.getters.currentBaseDialog;
        return currentDialog?.id === this.dialogId && this.showDialog;
      },
      set(value: boolean) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
    dialogWidth(): string {
      return this.smAndDown ? this.mobileWidth : this.width;
    },
  },
  methods: {
    closeDialog() {
      this.$emit(BaseDialogEmits.closeDialog);
      this.$nextTick(() => {
        this.$store.commit(MutationType.removeFromDialogQueue, this.dialogId);
      });
    },
    generateDialogId() {
      return `${this.$options.name}-${Date.now()}-${Math.random()
        .toString(36)
        .substr(2, 9)}`;
    },
    submit() {
      this.$emit(BaseDialogEmits.submit);
    },
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const existingDialog = this.$store.state.baseDialogQueue.find(
            (dialog: any) => dialog.id === this.dialogId
          );
          if (!existingDialog) {
            this.$store.commit(MutationType.addToDialogQueue, {
              id: this.dialogId,
              props: this.$props,
              timestamp: Date.now(),
            });
          }
        } else {
          this.$store.commit(MutationType.removeFromDialogQueue, this.dialogId);
        }
      },
    },
  },
  beforeUnmount() {
    if (this.showDialog) {
      this.$store.commit(MutationType.removeFromDialogQueue, this.dialogId);
    }
  },
});
</script>
