import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"min-height":"2.5rem"}
}
const _hoisted_2 = {
  key: 2,
  class: "dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showDialogComputed,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialogComputed) = $event)),
    "max-width": _ctx.dialogWidth,
    persistent: _ctx.persistent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          (!_ctx.hideTitle)
            ? (_openBlock(), _createBlock(_component_v_card_title, {
                key: 0,
                class: "d-flex align-start"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.titleClass)
                  }, [
                    _renderSlot(_ctx.$slots, "title")
                  ], 2),
                  _createVNode(_component_v_spacer),
                  (!_ctx.persistent)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog())),
                        icon: "",
                        size: "s",
                        variant: "text",
                        density: "compact"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("fa-solid fa-xmark close-icon")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content"),
          (_ctx.showActions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.showActions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.showActions)
            ? (_openBlock(), _createBlock(_component_v_card_actions, {
                key: 3,
                class: "dialog-edit-item-action-buttons"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "actions")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue", "max-width", "persistent"]))
}