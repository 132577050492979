import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, withKeys as _withKeys, mergeProps as _mergeProps, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_container = _resolveComponent("v-container")!
  const _directive_autoscroll = _resolveDirective("autoscroll")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { variant: "flat" }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_v_card_text, {
            id: "chat-container",
            class: "chat-container"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(['d-flex', message.isUser ? 'justify-end' : 'justify-start'])
                }, [
                  _createVNode(_component_v_card, {
                    color: message.isUser ? 'secondary' : 'surface-bright',
                    class: _normalizeClass(['ma-2', 'pa-3', { 'white--text': message.isUser }]),
                    "max-width": "80%",
                    rounded: "lg"
                  }, {
                    default: _withCtx(() => [
                      (message.isUser)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(message.text), 1))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "markdown-body",
                            innerHTML: _ctx.renderMarkdown(message.text)
                          }, null, 8, _hoisted_2))
                    ]),
                    _: 2
                  }, 1032, ["color", "class"])
                ], 2))
              }), 128)),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_v_card, {
                      variant: "flat",
                      class: "ma-2 pa-3",
                      "max-width": "80%",
                      rounded: "lg"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          src: _ctx.generateSpinner,
                          alt: "Generiere...",
                          height: "16",
                          width: "32"
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })), [
            [_directive_autoscroll]
          ]),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_card_actions, { class: "pa-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                modelValue: _ctx.newMessage,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMessage) = $event)),
                placeholder: "Frag mich etwas...",
                onKeyup: _withKeys(_ctx.sendMessage, ["enter"]),
                disabled: _ctx.isLoading,
                "hide-details": "",
                class: "mr-2"
              }), null, 16, ["modelValue", "onKeyup", "disabled"]),
              _createVNode(_component_v_btn, {
                color: "primary",
                onClick: _ctx.sendMessage,
                loading: _ctx.isLoading,
                disabled: !_ctx.newMessage.trim()
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Senden ")
                ])),
                _: 1
              }, 8, ["onClick", "loading", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}