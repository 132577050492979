import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic && _ctx.layout !== _ctx.AppLayout.persontobusiness)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "mt-5" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "mt-3" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Verlinkungen bereinigen")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_divider),
                        _createVNode(_component_v_list, { dense: "" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkings, (linking, index) => {
                              return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_v_list_item_title, { class: "d-flex justify-space-between" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, [
                                          _createTextVNode(_toDisplayString(linking.description?.firstName) + " " + _toDisplayString(linking.description?.lastName) + " ", 1),
                                          (
                          linking.description?.lastName &&
                          linking.description?.customerName
                        )
                                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                                key: 0,
                                                class: "mx-1",
                                                size: "small"
                                              }, {
                                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                                  _createTextVNode("fas fa-link")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(linking.description?.customerName), 1)
                                        ]),
                                        _createVNode(_component_v_btn, {
                                          icon: "",
                                          density: "compact",
                                          variant: "text",
                                          onClick: ($event: any) => (_ctx.deleteLinking(linking._id))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                                _createTextVNode("fa-solid fa-xmark")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_list_item_subtitle, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Letztes Event: " + _toDisplayString(_ctx.formatDate(
                        linking.events[linking.events.length - 1].eventDate[0]
                      )) + " - " + _toDisplayString(linking.events[linking.events.length - 1].eventType), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_divider, { class: "mt-3" })
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Alle Daten aus ERP System importieren ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Überschreibt Kunden / Mitarbeiterdaten! ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                          label: "initialisieren / aktualisieren",
                          modelValue: _ctx.erpOptions,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.erpOptions) = $event)),
                          items: _ctx.ErpFetchOptions,
                          "item-title": "label",
                          "item-value": "label",
                          multiple: ""
                        }), null, 16, ["modelValue", "items"]),
                        _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchErpInitData(_ctx.erpOptions)))
                        }), {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("FETCH")
                          ])),
                          _: 1
                        }, 16)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}