import { FirstContactData } from "../../models/first-contact-data.model";
import { ApiPublic } from "./api-public.class";

export class RegistrationService {
  private api = ApiPublic.getInstance();
  private urlPath = "/registration";

  async registerCompany(data: FirstContactData) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}`, { data });
    return response.data;
  }
}
