import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse mt-4 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidateDeletionDialog = _resolveComponent("ValidateDeletionDialog")!
  const _component_CompanyDialog = _resolveComponent("CompanyDialog")!
  const _component_UserDialog = _resolveComponent("UserDialog")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ValidateDeletionDialog, {
      show: _ctx.showValidateDeletionDialog,
      email: _ctx.deleteCompanyId,
      onValidate: _ctx.deleteCompany,
      "onUpdate:show": _ctx.updateValidateDeletionDialogVisibility
    }, null, 8, ["show", "email", "onValidate", "onUpdate:show"]),
    _createVNode(_component_CompanyDialog, {
      show: _ctx.showCompanyDialog,
      editMode: _ctx.editMode,
      editCompanyModel: _ctx.companyModel,
      "onUpdate:show": _ctx.updateDialogVisibility,
      "onClick:outside": _ctx.handleBackgroundClick
    }, null, 8, ["show", "editMode", "editCompanyModel", "onUpdate:show", "onClick:outside"]),
    _createVNode(_component_UserDialog, {
      showDialog: _ctx.showUserDialog,
      mandants: _ctx.mandants,
      editMode: _ctx.editMode,
      editUserModel: _ctx.userModel,
      onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateDialogVisibility(false))),
      "onClick:outside": _ctx.handleBackgroundClick
    }, null, 8, ["showDialog", "mandants", "editMode", "editUserModel", "onClick:outside"]),
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic && _ctx.layout !== _ctx.AppLayout.persontobusiness)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
            density: "comfortable",
            size: "large",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addCompany()))
          }), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "fa-solid fa-plus" })
            ]),
            _: 1
          }, 16)
        ]),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                key: company.name,
                name: company.name,
                cols: "12",
                sm: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, {
                    title: `${company.name} - ${company.tradeRegisterEntry} `,
                    subtitle: company.email
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, { class: "d-flex" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "4"
                              }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("i", { class: "fa-solid fa-envelope" }),
                                    _createTextVNode(" :")
                                  ], -1),
                                  _createElementVNode("div", null, "UStId:", -1)
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "8"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(company.email), 1),
                                  _createElementVNode("div", null, _toDisplayString(company.vat), 1),
                                  _createElementVNode("div", null, _toDisplayString(company.address?.street), 1),
                                  _createElementVNode("div", null, _toDisplayString(company.address?.street_number), 1),
                                  _createElementVNode("div", null, _toDisplayString(company.address?.postcode), 1),
                                  _createElementVNode("div", null, _toDisplayString(company.address?.country), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                sm: "12"
                              }, {
                                default: _withCtx(() => [
                                  _cache[5] || (_cache[5] = _createElementVNode("div", null, "Benutzer:", -1)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(company.users, (user) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: user._id
                                    }, [
                                      _createElementVNode("div", null, _toDisplayString(user.username) + " (" + _toDisplayString(_ctx.getRole(user.role)) + ")", 1)
                                    ]))
                                  }), 128)),
                                  _createVNode(_component_v_btn, {
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addUser()))
                                  }, {
                                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                                      _createTextVNode("Benutzer anlegen")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_card_actions, { class: "d-flex justify-space-between" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "success",
                            onClick: ($event: any) => (_ctx.editCompany(company))
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("Bearbeiten")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_btn, {
                            color: "abort",
                            onClick: ($event: any) => (_ctx.removeCompany(company))
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Löschen")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040, ["title", "subtitle"])
                ]),
                _: 2
              }, 1032, ["name"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}