import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse mt-4 mb-4" }
const _hoisted_2 = {
  key: 1,
  class: "no-entries"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidateDeletionDialog = _resolveComponent("ValidateDeletionDialog")!
  const _component_UserDialog = _resolveComponent("UserDialog")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ValidateDeletionDialog, {
      show: _ctx.showValidateDeletionDialog,
      email: _ctx.deleteUserId,
      onValidate: _ctx.deleteUser,
      "onUpdate:show": _ctx.updateValidateDeletionDialogVisibility
    }, null, 8, ["show", "email", "onValidate", "onUpdate:show"]),
    _createVNode(_component_UserDialog, {
      showDialog: _ctx.showUserDialog,
      mandants: _ctx.mandants,
      show: _ctx.showUserDialog,
      editMode: _ctx.editMode,
      editUserModel: _ctx.userModel,
      onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUserDialog = false))
    }, null, 8, ["showDialog", "mandants", "show", "editMode", "editUserModel"]),
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic && _ctx.layout !== _ctx.AppLayout.persontobusiness)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.hasFullCompanyUserAccess)
            ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, _ctx.vStyle.btn, {
                density: "comfortable",
                size: "large",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addUser()))
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { icon: "fa-solid fa-plus" })
                ]),
                _: 1
              }, 16))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasFullCompanyUserAccess)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: user.email,
                    name: user.lastname,
                    cols: "12",
                    sm: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, {
                        title: `${_ctx.getSalutation(user.salutation)} ${
            user.forename || 'Daten'
          } ${user.lastname || 'eintragen'} `,
                        subtitle: user.email
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, { class: "d-flex" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "4"
                                  }, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("i", { class: "fa-solid fa-phone" }),
                                        _createTextVNode(" Telefon:")
                                      ], -1),
                                      _createElementVNode("div", null, [
                                        _createElementVNode("i", { class: "fa-solid fa-mobile-screen-button" }),
                                        _createTextVNode("  Mobil: ")
                                      ], -1)
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    sm: "8"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, _toDisplayString(user.tel), 1),
                                      _createElementVNode("div", null, _toDisplayString(user.mobilePhone), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_card_actions, { class: "d-flex justify-space-between" }, {
                            default: _withCtx(() => [
                              (_ctx.hasFullCompanyUserAccess)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    color: "success",
                                    onClick: ($event: any) => (_ctx.editUser(user))
                                  }, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode("Bearbeiten")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (_ctx.hasRemoveCompanyUserAccess)
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 1,
                                    color: "abort",
                                    onClick: ($event: any) => (_ctx.removeUser(user))
                                  }, {
                                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                                      _createTextVNode("Löschen")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1040, ["title", "subtitle"])
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.users.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Keine Einträge vorhanden "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}