<template>
  <BaseDialog
    :show-dialog="showDialog"
    :width="candidate ? 'var(--d-w-s)' : 'var(--d-w-l)'"
    @closeDialog="closeDialog"
  >
    <template #title>
      {{ getDialogTitle() }}
    </template>

    <template #content>
      <!--Manual Link Mode-->
      <div v-if="showExtractCandidateUuidFromAtsRecruitLinkForm()">
        <v-card-title class="text-subtitle-1 mb-2">
          <v-icon class="mr-2 text-medium-emphasis"
            >fa-solid fa-person-circle-question</v-icon
          >
          Doch vorhanden? Manuell verknüpfen
        </v-card-title>
        <v-card-text>
          <ExtractCandidateUuidFromAtsRecruitLinkForm
            @extractedUuid="receiveCandidateUuid"
          />
          <v-divider class="mt-15 mb-10"></v-divider>
        </v-card-text>
      </div>

      <!-- API Wrapper Mode-->
      <div v-if="showXpMode()">
        <v-card-title class="text-subtitle-1 mb-2">
          <v-icon size="small" class="mr-1 text-medium-emphasis"
            >fa-solid fa-flask</v-icon
          >
          XP-Mode: Kandidat direkt ins ATS übertragen
        </v-card-title>
        <v-card-text>
          <v-select
            v-bind="vStyle?.input"
            density="compact"
            width="345"
            v-if="
              softwareIntegration.zvooveRecruit &&
              atsRecruitMandantWithAddresses?.length > 0
            "
            v-model="selectedAtsRecruitMandant"
            :items="atsRecruitMandantWithAddresses"
            item-title="Bezeichnung"
            return-object
            label="Mandant"
            @update:modelValue="handleAtsRecruitMandantChange"
          ></v-select>
          <v-row class="ml-4 d-flex align-center text-body-2">
            1.
            <v-btn
              icon
              variant="text"
              @click="showDialogEditNewOrUpdatedCandidate()"
              density="compact"
              class="ml-2"
              color="primary"
            >
              <v-icon>fa-solid fa-user-pen</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Kandidatendatensatz bearbeiten
              </v-tooltip>
            </v-btn>
            <v-icon class="mx-4 text-medium-emphasis" size="1.5rem"
              >fa-solid fa-arrow-right</v-icon
            >
            2.
            <v-btn
              @click="addEmployeeToAtsRecruit"
              :disabled="!selectedAtsRecruitMandant"
              variant="text"
              >Übertragen (ATS)<v-icon
                size="small"
                class="ml-2 text-medium-emphasis"
                >fa-solid fa-cloud-arrow-up</v-icon
              ></v-btn
            >
            <v-btn
              v-if="
                !atsRecruitMandantWithAddresses ||
                atsRecruitMandantWithAddresses.length === 0
              "
              icon
              variant="text"
              @click="getAtsRecruitDefaults"
              ><v-icon>fa-solid fa-satellite-dish</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Verbindung zu ATS Recruit herstellen
              </v-tooltip>
            </v-btn>
          </v-row>
          <v-divider class="mt-10"></v-divider>
        </v-card-text>
      </div>

      <!--ATS Recruit Mode-->
      <div v-if="softwareIntegration.zvooveRecruit">
        <v-card-title class="text-subtitle-1 mb-2">
          {{ showXpMode() ? "Optional: " : "" }}Personaldaten als Bewerbung ans
          ATS Recruit senden
        </v-card-title>
        <v-card-text>
          <v-row class="ml-4 d-flex align-center text-body-2">
            1.
            <v-btn
              icon
              variant="text"
              @click="showDialogEditNewOrUpdatedCandidate()"
              density="compact"
              class="ml-4"
              color="primary"
            >
              <v-icon>fa-solid fa-user-pen</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Kandidatendatensatz bearbeiten
              </v-tooltip>
            </v-btn>
            <v-icon class="mx-4 text-medium-emphasis" size="1.5rem"
              >fa-solid fa-arrow-right</v-icon
            >
            2.
            <v-btn @click="addApplicationToAts" variant="text"
              >Neue Bewerbung<v-icon class="ml-2 text-medium-emphasis"
                >fa-solid fa-paper-plane</v-icon
              ></v-btn
            >
          </v-row>
          <v-divider class="mt-10"></v-divider>
        </v-card-text>
      </div>

      <!--no external ATS needed-->
      <div v-if="!candidateToSendToAts">
        <v-card-title class="text-subtitle-1 mb-2"
          >{{ isAtsSystem() ? "Optional: " : "" }}Kandidat
          {{ isAtsSystem() ? "nur " : "" }}im
          {{ appTitle() }} anlegen</v-card-title
        >
        <v-card-text>
          <v-row class="ml-4 mb-15 d-flex align-center text-body-2">
            1.
            <v-btn
              icon
              variant="text"
              @click="showDialogEditNewOrUpdatedCandidate()"
              density="compact"
              class="ml-4"
              color="primary"
            >
              <v-icon>fa-solid fa-user-pen</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Kandidatendatensatz bearbeiten
              </v-tooltip>
            </v-btn>
            <v-icon class="mx-4 text-medium-emphasis" size="1.5rem"
              >fa-solid fa-arrow-right</v-icon
            >
            2.
            <v-btn
              :disabled="!newOrUpdatedCandidate"
              @click="createCandidateAndUpdateEmployee()"
              variant="text"
              >Kandidat erstellen<v-icon class="ml-2 text-medium-emphasis"
                >fa-solid fa-floppy-disk</v-icon
              >
              <v-tooltip activator="parent" location="bottom">
                Datensatz im {{ appTitle() }} in der Spalte "Kandidat" anlegen
              </v-tooltip>
            </v-btn>
          </v-row>
        </v-card-text>
      </div>
    </template>
  </BaseDialog>

  <DialogEditCandidate
    ref="dialogEditCandidateComponent"
    v-if="dialogEditNewOrUpdatedCandidate"
    :candidate="newOrUpdatedCandidate"
    :emitOnlyCandidateOnSubmit="true"
    @submitCandidateObjekt="receiveUpdatedCandidate"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Candidate } from "../../../models/candidate.model";
import {
  AtsRecruitCareerStepType,
  AtsRecruitMandantWithAddress,
  NewAtsRecruitCandidate,
} from "../../../models/external/zvoove-candidate.model";
import { mapGetters } from "vuex";
import { AllAtsAdapter } from "../../../adapter/all-ats.adapter";
import { Employee } from "../../../models/employee.model";
import { CommunicationType } from "../../../enums/communication-types.enum";
import ToastService from "../../../services/toast.service";
import { AllErpAdapter } from "../../../adapter/all-erp.adapter";
import { EmployeeToCandidate } from "../../../helper/employee-to-candidate.helper";
import { SpinnerService } from "../../../services/spinner.service";
import { CandidateService } from "../../../services/api/candidate.service";
import { appTitle } from "../../../helper/app-title.helper";
import DialogEditCandidate from "./DialogEditCandidate.vue";
import { useDisplay } from "vuetify";
import ExtractCandidateUuidFromAtsRecruitLinkForm from "./ats-recruit-helper/ExtractCandidateUuidFromAtsRecruitLinkForm.vue";
import { candidateToAtsRecruitApplication } from "../../../adapter/api-integration-one.adapter";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum DialogAddCandidateToAtsEmits {
  candidateCreatedOrUpdated = "candidateCreatedOrUpdated",
  closeDialog = "closeDialog",
  receivedCandidateUuidFromLink = "receivedCandidateUuidFromLink",
}

export default defineComponent({
  name: "AddCandidateToAts",
  components: {
    BaseDialog,
    DialogEditCandidate,
    ExtractCandidateUuidFromAtsRecruitLinkForm,
  },
  emits: [
    DialogAddCandidateToAtsEmits.candidateCreatedOrUpdated,
    DialogAddCandidateToAtsEmits.closeDialog,
    DialogAddCandidateToAtsEmits.receivedCandidateUuidFromLink,
  ],
  props: {
    droppedCv: {
      type: Object as PropType<File | null>,
      required: false,
      default: null,
    },
    employee: {
      type: Object as PropType<Employee>,
      required: false,
      default: undefined,
    },
    candidate: {
      type: Object as PropType<Candidate>,
      required: false,
      default: undefined,
    },
    candidateToSendToAts: {
      type: Object as PropType<Candidate>,
      required: false,
      default: undefined,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["softwareIntegration"]),
  },
  watch: {
    candidate: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.newOrUpdatedCandidate = newVal;
        }
      },
      immediate: true,
    },
    candidateToSendToAts: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.newOrUpdatedCandidate = newVal;
        }
      },
      immediate: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      cvFileFromDialogEditCandidate: null as File | null,
      dialog: true,
      dialogEditNewOrUpdatedCandidate: false,
      newOrUpdatedCandidate: undefined as Candidate | undefined,
      vStyle: this.$store.state.vStyle,
      // API Wrapper Mode:
      atsRecruitMandantWithAddresses: [] as AtsRecruitMandantWithAddress[],
      atsRecruitExternalDto: {} as NewAtsRecruitCandidate,
      selectedAtsRecruitMandant: null as AtsRecruitMandantWithAddress | null,
      atsRecruitCareerStepTypes: [] as AtsRecruitCareerStepType[],
      // Helper:
      appTitle,
    };
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    async addApplicationToAts() {
      if (!this.newOrUpdatedCandidate) return;
      const atsRecruitApplication = candidateToAtsRecruitApplication(
        this.newOrUpdatedCandidate
      );
      try {
        SpinnerService.showSpinner();
        const cvFile =
          this.cvFileFromDialogEditCandidate ?? this.droppedCv ?? undefined;
        const response = await AllAtsAdapter.postApplicationToAts(
          atsRecruitApplication,
          cvFile ? [cvFile] : undefined
        );
        if (response === 200) {
          ToastService.showSuccess(
            "Bewerbung erfolgreich an das ATS Recruit gesendet"
          );
          this.closeDialog();
        } else {
          ToastService.showError(
            "Fehler beim Senden der Bewerbung an das ATS Recruit"
          );
        }
      } catch (error) {
        console.error(
          "Fehler beim Senden der Bewerbung an das ATS Recruit:",
          error
        );
        ToastService.showError(
          "Fehler beim Senden der Bewerbung an das ATS Recruit"
        );
      } finally {
        SpinnerService.removeSpinner();
        this.openInboxInAts();
      }
    },
    async createCandidateAndUpdateEmployee() {
      if (!this.newOrUpdatedCandidate) return;
      this.$emit(
        DialogAddCandidateToAtsEmits.candidateCreatedOrUpdated,
        this.newOrUpdatedCandidate
      );
    },
    async createCandidateFromEmployee(employeeWithCarreerSteps?: Employee) {
      if (!this.employee?._id) return;
      let employeeToConvert = employeeWithCarreerSteps ?? this.employee;
      employeeToConvert = { ...employeeToConvert, _id: this.employee._id };
      const candidate =
        EmployeeToCandidate.employeeToCandidate(employeeToConvert);
      const candidateService = new CandidateService();
      this.newOrUpdatedCandidate = await candidateService.postCandidate(
        candidate
      );
    },
    closeDialog() {
      this.$emit(DialogAddCandidateToAtsEmits.closeDialog);
    },
    getDialogTitle() {
      if (this.candidate) {
        return "Neuer Kandidat hinzufügen";
      }
      return this.isAtsSystem() && !this.candidate
        ? "Kein Kandidat im ATS gefunden"
        : "Kandidatendatensatz zum Mitarbeiter erstellen";
    },
    isAtsSystem() {
      return AllAtsAdapter.isAtsSystem();
    },
    initComponent() {
      const hasZvooveOneAndRecruit =
        this.softwareIntegration.zvooveOne &&
        this.softwareIntegration.zvooveRecruit;
      const hasPdHubAndRecruit =
        this.softwareIntegration.pdHub &&
        this.softwareIntegration.zvooveRecruit;

      switch (true) {
        case hasZvooveOneAndRecruit:
          this.createCandidateFromEmployee();
          this.getAtsRecruitDefaults();
          break;

        case hasPdHubAndRecruit:
          this.getEmployeeDataWithCareerSteps();
          break;
      }
    },
    openInboxInAts() {
      let externalLink = undefined;
      if (this.softwareIntegration.zvooveRecruit) {
        externalLink = `${this.softwareIntegration.zvooveRecruitLink}/recruiting/inbox/bw`;
      }
      if (externalLink) {
        window.open(externalLink, "_blank", PopUpWindowSize.externalSoftware);
      }
    },
    receiveCandidateUuid(uuid: string) {
      this.$emit(
        DialogAddCandidateToAtsEmits.receivedCandidateUuidFromLink,
        uuid
      );
      this.closeDialog();
    },
    receiveUpdatedCandidate(updatedCandidate: Candidate, file: File | null) {
      this.newOrUpdatedCandidate = updatedCandidate;
      if (file) this.cvFileFromDialogEditCandidate = file;
    },
    showDialogEditNewOrUpdatedCandidate() {
      this.dialogEditNewOrUpdatedCandidate = Boolean(
        this.newOrUpdatedCandidate
      );
      this.$nextTick().then(() => {
        if (this.$refs.dialogEditCandidateComponent) {
          (
            this.$refs.dialogEditCandidateComponent as InstanceType<
              typeof DialogEditCandidate
            >
          ).openModal();
        }
      });
    },
    showExtractCandidateUuidFromAtsRecruitLinkForm() {
      return (
        this.softwareIntegration.zvooveRecruit &&
        !this.candidate &&
        !this.candidateToSendToAts
      );
    },
    //L1 Mode pdHub:
    async getEmployeeDataWithCareerSteps() {
      if (!this.employee) return;
      try {
        SpinnerService.showSpinner();
        let employeeWithCarreerSteps = (await AllErpAdapter.getEmployeeFromErp(
          this.employee.employeeNumber,
          this.employee.mandantL1 ??
            this.$store.state.company?.apiKeys?.pdHubL1Mandant,
          this.employee.branchOfficeId ?? "-1",
          true
        )) as Employee;

        this.createCandidateFromEmployee(employeeWithCarreerSteps);
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der PD-Hub Personaldaten mit Lebenslaufdaten"
        );
        console.error(
          "Fehler beim Abrufen der PD-Hub Personaldaten mit Lebenslaufdatem",
          error
        );
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    // API Wrapper Mode:
    async addEmployeeToAtsRecruit() {
      if (!this.employee) {
        ToastService.showError("Kein Mitarbeiter ausgewählt");
        return;
      }

      if (!this.atsRecruitExternalDto?.Bewerbung?.Mandant) {
        ToastService.showError("Keine Niederlassung zum Anlegen ausgewählt");
        return;
      }

      const errorMessage = "Fehler beim Anlegen des Kandidaten im ATS:";

      try {
        SpinnerService.showSpinner();
        this.atsRecruitExternalDto.Mitarbeiter = {
          ...this.atsRecruitExternalDto.Mitarbeiter,
          Titel: this.employee.title || null,
          SalutationCatalogId: this.employee.gender || null,
          Vorname: this.employee.firstName || "",
          Nachname: this.employee.lastName || "",
          Geburtsdatum: this.employee.birthDate || null,
          Geburtsort: this.employee.birthPlace || null,
          Geburtsname: this.employee.birthName || null,
          Adressen: [
            {
              Strasse: this.employee.address.street || null,
              PLZ: this.employee.address.postalCode || null,
              Ort: this.employee.address.city || null,
              Bezeichnung: null,
              Bundesland: null,
              Einsatzradius: null,
              Gemeindenummer: null,
              Hausnummer: null,
              Land: {
                Iso002: "DE", //FIXME: has to come from a Iso002 datebase based on the address.country
                Bezeichnung:
                  this.atsRecruitMandantWithAddresses[0].AdresseLand
                    .Bezeichnung,
                ObjectUuid:
                  this.atsRecruitMandantWithAddresses[0].AdresseLand.ObjectUuid,
              },
              ObjectUuid: null,
              Plz: this.employee.address.postalCode || null,
              SortOrder: 0,
              UseInMatch: false,
              Zusatz: null,
            },
          ],
          Kommunikationsmittel: [
            this.employee.address?.phone1 && {
              Beschreibung: "Telefon 1",
              ObjectUuid: null,
              SortOrder: 0,
              Typ: CommunicationType.Phone,
              Wert: this.employee.address.phone1,
            },
            this.employee.address?.phone2 && {
              Beschreibung: "Telefon 2",
              ObjectUuid: null,
              SortOrder: 1,
              Typ: CommunicationType.Mobile,
              Wert: this.employee.address.phone2,
            },
            this.employee.address?.email && {
              Beschreibung: "Email",
              ObjectUuid: null,
              SortOrder: 2,
              Typ: CommunicationType.Email,
              Wert: this.employee.address.email,
            },
          ].filter(Boolean),
        };

        const response = await AllAtsAdapter.addCandidateToAts(
          this.atsRecruitExternalDto
        );

        if (response.MitarbeiterUuid) {
          ToastService.showSuccess("Kandidat erfolgreich im ATS angelegt");
          this.receiveCandidateUuid(response.MitarbeiterUuid);
        } else {
          ToastService.showError(errorMessage);
        }
      } catch (error) {
        console.error(errorMessage, error);
        ToastService.showError(errorMessage);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async getAtsRecruitDefaults() {
      if (
        !this.softwareIntegration.zvooveRecruit ||
        !this.softwareIntegration.zvooveOne
      )
        return;
      const response = await AllAtsAdapter.getAtsNewCandidateDefaults();
      this.atsRecruitExternalDto = response.newCandidateExternalDto;
      this.atsRecruitMandantWithAddresses = response.mandantsWithAddresses;
      this.atsRecruitCareerStepTypes = response.careerStepTypes;
    },
    handleAtsRecruitMandantChange() {
      this.atsRecruitExternalDto.Bewerbung.Mandant =
        this.selectedAtsRecruitMandant;
    },
    showXpMode() {
      return (
        this.softwareIntegration.zvooveOne &&
        this.softwareIntegration.zvooveRecruit &&
        !this.candidate
      );
    },
  },
});
</script>

<style scoped></style>
