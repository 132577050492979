<!--src/components/config/software-integration/ConfigZorst.vue-->
<template>
  <v-container>
    <h2 class="mb-5 d-flex">
      <v-img class="mr-3" :src="zorstIcon" alt="Zorst Icon" max-width="7rem">
      </v-img
      >Chrome Browsererweiterung
    </h2>
    <v-col cols="12" md="8">
      <v-card v-bind="vStyle.card">
        <v-card-title class="d-flex justify-space-between"
          >Download
          <v-switch
            v-if="hasZorstDebugAccess"
            v-model="isDebugMode"
            label="ZORST debug mode"
            color="tertiary"
            @update:modelValue="toggleZorstDebugging()"
        /></v-card-title>
        <v-card-text>
          <h3>Schritt 1: Download der Datei</h3>
          Klicke auf diesen Button, um die Datei herunterzuladen:<br />
          <v-btn
            v-bind="vStyle.btn"
            v-if="$store.state.company.softwareIntegration.zvooveOne"
            class="my-3"
            color="primary"
            href="https://zorst.dispositioner.de/download/zorst_full.zip"
            target="_blank"
          >
            ZORST {{ $store.getters.getEnv.zorstVersion }} FULL
          </v-btn>
          <v-btn
            v-bind="vStyle.btn"
            v-if="!$store.state.company.softwareIntegration.zvooveOne"
            color="primary"
            href="https://zorst.dispositioner.de/download/zorst_ats.zip"
            target="_blank"
          >
            ZORST {{ $store.getters.getEnv.zorstVersion }} ATS
          </v-btn>
          <br /><br />
          <h3>Schritt 2: Entpacken der Datei</h3>
          1. Gehe zu dem Ordner, in dem du <code>zorst.zip</code> gespeichert
          hast.<br />
          2. Rechtsklicke auf die Datei und wähle "Alle extrahieren..." oder
          eine ähnliche Option.<br />
          3. Entpacke die Datei in einen Zielordner.<br /><br />
          <h3>Schritt 3: Installation der Erweiterung in Chrome</h3>
          1. Öffne Google Chrome.<br />
          2. Klicke auf das Dreipunkt-Menü (oben rechts) und wähle
          "Erweiterungen" > "Erweiterungen verwalten".<br />
          3. Aktiviere den Entwicklermodus (Schalter oben rechts).<br />
          4.Klicke auf "Entpackte Erweiterung laden".<br />
          5. Wähle den Ordner aus, in den du die Dateien entpackt hast.<br />
          6. Klicke auf "Ordner auswählen".<br /><br /><br />
          <p>Fertig! Die Erweiterung ist jetzt installiert.</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { UserRole } from "../../../../enums/user-role.enum";
import { MutationType } from "../../../../enums/vuex-types.enum";
import { UserRoleHelper } from "../../../../helper/user-role.helper";
import { AccessRule } from "../../../../enums/access-rule.enum";
import { getAssetIconPath } from "../../../../helper/get-assets.helper";

export default defineComponent({
  name: "ConfigZorst",
  computed: {
    ...mapGetters(["getEnv", "user"]),
    ...mapState({
      zorstDebugging: (state: any) => state.zorstDebugging,
    }),
    hasZorstDebugAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.configZorstDebug
      );
    },
  },
  data() {
    return {
      isDebugMode: this.zorstDebugging,
      UserRole,
      vStyle: this.$store.state.vStyle,
      zorstIcon: getAssetIconPath("zorst-logo-wide.png"),
    };
  },
  methods: {
    toggleZorstDebugging() {
      if (this.isDebugMode !== this.zorstDebugging) {
        this.$store.commit(MutationType.toggleZorstDebugging);
      }
    },
  },
});
</script>
