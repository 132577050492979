import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  style: {"color":"black"}
}
const _hoisted_4 = { class: "mx-1 text-caption font-weight-light text-medium-emphasis" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = {
  key: 2,
  class: "mx-1"
}
const _hoisted_7 = {
  key: 3,
  class: "mx-1 text-caption text-medium-emphasis"
}
const _hoisted_8 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_9 = { style: {"font-size":"0.7em"} }
const _hoisted_10 = { style: {"font-size":"0.8em"} }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "ma-0 pa-0" }
const _hoisted_13 = { class: "communication-icons-container" }
const _hoisted_14 = { class: "communication-icons-container" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_AtsRecruitLinkMenu = _resolveComponent("AtsRecruitLinkMenu")!
  const _component_ExistingProfiles = _resolveComponent("ExistingProfiles")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_WhatsAppClient = _resolveComponent("WhatsAppClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_DialogApplicationForm = _resolveComponent("DialogApplicationForm")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_SendCandidateToWp = _resolveComponent("SendCandidateToWp")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_JobAdLeadsCandidate = _resolveComponent("JobAdLeadsCandidate")!
  const _component_DialogInterviewCompleted = _resolveComponent("DialogInterviewCompleted")!
  const _component_DialogManualAnzeigendatenSearch = _resolveComponent("DialogManualAnzeigendatenSearch")!
  const _component_DialogCompareCandidates = _resolveComponent("DialogCompareCandidates")!
  const _component_DialogEditCandidate = _resolveComponent("DialogEditCandidate")!
  const _component_DialogExtractCandidateUuidFromAtsRecruitLink = _resolveComponent("DialogExtractCandidateUuidFromAtsRecruitLink")!
  const _component_DialogSelectAtsCandidates = _resolveComponent("DialogSelectAtsCandidates")!
  const _component_GeneralItem = _resolveComponent("GeneralItem")!

  return (_openBlock(), _createBlock(_component_GeneralItem, {
    ref: "generalItemComponent",
    color: _ctx.item.color,
    dragOverIcon: _ctx.dragOver.icon,
    dragOverText: _ctx.dragOver.text,
    hoverColor: _ctx.item.hoverColor,
    isActive: _ctx.isActive,
    isDialog: _ctx.isOpenedAsDialog(),
    isFullyCollapsed: _ctx.isFullyCollapsed,
    parentRole: _ctx.Role.candidate,
    statusBlinking: _ctx.needToContact || _ctx.prioNeedToContact,
    statusColor: _ctx.statusColor,
    showMatchIcon: _ctx.customerOrDemandTagsMatching,
    onCloseDialog: _cache[35] || (_cache[35] = ($event: any) => (_ctx.$emit(_ctx.CandidateItemEmit.closeDialog))),
    onDragOver: _ctx.handleDragOver,
    onDragStart: _ctx.handleDragStart,
    onIsExpanded: _cache[36] || (_cache[36] = ($event: any) => (_ctx.handleItemExpansion(true))),
    onIsNotExpanded: _cache[37] || (_cache[37] = ($event: any) => (_ctx.handleItemExpansion(false))),
    onOpenContextMenu: _cache[38] || (_cache[38] = ($event: any) => (_ctx.openContextMenu($event)))
  }, {
    "collapsed-view": _withCtx(() => [
      _createVNode(_component_v_sheet, {
        class: "ma-0 pt-1 px-1",
        color: _ctx.item.colorList
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
            default: _withCtx(() => [
              (
              !_ctx.candidate.firstName &&
              !_ctx.candidate.lastName &&
              !_ctx.candidateData?.firstName &&
              !_ctx.candidateData?.lastName
            )
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    rounded: _ctx.vStyle?.btn?.rounded,
                    border: _ctx.vStyle?.btn?.border,
                    loading: _ctx.isLoadingFromAts,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
                    variant: "tonal",
                    density: "compact",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        class: "mr-2",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                          _createTextVNode("fa-solid fa-cloud-arrow-down")
                        ])),
                        _: 1
                      }),
                      _createTextVNode(" #" + _toDisplayString(_ctx.candidate.applicationId) + " ", 1),
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "top left"
                      }, {
                        default: _withCtx(() => _cache[40] || (_cache[40] = [
                          _createTextVNode("Kandidatendaten vollständig aus dem ATS laden ...")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rounded", "border", "loading"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.candidateData?.firstName
                ? _ctx.candidateData.firstName
                : _ctx.candidate.firstName) + " " + _toDisplayString(_ctx.candidateData?.lastName
                ? _ctx.candidateData.lastName
                : _ctx.candidate.lastName) + " ", 1),
                (_ctx.candidateAge && _ctx.candidateAge > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(_ctx.candidateAge) + ")", 1))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.shiftIcon
                ? _ctx.candidateData.shiftIcon
                : _ctx.candidate.shiftIcon) + " ", 1),
                (
                (_ctx.candidateData &&
                  _ctx.candidateData.mobilityRadius &&
                  _ctx.candidateData.mobilityRadius > 0) ||
                (_ctx.candidate &&
                  _ctx.candidate?.mobilityRadius &&
                  _ctx.candidate?.mobilityRadius > 0)
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _createTextVNode(" (" + _toDisplayString(_ctx.candidateData?.mobilityRadius
                  ? _ctx.candidateData.mobilityRadius
                  : _ctx.candidate.mobilityRadius) + " km ", 1),
                      _createVNode(_component_v_icon, { size: "xsmall" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.candidateData?.mobilityIcon
                  ? _ctx.candidateData.mobilityIcon
                  : _ctx.candidate.mobilityIcon), 1)
                        ]),
                        _: 1
                      }),
                      _cache[41] || (_cache[41] = _createTextVNode(" ) "))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.candidateData?.addressCity
                ? _ctx.candidateData.addressCity
                : _ctx.candidate.addressCity), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["status-list", { blinking: _ctx.needToContact || _ctx.prioNeedToContact }]),
                style: _normalizeStyle({ backgroundColor: _ctx.statusColor })
              }, null, 6)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, { class: "my-1" })
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    "item-collapsed": _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.avatar)
          ? (_openBlock(), _createBlock(_component_v_avatar, {
              key: 0,
              size: "2.5rem",
              class: "mr-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  alt: "Avatar",
                  src: _ctx.avatar
                }, null, 8, ["src"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_v_card_title, { class: "d-flex pa-0 text-body-2" }, {
            default: _withCtx(() => [
              (
                !_ctx.candidate.firstName &&
                !_ctx.candidate.lastName &&
                !_ctx.candidateData?.firstName &&
                !_ctx.candidateData?.lastName
              )
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    rounded: _ctx.vStyle.btn.rounded || undefined,
                    border: _ctx.vStyle.btn.border || undefined,
                    loading: _ctx.isLoadingFromAts,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
                    variant: "tonal"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        class: "mr-2",
                        size: "small"
                      }, {
                        default: _withCtx(() => _cache[42] || (_cache[42] = [
                          _createTextVNode("fa-solid fa-cloud-arrow-down")
                        ])),
                        _: 1
                      }),
                      _createTextVNode(" #" + _toDisplayString(_ctx.candidate.applicationId) + " ", 1),
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "top left"
                      }, {
                        default: _withCtx(() => _cache[43] || (_cache[43] = [
                          _createTextVNode("Kandidatendaten vollständig aus dem ATS laden ...")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rounded", "border", "loading"]))
                : _createCommentVNode("", true),
              (_ctx.isAtsStatusUnknown && !_ctx.isAtsStatusChangedFromUnknown)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    icon: "",
                    variant: "text",
                    density: "compact",
                    onClick: _ctx.updateComponent
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { size: "small" }, {
                        default: _withCtx(() => _cache[44] || (_cache[44] = [
                          _createTextVNode("fa-regular fa-question-circle")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "bottom"
                      }, {
                        default: _withCtx(() => _cache[45] || (_cache[45] = [
                          _createTextVNode("ATS Status ist unbekannt! Kandidat aktualisieren und neuen Status abrufen?")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.firstName
                ? _ctx.candidateData?.firstName
                : _ctx.candidate?.firstName) + " " + _toDisplayString(_ctx.candidateData?.lastName
                ? _ctx.candidateData?.lastName
                : _ctx.candidate?.lastName) + " ", 1),
              (_ctx.candidateAge && _ctx.candidateAge > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (" + _toDisplayString(_ctx.candidateAge) + ")", 1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.candidateData?.shiftIcon
                ? _ctx.candidateData?.shiftIcon
                : _ctx.candidate?.shiftIcon) + " ", 1),
              (_ctx.isAtsStatusChangedFromUnknown)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (verschoben nach " + _toDisplayString(_ctx.matchedApplication.ats?.status) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.candidateData?.addressPostalCode
                ? _ctx.candidateData?.addressPostalCode
                : _ctx.candidate?.addressPostalCode) + " " + _toDisplayString(_ctx.candidateData?.addressCity
                ? _ctx.candidateData?.addressCity
                : _ctx.candidate?.addressCity) + " ", 1),
              (
                (_ctx.candidateData &&
                  _ctx.candidateData?.mobilityRadius &&
                  _ctx.candidateData?.mobilityRadius > 0) ||
                (_ctx.candidate &&
                  _ctx.candidate?.mobilityRadius &&
                  _ctx.candidate?.mobilityRadius > 0)
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createTextVNode(" (" + _toDisplayString(_ctx.candidateData?.mobilityRadius
                  ? _ctx.candidateData?.mobilityRadius
                  : _ctx.candidate?.mobilityRadius) + " km ", 1),
                    _createVNode(_component_v_icon, { size: "xsmall" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.candidateData?.mobilityIcon
                  ? _ctx.candidateData?.mobilityIcon
                  : _ctx.candidate?.mobilityIcon), 1)
                      ]),
                      _: 1
                    }),
                    _cache[46] || (_cache[46] = _createTextVNode(" ) "))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    "hide-on-inactive-column": _withCtx(() => [
      _createElementVNode("span", _hoisted_9, [
        _createElementVNode("b", null, "#" + _toDisplayString(_ctx.candidate?.applicationId) + " " + _toDisplayString(_ctx.matchedApplication?.appliedAs
              ? _ctx.matchedApplication.appliedAs
              : _ctx.candidate.appliedAs), 1)
      ]),
      _cache[47] || (_cache[47] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("span", _hoisted_10, [
        _createTextVNode(_toDisplayString(_ctx.matchedMandantName) + " ", 1),
        _createVNode(_component_v_menu, { activator: "parent" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    value: index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, {
                        onClick: ($event: any) => (_ctx.changeMandant(mandant.uuid))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(mandant.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    "communication-icons-container": _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (_ctx.candidateData && _ctx.candidateData?._id)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  icon: "",
                  density: "compact",
                  variant: "text",
                  onClick: _ctx.createNewMandantAppointmentOnDependencies,
                  class: "appointment-icon"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("fa-solid fa-building")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "bottom"
                    }, {
                      default: _withCtx(() => _cache[49] || (_cache[49] = [
                        _createTextVNode(" Statuswechsel mit Automatisierungen z.B. Termine mit Niederlassung ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_btn, {
              variant: "text",
              class: "appointment-icon",
              icon: "",
              density: "compact"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  class: "text-medium-emphasis",
                  color: "secondary"
                }, {
                  default: _withCtx(() => _cache[50] || (_cache[50] = [
                    _createTextVNode("fa-solid fa-screwdriver-wrench")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_menu, {
                  activator: "parent",
                  "offset-y": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptionsRecruit, (option) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: option.value,
                            onClick: ($event: any) => (_ctx.changeStatus(option.value))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(option.text), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => _cache[51] || (_cache[51] = [
                    _createTextVNode("Manueller Statuswechsel ohne automatisierungen wie Mailvorlage, Kalendereintrag oder Dokumentation etc.")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_v_label, {
            style: {"font-size":"x-small","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","max-width":"100%"},
            class: "font-weight-thin ma-0 pa-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getDispositionerStatus()), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_v_spacer),
      _createElementVNode("div", _hoisted_14, [
        (!_ctx.candidateData?.isNotInAts)
          ? (_openBlock(), _createBlock(_component_AtsRecruitLinkMenu, {
              key: 0,
              atsRecruitUuid: _ctx.candidateData?.uuid ?? _ctx.candidate?.candidateUuid,
              atsRecruitCurrentApplicationUuid: _ctx.candidate?.applicationUuid,
              onGetRecruitData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware?.atsRecruit)))
            }, null, 8, ["atsRecruitUuid", "atsRecruitCurrentApplicationUuid"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.showAtsConnectionOptions())
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.getAssetIconPath('icon-ats-disconnected.svg'),
                alt: "ATS disconnected",
                class: "communication-container-img-icon",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.searchForCandidateDataInAts()))
              }, null, 8, _hoisted_15))
            : _createCommentVNode("", true),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[52] || (_cache[52] = [
              _createTextVNode("Keine Verbindung zu eine ATS Kandidatendatensatz! Verbindung mit einem existierenden Kandidaten aus dem ATS herstellen?")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          (_ctx.showAtsConnectionOptions())
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.getAssetIconPath('icon-ats-upload.svg'),
                alt: "ATS disconnected",
                class: "communication-container-img-icon",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sendCandidateToAts()))
              }, null, 8, _hoisted_16))
            : _createCommentVNode("", true),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[53] || (_cache[53] = [
              _createTextVNode("Keine Verbindung zu eine ATS Kandidatendatensatz! Soll der Kandidat an das ATS System gesendet werden?")
            ])),
            _: 1
          })
        ]),
        (_ctx.showExistingProfileIcon())
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              icon: "",
              variant: "text",
              density: "compact",
              class: "communication-container-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "var(--dispatcher-board-communication-icon-size)" }, {
                  default: _withCtx(() => _cache[54] || (_cache[54] = [
                    _createTextVNode("fa-solid fa-image-portrait")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_menu, {
                  activator: "parent",
                  "offset-y": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ExistingProfiles, {
                      profiles: _ctx.candidateData?.profiles || [],
                      showDeleteButton: true,
                      onGetExistingProfile: _ctx.showExistingProfile,
                      onDeleteProfile: _ctx.deleteProfile
                    }, null, 8, ["profiles", "onGetExistingProfile", "onDeleteProfile"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => _cache[55] || (_cache[55] = [
                    _createTextVNode("Profile")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showGdprConsentIcon())
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              icon: "",
              variant: "text",
              density: "compact",
              class: "communication-container-icon",
              color: _ctx.showGdprConsentCred()?.color ?? undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_badge, {
                  color: _ctx.showGdprConsentCred()?.badgeColor ?? undefined,
                  content: _ctx.showGdprConsentCred()?.badgeContent ?? undefined
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "xsmall" }, {
                      default: _withCtx(() => _cache[56] || (_cache[56] = [
                        _createTextVNode("fa-solid fa-fingerprint")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["color", "content"]),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.showGdprConsentCred()?.tooltip ?? 'Datenschutz'
                    }, null, 8, _hoisted_17)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["color"]))
          : _createCommentVNode("", true),
        (!_ctx.isStatusInterview())
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 3,
              icon: "",
              class: "communication-container-icon",
              variant: "text",
              density: "compact",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openApplicationFormDispatcherMode()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { size: "xsmall" }, {
                  default: _withCtx(() => _cache[57] || (_cache[57] = [
                    _createTextVNode("fa-solid fa-id-card")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => _cache[58] || (_cache[58] = [
                    _createTextVNode("Zum Bewerberbogen")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_PhoneClient, {
          ref: "phoneClientComponent",
          candidate: _ctx.candidate,
          candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
          phoneNumbers: _ctx.filteredPhoneNumbers,
          onAddFollowUpEvent: _ctx.addFollowUpOnDependencies,
          onCollapseParentItem: _ctx.collapseItem,
          onIsDialingNumber: _ctx.phoneClientIsDialing,
          onSetAppointmentEvent: _ctx.setAppointmentOnDependencies
        }, null, 8, ["candidate", "candidateName", "phoneNumbers", "onAddFollowUpEvent", "onCollapseParentItem", "onIsDialingNumber", "onSetAppointmentEvent"]),
        _createVNode(_component_WhatsAppClient, {
          AiMessageType: _ctx.AiMessageType?.whatsAppCandidate,
          candidate: _ctx.candidate,
          linkingStatuses: _ctx.linkingStatuses,
          message: _ctx.message.body,
          phoneNumbers: _ctx.filteredPhoneNumbers,
          receiverName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
          salutation: _ctx.salutationText,
          signature: _ctx.signatureWhatsApp,
          onCollapseParentItem: _ctx.collapseItem
        }, null, 8, ["AiMessageType", "candidate", "linkingStatuses", "message", "phoneNumbers", "receiverName", "salutation", "signature", "onCollapseParentItem"]),
        _createVNode(_component_MailClient, {
          ref: "mailClientComponent",
          AiMessageType: _ctx.AiMessageType.mailCandidate,
          candidate: _ctx.candidate,
          candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
          emailAddresses: _ctx.filteredEmail,
          emailObject: _ctx.message,
          linkingStatuses: _ctx.linkingStatuses,
          signature: _ctx.signatureMail,
          onCollapseParentItem: _ctx.collapseItem,
          onEditContacts: _ctx.openEditCandidateModal
        }, null, 8, ["AiMessageType", "candidate", "candidateName", "emailAddresses", "emailObject", "linkingStatuses", "signature", "onCollapseParentItem", "onEditContacts"])
      ]),
      _createVNode(_component_DialogApplicationForm, {
        ref: "dialogApplicationFormComponent",
        candidate: _ctx.candidateData,
        isStatusInterview: _ctx.isStatusInterview(),
        onSubmit: _ctx.updateCandidateDataFromEditDialog
      }, null, 8, ["candidate", "isStatusInterview", "onSubmit"])
    ]),
    "checklist-container": _withCtx(() => [
      _createVNode(_component_Checklist, {
        checklist: _ctx.candidateData?.checklist || [],
        candidate: _ctx.candidate,
        onUpdateChecklist: _ctx.updateChecklist
      }, null, 8, ["checklist", "candidate", "onUpdateChecklist"])
    ]),
    "tags-container": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.candidateData?.tags, (tag, index) => {
        return (_openBlock(), _createBlock(_component_v_chip, {
          key: index,
          class: "dispatcher-board-icon-tag",
          color: "primary",
          "onClick:close": ($event: any) => (_ctx.removeTag(index))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tag) + " ", 1),
            _createVNode(_component_v_icon, {
              small: "",
              onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
              class: "fa fa-times"
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["onClick:close"]))
      }), 128)),
      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
        class: "mt-2",
        label: "Neuer Tag",
        style: {"max-width":"10rem"},
        density: "compact",
        modelValue: _ctx.newTag,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newTag) = $event)),
        onKeyup: _withKeys(_ctx.addTag, ["enter"])
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[59] || (_cache[59] = [
              _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 16, ["modelValue", "onKeyup"]),
      _createVNode(_component_v_divider, { class: "mb-4" })
    ]),
    "dependencies-container": _withCtx(() => [
      (
          _ctx.candidate &&
          (_ctx.hasLinking(_ctx.candidate?.parentObjectid) || _ctx.isDependenciesActive)
        )
        ? (_openBlock(), _createBlock(_component_Dependencies, {
            key: 0,
            ref: "dependenciesComponent",
            mandant: [_ctx.matchedMandantUuid || ''],
            candidate_id: _ctx.candidate?.parentObjectid,
            linkingDescription: _ctx.linkingDescription,
            onUpdateCandidateStatus: _ctx.handleCandidateStatusUpdate,
            onCandidateToMandantLinked: _ctx.setCandidateToMandantFromDependencies,
            onNeedToContact: _cache[7] || (_cache[7] = ($event: any) => (_ctx.prioNeedToContact = true)),
            onNotNeedToContact: _cache[8] || (_cache[8] = ($event: any) => (_ctx.prioNeedToContact = false)),
            onOpenPhoneClient: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openPhoneClient()))
          }, null, 8, ["mandant", "candidate_id", "linkingDescription", "onUpdateCandidateStatus", "onCandidateToMandantLinked"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_divider, { class: "mb-4" })
    ]),
    "info-container": _withCtx(() => [
      _createVNode(_component_v_textarea, {
        class: "mt-2 pr-1",
        modelValue: _ctx.interviewResultEdit,
        "onUpdate:modelValue": [
          _cache[10] || (_cache[10] = ($event: any) => ((_ctx.interviewResultEdit) = $event)),
          _ctx.saveInterviewResultDebounced
        ],
        label: "Zusammenfassung Vorstellungsgespräch / Notizen:",
        variant: "solo",
        rows: "4"
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_v_container, { class: "ma-0 pa-0 d-flex" }, {
        default: _withCtx(() => [
          (
            _ctx.candidateData &&
            _ctx.softwareIntegration?.wordPressPlugin &&
            _ctx.isCandidateActiveForPlacement()
          )
            ? (_openBlock(), _createBlock(_component_v_checkbox, {
                key: 0,
                density: "compact",
                modelValue: _ctx.candidateData.isPublishedOnWebsite,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.candidateData.isPublishedOnWebsite) = $event)),
                label: `Kandidat auf die Webseite stellen (WordPress Plugin)`,
                onChange: _ctx.handlePublishedOnWebseiteChange
              }, null, 8, ["modelValue", "onChange"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer)
        ]),
        _: 1
      }),
      (_ctx.getCareerLevelDescription())
        ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.getCareerLevelDescription()), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_v_divider),
      (_ctx.softwareIntegration?.wordPressPlugin)
        ? (_openBlock(), _createBlock(_component_SendCandidateToWp, {
            key: 1,
            ref: "sendCandidateToWpComponent",
            onAborted: _cache[12] || (_cache[12] = ($event: any) => (_ctx.sendCandidateToWpAborted()))
          }, null, 512))
        : _createCommentVNode("", true)
    ]),
    "timeline-container": _withCtx(() => [
      _createVNode(_component_Timeline, {
        candidate: _ctx.candidate,
        candidateName: `${_ctx.candidateData?.firstName} ${_ctx.candidateData?.lastName}`,
        mandant: _ctx.matchedMandantUuid || ''
      }, null, 8, ["candidate", "candidateName", "mandant"])
    ]),
    "append-container": _withCtx(() => [
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(_component_v_menu, {
          modelValue: _ctx.showContextMenu,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.showContextMenu) = $event)),
          style: _normalizeStyle({
            top: _ctx.contextMenuPosition.y + 'px',
            left: _ctx.contextMenuPosition.x + 'px',
          })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, { dense: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, {
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openEditCandidateModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1 mr-2",
                      size: "xs"
                    }, {
                      default: _withCtx(() => _cache[60] || (_cache[60] = [
                        _createTextVNode(" fa-solid fa-pen-to-square ")
                      ])),
                      _: 1
                    }),
                    _cache[61] || (_cache[61] = _createTextVNode("bearbeiten"))
                  ]),
                  _: 1
                }),
                (!_ctx.isOpenedAsDialog())
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 0,
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.askCustomerItemsForMatch()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-2",
                          size: "xs"
                        }, {
                          default: _withCtx(() => _cache[62] || (_cache[62] = [
                            _createTextVNode(" fa-solid fa-heart ")
                          ])),
                          _: 1
                        }),
                        _cache[63] || (_cache[63] = _createTextVNode("Matching"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.$store.state?.isActiveCandidateMatching)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 1,
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.clearMatchingOject()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-2",
                          size: "xs"
                        }, {
                          default: _withCtx(() => _cache[64] || (_cache[64] = [
                            _createTextVNode(" fa-solid fa-heart-circle-xmark ")
                          ])),
                          _: 1
                        }),
                        _cache[65] || (_cache[65] = _createTextVNode("Matches bei Unternehmen/Anfragen entfernen"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_list_item, {
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.placementWebSearch()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1 mr-2",
                      size: "xs"
                    }, {
                      default: _withCtx(() => _cache[66] || (_cache[66] = [
                        _createTextVNode(" fa-brands fa-google ")
                      ])),
                      _: 1
                    }),
                    _cache[68] || (_cache[68] = _createTextVNode("Jobsuche")),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1",
                      size: "0.8rem"
                    }, {
                      default: _withCtx(() => _cache[67] || (_cache[67] = [
                        _createTextVNode(" fa-solid fa-rocket ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item, {
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setCandidateAddressForCommuteWebSearch()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1 mr-2",
                      size: "xs"
                    }, {
                      default: _withCtx(() => _cache[69] || (_cache[69] = [
                        _createTextVNode(" fa-solid fa-location-dot ")
                      ])),
                      _: 1
                    }),
                    _cache[71] || (_cache[71] = _createTextVNode("Arbeitsweg Recherche")),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1",
                      size: "0.8rem"
                    }, {
                      default: _withCtx(() => _cache[70] || (_cache[70] = [
                        _createTextVNode(" fa-solid fa-rocket ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_list_item, {
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.deleteCandidate()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis ml-1 mr-2",
                      size: "xs"
                    }, {
                      default: _withCtx(() => _cache[72] || (_cache[72] = [
                        _createTextVNode(" fa-solid fa-xmark ")
                      ])),
                      _: 1
                    }),
                    _cache[73] || (_cache[73] = _createTextVNode("Kandidat vom Dispoboard löschen"))
                  ]),
                  _: 1
                }),
                (_ctx.hasFullCandidatesAccess)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 2,
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.deleteCandidate(_ctx.candidate?.candidateUuid)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-2",
                          size: "xs"
                        }, {
                          default: _withCtx(() => _cache[74] || (_cache[74] = [
                            _createTextVNode(" fa-solid fa-user-xmark ")
                          ])),
                          _: 1
                        }),
                        _cache[75] || (_cache[75] = _createTextVNode("ADMIN: Kandidat anhand uuid löschen"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isOpenedAsDialog())
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 3,
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.emitLoadCandidatesFull()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-2",
                          size: "xs"
                        }, {
                          default: _withCtx(() => _cache[76] || (_cache[76] = [
                            _createTextVNode(" fa-regular fa-id-card ")
                          ])),
                          _: 1
                        }),
                        _cache[77] || (_cache[77] = _createTextVNode("alle vollständig laden"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isOpenedAsDialog())
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 4,
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.emitLoadCandidatesFromAts()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-2",
                          size: "xs"
                        }, {
                          default: _withCtx(() => _cache[78] || (_cache[78] = [
                            _createTextVNode(" fa-solid fa-cloud-arrow-down ")
                          ])),
                          _: 1
                        }),
                        _cache[79] || (_cache[79] = _createTextVNode("alle aus ATS überschreiben"))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isOpenedAsDialog())
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 5,
                      onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.emitToggleItemsMinimized()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: "text-medium-emphasis ml-1 mr-1",
                          size: "xs"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isFullyCollapsed
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-chevron-down"), 1)
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.isFullyCollapsed ? "Karten normal" : "Karten minimieren"), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "style"]),
        (
            _ctx.isExpanded &&
            _ctx.candidateData?.jobAdLeads &&
            _ctx.isCandidateActiveForPlacement() &&
            !_ctx.isOpenedAsDialog()
          )
          ? (_openBlock(), _createBlock(_component_JobAdLeadsCandidate, {
              key: 0,
              ref: "jobAdListComponent",
              jobAds: _ctx.candidateData?.jobAdLeads as JobAdLead[] || [],
              indexCareerLevel: _ctx.candidateData?.careerLevel,
              mandant: _ctx.matchedMandantUuid || '',
              onGenerateAiSearchterm: _cache[24] || (_cache[24] = ($event: any) => (_ctx.fetchJobAdLeads(true))),
              onManualAnzeigendatenSearch: _cache[25] || (_cache[25] = ($event: any) => (_ctx.openAnzeigendatenManualSearch())),
              onUpdateCareerLevels: _ctx.updateCareerLevels
            }, null, 8, ["jobAds", "indexCareerLevel", "mandant", "onUpdateCareerLevels"]))
          : _createCommentVNode("", true),
        (_ctx.isDialogInterviewCompletedActive)
          ? (_openBlock(), _createBlock(_component_DialogInterviewCompleted, {
              key: 1,
              ref: "dialogInterviewCompletedComponent",
              atsRecruitUuid: _ctx.candidate.candidateUuid,
              onSaveResumeInterview: _ctx.handleSaveResumeInterview,
              onFetchRecruitData: _cache[26] || (_cache[26] = ($event: any) => (_ctx.loadCandidate(_ctx.ExternalSoftware.atsRecruit))),
              onOpenEditCandidateDialog: _ctx.openEditCandidateModal
            }, null, 8, ["atsRecruitUuid", "onSaveResumeInterview", "onOpenEditCandidateDialog"]))
          : _createCommentVNode("", true),
        (_ctx.isDialogManualAnzeigendatenSearchActive)
          ? (_openBlock(), _createBlock(_component_DialogManualAnzeigendatenSearch, {
              key: 2,
              dataForQuery: _ctx.prepareDataForJobAdLeadsQuery(),
              ref: "dialogManualAnzeigendatenSearchComponent",
              onSearchAndReplace: _ctx.searchAnzeigendatenAndReplace,
              onSearchAndAdd: _ctx.searchAnzeigendatenAndAdd
            }, null, 8, ["dataForQuery", "onSearchAndReplace", "onSearchAndAdd"]))
          : _createCommentVNode("", true),
        _createVNode(_component_DialogCompareCandidates, {
          showDialog: _ctx.showDialogCompareCandidates,
          onCloseDialog: _cache[27] || (_cache[27] = ($event: any) => (_ctx.showDialogCompareCandidates = false)),
          candidateExisting: _ctx.candidateToCompareExisting,
          candidateCurrent: _ctx.candidateToCompareCurrent,
          onSelectExisting: _cache[28] || (_cache[28] = ($event: any) => (_ctx.selectComparedCandidate(false))),
          onSelectCurrent: _cache[29] || (_cache[29] = ($event: any) => (_ctx.selectComparedCandidate(true)))
        }, null, 8, ["showDialog", "candidateExisting", "candidateCurrent"]),
        (_ctx.isDialogEditCandidateActive)
          ? (_openBlock(), _createBlock(_component_DialogEditCandidate, {
              key: 3,
              ref: "dialogEditCandidateComponent",
              candidate: _ctx.candidateData,
              onSubmit: _ctx.updateCandidateDataFromEditDialog,
              onFetchJobAdLeads: _cache[30] || (_cache[30] = ($event: any) => (_ctx.fetchJobAdLeads())),
              onGenerateAiSearchterm: _cache[31] || (_cache[31] = ($event: any) => (_ctx.fetchJobAdLeads(true))),
              onManualAnzeigendatenSearch: _cache[32] || (_cache[32] = ($event: any) => (_ctx.openAnzeigendatenManualSearch()))
            }, null, 8, ["candidate", "onSubmit"]))
          : _createCommentVNode("", true),
        _createVNode(_component_DialogExtractCandidateUuidFromAtsRecruitLink, {
          showDialog: _ctx.showDialogExtractCandidateUuidFromAtsRecruitLink,
          onCloseDialog: _cache[33] || (_cache[33] = ($event: any) => (
            _ctx.showDialogExtractCandidateUuidFromAtsRecruitLink = false
          )),
          onExtractedUuid: _ctx.searchForCandidateDataInAtsReceiveCandidateUuid
        }, null, 8, ["showDialog", "onExtractedUuid"]),
        _createVNode(_component_DialogSelectAtsCandidates, {
          showDialog: _ctx.showDialogSelectAtsCandidates,
          onCloseDialog: _cache[34] || (_cache[34] = ($event: any) => (_ctx.showDialogSelectAtsCandidates = false)),
          resultsCandidateSearch: _ctx.resultsCandidateSearch,
          onSelectCandidateSearchResult: 
            _ctx.selectCandidateToUpdateAndCheckForDuplicates
          
        }, null, 8, ["showDialog", "resultsCandidateSearch", "onSelectCandidateSearchResult"])
      ]))
    ]),
    _: 1
  }, 8, ["color", "dragOverIcon", "dragOverText", "hoverColor", "isActive", "isDialog", "isFullyCollapsed", "parentRole", "statusBlinking", "statusColor", "showMatchIcon", "onDragOver", "onDragStart"]))
}