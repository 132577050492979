<!-- src/components/config/ai/AiConfigEmailTemplates.vue -->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-envelope</v-icon>
      Nachrichten Vorlagen
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(template, index) in localAiData.template.mail"
          :key="index"
        >
          <v-card v-bind="vStyle.card" class="mb-4 pa-0">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-5"
            >
              {{ getEventNameByCandidateStatus(template.candidateStatus) }}
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="removeTemplate(index)">
                <v-icon>fa-solid fa-xmark</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-bind="vStyle.input"
                v-model="template.subject"
                label="Betreff"
              ></v-text-field>
              <v-textarea
                v-bind="vStyle.input"
                v-model="template.text1"
                label="Text 1"
              ></v-textarea>
              <v-textarea
                v-bind="vStyle.input"
                v-model="template.text2"
                label="Text 2"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card v-bind="vStyle.card" class="border-dashed mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-5"
            >
              Neue Nachrichtenvorlage
            </v-card-title>
            <v-card-text>
              <v-select
                v-bind="vStyle.input"
                v-model="newMailTemplate.candidateStatus"
                :items="candiateStatusOptions"
                label="Status"
                density="compact"
              ></v-select>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMailTemplate.subject"
                label="Betreff"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-textarea
                v-bind="vStyle.input"
                v-model="newMailTemplate.text1"
                label="Text 1"
                row-height="20"
                rows="3"
                density="compact"
                class="mb-2"
              ></v-textarea>
              <v-textarea
                v-bind="vStyle.input"
                v-model="newMailTemplate.text2"
                label="Text 2"
                row-height="20"
                rows="4"
                density="compact"
                class="mb-2"
              ></v-textarea>
              <v-btn color="primary" variant="text" @click="addNewMailTemplate">
                Hinzufügen
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AiData } from "@/models/company-config.model";
import { mapGetters } from "vuex";
import { LinkingStatus } from "@/enums/dependency.enum";
import ConfigSaveButton from "../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";
export default defineComponent({
  name: "AiConfigEmailTemplates",
  components: {
    ConfigSaveButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      localAiData: cloneDeep(this.modelValue),
      newMailTemplate: {
        candidateStatus: "" as LinkingStatus,
        subject: "",
        text1: "",
        text2: "",
      },
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEventNameByCandidateStatus"]),
    candiateStatusOptions() {
      return Object.values(LinkingStatus);
    },
  },
  watch: {
    modelValue: {
      handler(newValue: AiData) {
        this.localAiData = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localAiData: {
      handler(newValue: AiData) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.isDirty = false;
    }, 500);
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
    removeTemplate(index: number) {
      this.localAiData.template.mail.splice(index, 1);
    },
    addNewMailTemplate() {
      if (this.newMailTemplate.subject && this.newMailTemplate.text1) {
        this.localAiData.template.mail.push({
          ...this.newMailTemplate,
        });
        this.resetNewMailTemplate();
        this.handleSubmit();
      }
    },
    resetNewMailTemplate() {
      this.newMailTemplate = {
        candidateStatus: "" as LinkingStatus,
        subject: "",
        text1: "",
        text2: "",
      };
    },
  },
});
</script>
