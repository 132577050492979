import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "popup-content" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "document-links" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "info-table" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, [
      (_ctx.lead.link)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.lead.link,
            target: "_blank",
            class: "document-link"
          }, _toDisplayString(_ctx.lead.jobTitle), 9, _hoisted_2))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.lead.jobTitle), 1)
          ], 64))
    ]),
    _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.lead.internLinkTxt)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("a", {
              href: _ctx.lead.internLinkTxt,
              target: "_blank",
              class: "document-icon"
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "far fa-file-lines" }, null, -1)
            ]), 8, _hoisted_4),
            _cache[1] || (_cache[1] = _createTextVNode(" Text öffnen "))
          ], 64))
        : _createCommentVNode("", true),
      _cache[4] || (_cache[4] = _createTextVNode("   ")),
      (_ctx.lead.internLinkPdf)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("a", {
              href: _ctx.lead.internLinkPdf,
              target: "_blank",
              class: "document-icon"
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "far fa-file-pdf" }, null, -1)
            ]), 8, _hoisted_5),
            _cache[3] || (_cache[3] = _createTextVNode(" PDF öffnen "))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("table", _hoisted_6, [
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _cache[5] || (_cache[5] = _createElementVNode("td", null, [
            _createElementVNode("strong", null, "Firma:")
          ], -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.name || "N/A"), 1)
        ]),
        _createElementVNode("tr", null, [
          _cache[6] || (_cache[6] = _createElementVNode("td", null, [
            _createElementVNode("strong", null, "Ort:")
          ], -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.postalCode) + " " + _toDisplayString(_ctx.lead.company?.city), 1)
        ]),
        (_ctx.lead.company?.address)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
              _cache[7] || (_cache[7] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Adresse:")
              ], -1)),
              _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.address) + " " + _toDisplayString(_ctx.lead.company?.addressNumber), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.lead.company?.phone)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Tel:")
              ], -1)),
              _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.phone), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.lead.company?.email)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
              _cache[9] || (_cache[9] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Email:")
              ], -1)),
              _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.email), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.lead.company?.internet)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
              _cache[10] || (_cache[10] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Web:")
              ], -1)),
              _createElementVNode("td", null, [
                _createElementVNode("a", {
                  href: _ctx.lead.company?.internet,
                  target: "_blank"
                }, _toDisplayString(_ctx.lead.company?.internet), 9, _hoisted_11)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.lead.company?.employees)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
              _cache[11] || (_cache[11] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Mitarbeiter:")
              ], -1)),
              _createElementVNode("td", null, _toDisplayString(_ctx.lead.company?.employees), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.lead.branche)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
              _cache[12] || (_cache[12] = _createElementVNode("td", null, [
                _createElementVNode("strong", null, "Branche:")
              ], -1)),
              _createElementVNode("td", null, _toDisplayString(_ctx.lead.branche), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}