<!--src/components/config/ai/AiConfigJobAdTemplate.vue-->
<template>
  <h2 class="mb-5">
    <v-icon size="small" class="mr-2">fa-solid fa-rectangle-ad</v-icon>Vorlage
    Stellenanzeigen
  </h2>
  <v-form class="mt-9" v-model="isValid">
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.jobTitle"
              label="Stellen"
              class="mb-4"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.tasksHeader"
              label="Aufgaben Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.tasks"
              label="Aufgaben"
            ></v-textarea>
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.technicalRequirementsHeader"
              label="Fachliche Anforderungen Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.technicalRequirements"
              label="Fachliche Anforderungen"
            ></v-textarea>
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.personalRequirementsHeader"
              label="Persönliche Anforderungen Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.personalRequirements"
              label="Persönliche Anforderungen"
              rows="5"
            ></v-textarea>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.metaKeywords"
              label="Meta Beschreibung"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.employerBenefitsHeader"
              label="Benefits Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.employerBenefits"
              label="Benefits"
            ></v-textarea>
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.perspectivesHeader"
              label="Perspektiven Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.perspectives"
              label="Perspektiven"
            ></v-textarea>
            <v-text-field
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.jobObjectiveHeader"
              label="Stellenziel Header"
            ></v-text-field>
            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.jobObjective"
              label="Stellenziel"
            ></v-textarea>
            <v-divider class="mt-4 mb-8"></v-divider>

            <v-textarea
              v-bind="vStyle.input"
              density="compact"
              v-model="localAiData.template.jobAd.metaDescription"
              label="Meta Keywords"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-card v-bind="vStyle.card">
          <v-card-title>
            <v-icon size="small" class="mr-2">fa-solid fa-rocket</v-icon>
            So erstellst Du Deine KI-Vorlage
          </v-card-title>
          <v-card-text class="text-body-2 mt-3">
            Auf der linken Seite gibst Recurion ein Beispiel für eine
            Stellenanzeige ein. Unterhalb der Felder kannst Du die Vorlage
            Deiner Stelleanzeige anpassen und als Template in Dein ATS
            importieren.

            <h4 class="text-subtitle-1 mt-8 mb-2">
              Die KI unterstützt Dich dabei:
            </h4>

            <v-list-item prepend-icon="fa-solid fa-check" density="compact">
              Sie analysiert Deinen Stil und passt sich an
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-check" density="compact">
              Generiert passende Aufgaben & Anforderungen
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-check" density="compact">
              Passt den Kommunikationsstil flexibel an
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-check" density="compact">
              Ergänzt relevante Fachbegriffe
            </v-list-item>

            <h4 class="text-subtitle-1 mt-8 mb-2">
              Tipps für beste Ergebnisse:
            </h4>

            <v-list-item prepend-icon="fa-solid fa-lightbulb" density="compact">
              Am besten lässt Du die Felder für Aufgaben und Anforderungen leer,
              die AI generiert dann passende Aufgaben und Anforderungen.
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-lightbulb" density="compact">
              Du kannst allgemeine Informationen wie Stellenziel, Benefits und
              Perspektiven in die Felder schreiben und Recurion genierert dann
              passend aus diesen Informationen die Texte für Deine
              Stellenanzeige.
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-lightbulb" density="compact">
              Du kannst auch Prompts oder Anweisungen in die Felder schreiben.
            </v-list-item>
            <v-list-item prepend-icon="fa-solid fa-lightbulb" density="compact">
              Je konkreter Deine Angaben, desto besser das Ergebnis
            </v-list-item>

            <h4 class="text-subtitle-1 mt-8 mb-2">Design personalisieren</h4>
            <v-list-item prepend-icon="fa-solid fa-palette" density="compact">
              Passe die Farben an Deine Marke an, wähle Deine Schriftart und
              füge Dein Logo ein.
            </v-list-item>

            <h4 class="text-subtitle-1 mt-8 mb-2">Vorschau und Export</h4>
            <v-list-item prepend-icon="fa-solid fa-eye" density="compact">
              Prüfe das Ergebnis in der Live-Vorschau und exportiere die fertige
              Anzeige für Dein ATS-System.
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-card subtitle="Primäre Farbe" v-bind="vStyle.card" class="mb-5">
          <v-color-picker
            v-model="localAiData.template.jobAd.primaryColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
        </v-card>
        <v-card subtitle="Sekundäre Farbe" v-bind="vStyle.card" class="mb-5">
          <v-color-picker
            v-model="localAiData.template.jobAd.secondaryColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
        </v-card>
        <v-card subtitle="Hintergrundfarbe" v-bind="vStyle.card" class="mb-5">
          <v-color-picker
            v-model="localAiData.template.jobAd.bgColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
          <v-slider
            class="mt-10"
            v-bind="vStyle.input"
            v-model="localAiData.template.jobAd.shadow1"
            step="0.01"
            min="0.0"
            max="1"
            label="Schatten 1"
          ></v-slider>
          <v-slider
            v-bind="vStyle.input"
            v-model="localAiData.template.jobAd.shadow2"
            step="0.01"
            min="0.0"
            max="1"
            label="Schatten 2"
          ></v-slider>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-slider
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.rotateThreshold"
          step="0.1"
          min="0"
          max="45"
          label="Drehung"
        ></v-slider>
        <v-slider
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.shiftX"
          step="0.01"
          min="0"
          max="5"
          label="Versatz X"
        ></v-slider>
        <v-slider
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.shiftY"
          step="0.01"
          min="0"
          max="5"
          label="Versatz Y"
        ></v-slider>
        <v-slider
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.rounding"
          step="0.1"
          min="0"
          max="20"
          label="Rundung"
        ></v-slider>
        <div class="mt-4 d-flex">
          <v-text-field
            density="compact"
            v-bind="vStyle.input"
            v-model="localAiData.template.jobAd.imageUrl"
            label="Header Bild URL"
            class="mr-3"
          ></v-text-field>
          <v-text-field
            density="compact"
            v-bind="vStyle.input"
            v-model="localAiData.template.jobAd.logoUrl"
            label="Logo URL"
            class="ml-3"
          ></v-text-field>
        </div>
        <JobAdTemplate :jobAdTemplateData="localAiData.template.jobAd" />
      </v-col>
      <v-col cols="12" md="2">
        <v-card
          subtitle="Schrift auf primäre Farbe"
          v-bind="vStyle.card"
          class="mb-5"
        >
          <v-color-picker
            v-model="localAiData.template.jobAd.onPrimaryColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
        </v-card>
        <v-card
          subtitle="Schrift auf sekundäre Farbe"
          v-bind="vStyle.card"
          class="mb-5"
        >
          <v-color-picker
            v-model="localAiData.template.jobAd.onSecondaryColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
        </v-card>
        <v-card
          subtitle="Schrift auf tertiäre Farbe"
          v-bind="vStyle.card"
          class="mb-5"
        >
          <v-color-picker
            v-model="localAiData.template.jobAd.onTertiaryColor"
            :modes="['hex']"
            width="100%"
          ></v-color-picker>
        </v-card>
        <v-select
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.fontFamily"
          :items="fontFamilies"
          item-title="name"
          item-value="value"
          label="Schriftart"
          class="mt-10"
        ></v-select>
        <v-text-field
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.impressumUrl"
          label="Impressum URL"
        ></v-text-field>
        <v-text-field
          v-bind="vStyle.input"
          v-model="localAiData.template.jobAd.impressumText"
          label="Impressum Text"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AiData } from "@/models/company-config.model";
import JobAdTemplate from "../elements/JobAdTemplate.vue";
import ConfigSaveButton from "../elements/ConfigSaveButton.vue";
import { fontFamilies } from "../../../constants/fontFamilies";
import { cloneDeep } from "lodash";

enum AiConfigJobAdTemplateEmit {
  updateModelValue = "update:modelValue",
  save = "save",
}

export default defineComponent({
  name: "AiConfigJobAdTemplate",
  components: {
    ConfigSaveButton,
    JobAdTemplate,
  },
  emits: [
    AiConfigJobAdTemplateEmit.updateModelValue,
    AiConfigJobAdTemplateEmit.save,
  ],
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      fontFamilies: fontFamilies,
      isDirty: false,
      isValid: false as boolean | null,
      localAiData: cloneDeep(this.modelValue),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    jobAdTemplate() {
      return this.localAiData?.template?.jobAd;
    },
  },
  watch: {
    modelValue: {
      handler(newValue: AiData) {
        this.localAiData = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localAiData: {
      handler(newValue: AiData) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.localAiData.template.jobAd.contactTextHeader =
      this.$store.state.company?.mandants[0]?.name;
    this.localAiData.template.jobAd.contactText =
      this.$store.state.company?.mandants[0]?.contact;
    setTimeout(() => {
      this.isDirty = false;
    }, 500);
  },
  methods: {
    handleSubmit() {
      this.$emit(AiConfigJobAdTemplateEmit.updateModelValue, this.localAiData);
      this.$emit(AiConfigJobAdTemplateEmit.save);
      this.isDirty = false;
    },
  },
});
</script>
