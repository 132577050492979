<template>
  <AdminMenu />
  <AppHeader v-if="layout !== AppLayout.classic"></AppHeader>
  <v-container :fluid="true">
    <div class="roles-container">
      <h1>Rollenverwaltung</h1>

      <!-- Neue Rolle hinzufügen -->
      <div class="role-input" v-if="hasRoleCreateAccess">
        <input
          v-model="newRoleName"
          type="text"
          placeholder="Neue Rolle..."
          @keyup.enter="createRole"
        />
        <button @click="createRole">Hinzufügen</button>
      </div>

      <!-- Rollenliste -->
      <div v-for="role in roles" :key="role._id" class="role-item">
        <div class="role-header">
          <h2>{{ role.name }}</h2>
          <v-btn
            icon
            variant="text"
            density="compact"
            @click="deleteRole(role._id as string)"
            v-if="hasRoleDeleteAccess"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </div>

        <!-- Berechtigungen verwalten -->
        <div class="permissions">
          <h3>Berechtigungen:</h3>

          <div v-if="role.permissions?.length === 0">Keine Berechtigungen</div>
          <ul>
            <li v-for="permission in role.permissions" :key="permission">
              {{ permission }}
              <button
                class="remove-btn"
                @click="removePermission(role, permission)"
                v-if="hasRoleUpdateAccess"
              >
                ✖
              </button>
            </li>
          </ul>

          <!-- Neue Berechtigung hinzufügen -->
          <div class="permission-add">
            <select
              v-model="newPermissions[role._id as string]"
              class="permission-select"
            >
              <option
                v-for="perm in availablePermissions"
                :key="perm"
                :value="perm"
              >
                {{ perm }}
              </option>
            </select>
            <button @click="addPermission(role)" v-if="hasRoleUpdateAccess">
              + Berechtigung
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Role } from "../../../models/role.model";
import { AppLayout } from "../../../enums/app-layout.enum";
import AdminMenu from "../../menus/admin-menu.component.vue";
import AppHeader from "@/components/header/Header.vue";
import { RoleService } from "../../../services/api/role.service";
import { UserRoleHelper } from "../../../helper/user-role.helper";
import { mapGetters } from "vuex";
import { AccessRule } from "../../../enums/access-rule.enum";
import ToastService from "../../../services/toast.service";
import { ApiResponse } from "../../../models/api-response.model";

export default defineComponent({
  name: "RolesView",
  components: {
    AdminMenu,
    AppHeader,
  },
  computed: {
    ...mapGetters(["user"]),

    hasRoleDeleteAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.roleDelete
      );
    },

    hasRoleCreateAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.roleCreate
      );
    },

    hasRoleUpdateAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.roleUpdate
      );
    },
  },
  data() {
    return {
      AppLayout: AppLayout,
      layout: this.$store.getters.appLayout,
      roles: [] as Role[],
      newRoleName: "",
      newPermissions: {} as any, // Speichert ausgewählte Berechtigungen für jede Rolle
      roleService: RoleService.getInstance(),
      availablePermissions: Object.values(AccessRule),
    };
  },
  async mounted() {
    this.roles = (await this.roleService.getRoles()).reverse();
  },
  methods: {
    createRole() {
      if (!this.newRoleName.trim()) return;
      const errorFunc = () =>
        ToastService.showError("Rolle konnte nicht angelegt werden");
      try {
        this.roleService
          .createRole(this.newRoleName.trim())
          .then((apiResponse: ApiResponse) => {
            if (apiResponse.response) {
              this.roles = this.roles.reverse();
              this.roles.push(apiResponse.response);
              this.roles = this.roles.reverse();
              this.newRoleName = "";
            } else if (apiResponse.error) {
              console.error(apiResponse.error);
              errorFunc();
            }
          });
      } catch (error) {
        errorFunc();
      }
    },
    deleteRole(roleId: string) {
      const errorFunc = () =>
        ToastService.showError("Rolle konnte nicht gelöscht werden");
      try {
        this.roleService.removeRole(roleId).then((response: ApiResponse) => {
          if (response.response) {
            this.roles = this.roles.filter(
              (role: Role) => (role._id as string) !== roleId
            );
          } else if (response.error) {
            console.error(response.error);
            errorFunc();
          }
        });
      } catch (error) {
        errorFunc();
      }
    },
    addPermission(role: Role) {
      const selectedPermission = this.newPermissions[role._id as string];
      if (!selectedPermission) {
        ToastService.show("Bitte Berechtigung auswählen");
        return;
      }
      const errorFunc = () =>
        ToastService.showError("Rolle konnte nicht gelöscht werden");
      try {
        this.roleService
          .addPermission(role._id as string, selectedPermission)
          .then((response: ApiResponse) => {
            if (response.response) {
              if (
                selectedPermission &&
                !role.permissions.includes(selectedPermission)
              ) {
                role.permissions.push(selectedPermission);
                this.newPermissions[role._id as string] = ""; // reset selection
              }
            } else if (response.error) {
              console.error(response.error);
              errorFunc();
            }
          });
      } catch (error) {
        errorFunc();
      }
    },
    removePermission(role: Role, permission: string) {
      const errorFunc = () =>
        ToastService.showError("Rolle konnte nicht gelöscht werden");
      try {
        this.roleService
          .removePermission(role._id as string, permission)
          .then((response: ApiResponse) => {
            if (response.response) {
              role.permissions = role.permissions.filter(
                (p: string) => p !== permission
              );
            } else if (response.error) {
              console.error(response.error);
              errorFunc();
            }
          });
      } catch (error) {
        errorFunc();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.permission-select {
  background-color: #edeceb;
  border: 1px solid #ccc;
}

.roles-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
}

.role-input {
  display: flex;
  margin-bottom: 15px;
  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
  }
  button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 5px;
  }
}

.role-item {
  background: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-btn {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.permissions {
  margin-top: 10px;
  h3 {
    margin-bottom: 5px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      background: white;
      padding: 5px;
      border: 1px solid #ddd;
      margin-bottom: 5px;
      border-radius: 3px;
    }
  }
}

.remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.permission-add {
  margin-top: 5px;
  display: flex;
  select {
    flex: 1;
    padding: 5px;
  }
  button {
    margin-left: 5px;
    padding: 5px 10px;
    background: green;
    color: white;
    border: none;
    cursor: pointer;
  }
}
</style>
