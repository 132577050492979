<template>
  <v-form>
    <v-row>
      <v-col cols="9">
        <v-text-field
          v-bind="vStyle.input"
          label="Server"
          v-model="emailConfigCopy.server"
          hide-details="true"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-bind="vStyle.input"
          label="Port"
          v-model="emailConfigCopy.port"
          hide-details="true"
          type="number"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-bind="vStyle.input"
          label="E-Mail Adresse"
          v-model="emailConfigCopy.from"
          hide-details="true"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="display-flex justify-end">
        <v-checkbox
          v-bind="vStyle.input"
          label="SSL"
          v-model="emailConfigCopy.secure"
          hide-details="true"
          density="compact"
          class="ma-1"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="vStyle.input"
          label="Username"
          v-model="emailConfigCopy.username"
          hide-details="true"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-bind="vStyle.input"
          label="Passwort"
          v-model="emailConfigCopy.password"
          hide-details="true"
          density="compact"
          class="ma-1"
          type="password"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-5">
      <div
        :style="{
          visibility: errorCode ? 'visible' : 'hidden',
          opacity: errorCode ? 1 : 0,
          color: '#f00',
        }"
      >
        {{ errorCode }}
      </div>
      <EmailTestButton
        v-if="!testSuccess"
        :modelValue="modelValue"
        @testConfig="handleEmailTest"
      ></EmailTestButton>
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MailServer } from "../../models/mailserver.model";
import EmailTestButton from "../email/email-test/EmailTest.vue";
import ToastService from "../../services/toast.service";

enum EmailServerConfigFormEmits {
  testResult = "testResult",
  updateModelValue = "update:modelValue",
}

export default defineComponent({
  name: "EmailServerConfigForm",
  emits: [
    EmailServerConfigFormEmits.updateModelValue,
    EmailServerConfigFormEmits.testResult,
  ],
  components: {
    EmailTestButton,
  },
  props: {
    modelValue: {
      type: Object as () => MailServer,
      required: true,
      default: () => ({
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      }),
    },
  },
  data() {
    return {
      emailConfigCopy: this.modelValue || {
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      },
      errorCode: "",
      testSuccess: true,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue: {
      handler(model: MailServer) {
        this.errorCode = "";
        this.testSuccess = false;
        this.updateModel(model);
      },
      deep: true,
    },
    emailConfigCopy: {
      handler(newValue: MailServer) {
        this.$emit(EmailServerConfigFormEmits.updateModelValue, newValue);
      },
      deep: true,
    },
  },
  methods: {
    handleEmailTest(result: string) {
      switch (result) {
        case "1":
          this.testSuccess = true;
          ToastService.show("Email Konfiguration erfolgreich getestet");
          this.$emit(EmailServerConfigFormEmits.testResult);
          break;
        case "535":
          this.errorCode = "Username/Passwort fehlerhaft";
          break;
        case "ssl":
          this.errorCode = "SSL Fehler";
          break;
        case "0":
          this.errorCode = "Nicht erfolgreich";
      }
    },
    updateModel(model: MailServer) {
      this.emailConfigCopy = model || {
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      };
    },
  },
});
</script>
