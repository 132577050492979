import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("fa-solid fa-palette")
            ])),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createTextVNode("Farbsystem für Status der Kanbankarten "))
        ]),
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCandidateStatusColor, (color, status) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    cols: "12",
                    md: "4",
                    key: status
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, { class: "mb-4 pa-4" }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getReadableName(status)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_color_picker, {
                            modelValue: _ctx.localConfig.candidateStatusColor[status],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.localConfig.candidateStatusColor[status]) = $event),
                            modes: ['hex'],
                            elevation: "0"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}