import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExtractCandidateUuidFromAtsRecruitLinkForm = _resolveComponent("ExtractCandidateUuidFromAtsRecruitLinkForm")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    "show-dialog": _ctx.showDialog,
    width: _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)',
    onCloseDialog: _ctx.closeDialog
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Gib deinen Link zum Kandidaten in ATS hier ein ")
    ])),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_ExtractCandidateUuidFromAtsRecruitLinkForm, { onExtractedUuid: _ctx.extractedUuid }, null, 8, ["onExtractedUuid"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show-dialog", "width", "onCloseDialog"]))
}