import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenuComponent = _resolveComponent("AdminMenuComponent")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenuComponent),
    (
      _ctx.appLayout !== _ctx.AppLayout.classic &&
      _ctx.appLayout !== _ctx.AppLayout.persontobusiness
    )
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: "ma-10",
          cols: "12",
          md: "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isUberAdmin ? "DEBUG Info" : "Aktuelle Softwareversion"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, "Alpha " + _toDisplayString(_ctx.getVersion()), 1),
                    (_ctx.isUberAdmin)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Environment: " + _toDisplayString(_ctx.getEnvironment()), 1))
                      : _createCommentVNode("", true),
                    (_ctx.isUberAdmin)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Backend URL: " + _toDisplayString(_ctx.getBackendURI()), 1))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_btn, {
                      class: "mt-10",
                      color: "primary",
                      variant: "outlined",
                      href: 'https://www.youtube.com/playlist?list=PLjAx46HKPM5txs1szztyT6uLyE__xMLVh',
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { class: "mr-1" }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode("fa-brands fa-youtube")
                          ])),
                          _: 1
                        }),
                        _cache[1] || (_cache[1] = _createTextVNode(" Changelog "))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}