<template>
  <v-btn variant="text" icon density="compact" class="mr-2">
    <v-icon size="small" @click="mainModal = true" class="mx-3 clickable">
      fa-solid fa-user
    </v-icon>
    <v-tooltip activator="parent" location="bottom"
      >User Profil ändern</v-tooltip
    >
  </v-btn>
  <BaseDialog
    :showDialog="mainModal"
    :width="'var(--d-w-sm)'"
    @closeDialog="mainModal = false"
  >
    <template #title> Profil bearbeiten </template>
    <template #content>
      <v-card-subtitle class="ml-2 mt-5 mb-1">
        <v-icon>fa-solid fa-user</v-icon> Allgemeine Daten
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row noGutters>
            <v-col cols="12" md="6">
              <v-text-field
                density="compact"
                v-bind="vStyle?.input"
                v-model="user.forename"
                label="Vorname"
                class="mr-2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="ml-2"
                density="compact"
                v-bind="vStyle?.input"
                v-model="user.lastname"
                label="Nachname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mr-2"
                density="compact"
                v-bind="vStyle?.input"
                v-model="user.tel"
                label="Telefonnummer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="ml-2"
                density="compact"
                v-bind="vStyle?.input"
                v-model="user.mobilePhone"
                label="Handynummer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mr-2"
                density="compact"
                v-bind="vStyle?.input"
                v-model="user.email"
                label="E-Mail"
                :rules="[rules.required, rules.email]"
                @update:model-value="resetError"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end align-top" cols="12" md="6">
              <OfficeIntBtn
                v-if="user?.config?.hasMailaddress"
                :isExistingAccessToken="checkExistingAccessToken()"
                :variant="'outlined'"
                :height="button.height"
                :width="button.width"
                @logout="office365Logout()"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider class="mt-5"></v-divider>
      </v-card-text>
      <v-card-subtitle class="ml-2">
        <v-icon>fa-solid fa-lock</v-icon> Passwort und Sicherheit
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <div>
            <v-btn
              v-bind="vStyle?.btn"
              color="primary"
              :width="button.width"
              :height="button.height"
              @click="showPasswordModal"
              @update:model-value="resetError"
              ><v-icon class="mr-1">fa-solid fa-key</v-icon>ändern</v-btn
            >
            <ErrorChip :label="requestError"></ErrorChip>
          </div>
          <div>
            <v-btn
              v-bind="vStyle?.btn"
              color="abort"
              :width="button.width"
              :height="button.height"
              @click="showPasswordForgottenModal"
              ><v-icon class="mr-1">fa-solid fa-arrow-rotate-left</v-icon
              >Zurücksetzen</v-btn
            >
          </div>
        </div>
        <v-divider class="mt-8"></v-divider>
      </v-card-text>
      <v-card-subtitle class="ml-2">
        <v-icon>fa-solid fa-gear</v-icon> Sonstiges
      </v-card-subtitle>
      <v-card-text>
        <v-divider v-if="softwareIntegration.pdHub" class="my-5"></v-divider>
        <v-switch
          v-if="user?.config?.hideTip?.onDispatcherBoard"
          color="primary"
          density="compact"
          v-model="user.config.hideTip.onDispatcherBoard"
          label="Spaltenüberschriften auf Dispoboard verbergen"
        ></v-switch>
        <v-switch
          v-model="themeLight"
          :label="`Theme: ${themeLight ? 'Light' : 'Dark'}`"
          color="primary"
          density="compact"
          hide-details
          @update:model-value="setTheme"
        ></v-switch>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="red-darken-3" @click="mainModal = false">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="green-darken-4" @click="save">Speichern</v-btn>
    </template>
  </BaseDialog>

  <PasswordDialog
    :showDialog="passwordModal"
    :requestError="passwordRequestError"
    @cancel="passwordModal = false"
    @changePassword="changePassword"
    @click:outside="handlePasswordBackgroundClick"
    @dirty="resetPasswordError"
  ></PasswordDialog>

  <PasswordForgottenDialog
    :showDialog="passwordForgottenModal"
    :requestError="passwordRequestError"
    @cancel="passwordForgottenModal = false"
    @sent="passwordForgottenModal = false"
    @click:outside="handlePasswordBackgroundClick"
  ></PasswordForgottenDialog>
</template>

<script lang="ts">
import { ApiResponse } from "@/models/api-response.model";
import { defineComponent, ref } from "vue";
import { formRules } from "@/helper/form-rules.helper";
import { mapGetters } from "vuex";
import { PasswordChange } from "@/models/password-change.model";
import { Salutation } from "@/enums/salutation.enum";
import { useDisplay, useTheme } from "vuetify";
import { User, UserConfig } from "@/models/user.model";
import { UserService } from "@/services/api/user.service";
import ErrorChip from "@/helper/ErrorChip.vue";
import PasswordDialog from "./dialogs/PasswordDialog.vue";
import PasswordForgottenDialog from "./dialogs/PasswordForgottenDialog.vue";
import OfficeIntBtn from "@/components/email/office-int/OfficeIntBtn.vue";
import { MutationType } from "../../../enums/vuex-types.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    BaseDialog,
    ErrorChip,
    OfficeIntBtn,
    PasswordDialog,
    PasswordForgottenDialog,
  },
  setup() {
    const theme = useTheme();
    const { smAndDown } = useDisplay();
    const themeLight = ref(theme.global.name.value === "light");

    const setTheme = () => {
      theme.global.name.value = themeLight.value ? "light" : "dark";
    };

    return {
      theme,
      smAndDown,
      setTheme,
      themeLight,
    };
  },
  data() {
    return {
      button: {
        width: 225,
        height: 40,
      },
      mainModal: false,
      passwordForgottenModal: false,
      passwordModal: false,
      passwordRequestError: "",
      passwordValid: false,
      requestError: "",
      rules: formRules,
      user: this.$store.state.company?.loggedInUser as User,
      userService: new UserService(),
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user",
      softwareIntegration: "softwareIntegration",
    }),
    salutations() {
      return Object.entries(Salutation)
        .filter(([, value]) => !isNaN(Number(value)))
        .map(([key, value]) => ({
          text: key,
          value: value as number,
        }));
    },
  },
  methods: {
    changePassword(passwordChange: PasswordChange) {
      if (passwordChange) {
        this.userService
          .changePassword(passwordChange)
          .then((response: ApiResponse) => {
            if (response.error === "Password object invalid") {
              this.passwordRequestError = "Falsches aktuelles Passwort";
            } else {
              this.passwordModal = false;
            }
          });
      }
    },
    checkExistingAccessToken() {
      return (
        this.loggedInUser?.config?.accessTokens?.office365?.accessToken ===
        "true"
      );
    },
    handlePasswordBackgroundClick() {
      this.passwordModal = false;
      this.passwordForgottenModal = false;
    },
    handleBackgroundClick() {
      this.mainModal = false;
    },
    office365Logout() {
      if (this.user.config.accessTokens?.office365?.accessToken) {
        const userConfig = this.$store.state.company.loggedInUser
          .config as UserConfig;
        if (userConfig.accessTokens && userConfig.accessTokens.office365)
          userConfig.accessTokens.office365.accessToken = "false";
        this.user.config.accessTokens.office365.accessToken = "false";
        this.$store.commit(MutationType.setUserConfig, userConfig);
        this.userService.updateModelStore();
      }
    },
    showPasswordForgottenModal() {
      this.passwordForgottenModal = true;
    },
    showPasswordModal() {
      this.passwordModal = true;
    },
    resetError() {
      this.requestError = "";
    },
    resetPasswordError() {
      this.passwordRequestError = "";
    },
    save() {
      if (this.valid) {
        this.userService
          .updateUserProfile({
            email: this.user.email,
            mobilePhone: this.user.mobilePhone,
            tel: this.user.tel,
          })
          .then((response: ApiResponse) => {
            if (!response.error) {
              this.mainModal = false;
            } else {
              this.requestError = response.error;
            }
          });
      } else {
        this.requestError = "Formular unvollständig";
      }
    },
  },
});
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.clickable:hover {
  transition: all 0.3s ease;
  scale: 1.2;
}
.name-title {
  margin: 0 1.5rem;
}

.title {
  margin: 8px;
}
</style>
