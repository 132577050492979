<template>
  <BaseDialog
    :showDialog="showDialog"
    :showActions="true"
    @closeDialog="closeModal"
    :width="'var(--d-w-xl)'"
  >
    <template #title>
      <div class="d-flex align-end">
        <img
          class="mr-2"
          :src="logoPayFlow"
          alt="PayFlow Logo"
          style="width: 4rem"
        />
        - {{ isEdit ? "bearbeiten" : "hinzufügen" }}
      </div>
    </template>
    <template #content>
      <v-card-text class="mb-5">
        <v-form ref="form">
          <v-row>
            <v-divider class="mb-5"></v-divider>
            <v-col cols="12" md="3">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.employeeNumber"
                label="Personalnummer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.firstName"
                label="Vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.lastName"
                label="Nachname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-bind="vStyle.input"
                v-model="localPayFlow.infoType"
                :items="payrollInfoTypeOptions"
                item-title="label"
                item-value="label"
                label="Art"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" v-if="isMiscellaneous">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.miscellaneous"
                label="Beschreibung"
              ></v-text-field>
            </v-col>
            <!-- Monetary Amount, only for specific info types -->
            <v-col cols="12" md="3" v-if="hasMonetaryAmount">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.monetaryAmount"
                label="Betrag"
                type="number"
              ></v-text-field>
            </v-col>
            <!-- Hours, only for specific info types -->
            <v-col cols="12" md="3" v-if="hasHours">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.hours"
                label="Stunden"
                type="number"
              ></v-text-field>
            </v-col>
            <!-- Date From and Date To, only for specific info types -->
            <v-col cols="12" md="3" v-if="hasDateRange">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.dateFrom"
                label="von Datum"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" v-if="hasDateRange">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.dateTo"
                label="bis Datum"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" v-if="!localPayFlow.monthly">
              <v-select
                v-bind="vStyle.input"
                :items="payrollMonths"
                v-model="localPayFlow.payrollMonth"
                label="Abrechnungsmonat"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-switch
                v-model="localPayFlow.monthly"
                color="primary"
                label="monatlich"
                variant="outlined"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.info"
                label="Info"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Bank Account Details, only for advance and partialPayment -->
          <v-row v-if="hasBankAccountData && localPayFlow.bankAccount">
            <v-col cols="12" md="5">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.bankAccount.iban"
                label="IBAN"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.bankAccount.bank.name"
                label="Bank Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.bankAccount.bank.bic"
                label="BIC"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle.input"
                v-model="localPayFlow.bankAccount.differentAccountHolder"
                label="Abweichender Kontoinhaber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="getBankDataFromErp(localPayFlow.employeeNumber)"
                icon
                variant="text"
                density="compact"
              >
                <v-icon> fa-solid fa-cloud-arrow-down </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeModal()">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save">{{
        isEdit ? "Speichern" : "Hinzufügen"
      }}</v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { PayFlow } from "@/models/payflow.model";
import { PayrollInfoType } from "@/enums/payflow.enum";
import { defineComponent, PropType } from "vue";
import { getEnumOptions } from "@/helper/enum.helper";
import { useDisplay } from "vuetify";
import moment from "moment";
import { ZvooveOneEmployee } from "@/services/api/api-integration-one.service";
import ToastService from "@/services/toast.service";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default defineComponent({
  name: "DialogAddEditPayFlow",
  components: {
    BaseDialog,
  },
  emits: ["addPayFlow", "updatePayFlow"],
  props: {
    payFlow: {
      type: Object as PropType<PayFlow>,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      showDialog: false,
      localPayFlow: { ...this.payFlow },
      logoPayFlow: `${this.$store.getters.getEnv.assetsUrl}/payflow-logo-light.svg`,
      payrollInfoTypeOptions: getEnumOptions(PayrollInfoType),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    hasBankAccountData() {
      return (
        this.localPayFlow.infoType === PayrollInfoType.advance ||
        this.localPayFlow.infoType === PayrollInfoType.partialPayment ||
        this.localPayFlow.infoType === PayrollInfoType.miscellaneous
      );
    },
    hasDateRange() {
      return [
        PayrollInfoType.unpaidVacation,
        PayrollInfoType.unpaidTrailWork,
        PayrollInfoType.noWageEntitlement,
        PayrollInfoType.miscellaneous,
      ].includes(this.localPayFlow.infoType);
    },
    hasMonetaryAmount() {
      return [
        PayrollInfoType.advance,
        PayrollInfoType.partialPayment,
        PayrollInfoType.contractualPenalty,
        PayrollInfoType.deductPPE,
        PayrollInfoType.reimbursePPE,
        PayrollInfoType.fuelVoucher,
        PayrollInfoType.goodsVoucher,
        PayrollInfoType.employeeReferralBonus,
        PayrollInfoType.miscellaneous,
      ].includes(this.localPayFlow.infoType);
    },
    hasHours() {
      return (
        this.localPayFlow.infoType === PayrollInfoType.payOvertime ||
        this.localPayFlow.infoType === PayrollInfoType.miscellaneous
      );
    },
    isMiscellaneous() {
      return this.localPayFlow.infoType === PayrollInfoType.miscellaneous;
    },
    payrollMonths(): string[] {
      const months = [];
      for (let i = 3; i > 0; i--) {
        months.push(moment().subtract(i, "months").format("MM/YYYY"));
      }
      months.push(moment().format("MM/YYYY"));
      months.push(moment().add(1, "months").format("MM/YYYY"));
      return months;
    },
  },
  watch: {
    payFlow(newValue: PayFlow) {
      this.localPayFlow = { ...newValue };
    },
    "localPayFlow.infoType"(newType) {
      this.switchMonthly(newType);
      this.updatePayFlowFields();
    },
    "localPayFlow.monthly"() {
      this.updatePayRollMonth();
    },
  },
  methods: {
    closeModal() {
      this.showDialog = false;
    },
    async getBankDataFromErp(employeeNumber: string) {
      if (this.$store.state.company.softwareIntegration.zvooveOne) {
        const zvooveOneEmployee = new ZvooveOneEmployee();
        const bankData = await zvooveOneEmployee.getBankData(employeeNumber);
        this.localPayFlow.bankAccount = bankData;
      } else {
        ToastService.showError(
          "kein passendes ERP System um Bankdaten zu holen"
        );
      }
    },
    openModal() {
      this.showDialog = true;
    },
    save() {
      if (this.isEdit) {
        this.$emit("updatePayFlow", this.localPayFlow);
      } else {
        this.$emit("addPayFlow", this.localPayFlow);
      }
    },
    switchMonthly(infoType: PayrollInfoType) {
      switch (infoType) {
        case PayrollInfoType.advance ||
          PayrollInfoType.contractualPenalty ||
          PayrollInfoType.unpaidVacation ||
          PayrollInfoType.unpaidVacation ||
          PayrollInfoType.unpaidTrailWork ||
          PayrollInfoType.noWageEntitlement ||
          PayrollInfoType.deductPPE ||
          PayrollInfoType.reimbursePPE ||
          PayrollInfoType.employeeReferralBonus:
          this.localPayFlow.monthly = false;
          break;
        case PayrollInfoType.partialPayment:
          this.localPayFlow.monthly = true;
          break;
        default:
          this.localPayFlow.monthly = false;
          break;
      }
    },

    updatePayFlowFields() {
      if (this.hasBankAccountData) {
        if (!this.localPayFlow.bankAccount) {
          this.localPayFlow.bankAccount = {
            differentAccountHolder: "",
            iban: "",
            bank: {
              bic: "",
              name: "",
            },
          };
        }
      } else {
        delete this.localPayFlow.bankAccount;
      }

      if (this.hasDateRange) {
        if (!this.localPayFlow.dateFrom || !this.localPayFlow.dateTo) {
          this.localPayFlow.dateFrom = "";
          this.localPayFlow.dateTo = "";
        }
      } else {
        delete this.localPayFlow.dateFrom;
        delete this.localPayFlow.dateTo;
      }

      if (this.hasMonetaryAmount) {
        if (!this.localPayFlow.monetaryAmount) {
          this.localPayFlow.monetaryAmount = "";
        }
      } else {
        delete this.localPayFlow.monetaryAmount;
      }

      if (this.hasHours) {
        if (!this.localPayFlow.hours) {
          this.localPayFlow.hours = "";
        }
      } else {
        delete this.localPayFlow.hours;
      }

      if (this.isMiscellaneous) {
        if (!this.localPayFlow.miscellaneous) {
          this.localPayFlow.miscellaneous = "";
        }
      } else {
        delete this.localPayFlow.miscellaneous;
      }
    },

    updatePayRollMonth() {
      if (!this.localPayFlow.monthly) {
        if (!this.localPayFlow.payrollMonth) {
          this.localPayFlow.payrollMonth = moment().format("MM/YYYY");
        }
      } else {
        this.localPayFlow.payrollMonth = "";
        delete this.localPayFlow.payrollMonth;
      }
    },
  },
});
</script>
