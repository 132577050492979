import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
    variant: _ctx.variant ?? undefined,
    width: _ctx.width ?? undefined,
    height: _ctx.height ?? undefined,
    onClick: _ctx.login
  }, _ctx.vStyle?.btn, { color: "primary" }), {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        class: "mr-2",
        icon: "fa-brands fa-windows"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.isExistingAccessToken ? "Email entfernen" : "Microsoft Office365"), 1)
    ]),
    _: 1
  }, 16, ["variant", "width", "height", "onClick"]))
}