export abstract class BaseStoreModel<T> {
  protected model!: T;

  // commit model to store
  abstract saveModelStore(model: T): void;

  // get model from backend
  abstract getModel(param?: any): Promise<T>;

  // get model from backend and commit to store
  async updateModelStore(param?: any) {
    const model = await this.getModel(param);
    this.saveModelStore(model);
  }
}
