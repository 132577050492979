import { Api } from "./api.class";

export class Office365Service {
  private api = Api.getInstance();
  private urlPath = "/office365";

  async office365Logout(emailScope: string, mandantUuid?: string) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/logout`, { emailScope, mandantUuid });
    return response.data;
  }
}
