import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.show,
    showActions: true,
    onCloseDialog: _ctx.handleAbort,
    width: 'var(--d-w-sm)'
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-4 mb-5 text-dialog" }, {
        default: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _ctx.question }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: _ctx.btnAbortColor,
        onClick: _ctx.handleAbort
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnAbortText), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"]),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        color: _ctx.btnConfirmColor,
        onClick: _ctx.handleConfirm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnConfirmText), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"])
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}