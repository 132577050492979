<template>
  <div class="video-container" @click="handleVideoClick">
    <video
      ref="welcomeVideo"
      :src="welcomeVideo"
      autoplay
      muted
      loop
      class="video"
    ></video>

    <div class="overlay">
      <h2 class="candidate-name">{{ candidateName }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "WelcomeVideo",
  props: {
    candidateName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      welcomeVideo: `${this.$store.getters.getEnv.assetsUrl}/video/application-form-welcome.mp4`,
    };
  },
  methods: {
    handleVideoClick() {
      this.$emit("videoClick");
    },
  },
});
</script>

<style scoped>
.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vh;
  height: 100vh;
  cursor: pointer;
  position: relative;
}

.video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 20%;
  width: 90%;
  text-align: center;
}

.candidate-name {
  color: var(--color-primary);
  font-size: 4rem;
  font-weight: bold;
}
</style>
