<template>
  <div class="main-header-menu">
    <router-link :to="Routes.dispostion" data-tid="header-link-dashboard">
      <img class="logo" :src="appLogo" alt="Logo" />
    </router-link>
    <div class="panels">
      <v-sheet
        @click="navigateTo(Routes.recruiting)"
        class="mainbutton recruiting"
        :color="hover.a ? 'secondary' : 'primary'"
        @mouseover="hover.a = true"
        @mouseleave="hover.a = false"
        data-tid="header-link-recruiting"
      >
        <i class="fa-solid fa-people-arrows panel-icon"></i>
        <p>Recruiting</p>
      </v-sheet>

      <v-sheet
        @click="navigateTo(Routes.dispostion)"
        class="mainbutton"
        :color="hover.b ? 'secondary' : 'primary'"
        @mouseover="hover.b = true"
        @mouseleave="hover.b = false"
        data-tid="header-link-disposition"
      >
        <i class="fa-solid fa-headset panel-icon"></i>
        <p>Disposition</p>
      </v-sheet>

      <v-sheet
        @click="navigateTo(Routes.administration)"
        class="mainbutton"
        :color="hover.c ? 'secondary' : 'primary'"
        @mouseover="hover.c = true"
        @mouseleave="hover.c = false"
      >
        <i class="fa-regular fa-folder-open panel-icon"></i>
        <p>Verwaltung</p>
      </v-sheet>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Routes } from "@/enums/routes.enum";

export default defineComponent({
  name: "MainHeaderMenu",
  data() {
    return {
      hover: {
        a: false,
        b: false,
        c: false,
      },
      appLogo: `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.$store.getters.appLayout}/header-logo.svg`,
      Routes,
    };
  },
  methods: {
    navigateTo(route: string) {
      this.$router.push(route);
    },
  },
});
</script>

<style scoped lang="scss">
@use "@/scss/variables.scss";
.main-header-menu {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.logo {
  margin-right: 2rem;
  margin-left: 1rem;
  transition: all 1s ease;
  width: 9rem;
  height: 9rem;
}
.panel-icon {
  font-size: 4rem;
}
.logo:hover {
  scale: var(--medium-scale-up);
}
.panels {
  height: 9rem;
  display: flex;
  justify-content: center;
  transition: all 1s ease;
}

.panels:hover {
  scale: var(--xs-scale-up);
}

.mainbutton {
  align-items: center;
  width: 8rem;
  border-radius: 8px;
  border: 2px solid var(--color-border-light);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  padding: 0.1rem 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.mainbutton:hover {
  transform: scale(1.1);
  background-color: var(--color-secondary);
  border: 2px solid var(--color-on-secondary);
}
@media (max-width: variables.$breakpointS) {
  .logo {
    display: none;
  }

  .panel-icon {
    font-size: 1.5rem;
  }

  .panels {
    flex-direction: column;
  }

  .mainbutton {
    width: 3rem;
    height: 2.5rem;
    padding: 0.2rem;
    font-size: 0.8rem;
  }

  .mainbutton p {
    display: none;
  }
}
</style>
