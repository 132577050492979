import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  style: {"border":"0.2rem dashed var(--color-primary-darken-1)","border-radius":"var(--border-radius-dialog)"},
  class: "pa-4 d-flex justify-center align-center h-100"
}
const _hoisted_2 = {
  class: "d-flex flex-column justify-center align-center text-center",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "text-h5 mb-5 field-text-title" }
const _hoisted_4 = { class: "text-h6 mb-5 field-text-subtitle" }
const _hoisted_5 = { class: "text-body-1 field-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_ctx.showDropField)
    ? (_openBlock(), _createBlock(_component_v_sheet, {
        key: 0,
        color: "surface-bright",
        rounded: "lg",
        class: "drop-field my-15",
        onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        onDragenter: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        onDragleave: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
        onDrop: _withModifiers(_ctx.handleDrop, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.fieldText.icon)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "mb-5 field-text-icon",
                    size: "3rem"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.fieldText.icon), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.fieldText.title), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.fieldText.subtitle), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.fieldText.text), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onDrop"]))
    : _createCommentVNode("", true)
}