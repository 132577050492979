import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_SearchEmployee = _resolveComponent("SearchEmployee")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "mt-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "mb-4" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Import")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_FileUpload, { onAddWorkTime: _ctx.addWorktime }, null, 8, ["onAddWorkTime"]),
                  _createVNode(_component_v_card_subtitle, { class: "my-4" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Stundenzuordnung im ERP")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                    "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                    "onClick:prependInner": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchEmployeeMenuOpen())),
                    modelValue: _ctx.transponderNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transponderNumber) = $event)),
                    label: "Mitarbeiter Transpondernummer",
                    clearable: "",
                    density: "compact"
                  }), null, 16, ["modelValue"]),
                  _createVNode(_component_SearchEmployee, {
                    ref: "searchEmployeeComponent",
                    onEmployeeSelected: _ctx.setTransponderNummer
                  }, null, 8, ["onEmployeeSelected"]),
                  _createVNode(_component_v_card_subtitle, { class: "mb-4" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Nachzuschlag")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                      label: "Zuschlagszeit von",
                      modelValue: _ctx.nightShiftBonus.startTime,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nightShiftBonus.startTime) = $event)),
                      type: "time",
                      class: "mr-1",
                      clearable: "",
                      density: "compact"
                    }), null, 16, ["modelValue"]),
                    _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                      label: "Zuschlagszeit bis",
                      modelValue: _ctx.nightShiftBonus.endTime,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nightShiftBonus.endTime) = $event)),
                      type: "time",
                      class: "ml-1",
                      clearable: "",
                      density: "compact"
                    }), null, 16, ["modelValue"])
                  ]),
                  _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
                    disabled: !_ctx.worktimesExportable,
                    block: "",
                    onClick: _ctx.exportWorktimes,
                    color: "primary"
                  }), {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Buchungsdaten exportieren ")
                    ])),
                    _: 1
                  }, 16, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      }),
      _createVNode(_component_v_col, {
        cols: "12",
        md: "8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Abrechnungsmonat " + _toDisplayString(_ctx.payrollMonth.format("MM-YYYY")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.worktime, (workday, index) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "mt-1" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "2" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formateDate(workday.date)), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, {
                                  cols: "auto",
                                  class: "d-flex"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                      class: "mr-2",
                                      modelValue: workday.startTime,
                                      "onUpdate:modelValue": ($event: any) => ((workday.startTime) = $event),
                                      label: "Startzeit",
                                      density: "compact",
                                      type: "time",
                                      clearable: ""
                                    }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                      class: "mr-2",
                                      modelValue: workday.endTime,
                                      "onUpdate:modelValue": ($event: any) => ((workday.endTime) = $event),
                                      label: "Endzeit",
                                      density: "compact",
                                      type: "time",
                                      clearable: ""
                                    }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_v_btn, { class: "ml-2" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { class: "mr-1" }, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("fas fa-mug-hot")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.calculateTimes(workday).totalPauseMinutes) + " ", 1),
                                        _createVNode(_component_v_menu, {
                                          activator: "parent",
                                          "close-on-content-click": false
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card), {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_title, null, {
                                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                    _createTextVNode("Pausenzeiten")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_card_text, { class: "d-flex" }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workday.breaks, (breakTime, breakIndex) => {
                                                      return (_openBlock(), _createElementBlock("div", { key: breakIndex }, [
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                                          modelValue: breakTime.breakStart,
                                                          "onUpdate:modelValue": ($event: any) => ((breakTime.breakStart) = $event),
                                                          label: breakIndex + 1 + '. Pause',
                                                          class: "mr-2",
                                                          density: "compact",
                                                          type: "time",
                                                          clearable: ""
                                                        }), null, 16, ["modelValue", "onUpdate:modelValue", "label"]),
                                                        _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                                          modelValue: breakTime.breakEnd,
                                                          "onUpdate:modelValue": ($event: any) => ((breakTime.breakEnd) = $event),
                                                          label: "Ende",
                                                          class: "mr-2",
                                                          density: "compact",
                                                          type: "time",
                                                          clearable: ""
                                                        }), null, 16, ["modelValue", "onUpdate:modelValue"])
                                                      ]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1040)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { cols: "2" }, {
                                  default: _withCtx(() => [
                                    (_ctx.calculateTimes(workday).totalWorkMinutes !== 'NaN')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                          _createElementVNode("p", null, [
                                            _createVNode(_component_v_icon, {
                                              size: "xsmall",
                                              class: "mr-1"
                                            }, {
                                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                _createTextVNode("fa-regular fa-clock")
                                              ])),
                                              _: 1
                                            }),
                                            _createTextVNode(_toDisplayString(_ctx.calculateTimes(workday).totalWorkMinutes) + " ", 1),
                                            _createVNode(_component_v_icon, { size: "xsmall" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.compareTimes(
                          _ctx.calculateTimes(workday).totalWorkMinutes,
                          workday.totalWorkTime
                        )), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createTextVNode(" " + _toDisplayString(workday.totalWorkTime), 1)
                                          ]),
                                          (
                        parseFloat(_ctx.calculateTimes(workday).nightBonusHours) > 0
                      )
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                                                _createVNode(_component_v_icon, {
                                                  size: "xsmall",
                                                  class: "mr-1"
                                                }, {
                                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                    _createTextVNode("fa-solid fa-moon>")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.calculateTimes(workday).nightBonusHours) + " ", 1),
                                                _createVNode(_component_v_icon, { size: "xsmall" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.compareTimes(
                          _ctx.calculateTimes(workday).nightBonusHours,
                          workday.nightShiftBonus
                        )), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createTextVNode(" " + _toDisplayString(workday.nightShiftBonus), 1)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, { cols: "2" }, {
                                  default: _withCtx(() => [
                                    (workday.sundayShiftBonus)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                                          _createVNode(_component_v_icon, {
                                            size: "xsmall",
                                            class: "mr-1"
                                          }, {
                                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                                              _createTextVNode("fa-solid fa-sun")
                                            ])),
                                            _: 1
                                          }),
                                          _createTextVNode(" " + _toDisplayString(workday.sundayShiftBonus), 1)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (workday.holidayShiftBonus)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                                          _createVNode(_component_v_icon, {
                                            size: "xsmall",
                                            class: "mr-1"
                                          }, {
                                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                                              _createTextVNode("fa-solid fa-gifts")
                                            ])),
                                            _: 1
                                          }),
                                          _createTextVNode(" " + _toDisplayString(workday.holidayShiftBonus), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}