import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_EmailTestButton = _resolveComponent("EmailTestButton")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                label: "Server",
                modelValue: _ctx.emailConfigCopy.server,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailConfigCopy.server) = $event)),
                "hide-details": "true",
                density: "compact",
                class: "ma-1"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                label: "Port",
                modelValue: _ctx.emailConfigCopy.port,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailConfigCopy.port) = $event)),
                "hide-details": "true",
                type: "number",
                density: "compact",
                class: "ma-1"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                label: "E-Mail Adresse",
                modelValue: _ctx.emailConfigCopy.from,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailConfigCopy.from) = $event)),
                "hide-details": "true",
                density: "compact",
                class: "ma-1"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "3",
            class: "display-flex justify-end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, _mergeProps(_ctx.vStyle.input, {
                label: "SSL",
                modelValue: _ctx.emailConfigCopy.secure,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.emailConfigCopy.secure) = $event)),
                "hide-details": "true",
                density: "compact",
                class: "ma-1"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                label: "Username",
                modelValue: _ctx.emailConfigCopy.username,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailConfigCopy.username) = $event)),
                "hide-details": "true",
                density: "compact",
                class: "ma-1"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                label: "Passwort",
                modelValue: _ctx.emailConfigCopy.password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.emailConfigCopy.password) = $event)),
                "hide-details": "true",
                density: "compact",
                class: "ma-1",
                type: "password"
              }), null, 16, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          style: _normalizeStyle({
          visibility: _ctx.errorCode ? 'visible' : 'hidden',
          opacity: _ctx.errorCode ? 1 : 0,
          color: '#f00',
        })
        }, _toDisplayString(_ctx.errorCode), 5),
        (!_ctx.testSuccess)
          ? (_openBlock(), _createBlock(_component_EmailTestButton, {
              key: 0,
              modelValue: _ctx.modelValue,
              onTestConfig: _ctx.handleEmailTest
            }, null, 8, ["modelValue", "onTestConfig"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}