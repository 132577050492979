<template>
  <BaseDialog
    :showDialog="showDialog"
    :width="'var(--d-w-l)'"
    @closeDialog="closeDialog()"
    :showActions="true"
  >
    <template #title>
      {{ editMode ? "Niederlassung bearbeiten" : "Neue Niederlassung anlegen" }}
    </template>
    <template #content>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-row noGutters>
            <v-col cols="12" md="6" class="d-flex">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.uuid"
                label="Uuid"
                :disabled="editMode"
                class="ma-1"
                required
              >
                <template
                  #append-inner
                  v-if="isShowMandantImportFromAtsRecruitButton()"
                >
                  <v-icon
                    class="append-icon"
                    data-activator="append-inner"
                    @click="getAtsMandants()"
                  >
                    fa-solid fa-magnifying-glass
                  </v-icon>
                </template>
              </v-text-field>

              <v-tooltip
                v-if="isShowMandantImportFromAtsRecruitButton()"
                activator="[data-activator='append-inner']"
                location="bottom"
              >
                Mandanten aus ATS Recruit abrufen
              </v-tooltip>

              <v-menu
                v-if="isShowMandantImportFromAtsRecruitButton()"
                activator="[data-activator='append-inner']"
              >
                <v-list>
                  <v-list-item
                    v-for="(atsMandant, index) in atsRecruitMandants"
                    :key="index"
                    :value="index"
                  >
                    <v-list-item-title @click="selectAtsMandant(atsMandant)">
                      {{ atsMandant.Bezeichnung }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.name"
                :rules="[rules.required]"
                label="Name"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="softwareIntegration.pdHub ? '2' : '3'">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.branchNumber"
                label="NL Nr."
                class="ma-1"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="softwareIntegration.pdHub ? '2' : '3'">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.branchInitials"
                label="Initialen"
                class="ma-1"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="softwareIntegration.pdHub" cols="12" md="2">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.mandantL1"
                label="Mandant L1"
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.whatsApp"
                :rules="[rules.phoneNumber]"
                label="WhatsApp"
                class="ma-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-subtitle
            class="my-3"
            v-if="$store.state.company.softwareIntegration.zvooveRecruit"
            >Konfiguration ATS Recruit:</v-card-subtitle
          >
          <v-row
            noGutters
            v-if="$store.state.company.softwareIntegration.zvooveRecruit"
          >
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.zvoovename"
                :rules="[rules.required]"
                label="Name Mandant im ATS"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-bind="vStyle.input"
                v-model="newMandant.EmailEingangskontoId"
                label="EmailEingangskontoId"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <v-textarea
                v-bind="vStyle.input"
                v-model="newMandant.contact"
                label="Kontakt (Html)"
                rows="8"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="7" style="padding: 0">
              <v-card-subtitle class="ml-0 mt-1 mb-2"
                >Email Konfiguration</v-card-subtitle
              >
              <EmailConfiguration
                v-if="editMode"
                :office365AccessToken="isExistingAccessToken"
                :mailServerConfig="newMandant.mailServer"
                :office365Config="newMandant.office365Config"
                :uuid="editMandantModel?.uuid"
                :emailScope="EmailScope.mandant"
                @emailValidationResult="emailValidationResult"
                @office365Logout="office365Logout"
                @removeMailserverConfig="removeMailserverConfig"
              >
              </EmailConfiguration>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-card-subtitle
                >zugeordnete Postleitzahlen für Bewerbungszuordnung von
                Landingpage</v-card-subtitle
              >
              <v-chip-group column>
                <v-chip
                  v-for="(postcode, index) in newMandant.postalcodes"
                  color="primary"
                  :key="index"
                  close
                  @click:close="removePostcode(index)"
                  @click="openEditPostcodeModal(index)"
                >
                  {{ postcode }}
                  <v-icon
                    small
                    @click.stop="removePostcode(index)"
                    class="ml-2"
                  >
                    fa-solid fa-times
                  </v-icon>
                </v-chip>
                <v-icon
                  @click="openAddPostcodeModal"
                  color="primary"
                  class="mt-2"
                >
                  fa-solid fa-plus
                </v-icon>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </template>
    <template #actions>
      <div v-if="saveError">{{ saveError }}</div>
      <v-btn @click="closeDialog" color="abort">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        type="submit"
        color="success"
        :disabled="!isFormValid"
        @click="saveMandant(false)"
        >Speichern</v-btn
      >
    </template>
  </BaseDialog>
  <!-- Modal for websearch-result-->
  <BaseDialog
    :showDialog="showWebSearchResultDialog"
    :width="'var(--d-w-m)'"
    @close-dialog="showWebSearchResultDialog = false"
  >
    <template #title> Ergebnisse auswählen </template>

    <template #content>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(result, index) in webSearchResults"
            :key="index"
            @click="selectWebSearchResult(result)"
          >
            <div>
              <v-list-item-title>
                {{ result.generalData.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ result.addressAndCommunication.street }},
                {{ result.addressAndCommunication.postalCode }},
                {{ result.addressAndCommunication.city }}
              </v-list-item-subtitle>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="abort" @click="showWebSearchResultDialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </template>
  </BaseDialog>
  <!-- Modal for Adding/Editing Postcode -->
  <BaseDialog
    :showDialog="showPostcodeModal"
    :width="'var(--d-w-s)'"
    @close-dialog="closePostcodeModal"
  >
    <template #title>
      {{
        editPostcodeIndex !== null
          ? "Postleitzahl bearbeiten"
          : "Neue Postleitzahl hinzufügen"
      }}
    </template>

    <template #content>
      <v-card-text>
        <v-form ref="postcodeForm">
          <v-text-field
            v-bind="vStyle.input"
            v-model="postcodeInput"
            :rules="[rules.postalCode]"
            label="Postleitzahl"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="editPostcodeIndex !== null"
          @click="closePostcodeModal"
          color="abort"
        >
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="savePostcode" color="success"> Speichern </v-btn>
      </v-card-actions>

      <v-container v-if="editPostcodeIndex === null">
        <v-divider></v-divider>
        <v-card-title>oder Umkreissuche</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-bind="vStyle.input"
                v-model="postcodeInputCenter"
                :rules="[rules.postalCode]"
                label="zentrale Postleitzahl"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-bind="vStyle.input"
                v-model="zipcodeRadius"
                label="Radius in km"
                :rules="rules.radiusRules"
                required
              ></v-text-field>
              <v-label>{{ zipcodeRadius }} kilometer</v-label>
            </v-col>
          </v-row>
          <v-slider
            v-model="zipcodeRadius"
            :max="zipcodeMax"
            :min="zipcodeMin"
            :step="1"
            thumb-label
            class="align-center"
            hide-details
          ></v-slider>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closePostcodeModal" color="abort"> Abbrechen </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="searchPostcodes" color="primary"> Suchen </v-btn>
        </v-card-actions>
      </v-container>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { AiService } from "../../../services/ai.service";
import { AtsRecruitService } from "../../../services/api/api-integration-one.service";
import { CompanyWebSearchService } from "../../../services/api/company-web-search.service";
import { Customer } from "../../../models/customer.model";
import { defineComponent, PropType } from "vue";
import { formRules } from "@/helper/form-rules.helper";
import { MailServer } from "../../../models/mailserver.model";
import { Mandant } from "@/models/mandant.model";
import { MandantService } from "@/services/api/mandant.service";
import { mapGetters } from "vuex";
import { NextLevelMandant } from "../../../models/external/zvoove-mandants.model";
import { SpinnerService } from "../../../services/spinner.service";
import { useDisplay } from "vuetify";
import { ZipCodeService } from "@/services/api/zip-code.service";
import DialogService from "@/services/dialog.service";
import ToastService from "../../../services/toast.service";
import { EmailScope } from "../../../enums/email-scope.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { MutationType } from "../../../enums/vuex-types.enum";
import { VuetifyColor } from "../../../plugins/vuetify";
import EmailConfiguration from "../../email/email-configuration/EmailConfiguration.vue";

export default defineComponent({
  name: "MandantDialog",
  emits: ["closeDialog", "error"],
  components: {
    EmailConfiguration,
    BaseDialog,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editMandantModel: {
      type: Object as () => Mandant | null,
      default: null,
    },
    allMandants: {
      type: Object as PropType<Mandant[]>,
      default: () => [] as Mandant[],
    },
  },
  computed: {
    ...mapGetters(["getEnv", "softwareIntegration"]),
    isExistingAccessToken() {
      const token =
        this.editMandantModel?.accessTokens?.office365?.accessToken === "true";
      return token || false;
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      atsRecruitMandants: [] as NextLevelMandant[],
      editPostcodeIndex: null as number | null,
      EmailScope,
      form: null as any,
      isFormValid: false,
      mandantService: new MandantService(),
      newMandant: this.editMandantModel ?? this.initEmptyMandant(),
      postcodeInput: "",
      postcodeInputCenter: "",
      rules: formRules,
      saveError: "",
      showPostcodeModal: false,
      showWebSearchResultDialog: false,
      smAndDown,
      vStyle: this.$store.state.vStyle,
      webSearchResults: [] as Customer[],
      zipcodeMax: 50,
      zipcodeMin: 0,
      zipcodeRadius: 15,
      zipCodeService: new ZipCodeService(),
    };
  },
  watch: {
    editMandantModel(newVal) {
      if (newVal && this.editMode) {
        this.newMandant = { ...newVal };
      }
      if (newVal && !this.editMode) {
        this.clearMandant();
      }
      if (!newVal) {
        this.clearMandant();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    closePostcodeModal() {
      this.showPostcodeModal = false;
    },
    emailValidationResult(mailServerConfig: MailServer) {
      this.newMandant.mailServer = mailServerConfig;
    },
    async getAtsMandants() {
      try {
        SpinnerService.showSpinner();
        const atsRecruitService = new AtsRecruitService();
        const response = await atsRecruitService.getMandants();
        this.atsRecruitMandants = response;
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    office365Logout() {
      if (this.isExistingAccessToken) {
        this.isExistingAccessToken = false;
        //this.$store.commit(MutationType.setMandants, this.localConfig);
        this.mandantService.updateModelStore(this.editMandantModel?.uuid);
      }
    },
    openAddPostcodeModal() {
      this.postcodeInput = "";
      this.editPostcodeIndex = null;
      this.showPostcodeModal = true;
    },
    openEditPostcodeModal(index: number) {
      this.postcodeInput = this.newMandant.postalcodes[index];
      this.editPostcodeIndex = index;
      this.showPostcodeModal = true;
    },
    removeMailserverConfig() {
      this.newMandant.mailServer = null;
      this.saveMandant(true);
    },
    savePostcode() {
      if ((this.$refs.postcodeForm as any).validate()) {
        const assignedPostcodes: { name: string; postcodes: string[] }[] = [];

        if (this.allMandants) {
          const isAssigned = this.listExistingPostcodesToMandant(
            this.allMandants,
            this.postcodeInput,
            assignedPostcodes
          );

          if (isAssigned) {
            this.showAlertForExistingPostcodes(assignedPostcodes);
          } else {
            if (this.editPostcodeIndex !== null) {
              this.newMandant.postalcodes[this.editPostcodeIndex] =
                this.postcodeInput;
            } else {
              this.newMandant.postalcodes.push(this.postcodeInput);
            }
            this.showPostcodeModal = false;
          }
        }
      }
    },
    async searchPostcodes() {
      const isValidPostcode =
        this.rules.postalCode(this.postcodeInputCenter) === true;

      if (!isValidPostcode) {
        DialogService.alert(
          "Die zentrale Postleitzahl muss eine Zahl mit 4 bis 5 Stellen sein."
        );
        return;
      }
      const postcodes = await this.zipCodeService.getZipcodesWithinRadius(
        this.postcodeInputCenter,
        this.zipcodeRadius
      );

      if (postcodes.length > 0 && this.allMandants) {
        const newPostcodes: string[] = [];
        const assignedPostcodes: { name: string; postcodes: string[] }[] = [];

        postcodes.forEach((postcode: any) => {
          if (this.allMandants) {
            const isAssigned = this.listExistingPostcodesToMandant(
              this.allMandants,
              postcode,
              assignedPostcodes
            );
            if (!isAssigned) {
              newPostcodes.push(postcode);
            }
          }
        });

        this.showAlertForExistingPostcodes(assignedPostcodes);
        this.newMandant.postalcodes = [
          ...this.newMandant.postalcodes,
          ...newPostcodes,
        ];
        this.showPostcodeModal = false;
      }
    },
    checkExistingPostcodes(
      mandants: Mandant[],
      postcode: string
    ): { name: string; postcodes: string[] } | null {
      for (const mandant of mandants) {
        if (mandant.postalcodes && mandant.postalcodes.includes(postcode)) {
          return {
            name: mandant.name,
            postcodes: [postcode],
          };
        }
      }
      return null;
    },
    clearMandant() {
      this.newMandant = this.initEmptyMandant();
    },
    initEmptyMandant() {
      return {
        branchInitials: "",
        branchNumber: "",
        contact: "",
        EmailEingangskontoId: "",
        emailSenderAddress: "",
        mailServer: {} as MailServer,
        name: "",
        postalcodes: [],
        uuid: "",
        whatsApp: "",
        zvoovename: "",
      } as Mandant;
    },
    isShowMandantImportFromAtsRecruitButton() {
      return !this.editMode && this.softwareIntegration.zvooveRecruit;
    },
    listExistingPostcodesToMandant(
      mandants: Mandant[],
      postcode: string,
      assignedPostcodes: { name: string; postcodes: string[] }[]
    ): boolean {
      let isAssigned = false;
      mandants.forEach((mandant) => {
        if (mandant.postalcodes && mandant.postalcodes.includes(postcode)) {
          isAssigned = true;
          const existingMandant = assignedPostcodes.find(
            (item) => item.name === mandant.name
          );
          if (existingMandant) {
            existingMandant.postcodes.push(postcode);
          } else {
            assignedPostcodes.push({
              name: mandant.name,
              postcodes: [postcode],
            });
          }
        }
      });
      return isAssigned;
    },
    async selectAtsMandant(atsMandant: NextLevelMandant) {
      this.newMandant.uuid = atsMandant.ObjectUuid;
      this.newMandant.zvoovename = atsMandant.Bezeichnung;
      const confirmed = await DialogService.confirm(
        `Jetzt nach '${atsMandant.Bezeichnung}' im Internet suchen?`,
        "Abbrechen",
        "Suchen",
        "Niederlassung in Suchmaschine suchen",
        VuetifyColor.primary,
        VuetifyColor.success
      );

      if (confirmed) {
        const companyWebService = new CompanyWebSearchService();
        const companyEntries = (await companyWebService.companyWebSearch(
          atsMandant.Bezeichnung,
          true
        )) as Customer[];
        if (companyEntries.length > 0) {
          this.webSearchResults = companyEntries;
          this.showWebSearchResultDialog = true;
        } else {
          ToastService.showError(
            `Keine Einträge für '${atsMandant.Bezeichnung}' gefunden!`
          );
        }
      }
    },
    async selectWebSearchResult(result: Customer) {
      this.showWebSearchResultDialog = false;
      try {
        const aiService = new AiService();
        SpinnerService.showSpinner();
        ToastService.showReminder("Formular wird mit AI vorausgefüllt");
        const mandantFormData = await aiService.generateMandantFormContent(
          result
        );
        if (mandantFormData?.name) {
          this.newMandant.name = mandantFormData.name ?? "";
          this.newMandant.contact = mandantFormData.contact ?? "";
          this.newMandant.branchInitials = mandantFormData.branchInitials ?? "";
          this.newMandant.whatsApp = mandantFormData.whatsApp ?? "";
        } else {
          this.newMandant.name = result.addressAndCommunication.city ?? "";
          const resultName = result.generalData.name.slice(0, 24);
          this.newMandant.contact = `${resultName}<br>${result.addressAndCommunication.street}<br>${result.addressAndCommunication.postalCode} ${result.addressAndCommunication.city}<br>Tel.${result.addressAndCommunication.phone1}`;
        }
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
      const confirmed = await DialogService.confirm(
        "Sollen wir gleich die Postleitzahlen zuordnen, um später die Bewerbungen den Niederlassungen zuordnen zu können?",
        "Nein",
        "Ja",
        "Postleitzahlen zuordnen",
        VuetifyColor.primary,
        VuetifyColor.success
      );
      if (confirmed) {
        this.postcodeInputCenter =
          result.addressAndCommunication.postalCode ?? "";
        this.showPostcodeModal = true;
      }
    },
    showAlertForExistingPostcodes(
      assignedPostcodes: { name: string; postcodes: string[] }[]
    ) {
      if (assignedPostcodes.length > 0) {
        const alertMessage = assignedPostcodes
          .map((mandant) => `${mandant.name}: ${mandant.postcodes.join(", ")}`)
          .join("! ");
        DialogService.alert(
          `Bereits vorhandene Postleitzahlen: ${alertMessage}!`
        );
      }
    },
    async saveMandant(withoutClose?: boolean) {
      this.saveError = "";
      if (this.$refs.form && (this.$refs.form as any).validate()) {
        try {
          let mandants = this.allMandants;
          let response;
          if (!this.editMode)
            response = await this.mandantService.addMandant(this.newMandant);
          else
            response = await this.mandantService.editMandant(this.newMandant);
          if (response?.mandants) {
            mandants = response.mandants;
          }
          this.$store.commit(MutationType.setMandants, mandants);
          ToastService.showSuccess("Mandant gespeichert");

          if (!withoutClose) {
            this.$emit("closeDialog");
            (this.$refs.form as any).reset();
          }
        } catch (error: any) {
          switch (error?.response?.status) {
            case 401:
              this.saveError =
                "Authentifizierungsfehler. Bitte loggen Sie sich neu ein.";
              break;
            default:
              // TODO: Error handling GIT#477
              ToastService.showError("Fehler beim Speichern des Mandanten.");
          }
        }
      }
    },
    removePostcode(index: number) {
      if (this.newMandant?.postalcodes?.length > 1) {
        this.newMandant.postalcodes.splice(index, 1);
      } else {
        this.$emit("error", "Mindestens eine Postleitzahl ist erforderlich.");
      }
    },
  },
});
</script>

<style scoped>
.minus-icon {
  margin-top: -1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
