// src/services/jobPoster.service.ts
import { AtsRecruitService } from "./api/api-integration-one.service";
import { JobposterState } from "@/enums/jobposterstate.enum";
import { Mandant } from "@/models/mandant.model";
import { ZorstService } from "./zorst.service";
import DialogService from "@/services/dialog.service";
import store from "../store/store";
import ToastService from "./toast.service";
import { JobAdService } from "./api/job-ad.service";
import { jobAdType, JobLocation, PostingStatus } from "@/models/job-ad.model";
import { AiCluster } from "./api/ai-cluster.service";
import { AiCore, AiModel } from "../enums/ai-options.enum";
import { AiRequest } from "../models/ai-specs.model";
import { MutationType } from "../enums/vuex-types.enum";
import { TimelineHelperService } from "./timeline-helper.service";
import { LinkingStatus } from "../enums/dependency.enum";
import { TemplateJobAd } from "../models/company-config.model";
import { RecurionSpinnerService } from "./recurion-spinner.service";

export class JobPosterService {
  private atsRecruitService = new AtsRecruitService();
  private collectiveAgreement = "GVP"; // TODO: in company config
  private templateData = ""; // later from the backend
  private weAre = ""; // later from the backend
  private mandants: Mandant[] = [];
  private zvooveRecruit = false;
  private jobAdService = new JobAdService();
  private locations = [] as JobLocation[];

  constructor() {
    this.updateStoreVariables();
  }

  private getLocationById(id: string) {
    const location = this.locations.find(
      (location: JobLocation) => location._id === id
    );
    return location || null;
  }

  private updateStoreVariables() {
    this.templateData = JSON.stringify(
      store.state.company.aiData.template.jobAd,
      null,
      2
    ); // later from the backend
    this.weAre = store.state.company.aiData.prompt.company.weAre; // later from the backend
    this.mandants = store.state.company.mandants;
    this.zvooveRecruit = store.state.company.softwareIntegration.zvooveRecruit;
  }

  private getSalaryRange(
    salaryFrom: number | null,
    salaryTo: number | null,
    salaryPeriod: string
  ): string {
    let salaryRange = " ";

    if ((salaryFrom && salaryFrom > 0) || (salaryTo && salaryTo > 0)) {
      salaryRange = `${salaryFrom} bis ${salaryTo} ${salaryPeriod}`;
    }

    return salaryRange;
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async generateJobAd(jobAd: {
    jobTitle: string;
    postalCode: number | null;
    city: string;
    tasks: string;
    profile: string;
    salaryFrom: number | null;
    salaryTo: number | null;
    salaryPeriod: string;
    mandantUuid: string;
  }) {
    RecurionSpinnerService.setSpinner();

    jobAd.tasks = jobAd.tasks
      ? `, die Aufgaben der Stelle sind: ${jobAd.tasks}. Wenn die Aufgaben weniger als vier sind, ergänze bitte 4 typische Aufgaben, die zur Stellenbezeichnung des ${jobAd.jobTitle} passen.`
      : "";
    jobAd.profile = jobAd.profile
      ? `, die Anforderungen der Stelle sind: ${jobAd.profile}. Wenn die Anforderungen weniger als vier sind, ergänze bitte 4 typische Anforderungen, die zur Stellenbezeichnung des ${jobAd.jobTitle} passen.`
      : "";

    const request = {
      systemPrompt:
        "Du bist eine KI die Stellenanzeigen für ein ATS System generiert!",
      userPrompt: `
      ${this.weAre}
      Bitte erstelle eine Stellenanzeige für die Position eines ${jobAd.jobTitle} in ${jobAd.postalCode} ${jobAd.city}${jobAd.tasks}${jobAd.profile} basierend auf der folgender Vorlage:
      ${this.templateData}
      Bitte passe jeden in der Vorlage vorhandenen Textabschnitt so an, dass der Sinn erhalten bleibt, attraktiv für Bewerber und suchmaschinenoptimiert ist. Passe auch den Titel '${jobAd.jobTitle}' so an, dass er kurz und attraktiv ist, gut klingt und (m/w/d) enthält! Der Titel der Stellenanzeige soll nur den Beruf enthalten und keine Angaben über Ort, Abteilung oder sonstiges!

      Gib deine Antwort exakt in folgenden JSON-Format aus, damit ein Programm die verarbeiten kann.
      Formatvorlage für Deine Antwort:
      {
        "jobTitle": "DEIN TEXT",
        "tasksHeader": "DEIN TEXT",
        "tasks": "DEIN TEXT",
        "technicalRequirementsHeader": "DEIN TEXT",
        "technicalRequirements": "DEIN TEXT",
        "personalRequirementsHeader": "DEIN TEXT",
        "personalRequirements": "DEIN TEXT",
        "employerBenefitsHeader": "DEIN TEXT",
        "employerBenefits": "DEIN TEXT",
        "perspectivesHeader": "DEIN TEXT",
        "perspectives": "DEIN TEXT",
        "jobObjectiveHeader": "DEIN TEXT",
        "jobObjective": "DEIN TEXT",
        "metaDescription": "für die Metadescription der HTML-Stellenanzeige",
        "metaKeywords": "für die Metakeys der HTML-Stellenanzeige",
      }
    `,
      aiCore: AiCore.perform,
      aiModel: AiModel.mini,
      temperature: 0.7,
    } as AiRequest;
    try {
      const aiCluster = new AiCluster();
      const response = await aiCluster.neuralRequest(request);
      const generatedText = response.data.response;

      const startIndex = generatedText.indexOf("{");
      const endIndex = generatedText.lastIndexOf("}") + 1;
      const jsonPart = generatedText.substring(startIndex, endIndex);
      const parsedResponse = JSON.parse(jsonPart) as TemplateJobAd;

      if (!parsedResponse) {
        throw new Error("Keine gültige Antwort vom AI erhalten");
      }
      const mandant = this.mandants.find(
        (m: Mandant) => m.uuid === jobAd.mandantUuid
      );
      const salaryRange = this.getSalaryRange(
        jobAd.salaryFrom,
        jobAd.salaryTo,
        jobAd.salaryPeriod
      );
      const whatsApp = mandant?.whatsApp?.replace(/[^0-9]/g, "")
        ? mandant?.whatsApp?.replace(/[^0-9]/g, "")
        : "";

      const generatedJobAd: TemplateJobAd = {
        jobTitle: parsedResponse.jobTitle,
        tasksHeader: parsedResponse.tasksHeader,
        salaryRange: salaryRange,
        whatsAppNumber: whatsApp,
        locationCity: jobAd.city,
        locationPostalCode: jobAd.postalCode?.toString() || "",
        emailAccountId: mandant?.EmailEingangskontoId,
        tasks: parsedResponse.tasks,
        technicalRequirementsHeader: parsedResponse.technicalRequirementsHeader,
        technicalRequirements: parsedResponse.technicalRequirements,
        personalRequirementsHeader: parsedResponse.personalRequirementsHeader,
        personalRequirements: parsedResponse.personalRequirements,
        employerBenefitsHeader: parsedResponse.employerBenefitsHeader,
        employerBenefits: parsedResponse.employerBenefits,
        perspectivesHeader: parsedResponse.perspectivesHeader,
        perspectives: parsedResponse.perspectives,
        jobObjectiveHeader: parsedResponse.jobObjectiveHeader,
        jobObjective: parsedResponse.jobObjective,
        metaDescription: parsedResponse.metaDescription,
        metaKeywords: parsedResponse.metaKeywords,
        contactTextHeader: mandant?.name,
        contactText: mandant?.contact,
        mandant: { ObjectUuid: jobAd.mandantUuid },
      };

      return generatedJobAd as TemplateJobAd;
    } catch (error: any) {
      console.error(
        "Error in ai-core:",
        error.response ? error.response.data : error.message
      );
      throw error;
    } finally {
      RecurionSpinnerService.clearSpinner();
    }
  }

  public async postJobAdToZvoove(generatedJobAd: TemplateJobAd) {
    const parameter = {
      Bezeichnung: generatedJobAd.jobTitle,
      BezeichnungAuschreibung: generatedJobAd.jobTitle,
      BeginnAbSofort: true,
      ArbeitgebervorstellungHeader:
        generatedJobAd.salaryRange?.slice(0, 100) || "",
      Arbeitgebervorstellung: generatedJobAd.whatsAppNumber,
      EinsatzortOrt: generatedJobAd.locationCity,
      EinsatzortPlz: generatedJobAd.locationPostalCode,
      EmailEingangskontoId: generatedJobAd.emailAccountId,
      StellenzielHeader: generatedJobAd.jobObjectiveHeader?.slice(0, 100) || "",
      Stellenziel: generatedJobAd.jobObjective.replace(/\r?\n/g, "<br>"),
      AufgabenHeader: generatedJobAd.tasksHeader?.slice(0, 100) || "",
      Aufgaben: generatedJobAd.tasks.replace(/\r?\n/g, "<br>"),
      PerspektivenHeader:
        generatedJobAd.perspectivesHeader?.slice(0, 100) || "",
      Perspektiven: generatedJobAd.perspectives.replace(/\r?\n/g, "<br>"),
      UnternehmensbedeutungHeader:
        generatedJobAd.metaKeywords?.slice(0, 100) || "",
      Unternehmensbedeutung: generatedJobAd.metaDescription.slice(0, 160) || "",
      FachlicheAnforderungenHeader:
        generatedJobAd.technicalRequirementsHeader?.slice(0, 100) || "",
      FachlicheAnforderungen: generatedJobAd.technicalRequirements.replace(
        /\r?\n/g,
        "<br>"
      ),
      PersoenlicheAnforderungenHeader:
        generatedJobAd.personalRequirementsHeader?.slice(0, 100) || "",
      PersoenlicheAnforderungen: generatedJobAd.personalRequirements.replace(
        /\r?\n/g,
        "<br>"
      ),
      ArbeitgeberleistungHeader: generatedJobAd.employerBenefitsHeader,
      Arbeitgeberleistung: generatedJobAd.employerBenefits.replace(
        /\r?\n/g,
        "<br>"
      ),
      KontaktTextHeader: generatedJobAd.contactTextHeader?.slice(0, 100) || "",
      KontaktText: generatedJobAd.contactText,
      Mandant: generatedJobAd.mandant,
      EinsatzortLand: "Deutschland",
      EinsatzortLandIso002: "DE",
      Tarifvertrag: this.collectiveAgreement,
    };

    try {
      const createResponse = await this.atsRecruitService.postStelleCreate(
        parameter
      );

      if (
        store.state.jobPosterState.jobAdPublishHomepage &&
        createResponse.StelleUuid
      ) {
        const publishParameter = { StelleUuid: createResponse.StelleUuid };
        await this.atsRecruitService.publishStelle(publishParameter);
      }
      return createResponse.StelleUuid;
    } catch (error) {
      console.error("Fehler beim Posten der Stellenanzeige zu Zvoove:", error);
      throw error;
    }
  }

  public async jobPostingLoop(type: jobAdType, mandants?: string[]) {
    this.updateStoreVariables();
    const timelineHelperService = new TimelineHelperService();

    if (store.state.jobPosterState.postingIsCancelled) {
      store.commit(MutationType.togglePostingIsCancelled, false);
    }
    store.commit(MutationType.resetPostedJobAds);

    this.locations = await this.jobAdService.getLocations();

    let allAds = await this.jobAdService.getRequestedJobAds(type, mandants);
    const totalJobAds = allAds.length;

    ToastService.showReminder(
      `🚀 Ich bin super motiviert und habe derzeit ${totalJobAds} Stellen zum Ausschreiben. 🤖`
    );
    store.commit(MutationType.setTotalJobAds, totalJobAds);

    while (allAds.length > 0) {
      // need new status if many clients posting:
      allAds = await this.jobAdService.getRequestedJobAds(type, mandants);

      if (allAds.length === 0) {
        DialogService.alert("Keine weiteren Stellenanzeigen zum Ausschreiben.");
        store.commit(MutationType.setStatus, JobposterState.completed);
        break;
      }

      if (type === jobAdType.jobmatrix) this.shuffleArray(allAds);

      const jobAd = allAds[0];

      if (store.state.jobPosterState.postingIsCancelled) {
        store.commit(MutationType.setStatus, JobposterState.break);
        break;
      }

      while (store.state.jobPosterState.postingIsPaused) {
        store.commit(MutationType.setStatus, JobposterState.paused);
        await this.delay(1000);
      }

      store.commit(MutationType.setStatus, JobposterState.posting);

      try {
        if (jobAd._id) {
          const location = await this.getLocationById(jobAd.locationId);
          const adToGenerate = {
            jobTitle: jobAd.title,
            postalCode:
              typeof location?.postalCode === "number"
                ? location.postalCode
                : null,
            city: location?.city ?? "",
            tasks: jobAd.tasks ?? "",
            profile: jobAd.profile ?? "",
            salaryFrom: jobAd.salaryFrom,
            salaryTo: jobAd.salaryTo,
            salaryPeriod: jobAd.salaryPeriod,
            mandantUuid: location?.mandants?.[0] ?? "",
          };

          const generatedJobAd = await this.generateJobAd(adToGenerate);

          if (this.zvooveRecruit) {
            try {
              const recruitJobAdUuid = await this.postJobAdToZvoove(
                generatedJobAd
              );
              jobAd.status = PostingStatus.posted;
              jobAd.uuid = recruitJobAdUuid;

              if (
                (store.state.jobPosterState.jobAdPublishOnBfA ||
                  jobAd.supportedBfAJobAd) &&
                jobAd.categoriesBfA
              ) {
                const zorstService = ZorstService.getInstance();
                const zvooveJobAdLink = `${store.state.company.softwareIntegration.zvooveRecruitLink}/recruiting/stelle/${recruitJobAdUuid}/publish/shop/free`;
                const zorstJobAdData = {
                  openJobAdLink: zvooveJobAdLink,
                  categoriesBfA: jobAd.categoriesBfA,
                  salary: `${jobAd.salaryFrom} bis ${jobAd.salaryTo} ${jobAd.salaryPeriod}`,
                  placement: "Arbeitnehmerüberlassung",
                  collectiveAgreement: this.collectiveAgreement,
                };
                try {
                  await zorstService.ZorstPublishJobAdInZvooveRecruit(
                    zorstJobAdData,
                    jobAd.supportedBfAJobAd
                  );
                } catch (error) {
                  console.error(error);
                  store.commit(MutationType.togglePostingIsCancelled, true);
                  store.commit(MutationType.setStatus, JobposterState.break);
                  break;
                }
              }

              await this.jobAdService.updateJobAd(jobAd);

              allAds = await this.jobAdService.getRequestedJobAds(
                type,
                mandants
              );
              store.commit(
                MutationType.postedJobAds,
                totalJobAds - allAds.length
              );
              let linkingStatus = LinkingStatus.jobAdPublishedFromJobsList;
              if (type === jobAdType.jobmatrix)
                linkingStatus = LinkingStatus.jobAdPublishedFromJobsMatrix;
              timelineHelperService.systemAutoDocu(
                linkingStatus,
                location?.mandants?.[0] ?? "",
                `${jobAd.title} in ${location?.postalCode} ${location?.city} erfolgreich erstellt`
              );

              if (store.state.jobPosterState.postedJobAds === totalJobAds) {
                store.commit(MutationType.setStatus, JobposterState.completed);
                break;
              }
            } catch (error) {
              ToastService.showError(
                "Fehler beim Posten der Stellenanzeige zu Zvoove: " + error
              );
            }
          }
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Verarbeiten der Stellenanzeige: " + error
        );
      }
    }
  }

  private shuffleArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
