import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("fa-solid fa-rectangle-ad")
            ])),
            _: 1
          }),
          _cache[12] || (_cache[12] = _createTextVNode("INDEX Anzeigendaten "))
        ]),
        _createVNode(_component_v_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_slider, {
                      modelValue: _ctx.localConfig.softwareIntegration.indexJobAdRefreshInterval,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.softwareIntegration.indexJobAdRefreshInterval) = $event)),
                      max: 30,
                      min: 1,
                      step: "1",
                      label: "aktualisieren bei Firmen (Tage)",
                      "thumb-label": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_slider, {
                      modelValue: _ctx.localConfig.softwareIntegration.indexJobAdAgeLimitDays,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localConfig.softwareIntegration.indexJobAdAgeLimitDays) = $event)),
                      max: 365,
                      min: 1,
                      step: "1",
                      label: "max. Alter (Firmen)",
                      "thumb-label": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_slider, {
                      modelValue: _ctx.localConfig.softwareIntegration.indexJobLeadsAgeLimitDays,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localConfig.softwareIntegration.indexJobLeadsAgeLimitDays) = $event)),
                      max: 365,
                      min: 1,
                      step: "1",
                      label: "max. Alter (Kandidat)",
                      "thumb-label": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_slider, {
                      modelValue: _ctx.localConfig.softwareIntegration.indexJobLeadsMaxAds,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localConfig.softwareIntegration.indexJobLeadsMaxAds) = $event)),
                      max: 250,
                      min: 1,
                      step: "1",
                      label: "max. Anzahl Stellenanzeigen",
                      "thumb-label": ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                (_ctx.hasConfigIndexAnzAccess)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.localConfig.apiKeys.baseUrlIndexCustomers,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localConfig.apiKeys.baseUrlIndexCustomers) = $event)),
                          label: "Link zur Schnittstelle für Kunden"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.hasConfigIndexAnzAccess)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.localConfig.apiKeys.baseURLIndexAds,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localConfig.apiKeys.baseURLIndexAds) = $event)),
                          label: "Link zur Schnittstelle für Stellenanzeigen"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.hasConfigIndexAnzAccess)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 2,
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          modelValue: _ctx.localConfig.apiKeys.baseURLIndexAuth,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localConfig.apiKeys.baseURLIndexAuth) = $event)),
                          label: "Base URL Index Ads"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
                      color: "primary",
                      class: "ma-2",
                      onClick: _ctx.openIndexAuthModal
                    }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          class: _normalizeClass(
                _ctx.localConfig.apiKeys.indexAnzeigendaten.length > 0
                  ? 'fa-solid fa-lock-open'
                  : 'fa-solid fa-lock'
              )
                        }, null, 8, ["class"]),
                        _cache[13] || (_cache[13] = _createTextVNode("   neues Token einrichten "))
                      ]),
                      _: 1
                    }, 16, ["onClick"])
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localConfig.apiKeys.indexAnzeigendaten, (ad, index) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: ad._id,
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, { class: "mb-4 py-4" }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_combobox, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: 
                    _ctx.localConfig.apiKeys.indexAnzeigendaten[index]
                      .creditalsForUsersOnly
                  ,
                                "onUpdate:modelValue": ($event: any) => ((
                    _ctx.localConfig.apiKeys.indexAnzeigendaten[index]
                      .creditalsForUsersOnly
                  ) = $event),
                                items: _ctx.creditalsForUsersOnlyItems,
                                label: "creditalsForUsersOnly",
                                "prepend-inner-icon": "fa-solid fa-user",
                                chips: "",
                                clearable: "",
                                multiple: "",
                                density: "compact"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                              _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: 
                    _ctx.localConfig.apiKeys.indexAnzeigendaten[index]
                      .creditalsForMandantsOnly
                  ,
                                "onUpdate:modelValue": ($event: any) => ((
                    _ctx.localConfig.apiKeys.indexAnzeigendaten[index]
                      .creditalsForMandantsOnly
                  ) = $event),
                                items: _ctx.creditalsForMandantsOnlyItems,
                                "item-title": "label",
                                "item-value": "value",
                                label: "Token gültig für Niederlassungen",
                                "prepend-inner-icon": "fa-solid fa-building",
                                density: "compact",
                                chips: "",
                                clearable: "",
                                multiple: ""
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: _ctx.localConfig.apiKeys.indexAnzeigendaten[index].LOGIN,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.localConfig.apiKeys.indexAnzeigendaten[index].LOGIN) = $event),
                                disabled: !_ctx.hasConfigIndexAnzAccess,
                                density: "compact",
                                label: "Login"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "disabled"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: _ctx.localConfig.apiKeys.indexAnzeigendaten[index].TOKEN,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.localConfig.apiKeys.indexAnzeigendaten[index].TOKEN) = $event),
                                disabled: !_ctx.hasConfigIndexAnzAccess,
                                density: "compact",
                                label: "Token"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "disabled"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: _ctx.localConfig.apiKeys.indexAnzeigendaten[index].EXPIRE,
                                "onUpdate:modelValue": ($event: any) => ((_ctx.localConfig.apiKeys.indexAnzeigendaten[index].EXPIRE) = $event),
                                disabled: !_ctx.hasConfigIndexAnzAccess,
                                density: "compact",
                                label: "Expire"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "disabled"]),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "2",
                                class: "d-flex align-center justify-center"
                              })
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                color: "abort",
                                onClick: ($event: any) => (_ctx.deleteAd(index))
                              }, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createTextVNode("Löschen")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_BaseDialog, {
          showDialog: _ctx.dialog,
          width: 'var(--d-w-s)',
          showActions: true,
          onCloseDialog: _cache[10] || (_cache[10] = ($event: any) => (_ctx.dialog = false))
        }, {
          title: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode(" Anzeigendaten Zugangsdaten ")
          ])),
          content: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, { ref: "form" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                      modelValue: _ctx.credentials.login,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.credentials.login) = $event)),
                      label: "User",
                      required: ""
                    }), null, 16, ["modelValue"]),
                    _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                      modelValue: _ctx.credentials.password,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.credentials.password) = $event)),
                      label: "Passwort",
                      type: "password",
                      required: ""
                    }), null, 16, ["modelValue"])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            })
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "red darken-4",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.dialog = false))
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Abbrechen")
              ])),
              _: 1
            }),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              color: "green darken-3",
              onClick: _ctx.submitIndexAuthModal
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("OK")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["showDialog"])
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}