<template>
  <!-- TODO: use dialog.service confirmDialog -->
  <v-dialog v-model="showDialog" class="responsive-dialog">
    <v-card title="Eintrag löschen">
      <v-card-text> Soll der Eintrag wirklich gelöscht werden? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="Löschen" @click="validate"></v-btn>
        <v-btn text="Abbrechen" @click="closeDialog"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "ValidateDeletionDialog",
  props: {
    show: Boolean,
    email: String,
  },
  setup(props, { emit }) {
    const showDialog = ref(false);

    watch(
      () => props.show,
      (newVal) => {
        showDialog.value = newVal;
      }
    );

    const validate = () => {
      closeDialog();
      emit("validate", props.email);
    };

    const closeDialog = () => {
      showDialog.value = false;
      emit("update:show", false); // Emit event to update the prop
    };

    return {
      showDialog,
      closeDialog,
      validate,
    };
  },
});
</script>
<style>
.responsive-dialog .v-overlay__content {
  width: 100% !important;
  max-width: none !important;
}

@media (min-width: 960px) {
  .responsive-dialog .v-overlay__content {
    width: 25% !important;
  }
}
</style>
