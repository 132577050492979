<!--src/components/disposition/EmployeeColumn.vue-->
<template>
  <div
    class="column"
    @drop="handleDrop"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent
  >
    <CvDropField
      :showDropField="showDropField"
      :parentRole="Role.employee"
      :fieldText="dropFieldText"
      @handleDrop="handleDrop"
    />
    <div
      v-if="!isActive"
      :class="
        mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      "
    ></div>
    <div v-if="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <v-text-field
              v-bind="vStyle?.input"
              v-model="searchTerm"
              density="compact"
              label="Suchbegriff"
              @keydown.enter="filterSearchterm(searchTerm)"
            >
              <template v-slot:append-inner>
                <v-btn
                  class="mx-2"
                  density="compact"
                  variant="text"
                  icon
                  :disabled="!searchTerm"
                  @click="loadEmployees()"
                >
                  <v-icon> fa-solid fa-arrows-rotate </v-icon>
                  <v-tooltip activator="parent" location="top"
                    >alle Firmen wieder anzeigen</v-tooltip
                  >
                </v-btn>
              </template>
              <v-tooltip activator="parent" location="top"
                >Einen oder mehrere Suchbegriffe eingeben und mit Enter
                bestätigen</v-tooltip
              >
            </v-text-field>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="(label, key) in employeeStates"
                :key="key"
                v-model="selectedStatuses"
                :label="label"
                :value="String(key)"
                class="ma-0 pa-0"
                @update:model-value="updateStatusFilter(selectedStatuses)"
              ></v-checkbox>
            </div>
          </template>
          <template #filter-menu-right-column>
            <v-select
              v-bind="vStyle?.input"
              v-model="filterEmployeesCurrent"
              density="compact"
              :items="FilterEmployees"
              label="sortieren nach ..."
              item-title="label"
              item-value="label"
              @update:model-value="updateItemsOrder"
            >
            </v-select>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
                class="ma-0 pa-0"
                @update:model-value="updateMandantsFilter(selectedMandants)"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon variant="text" v-bind="props"
            ><v-icon size="large" class="filter-icon"
              >fa-solid fa-circle-plus</v-icon
            >
            <v-tooltip activator="parent" location="top left"
              >Personaldaten anlegen</v-tooltip
            ></v-btn
          >
        </template>
        <v-card v-bind="vStyle.card">
          <v-card-title class="">Personaldaten</v-card-title>
          <v-card-subtitle class="mb-2">aus ERP importieren:</v-card-subtitle>
          <v-list dense>
            <v-list-item
              v-if="softwareIntegration.zvooveOne"
              @click="openAddEmployeeDialog()"
            >
              <div>
                <img
                  :src="$store.getters.getEnv.assetsUrl + '/icons/icon-erp.svg'"
                  class="context-menu-img-icon mt-3"
                />
                aus ERP One
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.pdHub"
              @click="openAddEmployeeDialog()"
            >
              <div>
                <img
                  :src="
                    $store.getters.getEnv.assetsUrl + '/icons/icon-pd-hub.svg'
                  "
                  class="context-menu-img-icon mt-3"
                />
                aus PD Hub
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.zvooveOne &&
                loggedInMandantUuids.length > 0 &&
                hasFullEmployeesAccess
              "
              @click="getAllEmployeesFromErp"
            >
              <div class="d-flex">
                <img
                  :src="$store.getters.getEnv.assetsUrl + '/icons/icon-erp.svg'"
                  class="context-menu-img-icon mt-3"
                />
                <div class="mt-2">alle Status</div>
                <v-select
                  class="mx-1"
                  variant="plain"
                  density="compact"
                  :items="[2, 3, 4]"
                  v-model="employeeStateForGetAllFromERP"
                  @click.stop
                ></v-select>
                <div class="mt-2">aus ERP One</div>
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.pdHub &&
                loggedInMandantUuids.length > 0 &&
                hasFullEmployeesAccess
              "
              @click="getAllEmployeesFromErp"
            >
              <div class="d-flex">
                <img
                  :src="
                    $store.getters.getEnv.assetsUrl + '/icons/icon-pd-hub.svg'
                  "
                  class="context-menu-img-icon mt-3"
                />
                <div class="mt-2">alle Status</div>
                <v-select
                  class="mx-1"
                  variant="plain"
                  density="compact"
                  :items="[2, 3, 4]"
                  v-model="employeeStateForGetAllFromERP"
                  @click.stop
                ></v-select>
                <div class="mt-2">aus PD-Hub</div>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div v-if="employees.length === 0" @drop.prevent @dragover.prevent>
      <div class="dispatcher-board-empty-column-spacer"></div>
      <v-card v-bind="vStyle?.card" border="dashed">
        <v-card-title>Keine Personaldaten </v-card-title>
        <v-card-subtitle>unter diesem Filter</v-card-subtitle>
        <v-card-text>
          Kandidat auf dieses Feld ziehen zum Anlegen im ERP System
        </v-card-text>
      </v-card>
    </div>
    <v-infinite-scroll
      :height="columnHeight - DispatcherBoardColumnHeight.iLoaderSubtract"
      :distance="DispatcherBoardColumnHeight.iLoaderDistance"
      disabled
      class="infinite-scroll-hidden-scrollbar ma-0 pa-0"
    >
      <div class="ma-0 pa-0 dispatcher-board-draggable">
        <EmployeeItem
          v-for="element in employees"
          :key="element._id"
          :ref="
            (el: any) => {
              if (el) employeeItemRefs.push(el);
            }
          "
          :employee="element"
          :isActive="isActive"
          :isFullyCollapsed="minimizeAllItems"
          :user="user"
          @insertUpdatedEmployee="insertUpdatedEmployee"
          @editContact="editContact"
          @contextmenu.prevent="openContextMenu($event, element)"
          @longpress="openContextMenu($event, element)"
          @openContextMenu="openContextMenu($event, element)"
        />
      </div>
      <template v-slot:loading
        ><!--Placeholder for message in pagination logic--></template
      >
    </v-infinite-scroll>
  </div>
  <v-menu
    v-model="showContextMenu"
    :style="{
      top: contextMenuPosition.y + 'px',
      left: contextMenuPosition.x + 'px',
    }"
    @click.stop
  >
    <v-list dense>
      <v-list-item @click="editEmployee"
        ><v-icon class="text-medium-emphasis mx-1" size="xs">
          fa-solid fa-pen-to-square
        </v-icon>
        bearbeiten</v-list-item
      >
      <v-list-item
        @click="updateAllEmployees(AllItemUpdate.checkEmployeeStatus)"
      >
        <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
          fa-solid fa-magnifying-glass-chart </v-icon
        >alle Checken: Status aus ERP</v-list-item
      >
      <v-list-item @click="updateAllEmployees(AllItemUpdate.getPlacements)">
        <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
          fa-solid fa-user-clock </v-icon
        >alle Updaten: Einsätze aus ERP</v-list-item
      >
      <v-list-item
        @click="updateAllEmployees(AllItemUpdate.updateEmployeeFromErp)"
      >
        <v-icon class="text-medium-emphasis ml-1 mr-2" size="xs">
          fa-solid fa-cloud-arrow-down </v-icon
        >alle aus ERP überschreiben</v-list-item
      >
      <v-list-item @click="confirmDeleteemployee(employee)">
        <v-icon class="text-medium-emphasis mx-1" size="xs">
          fa-solid fa-xmark
        </v-icon>
        löschen
      </v-list-item>
      <v-list-item @click="toggleItemsMinimized()">
        <v-icon class="text-medium-emphasis mr-1" size="xs">
          {{
            minimizeAllItems
              ? "fa-solid fa-chevron-up"
              : "fa-solid fa-chevron-down"
          }}
        </v-icon>
        {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
      </v-list-item>
    </v-list>
  </v-menu>
  <DialogEditEmployee
    :employee="employee"
    ref="dialogEditEmployeeComponent"
    @insertUpdatedEmployee="insertUpdatedEmployee"
  />
  <BaseDialog
    :showDialog="showDialogCandidateToEmployee"
    :showActions="true"
    :width="'var(--d-w-l)'"
    @closeDialog="closeModal()"
  >
    <template #title> neuen Mitarbeiter im ERP System anlegen </template>
    <template #content>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.salutationCatalogId"
              :items="salutations"
              item-title="value"
              item-value="label"
              label="Anrede"
            />
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.firstName"
              label="Vorname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.lastName"
              label="Nachname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.addressStreet"
              label="Straße"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.addressHouseNumber"
              label="Hausnummer"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.addressPostalCode"
              label="PLZ"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.addressCity"
              label="Ort"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateData.maritalStatus"
              :items="maritalStatus"
              item-title="value"
              item-value="value"
              label="Familienstand"
            />
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="5">
            <v-select
              v-bind="vStyle?.input"
              v-model="candidateForErp.candidateMandantUuid"
              :items="mandants"
              item-title="name"
              item-value="uuid"
              label="anlegen unter:"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeModal()">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="createEmployeeFromCandidateData()"
        >Mitarbeiter Anlegen</v-btn
      >
    </template>
  </BaseDialog>
  <DialogAddEmployee
    ref="dialogAddEmployeeComponent"
    @submit="getEmployeeFromErp"
    @selectedResult="addEmployee"
  ></DialogAddEmployee>
</template>

<script lang="ts">
import { Candidate } from "@/models/candidate.model";
import { defineComponent, PropType } from "vue";
import { Employee } from "@/models/employee.model";
import { EmployeeService } from "@/services/api/employee.service";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters } from "vuex";
import { MaritalStatus } from "@/enums/marital-status.enum";
import { Salutation } from "@/enums/salutation.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import DialogAddEmployee from "./elements/DialogAddEmployee.vue";
import DialogEditEmployee from "@/components/disposition/elements/DialogEditEmployee.vue";
import DialogService from "@/services/dialog.service";
import EmployeeItem from "@/components/disposition/EmployeeItem.vue";
import FilterMenu from "./elements/FilterMenu.vue";
import ToastService from "@/services/toast.service";
import { ModalMode } from "@/enums/dialog.enum";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import { debounce } from "lodash";
import { DispatcherBoardColumnHeight } from "../../enums/app-layout.enum";
import { Role } from "../../enums/dependency.enum";
import {
  AllItemUpdate,
  FilterEmployees,
  UserConfigFilterBoard,
  UserConfigFilterType,
} from "../../enums/board-actions.enum";
import { Assignment } from "../../models/assignment.model";
import moment from "moment";
import { ActionType } from "../../enums/vuex-types.enum";
import { SpinnerService } from "../../services/spinner.service";
import CvDropField from "./elements/drag-and-drop/DropField.vue";
import { DragEventSpec } from "../../enums/drag-event-spec.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { VuetifyColor } from "../../plugins/vuetify";
import { AccessRule } from "../../enums/access-rule.enum";

export default defineComponent({
  name: "EmployeeColumn",
  components: {
    BaseDialog,
    CvDropField,
    DialogAddEmployee,
    DialogEditEmployee,
    EmployeeItem,
    FilterMenu,
  },
  props: {
    columnHeight: {
      type: Number,
      default: DispatcherBoardColumnHeight.standard,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { smAndDown, mdAndDown } = useDisplay();
    return {
      smAndDown,
      mdAndDown,
      AllItemUpdate,
      candidateForErp: {
        candidateMandantUuid: "",
        candidateData: {} as Candidate,
      },
      contextMenuPosition: { x: 0, y: 0 },
      DispatcherBoardColumnHeight,
      drag: false,
      dragOverTimer: null as number | null,
      dropFieldText: {
        icon: "",
        title: "",
        subtitle: "",
        text: "",
      },
      employee: {} as Employee,
      employees: [] as Employee[],
      employeeService: new EmployeeService(),
      employeeStateForGetAllFromERP: 2,
      employeeItemRefs: [] as InstanceType<typeof EmployeeItem>[],
      FilterEmployees: getEnumOptions(FilterEmployees),
      filterEmployeesCurrent: this.user.config.dispatcherBoard.columnEmployee
        .orderDraggable[0] as FilterEmployees,
      ModalMode: ModalMode,
      minimizeAllItems: false,
      Role,
      searchTerm: "",
      selectedMandants:
        this.user.config.dispatcherBoard.columnEmployee.filterMandants,
      selectedStatuses:
        this.user.config.dispatcherBoard.columnEmployee.filterStatus,
      showContextMenu: false,
      showDialogCandidateToEmployee: false,
      showDropField: false,
      showFilters: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      employeeStates: "employeeStates",
    }),
    hasFullEmployeesAccess(): boolean {
      return UserRoleHelper.hasAccess(this.user, AccessRule.getAllEmployees);
    },
    maritalStatus() {
      return getEnumOptions(MaritalStatus);
    },
    salutations() {
      return getEnumOptions(Salutation);
    },
    loggedInGeschaeftsstelleIds() {
      const ids = this.$store.getters.getLoggedInMandantBranchNumbers;
      const uniqueIds = [...new Set(ids)];
      return uniqueIds.map((id: any) => id.toString());
    },
  },
  created() {
    this.getFiltersettingsFromStore().then(() => {
      this.loadEmployees();
    });
  },
  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
  },
  beforeUpdate() {
    this.employeeItemRefs = [];
  },
  beforeUnmount() {
    this.employeeItemRefs = [];
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    addEmployee(employee: Employee) {
      this.employeeService
        .addEmployee(employee)
        .then((newEmployee: Employee) => {
          this.employees.unshift(newEmployee);
        });
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    closeModal() {
      this.showDialogCandidateToEmployee = false;
    },
    async confirmDeleteemployee(employee: Employee) {
      if (employee) {
        const confirmed = await DialogService.confirm(
          "Der Personaldatensatz wird unwiderruflich gelöscht!",
          "Behalten",
          "Löschen",
          "Mitarbeiter löschen?",
          VuetifyColor.primary,
          VuetifyColor.error
        );

        if (confirmed && employee._id) {
          this.employeeService.deleteEmployee(employee._id).then(() => {
            this.loadEmployees();
          });
        }
      }
      this.closeContextMenu();
    },
    async createEmployeeFromCandidateData() {
      try {
        const response = await AllErpAdapter.createEmployeeFromCandidateData(
          this.candidateForErp.candidateData as Candidate,
          this.candidateForErp.candidateMandantUuid
        );
        if (response && response.employee) {
          this.addEmployee(response.employee);

          this.closeModal();
        } else {
          ToastService.showError("Anlage im ERP-System hat nicht geklappt!");
        }
      } catch (error) {
        ToastService.showError("Fehler bei der Synchronisation: " + error);
      }
    },
    editContact(employee: Employee) {
      this.employee = employee;
      this.editEmployee();
    },
    editEmployee() {
      if (this.$refs.dialogEditEmployeeComponent) {
        (
          this.$refs.dialogEditEmployeeComponent as InstanceType<
            typeof DialogEditEmployee
          >
        ).openModal();
      }
    },
    async filterEmployees(
      employeeList: Employee[],
      filterOption: FilterEmployees
    ) {
      const today = moment();

      switch (filterOption) {
        case FilterEmployees.name:
          employeeList = this.filterEmployeesByName(employeeList);
          break;

        case FilterEmployees.postcode:
          employeeList = this.filterEmployeeByPostcode(employeeList);
          break;

        case FilterEmployees.employeeNumber:
          employeeList = this.filterEmployeesByEmployeeNumber(employeeList);
          break;

        case FilterEmployees.entryDate:
          employeeList = this.filterEmployeesByEntryDate(employeeList);
          break;

        case FilterEmployees.exitDate:
          employeeList = this.filterEmployeesByExitDate(employeeList, today);
          break;

        case FilterEmployees.expiringAssignments:
          employeeList =
            this.filterEmployeesByExpiringAssignments(employeeList);
          break;

        default:
          break;
      }

      this.employees = employeeList;
    },
    filterEmployeesByName(employeeList: Employee[]) {
      return employeeList.sort((a, b) =>
        (a.lastName || "").localeCompare(b.lastName || "")
      );
    },
    filterEmployeeByPostcode(employeeList: Employee[]) {
      return employeeList.sort((a, b) =>
        (a.address.postalCode || "").localeCompare(b.address.postalCode || "")
      );
    },
    filterEmployeesByEmployeeNumber(employeeList: Employee[]) {
      return employeeList.sort((a, b) =>
        (a.employeeNumber || "").localeCompare(b.employeeNumber || "")
      );
    },
    filterEmployeesByEntryDate(employeeList: Employee[]) {
      return employeeList.sort((a, b) => {
        const dateA = moment(a.entryDate || 0).valueOf();
        const dateB = moment(b.entryDate || 0).valueOf();
        return dateB - dateA;
      });
    },
    filterEmployeesByExitDate(employeeList: Employee[], today: moment.Moment) {
      return employeeList.sort((a, b) => {
        const dateA = a.exitDate ? moment(a.exitDate).valueOf() : Infinity;
        const dateB = b.exitDate ? moment(b.exitDate).valueOf() : Infinity;

        if (dateA === Infinity && dateB === Infinity) return 0;
        if (dateA === Infinity) return 1;
        if (dateB === Infinity) return -1;

        return Math.abs(today.diff(dateA)) - Math.abs(today.diff(dateB));
      });
    },
    filterEmployeesByExpiringAssignments(employeeList: Employee[]) {
      return employeeList.sort((a, b) => {
        const getLatestAssignmentEndDate = (assignments?: Assignment[]) => {
          if (!assignments || assignments.length === 0) return null;

          const latestAssignment = assignments.reduce((latest, current) => {
            if (!moment(current.to).isValid()) return latest;
            return moment(current.to).isAfter(moment(latest.to))
              ? current
              : latest;
          });

          return moment(latestAssignment.to).isValid()
            ? moment(latestAssignment.to).valueOf()
            : null;
        };

        const endDateA = getLatestAssignmentEndDate(a.assignments);
        const endDateB = getLatestAssignmentEndDate(b.assignments);

        if (endDateA === null && endDateB === null) return 0;
        if (endDateA === null) return -1;
        if (endDateB === null) return 1;

        return endDateA - endDateB;
      });
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadEmployees();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ") ?? "";

      const filteredemployees = this.employees.filter((employee) => {
        const matches = terms.every((term) =>
          this.searchAllFields(employee, term)
        );

        return matches;
      });

      this.employees = filteredemployees;
    },
    async getAllEmployeesFromErp() {
      try {
        const response = await AllErpAdapter.getAllEmployeesFromErp(
          this.employeeStateForGetAllFromERP
        );

        if (response) {
          this.initAllEmployeeData(response);
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der Mitarbeiterdaten: " + error
        );
      }
    },
    async getFiltersettingsFromStore(): Promise<void> {
      return new Promise((resolve) => {
        this.selectedMandants =
          this.user.config.dispatcherBoard.columnEmployee.filterMandants;
        this.selectedStatuses =
          this.user.config.dispatcherBoard.columnEmployee.filterStatus;
        resolve();
      });
    },
    async getEmployeeFromErp(employeeNumber: string) {
      try {
        const result = await AllErpAdapter.getEmployeeFromErp(employeeNumber);
        if (result && this.$refs.dialogAddEmployeeComponent) {
          (
            this.$refs.dialogAddEmployeeComponent as InstanceType<
              typeof DialogAddEmployee
            >
          ).openResultsModal(result);
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der Personaldaten: " + error
        );
      }
    },
    handleDragOver() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.handleShowDropField();
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleDragLeave() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleShowDropField() {
      const originComponent = this.$store.state.dragOriginComponent;
      if (
        originComponent === Role.employee ||
        originComponent === Role.demand ||
        originComponent === Role.customer
      ) {
        this.showDropField = false;
      } else if (originComponent === Role.candidate) {
        const hasLinkedEmployee =
          this.$store.state.isDraggingItem?.candidate?.candidateData
            ?.employeeObjectId ?? undefined;
        this.dropFieldText = {
          icon: hasLinkedEmployee
            ? "fa-solid fa-circle-exclamation"
            : "fa-solid fa-cloud-arrow-up",
          title: hasLinkedEmployee ? "Der Kandidat" : "Der Kandidat",
          subtitle: hasLinkedEmployee
            ? "ist bereits mit ERP Personalie verknüpft"
            : "wird ins ERP System übertragen.",
          text: hasLinkedEmployee
            ? "Es wird eine weitere Personalie erstellt"
            : "Der Datensatz wird als Bewerberdatensatz angelegt",
        };
        this.showDropField = true;
      }
    },
    handleDrop(event: DragEvent) {
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          DragEventSpec.originComponent
        );

        if (originComponent === Role.candidate) {
          this.candidateForErp.candidateMandantUuid =
            this.$store.state.isDraggingItem?.candidate?.candidateMandantUuid;
          this.candidateForErp.candidateData =
            this.$store.state.isDraggingItem?.candidate?.candidateData;
          this.openCandidateToEmployeeModal();
        }
      }
    },
    async initAllEmployeeData(employees: Employee[]) {
      try {
        const response = await this.employeeService.addAllEmployees(employees);
        const { added, updated, skipped } = response;

        let successSummary = `${added} Personaldaten erfolgreich hinzugefügt.`;
        if (updated > 0) {
          successSummary += `\n${updated} Personaldaten erfolgreich aktualisiert.`;
        }
        if (skipped > 0) {
          successSummary += `\n${skipped} Personaldaten wurden übersprungen, da sie bereits vorhanden waren.`;
        }
        ToastService.showReminder(successSummary);
      } catch (error) {
        ToastService.showError(
          "Fehler beim Hinzufügen oder Aktualisieren der Personaldaten."
        );
      }

      this.loadEmployees();
    },
    insertUpdatedEmployee(updatedEmployee: Employee) {
      const index = this.employees.findIndex(
        (employee) => employee._id === updatedEmployee._id
      );

      if (index !== -1) {
        this.employees[index] = updatedEmployee;
        this.$nextTick(() => {
          const employeeItem = this.employeeItemRefs.find(
            (ref) => ref.employee._id === updatedEmployee._id
          );
          if (employeeItem) {
            employeeItem.updateFilteredEmail();
          }
        });
      }
    },
    searchAllFields(object: Employee, term: string): boolean {
      return Object.values(object).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(term);
        } else if (typeof value === "object" && value !== null) {
          return this.searchAllFields(value, term);
        } else if (Array.isArray(value)) {
          return value.some((item) => this.searchAllFields(item, term));
        }
        return false;
      });
    },
    async loadEmployees() {
      await this.$nextTick();
      const selectedStatusesNumbers = this.selectedStatuses.map(Number);
      const response = await this.employeeService.getAllFiltered(
        this.selectedMandants,
        selectedStatusesNumbers
      );
      this.employees = response ? response : [];
      if (this.filterEmployeesCurrent)
        this.filterEmployees(this.employees, this.filterEmployeesCurrent);
    },
    openContextMenu(event: MouseEvent, employee: Employee) {
      this.employee = employee;
      event.preventDefault();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuWidth = 200;
      const menuHeight = 180;

      let xPos = event.clientX;
      let yPos = event.clientY;

      if (xPos + menuWidth > windowWidth) {
        xPos = windowWidth - menuWidth;
      }

      if (yPos + menuHeight > windowHeight) {
        yPos = windowHeight - menuHeight;
      }

      if (xPos < 0) {
        xPos = 0;
      }
      this.contextMenuPosition = { x: xPos, y: yPos };
      this.showContextMenu = true;
    },
    openCandidateToEmployeeModal() {
      this.showDialogCandidateToEmployee = true;
    },
    openAddEmployeeDialog() {
      const dialog = {
        title: "",
        type: "" as ModalMode,
        input: "",
        inputLabel: "Personalnummer",
        actionButtonText: "",
      };
      if (this.softwareIntegration?.zvooveOne) {
        dialog.title = "Personaldaten in ERP One suchen";
        dialog.type = ModalMode.zvooveOne;
        dialog.actionButtonText = "In ERP One suchen";
      } else if (this.softwareIntegration?.pdHub) {
        dialog.title = "Personaldaten in PD-Hub suchen";
        dialog.type = ModalMode.zvooveOne;
        dialog.actionButtonText = "In PD-Hub suchen";
      } else {
        return;
      }

      if (this.$refs.dialogAddEmployeeComponent) {
        (
          this.$refs.dialogAddEmployeeComponent as InstanceType<
            typeof DialogAddEmployee
          >
        ).openModal(dialog);
      }
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.selectedMandants && this.loggedInMandantUuids) {
        if (
          this.user?.config?.dispatcherBoard?.columnEmployee?.filterMandants
            ?.length > 0
        ) {
          // Do not set if other filters are set
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.branchNumber)
          .filter(
            (branchNumber): branchNumber is string => branchNumber !== undefined
          );
      }
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
    async updateAllEmployees(methode: AllItemUpdate) {
      if (this.employeeItemRefs.length === 0) {
        console.debug("Keine Employee Items gefunden");
        return;
      }

      SpinnerService.showSpinner();
      for (const employeeItem of this.employeeItemRefs) {
        if (employeeItem && employeeItem.allItemUpdate) {
          try {
            await employeeItem.allItemUpdate(methode);
          } catch (error) {
            console.error(
              `Fehler bei der Verarbeitung von Mitarbeiter ${employeeItem.employee?.employeeNumber}:`,
              error
            );
          }
        }
      }
      SpinnerService.removeSpinner();
    },
    updateStatusFilter: debounce(function (this: any, newValue: string[]) {
      this.loadEmployees();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnEmployee,
          property: UserConfigFilterType.filterStatus,
          value: newValue,
          validArray: Object.keys(this.employeeStates),
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
    }, 1500),
    updateMandantsFilter: debounce(function (this: any, newValue: string[]) {
      this.loadEmployees();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnEmployee,
          property: UserConfigFilterType.filterMandants,
          value: newValue,
          validArray: this.$store.getters.mandantUuids,
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
    }, 1500),
    updateItemsOrder: debounce(function (this: any, newValue: FilterEmployees) {
      this.filterEmployees(this.employees, newValue);
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnEmployee,
          property: UserConfigFilterType.orderDraggable,
          value: [newValue],
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
    }, 1500),
  },
});
</script>
