import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.show,
    showActions: true,
    onCloseDialog: _ctx.handleAbort
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.promptQuestion) + " ", 1),
          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            label: _ctx.inputLabelText,
            class: "mt-4"
          }), null, 16, ["modelValue", "label"])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: _ctx.btnAbortColor,
        onClick: _ctx.handleAbort
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnAbortText), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"]),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        color: _ctx.btnConfirmColor,
        onClick: _ctx.handleConfirm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnConfirmText), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"])
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}