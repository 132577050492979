import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ProfileEditor = _resolveComponent("ProfileEditor")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("fa-solid fa-id-badge")
            ])),
            _: 1
          }),
          _cache[23] || (_cache[23] = _createTextVNode("Profilvorlage "))
        ]),
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                                    _createTextVNode("Logos & Links")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Link zu Logo hell",
                                  modelValue: _ctx.localConfig.profileTemplate.logo,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.profileTemplate.logo) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Link zu Logo dunkel",
                                  modelValue: _ctx.localConfig.profileTemplate.logoDark,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localConfig.profileTemplate.logoDark) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Button 'weitere Kandidaten' Text",
                                  modelValue: _ctx.localConfig.profileTemplate.candidatesButtonText,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localConfig.profileTemplate.candidatesButtonText) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Button Link",
                                  modelValue: _ctx.localConfig.profileTemplate.candidatesButtonLink,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localConfig.profileTemplate.candidatesButtonLink) = $event))
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                                    _createTextVNode("Überschriften linker Streifen")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Titel Kandidatendaten",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.background,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.background) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Titel Schichtbereitschaft",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.shiftReadiness,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.shiftReadiness) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Titel Führerscheine",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.licences,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.licences) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Titel Kontakt",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.contact,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.contact) = $event))
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                                    _createTextVNode("Überschriften Hauptteil")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Skills",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.skills,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.skills) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Schul- und Ausbildung",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.education,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.education) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Werdegang",
                                  modelValue: _ctx.localConfig.profileTemplate.titles.cv,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.cv) = $event))
                                }), null, 16, ["modelValue"]),
                                _createVNode(_component_v_card_subtitle, null, {
                                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createTextVNode("(Nur für nicht-AI-Profile)")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_title, { class: "mb-10" }, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode("Aufteilung der Seite")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_range_slider, {
                          modelValue: _ctx.widthTblMid,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.widthTblMid) = $event)),
                          step: "1",
                          "thumb-label": "always"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode("UberAdmin Profilgenerator Config")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "7"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Markdown Template",
                                  rows: "17",
                                  modelValue: 
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateMarkdownTemplate
                  ,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateMarkdownTemplate
                  ) = $event))
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "5"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Allgemeine Anweisungen",
                                  rows: "3",
                                  modelValue: _ctx.localConfig.aiData.prompt.company.profileCandidate,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localConfig.aiData.prompt.company.profileCandidate) = $event))
                                }), null, 16, ["modelValue"]),
                                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "profile-template-prompts-textarea-spacer" }, null, -1)),
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Regeln für Beschreibung",
                                  rows: "3",
                                  modelValue: 
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateDescriptionRules
                  ,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateDescriptionRules
                  ) = $event))
                                }), null, 16, ["modelValue"]),
                                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "profile-template-prompts-textarea-spacer" }, null, -1)),
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Regeln für Überschrift",
                                  rows: "3",
                                  modelValue: 
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateHeadlineRules
                  ,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateHeadlineRules
                  ) = $event))
                                }), null, 16, ["modelValue"]),
                                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "profile-template-prompts-textarea-spacer" }, null, -1)),
                                _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                                  density: "compact",
                                  label: "Regeln für Sprachkenntnisse",
                                  rows: "3",
                                  modelValue: 
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateLanguageRules
                  ,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((
                    _ctx.localConfig.aiData.prompt.company
                      .profileCandidateLanguageRules
                  ) = $event))
                                }), null, 16, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[33] || (_cache[33] = [
                            _createTextVNode("Primärfarbe")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_color_picker, {
                          modelValue: _ctx.localConfig.profileTemplate.colorPrimary,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localConfig.profileTemplate.colorPrimary) = $event)),
                          modes: ['hex'],
                          elevation: "0"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode("Sekundärfarbe")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_color_picker, {
                          modelValue: _ctx.localConfig.profileTemplate.colorSecondary,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localConfig.profileTemplate.colorSecondary) = $event)),
                          modes: ['hex'],
                          elevation: "0"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode("linker Streifen")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_color_picker, {
                          modelValue: _ctx.localConfig.profileTemplate.background,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.localConfig.profileTemplate.background) = $event)),
                          modes: ['hex'],
                          elevation: "0"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "mb-4 px-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[36] || (_cache[36] = [
                            _createTextVNode("Schriftfarbe Hell")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_color_picker, {
                          modelValue: _ctx.localConfig.profileTemplate.fontLight,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.localConfig.profileTemplate.fontLight) = $event)),
                          modes: ['hex'],
                          elevation: "0"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }),
    (_ctx.showProfileEditorPreview)
      ? (_openBlock(), _createBlock(_component_BaseDialog, {
          key: 0,
          rounded: _ctx.vStyle?.card?.rounded,
          color: "secondary",
          showDialog: _ctx.showProfileEditorPreview,
          width: 'var(--d-w-xl)',
          onCloseDialog: _cache[21] || (_cache[21] = ($event: any) => (_ctx.closeProfileEditorPreview()))
        }, {
          content: _withCtx(() => [
            _createVNode(_component_ProfileEditor)
          ]),
          _: 1
        }, 8, ["rounded", "showDialog"]))
      : _createCommentVNode("", true),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 1,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, {
      variant: "tonal",
      class: "pa-1",
      style: {"position":"fixed","top":"14rem","right":"0.5rem","z-index":"1000"},
      onClick: _ctx.previewProfileTemplate
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => _cache[37] || (_cache[37] = [
            _createTextVNode("fa-solid fa-eye")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tooltip, {
          location: "top",
          activator: "parent"
        }, {
          default: _withCtx(() => _cache[38] || (_cache[38] = [
            _createTextVNode(" Profilvorlage Vorschau ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}