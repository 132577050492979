import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "email-buttons" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_EmailServerConfigForm = _resolveComponent("EmailServerConfigForm")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_OfficeIntBtn = _resolveComponent("OfficeIntBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.office365AccessToken && !_ctx.mailServer)
      ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, _ctx.vStyle.btn, {
          class: "ml-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMailserverConfig())),
          width: "250"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("fas fa-plus")
              ])),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createTextVNode("SMTP Mailserver"))
          ]),
          _: 1
        }, 16))
      : _createCommentVNode("", true),
    (_ctx.mailServer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_EmailServerConfigForm, {
            modelValue: _ctx.mailServer,
            onTestResult: _ctx.emailValidationResult
          }, null, 8, ["modelValue", "onTestResult"]),
          _createVNode(_component_v_btn, {
            icon: "",
            variant: "text",
            onClick: _ctx.removeMailserverConfig
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("fa-solid fa-xmark")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.mailServer && !_ctx.office365AccessToken)
      ? (_openBlock(), _createBlock(_component_v_divider, {
          key: 2,
          class: "my-5"
        }))
      : _createCommentVNode("", true),
    (!_ctx.mailServer)
      ? (_openBlock(), _createBlock(_component_OfficeIntBtn, {
          key: 3,
          class: "ml-2",
          width: 250,
          emailScope: _ctx.emailScope,
          mandantUuid: _ctx.uuid,
          isExistingAccessToken: _ctx.office365AccessToken,
          onLogout: _ctx.office365Logout
        }, null, 8, ["emailScope", "mandantUuid", "isExistingAccessToken", "onLogout"]))
      : _createCommentVNode("", true)
  ]))
}