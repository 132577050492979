import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, _mergeProps({ style: {"position":"fixed","bottom":"0.5rem","right":"0.5rem","width":"12rem"} }, _ctx.vStyle.card, {
    variant: "flat",
    class: "pa-2 d-flex justify-space-around"
  }), {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
        type: "submit",
        color: "success",
        onClick: _ctx.submit,
        block: ""
      }), {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode("Speichern")),
          _createVNode(_component_v_icon, { class: "ml-2" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("fa-solid fa-floppy-disk")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 16, ["onClick"])
    ]),
    _: 1
  }, 16))
}