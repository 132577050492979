export enum MutationType {
  addToDialogQueue = "ADD_TO_DIALOG_QUEUE",
  addLinkings = "ADD_LINKINGS",
  addPerfRecentEntries = "ADD_PERF_RECENT_ENTRIES",
  addPostcodeCandidates = "ADD_POSTCODE_CANDIDATES",
  addReminder = "ADD_REMINDER",
  addUserPerfInsight = "ADD_USER_PERF_INSIGHT",
  addToCustomerList = "ADD_TO_CUSTOMER_LIST",
  clearDraggingItem = "CLEAR_DRAGGING_ITEM",
  clearHeaderSpinnerElement = "CLEAR_HEADER_SPINNER_ELEMENT",
  clearICM = "CLEAR_INTER_COMPONENT_MESSAGE",
  clearPostcodesCandidates = "CLEAR_POSTCODES_CANDIDATES",
  clearReminderBySource = "CLEAR_REMINDER_BY_SOURCE",
  deleteCandidateAddressForCommuteWebSearch = "DELETE_CANDIDATE_ADDRESS_FOR_COMMUTE_WEB_SEARCH",
  deleteLinking = "DELETE_LINKING",
  deleteWorktimePrompt = "DELETE_WORKTIME_PROMPT",
  editWorktimePrompt = "EDIT_WORKTIME_PROMPT",
  incrementPostedJobAds = "INCREMENT_POSTED_JOB_ADS",
  postedJobAds = "POSTED_JOB_ADS",
  removeFromDialogQueue = "REMOVE_FROM_DIALOG_QUEUE",
  resetJobPoster = "RESET_JOBPOSTER",
  resetPostedJobAds = "RESET_POSTED_JOB_ADS",
  resetStore = "RESET_STORE",
  setActiveRequests = "SET_ACTIVE_REQUESTS",
  setCandidateAddressForCommuteWebSearch = "SET_CANDIDATE_ADDRESS_FOR_COMMUTE_WEB_SEARCH",
  setCompanyConfig = "SET_COMPANY_CONFIG",
  setConfig = "SET_CONFIG",
  setDraggingItem = "SET_DRAGGING_ITEM",
  setDragOriginComponent = "SET_DRAG_ORIGIN_COMPONENT",
  setHeaderSpinnerElement = "SET_HEADER_SPINNER_ELEMENT",
  setICM = "SET_INTER_COMPONENT_MESSAGE",
  setJobAdPublishHomepage = "SET_JOB_AD_PUBLISH_HOMEPAGE",
  setJobAdPublishOnBfa = "SET_JOB_AD_PUBLISH_ON_BFA",
  setLastPhoneCallFrom = "SET_LAST_PHONE_CALL_FROM",
  setLastUpdateErpData = "SET_LAST_UPDATE_ERPDATA",
  setLastUpdateTimeline = "SET_LAST_UPDATE_TIMELINE",
  setMandants = "SET_MANDANTS",
  setPostcodeCustomers = "SET_POSTCODE_CUSTOMERS",
  setProfileTemplate = "SET_PROFILE_TEMPLATE",
  setRecurionChat = "SET_RECURION_CHAT",
  setRecurionChatActive = "SET_RECURION_CHAT_ACTIVE",
  setRecurionChatClosed = "SET_RECURION_CHAT_CLOSED",
  setRecurionChatInactive = "SET_RECURION_CHAT_INACTIVE",
  setRecurionChatOpen = "SET_RECURION_CHAT_OPEN",
  setScoreboardActive = "SET_SCOREBOARD_ACTIVE",
  setStatus = "SET_STATUS",
  setStoreHasToInit = "SET_STORE_HAS_TO_INIT",
  setTotalJobAds = "SET_TOTAL_JOB_ADS",
  setUser = "SET_USER",
  setUserConfig = "SET_USER_CONFIG",
  setUserDashboardAnalysis = "SET_USER_DASHBOARD_ANALYSIS",
  setUserPerfInsights = "SET_USER_PERF_INSIGHTS",
  setUserProfile = "SET_USER_PROFILE",
  setVStyle = "SET_VSTYLE",
  setWhatsapp = "SET_WHATSAPP",
  setWorktimePrompt = "SET_WORKTIME_PROMPT",
  setZvooveCustomerList = "SET_ZVOOVE_CUSTOMER_LIST",
  setZvooveCustomers = "SET_ZVOOVE_CUSTOMERS",
  setZvooveDashboardData = "SET_ZVOOVE_DASHBOARD_DATA",
  setZvooveEmployeeList = "SET_ZVOOVE_EMPLOYEE_LIST",
  setZvooveEmployees = "SET_ZVOOVE_EMPLOYEES",
  togglePostingIsCancelled = "TOGGLE_POSTING_IS_CANCELLED",
  togglePostingIsPaused = "TOGGLE_POSTING_IS_PAUSED",
  toggleRecurionMenu = "TOGGLE_RECURION_MENU",
  toggleZorstDebugging = "TOGGLE_ZORST_DEBUGGING",
  updateCompanyConfig = "UPDATE_COMPANY_CONFIG",
  updateDispatcherBoardFilter = "UPDATE_DISPATCHER_BOARD_FILTER",
  updateLastAutoUpdateFromErp = "UPDATE_LAST_AUTO_UPDATE_FROM_ERP",
  updateLoggedinMandants = "UPDATE_LOGGEDIN_MANDANTS",
  updatePartialCompanyConfig = "UPDATE_PARTIAL_COMPANY_CONFIG",
}

export enum ActionType {
  addCustomerReducedToCustomerList = "addCustomerReducedToCustomerList",
  loadConfig = "loadConfig",
  loadCustomerAndEmployeeBackgroundData = "loadCustomerAndEmployeeBackgroundData",
  loadLinkingsAndAddMissingDescriptions = "loadLinkingsAndAddMissingDescriptions",
  loadStoreInitialData = "loadStoreInitialData",
  updateDispatcherBoardFilter = "updateDispatcherBoardFilter",
  updateLastUpdateTimeline = "updateLastUpdateTimeline",
}
