import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between text-subtitle-1 mb-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_externalSoftwareLinksMenu = _resolveComponent("externalSoftwareLinksMenu")!
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_menu, null, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps(props, {
          icon: "",
          class: "communication-container-icon",
          variant: "text",
          density: "compact"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { size: "xsmall" }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("fa-solid fa-phone")
              ])),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Telefonat starten")
              ])),
              _: 1
            })
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
          default: _withCtx(() => [
            _createVNode(_component_v_card_subtitle, { class: "my-1" }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Telefon")
              ])),
              _: 1
            }),
            _createVNode(_component_v_list, { dense: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, { onClick: _ctx.handleOutgoingCall }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "text-medium-emphasis" }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("fas fa-phone")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis mr-3 mb-3",
                      size: "xsmall"
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("fas fa-right-long")
                      ])),
                      _: 1
                    }),
                    _cache[19] || (_cache[19] = _createTextVNode("Ausgehend"))
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_list_item, { onClick: _ctx.handleIncomingCall }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "text-medium-emphasis mr-1" }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("fas fa-phone-volume")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis mr-2 mb-3",
                      size: "xsmall"
                    }, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createTextVNode("fas fa-left-long")
                      ])),
                      _: 1
                    }),
                    _cache[22] || (_cache[22] = _createTextVNode("Eingehend"))
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16)
      ]),
      _: 1
    }),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showSelectNummerDialog,
      showActions: true,
      width: 'var(--d-w-xs)',
      onCloseDialog: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSelectNummerDialog = false))
    }, {
      title: _withCtx(() => _cache[23] || (_cache[23] = [
        _createTextVNode("Wähle eine Nummer")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneNumbers, (phoneNumber, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.dialNumber(phoneNumber)),
                    title: phoneNumber.label,
                    subtitle: phoneNumber.value
                  }, null, 8, ["onClick", "title", "subtitle"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelectNummerDialog = false))
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [
            _createTextVNode("Schließen")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["showDialog"]),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showSelectContactDialog,
      persistent: true,
      width: 'var(--d-w-xs)',
      onCloseDialog: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSelectContactDialog = false))
    }, {
      title: _withCtx(() => _cache[25] || (_cache[25] = [
        _createTextVNode("Wähle einen Ansprechpartner")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneNumbers, (phoneNumber, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.selectContact(phoneNumber)),
                    subtitle: phoneNumber.label
                  }, null, 8, ["onClick", "subtitle"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSelectContactDialog = false))
        }, {
          default: _withCtx(() => _cache[26] || (_cache[26] = [
            _createTextVNode("Schließen")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["showDialog"]),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showResultDialog,
      persistent: true,
      width: 'var(--d-w-xxs)'
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.isIncoming ? "Eingehendes Telefonat" : "Teilnehmer erreicht?") + " ", 1),
        _createVNode(_component_v_spacer)
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.candidate)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.candidateName), 1))
                : _createCommentVNode("", true),
              (_ctx.customer)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selectedPhoneNumber?.label), 1))
                : _createCommentVNode("", true),
              (_ctx.employee)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.employee?.firstName) + " " + _toDisplayString(_ctx.employee?.lastName), 1))
                : _createCommentVNode("", true),
              (_ctx.candidate)
                ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                    key: 3,
                    atsRecruitUuid: _ctx.candidate?.candidateUuid,
                    hideUpdateMenuPoint: true
                  }, null, 8, ["atsRecruitUuid"]))
                : (_ctx.employee)
                  ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                      key: 4,
                      erpEmployeeNumber: _ctx.employee?.employeeNumber,
                      hideUpdateMenuPoint: true
                    }, null, 8, ["erpEmployeeNumber"]))
                  : (_ctx.customer)
                    ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                        key: 5,
                        erpCustomerNumber: _ctx.customer?.customerNumber,
                        hideUpdateMenuPoint: true
                      }, null, 8, ["erpCustomerNumber"]))
                    : _createCommentVNode("", true)
            ]),
            _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
              variant: "outlined",
              block: "",
              color: "primary",
              class: "mb-1",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.callEstablished()))
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isIncoming ? "Notiz schreiben" : "Erreicht?"), 1)
              ]),
              _: 1
            }, 16),
            (_ctx.customer || _ctx.candidate)
              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, _ctx.vStyle.btn, {
                  variant: "outlined",
                  block: "",
                  color: "primary",
                  class: "mb-1",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setAppointment()))
                }), {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode("Termin / Ergebnis")
                  ])),
                  _: 1
                }, 16))
              : _createCommentVNode("", true),
            (!_ctx.isIncoming)
              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 1 }, _ctx.vStyle.btn, {
                  variant: "outlined",
                  block: "",
                  color: "primary",
                  class: "mb-1",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeModalNotReached(_ctx.PhoneCallOption.notReached)))
                }), {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode("Nicht erreicht")
                  ])),
                  _: 1
                }, 16))
              : _createCommentVNode("", true),
            (_ctx.customer || _ctx.candidate)
              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 2 }, _ctx.vStyle.btn, {
                  variant: "text",
                  block: "",
                  color: "primary",
                  class: "mt-5",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addFollowUp()))
                }), {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createTextVNode("Wiedervorlage")
                  ])),
                  _: 1
                }, 16))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["showDialog"]),
    _createVNode(_component_BaseDialog, {
      showDialog: _ctx.showNoteDialog,
      showActions: true,
      persistent: true,
      width: 'var(--d-w-s)'
    }, {
      title: _withCtx(() => _cache[30] || (_cache[30] = [
        _createTextVNode("Notiz zum Telefonat")
      ])),
      content: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
              modelValue: _ctx.callNote,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.callNote) = $event)),
              label: "Notiz"
            }), null, 16, ["modelValue"]),
            (_ctx.customer)
              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 0 }, _ctx.vStyle.btn, {
                  variant: "outlined",
                  block: "",
                  class: "mb-2",
                  density: "compact",
                  color: "primary",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.addDemand()))
                }), {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createTextVNode("Anfrage anlegen")
                  ])),
                  _: 1
                }, 16))
              : _createCommentVNode("", true),
            (_ctx.customer || _ctx.candidate)
              ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ key: 1 }, _ctx.vStyle.btn, {
                  variant: "outlined",
                  block: "",
                  density: "compact",
                  color: "primary",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addFollowUp()))
                }), {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createTextVNode("Wiedervorlage")
                  ])),
                  _: 1
                }, 16))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "primary",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.backToResultModal()))
        }, {
          default: _withCtx(() => _cache[33] || (_cache[33] = [
            _createTextVNode("zurück")
          ])),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_btn, {
          color: "success",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.submitNote(true)))
        }, {
          default: _withCtx(() => _cache[34] || (_cache[34] = [
            _createTextVNode("Speichern + zurück")
          ])),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          color: "success",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.submitNote()))
        }, {
          default: _withCtx(() => _cache[35] || (_cache[35] = [
            _createTextVNode("OK")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["showDialog"])
  ], 64))
}