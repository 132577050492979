<template>
  <AdminMenu />
  <AppHeader
    v-if="layout !== AppLayout.classic && layout !== AppLayout.persontobusiness"
  ></AppHeader>
  <v-container :fluid="true">
    <div class="admin-menu" />
    <v-row>
      <v-col v-if="hasSoftwareIntegrationAccess" cols="12" md="4">
        <v-img
          class="mb-5"
          :width="142"
          aspect-ratio="1/1"
          cover
          :src="settingsImage"
        >
          <v-menu v-if="isUberAdmin" activator="parent">
            <v-card>
              <v-card-text>
                <v-radio-group
                  v-model="companyConfigModel.appLayout"
                  label="App Layout auswählen"
                  @update:model-value="
                    setCompanyAppLayout(companyConfigModel.appLayout)
                  "
                >
                  <v-radio
                    v-for="option in appLayoutOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  ></v-radio>
                </v-radio-group>
                <v-switch
                  color="primary"
                  label="header-logo"
                  v-model="$store.state.headerLogo"
                />
              </v-card-text> </v-card></v-menu
        ></v-img>
        <v-card v-bind="vStyle.card">
          <v-form class="pa-5">
            <v-card-title>Unternehmensdaten</v-card-title>
            <v-text-field
              v-bind="vStyle.input"
              v-model="companyConfigModel.name"
              @update:model-value="setSubmitButtonTrue"
              label="Unternehmensname"
            ></v-text-field>
            <v-text-field
              v-bind="vStyle.input"
              @update:model-value="setSubmitButtonTrue"
              v-model="companyConfigModel.domain"
              label="Domain"
            ></v-text-field>
            <v-divider></v-divider>
            <v-card-title>Software Integration</v-card-title>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.dashboardAiKpiAnalysis)"
              noLink
              :label="'AI-Kennzahlenanalyse auf dem Dashboard'"
              :status="
                companyConfigModel?.softwareIntegration?.dashboardAiKpiAnalysis
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.dashboardAiKpiAnalysis)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.zvooveRecruit)"
              :label="'ATS: Recruit'"
              :status="companyConfigModel?.softwareIntegration?.zvooveRecruit"
              @labelClick="
                handleLabelClick(SoftwareIntegrationType.zvooveRecruit)
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.zvooveRecruit)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.zvooveOne)"
              :label="'ERP: Universalschnittstelle (experimentell)'"
              :status="companyConfigModel?.softwareIntegration?.zvooveOne"
              @labelClick="handleLabelClick(SoftwareIntegrationType.zvooveOne)"
              @handleChange="handleChange(SoftwareIntegrationType.zvooveOne)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.pdHub)"
              :label="'ERP: PD-Hub'"
              :status="companyConfigModel?.softwareIntegration?.pdHub"
              @labelClick="handleLabelClick(SoftwareIntegrationType.pdHub)"
              @handleChange="handleChange(SoftwareIntegrationType.pdHub)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.gameBar)"
              :status="companyConfigModel?.softwareIntegration?.gameBar"
              :label="'Gamification (Scorebar für Aktivitäten)'"
              @handleChange="handleChange(SoftwareIntegrationType.gameBar)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.indexAnzeigendaten)"
              :status="
                companyConfigModel?.softwareIntegration?.indexAnzeigendaten
              "
              :label="'INDEX Anzeigendaten'"
              @labelClick="
                handleLabelClick(SoftwareIntegrationType.indexAnzeigendaten)
              "
              @handleChange="
                handleChange(SoftwareIntegrationType.indexAnzeigendaten)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="
                checkAccess(SoftwareIntegrationType.jobListAutoBfaSupportedAd)
              "
              :status="
                companyConfigModel?.softwareIntegration
                  ?.jobListAutoBfaSupportedAd
              "
              :label="'JobList Anzeigen automatisch in BA-Betreuung'"
              @handleChange="
                handleChange(SoftwareIntegrationType.jobListAutoBfaSupportedAd)
              "
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.payFlow)"
              :status="companyConfigModel?.softwareIntegration?.payFlow"
              :label="'PayFlow'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.payFlow)"
              @handleChange="handleChange(SoftwareIntegrationType.payFlow)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.whatsApp)"
              :label="'WhatsApp Integration'"
              :status="companyConfigModel?.softwareIntegration?.whatsApp"
              @labelClick="handleLabelClick(SoftwareIntegrationType.whatsApp)"
              @handleChange="handleChange(SoftwareIntegrationType.whatsApp)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.workTime)"
              :status="companyConfigModel?.softwareIntegration?.workTime"
              :label="'WorkTime Stundenerfassung'"
              @handleChange="handleChange(SoftwareIntegrationType.workTime)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.wordPress)"
              :status="companyConfigModel?.softwareIntegration?.wordPressPlugin"
              :label="'WordPress Plugin auf Unternehmensseite'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.wordPress)"
              @handleChange="handleChange(SoftwareIntegrationType.wordPress)"
            ></ClickableSwitch>
            <ClickableSwitch
              v-if="checkAccess(SoftwareIntegrationType.zorst)"
              :status="companyConfigModel?.softwareIntegration?.zorst"
              :label="'ZORST Chrome Erweiterung'"
              @labelClick="handleLabelClick(SoftwareIntegrationType.zorst)"
              @handleChange="handleChange(SoftwareIntegrationType.zorst)"
            ></ClickableSwitch>
          </v-form>
          <ConfigSaveButton
            v-if="isSubmitButtonActive"
            @save="updateCompanyConfig"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-menu v-if="hasSoftwareIntegrationAccess">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="{ ...vStyle.btn, ...props }">
                <v-icon>fa-solid fa-bars</v-icon>Einstellungen
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in filteredItems"
                :key="index"
                @click="setActiveComponent(item.component)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <component
          :is="activeConfigComponent"
          v-model="companyConfigModel"
          @save="updateCompanyConfig"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { CompanyConfig } from "@/models/company-config.model";
import { CompanyConfigComponentTypes } from "@/enums/company-config-components.enum";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { defineComponent } from "vue";
import { SoftwareIntegrationType } from "@/enums/softwareintegration-type.enum";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import ClickableSwitch from "./ClickableSwitch.vue";
import ConfigEmail from "./config/ConfigEmail.vue";
import ConfigIndexAnzeigendaten from "./config/ConfigIndexAnzeigendaten.vue";
import ConfigPayFlow from "./config/ConfigPayFlow.vue";
import ConfigPdHub from "./config/ConfigPdHub.vue";
import ConfigProfileTemplate from "./config/ConfigProfileTemplate.vue";
import ConfigReminderSettings from "./config/ConfigReminderSettings.vue";
import ConfigStatusColor from "./config/ConfigStatusColor.vue";
import ConfigTimelineEntries from "./config/ConfigTimelineEntries.vue";
import ConfigWhatsApp from "./config/ConfigWhatsApp.vue";
import ConfigWordPressPlugin from "./config/ConfigWordPressPlugin.vue";
import ConfigZorst from "./config/ConfigZorst.vue";
import ConfigZvooveOne from "./config/ConfigZvooveOne.vue";
import ConfigZvooveRecruit from "./config/ConfigZvooveRecruit.vue";
import ToastService from "@/services/toast.service";
import AppHeader from "@/components/header/Header.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import { getEnumOptions } from "../../../helper/enum.helper";
import { MutationType } from "../../../enums/vuex-types.enum";
import { UserRole } from "../../../enums/user-role.enum";
import ConfigSaveButton from "../elements/ConfigSaveButton.vue";
import { UserRoleHelper } from "../../../helper/user-role.helper";
import { mapGetters } from "vuex";
import { AccessRule } from "../../../enums/access-rule.enum";

export default defineComponent({
  name: "ConfigApi",
  components: {
    AdminMenu,
    ClickableSwitch,
    ConfigEmail,
    ConfigIndexAnzeigendaten,
    ConfigPdHub,
    ConfigPayFlow,
    ConfigProfileTemplate,
    ConfigReminderSettings,
    ConfigStatusColor,
    ConfigTimelineEntries,
    ConfigWhatsApp,
    ConfigWordPressPlugin,
    ConfigZorst,
    ConfigZvooveOne,
    ConfigZvooveRecruit,
    AppHeader,
    ConfigSaveButton,
  },
  data() {
    return {
      AppLayout: AppLayout,
      appLayoutOptions: getEnumOptions(AppLayout),
      layout: this.$store.getters.appLayout,
      apiService: new CompanyConfigService(),
      activeConfigComponent: CompanyConfigComponentTypes.ConfigZorst,
      companyConfigModel: {} as CompanyConfig,
      isSubmitButtonActive: false,
      isUberAdmin: UserRoleHelper.isUberAdmin(this.$store.getters.user),
      menuItems: [
        {
          title: "E-Mail",
          component: CompanyConfigComponentTypes.ConfigEmail,
          accessRule: AccessRule.configEmail,
        },
        {
          title: "Profil",
          component: CompanyConfigComponentTypes.ConfigProfileTemplate,
          accessRule: AccessRule.configProfileTemplate,
        },
        {
          title: "Erinnerungen/Deadlines",
          component: CompanyConfigComponentTypes.ConfigReminderSettings,
          accessRule: AccessRule.configReminderSettings,
        },
        {
          title: "Farben Kanbankarten",
          component: CompanyConfigComponentTypes.ConfigStatusColor,
          accessRule: AccessRule.configStatusColor,
        },
        {
          title: "Kontaktarten",
          component: CompanyConfigComponentTypes.ConfigTimelineEntries,
          accessRule: AccessRule.configTimelineEntries,
        },
      ],
      settingsImage: `${this.$store.getters.getEnv.assetsUrl}/app-layout/${this.$store.getters.appLayout}/settings.svg`,
      UserRole,
      vStyle: this.$store.state.vStyle,
      zvooverecruitstatus: [
        "neu",
        "terminiert",
        "pruefung",
        "vermittlung",
        "pool",
        "absage",
        "inaktiv",
      ],
      CompanyConfigComponentTypes: CompanyConfigComponentTypes,
      SoftwareIntegrationType,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    filteredItems() {
      return this.menuItems.filter((item) =>
        this.user.role.permissions.includes(item.accessRule)
      );
    },
    hasSoftwareIntegrationAccess() {
      return UserRoleHelper.hasAccess(
        this.user,
        AccessRule.softwareintegration
      );
    },
  },
  created() {
    this.apiService.getModel().then((companyConfig: CompanyConfig) => {
      this.companyConfigModel = companyConfig;
      this.syncSoftwareIntegration();
      this.syncApiKeys();
    });
  },
  mounted() {
    if (this.$store.state.company.softwareIntegration.zorst)
      this.activeConfigComponent = CompanyConfigComponentTypes.ConfigZorst;
  },
  methods: {
    checkAccess(softwareIntegrationType: SoftwareIntegrationType): boolean {
      const user = this.$store.getters.user;
      switch (softwareIntegrationType) {
        case SoftwareIntegrationType.dashboardAiKpiAnalysis:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiKpi)) return true;
          break;
        case SoftwareIntegrationType.jobListAutoBfaSupportedAd:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiBfa)) return true;
          break;
        case SoftwareIntegrationType.zvooveRecruit:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiZvooveRecruit))
            return true;
          break;
        case SoftwareIntegrationType.zvooveOne:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiZvooveOne))
            return true;
          break;
        case SoftwareIntegrationType.gameBar:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiGamebar))
            return true;
          break;
        case SoftwareIntegrationType.payFlow:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiPayFlow))
            return true;
          break;
        case SoftwareIntegrationType.pdHub:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiPdHub)) return true;
          break;
        case SoftwareIntegrationType.indexAnzeigendaten:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiIndexAnz))
            return true;
          break;
        case SoftwareIntegrationType.whatsApp:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiWhatsapp))
            return true;
          break;
        case SoftwareIntegrationType.workTime:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiWorktime))
            return true;
          break;
        case SoftwareIntegrationType.wordPress:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiWordpress))
            return true;
          break;
        case SoftwareIntegrationType.zorst:
          if (UserRoleHelper.hasAccess(user, AccessRule.swiZorst)) return true;
      }
      return false;
    },
    setSubmitButtonTrue() {
      this.isSubmitButtonActive = true;
    },
    handleChange(softwareIntegrationType: SoftwareIntegrationType) {
      this.setSubmitButtonTrue();
      switch (softwareIntegrationType) {
        case SoftwareIntegrationType.dashboardAiKpiAnalysis:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(
            SoftwareIntegrationType.dashboardAiKpiAnalysis
          );
          break;
        case SoftwareIntegrationType.jobListAutoBfaSupportedAd:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(
            SoftwareIntegrationType.jobListAutoBfaSupportedAd
          );
          break;
        case SoftwareIntegrationType.zvooveRecruit:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.zvooveRecruit);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZvooveRecruit
          );
          break;
        case SoftwareIntegrationType.zvooveOne:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.zvooveOne);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveOne,
            CompanyConfigComponentTypes.ConfigZvooveOne
          );
          this.toggleErp(SoftwareIntegrationType.zvooveOne);
          break;
        case SoftwareIntegrationType.gameBar:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.gameBar);
          break;
        case SoftwareIntegrationType.payFlow:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.payFlow);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.payFlow,
            CompanyConfigComponentTypes.ConfigPayFlow
          );
          break;
        case SoftwareIntegrationType.pdHub:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.pdHub);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.pdHub,
            CompanyConfigComponentTypes.ConfigPdHub
          );
          this.toggleErp(SoftwareIntegrationType.pdHub);
          break;
        case SoftwareIntegrationType.indexAnzeigendaten:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.indexAnzeigendaten);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.indexAnzeigendaten,
            CompanyConfigComponentTypes.ConfigIndexAnzeigendaten
          );
          break;
        case SoftwareIntegrationType.whatsApp:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.whatsApp);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.whatsApp,
            CompanyConfigComponentTypes.ConfigWhatsApp
          );
          break;
        case SoftwareIntegrationType.workTime:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.workTime);
          break;
        case SoftwareIntegrationType.wordPress:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.wordPress);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.wordPressPlugin,
            CompanyConfigComponentTypes.ConfigWordPressPlugin
          );
          break;
        case SoftwareIntegrationType.zorst:
          if (!this.checkAccess(softwareIntegrationType)) break;
          this.toggleStoreVariable(SoftwareIntegrationType.zorst);
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZorst
          );
          break;
      }
    },
    handleLabelClick(softwareIntegrationType: SoftwareIntegrationType) {
      switch (softwareIntegrationType) {
        case SoftwareIntegrationType.indexAnzeigendaten:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.indexAnzeigendaten,
            CompanyConfigComponentTypes.ConfigIndexAnzeigendaten
          );
          break;
        case SoftwareIntegrationType.payFlow:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.payFlow,
            CompanyConfigComponentTypes.ConfigPayFlow
          );
          break;
        case SoftwareIntegrationType.pdHub:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.pdHub,
            CompanyConfigComponentTypes.ConfigPdHub
          );
          break;
        case SoftwareIntegrationType.whatsApp:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.whatsApp,
            CompanyConfigComponentTypes.ConfigWhatsApp
          );
          break;
        case SoftwareIntegrationType.wordPress:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.wordPressPlugin,
            CompanyConfigComponentTypes.ConfigWordPressPlugin
          );
          break;
        case SoftwareIntegrationType.zorst:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZorst
          );
          break;
        case SoftwareIntegrationType.zvooveRecruit:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveRecruit,
            CompanyConfigComponentTypes.ConfigZvooveRecruit
          );
          break;
        case SoftwareIntegrationType.zvooveOne:
          this.openComponent(
            this.companyConfigModel?.softwareIntegration?.zvooveOne,
            CompanyConfigComponentTypes.ConfigZvooveOne
          );
          break;
      }
    },

    setActiveComponent(component: CompanyConfigComponentTypes) {
      this.activeConfigComponent = component;
    },
    setCompanyAppLayout(appLayout: AppLayout | undefined) {
      if (appLayout) {
        this.updateCompanyConfig();
      }
    },
    openComponent(isOpen: boolean, component: CompanyConfigComponentTypes) {
      if (isOpen) {
        this.setActiveComponent(component);
      }
    },
    updateCompanyConfig() {
      this.apiService
        .putCompanyConfig(this.companyConfigModel)
        .then(() => {
          // this.$store.dispatch(ActionType.loadStoreInitialData);
          this.$store.commit(
            MutationType.updateCompanyConfig,
            this.companyConfigModel
          );
          ToastService.showSuccess("Einstellungen erfolgreich gespeichert!");
          this.isSubmitButtonActive = false;
        })
        .catch((err) => {
          console.error(err);
          ToastService.showError("Fehler beim speichern der Einstellugen!");
        });
    },
    handleSave() {
      console.debug("Save event triggered from", this.activeConfigComponent);
    },
    syncSoftwareIntegration() {
      const storeSoftwareIntegration =
        this.$store.state.company.softwareIntegration;
      const localSoftwareIntegration =
        this.companyConfigModel?.softwareIntegration;
      const missingSoftwareIntegrations = Object.keys(
        storeSoftwareIntegration
      ).filter((key) => !(key in localSoftwareIntegration));

      if (missingSoftwareIntegrations.length > 0) {
        missingSoftwareIntegrations.forEach((key) => {
          (this.companyConfigModel.softwareIntegration as any)[key] =
            storeSoftwareIntegration[key];
        });
      }
    },
    syncApiKeys() {
      const storeApiKeys = this.$store.state.company.apiKeys;
      const localApiKeys = this.companyConfigModel.apiKeys;
      const missingApiKeys = Object.keys(storeApiKeys).filter(
        (key) => !(key in localApiKeys)
      );

      if (missingApiKeys.length > 0) {
        missingApiKeys.forEach((key) => {
          (this.companyConfigModel.apiKeys as any)[key] = storeApiKeys[key];
        });
      }
    },
    toggleErp(integration: string) {
      switch (integration) {
        case SoftwareIntegrationType.zvooveOne:
          if (this.companyConfigModel.softwareIntegration.zvooveOne) {
            this.companyConfigModel.softwareIntegration.pdHub = false;
          }
          break;
        case SoftwareIntegrationType.pdHub:
          if (this.companyConfigModel.softwareIntegration.pdHub) {
            this.companyConfigModel.softwareIntegration.zvooveOne = false;
          }
          break;
      }
    },
    toggleStoreVariable(variableName: string) {
      (this.companyConfigModel.softwareIntegration as any)[variableName] = !(
        this.companyConfigModel.softwareIntegration as any
      )[variableName];
    },
  },
});
</script>

<style scoped>
.v-row {
  padding: 2rem 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
}
</style>
