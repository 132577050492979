import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("fa-solid fa-globe")
            ])),
            _: 1
          }),
          _cache[3] || (_cache[3] = _createTextVNode("Endpunkt zu Wordpressplugin für Kandidaten + Stellenanzeigen "))
        ]),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localConfig.apiKeys.wpPluginBaseUrl,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.apiKeys.wpPluginBaseUrl) = $event)),
                  label: "Base URL WordPress Plugin"
                }), null, 16, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localConfig.apiKeys.wpPlugin,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localConfig.apiKeys.wpPlugin) = $event)),
                  label: "API Key"
                }), null, 16, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}