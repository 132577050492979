<template>
  <div
    v-if="layout === AppLayout.classic || layout === AppLayout.persontobusiness"
  >
    <MainHeader></MainHeader>
    <MainDashboard></MainDashboard>
  </div>
  <v-row v-else class="d-flex">
    <v-col cols="12" md="auto">
      <LeftHeader></LeftHeader>
    </v-col>
    <v-col cols="12" md="11">
      <div :style="{ minHeight: '99vh' }">
        <AppHeader />
        <MainDashboard />
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AppLayout } from "@/enums/app-layout.enum";
import { defineComponent } from "vue";
import LeftHeader from "@/components/header/LeftHeader.vue";
import MainDashboard from "@/components/dashboard/Dashboard.vue";
import MainHeader from "@/components/header/ClassicHeader.vue";
import AppHeader from "@/components/header/Header.vue";
import { OpenAiCoachingHelper } from "../helper/open-ai-coaching.helper";

export default defineComponent({
  name: "DashboardView",
  components: {
    LeftHeader,
    MainDashboard,
    MainHeader,
    AppHeader,
  },
  data() {
    return {
      layout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.checkUserForOpenAiCoaching();
  },
  methods: {
    checkUserForOpenAiCoaching() {
      this.$nextTick(() => {
        OpenAiCoachingHelper.checkUserForOpenAiCoaching(
          this.$store,
          this.user.config
        );
      });
    },
  },
});
</script>
