import { ApiResponse } from "../../models/api-response.model";
import { WabMessage } from "../../models/wab/wab-chat-message-model";
import { WabTemplate } from "../../models/wab/wab-templatemodel";
import { Api } from "./api.class";

export class WhatsAppService {
  private api = Api.getInstance();
  private wabUrlPath = "/whatsapp";

  async sendWhatsAppBusinessMessage(wabMessage: WabMessage) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.wabUrlPath}/send`, { wabMessage });
    return response.data;
  }

  async getWhatsAppBusinessChats(
    phoneNumber: string,
    loggedInMandants: string[]
  ) {
    const loggedInMandantsString = loggedInMandants.toString();
    const response = await this.api
      .getBackendApi()
      .get(
        `${this.wabUrlPath}/chat?phoneNumber=${encodeURIComponent(
          phoneNumber
        )}&loggedInMandants=${encodeURIComponent(loggedInMandantsString)}`
      );
    return response.data;
  }

  async getTemplates(): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.wabUrlPath}/templates`);

    return response.data;
  }

  async sendTemplate(template: WabTemplate) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.wabUrlPath}/templates`, { template });
    return response.data;
  }

  async logout() {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.wabUrlPath}/logout`);
    return response.data;
  }
}
