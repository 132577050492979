<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-brands fa-whatsapp</v-icon>WhatsApp
      Integration
    </h2>
    <div v-if="wabNumber">
      <p>Firmen-WhatsApp-Nummer: {{ wabNumber }}</p>
      <v-btn
        icon="mdi-plus"
        v-bind="vStyle.btn"
        color="error"
        @click="disconnectMeta()"
        >Meta Verbindung trennen</v-btn
      >
    </div>
    <div v-else>
      <v-btn v-bind="vStyle.btn" color="success" @click="connectMeta"
        >Meta verbinden</v-btn
      >
    </div>
    <v-form v-if="false">
      <v-card-title>Accounts</v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(account, index) in whatsAppConfig.accounts"
          :key="account._id"
        >
          <v-card v-bind="vStyle.card" class="mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              {{ account.description }}
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="removeAccount(index)">
                <v-icon>fa-solid fa-xmark</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-bind="vStyle.input"
                v-model="account.description"
                label="Name"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-bind="vStyle.input"
                v-model="account.mandants"
                :items="mandants"
                item-title="name"
                item-value="uuid"
                label="Niederlassungen"
                density="compact"
                class="mb-2"
                multiple
              ></v-select>
              <v-text-field
                v-bind="vStyle.input"
                v-model="account.phoneNumber"
                label="WhatsApp Nummer"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="account.accountId"
                label="WA Business Account ID"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="account.apiToken"
                label="WA Business API Token"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="account.phoneId"
                label="WA Business Phone ID"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-bind="vStyle.input"
                v-model="account.bot"
                :items="botOptions"
                label="Ai Bot"
                item-value="value"
                item-title="label"
                density="compact"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card v-bind="vStyle.card" class="border-dashed mb-2">
            <v-card-title
              class="d-flex justify-content-between align-items-center mb-3"
            >
              Neuer Status
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newAccount.description"
                label="Name"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-bind="vStyle.input"
                v-model="newAccount.mandants"
                :items="mandants"
                item-title="name"
                item-value="uuid"
                label="Niederlassungen"
                density="compact"
                class="mb-2"
                multiple
              ></v-select>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newAccount.phoneNumber"
                label="WhatsApp Nummer"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newAccount.accountId"
                label="WA Business Account ID"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newAccount.apiToken"
                label="WA Business API Token"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-bind="vStyle.input"
                v-model="newAccount.phoneId"
                label="WA Business Phone ID"
                density="compact"
                class="mb-2"
              ></v-text-field>
              <v-select
                v-bind="vStyle.input"
                v-model="newAccount.bot"
                :items="botOptions"
                label="Ai Bot"
                item-value="value"
                item-title="label"
                density="compact"
              ></v-select>
              <v-btn color="primary" variant="text" @click="addNewAccount"
                >Hinzufügen</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn v-bind="vStyle.btn" class="mt-10" type="submit" color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { WhatsAppService } from "@/services/api/whatsApp.service";
import { WhatsApp } from "@/models/wab/whatsapp.model";
import { WhatsAppBot } from "@/enums/whatsapp/whatsapp.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { mapGetters } from "vuex";
import { ApiResponse } from "../../../../models/api-response.model";
import { MutationType } from "../../../../enums/vuex-types.enum";

export default defineComponent({
  name: "ConfigWhatsApp",
  data() {
    const redirectURI = this.getRedirectURI();
    const metaLink = this.getMetaLink();
    return {
      company: this.$store.state.company,
      newAccount: {
        description: "",
        mandants: [] as string[],
        phoneNumber: "",
        accountId: "",
        apiToken: "",
        phoneId: "",
        bot: WhatsAppBot.noBot,
      },
      metaLink,
      redirectURI,
      unlinkMetaLink: "",
      vStyle: this.$store.state.vStyle,
      whatsAppConfigApi: new WhatsAppService(),
      whatsAppConfig: {} as WhatsApp,
    };
  },
  computed: {
    ...mapGetters(["getEnv"]),
    ...mapGetters({
      mandants: "reducedMandants",
    }),
    botOptions() {
      return getEnumOptions(WhatsAppBot);
    },

    wabNumber() {
      return this.$store.state.company?.whatsApp?.phoneNumber;
    },
  },
  methods: {
    addNewAccount() {
      if (this.newAccount.description && this.newAccount.phoneNumber) {
        this.whatsAppConfig.accounts.push({ ...this.newAccount });
        this.resetNewStatusOption();
      }
    },
    addWabNumber(wabNumber: string) {
      this.$store.state.company.whatsApp = {
        phoneNumber: wabNumber,
      };
      //this.$store.dispatch("updateCompany", { whatsApp: { phoneNumber: this.wabNumber } });
      this.wabNumber = wabNumber;
      this.metaLink = this.getMetaLink();
    },
    connectMeta() {
      window.location.replace(this.metaLink);
    },
    disconnectMeta() {
      this.whatsAppConfigApi.logout().then((response: ApiResponse) => {
        if (response.response === "Whatsapp deleted") {
          this.wabNumber = "";
          const config = this.$store.state.company;
          delete config.whatsApp;
          // FIXME this could be wrong
          this.$store.commit(MutationType.setCompanyConfig, config);
        }
      });
    },
    getMetaLink() {
      const appId = this.$store.getters.getEnv.whatsappConfig.appId;
      const redirectURI = this.getRedirectURI();
      const state = `{"companyId:${this.$store.state.company?._id},wabNumber:${this.wabNumber}"}`;
      const url = `https://facebook.com/v21.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectURI}&state=${state}&scope=pages_messaging,pages_show_list,whatsapp_business_management,whatsapp_business_messaging,business_management`;
      return url;
    },
    getRedirectURI() {
      return `${this.$store.getters.getEnv.whatsappConfig.redirectUrl}`;
    },
    removeAccount(index: number) {
      this.whatsAppConfig.accounts.splice(index, 1);
    },
    resetNewStatusOption() {
      this.newAccount = {
        description: "",
        mandants: [] as string[],
        phoneNumber: "",
        accountId: "",
        apiToken: "",
        phoneId: "",
        bot: WhatsAppBot.noBot,
      };
    },
  },
});
</script>

<style scoped>
/* Add any necessary styling here */
</style>
