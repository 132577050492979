<!-- src/components/whatsapp/ChatMessages.vue -->
<template>
  <v-container
    v-if="chatMessages && chatMessages.length > 0"
    class="mx-0 pa-0 mb-1 chat-messages"
    fluid
    v-autoscroll="'.v-card'"
  >
    <v-card
      v-bind="vStyle.card"
      class="ma-0 pa-0 border-md rounded-md chat-card"
    >
      <v-card-subtitle> Chat Nachrichten </v-card-subtitle>
      <v-card-text
        v-if="chatMessages.length === 0"
        class="spinner-container scrollable-messages"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="300"
          width="16"
          class="slow-spinner"
        ></v-progress-circular>
      </v-card-text>
      <v-card-text>
        <div
          v-for="msg in chatMessages"
          :key="msg._id"
          class="message-container"
        >
          <v-card
            :class="{
              'message-from-me': isFromMe(msg),
              'message-from-them': !isFromMe(msg),
            }"
          >
            <v-list-item-subtitle>
              <span>{{ msg.message }}</span>
            </v-list-item-subtitle>
            <small class="text-caption text-grey--text">
              {{ formatTimestamp(msg.timestamp as string) }}
            </small>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import {
  ref,
  onMounted,
  onBeforeUnmount,
  defineComponent,
  watch,
  PropType,
} from "vue";
import { WabMessage } from "../../models/wab/wab-chat-message-model";
import moment from "moment";
import { WabChatService } from "../../services/api/wab-chat.service";

export default defineComponent({
  name: "ChatMessages",
  props: {
    messages: {
      type: Array<WabMessage>,
      required: true,
    },
    wabNumber: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props) {
    const chatMessages = ref<WabMessage[]>(props.messages);
    let wabChatService: WabChatService | null = null;

    onMounted(() => {
      wabChatService = new WabChatService(props.wabNumber);
      wabChatService.onMessage((data: any) => {
        addMessage(data);
      });
    });

    onBeforeUnmount(() => {
      if (wabChatService) {
        wabChatService.disconnect();
      }
    });

    watch(
      () => props.wabNumber,
      (newWabNumber) => {
        if (wabChatService) {
          wabChatService.disconnect();
        }
        wabChatService = new WabChatService(newWabNumber);
        wabChatService.onMessage((data: any) => {
          addMessage(data);
        });
      }
    );

    watch(
      () => props.messages,
      (newMessages) => {
        chatMessages.value = [...newMessages];
      }
    );

    const addMessage = (data: WabMessage) => {
      chatMessages.value.push(data);
    };

    const formatTimestamp = (timestamp: string) => {
      const messageTimestamp = moment(timestamp);
      return messageTimestamp.local().format("HH:mm");
    };

    const isFromMe = (wabMessage: WabMessage): boolean => {
      return !wabMessage.foreign;
    };

    return { chatMessages, formatTimestamp, isFromMe };
  },
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
});
</script>

<style scoped>
.chat-messages {
  max-height: 28rem;
  overflow-y: auto;
}

.chat-card {
  height: 25rem;
  overflow-y: auto;
  background-color: #faf6f4;
  color: var(--color-font-primary);
  padding: 1rem;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.message-from-me {
  background-color: #d9fdd3;
  color: var(--color-font-primary);
  max-width: 95%;
  min-width: 30%;
  margin-left: auto;
  margin-bottom: 0.7rem;
  border-radius: 0.7rem;
  padding: 1rem;
  word-break: break-word;
}

.message-from-them {
  background-color: #fff;
  color: var(--color-font-primary);
  max-width: 95%;
  min-width: 30%;
  margin-right: auto;
  margin-bottom: 0.7rem;
  border-radius: 0.7rem;
  padding: 1rem;
  word-break: break-word;
}

.v-list-item-title {
  font-weight: bold;
}

.v-list-item-subtitle {
  margin-top: 0.5rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
