import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "roles-container" }
const _hoisted_2 = {
  key: 0,
  class: "role-input"
}
const _hoisted_3 = { class: "role-header" }
const _hoisted_4 = { class: "permissions" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "permission-add" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Rollenverwaltung", -1)),
          (_ctx.hasRoleCreateAccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newRoleName) = $event)),
                  type: "text",
                  placeholder: "Neue Rolle...",
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.createRole && _ctx.createRole(...args)), ["enter"]))
                }, null, 544), [
                  [_vModelText, _ctx.newRoleName]
                ]),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createRole && _ctx.createRole(...args)))
                }, "Hinzufügen")
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
            return (_openBlock(), _createElementBlock("div", {
              key: role._id,
              class: "role-item"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h2", null, _toDisplayString(role.name), 1),
                (_ctx.hasRoleDeleteAccess)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      icon: "",
                      variant: "text",
                      density: "compact",
                      onClick: ($event: any) => (_ctx.deleteRole(role._id as string))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("fa-solid fa-xmark")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Berechtigungen:", -1)),
                (role.permissions?.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Keine Berechtigungen"))
                  : _createCommentVNode("", true),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(role.permissions, (permission) => {
                    return (_openBlock(), _createElementBlock("li", { key: permission }, [
                      _createTextVNode(_toDisplayString(permission) + " ", 1),
                      (_ctx.hasRoleUpdateAccess)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "remove-btn",
                            onClick: ($event: any) => (_ctx.removePermission(role, permission))
                          }, " ✖ ", 8, _hoisted_6))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((_ctx.newPermissions[role._id as string]) = $event),
                    class: "permission-select"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePermissions, (perm) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: perm,
                        value: perm
                      }, _toDisplayString(perm), 9, _hoisted_9))
                    }), 128))
                  ], 8, _hoisted_8), [
                    [_vModelSelect, _ctx.newPermissions[role._id as string]]
                  ]),
                  (_ctx.hasRoleUpdateAccess)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.addPermission(role))
                      }, " + Berechtigung ", 8, _hoisted_10))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ], 64))
}