import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  class: "font-weight-bold mb-6",
  style: {"color":"var(--color-primary)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_WhatsAppMessenger = _resolveComponent("WhatsAppMessenger")!
  const _component_WabTemplates = _resolveComponent("WabTemplates")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _directive_collapse = _resolveDirective("collapse")!
  const _directive_autoscroll = _resolveDirective("autoscroll")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_icon, {
      class: "communication-container-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = true))
    }, {
      default: _withCtx(() => _cache[15] || (_cache[15] = [
        _createTextVNode("fa-brands fa-whatsapp")
      ])),
      _: 1
    }),
    _createVNode(_component_v_tooltip, {
      activator: "parent",
      location: "bottom"
    }, {
      default: _withCtx(() => _cache[16] || (_cache[16] = [
        _createTextVNode("WhatsApp versenden")
      ])),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showDialog) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_v_form, { ref: "form" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "mr-1" }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("fa-brands fa-whatsapp")
                      ])),
                      _: 1
                    }),
                    _cache[18] || (_cache[18] = _createTextVNode("WhatsApp senden"))
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "pb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, {
                      fluid: "",
                      style: {"padding":"0"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { dense: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "6" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_combobox, _mergeProps(_ctx.vStyle.input, {
                                  modelValue: _ctx.wabNumber,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.wabNumber) = $event)),
                                  items: _ctx.reversedPhoneNumbers,
                                  label: "Wähle eine Empfänger-Nummer",
                                  "item-text": "label",
                                  "item-value": "value",
                                  "return-object": "",
                                  class: "w-100",
                                  rules: [_ctx.requiredRule]
                                }), null, 16, ["modelValue", "items", "rules"])
                              ]),
                              _: 1
                            }),
                            (_ctx.isWabIntegration)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  cols: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTemplates())),
                                      height: "56",
                                      class: "w-100"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.templateToggleBtnTitle), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      (_ctx.isWabIntegration)
                        ? (_openBlock(), _createBlock(_component_v_select, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.selectedMandant,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedMandant) = $event)),
                            items: _ctx.getLoggedInMandantsItems(),
                            "item-title": "description",
                            "item-value": "id",
                            label: "Mandant Absendernummer",
                            density: "compact",
                            class: "mb-2",
                            rules: [_ctx.requiredRule]
                          }), null, 16, ["modelValue", "items", "rules"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_v_icon, { class: "fa-regular fa-circle-user" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.receiverName), 1)
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                      style: _normalizeStyle({ height: !_ctx.showTemplates ? '0' : 'auto' }),
                      class: "collapsible-box"
                    }, [
                      _createVNode(_component_WhatsAppMessenger, {
                        ref: "whatsAppMessengerComponent",
                        onCheckTimeline: _ctx.checkChatHistoryAndAddMissingTimelineEntries,
                        onCloseDialog: _cache[4] || (_cache[4] = (msg) => _ctx.close(msg)),
                        onChatsEmpty: _cache[5] || (_cache[5] = (value) => _ctx.setShowTemplates(value)),
                        whatsAppConfig: _ctx.isWabIntegration,
                        wabNumber: _ctx.wabNumber,
                        message: _ctx.fullMessage,
                        selectedMandant: _ctx.selectedMandant,
                        sendMessageEvent: _ctx.sendMessageEvent,
                        showChatMessages: _ctx.isWabIntegration,
                        username: _ctx.receiverName
                      }, null, 8, ["onCheckTimeline", "whatsAppConfig", "wabNumber", "message", "selectedMandant", "sendMessageEvent", "showChatMessages", "username"])
                    ], 4)), [
                      [_directive_collapse, !_ctx.showTemplates]
                    ]),
                    (_ctx.isWabIntegration)
                      ? _withDirectives((_openBlock(), _createBlock(_component_WabTemplates, {
                          key: 0,
                          onCloseDialog: _cache[6] || (_cache[6] = (msg) => _ctx.close(msg)),
                          message: "fullMessage",
                          receiverName: _ctx.receiverName,
                          sendTemplateEvent: _ctx.sendTemplateEvent,
                          wabNumber: _ctx.wabNumber,
                          wabSenderNumber: _ctx.wabSenderNumber,
                          selectedMandant: _ctx.selectedMandant,
                          style: _normalizeStyle({ height: !_ctx.showTemplates ? '0' : 'auto' }),
                          class: "collapsible-box"
                        }, null, 8, ["receiverName", "sendTemplateEvent", "wabNumber", "wabSenderNumber", "selectedMandant", "style"])), [
                          [_directive_collapse, _ctx.showTemplates]
                        ])
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_card_actions, { class: "ma-0 pa-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          color: "abort",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeDialog()))
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("Abbrechen")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showAiModelMenu($event)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[20] || (_cache[20] = [
                                _createTextVNode("fa-solid fa-rocket")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[21] || (_cache[21] = [
                                _createTextVNode("Nachricht mit AI umformulieren")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_menu, {
                              modelValue: _ctx.menuAiModelVisible,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.menuAiModelVisible) = $event)),
                              class: "ai-menu",
                              style: _normalizeStyle({
                    left: `${_ctx.menuPosition.x}px`,
                    top: `${_ctx.menuPosition.y}px`,
                  }),
                              ref: "aiMenu"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aiOptions, (aiOption) => {
                                      return (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: aiOption.value,
                                        onClick: ($event: any) => (_ctx.handleAiOptions(aiOption))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_title, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(aiOption.label), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue", "style"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          class: "longpress",
                          icon: "",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showLanguageMenu($event))),
                          onContextmenu: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.showAiModelMenu($event, true)), ["prevent"])),
                          onLongpress: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showAiModelMenu($event, true)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("fa-solid fa-comments")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "bottom"
                            }, {
                              default: _withCtx(() => _cache[23] || (_cache[23] = [
                                _createTextVNode("mit AI in eine andere Sprache übersetzen")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_menu, {
                          modelValue: _ctx.menuTranslationVisible,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.menuTranslationVisible) = $event)),
                          class: "ai-menu",
                          style: _normalizeStyle({
                  left: `${_ctx.menuPosition.x}px`,
                  top: `${_ctx.menuPosition.y}px`,
                }),
                          ref: "aiMenu"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: language,
                                    onClick: ($event: any) => (_ctx.selectLanguageAndTranslate(language))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(language), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "style"]),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.send,
                          color: "success"
                        }, {
                          default: _withCtx(() => [
                            _cache[25] || (_cache[25] = _createTextVNode("Senden")),
                            _createVNode(_component_v_icon, { class: "ml-1" }, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createTextVNode("fa-regular fa-paper-plane")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })), [
          [_directive_autoscroll, '.v-card']
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}