<template>
  <v-card v-bind="vStyle.card">
    <v-card-title class="d-flex"
      >Vorschau Job-Anzeige <v-spacer></v-spacer>
      <v-btn
        v-bind="vStyle.btn"
        color="primary"
        v-if="isShowSendTemplateToAtsButton()"
        @click="handleClickSendTemplateToAts()"
        >Exportieren<v-icon class="ml-2"
          >fa-solid fa-arrow-right-to-bracket</v-icon
        ></v-btn
      ></v-card-title
    >
    <v-card-text>
      <iframe
        ref="previewFrame"
        :srcdoc="getHtmlTemplate"
        style="width: 100%; border: none; height: 150rem"
      ></iframe>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TemplateJobAd } from "../../../models/company-config.model";
import { mapGetters } from "vuex";
import { PopUpWindowSize } from "../../../enums/app-layout.enum";
import DialogService from "../../../services/dialog.service";
import { VuetifyColor } from "../../../plugins/vuetify";

export default defineComponent({
  name: "JobAdTemplate",
  props: {
    jobAdTemplateData: {
      type: Object as PropType<TemplateJobAd>,
      required: true,
    },
  },
  data() {
    return {
      htmlTemplate: "wird geladen..." as string,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      softwareIntegration: "softwareIntegration",
    }),
    getHtmlTemplate() {
      return this.getJobAdHtmlTemplate(this.jobAdTemplateData);
    },
  },
  methods: {
    copyAtsRecruitTemplateToClipboard() {
      let ad = this.jobAdTemplateData;
      ad = { ...ad, ...this.getAtsRecruitVariables() };
      if (ad) {
        navigator.clipboard.writeText(this.getJobAdHtmlTemplate(ad));
      }
    },
    generateHtmlTemplate() {
      this.htmlTemplate = this.getJobAdHtmlTemplate(this.jobAdTemplateData);
    },
    getAtsRecruitVariables() {
      return {
        jobTitle: "{{Stelle.Stellenbezeichnung}}",
        tasksHeader: "{{Stelle.AufgabenHeader}}",
        tasks: "{{Stelle.Aufgaben}}",
        technicalRequirementsHeader: "{{Stelle.FachlicheAnforderungenHeader}}",
        technicalRequirements: "{{Stelle.FachlicheAnforderungen}}",
        personalRequirementsHeader:
          "{{Stelle.PersoenlicheAnforderungenHeader}}",
        personalRequirements: "{{Stelle.PersoenlicheAnforderungen}}",
        employerBenefitsHeader: "{{Stelle.ArbeitgeberleistungHeader}}",
        employerBenefits: "{{Stelle.Arbeitgeberleistung}}",
        perspectivesHeader: "{{Stelle.PerspektivenHeader}}",
        perspectives: "{{Stelle.Perspektiven}}",
        jobObjectiveHeader: "{{Stelle.StellenzielHeader}}",
        jobObjective: "{{Stelle.Stellenziel}}",
        metaDescription: "{{Stelle.Unternehmensbedeutung}}",
        metaKeywords: "{{Stelle.UnternehmensbedeutungHeader}}",
        jobTitleEncoded: "{{Stelle.Stellenbezeichnung:urlencoded='true'}}",
        locationEncoded: "{{Stelle.EinsatzortOrt:urlencoded='true'}}",
        locationCity: "{{Stelle.EinsatzortOrt}}",
        urlEncoded: "{{Stelle.StelleUrl:urlencoded='true'}}",
        jsonScriptTag: "{{Stelle.JsonLdScriptTag}}",
        collectiveAgreement: "{{Stelle.Tarifvertrag}}",
        url: "{{Stelle.StelleUrl}}",
        author: "{{Stelle.Mandant.Firmenbezeichnung}}",
        beginDate: "{{Stelle.Beginn}}",
        salary: "{{Stelle.ArbeitgebervorstellungHeader}}",
        applyUrl: "{{Stelle.BewerbungsformularUrl}}",
        whatsAppNumber: "{{Stelle.Arbeitgebervorstellung}}",
        id: "{{Stelle.StellenID}}",
        idEncoded: "{{Stelle.StellenID:urlencoded='true'}}",
        jobAdPdfUrl: "{{Stelle.StellePdfUrl}}",
        impressumUrl: "{{Stelle.ImpressumUrl}}",
        impressumText: "{{Stelle.BezeichnungImpressumLink}}",
        contactTextHeader: "{{Stelle.KontaktTextHeader}}",
        contactText: "{{Stelle.KontaktText}}",
        primaryColor: "{{Design.Primaerfarbe}}",
        secondaryColor: "{{Design.Sekundaerfarbe}}",
        // fontFamily: "{{Design.Schriftart}}", // deactivated because use default font family not zvoove
      } as TemplateJobAd;
    },
    getJobAdHtmlTemplate(ad: TemplateJobAd) {
      return `
<!DOCTYPE html>
<html lang="de">

<head>
  <style>
    /* Farb- und Stilvariablen */
    :root {
      --jt-rotate-threshold: ${ad.rotateThreshold ?? 1.5};
      --jt-border-radius: ${ad.rounding ?? 0.8}rem;
      --jt-shift-threshold-x: ${ad.shiftX ?? 0.3}rem;
      --jt-shift-threshold-y: ${ad.shiftY ?? 0.3}rem;
      --jt-box-shadow-1: 0 4px 10px rgba(0, 0, 0, ${ad.shadow1 ?? 0.2});
      --jt-box-shadow-2: 0 4px 10px rgba(0, 0, 0, ${ad.shadow2 ?? 0.15});
      --jt-primary-color: ${ad.primaryColor ?? "#1C365E"};
      --jt-secondary-color: ${ad.secondaryColor ?? "#008B8B"};
      --jt-on-primary-color: ${ad.onPrimaryColor ?? "#ffffff"};
      --jt-on-secondary-color: ${ad.onSecondaryColor ?? "#ffffff"};
      --jt-tertiary-color: color-mix(in srgb, var(--jt-on-secondary-color) 40%, var(--jt-secondary-color) 60%);
      --jt-on-tertiary-color: ${ad.onTertiaryColor ?? "#000000"};
      --jt-text-color: ${ad.textColor ?? "#333"};
      --jt-bg-color: ${ad.bgColor ?? "#ffffff"};
      --jt-body-color: #fff;
      --jt-bg-tabs: rgb(from var(--jt-primary-color) r g b / 0.25);
      --jt-bg-secondary-transparent: rgb(from var(--jt-secondary-color) r g b / 0.10);
      --jt-bg-primary-transparent: rgb(from var(--jt-primary-color) r g b / 0.10);
      --jt-font-family: ${ad.fontFamily ?? "Arial, sans-serif"};
      --jt-focus-outline: 3px solid var(--jt-secondary-color);
      --jt-focus-outline-offset: 2px;
      --jt-hover-scale: 1.02;
      --jt-transition-speed: 0.3s;
      --jt-header-image-url: url('${ad.imageUrl ?? ""}');
      --jt-company-logo-url: url('${ad.logoUrl ?? ""}')
    }


    /* Global Styles */
    body {
      font-family: var(--jt-font-family);
      margin: 0;
      padding: 0;
      background-color: var(--jt-body-color);
      color: var(--jt-text-color);
    }

    .jt-container {
      max-width: 1000px;
      margin: 2rem auto;
      background: var(--jt-bg-color);
      padding: 2rem;
      border-radius: var(--jt-border-radius);
      box-shadow: var(--jt-box-shadow-1);
      animation: fadeIn 1s ease-in-out;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    /* Header */
    .jt-header {
      background: var(--jt-primary-color);
      color: var(--jt-on-primary-color);
      padding: 0;
      text-align: center;
      border-radius: var(--jt-border-radius) var(--jt-border-radius) 0 0;
      font-size: 1rem;
      box-shadow: var(--jt-box-shadow-1);
    }

    /* Header Image */
    .jt-header-image {
      width: 100%;
      height: 250px;
      background-image: var(--jt-header-image-url);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: var(--jt-border-radius) var(--jt-border-radius) 0 0;
      transition: transform var(--jt-transition-speed) ease;
    }

    .jt-header-image:hover {
      opacity: 0.9;
    }


    .jt-header-text-container {
      padding: 1rem;
    }

    /* Company Logo */

    .jt-company-logo {
      max-height: 3rem;
      border-radius: var(--jt-border-radius);
      box-shadow: var(--jt-box-shadow-1);
      transition: all var(--jt-transition-speed) ease;
      padding: 10px;
      background: var(--jt-bg-color);
      background-image: var(--jt-company-logo-url);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .jt-company-logo:hover {
      transform: translateY(-2px);
      box-shadow: var(--jt-box-shadow-2);
    }

    /* Job-Details als Tabs */
    .jt-job-details {
      display: flex;
      justify-content: space-around;
      background: var(--jt-bg-tabs);
      padding: 15px;
      border-bottom-left-radius: var(--jt-border-radius);
      border-bottom-right-radius: var(--jt-border-radius);
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      box-shadow: var(--jt-box-shadow-1);
    }

    .jt-job-details div {
      flex: 1;
      padding: 10px;
      border-right: 0.2rem solid rgb(from var(--jt-primary-color) r g b / 0.2);
    }

    .jt-job-details div:last-child {
      border-right: none;
    }

    /* Bewerbungsbuttons */
    .jt-apply-button-container {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin: 30px 0;
      gap: 4rem;
      flex-direction: row;
      flex-wrap: wrap; 
      align-items: center;
      flex: 1;
      width: 100%;
    }

    .jt-apply-button {
      background: var(--jt-secondary-color);
      color: var(--jt-on-secondary-color);
      padding: 15px 30px;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.1rem;
      border-radius: var(--jt-border-radius);
      transition: 0.3s all ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 20rem
    }

    .jt-apply-button:hover {
      cursor: pointer;
      background: var(--jt-tertiary-color);
      color: var(--jt-on-tertiary-color);
    }

    /* Layout Anpassung für Desktop */
    .jt-content-layout {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      align-items: stretch;
    }

    /* right column */
    .jt-tasks-requirements {
      width: 65%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    /* left column */
    .jt-company-description {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .jt-angled-section,
    .jt-contact-section {
      padding: 2rem;
      
      position: relative;
      overflow: hidden;
    }

    .jt-contact-section {
      border-radius: var(--jt-border-radius);
      padding-left: 4rem;
      padding-right: 4rem;
    }

    .jt-angled-section::before,
    .jt-contact-section::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 15px;
      background: rgba(0, 0, 0, 0.1);
      transform: skewY(3deg);
    }


    .jt-sections-container {
      position: relative;
      padding: 1rem;
      margin-top: 2rem;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column
    }

    .jt-rotate-A-section,
    .jt-rotate-B-section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform-origin: center center;
      border-radius: var(--jt-border-radius);
      box-shadow: var(--jt-box-shadow-2);
      transition: all 2.0s ease;
    }

    .jt-rotate-A-section {
      transform:
        translate(calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-x)),
          calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-y))) rotate(calc(var(--jt-rotate-threshold) * 1deg));
      z-index: 1;
    }

    .jt-rotate-B-section {
      transform:
        translate(calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-x) * -1),
          calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-y) * -1)) rotate(calc(var(--jt-rotate-threshold) * -1deg));
      z-index: 2;
    }

    .jt-angled-section {
      position: relative;
      z-index: 3;
      padding: 20px;
      background: none;
    }

    .jt-primary-section {
      background: var(--jt-bg-primary-transparent);
    }

    .jt-secondary-section {
      background: var(--jt-bg-secondary-transparent);
    }

    .jt-share-button-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin: 2rem 0;
    }

    .jt-share-button {
      background: var(--jt-bg-primary-transparent);
      color: var(--jt-primary-color);
      font-size: 2rem;
      padding: 10px;
      border-radius: var(--jt-border-radius);
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 2.5rem;
      height: 2.5rem;
      margin: 1rem;
      transition: all 0.3s ease;
      box-shadow: var(--jt-box-shadow-1);
    }

    .jt-share-button:hover {
      cursor: pointer;
      background: var(--jt-secondary-color);
      color: var(--jt-on-secondary-color);
      scale: 1.05;
      transform: translateY(-2px);
    }

    .jt-google-maps-container {
      margin-top: 20px;
      border-radius: var(--jt-border-radius);
      overflow: hidden;
      /* Wichtig für den border-radius */
      box-shadow: var(--jt-box-shadow-1);
      position: relative;
      background: var(--jt-bg-primary-transparent);
      padding: 10px;
    }

    .jt-google-maps-container iframe {
      width: 100%;
      height: 300px;
      border: none;
      border-radius: var(--jt-border-radius);
      transition: all 0.3s ease;
    }

    .jt-google-maps-container:hover {
      transform: translateY(-2px);
    }

    /* Global focus styles */
    *:focus-visible {
      outline: var(--jt-focus-outline);
      outline-offset: var(--jt-focus-outline-offset);
      transition: outline-offset var(--jt-transition-speed) ease;
    }

    /* Enhanced interactive elements */
    .jt-apply-button {
      position: relative;
      overflow: hidden;
    }

    .jt-apply-button::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg,
          transparent,
          rgba(255, 255, 255, 0.2),
          transparent);
      transition: var(--jt-transition-speed);
    }

    .jt-apply-button:hover::after {
      left: 100%;
    }

    .jt-apply-button:hover {
      transform: scale(var(--jt-hover-scale));
    }

    .jt-apply-button:active {
      transform: scale(0.98);
    }

    /* Enhanced share buttons */
    .jt-share-button {
      position: relative;
      overflow: hidden;
    }

    .jt-share-button::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      background: var(--jt-secondary-color);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: width 0.4s ease, height 0.4s ease, opacity 0.4s ease;
    }

    .jt-share-button:hover::before {
      width: 120%;
      height: 120%;
      opacity: 0.1;
    }

    /* Enhanced sections animation */
    .jt-sections-container {
      transition: transform var(--jt-transition-speed) ease;
    }

    .jt-sections-container:hover {
      transform: translateY(-2px);
    }

    .jt-sections-container:hover .jt-rotate-A-section {
      transform: translate(calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-x) * 1.2),
          calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-y) * 1.2)) rotate(calc(var(--jt-rotate-threshold) * 1.2deg));
    }

    .jt-sections-container:hover .jt-rotate-B-section {
      transform: translate(calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-x) * -1.2),
          calc(var(--jt-rotate-threshold) * var(--jt-shift-threshold-y) * -1.2)) rotate(calc(var(--jt-rotate-threshold) * -1.2deg));
    }

    /* Contact Container Styles */
    .jt-contact-container {
      margin-top: 2rem;
    }

    .jt-contact-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 2rem;
      align-items: center;
    }

    .jt-contact-section {
      background: var(--jt-primary-color);
      color: var(--jt-on-primary-color);
      text-align: left;
      margin-top: 1.5rem;
      box-shadow: var(--jt-box-shadow-1);
    }


    .jt-contact-logo-section {
      display: flex;
      margin-top: 1.5rem;
      justify-content: flex-start;
      align-items: center;
    }

    .jt-company-logo-large {
      width: 300px;
      height: 120px;
      background-image: var(--jt-company-logo-url);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform var(--jt-transition-speed) ease;
    }

    .jt-company-logo-large:hover {
      transform: scale(1.05);
    }

    /* Responsive Design */
    @media (max-width: 768px) {

      /* Container & Layout */
      .jt-container {
        padding: 15px;
        margin: 15px;
        width: auto;
      }

      .jt-content-layout {
        flex-direction: column;
        gap: 15px;
      }

      /* Header Styles */
      .jt-header {
        font-size: 1.2rem;
      }

      .jt-header h1 {
        font-size: 1.5rem;
        margin: 0 0 10px 0;
      }

      .jt-header-image {
        height: 10rem;
      }

      /* Job Details */
      .jt-job-details {
        flex-direction: column;
        gap: 10px;
      }

      .jt-job-details div {
        padding: 15px 10px;
        font-size: 0.9rem;
        border-right: none;
        border-bottom: 0.1rem solid rgb(from var(--jt-primary-color) r g b / 0.2);
      }

      .jt-job-details div:last-child {
        border-bottom: none;
      }

      /* Content Sections */
      .jt-tasks-requirements {
        order: -1;
        width: 100%;
      }

      .jt-company-description {
        width: 100%;
      }

      /* Buttons & Interactive Elements */
      .jt-apply-button-container {
        padding: 10px;
      }

      .jt-apply-button {
        width: 90%;
        padding: 12px 20px;
        font-size: 1rem;
      }

      .jt-share-button-container {
        gap: 5px;
      }

      .jt-share-button {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
        margin: 0.5rem;
      }

      /* Section Containers */
      .jt-sections-container {
        width: 100%;
        padding: 0.5rem;
      }

      .jt-angled-section {
        padding: 15px;
      }

      /* Company Logo */
      .jt-company-logo {
        max-width: 150px;
      }

      .jt-contact-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .jt-company-logo-large {
        width: 200px;
        height: 80px;
      }
    }

    /* Impressum Link Styles */
    .jt-impressum-link {
      color: var(--jt-primary-color);
      text-decoration: none;
      transition: all var(--jt-transition-speed) ease;
    }

    .jt-impressum-link :hover {
      color: var(--jt-secondary-color);
      text-decoration: underline;
    }
  </style>
  <title>${ad.jobTitle ?? "Titel"} - Jetzt Bewerben!</title>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css">
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="author" content="${ad.author ?? "Autor"}">
  <meta name="description" content="${ad.metaDescription ?? "Beschreibung"}">
  <meta name="keywords" content="${ad.metaKeywords ?? "Schlüsselwörter"}">
  <meta name="metatitle"
    content="Aktuelles Stellenangebot - ${ad.jobTitle ?? "Titel"} - ${
        ad.locationCity ?? "Ort"
      } - ${ad.author ?? "Autor"}">

  <meta property="og:url" content="${ad.url ?? ""}"/>
  <meta property="og:type" content="article" />
  <meta property="og:title" content="${ad.jobTitle ?? "Titel"}"/>
  <meta property="og:description" content="${
    ad.metaDescription ?? "Beschreibung"
  }"/>
  <meta property="og:image" content="${ad.imageUrl ?? ""}"/>

  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="${ad.jobTitle ?? "Titel"}"/>
  <meta name="twitter:description" content="${
    ad.metaDescription ?? "Beschreibung"
  }"/>
  <meta name="twitter:image" content="${ad.imageUrl ?? ""}"/>



  <!--StepStone-->
  <meta name="brandingPrimaryColor" content="${ad.primaryColor ?? "#1C365E"}"/>
  <!-- background color, only on mobile devices -->
  <meta name="brandingSecondaryColor" content="${
    ad.secondaryColor ?? "#008B8B"
  }"/> <!-- headlines and links -->
  <meta name="brandingImageHero" content="${
    ad.imageUrl ?? ""
  }" /> <!-- jt-header image -->
  <style>
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: url(https://zvoovecdn.azureedge.net/fonts/roboto-normal-400.woff2) format('woff2');
    }

    @font-face {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://zvoovecdn.azureedge.net/fonts/quicksand-latin-ext_latin-regular.woff2) format('woff2');
    }
  </style>
</head>

<body>
  <div class="jt-container">
    <div class="jt-header">
      <div class="jt-header-image" role="img" aria-label="Header Image"></div>
      <div class="jt-header-text-container">
        <h1>${ad.jobTitle ?? "Titel"}</h1>
        <p *if="Stelle.Unternehmensbedeutung">${
          ad.metaDescription ?? "Beschreibung"
        }</p>
      </div>
    </div>

    <div class="jt-job-details">
      <div><i class="fa-solid fa-location-dot"></i><strong>Standort:</strong><br>${
        ad.locationCity ?? "Ort"
      }</div>
      <div><i class="fa-solid fa-calendar-day"></i> <strong>Beginn:</strong>
        <conditional condition="Stelle.BeginnAbSofort"><br>ab sofort</conditional>
        <conditional condition="!Stelle.BeginnAbSofort">${
          ad.beginDate ?? ""
        }</conditional>
      </div>
      <div *if="Stelle.IncludeTarifvertrag"><i class="fa-solid fa-file-contract"></i>
        <strong>Tarifvertrag:</strong><br>${
          ad.collectiveAgreement ?? "Tarifvertrag"
        }
      </div>
      <div><i class="fa-solid fa-euro-sign"></i> <strong>Gehalt:</strong><br>${
        ad.salary ?? "von 1 - 2 € / Stunde"
      }</div>
    </div>

    <div class="jt-apply-button-container">
      <a class="jt-apply-button" href="${ad.applyUrl ?? ""}">
        <i class="fa-solid fa-paper-plane"></i> Jetzt Bewerben
      </a>
      <a class="jt-apply-button"
        href="https://api.whatsapp.com/send?phone=${
          ad.whatsAppNumber ?? ""
        }&text=Ich%20interessiere%20mich%20für%20die%20Stellenanzeige%20${
        ad.jobTitleEncoded ?? ""
      }%20in%20${ad.locationEncoded}%20${ad.urlEncoded}%20ID${
        ad.idEncoded ?? ""
      }"
        target="_blank">
        <i class="fa-brands fa-whatsapp"></i> Kontakt über WhatsApp
      </a>
    </div>

    <div class="jt-content-layout">
      <div class="jt-tasks-requirements">
        <div *if="Stelle.IncludeAufgaben" class="jt-sections-container">
          <div class="jt-rotate-A-section jt-primary-section"></div>
          <div class="jt-rotate-B-section jt-primary-section"></div>
          <div class="jt-angled-section">
            <h2 *if="Stelle.AufgabenHeader">${ad.tasksHeader ?? "Aufgaben"}</h2>
            <p *if="Stelle.Aufgaben">${ad.tasks ?? ""}</p>
          </div>
        </div>
        <div *if="Stelle.IncludeFachlicheAnforderungen || Stelle.IncludePersoenlicheAnforderungen"
          class="jt-sections-container">
          <div class="jt-rotate-A-section jt-primary-section"></div>
          <div class="jt-rotate-B-section jt-primary-section"></div>
          <div class="jt-angled-section">
            <h2 *if="Stelle.FachlicheAnforderungenHeader">${
              ad.technicalRequirementsHeader ?? "Fachliche Anforderungen"
            }</h2>
            <p *if="Stelle.FachlicheAnforderungen">${
              ad.technicalRequirements ?? ""
            }</p>
            <h4 *if="Stelle.PersoenlicheAnforderungenHeader">${
              ad.personalRequirementsHeader ?? "Persönliche Anforderungen"
            }</h4>
            <p *if="Stelle.PersoenlicheAnforderungen">${
              ad.personalRequirements ?? ""
            }</p>
          </div>
        </div>
      </div>
      <div class="jt-company-description">
        <!--Benefits-->
        <div *if="Stelle.IncludeArbeitgeberleistung" class="jt-sections-container">
          <div class="jt-rotate-A-section jt-secondary-section"></div>
          <div class="jt-rotate-B-section jt-secondary-section"></div>
          <div class="jt-angled-section">
            <h2 *if="Stelle.ArbeitgeberleistungHeader">${
              ad.employerBenefitsHeader ?? "Benefits"
            }</h2>
            <p *if="Stelle.Arbeitgeberleistung">${ad.employerBenefits ?? ""}</p>
          </div>
        </div>
        <!--Perspectives-->
        <div *if="Stelle.IncludePerspektiven" class="jt-sections-container">
          <div class="jt-rotate-A-section jt-secondary-section"></div>
          <div class="jt-rotate-B-section jt-secondary-section"></div>
          <div class="jt-angled-section">
            <h2 *if="Stelle.PerspektivenHeader">${
              ad.perspectivesHeader ?? "Perspektiven"
            }</h2>
            <p *if="Stelle.Perspektiven">${ad.perspectives ?? ""}</p>
          </div>
        </div>
        <!--Job Objective-->
        <div *if="Stelle.IncludeStellenziel" class="jt-sections-container">
          <div class="jt-rotate-A-section jt-secondary-section"></div>
          <div class="jt-rotate-B-section jt-secondary-section"></div>
          <div class="jt-angled-section">
            <h2 *if="Stelle.StellenzielHeader">${
              ad.jobObjectiveHeader ?? "Stellenziel"
            }</h2>
            <p *if="Stelle.Stellenziel">${ad.jobObjective ?? ""}</p>
          </div>
        </div>
      </div>
    </div>


    <div style="line-height: 20px; text-align: center;">
      <div class="jt-share-button-container">
        <a class="jt-share-button" href="https://facebook.com/sharer/sharer.php?u=${
          ad.urlEncoded ?? ""
        }"
          target="_blank" aria-label="Auf Facebook teilen">
          <i class="fa-brands fa-facebook-f"></i>
        </a>

        <a class="jt-share-button" href="https://twitter.com/intent/tweet?url=${
          ad.urlEncoded ?? ""
        }"
          target="_blank" aria-label="Auf Twitter teilen">
          <i class="fa-brands fa-x-twitter"></i>
        </a>

        <a class="jt-share-button"
          href="https://www.linkedin.com/shareArticle?mini=true&amp;url=${
            ad.urlEncoded ?? ""
          }&amp;title=${ad.jobTitleEncoded ?? ""}"
          target="_blank" aria-label="Auf LinkedIn teilen">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>

        <a class="jt-share-button"
          href="https://www.xing.com/app/user?op=share;url=${
            ad.urlEncoded ?? ""
          };title=${ad.jobTitleEncoded ?? ""}"
          target="_blank" aria-label="Auf Xing teilen">
          <i class="fa-brands fa-xing"></i>
        </a>

        <a class="jt-share-button"
          href="https://api.whatsapp.com/send?text=${
            ad.jobTitleEncoded ?? ""
          }%20${ad.urlEncoded ?? ""}"
          target="_blank" aria-label="Via WhatsApp teilen">
          <i class="fa-brands fa-whatsapp"></i>
        </a>

        <a class="jt-share-button"
          href="https://telegram.me/share/url?text=${
            ad.jobTitleEncoded ?? ""
          }&amp;url=${ad.urlEncoded ?? ""}"
          target="_blank" aria-label="Via Telegram teilen">
          <i class="fa-brands fa-telegram"></i>
        </a>

        <a class="jt-share-button"
          href="mailto:?subject=Aktuelles Stellenangebot - ${
            ad.jobTitleEncoded ?? ""
          }&amp;body=${ad.urlEncoded ?? ""}"
          aria-label="Per E-Mail teilen">
          <i class="fa-solid fa-envelope"></i>
        </a>

        <a class="jt-share-button" href="${
          ad.jobAdPdfUrl ?? ""
        }" target="_blank" aria-label="Als PDF drucken">
          <i class="fa-solid fa-print"></i>
        </a>
      </div>
    </div>

    <div *if="Stelle.GoogleMapsIntegration" class="jt-google-maps-container">
      <iframe id="GoogleMapsIframe" src="{{Stelle.GoogleMapsGdrpConformIframeLink}}" style="border:0;" width="100%"
        height="200" sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox allow-same-origin"></iframe>
    </div>

    <div class="jt-contact-container jt-contact-section">
      <div class="jt-contact-grid">
        <div *if="Stelle.IncludeKontaktText">
          <h2 *if="Stelle.KontaktTextHeader">${
            ad.contactTextHeader ?? "Kontakt"
          }</h2>
          <p *if="Stelle.KontaktText">${ad.contactText ?? "Text"}</p>
        </div>
        <div class="jt-contact-logo-section">
          <div class="jt-company-logo-large"></div>
        </div>
      </div>
    </div>
    <div style="margin-top: 1rem; text-align: center;">
      <a href="${ad.impressumUrl ?? ""}" class="jt-impressum-link ">
        ${ad.impressumText ?? "Impressum"}
      </a>
    </div>
  </div>
</body>

</html>
`;
    },
    async handleClickSendTemplateToAts() {
      switch (true) {
        case this.softwareIntegration.zvooveRecruit: {
          const confirmed = await DialogService.confirm(
            `
<h4>Anleitung:</h4>
<ol style="margin-left: 2rem">
  <li>
    Klicke auf den Button und es öffnen sich die Einstellungen der Textvorlagen im ATS
  </li>
  <li>
    Wähle im ATS den "Plus"-Button oben rechts
  </li>
  <li>
    Wähle "Anhang"
  </li>
  <li>
    Wähle "Textvorlage"
  </li>
  <li>
    Wähle bei "Bezeichnung" einen Namen, damit die Vorlage ganz oben sichtbar ist, z.B. <strong>"001 - Stellenanzeige"</strong>
  </li>
  <li>
    Klicke in das große Textfeld "Vorlage" und drücke Strg+V, um das Template in das Textfeld zu kopieren
  </li>
  <li>
    Stelle nun deine Farben und Schriftarten ein
  </li>
  <li>
    Klicke auf "Speichern und schließen"
  </li>
</ol>	
`,
            "Abbrechen",
            "ATS öffnen",
            "Template in ATS anlegen?",
            VuetifyColor.abort,
            VuetifyColor.success
          );

          if (confirmed) {
            this.copyAtsRecruitTemplateToClipboard();
            this.openAtsRecruitTemplateSettings();
          }
          break;
        }
        default:
          DialogService.alert("Keine ATS Integration aktiviert");
          break;
      }
    },
    isShowSendTemplateToAtsButton() {
      return this.softwareIntegration.zvooveRecruit;
    },
    openAtsRecruitTemplateSettings() {
      const link = `${this.softwareIntegration.zvooveRecruitLink}/recruiting/settings/text-template`;
      window.open(link, "_blank", PopUpWindowSize.externalSoftware);
    },
  },
});
</script>
