import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, _mergeProps(_ctx.vStyle.card, {
    class: "mb-5",
    border: "dashed"
  }), {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "mt-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                            modelValue: _ctx.newEntry.from,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newEntry.from) = $event)),
                            label: "Von",
                            type: "date",
                            density: "compact",
                            class: "my-0"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                            modelValue: _ctx.newEntry.until,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newEntry.until) = $event)),
                            label: "Bis",
                            type: "date",
                            density: "compact",
                            class: "my-0"
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"height":"8.5rem"} }, null, -1)),
                          _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle?.btn, {
                            block: "",
                            color: "success",
                            onClick: _ctx.addEntry
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { class: "mr-2" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode("fa-solid fa-calendar-plus")
                                ])),
                                _: 1
                              }),
                              _cache[7] || (_cache[7] = _createTextVNode(" Neuer Eintrag hinzufügen "))
                            ]),
                            _: 1
                          }, 16, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { noGutters: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                            modelValue: _ctx.newEntry.title,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newEntry.title) = $event)),
                            label: "Titel",
                            density: "compact",
                            class: "my-0 text-h6",
                            style: {"font-weight":"600"}
                          }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle?.input, {
                              modelValue: _ctx.newEntry.type,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newEntry.type) = $event)),
                              items: _ctx.employmentTypes,
                              "item-title": "description",
                              "item-value": "systemName",
                              label: "Typ",
                              density: "compact",
                              class: "my-0 ml-2",
                              "max-width": 250
                            }), null, 16, ["modelValue", "items"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                    modelValue: _ctx.newEntry.at,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newEntry.at) = $event)),
                    label: "Bei",
                    density: "compact",
                    class: "my-0"
                  }), null, 16, ["modelValue"]),
                  _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle?.input, {
                    modelValue: _ctx.newEntry.details,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newEntry.details) = $event)),
                    label: "Details",
                    rows: "5",
                    density: "compact",
                    class: "my-0"
                  }), null, 16, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}