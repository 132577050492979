import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        class: "mt-5",
        justify: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "10",
            md: "7",
            lg: "5",
            xl: "3"
          }, {
            default: _withCtx(() => [
              _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Melde dich an – Wir setzen uns mit dir in Verbindung", -1)),
              _cache[12] || (_cache[12] = _createElementVNode("p", null, " Bitte fülle das Formular aus, um mit uns in Kontakt zu treten. Sobald wir deine Angaben erhalten haben, prüfen wir diese und melden uns schnellstmöglich bei dir. ", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createVNode(_component_v_form, {
                ref: "formRef",
                modelValue: _ctx.isValid,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isValid) = $event)),
                "lazy-validation": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.form.firstName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.firstName) = $event)),
                            label: "Vorname",
                            rules: [_ctx.rules.required],
                            required: ""
                          }, _ctx.vStyle.input, { "data-cy": "firstName" }), null, 16, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.form.lastName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.lastName) = $event)),
                            label: "Nachname",
                            rules: [_ctx.rules.required],
                            required: ""
                          }, _ctx.vStyle.input, { "data-cy": "lastName" }), null, 16, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.form.company,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.company) = $event)),
                            label: "Unternehmen",
                            rules: [_ctx.rules.required],
                            required: ""
                          }, _ctx.vStyle.input, { "data-cy": "company" }), null, 16, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.form.email,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
                            label: "E-Mail",
                            rules: [_ctx.rules.required, _ctx.rules.email],
                            required: ""
                          }, _ctx.vStyle.input, { "data-cy": "email" }), null, 16, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: _ctx.form.phone,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.phone) = $event)),
                            label: "Telefon"
                          }, _ctx.vStyle.input, { "data-cy": "phone" }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "6",
                        class: "d-flex align-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, _mergeProps({
                            modelValue: _ctx.form.whatsapp,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.whatsapp) = $event)),
                            label: "Über WhatsApp erreichbar",
                            "true-value": true,
                            "false-value": false
                          }, _ctx.vStyle.input, { "data-cy": "whatsapp" }), null, 16, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_textarea, _mergeProps({
                    modelValue: _ctx.form.details,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.details) = $event)),
                    label: "Weitere Informationen (optional)",
                    hint: "Falls du weitere Informationen mit uns teilen möchtest.",
                    "persistent-hint": "",
                    rows: "3",
                    "auto-grow": ""
                  }, _ctx.vStyle.input), null, 16, ["modelValue"]),
                  _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_v_btn, {
                      color: "success",
                      onClick: _ctx.goToHome,
                      "data-cy": "home"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Zurück ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, _mergeProps({
                      color: "primary",
                      disabled: !_ctx.isValid,
                      onClick: _ctx.submitForm
                    }, _ctx.vStyle.btn, { "data-cy": "emailSend" }), {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" Absenden ")
                      ])),
                      _: 1
                    }, 16, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}