<template>
  <ValidateDeletionDialog
    :show="showValidateDeletionDialog"
    :email="deleteUserId"
    @validate="deleteUser"
    @update:show="updateValidateDeletionDialogVisibility"
  />
  <UserDialog
    :showDialog="showUserDialog"
    :mandants="mandants"
    :show="showUserDialog"
    :editMode="editMode"
    :editUserModel="userModel"
    @closeDialog="showUserDialog = false"
  />
  <AdminMenu />
  <AppHeader
    v-if="layout !== AppLayout.classic && layout !== AppLayout.persontobusiness"
  ></AppHeader>
  <v-container :fluid="true">
    <div class="d-flex flex-row-reverse mt-4 mb-4">
      <v-btn
        v-bind="vStyle.btn"
        v-if="hasFullCompanyUserAccess"
        density="comfortable"
        size="large"
        @click="addUser()"
      >
        <v-icon icon="fa-solid fa-plus" />
      </v-btn>
    </div>
    <v-row v-if="hasFullCompanyUserAccess">
      <v-col
        v-for="user in users"
        :key="user.email"
        :name="user.lastname"
        cols="12"
        sm="3"
      >
        <v-card
          v-bind="vStyle.card"
          :title="`${getSalutation(user.salutation)} ${
            user.forename || 'Daten'
          } ${user.lastname || 'eintragen'} `"
          :subtitle="user.email"
        >
          <v-card-text class="d-flex">
            <v-row>
              <v-col cols="12" sm="4">
                <div><i class="fa-solid fa-phone"></i>&nbsp;Telefon:</div>
                <div>
                  <i class="fa-solid fa-mobile-screen-button"></i
                  >&nbsp;&nbsp;Mobil:
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div>{{ user.tel }}</div>
                <div>{{ user.mobilePhone }}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              v-if="hasFullCompanyUserAccess"
              color="success"
              @click="editUser(user)"
              >Bearbeiten</v-btn
            >
            <v-btn
              v-if="hasRemoveCompanyUserAccess"
              color="abort"
              @click="removeUser(user)"
              >Löschen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="users.length === 0" class="no-entries">
      Keine Eintr&auml;ge vorhanden
    </div>
  </v-container>
</template>

<script lang="ts">
import { AppLayout } from "@/enums/app-layout.enum";
import { defineComponent } from "vue";
import { Mandant } from "@/models/mandant.model";
import { MandantService } from "@/services/api/mandant.service";
import { Salutation } from "@/enums/salutation.model";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import AppHeader from "@/components/header/Header.vue";
import UserDialog from "@/components/config/user/dialogs/UserDialog.vue";
import ValidateDeletionDialog from "@/components/modals/validate-deletion.modal.vue";
import { UserRoleHelper } from "../../../helper/user-role.helper";
import { AccessRule } from "../../../enums/access-rule.enum";

export default defineComponent({
  name: "ConfigUser",
  components: {
    AdminMenu,
    AppHeader,
    UserDialog,
    ValidateDeletionDialog,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      AppLayout: AppLayout,
      layout: this.$store.getters.appLayout,
      deleteUserId: "",
      editMode: false,
      hasFullCompanyUserAccess: UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.createCompanyUser
      ),

      hasRemoveCompanyUserAccess: UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.removeCompanyUser
      ),
      mandants: [] as Mandant[],
      mandantService: new MandantService(),
      showUserDialog: false,
      showValidateDeletionDialog: false,
      userModel: undefined as undefined | User,
      users: [] as User[],
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  created(): void {
    this.loadUsers();
    this.loadMandants();
  },
  methods: {
    addUser() {
      this.editMode = false;
      this.userModel = Object.assign({});
      this.showUserDialog = true;
    },

    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    editUser(user: User) {
      this.editMode = true;
      this.userModel = user;
      this.showUserDialog = true;
    },

    getSalutation(salutation: number): string {
      return salutation ? Salutation[salutation] : "Admin";
    },

    async loadMandants() {
      await this.mandantService.getMandants().then((mandants: any) => {
        this.mandants = Object.assign(mandants);
      });
    },

    removeUser(user: User) {
      this.deleteUserId = user._id as string;
      this.showValidateDeletionDialog = true;
    },

    async deleteUser(id: string) {
      this.userService.removeUser(id).then(() => {
        this.loadUsers();
      });
    },

    loadUsers() {
      this.userService
        .getUsers()
        .then((users: User[]) => (this.users = Object.assign(users)));
    },

    updateDialogVisibility(newValue: boolean) {
      this.showUserDialog = newValue;
      this.loadUsers();
    },

    updateValidateDeletionDialogVisibility(newValue: boolean) {
      this.showValidateDeletionDialog = newValue;
      this.loadUsers();
    },
  },
});
</script>
<style scoped>
.no-entries {
  font-size: x-large;
  text-align: center;
  width: 100%;
}
</style>
