import { Api } from "./api.class";
import {
  Application,
  Candidate,
  CandidateSearchCriteria,
  MatchCandidatesRequest,
} from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";
import { LinkingStatus } from "../../enums/dependency.enum";
import moment from "moment";
import { BackendMessage } from "../../enums/backend-messages.enum";
import ToastService from "../toast.service";

export class CandidateService {
  private api = Api.getInstance();
  private urlPath = "/candidate";

  async updateCandidatesStatusAts(
    candidateList: CandidateList[],
    atsDeterminesStatus: boolean,
    searchterm: string
  ) {
    try {
      const payload = {
        candidateList,
        atsDeterminesStatus,
        searchterm,
      };
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}`, payload);

      return response.data.response || {};
    } catch (error) {
      console.error("Error updating candidate status:", error);
      return { data: null, error };
    }
  }

  async getCandidateByUuid(uuid: string) {
    if (uuid) {
      return (
        await this.api.getBackendApi().get(`${this.urlPath}/uuid/${uuid}`)
      ).data.response as Candidate;
    }
    return undefined;
  }
  async getCandidateById(_id: string) {
    if (_id) {
      return (await this.api.getBackendApi().get(`${this.urlPath}/id/${_id}`))
        .data.response as Candidate;
    }
    return undefined;
  }
  async getCandidateList(status: string, mandants?: string[]) {
    try {
      if (!status || status.trim() === "") {
        throw new Error("Invalid status. It cannot be empty or whitespace.");
      }

      const params = new URLSearchParams();

      if (mandants && mandants.length > 0) {
        params.append("mandants", mandants.join(","));
      }

      const response = await this.api
        .getBackendApi()
        .get(
          `${this.urlPath}/${encodeURIComponent(status)}?${params.toString()}`
        );

      return response.data.response || {};
    } catch (error) {
      console.error("Error fetching candidates by search term:", error);
      return { data: null, error };
    }
  }

  async getCandidateListBySearchTerm(searchterm: string, mandants?: string[]) {
    try {
      if (!searchterm || searchterm.trim() === "") {
        throw new Error(
          "Invalid search term. It cannot be empty or whitespace."
        );
      }

      const params = new URLSearchParams();

      if (mandants && mandants.length > 0) {
        params.append("mandants", mandants.join(","));
      }

      const response = await this.api
        .getBackendApi()
        .get(
          `${this.urlPath}/searchterm/${encodeURIComponent(
            searchterm
          )}?${params.toString()}`
        );

      return response.data.response || {};
    } catch (error) {
      console.error("Error fetching candidates by search term:", error);
      return { data: null, error };
    }
  }

  async getCandidateListByDeepSearch(
    searchCriteria: CandidateSearchCriteria
  ): Promise<CandidateList[]> {
    try {
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}/search`, searchCriteria);

      return response.data.response || [];
    } catch (error) {
      console.error("Error searching candidates:", error);
      return [];
    }
  }

  async matchCandidates(
    matchRequest: MatchCandidatesRequest
  ): Promise<CandidateList[]> {
    try {
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}/match`, matchRequest);

      return response.data.response || [];
    } catch (error) {
      console.error("Error matching candidates:", error);
      return [];
    }
  }

  async postCandidate(candidate: Candidate) {
    if (candidate) {
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}`, candidate);
      return response.data.response;
    }
    return undefined;
  }

  async updateCandidate(candidateData: Candidate) {
    if (candidateData?._id) {
      return this.updateCandidateById(candidateData?._id, candidateData);
    } else if (!candidateData?._id && candidateData?.uuid) {
      return this.updateCandidateByUuid(candidateData?.uuid, candidateData);
    }
    return undefined;
  }
  async updateCandidateByUuid(uuid: string, candidateData: Candidate) {
    if (uuid && candidateData) {
      candidateData = this.clearCandidateData(candidateData);
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/uuid/${uuid}`, candidateData);
      return response.data.response;
    }
    return undefined;
  }

  async updateCandidateById(_id: string, candidateData: Candidate) {
    try {
      if (!_id || !candidateData) {
        throw new Error("ID and candidate data are required");
      }
      candidateData = this.clearCandidateData(candidateData);
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/${_id}`, candidateData);

      switch (response.data.response.linking) {
        case BackendMessage.candidate
          .candidateUuidChangedAndUpdatedInLinkedEmployee:
          ToastService.show(
            "Externe ID vom Kandidat hat sich geändert und wurde im verlinkten Persondatensatz angepasst!"
          );
          break;
        case BackendMessage.candidate
          .candidateUuidChangedAndLinkedEmployeeNotFound:
          ToastService.showError(
            "Externe ID vom Kandidat hat sich geändert, der verlinkte Persondatensatz wurde jedoch nicht gefunden!"
          );
          break;
        case BackendMessage.candidate
          .candidateUuidChangedAndLinkedEmployeeUpdateFailed:
          ToastService.showError(
            "Externe ID vom Kandidat hat sich geändert, der verlinkte Persondatensatz wurde jedoch nicht aktualisiert!"
          );
          break;
      }

      return response?.data?.response?.candidate;
    } catch (error) {
      console.error(`Error updating candidate with ID ${_id}:`, error);
      return { data: null, error };
    }
  }

  clearCandidateData(candidateData: Candidate) {
    const lastApplicationStatus =
      candidateData?.applications[candidateData?.applications?.length - 1]
        .status;
    if (
      lastApplicationStatus === LinkingStatus.rejected ||
      lastApplicationStatus === LinkingStatus.rejectedCandidate ||
      lastApplicationStatus === LinkingStatus.rejectedUnattractive ||
      lastApplicationStatus === LinkingStatus.inactive ||
      lastApplicationStatus === LinkingStatus.contractDate
    ) {
      candidateData.jobAdLeads = undefined; // clear storage
    }
    return candidateData;
  }

  async removeCandidate(id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error removing candidate:", error);
      return { data: null, error };
    }
  }

  async deleteCandidateByUuid(uuid: string): Promise<any> {
    try {
      if (!uuid) {
        throw new Error("UUID is required to delete a candidate.");
      }

      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/uuid/${uuid}`);

      return response.data;
    } catch (error) {
      console.error(`Error deleting candidate with UUID ${uuid}:`, error);
      return { data: null, error };
    }
  }
  mergeCandidateData(
    existingData: Partial<Candidate>,
    newData: Partial<Candidate>
  ): Candidate {
    const result = { ...existingData } as Candidate;

    if (!newData) {
      return result;
    }

    Object.entries(newData).forEach(([key, newValue]) => {
      const existingValue = existingData?.[key as keyof Candidate];

      if (Array.isArray(newValue)) {
        (result[key as keyof Candidate] as any[]) = this.mergeArrays(
          key,
          (Array.isArray(existingValue) ? existingValue : []) as any[],
          newValue.filter((item) => item !== null && item !== undefined)
        );
      } else if (newValue && typeof newValue === "object") {
        if (
          key === "notes" &&
          (!newValue || Object.keys(newValue).length === 0)
        ) {
          (result[key as keyof Candidate] as typeof existingValue) =
            existingValue;
          return;
        }
        (result[key as keyof Candidate] as any) = this.mergeCandidateData(
          (existingValue || {}) as Partial<Candidate>,
          newValue as Partial<Candidate>
        );
      } else {
        if (newValue !== null && newValue !== undefined && newValue !== "") {
          (result[key as keyof Candidate] as typeof newValue) = newValue;
        } else {
          (result[key as keyof Candidate] as typeof existingValue) =
            existingValue;
        }
      }
    });

    return result;
  }
  mergeArrays(key: string, existingArray: any[], newArray: any[]): any[] {
    let idKeys: string[] = [];
    let validApplications: Application[] = [];
    switch (key) {
      case "profiles":
      case "avatar":
      case "files":
      case "applications":
        validApplications = newArray.filter((app) => app.uuid !== "");
        if (validApplications.length > 0) {
          return validApplications;
        }
        idKeys = ["uuid"];
        break;
      case "communicationMeans":
        idKeys = ["value"];
        break;
      case "skills":
        idKeys = ["description"];
        break;
      case "careerSteps":
        existingArray = existingArray.map((item) => ({
          ...item,
          from: moment(item.from).format("YYYY-MM-DD"),
          until: moment(item.until).format("YYYY-MM-DD"),
        }));
        newArray = newArray.map((item) => ({
          ...item,
          from: moment(item.from).format("YYYY-MM-DD"),
          until: moment(item.until).format("YYYY-MM-DD"),
        }));
        idKeys = ["from", "until"];
        break;
      default:
        return newArray;
    }

    const mergedArray = [...existingArray];

    newArray.forEach((newItem) => {
      const index = existingArray.findIndex((item) => {
        return idKeys.every((idKey) => item[idKey] === newItem[idKey]);
      });

      if (index > -1) {
        const mergedItem = this.mergeCandidateData(
          existingArray[index],
          newItem
        );
        mergedArray[index] = mergedItem;
      } else {
        mergedArray.push(newItem);
      }
    });

    return mergedArray;
  }
}
