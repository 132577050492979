import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_ClickableSwitch = _resolveComponent("ClickableSwitch")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenu),
    (_ctx.layout !== _ctx.AppLayout.classic && _ctx.layout !== _ctx.AppLayout.persontobusiness)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "admin-menu" }, null, -1)),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_ctx.hasSoftwareIntegrationAccess)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      class: "mb-5",
                      width: 142,
                      "aspect-ratio": "1/1",
                      cover: "",
                      src: _ctx.settingsImage
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isUberAdmin)
                          ? (_openBlock(), _createBlock(_component_v_menu, {
                              key: 0,
                              activator: "parent"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_text, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_radio_group, {
                                          modelValue: _ctx.companyConfigModel.appLayout,
                                          "onUpdate:modelValue": [
                                            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyConfigModel.appLayout) = $event)),
                                            _cache[1] || (_cache[1] = ($event: any) => (
                    _ctx.setCompanyAppLayout(_ctx.companyConfigModel.appLayout)
                  ))
                                          ],
                                          label: "App Layout auswählen"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appLayoutOptions, (option) => {
                                              return (_openBlock(), _createBlock(_component_v_radio, {
                                                key: option.label,
                                                label: option.label,
                                                value: option.value
                                              }, null, 8, ["label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"]),
                                        _createVNode(_component_v_switch, {
                                          color: "primary",
                                          label: "header-logo",
                                          modelValue: _ctx.$store.state.headerLogo,
                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$store.state.headerLogo) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["src"]),
                    _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_form, { class: "pa-5" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                _createTextVNode("Unternehmensdaten")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.companyConfigModel.name,
                              "onUpdate:modelValue": [
                                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.companyConfigModel.name) = $event)),
                                _ctx.setSubmitButtonTrue
                              ],
                              label: "Unternehmensname"
                            }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                              "onUpdate:modelValue": [
                                _ctx.setSubmitButtonTrue,
                                _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyConfigModel.domain) = $event))
                              ],
                              modelValue: _ctx.companyConfigModel.domain,
                              label: "Domain"
                            }), null, 16, ["onUpdate:modelValue", "modelValue"]),
                            _createVNode(_component_v_divider),
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createTextVNode("Software Integration")
                              ])),
                              _: 1
                            }),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.dashboardAiKpiAnalysis))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 0,
                                  noLink: "",
                                  label: 'AI-Kennzahlenanalyse auf dem Dashboard',
                                  status: 
                _ctx.companyConfigModel?.softwareIntegration?.dashboardAiKpiAnalysis
              ,
                                  onHandleChange: _cache[5] || (_cache[5] = ($event: any) => (
                _ctx.handleChange(_ctx.SoftwareIntegrationType.dashboardAiKpiAnalysis)
              ))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.zvooveRecruit))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 1,
                                  label: 'ATS: Recruit',
                                  status: _ctx.companyConfigModel?.softwareIntegration?.zvooveRecruit,
                                  onLabelClick: _cache[6] || (_cache[6] = ($event: any) => (
                _ctx.handleLabelClick(_ctx.SoftwareIntegrationType.zvooveRecruit)
              )),
                                  onHandleChange: _cache[7] || (_cache[7] = ($event: any) => (
                _ctx.handleChange(_ctx.SoftwareIntegrationType.zvooveRecruit)
              ))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.zvooveOne))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 2,
                                  label: 'ERP: Universalschnittstelle (experimentell)',
                                  status: _ctx.companyConfigModel?.softwareIntegration?.zvooveOne,
                                  onLabelClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.zvooveOne))),
                                  onHandleChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.zvooveOne)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.pdHub))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 3,
                                  label: 'ERP: PD-Hub',
                                  status: _ctx.companyConfigModel?.softwareIntegration?.pdHub,
                                  onLabelClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.pdHub))),
                                  onHandleChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.pdHub)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.gameBar))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 4,
                                  status: _ctx.companyConfigModel?.softwareIntegration?.gameBar,
                                  label: 'Gamification (Scorebar für Aktivitäten)',
                                  onHandleChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.gameBar)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.indexAnzeigendaten))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 5,
                                  status: 
                _ctx.companyConfigModel?.softwareIntegration?.indexAnzeigendaten
              ,
                                  label: 'INDEX Anzeigendaten',
                                  onLabelClick: _cache[13] || (_cache[13] = ($event: any) => (
                _ctx.handleLabelClick(_ctx.SoftwareIntegrationType.indexAnzeigendaten)
              )),
                                  onHandleChange: _cache[14] || (_cache[14] = ($event: any) => (
                _ctx.handleChange(_ctx.SoftwareIntegrationType.indexAnzeigendaten)
              ))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (
                _ctx.checkAccess(_ctx.SoftwareIntegrationType.jobListAutoBfaSupportedAd)
              )
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 6,
                                  status: 
                _ctx.companyConfigModel?.softwareIntegration
                  ?.jobListAutoBfaSupportedAd
              ,
                                  label: 'JobList Anzeigen automatisch in BA-Betreuung',
                                  onHandleChange: _cache[15] || (_cache[15] = ($event: any) => (
                _ctx.handleChange(_ctx.SoftwareIntegrationType.jobListAutoBfaSupportedAd)
              ))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.payFlow))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 7,
                                  status: _ctx.companyConfigModel?.softwareIntegration?.payFlow,
                                  label: 'PayFlow',
                                  onLabelClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.payFlow))),
                                  onHandleChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.payFlow)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.whatsApp))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 8,
                                  label: 'WhatsApp Integration',
                                  status: _ctx.companyConfigModel?.softwareIntegration?.whatsApp,
                                  onLabelClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.whatsApp))),
                                  onHandleChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.whatsApp)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.workTime))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 9,
                                  status: _ctx.companyConfigModel?.softwareIntegration?.workTime,
                                  label: 'WorkTime Stundenerfassung',
                                  onHandleChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.workTime)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.wordPress))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 10,
                                  status: _ctx.companyConfigModel?.softwareIntegration?.wordPressPlugin,
                                  label: 'WordPress Plugin auf Unternehmensseite',
                                  onLabelClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.wordPress))),
                                  onHandleChange: _cache[22] || (_cache[22] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.wordPress)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true),
                            (_ctx.checkAccess(_ctx.SoftwareIntegrationType.zorst))
                              ? (_openBlock(), _createBlock(_component_ClickableSwitch, {
                                  key: 11,
                                  status: _ctx.companyConfigModel?.softwareIntegration?.zorst,
                                  label: 'ZORST Chrome Erweiterung',
                                  onLabelClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.handleLabelClick(_ctx.SoftwareIntegrationType.zorst))),
                                  onHandleChange: _cache[24] || (_cache[24] = ($event: any) => (_ctx.handleChange(_ctx.SoftwareIntegrationType.zorst)))
                                }, null, 8, ["status"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        (_ctx.isSubmitButtonActive)
                          ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
                              key: 0,
                              onSave: _ctx.updateCompanyConfig
                            }, null, 8, ["onSave"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_spacer),
                  (_ctx.hasSoftwareIntegrationAccess)
                    ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps({ ..._ctx.vStyle.btn, ...props })), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                  _createTextVNode("fa-solid fa-bars")
                                ])),
                                _: 1
                              }),
                              _cache[29] || (_cache[29] = _createTextVNode("Einstellungen "))
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
                                return (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: index,
                                  onClick: ($event: any) => (_ctx.setActiveComponent(item.component))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeConfigComponent), {
                  modelValue: _ctx.companyConfigModel,
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.companyConfigModel) = $event)),
                  onSave: _ctx.updateCompanyConfig
                }, null, 40, ["modelValue", "onSave"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}