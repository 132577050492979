export enum AccessRule {
  bugReport = "BUG_REPORT",
  configAi = "CONFIG_AI",
  configAiCompanyPrompts = "CONFIG_AI_COMPANY_PROMPTS",
  configAiEmailTemplates = "CONFIG_AI_EMAIL_TEMPLATES",
  configAiJobAdTemplate = "CONFIG_AI_JOB_AD_TEMPLATE",
  configAiUserCoaching = "CONFIG_AI_USER_COACHING",
  configAiUserPrompt = "CONFIG_AI_USER_PROMPT",
  configEmail = "CONFIG_EMAIL",
  configIndexAnzeigedaten = "CONFIG_INDEX_ANZEIGEDATEN",
  configProfileTemplate = "CONFIG_PROFILE_TEMPLATE",
  configReminderSettings = "CONFIG_REMINDER_SETTINGS",
  configStatusColor = "CONFIG_STATUS_COLOR",
  configTimelineEntries = "CONFIG_TIMELINE_ENTRIES",
  configZorstDebug = "CONFIG_ZORST_DEBUG",
  createCompanyUser = "CREATE_COMPANY_USER",
  createUser = "CREATE_USER",
  dataCleaning = "DATA_CLEANING",
  editCompany = "EDIT_COMPANY",
  fetchErpInitData = "FETCH_ERP_INIT_DATA",
  getAllCandidates = "GET_ALL_CANDIDATES",
  getAllCompanies = "GET_ALL_COMPANIES",
  getAllEmployees = "GET_ALL_EMPLOYEES",
  getAllMandants = "GET_ALL_MANDANTS",
  getAppConfig = "GET_APP_CONFIG",
  getBackendLogs = "GET_BACKEND_LOGS",
  getPdHubConfig = "GET_PDHUB_CONFIG",
  mandantCreate = "MANDANT_CREATE",
  mandantDelete = "MANDANT_DELETE",
  mandantRead = "MANDANT_READ",
  mandantUpdate = "MANDANT_UPDATE",
  payFlowAccept = "PAY_FLOW_ACCEPT",
  payFlowComplete = "PAY_FLOW_COMPLETE",
  payFlowErp = "PAY_FLOW_ERP",
  payFlowReject = "PAY_FLOW_REJECT",
  removeCompanyUser = "DELETE_COMPANY_USER",
  roleCreate = "ROLE_CREATE",
  roleDelete = "ROLE_DELETE",
  roleRead = "ROLE_READ",
  roleUpdate = "ROLE_UPDATE",
  saveAppConfig = "SAVE_APP_CONFIG",

  //Show Software integration page
  softwareintegration = "SOFTWARE_INTEGRATION",
  swiKpi = "SWI_KPI",
  swiBfa = "SWI_BFA",
  swiZvooveRecruit = "SWI_ZVOOVE_RECRUIT",
  swiZvooveOne = "SWI_ZVOOVE_ONE",
  swiGamebar = "SWI_GAMEBAR",
  swiPayFlow = "SWI_PAYFLOW",
  swiIndexAnz = "SWI_INDEXANZ",
  swiWhatsapp = "SWI_WA",
  swiWorktime = "SWI_WORKTIME",
  swiWordpress = "SWI_WP",
  swiZorst = "SWI_ZORST",
  swiPdHub = "SWI_PDHUB",

  task = "GET_ALL_TASK",

  //Is UberAdmin
  UBER = "UBER",
}
