import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "mr-2"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("fa-solid fa-coins")
            ])),
            _: 1
          }),
          _cache[6] || (_cache[6] = _createTextVNode("PayFlow Einstellungen "))
        ]),
        _createVNode(_component_v_form, {
          onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localConfig.salaryAdjustments, (salaryAdjustment, index) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: index,
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, _mergeProps({ ref_for: true }, _ctx.vStyle.card, { class: "mb-4 pa-4" }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5 d-flex" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(salaryAdjustment.payrollInfoType || "Infotyp wählen") + " ", 1),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                icon: "",
                                density: "compact",
                                variant: "text",
                                onClick: ($event: any) => (_ctx.removeSalaryAdjustment(index))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, null, {
                                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                                      _createTextVNode("fa-solid fa-xmark")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                label: "Payroll Infotyp",
                                modelValue: salaryAdjustment.payrollInfoType,
                                "onUpdate:modelValue": ($event: any) => ((salaryAdjustment.payrollInfoType) = $event),
                                items: _ctx.payrollInfoTypeOptions,
                                "item-title": "label",
                                "item-value": "label"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: salaryAdjustment.description,
                                "onUpdate:modelValue": ($event: any) => ((salaryAdjustment.description) = $event),
                                label: "Name in ERP"
                              }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: salaryAdjustment.adjustmentTypeId,
                                "onUpdate:modelValue": ($event: any) => ((salaryAdjustment.adjustmentTypeId) = $event),
                                label: "Abzugsart ID"
                              }), null, 16, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: salaryAdjustment.type,
                                "onUpdate:modelValue": ($event: any) => ((salaryAdjustment.type) = $event),
                                items: _ctx.salaryAdjustmentType,
                                "item-title": "value",
                                "item-value": "label",
                                label: "Art"
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "items"]),
                              _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                modelValue: salaryAdjustment.accountNumber,
                                "onUpdate:modelValue": ($event: any) => ((salaryAdjustment.accountNumber) = $event),
                                label: "Sachkonto",
                                type: "number"
                              }), null, 16, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { class: "border-dashed mb-4 pa-4" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("Neuer Eintrag")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.newSalaryAdjustment.payrollInfoType,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newSalaryAdjustment.payrollInfoType) = $event)),
                              items: _ctx.payrollInfoTypeOptions,
                              "item-title": "label",
                              "item-value": "label",
                              label: "Payroll Infotyp"
                            }), null, 16, ["modelValue", "items"]),
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.newSalaryAdjustment.description,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newSalaryAdjustment.description) = $event)),
                              label: "Name in ERP"
                            }), null, 16, ["modelValue"]),
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.newSalaryAdjustment.adjustmentTypeId,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newSalaryAdjustment.adjustmentTypeId) = $event)),
                              label: "Abzugsart ID"
                            }), null, 16, ["modelValue"]),
                            _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.newSalaryAdjustment.type,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newSalaryAdjustment.type) = $event)),
                              items: _ctx.salaryAdjustmentType,
                              "item-title": "value",
                              "item-value": "label",
                              label: "Art"
                            }), null, 16, ["modelValue", "items"]),
                            _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                              modelValue: _ctx.newSalaryAdjustment.accountNumber,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newSalaryAdjustment.accountNumber) = $event)),
                              label: "Sachkonto",
                              type: "number"
                            }), null, 16, ["modelValue"]),
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              variant: "text",
                              onClick: _ctx.addSalaryAdjustment
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode(" Hinzufügen ")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 16)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}