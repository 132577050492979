<template>
  <v-list>
    <v-list-item v-for="profile in profiles" :key="profile._id">
      <div class="d-flex">
        <div
          class="d-flex"
          :style="{ cursor: 'pointer' }"
          @click="getExistingProfile(profile)"
        >
          <div v-if="profile?.uuid">
            <img
              :src="`${$store.getters.getEnv?.assetsUrl}/icons/icon-ats.svg`"
              class="communication-container-img-icon"
              alt="zvoove Recruit Icon"
            />
          </div>
          <div v-if="profile.html">
            <img
              :src="`${$store.getters.getEnv?.assetsUrl}/app-layout/${$store.getters.appLayout}/favicon/favicon.ico`"
              class="communication-container-img-icon"
              alt="Dispositioner:er Icon"
            />
          </div>
          <v-list-item-title>{{ profile.description }}</v-list-item-title>
        </div>
        <v-spacer />
        <v-btn
          v-if="profile?._id && showDeleteButton"
          icon="fa-solid fa-xmark"
          class="ml-2 text-medium-emphasis"
          size="small"
          variant="text"
          density="compact"
          @click="deleteProfile(profile?._id)"
        />
      </div>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Profile } from "../../../models/candidate.model";

enum existingProfilEmit {
  getExistingProfile = "getExistingProfile",
  deleteProfile = "deleteProfile",
}

export default defineComponent({
  name: "ExistingProfiles",
  emits: [
    existingProfilEmit.getExistingProfile,
    existingProfilEmit.deleteProfile,
  ],
  props: {
    profiles: {
      type: Object as PropType<Profile[]>,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteProfile(profile: string) {
      this.$emit(existingProfilEmit.deleteProfile, profile);
    },
    getExistingProfile(profile: Profile) {
      this.$emit(existingProfilEmit.getExistingProfile, profile);
    },
  },
});
</script>
<style scoped>
.ats-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
</style>
