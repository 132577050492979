import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "mt-4 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_JobAdTemplate = _resolveComponent("JobAdTemplate")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ConfigSaveButton = _resolveComponent("ConfigSaveButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, [
      _createVNode(_component_v_icon, {
        size: "small",
        class: "mr-2"
      }, {
        default: _withCtx(() => _cache[33] || (_cache[33] = [
          _createTextVNode("fa-solid fa-rectangle-ad")
        ])),
        _: 1
      }),
      _cache[34] || (_cache[34] = _createTextVNode("Vorlage Stellenanzeigen "))
    ]),
    _createVNode(_component_v_form, {
      class: "mt-9",
      modelValue: _ctx.isValid,
      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.isValid) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.jobTitle,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localAiData.template.jobAd.jobTitle) = $event)),
                          label: "Stellen",
                          class: "mb-4"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "7"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.tasksHeader,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAiData.template.jobAd.tasksHeader) = $event)),
                          label: "Aufgaben Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.tasks,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localAiData.template.jobAd.tasks) = $event)),
                          label: "Aufgaben"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.technicalRequirementsHeader,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localAiData.template.jobAd.technicalRequirementsHeader) = $event)),
                          label: "Fachliche Anforderungen Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.technicalRequirements,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localAiData.template.jobAd.technicalRequirements) = $event)),
                          label: "Fachliche Anforderungen"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.personalRequirementsHeader,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localAiData.template.jobAd.personalRequirementsHeader) = $event)),
                          label: "Persönliche Anforderungen Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.personalRequirements,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localAiData.template.jobAd.personalRequirements) = $event)),
                          label: "Persönliche Anforderungen",
                          rows: "5"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_divider, { class: "mt-4 mb-8" }),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.metaKeywords,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localAiData.template.jobAd.metaKeywords) = $event)),
                          label: "Meta Beschreibung"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.employerBenefitsHeader,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localAiData.template.jobAd.employerBenefitsHeader) = $event)),
                          label: "Benefits Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.employerBenefits,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localAiData.template.jobAd.employerBenefits) = $event)),
                          label: "Benefits"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.perspectivesHeader,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localAiData.template.jobAd.perspectivesHeader) = $event)),
                          label: "Perspektiven Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.perspectives,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localAiData.template.jobAd.perspectives) = $event)),
                          label: "Perspektiven"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.jobObjectiveHeader,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localAiData.template.jobAd.jobObjectiveHeader) = $event)),
                          label: "Stellenziel Header"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.jobObjective,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localAiData.template.jobAd.jobObjective) = $event)),
                          label: "Stellenziel"
                        }), null, 16, ["modelValue"]),
                        _createVNode(_component_v_divider, { class: "mt-4 mb-8" }),
                        _createVNode(_component_v_textarea, _mergeProps(_ctx.vStyle.input, {
                          density: "compact",
                          modelValue: _ctx.localAiData.template.jobAd.metaDescription,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localAiData.template.jobAd.metaDescription) = $event)),
                          label: "Meta Keywords"
                        }), null, 16, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, _normalizeProps(_guardReactiveProps(_ctx.vStyle.card)), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          size: "small",
                          class: "mr-2"
                        }, {
                          default: _withCtx(() => _cache[35] || (_cache[35] = [
                            _createTextVNode("fa-solid fa-rocket")
                          ])),
                          _: 1
                        }),
                        _cache[36] || (_cache[36] = _createTextVNode(" So erstellst Du Deine KI-Vorlage "))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, { class: "text-body-2 mt-3" }, {
                      default: _withCtx(() => [
                        _cache[47] || (_cache[47] = _createTextVNode(" Auf der linken Seite gibst Recurion ein Beispiel für eine Stellenanzeige ein. Unterhalb der Felder kannst Du die Vorlage Deiner Stelleanzeige anpassen und als Template in Dein ATS importieren. ")),
                        _cache[48] || (_cache[48] = _createElementVNode("h4", { class: "text-subtitle-1 mt-8 mb-2" }, " Die KI unterstützt Dich dabei: ", -1)),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-check",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[37] || (_cache[37] = [
                            _createTextVNode(" Sie analysiert Deinen Stil und passt sich an ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-check",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[38] || (_cache[38] = [
                            _createTextVNode(" Generiert passende Aufgaben & Anforderungen ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-check",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[39] || (_cache[39] = [
                            _createTextVNode(" Passt den Kommunikationsstil flexibel an ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-check",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                            _createTextVNode(" Ergänzt relevante Fachbegriffe ")
                          ])),
                          _: 1
                        }),
                        _cache[49] || (_cache[49] = _createElementVNode("h4", { class: "text-subtitle-1 mt-8 mb-2" }, " Tipps für beste Ergebnisse: ", -1)),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-lightbulb",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[41] || (_cache[41] = [
                            _createTextVNode(" Am besten lässt Du die Felder für Aufgaben und Anforderungen leer, die AI generiert dann passende Aufgaben und Anforderungen. ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-lightbulb",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[42] || (_cache[42] = [
                            _createTextVNode(" Du kannst allgemeine Informationen wie Stellenziel, Benefits und Perspektiven in die Felder schreiben und Recurion genierert dann passend aus diesen Informationen die Texte für Deine Stellenanzeige. ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-lightbulb",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[43] || (_cache[43] = [
                            _createTextVNode(" Du kannst auch Prompts oder Anweisungen in die Felder schreiben. ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-lightbulb",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[44] || (_cache[44] = [
                            _createTextVNode(" Je konkreter Deine Angaben, desto besser das Ergebnis ")
                          ])),
                          _: 1
                        }),
                        _cache[50] || (_cache[50] = _createElementVNode("h4", { class: "text-subtitle-1 mt-8 mb-2" }, "Design personalisieren", -1)),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-palette",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[45] || (_cache[45] = [
                            _createTextVNode(" Passe die Farben an Deine Marke an, wähle Deine Schriftart und füge Dein Logo ein. ")
                          ])),
                          _: 1
                        }),
                        _cache[51] || (_cache[51] = _createElementVNode("h4", { class: "text-subtitle-1 mt-8 mb-2" }, "Vorschau und Export", -1)),
                        _createVNode(_component_v_list_item, {
                          "prepend-icon": "fa-solid fa-eye",
                          density: "compact"
                        }, {
                          default: _withCtx(() => _cache[46] || (_cache[46] = [
                            _createTextVNode(" Prüfe das Ergebnis in der Live-Vorschau und exportiere die fertige Anzeige für Dein ATS-System. ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Primäre Farbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.primaryColor,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localAiData.template.jobAd.primaryColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Sekundäre Farbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.secondaryColor,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localAiData.template.jobAd.secondaryColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Hintergrundfarbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.bgColor,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localAiData.template.jobAd.bgColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_v_slider, _mergeProps({ class: "mt-10" }, _ctx.vStyle.input, {
                      modelValue: _ctx.localAiData.template.jobAd.shadow1,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localAiData.template.jobAd.shadow1) = $event)),
                      step: "0.01",
                      min: "0.0",
                      max: "1",
                      label: "Schatten 1"
                    }), null, 16, ["modelValue"]),
                    _createVNode(_component_v_slider, _mergeProps(_ctx.vStyle.input, {
                      modelValue: _ctx.localAiData.template.jobAd.shadow2,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.localAiData.template.jobAd.shadow2) = $event)),
                      step: "0.01",
                      min: "0.0",
                      max: "1",
                      label: "Schatten 2"
                    }), null, 16, ["modelValue"])
                  ]),
                  _: 1
                }, 16)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_slider, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.rotateThreshold,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.localAiData.template.jobAd.rotateThreshold) = $event)),
                  step: "0.1",
                  min: "0",
                  max: "45",
                  label: "Drehung"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_slider, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.shiftX,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.localAiData.template.jobAd.shiftX) = $event)),
                  step: "0.01",
                  min: "0",
                  max: "5",
                  label: "Versatz X"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_slider, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.shiftY,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.localAiData.template.jobAd.shiftY) = $event)),
                  step: "0.01",
                  min: "0",
                  max: "5",
                  label: "Versatz Y"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_slider, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.rounding,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.localAiData.template.jobAd.rounding) = $event)),
                  step: "0.1",
                  min: "0",
                  max: "20",
                  label: "Rundung"
                }), null, 16, ["modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_text_field, _mergeProps({ density: "compact" }, _ctx.vStyle.input, {
                    modelValue: _ctx.localAiData.template.jobAd.imageUrl,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.localAiData.template.jobAd.imageUrl) = $event)),
                    label: "Header Bild URL",
                    class: "mr-3"
                  }), null, 16, ["modelValue"]),
                  _createVNode(_component_v_text_field, _mergeProps({ density: "compact" }, _ctx.vStyle.input, {
                    modelValue: _ctx.localAiData.template.jobAd.logoUrl,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.localAiData.template.jobAd.logoUrl) = $event)),
                    label: "Logo URL",
                    class: "ml-3"
                  }), null, 16, ["modelValue"])
                ]),
                _createVNode(_component_JobAdTemplate, {
                  jobAdTemplateData: _ctx.localAiData.template.jobAd
                }, null, 8, ["jobAdTemplateData"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Schrift auf primäre Farbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.onPrimaryColor,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.localAiData.template.jobAd.onPrimaryColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Schrift auf sekundäre Farbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.onSecondaryColor,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.localAiData.template.jobAd.onSecondaryColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_v_card, _mergeProps({ subtitle: "Schrift auf tertiäre Farbe" }, _ctx.vStyle.card, { class: "mb-5" }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_color_picker, {
                      modelValue: _ctx.localAiData.template.jobAd.onTertiaryColor,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.localAiData.template.jobAd.onTertiaryColor) = $event)),
                      modes: ['hex'],
                      width: "100%"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 16),
                _createVNode(_component_v_select, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.fontFamily,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.localAiData.template.jobAd.fontFamily) = $event)),
                  items: _ctx.fontFamilies,
                  "item-title": "name",
                  "item-value": "value",
                  label: "Schriftart",
                  class: "mt-10"
                }), null, 16, ["modelValue", "items"]),
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.impressumUrl,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.localAiData.template.jobAd.impressumUrl) = $event)),
                  label: "Impressum URL"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                  modelValue: _ctx.localAiData.template.jobAd.impressumText,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.localAiData.template.jobAd.impressumText) = $event)),
                  label: "Impressum Text"
                }), null, 16, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.isDirty)
      ? (_openBlock(), _createBlock(_component_ConfigSaveButton, {
          key: 0,
          onSave: _ctx.handleSubmit
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true)
  ], 64))
}