export enum AppLayout {
  persontobusiness = "persontobusiness",
  classic = "classic",
  dispositioner = "dispositioner",
  pdmaster = "pdmaster",
  liebeZeitarbeit = "liebeZeitarbeit",
}

export enum AppLayoutName {
  localStorage = "appLayoutDispositioner",
}

export enum LeftMenuIconDiameter {
  dispositionerImageSize = "3rem",
  dispositionerIconSize = "2.5rem",
  dispositionerSheetHeight = "3.5rem",
  pdmasterImageSize = "3.5rem",
  pdmasterIconSize = "x-large",
  pdmasterSheetHeight = "3rem",
}

export enum AppTitle {
  classic = "Disposition:er Classic",
  dispositioner = "Disposition:er",
  pdmaster = "PD Master",
  persontobusiness = "p2b intern",
  liebeZeitarbeit = "Masterclass App",
}

export enum AppPageAndMenuTitle {
  ai = "Recurion.ai Einstellungen",
  appConfig = "App Konfiguration",
  backendLogs = "Backend Logs",
  company = "Firmenkunden",
  dataCleaning = "Initialisierung & Datenbereinigung",
  dashboard = "Dashboard",
  logout = "Ausloggen",
  mandants = "Niederlassungen",
  roles = "Rollen",
  softwareIntegration = "Software & Schnittstellen",
  version = "Version",
  user = "Benutzer",
}

export enum HeaderSpinner {
  pdLoading = "pdhub-loading.gif",
  recurion = "recurion.gif",
}

export enum HeaderSpinnerSize {
  small,
  normal,
  classic,
}

export enum DispatcherBoardColumnHeight {
  headerHeight = 280,
  standard = 500,
  iLoaderSubtract = 20,
  iLoaderDistance = 200,
}

//TODO: low prio: get screen height for profile from browser
//(not for external software after test its important to supress the recruit menu)
//service to get screen resolution from browser

export enum PopUpWindowSize {
  profile = "width=800,height=1000",
  externalSoftware = "width=950,height=900",
}
