import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import { mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { ThemeDefinition } from "vuetify";
import "@/scss/styles.scss";
import { watch } from "vue";
import store from "../store/store";
import { MutationType } from "../enums/vuex-types.enum";

export enum VuetifyColor {
  primary = "primary",
  secondary = "secondary",
  abort = "abort",
  success = "success",
  warning = "warning",
  info = "info",
  error = "error",
  //TODO MVCGA
}

const standardTheme = {
  light: {
    dark: false,
    // TODO all color to enum:
    colors: {
      background: "#F5F5F5",
      surface: "#FFFFFF",
      "surface-bright": "#F9F9F9",
      "surface-light": "#E0E0E0",
      "surface-variant": "#757575",
      "on-surface-variant": "#FFFFFF",
      border: "#494949",
      "border-light": "#E0E0E0",
      "border-medium": "#757575",
      primary: "#1C365E",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#15243E",
      secondary: "#008B8B",
      "on-secondary": "#FFFFFF",
      "secondary-darken-1": "#005F5F",
      "on-secondary-darken-1": "#FFFFFF",
      tertiary: "#EB8C2F",
      "tertiary-darken-1": "#B56623",
      "on-tertiary": "#FFFFFF",
      "on-tertiary-darken-1": "#FFFFFF",
      tableHeader: "#C7E0DC",
      "on-tableHeader": "#1C365E",
      tableHover: "#1C365E09",
      tableFirstColumn: "#E6EEF2",
      "on-tableFirstColumn": "#1C365E",
      card: "#F4F7FA",
      "card-darken-1": "#E8EDF3",
      "on-card": "#15243E",
      cardHover: "#F0F4F8",
      "on-cardHover": "#000",
      cardMatch: "#C2D8DF",
      "on-cardMatch": "#1C365E",
      matchHover: "#D2E1DA",
      "on-matchHover": "#1C365E",
      cardPerfectMatch: "#A8CBAF",
      "on-cardPerfectMatch": "#1C365E",
      perfectMatchHover: "#BDD4C5",
      "on-perfectMatchHover": "#1C365E",
      toast: "#333",
      "on-toast": "#fff",
      error: "#b71c1c",
      "on-error": "#fff",
      "error-darken-1": "#891414",
      info: "#0d47a1",
      "on-info": "#fff",
      "info-darken-1": "#09306b",
      abort: "#C62828",
      success: "#2e7d32",
      "on-success": "#fff",
      "success-darken-1": "#1b5e20",
      warning: "#ffc107",
      "on-warning": "#000",
      "warning-darken-1": "#ff8f00",
    },
    variables: {
      "border-color": "#008B8B",
      "border-opacity": 0.05,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.3,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#212529",
      "theme-on-kbd": "#FFFFFF",
      "theme-code": "#F5F5F5",
      "theme-on-code": "#000000",
    },
  },
  dark: {
    dark: true,
    colors: {
      background: "#121212",
      surface: "#1E1E1E",
      "surface-bright": "#2A2A2A",
      "surface-light": "#333333",
      "surface-variant": "#444E56",
      "on-surface-variant": "#F5F5F5",
      border: "#D0D0D0",
      "border-light": "#FFFFFF",
      "border-medium": "#A0A0A0",
      primary: "#D0E2F9",
      "on-primary": "#FFFFFF",
      "primary-darken-1": "#2C486A",
      secondary: "#008B8B",
      "on-secondary": "#FFFFFF",
      "secondary-darken-1": "#005F5F",
      "on-secondary-darken-1": "#FFFFFF",
      tertiary: "#EB8C2F",
      "tertiary-darken-1": "#B56623",
      "on-tertiary": "#FFFFFF",
      "on-tertiary-darken-1": "#FFFFFF",
      tableHeader: "#335E55",
      "on-tableHeader": "#E6EEF2",
      tableHover: "#1C365E09",
      tableFirstColumn: "#2F3E47",
      "on-tableFirstColumn": "#F5F5F5",
      card: "#2A2A2A",
      "card-darken-1": "#212121",
      "on-card": "#E0E0E0",
      cardHover: "#3A3A3A",
      "on-cardHover": "#FFFFFF",
      cardMatch: "#4C5A62",
      "on-cardMatch": "#F5F5F5",
      matchHover: "#58666F",
      "on-matchHover": "#F5F5F5",
      cardPerfectMatch: "#41594B",
      "on-cardPerfectMatch": "#FFFFFF",
      perfectMatchHover: "#4A6052",
      "on-perfectMatchHover": "#FFFFFF",
      toast: "#333",
      "on-toast": "#fff",
      error: "#b71c1c",
      "on-error": "#fff",
      "error-darken-1": "#891414",
      info: "#0d47a1",
      "on-info": "#fff",
      "info-darken-1": "#09306b",
      abort: "#C62828",
      success: "#2e7d32",
      "on-success": "#fff",
      "success-darken-1": "#1b5e20",
      warning: "#ffc107",
      "on-warning": "#000",
      "warning-darken-1": "#ff8f00",
    },
    variables: {
      "border-color": "#2196F3",
      "border-opacity": 0.12,
      "high-emphasis-opacity": 0.87,
      "medium-emphasis-opacity": 0.6,
      "disabled-opacity": 0.38,
      "idle-opacity": 0.04,
      "hover-opacity": 0.3,
      "focus-opacity": 0.12,
      "selected-opacity": 0.08,
      "activated-opacity": 0.12,
      "pressed-opacity": 0.12,
      "dragged-opacity": 0.08,
      "theme-kbd": "#F5F5F5",
      "theme-on-kbd": "#212529",
      "theme-code": "#1E1E1E",
      "theme-on-code": "#F5F5F5",
    },
  },
};
function updateCssVariablesFromVuetify(theme: ThemeDefinition | undefined) {
  if (!theme || !theme.colors) {
    console.warn("Theme or theme colors are undefined");
    return;
  }

  const root = document.documentElement;

  // Update color variables
  Object.entries(theme.colors).forEach(([key, value]) => {
    if (typeof value === "string") {
      root.style.setProperty(`--color-${key}`, value);
    }
  });

  // Update variables (if defined)
  if (theme.variables) {
    Object.entries(theme.variables).forEach(([key, value]) => {
      if (typeof value === "string") {
        root.style.setProperty(`--${key}`, value);
      }
    });
  }
}

export function createVuetifyInstance(appLayout: string) {
  let lightTheme = standardTheme.light;
  let darkTheme = standardTheme.dark;
  const appLayoutSource = `${store.getters.getEnv.assetsUrl}/app-layout/${appLayout}`;

  // create vuetify instance with standard themes
  const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        fa,
        mdi,
      },
    },
    theme: {
      defaultTheme: "light",
      themes: {
        light: lightTheme,
        dark: darkTheme,
      },
    },
  });

  // get themes from server
  Promise.all([
    fetch(`${appLayoutSource}/vuetify.json`),
    fetch(`${appLayoutSource}/vStyle.json`),
  ])
    .then(([vuetifyResponse, vStyleResponse]) =>
      Promise.all([vuetifyResponse.json(), vStyleResponse.json()])
    )
    .then(([vuetifyThemes, vStyleThemes]) => {
      lightTheme = vuetifyThemes.light;
      darkTheme = vuetifyThemes.dark;

      if (vStyleThemes) store.commit(MutationType.setVStyle, vStyleThemes);

      // update themes of vuetify instance
      vuetify.theme.themes.value.light = lightTheme as any;
      vuetify.theme.themes.value.dark = darkTheme as any;

      updateCssVariablesFromVuetify(
        vuetify.theme.global.name.value === "dark" ? darkTheme : lightTheme
      );
    })
    .catch((error) => {
      console.error("Detaillierter Fehler:", error);
      console.warn("Fehler beim Laden der Themes:", error);
    });

  watch(vuetify.theme.global.name, (newTheme) => {
    const theme = (
      vuetify.theme.themes as unknown as Record<string, ThemeDefinition>
    )[newTheme];
    updateCssVariablesFromVuetify(theme);
  });

  return vuetify;
}
