import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "mb-5 d-flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.show,
    hideTitle: true,
    onCloseDialog: _ctx.handleConfirm,
    width: 'var(--d-w-sm)'
  }, {
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-4 mt-2 mb-5 text-h6 text-center" }, {
        default: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _ctx.message }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_btn, _mergeProps(_ctx.vStyle.btn, {
          "min-width": "100",
          color: _ctx.btnColor,
          onClick: _ctx.handleConfirm
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 16, ["color", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}