<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
    >
      <v-card style="min-height: var(--d-h-full)" class="pa-0">
        <div>
          <v-sheet
            color="surface"
            style="z-index: 1000; position: fixed; top: 0; width: 99%"
          >
            <v-card-title>
              <v-card :rounded="vStyle.card.rounded">
                <v-tabs
                  v-model="activeTab"
                  bg-color="primary"
                  slider-color="on-primary"
                  @click="clickOnTab(activeTab)"
                >
                  <v-tab
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :value="index"
                  >
                    {{ tab }}
                  </v-tab>
                  <v-spacer></v-spacer>
                  <v-tab @click="closeModal()"
                    ><v-icon size="x-large"
                      >fa-solid fa-circle-xmark</v-icon
                    ></v-tab
                  >
                </v-tabs>
              </v-card>
            </v-card-title>
          </v-sheet>
          <div
            class="dialog-edit-item-before-menu-shadow dialog-edit-item-top-shadow"
          ></div>
        </div>
        <v-card-text>
          <v-tabs-window v-model="activeTab">
            <v-tabs-window-item
              v-for="(tab, index) in tabs"
              :key="index"
              :value="index"
              class="mt-15"
            >
              <!--Tab data + address-->
              <v-row v-if="index === 0">
                <v-card-title>Allgemeine Daten</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localEmployee.firstName"
                    label="Vorname"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localEmployee.lastName"
                    label="Nachname"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-bind="vStyle.input"
                    v-model="localEmployee.salutation"
                    :items="salutations"
                    label="Anrede"
                  />
                </v-col>
                <v-card-title>Kommunikation</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-bind="vStyle.input"
                    v-model="localEmployee.email"
                    label="Email"
                  />
                </v-col>
                <template v-if="localEmployee.address">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.phone1"
                      label="Telefon 1"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.phone2"
                      label="Telefon 2"
                    />
                  </v-col>
                  <v-card-title>Adresse</v-card-title>
                  <v-divider class="mb-3"></v-divider>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.street"
                      label="Straße"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.postalCode"
                      label="PLZ"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.city"
                      label="Ort"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.countryId"
                      label="Staat"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-bind="vStyle.input"
                      v-model="localEmployee.address.stateId"
                      label="Bundesland"
                    />
                  </v-col>
                </template>
              </v-row>
              <!--Tab assignments-->
              <v-row v-else-if="index === 1">
                <v-card-title>Einsätze</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-list>
                  <v-list-item
                    v-for="assignment in localEmployee.assignments"
                    :key="assignment.assignmentId"
                  >
                    <div>
                      <v-list-item-title>
                        {{ assignment.clientName }} -
                        {{ assignment.workLocation }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ assignment.jobTitle }} ({{ assignment.from }} -
                        {{ assignment.to }})
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </v-row>
              <!--Tab DSGVO-->
              <v-row v-else-if="index === 2">
                <v-card-title>Datenschutzeinwilligungen</v-card-title>
                <v-divider class="mb-3"></v-divider>
                <v-list>
                  <v-list-item
                    v-for="dsgvo in localEmployee.dataPrivacyConsents"
                    :key="dsgvo.id"
                  >
                    <div>
                      <v-list-item-title>
                        {{ dsgvo.id }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Gültig bis: {{ dsgvo.validUntil }} - Löschen:
                        {{ dsgvo.delete }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </v-list>
              </v-row>
              <!--Tab Kandidat (ATS)-->
              <v-row v-else-if="index === 3">
                <v-alert
                  class="ma-4 pa-4"
                  v-if="
                    !localEmployee?.candidateObjectId && !linkedCandidate?._id
                  "
                  variant="tonal"
                  border="start"
                  color="secondary"
                  title="Kein ATS Kandidat mit diesem Personaldatensatz verknüpft"
                  icon="fa-solid fa-link-slash"
                  closable
                >
                  Die Verknüpfung wird dann in der Regel automatisch hergestellt
                  kann jedoch auch manuell über die Mitarbeiterkarte erfolgen.
                </v-alert>
                <v-alert
                  class="ma-4 pa-4"
                  v-if="
                    localEmployee?.candidateObjectId && !linkedCandidate?._id
                  "
                  variant="tonal"
                  border="start"
                  color="error"
                  title="Fehler beim Laden des verknüften Kandidaten"
                  icon="fa-solid fa-circle-xmark"
                  closable
                >
                  Der verknüpfte Kandidat konnte in der Datenbank nicht gefunden
                  werden und ist eventuell gelöscht worden!
                  <v-btn
                    block
                    class="mt-15"
                    color="error"
                    variant="text"
                    @click="unlinkCandidate"
                  >
                    <v-icon class="mr-1">fa-solid fa-link-slash</v-icon>
                    Kandidatendaten trennen
                  </v-btn>
                </v-alert>
                <div style="width: 100%" v-if="linkedCandidate?._id">
                  <v-card-title class="d-flex">
                    <div>
                      <v-icon class="mr-1">fa-solid fa-link</v-icon>
                      verlinkt mit Kandidat
                      <strong
                        >{{ linkedCandidate?.firstName }}
                        {{ linkedCandidate?.lastName }}</strong
                      >
                      {{
                        linkedCandidate?.applications[
                          linkedCandidate?.applications.length - 1
                        ]?.applicationId
                          ? "#" +
                            linkedCandidate?.applications[
                              linkedCandidate?.applications.length - 1
                            ]?.applicationId
                          : ""
                      }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="text"
                      @click="unlinkCandidate"
                    >
                      <v-icon class="mr-1">fa-solid fa-link-slash</v-icon>
                      Kandidatendaten trennen
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    Geburtsdatum:
                    {{
                      linkedCandidate?.birthDate
                        ? FormatDate.germanStandard(linkedCandidate?.birthDate)
                        : "unbekannt"
                    }}<br />
                    <div class="my-2">
                      <p>Adresse:</p>
                      <p>
                        {{ linkedCandidate?.addressStreet }}
                        {{ linkedCandidate?.addressHouseNumber }}
                      </p>
                      <p>
                        {{ linkedCandidate?.addressPostalCode }}
                        {{ linkedCandidate?.addressCity }}
                      </p>
                    </div>
                    <p
                      v-for="comm in linkedCandidate?.communicationMeans"
                      :key="comm?.value"
                    >
                      {{ comm?.description ? comm?.description + ":" : "" }}
                      {{ comm?.value }}
                    </p>
                  </v-card-text>
                </div>
              </v-row>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <div
          class="dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow"
        ></div>
        <v-card-actions class="dialog-edit-item-action-buttons">
          <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submitModal">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { Salutation } from "@/enums/salutation.model";
import { EmployeeService } from "@/services/api/employee.service";
import { Employee } from "@/models/employee.model";
import ToastService from "@/services/toast.service";
import { Candidate } from "../../../models/candidate.model";
import { CandidateService } from "../../../services/api/candidate.service";
import { SpinnerService } from "../../../services/spinner.service";
import { FormatDate } from "../../../helper/format-date.helper";

enum DialogEditEmployeeEmits {
  insertUpdatedEmployee = "insertUpdatedEmployee",
}

enum DialogEditEmployeeTabs {
  General = "Allgemein",
  Assignments = "Einsätze",
  DataPrivacyConsents = "Datenschutzeinwilligungen",
  Candidate = "Kandidat (ATS)",
}

export default defineComponent({
  name: "DialogEditEmployee",
  emits: [DialogEditEmployeeEmits.insertUpdatedEmployee],
  props: {
    employee: Object as PropType<Employee>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      activeTab: 0,
      FormatDate,
      linkedCandidate: undefined as Candidate | undefined,
      showModal: false,
      tabs: [
        DialogEditEmployeeTabs.General,
        DialogEditEmployeeTabs.Assignments,
        DialogEditEmployeeTabs.DataPrivacyConsents,
        DialogEditEmployeeTabs.Candidate,
      ],
      salutations: Object.values(Salutation).filter(
        (value) => typeof value === "string"
      ),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    localEmployee() {
      const localEmployee = { ...this.employee } as Employee;
      return localEmployee;
    },
  },
  methods: {
    clickOnTab(index: number) {
      if (index === 3) {
        if (this.localEmployee.candidateObjectId) {
          this.getLinkedCandidate();
        }
      }
    },
    closeModal() {
      this.showModal = false;
    },
    async getLinkedCandidate() {
      if (!this.localEmployee.candidateObjectId) return;
      try {
        SpinnerService.showSpinner();
        const candidate = await new CandidateService().getCandidateById(
          this.localEmployee.candidateObjectId as string
        );
        if (candidate) this.linkedCandidate = candidate;
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    openModal() {
      this.showModal = true;
      if (this.employee?.candidateObjectId) {
        this.getLinkedCandidate();
      }
    },
    async submitModal() {
      const apiService = new EmployeeService();
      if (this.localEmployee) {
        apiService.editEmployee(this.localEmployee).then((response) => {
          if (response) {
            this.$emit(
              DialogEditEmployeeEmits.insertUpdatedEmployee,
              this.localEmployee
            );
            this.closeModal();
          } else {
            ToastService.showError("Fehler beim speichern der Personaldaten");
          }
        });
      }
    },
    unlinkCandidate() {
      this.linkedCandidate = undefined;
      this.localEmployee.candidateObjectId = null;
      this.localEmployee.candidateUuid = null;
      this.submitModal();
    },
  },
});
</script>

<style scoped>
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
