import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ExtractCandidateUuidFromAtsRecruitLinkForm = _resolveComponent("ExtractCandidateUuidFromAtsRecruitLinkForm")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!
  const _component_DialogEditCandidate = _resolveComponent("DialogEditCandidate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseDialog, {
      "show-dialog": _ctx.showDialog,
      width: _ctx.candidate ? 'var(--d-w-s)' : 'var(--d-w-l)',
      onCloseDialog: _ctx.closeDialog
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.getDialogTitle()), 1)
      ]),
      content: _withCtx(() => [
        (_ctx.showExtractCandidateUuidFromAtsRecruitLinkForm())
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_card_title, { class: "text-subtitle-1 mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "mr-2 text-medium-emphasis" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("fa-solid fa-person-circle-question")
                    ])),
                    _: 1
                  }),
                  _cache[6] || (_cache[6] = _createTextVNode(" Doch vorhanden? Manuell verknüpfen "))
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ExtractCandidateUuidFromAtsRecruitLinkForm, { onExtractedUuid: _ctx.receiveCandidateUuid }, null, 8, ["onExtractedUuid"]),
                  _createVNode(_component_v_divider, { class: "mt-15 mb-10" })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.showXpMode())
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_card_title, { class: "text-subtitle-1 mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    size: "small",
                    class: "mr-1 text-medium-emphasis"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("fa-solid fa-flask")
                    ])),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createTextVNode(" XP-Mode: Kandidat direkt ins ATS übertragen "))
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  (
              _ctx.softwareIntegration.zvooveRecruit &&
              _ctx.atsRecruitMandantWithAddresses?.length > 0
            )
                    ? (_openBlock(), _createBlock(_component_v_select, _mergeProps({ key: 0 }, _ctx.vStyle?.input, {
                        density: "compact",
                        width: "345",
                        modelValue: _ctx.selectedAtsRecruitMandant,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAtsRecruitMandant) = $event)),
                          _ctx.handleAtsRecruitMandantChange
                        ],
                        items: _ctx.atsRecruitMandantWithAddresses,
                        "item-title": "Bezeichnung",
                        "return-object": "",
                        label: "Mandant"
                      }), null, 16, ["modelValue", "items", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_row, { class: "ml-4 d-flex align-center text-body-2" }, {
                    default: _withCtx(() => [
                      _cache[16] || (_cache[16] = _createTextVNode(" 1. ")),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialogEditNewOrUpdatedCandidate())),
                        density: "compact",
                        class: "ml-2",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("fa-solid fa-user-pen")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Kandidatendatensatz bearbeiten ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, {
                        class: "mx-4 text-medium-emphasis",
                        size: "1.5rem"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("fa-solid fa-arrow-right")
                        ])),
                        _: 1
                      }),
                      _cache[17] || (_cache[17] = _createTextVNode(" 2. ")),
                      _createVNode(_component_v_btn, {
                        onClick: _ctx.addEmployeeToAtsRecruit,
                        disabled: !_ctx.selectedAtsRecruitMandant,
                        variant: "text"
                      }, {
                        default: _withCtx(() => [
                          _cache[13] || (_cache[13] = _createTextVNode("Übertragen (ATS)")),
                          _createVNode(_component_v_icon, {
                            size: "small",
                            class: "ml-2 text-medium-emphasis"
                          }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("fa-solid fa-cloud-arrow-up")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"]),
                      (
                !_ctx.atsRecruitMandantWithAddresses ||
                _ctx.atsRecruitMandantWithAddresses.length === 0
              )
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            icon: "",
                            variant: "text",
                            onClick: _ctx.getAtsRecruitDefaults
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createTextVNode("fa-solid fa-satellite-dish")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                location: "bottom"
                              }, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createTextVNode(" Verbindung zu ATS Recruit herstellen ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "mt-10" })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.softwareIntegration.zvooveRecruit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_v_card_title, { class: "text-subtitle-1 mb-2" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.showXpMode() ? "Optional: " : "") + "Personaldaten als Bewerbung ans ATS Recruit senden ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "ml-4 d-flex align-center text-body-2" }, {
                    default: _withCtx(() => [
                      _cache[23] || (_cache[23] = _createTextVNode(" 1. ")),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialogEditNewOrUpdatedCandidate())),
                        density: "compact",
                        class: "ml-4",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode("fa-solid fa-user-pen")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" Kandidatendatensatz bearbeiten ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, {
                        class: "mx-4 text-medium-emphasis",
                        size: "1.5rem"
                      }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode("fa-solid fa-arrow-right")
                        ])),
                        _: 1
                      }),
                      _cache[24] || (_cache[24] = _createTextVNode(" 2. ")),
                      _createVNode(_component_v_btn, {
                        onClick: _ctx.addApplicationToAts,
                        variant: "text"
                      }, {
                        default: _withCtx(() => [
                          _cache[22] || (_cache[22] = _createTextVNode("Neue Bewerbung")),
                          _createVNode(_component_v_icon, { class: "ml-2 text-medium-emphasis" }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode("fa-solid fa-paper-plane")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_divider, { class: "mt-10" })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.candidateToSendToAts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_v_card_title, { class: "text-subtitle-1 mb-2" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isAtsSystem() ? "Optional: " : "") + "Kandidat " + _toDisplayString(_ctx.isAtsSystem() ? "nur " : "") + "im " + _toDisplayString(_ctx.appTitle()) + " anlegen", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "ml-4 mb-15 d-flex align-center text-body-2" }, {
                    default: _withCtx(() => [
                      _cache[30] || (_cache[30] = _createTextVNode(" 1. ")),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        variant: "text",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialogEditNewOrUpdatedCandidate())),
                        density: "compact",
                        class: "ml-4",
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode("fa-solid fa-user-pen")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => _cache[26] || (_cache[26] = [
                              _createTextVNode(" Kandidatendatensatz bearbeiten ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, {
                        class: "mx-4 text-medium-emphasis",
                        size: "1.5rem"
                      }, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode("fa-solid fa-arrow-right")
                        ])),
                        _: 1
                      }),
                      _cache[31] || (_cache[31] = _createTextVNode(" 2. ")),
                      _createVNode(_component_v_btn, {
                        disabled: !_ctx.newOrUpdatedCandidate,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createCandidateAndUpdateEmployee())),
                        variant: "text"
                      }, {
                        default: _withCtx(() => [
                          _cache[29] || (_cache[29] = _createTextVNode("Kandidat erstellen")),
                          _createVNode(_component_v_icon, { class: "ml-2 text-medium-emphasis" }, {
                            default: _withCtx(() => _cache[28] || (_cache[28] = [
                              _createTextVNode("fa-solid fa-floppy-disk")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "bottom"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Datensatz im " + _toDisplayString(_ctx.appTitle()) + " in der Spalte \"Kandidat\" anlegen ", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-dialog", "width", "onCloseDialog"]),
    (_ctx.dialogEditNewOrUpdatedCandidate)
      ? (_openBlock(), _createBlock(_component_DialogEditCandidate, {
          key: 0,
          ref: "dialogEditCandidateComponent",
          candidate: _ctx.newOrUpdatedCandidate,
          emitOnlyCandidateOnSubmit: true,
          onSubmitCandidateObjekt: _ctx.receiveUpdatedCandidate
        }, null, 8, ["candidate", "onSubmitCandidateObjekt"]))
      : _createCommentVNode("", true)
  ], 64))
}