<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <!-- Collapsed View -->
        <div
          @dblclick="handleDoubleClickOnItem"
          @dragstart="handleDragStart"
          @drop="handleDrop"
          @dragover="handleDragOver"
          @contextmenu.prevent="handleContextMenu"
          @dragend="handleDragEnd"
          v-if="isFullyCollapsed"
          draggable="true"
        >
          <slot name="collapsed-view"></slot>
        </div>

        <!-- Regular View -->
        <v-card
          v-if="!isFullyCollapsed"
          v-bind="{ ...vStyle?.boardItem, ...props }"
          :color="isHovering ? hoverColor : color"
          :class="{
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
            'item pl-5 pr-2': isDialog,
            'item pa-2 mb-2': !isDialog,
          }"
          :max-height="getItemHeight()"
          @dblclick="handleDoubleClickOnItem"
          @dragstart="handleDragStart"
          @drop="handleDrop"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @contextmenu.prevent="handleContextMenu"
          @dragend="handleDragEnd"
          draggable="true"
          @mousedown="$event.currentTarget.style.cursor = 'grabbing'"
          @mouseup="$event.currentTarget.style.cursor = 'grab'"
        >
          <div v-if="isDraggingOver">
            <div
              :style="{ height: vStyle.itemHeight.collapsed }"
              class="d-flex align-center"
            >
              <v-icon
                class="drag-over-icon ml-1 mb-3"
                v-if="dragOverIcon"
                size="2rem"
              >
                {{ dragOverIcon }}
              </v-icon>
              <div class="drag-over-text mb-3">{{ dragOverText }}</div>
            </div>
            <v-divider class="mb-4"></v-divider>
          </div>
          <!-- TopRightButton für Expansion Control -->
          <TopRightButton
            v-if="!isDialog"
            :isExpanded="isExpanded"
            :isFullyExpanded="isFullyExpanded"
            :parentRole="parentRole"
            @toggleExpansion="toggleExpansion"
            @toggleFullExpansion="toggleFullExpansion"
            @openContextMenu="$emit(GeneralItemEmit.openContextMenu, $event)"
          />
          <!-- Dialog Close Button -->
          <div v-if="isDialog" class="d-flex ma-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit(GeneralItemEmit.closeDialog)"
              icon
              size="s"
              variant="text"
              density="compact"
            >
              <v-icon>fa-solid fa-xmark</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <img
              v-if="showMatchIcon"
              :src="getAssetIconPath('match-icon.svg')"
              alt="Match Icon"
              class="match-icon"
            />
            <div class="item-collapsed">
              <slot name="item-collapsed"></slot>
            </div>
          </div>
          <div class="hide-on-inactive-column" v-if="isActive">
            <slot name="hide-on-inactive-column"></slot>
          </div>
          <div
            v-if="statusColor"
            class="status"
            :style="{ backgroundColor: statusColor }"
            :class="{ blinking: statusBlinking }"
          ></div>
          <div v-if="!isExpanded" class="bottom-right-icon">
            <slot name="bottom-right-icon"></slot>
          </div>
          <v-divider class="my-2" v-if="isExpanded"></v-divider>
          <div
            v-if="isExpanded || isAllSlotsActive"
            class="communication-icons-container"
          >
            <slot name="communication-icons-container"></slot>
          </div>
          <v-divider
            class="my-2"
            v-if="isExpanded || isAllSlotsActive"
          ></v-divider>
          <slot name="checklist-container"></slot>
          <slot
            v-if="isExpanded || isAllSlotsActive"
            name="tags-container"
          ></slot>
          <slot name="dependencies-container"></slot>
          <slot
            v-if="isExpanded || isAllSlotsActive"
            name="info-container"
          ></slot>
          <slot
            v-if="isExpanded || isAllSlotsActive"
            name="payflow-container"
          ></slot>
          <slot
            v-if="isExpanded || isAllSlotsActive"
            name="timeline-container"
          ></slot>
        </v-card>
        <slot name="append-container"></slot>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import TopRightButton from "../elements/TopRightButton.vue";
import { Role } from "../../../enums/dependency.enum";
import { MutationType } from "../../../enums/vuex-types.enum";
import { getAssetIconPath } from "../../../helper/get-assets.helper";
import { DragEventSpec } from "../../../enums/drag-event-spec.enum";
enum GeneralItemEmit {
  dragOver = "dragOver",
  dragStart = "dragStart",
  drop = "drop",
  closeDialog = "closeDialog",
  openContextMenu = "openContextMenu",
  isExpanded = "isExpanded",
  isNotExpanded = "isNotExpanded",
}

export default defineComponent({
  name: "GeneralItem",
  emits: [
    GeneralItemEmit.dragOver,
    GeneralItemEmit.dragStart,
    GeneralItemEmit.drop,
    GeneralItemEmit.closeDialog,
    GeneralItemEmit.openContextMenu,
    GeneralItemEmit.isExpanded,
    GeneralItemEmit.isNotExpanded,
  ],

  components: {
    TopRightButton,
  },

  props: {
    border: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    dragOverIcon: {
      type: String,
      default: undefined,
    },
    dragOverText: {
      type: String,
      default: undefined,
    },
    elevation: {
      type: Number,
      default: 2,
    },
    hoverColor: {
      type: String,
      default: undefined,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isAllSlotsActive: {
      type: Boolean,
      default: false,
    },
    parentRole: {
      type: String as PropType<Role>,
    },
    showMatchIcon: {
      type: Boolean,
      default: false,
    },
    statusColor: {
      type: String,
      default: undefined,
    },
    statusBlinking: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      GeneralItemEmit,
      isExpanded: false,
      isFullyExpanded: false,
      isDraggingOver: false,
      dragOverTimer: null as number | null,
      vStyle: (this.$store as any).state.vStyle,
      //helper methods
      getAssetIconPath,
    };
  },

  methods: {
    collapseItem() {
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    getItemHeight(): string {
      const { fullyExpanded, expanded, collapsed } = this.vStyle.itemHeight;
      if (this.isFullyExpanded) return fullyExpanded;
      if (this.isExpanded) return expanded;
      return collapsed;
    },
    handleDragOver(event: DragEvent) {
      event.preventDefault();
      this.$store.state.dragOverItem = this.parentRole;
      this.handleDragOverShowMessage();
      this.$emit(GeneralItemEmit.dragOver, event);
    },
    handleDragOverShowMessage() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.isDraggingOver = true;
      this.dragOverTimer = window.setTimeout(() => {
        this.isDraggingOver = false;
        this.dragOverTimer = null;
      }, 100);
    },
    handleDragLeave() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.dragOverTimer = window.setTimeout(() => {
        this.isDraggingOver = false;
        this.dragOverTimer = null;
      }, 100);
    },
    handleDragStart(event: DragEvent) {
      if (!event.dataTransfer) return;
      if (event.dataTransfer) {
        event.dataTransfer.setData(
          DragEventSpec.originComponent,
          this.parentRole as string
        );
        this.$store.commit(
          MutationType.setDragOriginComponent,
          this.parentRole
        );
      }
      this.$emit(GeneralItemEmit.dragStart, event);
    },
    handleDrop(event: DragEvent) {
      event.preventDefault();
      this.$emit(GeneralItemEmit.drop, event);
    },
    handleDragEnd() {
      this.$store.commit(MutationType.setDragOriginComponent, null);
    },
    handleDoubleClickOnItem() {
      if (this.isDialog) return;
      if (!this.isExpanded) {
        this.toggleExpansion();
      } else if (this.isExpanded && !this.isFullyExpanded) {
        this.toggleFullExpansion();
      } else if (this.isFullyExpanded) {
        this.toggleExpansion();
      }
    },
    handleContextMenu(event: MouseEvent) {
      if (this.parentRole === Role.candidate) {
        this.$emit(GeneralItemEmit.openContextMenu, event);
      }
    },
    handleExpansionState() {
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }

      this.$emit(
        this.isExpanded
          ? GeneralItemEmit.isExpanded
          : GeneralItemEmit.isNotExpanded
      );
    },
    setExpansion(expanded: boolean) {
      this.isExpanded = expanded;
      this.handleExpansionState();
    },
    setFullyExpansion(fullyExpanded: boolean) {
      this.isFullyExpanded = fullyExpanded;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      this.handleExpansionState();
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
  },

  beforeUnmount() {
    if (this.dragOverTimer) {
      clearTimeout(this.dragOverTimer);
    }
  },
});
</script>

<style scoped>
.item {
  position: relative;
  transition: all 0.3s ease;
}

.expanded {
  min-height: var(--item-height-expanded);
}

.fully-expanded {
  min-height: var(--item-height-fully-expanded);
}
.bottom-right-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  max-height: 1rem;
  bottom: 0.5rem;
  right: 0.6rem;
}
.status {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: -webkit-fill-available;
  z-index: 10;
}
.status-list {
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 60%;
  z-index: 10;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    background-color: var(--color-primary);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.blinking {
  animation: blink 1.5s infinite;
}
.drag-over-icon {
  text-shadow: 0 0 0.1rem var(--color-primary);
  color: var(--color-primary-darken-1);
  animation: popIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}
.drag-over-text {
  margin-left: 0.5rem;
  font-weight: 600;
  line-height: 1rem;
  font-size: 0.8rem;
  text-wrap: balance;
  overflow: hidden;
  animation: fadeIn 0.4s ease forwards;
  animation-delay: 0.1s;
  opacity: 0;
}

@keyframes popIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
