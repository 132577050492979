<!-- eslint-disable vue/no-mutating-props -->
<template>
  <BaseDialog
    :showDialog="showMatchDialog"
    @closeDialog="closeMatchDialog"
    :width="'var(--d-w-s)'"
    class="fill-height"
    @mouseenter="preloadAllCandidates()"
  >
    <template #title>Umkreissuche {{ postcodeRadius }}km</template>
    <template #content>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <!-- FIXME: Fix backend matching
          <v-select
            v-bind="vStyle.input"
            :items="statusOptionsRecruit"
            item-title="text"
            item-value="value"
            label="ein oder mehrere Kandidatenstatus"
            v-model="matchCandidateRequest.selectedStatuses"
            :rules="[rules.required]"
            hint="* Erforderlich"
            persistent-hint
            multiple
            @click.stop
          ></v-select>
          <v-label class="mt-5 mx-2"
            >Suche {{ postcodeRadius }}km im Umkreis von Postleitzahl
            {{ postcode }}</v-label
          >
          -->
          <v-slider
            :max="100"
            :min="0"
            :step="1"
            class="my-2"
            v-model="postcodeRadius"
            thumb-label
            @update:modelValue="savePostcodeRadius"
          ></v-slider>
          <v-btn
            block
            color="secondary"
            :disabled="!valid"
            @click="startMatching()"
            >Suchen</v-btn
          >
        </v-form>
      </v-card-text>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formRules } from "../../../helper/form-rules.helper";
import { InterComponentMessage } from "../../../enums/inter-component-messagin.enum";
import { mapGetters, mapMutations } from "vuex";
import { MatchCandidatesRequest } from "../../../models/candidate.model";
import { MutationType } from "../../../enums/vuex-types.enum";
import { useDisplay } from "vuetify";
import { ZipCodeService } from "../../../services/api/zip-code.service";
import { SpinnerService } from "../../../services/spinner.service";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { debounce } from "lodash";
import { LocalStorage } from "../../../enums/local-storage.enum";

enum DialogMatchFromCustomerOrDemandEmit {
  closeDialog = "closeDialog",
}

export default defineComponent({
  name: "DialogMatchFromCustomerOrDemand",
  emits: [DialogMatchFromCustomerOrDemandEmit.closeDialog],
  components: {
    BaseDialog,
  },
  props: {
    postcode: {
      type: String,
      required: true,
    },
    showMatchDialog: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Object as PropType<string[]>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      matchCandidateRequest: {} as MatchCandidatesRequest,
      postcodeRadius: this.getPostcodeRadius(),
      preloadAllCandidatesActive: false,
      rules: formRules,
      tagsMatchingThreshold:
        this.$store.state.company.aiData.prompt.company.tagsMatchingThreshold,
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
  },
  mounted() {
    this.postcodeRadius = this.getPostcodeRadius();
  },

  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    closeMatchDialog() {
      this.$emit(DialogMatchFromCustomerOrDemandEmit.closeDialog);
    },
    getPostcodeRadius() {
      return Number(localStorage.getItem(LocalStorage.postcodeRadius)) || 30;
    },
    async preloadAllCandidates() {
      if (this.preloadAllCandidatesActive) return;

      // Preload all candidates since infinite scroll would only load partial data
      try {
        SpinnerService.showSpinner();
        this.preloadAllCandidatesActive = true;
        this.setICM({
          message: InterComponentMessage.preloadDispatcherBoardColumn,
          payload: {
            candidateColumn: true,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.preloadAllCandidatesActive = false;
        this.$nextTick(() => {
          SpinnerService.removeSpinner();
        });
      }
    },
    async startMatching() {
      try {
        SpinnerService.showSpinner();
        const zipcodeService = new ZipCodeService();
        const postcodes = (await zipcodeService.getZipcodesWithinRadius(
          this.postcode,
          this.postcodeRadius
        )) as string[];

        this.matchCandidateRequest.postcodes = postcodes;
        this.matchCandidateRequest.tags = this.tags;
        this.matchCandidateRequest.threshold = this.tagsMatchingThreshold;
        this.setICM({
          message: InterComponentMessage.matchMeFromCustomerOrDemand,
          payload: this.matchCandidateRequest,
        });
        this.closeMatchDialog();
      } catch (error) {
        console.error(error);
      } finally {
        this.$nextTick(() => {
          SpinnerService.removeSpinner();
        });
      }
    },
    savePostcodeRadius: debounce(function (this: any) {
      localStorage.setItem(
        LocalStorage.postcodeRadius,
        this.postcodeRadius.toString()
      );
    }, 1000),
  },
});
</script>
