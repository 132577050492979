import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showModal) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          style: {"min-height":"var(--d-h-full)"},
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_sheet, {
                color: "surface",
                style: {"z-index":"1000","position":"fixed","top":"0","width":"99%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        rounded: _ctx.vStyle.card.rounded
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tabs, {
                            modelValue: _ctx.activeTab,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
                            "bg-color": "primary",
                            "slider-color": "on-primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickOnTab(_ctx.activeTab)))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                                return (_openBlock(), _createBlock(_component_v_tab, {
                                  key: index,
                                  value: index
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(tab), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128)),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_tab, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, { size: "x-large" }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode("fa-solid fa-circle-xmark")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["rounded"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "dialog-edit-item-before-menu-shadow dialog-edit-item-top-shadow" }, null, -1))
            ]),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_tabs_window, {
                  modelValue: _ctx.activeTab,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.activeTab) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                      return (_openBlock(), _createBlock(_component_v_tabs_window_item, {
                        key: index,
                        value: index,
                        class: "mt-15"
                      }, {
                        default: _withCtx(() => [
                          (index === 0)
                            ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                                      _createTextVNode("Allgemeine Daten")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mb-3" }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: _ctx.localEmployee.firstName,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localEmployee.firstName) = $event)),
                                        label: "Vorname"
                                      }), null, 16, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: _ctx.localEmployee.lastName,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEmployee.lastName) = $event)),
                                        label: "Nachname"
                                      }), null, 16, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: _ctx.localEmployee.salutation,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localEmployee.salutation) = $event)),
                                        items: _ctx.salutations,
                                        label: "Anrede"
                                      }), null, 16, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                                      _createTextVNode("Kommunikation")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_divider, { class: "mb-3" }),
                                  _createVNode(_component_v_col, {
                                    cols: "12",
                                    md: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                        modelValue: _ctx.localEmployee.email,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localEmployee.email) = $event)),
                                        label: "Email"
                                      }), null, 16, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  (_ctx.localEmployee.address)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "4"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.phone1,
                                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localEmployee.address.phone1) = $event)),
                                              label: "Telefon 1"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "4"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.phone2,
                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localEmployee.address.phone2) = $event)),
                                              label: "Telefon 2"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_card_title, null, {
                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                            _createTextVNode("Adresse")
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_divider, { class: "mb-3" }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "4"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.street,
                                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localEmployee.address.street) = $event)),
                                              label: "Straße"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "2"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.postalCode,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localEmployee.address.postalCode) = $event)),
                                              label: "PLZ"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "2"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.city,
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localEmployee.address.city) = $event)),
                                              label: "Ort"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "2"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.countryId,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localEmployee.address.countryId) = $event)),
                                              label: "Staat"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          md: "2"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, _mergeProps({ ref_for: true }, _ctx.vStyle.input, {
                                              modelValue: _ctx.localEmployee.address.stateId,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localEmployee.address.stateId) = $event)),
                                              label: "Bundesland"
                                            }), null, 16, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }))
                            : (index === 1)
                              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                                        _createTextVNode("Einsätze")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_divider, { class: "mb-3" }),
                                    _createVNode(_component_v_list, null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEmployee.assignments, (assignment) => {
                                          return (_openBlock(), _createBlock(_component_v_list_item, {
                                            key: assignment.assignmentId
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, [
                                                _createVNode(_component_v_list_item_title, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(assignment.clientName) + " - " + _toDisplayString(assignment.workLocation), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_list_item_subtitle, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(assignment.jobTitle) + " (" + _toDisplayString(assignment.from) + " - " + _toDisplayString(assignment.to) + ") ", 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : (index === 2)
                                ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_title, null, {
                                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                                          _createTextVNode("Datenschutzeinwilligungen")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_divider, { class: "mb-3" }),
                                      _createVNode(_component_v_list, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEmployee.dataPrivacyConsents, (dsgvo) => {
                                            return (_openBlock(), _createBlock(_component_v_list_item, {
                                              key: dsgvo.id
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", null, [
                                                  _createVNode(_component_v_list_item_title, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(dsgvo.id), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_list_item_subtitle, null, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(" Gültig bis: " + _toDisplayString(dsgvo.validUntil) + " - Löschen: " + _toDisplayString(dsgvo.delete), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ])
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : (index === 3)
                                  ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                                      default: _withCtx(() => [
                                        (
                    !_ctx.localEmployee?.candidateObjectId && !_ctx.linkedCandidate?._id
                  )
                                          ? (_openBlock(), _createBlock(_component_v_alert, {
                                              key: 0,
                                              class: "ma-4 pa-4",
                                              variant: "tonal",
                                              border: "start",
                                              color: "secondary",
                                              title: "Kein ATS Kandidat mit diesem Personaldatensatz verknüpft",
                                              icon: "fa-solid fa-link-slash",
                                              closable: ""
                                            }, {
                                              default: _withCtx(() => _cache[23] || (_cache[23] = [
                                                _createTextVNode(" Die Verknüpfung wird dann in der Regel automatisch hergestellt kann jedoch auch manuell über die Mitarbeiterkarte erfolgen. ")
                                              ])),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (
                    _ctx.localEmployee?.candidateObjectId && !_ctx.linkedCandidate?._id
                  )
                                          ? (_openBlock(), _createBlock(_component_v_alert, {
                                              key: 1,
                                              class: "ma-4 pa-4",
                                              variant: "tonal",
                                              border: "start",
                                              color: "error",
                                              title: "Fehler beim Laden des verknüften Kandidaten",
                                              icon: "fa-solid fa-circle-xmark",
                                              closable: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _cache[26] || (_cache[26] = _createTextVNode(" Der verknüpfte Kandidat konnte in der Datenbank nicht gefunden werden und ist eventuell gelöscht worden! ")),
                                                _createVNode(_component_v_btn, {
                                                  block: "",
                                                  class: "mt-15",
                                                  color: "error",
                                                  variant: "text",
                                                  onClick: _ctx.unlinkCandidate
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                        _createTextVNode("fa-solid fa-link-slash")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[25] || (_cache[25] = _createTextVNode(" Kandidatendaten trennen "))
                                                  ]),
                                                  _: 1
                                                }, 8, ["onClick"])
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (_ctx.linkedCandidate?._id)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                              _createVNode(_component_v_card_title, { class: "d-flex" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                                                        _createTextVNode("fa-solid fa-link")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[28] || (_cache[28] = _createTextVNode(" verlinkt mit Kandidat ")),
                                                    _createElementVNode("strong", null, _toDisplayString(_ctx.linkedCandidate?.firstName) + " " + _toDisplayString(_ctx.linkedCandidate?.lastName), 1),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.linkedCandidate?.applications[
                          _ctx.linkedCandidate?.applications.length - 1
                        ]?.applicationId
                          ? "#" +
                            _ctx.linkedCandidate?.applications[
                              _ctx.linkedCandidate?.applications.length - 1
                            ]?.applicationId
                          : ""), 1)
                                                  ]),
                                                  _createVNode(_component_v_spacer),
                                                  _createVNode(_component_v_btn, {
                                                    color: "error",
                                                    variant: "text",
                                                    onClick: _ctx.unlinkCandidate
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                                                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                                                          _createTextVNode("fa-solid fa-link-slash")
                                                        ])),
                                                        _: 1
                                                      }),
                                                      _cache[30] || (_cache[30] = _createTextVNode(" Kandidatendaten trennen "))
                                                    ]),
                                                    _: 1
                                                  }, 8, ["onClick"])
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_card_text, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(" Geburtsdatum: " + _toDisplayString(_ctx.linkedCandidate?.birthDate
                        ? _ctx.FormatDate.germanStandard(_ctx.linkedCandidate?.birthDate)
                        : "unbekannt"), 1),
                                                  _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
                                                  _createElementVNode("div", _hoisted_2, [
                                                    _cache[31] || (_cache[31] = _createElementVNode("p", null, "Adresse:", -1)),
                                                    _createElementVNode("p", null, _toDisplayString(_ctx.linkedCandidate?.addressStreet) + " " + _toDisplayString(_ctx.linkedCandidate?.addressHouseNumber), 1),
                                                    _createElementVNode("p", null, _toDisplayString(_ctx.linkedCandidate?.addressPostalCode) + " " + _toDisplayString(_ctx.linkedCandidate?.addressCity), 1)
                                                  ]),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkedCandidate?.communicationMeans, (comm) => {
                                                    return (_openBlock(), _createElementBlock("p", {
                                                      key: comm?.value
                                                    }, _toDisplayString(comm?.description ? comm?.description + ":" : "") + " " + _toDisplayString(comm?.value), 1))
                                                  }), 128))
                                                ]),
                                                _: 1
                                              })
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "dialog-edit-item-before-menu-shadow dialog-edit-item-bottom-shadow" }, null, -1)),
            _createVNode(_component_v_card_actions, { class: "dialog-edit-item-action-buttons" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                    _createTextVNode("Abbrechen")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _ctx.submitModal
                }, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createTextVNode("Speichern")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ]))
}