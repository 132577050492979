import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { class: "ml-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_3 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = {
  key: 0,
  class: "ml-1 text-medium-emphasis font-weight-bold"
}
const _hoisted_5 = { class: "employee-status" }
const _hoisted_6 = { class: "text-caption text-medium-emphasis" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_ErpOrAtsLinkMenu = _resolveComponent("ErpOrAtsLinkMenu")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_WhatsAppClient = _resolveComponent("WhatsAppClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_Assignments = _resolveComponent("Assignments")!
  const _component_PayFlowEmployee = _resolveComponent("PayFlowEmployee")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_DialogSelectAtsCandidates = _resolveComponent("DialogSelectAtsCandidates")!
  const _component_DialogAddCandidateToAts = _resolveComponent("DialogAddCandidateToAts")!
  const _component_GeneralItem = _resolveComponent("GeneralItem")!

  return (_openBlock(), _createBlock(_component_GeneralItem, {
    ref: "generalItemComponent",
    isFullyCollapsed: _ctx.isFullyCollapsed,
    isDialog: false,
    isActive: _ctx.isActive,
    color: 'card',
    hoverColor: 'cardHover',
    parentRole: _ctx.Role.employee,
    dragOverIcon: _ctx.dragOver.icon,
    dragOverText: _ctx.dragOver.text,
    statusColor: _ctx.getEmployeeStatusCreds().color,
    statusBlinking: _ctx.getEmployeeStatusCreds().blinking,
    onIsExpanded: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateComponent())),
    onDragStart: _ctx.handleDragStart,
    onDragOver: _ctx.handleDragOver,
    onOpenContextMenu: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit(_ctx.EmployeeItemEmit.openContextMenu, $event)))
  }, {
    "collapsed-view": _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.employee.employeeNumber), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.employee.firstName) + " " + _toDisplayString(_ctx.employee.lastName), 1)
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.employee.address.city), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "my-1" })
    ]),
    "item-collapsed": _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "pa-0 text-body-2 d-flex" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.employee?.firstName) + " " + _toDisplayString(_ctx.employee?.lastName) + " ", 1),
          _createVNode(_component_v_spacer),
          (_ctx.getAssignmentExpiringText())
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.getAssignmentExpiringText()), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.employee?.address?.postalCode) + " " + _toDisplayString(_ctx.employee?.address?.city) + " (" + _toDisplayString(_ctx.employee?.employeeNumber) + ") ", 1)
        ]),
        _: 1
      })
    ]),
    "hide-on-inactive-column": _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.getEmployeeState(_ctx.employee?.employeeStatus)) + " ", 1),
        _createVNode(_component_v_menu, { activator: "parent" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeStates, (item, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    onClick: ($event: any) => (_ctx.saveStatusSelection(index.toString())),
                    key: index,
                    value: index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$store.getters.getMandantNameByUuid(_ctx.employee.mandants[0])), 1)
    ]),
    "communication-icons-container": _withCtx(() => [
      _createElementVNode("div", null, [
        (!_ctx.employee.candidateUuid)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.getAssetIconPath('icon-ats-unknown.svg'),
              alt: "pd hub upload",
              class: "communication-container-img-icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchForCandidateDataInAts()))
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Kein passender Kandidatendatensatz gespeichert! Klicken um im ATS nach Daten zu suchen. ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        (_ctx.employee.candidateUuid && !_ctx.employee.candidateObjectId)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.getAssetIconPath('icon-ats-disconnected.svg'),
              alt: "pd hub upload",
              class: "communication-container-img-icon",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkLinkToCandidate()))
            }, null, 8, _hoisted_8))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "bottom"
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode(" Kandidatennummer in den Daten vorhanden! Klicken um Kandidat und Mitarbeiter zu verlinken bzw. aus dem ATS abzurufen. ")
          ])),
          _: 1
        })
      ]),
      _createVNode(_component_v_spacer),
      (_ctx.employee.candidateUuid)
        ? (_openBlock(), _createBlock(_component_ErpOrAtsLinkMenu, {
            key: 0,
            atsRecruitUuid: _ctx.employee.candidateUuid
          }, null, 8, ["atsRecruitUuid"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ErpOrAtsLinkMenu, {
        erpEmployeeNumber: _ctx.employee.employeeNumber,
        onGetErpData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateEmployeeFromErp())),
        onPutErpData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateErpFromEmployee()))
      }, null, 8, ["erpEmployeeNumber"]),
      _createVNode(_component_PhoneClient, {
        employee: _ctx.employee,
        phoneNumbers: _ctx.filteredPhoneNumbers,
        onCollapseParentItem: _ctx.collapseItem
      }, null, 8, ["employee", "phoneNumbers", "onCollapseParentItem"]),
      _createVNode(_component_WhatsAppClient, {
        AiMessageType: _ctx.AiMessageType.whatsAppEmployee,
        employee: _ctx.employee,
        message: _ctx.message.body,
        phoneNumbers: _ctx.filteredPhoneNumbers,
        receiverName: `${_ctx.employee?.firstName} ${_ctx.employee.lastName}`,
        salutation: _ctx.employee.letterSalutation,
        signature: _ctx.signatureWhatsApp(),
        onCollapseParentItem: _ctx.collapseItem
      }, null, 8, ["AiMessageType", "employee", "message", "phoneNumbers", "receiverName", "salutation", "signature", "onCollapseParentItem"]),
      _createVNode(_component_MailClient, {
        employee: _ctx.employee,
        emailAddresses: _ctx.filteredEmail,
        AiMessageType: _ctx.AiMessageType.mailEmployee,
        emailObject: _ctx.message,
        signature: _ctx.signatureMail(),
        onCollapseParentItem: _ctx.collapseItem,
        onEditContacts: _ctx.editContact
      }, null, 8, ["employee", "emailAddresses", "AiMessageType", "emailObject", "signature", "onCollapseParentItem", "onEditContacts"])
    ]),
    "checklist-container": _withCtx(() => [
      _createVNode(_component_Checklist, {
        checklist: _ctx.employee.checklist || [],
        employee: _ctx.employee,
        onUpdateChecklist: _ctx.updateChecklist
      }, null, 8, ["checklist", "employee", "onUpdateChecklist"])
    ]),
    "info-container": _withCtx(() => [
      _createVNode(_component_Assignments, {
        isEmployee: true,
        assignments: _ctx.employee?.assignments,
        onUpdateAssignments: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getPlacements()))
      }, null, 8, ["assignments"])
    ]),
    "payflow-container": _withCtx(() => [
      (_ctx.softwareIntegration.payFlow)
        ? (_openBlock(), _createBlock(_component_PayFlowEmployee, {
            key: 0,
            employeeNumber: _ctx.employee.employeeNumber,
            firstName: _ctx.employee.firstName,
            lastName: _ctx.employee.lastName,
            mandants: _ctx.employee.mandants
          }, null, 8, ["employeeNumber", "firstName", "lastName", "mandants"]))
        : _createCommentVNode("", true)
    ]),
    "timeline-container": _withCtx(() => [
      _createVNode(_component_Timeline, {
        employee: _ctx.employee,
        mandant: _ctx.employee.mandants[0]
      }, null, 8, ["employee", "mandant"])
    ]),
    "append-container": _withCtx(() => [
      _createVNode(_component_DialogSelectAtsCandidates, {
        showDialog: _ctx.showResultsDialog,
        resultsCandidateSearch: _ctx.resultsCandidateSearch,
        onCloseDialog: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showResultsDialog = false)),
        onSelectCandidateSearchResult: _ctx.selectCandidateSearchResult,
        onAbort: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showAddCandidateToAtsDialog = true))
      }, null, 8, ["showDialog", "resultsCandidateSearch", "onSelectCandidateSearchResult"]),
      (_ctx.showAddCandidateToAtsDialog)
        ? (_openBlock(), _createBlock(_component_DialogAddCandidateToAts, {
            key: 0,
            showDialog: _ctx.showAddCandidateToAtsDialog,
            employee: _ctx.employee,
            onCandidateCreatedOrUpdated: _ctx.linkNewCandidateToEmployee,
            onCloseDialog: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showAddCandidateToAtsDialog = false)),
            onReceivedCandidateUuidFromLink: _ctx.receiveCandidateUuidFromLink
          }, null, 8, ["showDialog", "employee", "onCandidateCreatedOrUpdated", "onReceivedCandidateUuidFromLink"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["isFullyCollapsed", "isActive", "parentRole", "dragOverIcon", "dragOverText", "statusColor", "statusBlinking", "onDragStart", "onDragOver"]))
}