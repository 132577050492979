import store from "@/store/store";
import {
  AppLayout,
  HeaderSpinner,
  HeaderSpinnerSize,
} from "@/enums/app-layout.enum";
import { SpinnerService } from "./spinner.service";

export class RecurionSpinnerService {
  static setSpinner(): void {
    if (
      store.getters.appLayout === AppLayout.classic ||
      store.getters.appLayout === AppLayout.persontobusiness
    ) {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.classic,
        HeaderSpinner.recurion
      );
    } else {
      SpinnerService.setHeaderSpinnerElement(
        HeaderSpinnerSize.normal,
        HeaderSpinner.recurion
      );
    }
  }

  static clearSpinner(): void {
    SpinnerService.clearHeaderSpinnerElement();
  }
}
