<template>
  <v-tabs
    v-model="activeTab"
    align-tabs="start"
    bg-color="primary-darken-1"
    slider-color="primary-darken-1"
    height="30"
  >
    <v-tab class="text-none text-subtitle-1"
      ><v-icon class="mr-1">fas fa-gear</v-icon>Einstellungen</v-tab
    >
    <v-tab
      class="text-none text-caption"
      v-for="item in menuItems"
      :key="item"
      :value="item"
      @click="navigateTo(item)"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { Routes } from "@/enums/routes.enum";
import { AppPageAndMenuTitle } from "../../enums/app-layout.enum";
import { MutationType } from "../../enums/vuex-types.enum";
import { AccessRule } from "../../enums/access-rule.enum";

export default defineComponent({
  name: "AdminMenu",
  data() {
    const user = this.$store.getters.user;
    const menuItems = [
      AppPageAndMenuTitle.softwareIntegration,
      AppPageAndMenuTitle.ai,
    ];
    if (UserRoleHelper.hasAccess(user, AccessRule.getAllMandants)) {
      menuItems.unshift(AppPageAndMenuTitle.mandants);
    }
    if (UserRoleHelper.hasAccess(user, AccessRule.dataCleaning)) {
      menuItems.unshift(AppPageAndMenuTitle.dataCleaning);
    }
    if (UserRoleHelper.isUberAdmin(user)) {
      menuItems.unshift(AppPageAndMenuTitle.roles);
    }
    if (UserRoleHelper.isUberAdmin(user)) {
      menuItems.unshift(AppPageAndMenuTitle.appConfig);
    }
    if (UserRoleHelper.isUberAdmin(user)) {
      menuItems.unshift(AppPageAndMenuTitle.company);
    }
    if (UserRoleHelper.hasAccess(user, AccessRule.createCompanyUser)) {
      menuItems.unshift(AppPageAndMenuTitle.user);
    }
    if (UserRoleHelper.hasAccess(user, AccessRule.getBackendLogs)) {
      menuItems.unshift(AppPageAndMenuTitle.backendLogs);
    }
    return {
      activeTab: AppPageAndMenuTitle.user,
      menuItems,
      user,
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    this.$store.commit(MutationType.setRecurionChatInactive);
  },
  methods: {
    navigateTo(menuItem: string) {
      switch (menuItem) {
        case AppPageAndMenuTitle.company:
          if (UserRoleHelper.isUberAdmin(this.user)) {
            this.$router.push(Routes.configCompany);
          }
          break;
        case AppPageAndMenuTitle.backendLogs:
          if (UserRoleHelper.hasAccess(this.user, AccessRule.getBackendLogs)) {
            this.$router.push(Routes.backendLogs);
          }
          break;
        case AppPageAndMenuTitle.user:
          if (
            UserRoleHelper.hasAccess(this.user, AccessRule.createCompanyUser)
          ) {
            this.$router.push(Routes.configUser);
          }
          break;
        case AppPageAndMenuTitle.roles:
          if (UserRoleHelper.hasAccess(this.user, AccessRule.getAppConfig)) {
            this.$router.push(Routes.roles);
          }
          break;
        case AppPageAndMenuTitle.mandants:
          if (UserRoleHelper.hasAccess(this.user, AccessRule.getAllMandants)) {
            this.$router.push(Routes.configMandant);
          }
          break;
        case AppPageAndMenuTitle.appConfig:
          if (UserRoleHelper.isUberAdmin(this.user)) {
            this.$router.push(Routes.appConfig);
          }
          break;
        case AppPageAndMenuTitle.softwareIntegration:
          this.$router.push(Routes.configApi);
          break;
        case AppPageAndMenuTitle.ai:
          this.$router.push(Routes.configAi);
          break;
        case AppPageAndMenuTitle.dataCleaning:
          if (UserRoleHelper.hasAccess(this.user, AccessRule.dataCleaning)) {
            this.$router.push(Routes.dataCleaning);
          }
          break;
        default:
      }
    },
  },
});
</script>
