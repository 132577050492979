import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "pa-0 text-body-2" }
const _hoisted_2 = { class: "font-weight-light text-caption text-medium-emphasis" }
const _hoisted_3 = { class: "mx-1 font-weight-light text-caption text-medium-emphasis" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "customer-mandant" }
const _hoisted_6 = { class: "customer-status" }
const _hoisted_7 = { class: "communication-icons-container" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 2 }
const _hoisted_13 = ["src"]
const _hoisted_14 = { key: 3 }
const _hoisted_15 = ["src"]
const _hoisted_16 = { key: 4 }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "communication-icons-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_ErpLinkMenu = _resolveComponent("ErpLinkMenu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_PhoneClient = _resolveComponent("PhoneClient")!
  const _component_MailClient = _resolveComponent("MailClient")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_Dependencies = _resolveComponent("Dependencies")!
  const _component_Assignments = _resolveComponent("Assignments")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_DialogUpdateCustomer = _resolveComponent("DialogUpdateCustomer")!
  const _component_ProfileGenerator = _resolveComponent("ProfileGenerator")!
  const _component_JobAdListCustomer = _resolveComponent("JobAdListCustomer")!
  const _component_GeneralItem = _resolveComponent("GeneralItem")!

  return (_openBlock(), _createBlock(_component_GeneralItem, {
    ref: "generalItemComponent",
    color: _ctx.item.color,
    dragOverIcon: _ctx.dragOver.icon,
    dragOverText: _ctx.dragOver.text,
    hoverColor: _ctx.item.hoverColor,
    isActive: _ctx.isActive,
    isDialog: _ctx.isOpenedAsDialog,
    isFullyCollapsed: _ctx.isFullyCollapsed,
    parentRole: _ctx.Role.customer,
    showMatchIcon: _ctx.candidatesTagsMatching,
    onCloseDialog: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$emit(_ctx.CustomerItemEmit.closeDialog))),
    onDragOver: _ctx.handleDragOver,
    onDragStart: _ctx.handleDragStart,
    onDrop: _ctx.handleDrop,
    onOpenContextMenu: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit(_ctx.CustomerItemEmit.openContextMenu)))
  }, {
    "collapsed-view": _withCtx(() => [
      _createVNode(_component_v_sheet, {
        class: "ma-0 pt-1 px-1",
        color: _ctx.item.colorList
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex justify-space-between pa-0" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.customer?.generalData?.name) + " ", 1),
                _createElementVNode("span", _hoisted_2, "(" + _toDisplayString(_ctx.customer?.customerNumber) + ") ", 1)
              ]),
              _createElementVNode("span", _hoisted_3, [
                (_ctx.customer?.jobAds && _ctx.customer.jobAds.length > 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
                      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scheduleJobAdListClose && _ctx.scheduleJobAdListClose(...args)))
                    }, _cache[22] || (_cache[22] = [
                      _createElementVNode("i", { class: "fa-solid fa-rectangle-ad" }, null, -1)
                    ]), 32))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.customer?.addressAndCommunication?.city), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, { class: "mt-1" })
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    "item-collapsed": _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "pa-0 text-body-2" }, {
        default: _withCtx(() => [
          (_ctx.customer?.additionalInfo && _ctx.customer?.additionalInfo.logoLink)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "Logo",
                src: _ctx.customer?.additionalInfo?.logoLink,
                class: "logo-img mr-1"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.customer?.generalData?.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, { class: "pl-0" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.customer?.addressAndCommunication?.postalCode) + " " + _toDisplayString(_ctx.customer?.addressAndCommunication?.city) + " (" + _toDisplayString(_ctx.customer?.customerNumber) + ") ", 1)
        ]),
        _: 1
      })
    ]),
    "bottom-right-icon": _withCtx(() => [
      (_ctx.showJobAdsIcon())
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
            onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.scheduleJobAdListClose && _ctx.scheduleJobAdListClose(...args)))
          }, [
            _createVNode(_component_v_icon, {
              class: "text-medium-emphasis",
              size: "x-small"
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode(" fa-solid fa-rectangle-ad ")
              ])),
              _: 1
            })
          ], 32))
        : _createCommentVNode("", true)
    ]),
    "hide-on-inactive-column": _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createTextVNode(_toDisplayString(_ctx.mandantName()) + " ", 1),
        _createVNode(_component_v_menu, { activator: "parent" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (item, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    onClick: ($event: any) => (_ctx.saveMandantSelection(item.uuid)),
                    key: index,
                    value: index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.customer?.generalData?.status) + " ", 1),
        _createVNode(_component_v_menu, { activator: "parent" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerStates, (item, index) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: index,
                    value: index,
                    onClick: ($event: any) => (_ctx.saveStatusSelection(index.toString()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    "communication-icons-container": _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (
                  (_ctx.softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
                  (!_ctx.customer?.additionalInfo?.indexLink ||
                    _ctx.customer?.additionalInfo?.indexLink === '')
                )
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("img", {
                src: _ctx.getAssetIconPath('icon-index-disconnect.png'),
                alt: "Index Anzeigendaten disconnected",
                class: "communication-container-img-icon",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.indexAnzeigendaten)))
              }, null, 8, _hoisted_9),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("mit Anzeigendaten verknüpfen")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
                  (_ctx.softwareIntegration as SoftwareIntegration).zvooveOne &&
                  (!_ctx.isNumeric(_ctx.customer?.customerNumber) ||
                    _ctx.customer?.generalData?.costCenter == null)
                )
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("img", {
                src: _ctx.getAssetIconPath('icon-erp-disconnect.svg'),
                alt: "ERP One disconnected",
                class: "communication-container-img-icon",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.zvooveOne)))
              }, null, 8, _hoisted_11),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createTextVNode("mit bestehendem Kunden in ERP One verknüpfen")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isNotZvooveCustomerNumber())
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("img", {
                src: _ctx.getAssetIconPath('icon-erp-upload.svg'),
                alt: "ERP One upload",
                class: "communication-container-img-icon",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit(_ctx.CustomerItemEmit.createInErp, _ctx.customer)))
              }, null, 8, _hoisted_13),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => _cache[26] || (_cache[26] = [
                  _createTextVNode("in ERP One anlegen")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
                  (_ctx.softwareIntegration as SoftwareIntegration).pdHub &&
                  (!_ctx.isNumeric(_ctx.customer?.customerNumber) ||
                    _ctx.customer?.generalData?.costCenter == null)
                )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("img", {
                src: _ctx.getAssetIconPath('icon-erp-disconnect.svg'),
                alt: "ERP disconnected",
                class: "communication-container-img-icon",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.pdHub)))
              }, null, 8, _hoisted_15),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode("mit bestehendem Kunden in PD-Hub verknüpfen")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_ctx.isNotPdHubCustomerNumber())
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("img", {
                src: _ctx.getAssetIconPath('icon-erp-upload.svg'),
                alt: "ERP upload",
                class: "communication-container-img-icon",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit(_ctx.CustomerItemEmit.createInErp, _ctx.customer)))
              }, null, 8, _hoisted_17),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "bottom"
              }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode("in PD-Hub anlegen")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("i", {
            class: "fa-regular fa-address-book get-contacts-icon",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openUpdateCustomerModal(_ctx.ModalMode.scrapeContactsFromSite)))
          }, [
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[29] || (_cache[29] = [
                _createTextVNode("Ansprechpartner von Webseite ergänzen")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        (_ctx.customer?.generalData?.costCenter != null)
          ? (_openBlock(), _createBlock(_component_ErpLinkMenu, {
              key: 0,
              erpCustomerNumber: _ctx.customer?.customerNumber,
              onGetErpData: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateCustomerFromErp())),
              onPutErpData: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateErpFromCustomer()))
            }, null, 8, ["erpCustomerNumber"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, {
          icon: "",
          variant: "text",
          density: "compact",
          class: "communication-container-icon text-medium-emphasis",
          onClick: _ctx.toggleProfileDesired
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              size: "var(--dispatcher-board-communication-icon-size)",
              color: _ctx.getProfileDesiredStatus().color
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getProfileDesiredStatus().icon), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getProfileDesiredStatus().tooltip), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_PhoneClient, {
          ref: "phoneClientComponent",
          customer: _ctx.customer,
          phoneNumbers: _ctx.filteredPhoneNumbers,
          onAddFollowUpEvent: _ctx.addFollowUpOnDependencies,
          onCollapseParentItem: _ctx.collapseItem,
          onIsDialingNumber: _ctx.phoneClientIsDialing,
          onSetAppointmentEvent: _ctx.setAppointmentOnDependencies
        }, null, 8, ["customer", "phoneNumbers", "onAddFollowUpEvent", "onCollapseParentItem", "onIsDialingNumber", "onSetAppointmentEvent"]),
        _createVNode(_component_MailClient, {
          ref: "mailClientComponent",
          AiMessageType: _ctx.AiMessageType.mailCustomer,
          customer: _ctx.customer,
          emailAddresses: _ctx.filteredEmail,
          emailObject: _ctx.email,
          signature: _ctx.signatureText,
          onCollapseParentItem: _ctx.collapseItem,
          onEditContacts: _ctx.editContacts
        }, null, 8, ["AiMessageType", "customer", "emailAddresses", "emailObject", "signature", "onCollapseParentItem", "onEditContacts"])
      ])
    ]),
    "checklist-container": _withCtx(() => [
      _createVNode(_component_Checklist, {
        checklist: _ctx.customer?.checklist || [],
        customer: _ctx.customer,
        onUpdateChecklist: _ctx.updateChecklist
      }, null, 8, ["checklist", "customer", "onUpdateChecklist"])
    ]),
    "tags-container": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer?.tags, (tag, index) => {
        return (_openBlock(), _createBlock(_component_v_chip, {
          key: index,
          class: "dispatcher-board-icon-tag",
          color: "primary",
          "onClick:close": ($event: any) => (_ctx.removeTag(index))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tag) + " ", 1),
            _createVNode(_component_v_icon, {
              small: "",
              onClick: _withModifiers(($event: any) => (_ctx.removeTag(index)), ["stop"]),
              class: "fa fa-times"
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["onClick:close"]))
      }), 128)),
      _createVNode(_component_v_text_field, {
        variant: "outlined",
        class: "mt-2",
        label: "Neuer Tag",
        style: {"max-width":"10rem"},
        rounded: "lg",
        density: "compact",
        modelValue: _ctx.newTag,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newTag) = $event)),
        onKeyup: _withKeys(_ctx.addTag, ["enter"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[30] || (_cache[30] = [
              _createTextVNode("mit \"Enter\" neuen Tag hinzufügen")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onKeyup"]),
      _createVNode(_component_v_btn, {
        icon: "",
        class: "mt-3",
        variant: "text",
        density: "compact",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.generateCustomerTags()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { size: "small" }, {
            default: _withCtx(() => _cache[31] || (_cache[31] = [
              _createTextVNode("fas fa-arrows-rotate")
            ])),
            _: 1
          }),
          _createVNode(_component_v_tooltip, {
            activator: "parent",
            location: "bottom"
          }, {
            default: _withCtx(() => _cache[32] || (_cache[32] = [
              _createTextVNode("Tags anhand historischer Einsätze und ausgeschriebener Stellen neu generieren")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "my-2" })
    ]),
    "dependencies-container": _withCtx(() => [
      (_ctx.customer && (_ctx.hasLinking(_ctx.customer?._id) || _ctx.isDependenciesActive))
        ? (_openBlock(), _createBlock(_component_Dependencies, {
            key: 0,
            mandant: [_ctx.customer?.mandants[0] || ''],
            customer_id: _ctx.customer?._id,
            linkingDescription: _ctx.linkingDescription,
            interComponentMessage: _ctx.interComponentMessage,
            ref: "dependenciesComponent",
            onGenerateProfile: _cache[14] || (_cache[14] = ($event: any) => (_ctx.generateProfileFromDependencies())),
            onOpenPhoneClient: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openPhoneClient()))
          }, null, 8, ["mandant", "customer_id", "linkingDescription", "interComponentMessage"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_divider, { class: "my-2" })
    ]),
    "info-container": _withCtx(() => [
      _createVNode(_component_Assignments, {
        isCustomer: true,
        assignments: _ctx.customer?.assignments,
        onUpdateAssignments: _cache[16] || (_cache[16] = ($event: any) => (
          _ctx.getAssignmentsFromErp(_ctx.customer?.customerNumber ?? '')
        ))
      }, null, 8, ["assignments"])
    ]),
    "timeline-container": _withCtx(() => [
      _createVNode(_component_Timeline, {
        contact: "Test Ansprechpartner",
        customer: _ctx.customer
      }, null, 8, ["customer"])
    ]),
    "append-container": _withCtx(() => [
      (_ctx.showModal || _ctx.resultsModal)
        ? (_openBlock(), _createBlock(_component_DialogUpdateCustomer, {
            key: 0,
            showModal: _ctx.showModal,
            showSearchResultsFromErp: _ctx.showSearchResultsFromErp,
            "onUpdate:showModal": _cache[17] || (_cache[17] = ($event: any) => (_ctx.showModal = $event)),
            results: _ctx.results,
            resultsModal: _ctx.resultsModal,
            "onUpdate:resultsModal": _cache[18] || (_cache[18] = ($event: any) => (_ctx.resultsModal = $event)),
            modalTitle: _ctx.modalTitle,
            modalType: _ctx.modalType,
            addCustomerModalInput1: _ctx.addCustomerModalInput1,
            addCustomerModalInput2: _ctx.addCustomerModalInput2,
            addCustomerModalLabel1: _ctx.addCustomerModalLabel1,
            addCustomerModalLabel2: _ctx.addCustomerModalLabel2,
            modalActionButtonText: _ctx.modalActionButtonText,
            onSubmit: _ctx.handleModalSubmit,
            onSelectedResult: _ctx.handleSelectedResult
          }, null, 8, ["showModal", "showSearchResultsFromErp", "results", "resultsModal", "modalTitle", "modalType", "addCustomerModalInput1", "addCustomerModalInput2", "addCustomerModalLabel1", "addCustomerModalLabel2", "modalActionButtonText", "onSubmit", "onSelectedResult"]))
        : _createCommentVNode("", true),
      (_ctx.isProfileGeneratorActive)
        ? (_openBlock(), _createBlock(_component_ProfileGenerator, {
            key: 1,
            ref: "profileGeneratorComponent",
            profileSourceData: _ctx.profileSourceData,
            onOpenMailClient: _ctx.openMailClientFromProfileGenerator
          }, null, 8, ["profileSourceData", "onOpenMailClient"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        (_ctx.customer?.jobAds)
          ? (_openBlock(), _createBlock(_component_JobAdListCustomer, {
              key: 0,
              ref: "jobAdListRef",
              onFetchIndexAnzeigendaten: _cache[19] || (_cache[19] = ($event: any) => (_ctx.autoFetchJobAds(true))),
              jobAds: _ctx.customer?.jobAds || [],
              mandant: _ctx.customer?.mandants[0] || '',
              positionX: _ctx.jobAdListPosition.x,
              positionY: _ctx.jobAdListPosition.y
            }, null, 8, ["jobAds", "mandant", "positionX", "positionY"]))
          : _createCommentVNode("", true)
      ]))
    ]),
    _: 1
  }, 8, ["color", "dragOverIcon", "dragOverText", "hoverColor", "isActive", "isDialog", "isFullyCollapsed", "parentRole", "showMatchIcon", "onDragOver", "onDragStart", "onDrop"]))
}