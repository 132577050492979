<template>
  <BaseDialog :showDialog="show" :showActions="true" @closeDialog="handleAbort">
    <template #title>
      {{ title }}
    </template>
    <template #content>
      <v-card-text class="pa-4">
        {{ promptQuestion }}
        <v-text-field
          v-bind="vStyle.input"
          v-model="inputValue"
          :label="inputLabelText"
          class="mt-4"
        ></v-text-field>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn :color="btnAbortColor" @click="handleAbort">{{
        btnAbortText
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn :color="btnConfirmColor" @click="handleConfirm">{{
        btnConfirmText
      }}</v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseDialog from "./BaseDialog.vue";
import { SpinnerService } from "../../services/spinner.service";

export enum PromptDialogEmit {
  confirm = "confirm",
}

export default defineComponent({
  name: "PromptDialog",
  components: {
    BaseDialog,
  },
  emits: [PromptDialogEmit.confirm],
  props: {
    title: { type: String, required: true },
    promptQuestion: { type: String, required: true },
    inputLabelText: { type: String, default: "Bitte Eingabe machen" },
    btnAbortText: { type: String, default: "Abbrechen" },
    btnConfirmText: { type: String, default: "Bestätigen" },
    btnAbortColor: { type: String, default: "error" },
    btnConfirmColor: { type: String, default: "success" },
  },
  data() {
    return {
      show: true,
      inputValue: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    SpinnerService.removeSpinner();
  },
  methods: {
    handleConfirm() {
      this.show = false;
      this.$emit(PromptDialogEmit.confirm, this.inputValue);
    },
    handleAbort() {
      this.show = false;
      this.$emit(PromptDialogEmit.confirm, "");
    },
  },
});
</script>
