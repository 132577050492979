import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
    disabled: !_ctx.isFormComplete
  }, _ctx.vStyle.btn, {
    color: _ctx.testSuccessful ? 'secondary' : 'primary',
    onClick: _ctx.testMailConnection
  }), {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" EMail testen ")
    ])),
    _: 1
  }, 16, ["disabled", "color", "onClick"]))
}