import {
  DispatcherBoard,
  User,
  UserBackend,
  UserConfig,
} from "@/models/user.model";
import store from "@/store/store";
import { PasswordChange } from "@/models/password-change.model";
import { ApiResponse } from "@/models/api-response.model";
import { Routes } from "@/enums/routes.enum";
import { ArrayUtils } from "../../helper/remove-duplicates.helper";
import {
  UserConfigFilterBoard,
  UserConfigFilterType,
} from "../../enums/board-actions.enum";
import { ApiPublic } from "./api-public.class";
import { Api } from "./api.class";
import { MutationType } from "../../enums/vuex-types.enum";
import { PerfInsight } from "@/models/perf.model";
import { DashboardAiText } from "../../models/dashboard-ai-text.model";
import router from "../../router";
import { BaseStoreModel } from "./base-store-model";

export class UserService extends BaseStoreModel<User> {
  private api = Api.getInstance();
  private apiPublic = ApiPublic.getInstance();
  private urlPath = "/user";

  async getModel(): Promise<User> {
    const response = await this.api.getBackendApi().get<User>(this.urlPath);
    const user: User = response.data;

    return Promise.resolve(user);
  }

  async getUsers() {
    const response = await this.api
      .getBackendApi()
      .get<User[]>(`${this.urlPath}/all`);
    return Promise.resolve(response.data);
  }

  async addUser(user: User) {
    const userToSend: UserBackend = {
      ...user,
      role: user.role._id || user.role.name,
    };
    const response = await this.api
      .getBackendApi()
      .post<User>(this.urlPath, userToSend);
    return response.data;
  }

  async editUser(user: User): Promise<ApiResponse | undefined> {
    if (user.config.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      return undefined;
    }
    const userToSend: UserBackend = {
      ...user,
      role: user.role?._id || user.role?.name,
    };
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(this.urlPath, userToSend);
    console.log("response", response);
    this.saveModelStore(user);
    return response.data;
  }

  saveModelStore(model: User) {
    if (store.getters.user?._id === model._id) {
      store.commit(MutationType.setUser, model);
    }
  }

  async updateUserConfig(userConfig: UserConfig) {
    if (userConfig.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      router.push(Routes.dashboard); // FIXME: when user mandant deletion bug from dispatcherboard is fixed
      return null;
    }

    if (userConfig.mandants)
      userConfig.mandants = ArrayUtils.removeDuplicates(userConfig.mandants);
    if (userConfig.loggedInMandants)
      userConfig.loggedInMandants = ArrayUtils.removeDuplicates(
        userConfig.loggedInMandants
      );

    const response = await this.api
      .getBackendApi()
      .put<UserConfig>(`/user/config`, userConfig);

    store.commit(MutationType.setUserConfig, userConfig);
    return response.data;
  }

  async updateUserConfigDispatcherBoard(
    dispatcherBoardFilters: DispatcherBoard
  ) {
    // Deduplicate properties in dispatcherBoard columns-filters
    if (dispatcherBoardFilters) {
      const columns: Array<keyof typeof dispatcherBoardFilters> = [
        UserConfigFilterBoard.columnCandidate,
        UserConfigFilterBoard.columnCustomer,
        UserConfigFilterBoard.columnDemand,
        UserConfigFilterBoard.columnEmployee,
      ];
      columns.forEach((column) => {
        const colConfig = dispatcherBoardFilters[column];
        if (colConfig) {
          ArrayUtils.deduplicateProperties(colConfig, [
            UserConfigFilterType.filterMandants,
            UserConfigFilterType.filterStatus,
            UserConfigFilterType.orderDraggable,
          ]);
        }
      });
    }
    const response = await this.api
      .getBackendApi()
      .put<UserConfig>(`/user/board-filters`, dispatcherBoardFilters);
    return response.data;
  }

  async updateUserProfile(
    userProfileData: Partial<User>
  ): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(`${this.urlPath}/profile`, userProfileData);
    store.commit(MutationType.setUserProfile, userProfileData);
    return response.data;
  }

  async changePassword(passwordChange: PasswordChange): Promise<ApiResponse> {
    return (
      await this.api
        .getBackendApi()
        .put(`${this.urlPath}/password`, passwordChange)
    ).data as ApiResponse;
  }

  async restorePassword(
    linkId: string,
    newPassword: string
  ): Promise<ApiResponse> {
    return (
      await this.apiPublic
        .getBackendApi()
        .post(`${this.urlPath}/restore-password`, { newPassword, linkId })
    ).data as ApiResponse;
  }

  async checkRestoreLink(id: string): Promise<ApiResponse> {
    const response = await this.apiPublic
      .getBackendApi()
      .get(`${this.urlPath}/check-restore-link?id=${id}`);
    return response.data as ApiResponse;
  }

  async forgotPassword(emailAddress: string): Promise<ApiResponse> {
    const response = await this.apiPublic
      .getBackendApi()
      .post(`${this.urlPath}/password-forgotten`, { emailAddress });
    return response.data as ApiResponse;
  }

  async removeUser(usermail: string): Promise<void> {
    await this.api.getBackendApi().delete(`${this.urlPath}/${usermail}`);
  }

  async updatePerfInsights(
    userId: string,
    newPerfInsight: PerfInsight
  ): Promise<ApiResponse> {
    try {
      const response = await this.api
        .getBackendApi()
        .put<ApiResponse>(`${this.urlPath}/${userId}/perf`, newPerfInsight);
      return response.data.response;
    } catch (error) {
      console.error("Error updating P.E.R.F. insights:", error);
      throw error;
    }
  }

  async updateDashboardText(
    userId: string,
    newDashboardText: DashboardAiText
  ): Promise<ApiResponse> {
    try {
      const response = await this.api
        .getBackendApi()
        .put<ApiResponse>(
          `${this.urlPath}/${userId}/dashboard-text`,
          newDashboardText
        );
      return response.data.response;
    } catch (error) {
      console.error("Error updating dashboard text:", error);
      throw error;
    }
  }
}
