<!--src/components/config/software-integration/ConfigZvooveOne.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für ERP System zvoove One
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="9"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.erpAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-bind="vStyle.input"
            v-model="localConfig.softwareIntegration.zvooveOneLink"
            label="zvoove One Link"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-bind="vStyle.input"
            v-model="localConfig.apiKeys.baseURLZvooveOne"
            label="Base URL API"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-bind="vStyle.input"
            v-model="localConfig.apiKeys.xCoreClientIdZvooveOne"
            label="X-Core-Client-Id"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ConfigZvooveOne",
  components: {
    ConfigSaveButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      localConfig: cloneDeep(this.modelValue),
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue: {
      handler(newValue: CompanyConfig) {
        this.localConfig = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localConfig: {
      handler(newValue: CompanyConfig) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
