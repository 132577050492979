<!--src/components/dispostion/MailClient.vue-->
<template>
  <div>
    <v-btn
      density="compact"
      variant="text"
      icon
      class="communication-container-icon"
      @click="openMailClient()"
    >
      <v-icon size="1.5rem">fa-solid fa-envelope</v-icon>
      <v-tooltip activator="parent" location="bottom">Email öffnen</v-tooltip>
    </v-btn>

    <BaseDialog
      :showDialog="showModal"
      :showActions="true"
      @closeDialog="showModal = false"
      :width="'var(--d-w-l)'"
    >
      <template #title>
        <v-icon class="mx-2">fa-solid fa-envelope</v-icon>E-Mail senden
      </template>
      <template #content>
        <v-card-text>
          <v-select
            v-bind="vStyle.input"
            v-model="senderModel"
            :items="senderMailAddresses"
            item-title="email"
            item-value="data"
            prepend-inner-icon="fa-solid fa-user"
            return-object
            label="Absender"
          />
          <!-- TODO combobox + rules only valid addresses -->
          <v-select
            v-bind="vStyle.input"
            v-model="selectedEmailObject"
            :items="emailAddresses"
            label="Empfänger wählen"
            item-title="mailaddress"
            return-object
            @update:model-value="updateFullMessage()"
            prepend-inner-icon="fa-solid fa-building-user"
          >
            <template #append-inner>
              <v-icon @click="$emit(MailClientEmit.editContacts)">{{
                selectedEmailObject.mailaddress === MessageContent.noMailAddress
                  ? "fa-solid fa-plus"
                  : "fa-solid fa-pencil"
              }}</v-icon>
              <v-tooltip activator="parent" location="top left">{{
                selectedEmailObject.mailaddress === MessageContent.noMailAddress
                  ? "Kontakt hinzufügen"
                  : "Kontakte bearbeiten"
              }}</v-tooltip>
            </template>
          </v-select>

          <v-text-field
            v-bind="vStyle.input"
            v-model="mailSubject"
            label="Betreff"
          ></v-text-field>
          <v-file-input
            v-bind="vStyle.input"
            accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpg, image/jpeg"
            prepend-icon="fa-solid fa-paperclip"
            label="Dokumentanhang (*.pdf, *.doc, *.docx, *.png, *.jpg, *.jpeg)"
            v-model="file"
            chips
            multiple
          >
          </v-file-input>
          <v-card v-bind="vStyle.card" class="main-container">
            <div
              class="editor-container editor-container_classic-editor"
              ref="editorContainerElement"
            >
              <div class="editor-container__editor">
                <div ref="editorElement">
                  <ckeditor
                    v-if="isLayoutReady"
                    v-model="fullMessage"
                    :editor="editor"
                    :config="config"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-card-text>
      </template>
      <template #actions>
        <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="showAiModelMenu($event)">
          <v-icon>fa-solid fa-rocket</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >Mail mit AI umformulieren</v-tooltip
          >
        </v-btn>
        <v-menu
          v-model="menuAiModelVisible"
          class="ai-menu"
          :style="{
            left: `${menuPosition.x}px`,
            top: `${menuPosition.y}px`,
          }"
          ref="aiMenu"
        >
          <v-list>
            <v-list-item
              v-for="aiOption in aiOptions"
              :key="aiOption.value"
              @click="handleAiOptions(aiOption)"
            >
              <v-list-item-title>{{ aiOption.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="longpress"
          icon
          @click="showLanguageMenu($event)"
          @contextmenu.prevent="showAiModelMenu($event, true)"
          @longpress="showAiModelMenu($event, true)"
        >
          <v-icon>fa-solid fa-comments</v-icon>
          <v-tooltip activator="parent" location="bottom"
            >mit AI in eine andere Sprache übersetzen</v-tooltip
          >
        </v-btn>
        <!--language selection for Ai Translator-->
        <v-menu
          v-model="menuTranslationVisible"
          class="ai-menu"
          :style="{
            left: `${menuPosition.x}px`,
            top: `${menuPosition.y}px`,
          }"
          ref="aiMenu"
        >
          <v-list>
            <v-list-item
              v-for="language in languages"
              :key="language"
              @click="selectLanguageAndTranslate(language)"
            >
              <v-list-item-title>{{ language }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="sendMessage"
          :disabled="
            !(senderMailAddresses && selectedEmailObject && mailSubject)
          "
          >Senden</v-btn
        >
      </template>
    </BaseDialog>
  </div>
</template>

<script lang="ts">
import { Customer } from "@/models/customer.model";
import { defineComponent, PropType, toRaw } from "vue";
import DialogService from "@/services/dialog.service";
import { EmailDetails } from "@/models/email-details.model";
import { EmailService } from "@/services/api/email.service";
import { AiService } from "@/services/ai.service";
import { Mandant } from "@/models/mandant.model";
import { SpinnerService } from "@/services/spinner.service";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import { useDisplay } from "vuetify";
import { CandidateList } from "@/models/candidate-list.model";
import { Employee } from "@/models/employee.model";
import ToastService from "@/services/toast.service";
import "ckeditor5/ckeditor5.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translations from "ckeditor5/translations/de.js"; // FIXME

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";
import moment from "moment";
import { getEnumOptions } from "@/helper/enum.helper";
import { AiMessageType, AiOptions } from "@/enums/ai-options.enum";
import { Email } from "@/models/email.model";
import { MessageContent } from "@/enums/empty-message.enum";
import { LinkingEvent } from "@/models/linking.model";
import { mapGetters, mapMutations } from "vuex";
import { EmailFrom } from "../../../enums/email-from.enum";
import { MutationType } from "../../../enums/vuex-types.enum";
import { InterComponentMessage } from "../../../enums/inter-component-messagin.enum";
import { ProfileSendDocumentation } from "../../../models/profile.model";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { VuetifyColor } from "../../../plugins/vuetify";

enum MailClientEmit {
  editContacts = "editContacts",
  collapseParentItem = "collapseParentItem",
}

export default defineComponent({
  name: "MailClient",
  emits: [MailClientEmit.editContacts, MailClientEmit.collapseParentItem],
  components: {
    BaseDialog,
  },
  props: {
    candidate: {
      type: Object as () => CandidateList | null,
      default: null,
    },
    candidateName: {
      type: String,
      default: "",
    },
    customer: {
      type: Object as () => Customer | null,
      default: null,
    },
    employee: {
      type: Object as () => Employee | null,
      default: null,
    },
    emailObject: {
      type: Object as () => Email,
      default: () => ({
        body: "",
        subject: "",
      }),
    },
    linkingStatuses: {
      type: Array as PropType<LinkingEvent[]>,
      default: () => [],
    },
    signature: {
      type: String,
      default: "",
    },
    emailAddresses: {
      type: Array as PropType<EmailDetails[]>,
      required: true,
    },
    AiMessageType: {
      type: String as PropType<AiMessageType>,
      default: AiMessageType.mailCustomer,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      MessageContent: MessageContent,
      file: [] as Array<File>,
      smAndDown,
      awaitSelection: false,
      config: null as any, // CKEditor needs the DOM tree before calculating the configuration.
      editor: ClassicEditor,
      fullMessage: `${MessageContent.standardSalutation}<br>${this.emailObject.body}`,
      isLayoutReady: false,
      isProfileEmail: false,
      MailClientEmit: MailClientEmit,
      mailSubject: this.emailObject.subject,
      menuTranslationVisible: false,
      menuAiModelVisible: false,
      menuPosition: { x: 0, y: 0 },
      lastSalutation: MessageContent.standardSalutation as string,
      profileSendDocumentation: {} as ProfileSendDocumentation,
      selectedAiModel: "",
      selectedEmailObject:
        this.emailAddresses.length > 0
          ? this.emailAddresses[0]
          : {
              mailaddress: MessageContent.noMailAddress as string,
              salutation: MessageContent.standardSalutation as string,
            },
      senderMailAddresses: this.getSenderMailAddresses(),
      senderModel: this.getFromEmailPreselection(),
      showModal: false,
      targetLanguage: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      mandants: "reducedMandants",
      company: "company",
      user: "user",
    }),
    aiOptions() {
      return getEnumOptions(AiOptions);
    },
    languages() {
      return this.$store.state.company.aiData.languages;
    },
    mailSignature() {
      if (this.$store.state.company.loggedInUser.config.aiData.mailSignature) {
        return this.$store.state.company.loggedInUser.config.aiData
          .mailSignature;
      } else {
        return this.signature;
      }
    },
    userMailServerConfigFrom() {
      return this.$store.state?.company?.loggedInUser?.config?.mailServer?.from;
    },
  },
  watch: {
    salutation() {
      this.updateFullMessage();
    },
    emailObject: {
      handler(newVal: Email) {
        this.updateFullMessage(newVal);

        this.mailSubject = newVal.subject;
        if (newVal.pdf && newVal.pdf.data && newVal.pdf.fileName) {
          const blob = new Blob([newVal.pdf.data], { type: "application/pdf" });
          const file = new File([blob], newVal.pdf.fileName, {
            type: "application/pdf",
          });

          this.file = [file];
        } else {
          this.file = [];
        }
      },
      deep: true,
    },
    signature() {
      this.updateFullMessage();
    },
  },
  mounted() {
    this.config = {
      toolbar: {
        items: [
          "undo",
          "redo",
          "|",
          "selectAll",
          "|",
          "heading",
          "|",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "subscript",
          "superscript",
          "|",
          "specialCharacters",
          "horizontalLine",
          "link",
          "insertImage",
          "insertTable",
          "highlight",
          "blockQuote",
          "|",
          "alignment",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "indent",
          "outdent",
          "|",
          "accessibilityHelp",
        ],
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        Base64UploadAdapter,
        BlockQuote,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        Paragraph,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TodoList,
        Underline,
        Undo,
      ],
      balloonToolbar: [
        "bold",
        "italic",
        "|",
        "link",
        "insertImage",
        "|",
        "bulletedList",
        "numberedList",
      ],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
          {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
          },
        ],
      },
      image: {
        toolbar: [
          "toggleImageCaption",
          "imageTextAlternative",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "|",
          "resizeImage",
        ],
      },
      initialData: "",
      language: "de",
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: "https://",
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      placeholder: "Type or paste your content here!",
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      translations: [translations],
    };

    this.isLayoutReady = true;
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    cleanMessage() {
      const salutation = this.selectedEmailObject.salutation;
      const mailSignatur = this.mailSignature;
      const lastSalutation = this.lastSalutation;

      let message = this.fullMessage;

      if (!(salutation && lastSalutation && mailSignatur)) return "";

      const escapedSalutation = salutation.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const escapedLastSalutation = lastSalutation.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const escapedSignature = mailSignatur.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const salutationRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSalutation}\\s*(<[^>]*>)*`,
        "gi"
      );
      const lastSalutationRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedLastSalutation}\\s*(<[^>]*>)*`,
        "gi"
      );
      const mailSignaturRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSignature
          .split("\n")
          .map((line: any) => line.trim())
          .join("\\s*")}\\s*(<[^>]*>)*`,
        "gi"
      );

      // remove all occurrences of salutation, lastSalutation and mailSignatur including surrounding HTML tags and spaces
      message = message
        .replace(salutationRegex, "")
        .replace(lastSalutationRegex, "")
        .replace(mailSignaturRegex, "")
        .trim();

      // remove any extraneous HTML tags or empty paragraphs
      message = message
        .replace(/<p>\s*<\/p>/g, "")
        .replace(/<br>\s*<br>/g, "<br>");

      return message;
    },

    closeModal() {
      this.showModal = false;
    },
    async checkGDPR(reminderType: string) {
      const reminderDSGVO = this.$store.state.reminderDSGVO[reminderType];

      const lastReminderTime = moment(reminderDSGVO.lastReminder);
      const currentTime = moment();
      const intervalTime = lastReminderTime
        .add(reminderDSGVO.intervalHours, "hours")
        .add(reminderDSGVO.intervalDays, "days");

      const hasToCheck =
        !reminderDSGVO.lastReminder || currentTime.isAfter(intervalTime);

      if (hasToCheck) {
        const confirmed = await DialogService.confirm(
          reminderDSGVO.reminderText,
          "Abbrechen",
          "mit ChatGPT verarbeiten",
          "DSGVO check",
          VuetifyColor.abort,
          VuetifyColor.success
        );

        if (!confirmed) {
          return Promise.reject("DSGVO check not confirmed");
        }

        this.$store.state.reminderDSGVO[reminderType].lastReminder =
          moment().format("YYYY-MM-DD HH:mm:ss");
      }

      return Promise.resolve();
    },
    getFromEmailPreselection() {
      let email;
      let data;

      // check for office365 email
      const userOffice365Email =
        this.$store.state?.company?.loggedInUser?.config?.accessTokens
          ?.office365?.email;
      const userMailFrom =
        this.$store.state?.company?.loggedInUser?.config?.mailServer?.from;

      if (userOffice365Email || userMailFrom) {
        email = userOffice365Email || userMailFrom;
        data = EmailFrom.user;
      } else {
        const mandantEmailFromSelection = this.getMandantEmailFromSelection();
        if (mandantEmailFromSelection.email) {
          email = mandantEmailFromSelection.email;
          data = mandantEmailFromSelection.data;
        }
      }

      // Fallback company mail
      if (!email) {
        const companyOffice365Email =
          this.$store.state?.company?.accessTokens?.office365?.email;
        const companyMailServer = this.$store.state?.company?.mailServer?.from;

        if (companyOffice365Email || companyMailServer) {
          email = "Firma: " + (companyOffice365Email || companyMailServer);
          data = EmailFrom.company;
        }
      }

      return { email, data };
    },
    getMandantEmailFromSelection() {
      const result = {
        email: "",
        data: "",
      };

      const loggedInMandantsMailservers =
        this.$store.getters.getMailServersForLoggedInMandants;

      for (const mandant of loggedInMandantsMailservers) {
        const fromEmail = mandant?.mailServer?.from;
        if (fromEmail) {
          result.email = fromEmail;
          result.data = mandant.uuid;
          break;
        }
      }

      return result;
    },
    getStatusWithCompany(linkingStatuses: LinkingEvent[]): string {
      const companyEvents = linkingStatuses.filter(
        (event) => !event.description
      );

      let statusWithCompany = "aktueller Status des Kandidaten mit uns:\n";

      if (companyEvents.length > 0) {
        const lastCompanyEvent = companyEvents[companyEvents.length - 1];
        const eventDates = lastCompanyEvent.eventDate.join(", ");
        statusWithCompany += `${lastCompanyEvent.eventType} am ${eventDates}\n`;
      } else {
        statusWithCompany += "Es gibt noch keine Events mit uns.\n";
      }

      return statusWithCompany;
    },
    getStatusWithCustomers(linkingStatuses: LinkingEvent[]): string {
      const customerEvents = linkingStatuses.filter(
        (event) => event.description
      );

      let statusWithCustomers = "aktueller Status der Bewerbungsverfahren:\n";

      customerEvents.forEach((event) => {
        const eventDates = event.eventDate.join(", ");
        statusWithCustomers += `Kunde: ${event.description} - Letztes Event: ${event.eventType} am ${eventDates}\n`;
      });

      return statusWithCustomers;
    },
    getUserMandantsWithMailServerConfig(
      companyMandants: Mandant[],
      userMandants: string[]
    ) {
      const items: any = [];
      const filteredMandants = companyMandants.filter((mandant: Mandant) =>
        userMandants.includes(mandant.uuid)
      );
      filteredMandants.forEach((mandant: Mandant) => {
        if (
          mandant?.mailServer?.from ||
          mandant.accessTokens?.office365?.accessToken
        ) {
          items.push({
            email:
              `Niederlassung ${mandant.name}: ` +
              (mandant.accessTokens?.office365?.email ||
                mandant?.mailServer?.from),
            data: mandant.uuid,
          });
        }
      });

      return items;
    },
    handleAiOptions(aiModel: { value: string; label: string }) {
      if (this.awaitSelection) {
        ToastService.showSuccess(
          `"${aiModel.label}" wurde als AI für die Übersetzung ausgewählt`
        );
        this.selectedAiModel = aiModel.label;
        this.awaitSelection = false;
      } else {
        this.handleGenerateText(aiModel.label);
      }
    },
    async handleGenerateText(aiModel: string) {
      let additionalPrompt = "";
      if (
        this.candidate &&
        this.linkingStatuses.length > 0 &&
        this.emailObject.body === MessageContent.empty
      ) {
        const today = `Heutiges Datum: ${moment().format("DD-MM-YYYY HH:mm")}`;
        const statusWithCompany = this.getStatusWithCompany(
          this.linkingStatuses
        );
        const statusWithCustomers = this.getStatusWithCustomers(
          this.linkingStatuses
        );
        additionalPrompt = `Generiere Deine Nachricht basierend auf diesen Informationen:\n${today}\n${statusWithCompany}\n${statusWithCustomers}`;
      }

      if (aiModel === AiOptions.chatGPT) {
        try {
          await this.checkGDPR("eMail");

          SpinnerService.showSpinner();
          const cleanedMessage = this.cleanMessage();
          const aiService = new AiService();
          const response = await aiService.generateEmail(
            cleanedMessage,
            additionalPrompt,
            this.AiMessageType,
            false
          );
          const eMail = {
            body: response.text,
          };
          this.updateFullMessage(eMail);

          if (response.subject) {
            this.mailSubject = response.subject;
          }
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Textgenerierung!");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else if (aiModel === AiOptions.recurion) {
        SpinnerService.showSpinner();
        try {
          const cleanedMessage = this.cleanMessage();
          const aiService = new AiService();
          const response = await aiService.generateEmail(
            cleanedMessage,
            additionalPrompt,
            this.AiMessageType,
            true
          );
          const eMail = {
            body: response.text,
          };
          this.updateFullMessage(eMail);

          if (response.subject) {
            this.mailSubject = response.subject;
          }

          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Textgenerierung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    async handleTranslateText(targetLanguage: string) {
      if (this.selectedAiModel === AiOptions.chatGPT) {
        try {
          await this.checkGDPR("translation");

          SpinnerService.showSpinner();
          const originalText = `${
            this.selectedEmailObject.salutation
          }<br>${this.cleanMessage()}`;
          const aiService = new AiService();
          const response = await aiService.translateMessage(
            originalText,
            targetLanguage,
            false
          );
          const translatedText = response.text;

          this.fullMessage = `${translatedText}<br><hr><strong>Original:</strong><br>${originalText}`;
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Übersetzung!");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else {
        SpinnerService.showSpinner();
        try {
          const originalText = this.fullMessage;
          const salutationAndText = `${
            this.selectedEmailObject.salutation
          }<br>${this.cleanMessage()}`;
          const aiService = new AiService();
          const response = await aiService.translateMessage(
            salutationAndText,
            targetLanguage,
            true
          );
          const translatedText = response.text;
          this.fullMessage = `${translatedText}}<br><hr><strong>Original:</strong><br>${originalText}`;
          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Übersetzung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    openMailClient() {
      this.showModal = true;
      this.updateFullMessage();
    },
    openMailTo(
      selectedEmail: any,
      mailSubject: any,
      fullMessage: any,
      attachmentUrl?: string
    ): boolean {
      let mailtoLink = `mailto:${encodeURIComponent(
        selectedEmail
      )}?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(
        fullMessage
      )}`;

      if (attachmentUrl) {
        mailtoLink += `&attachment=${encodeURIComponent(attachmentUrl)}`;
      }

      window.open(mailtoLink, "_blank");
      return true;
    },
    openModal(profileSendDocumentation?: ProfileSendDocumentation) {
      if (profileSendDocumentation) {
        this.isProfileEmail = true;
        this.profileSendDocumentation = profileSendDocumentation;
      }
      this.showModal = true;
    },
    async selectLanguageAndTranslate(language: string) {
      this.targetLanguage = language;
      await this.handleTranslateText(language);
      this.menuTranslationVisible = false;
    },
    async sendMail(
      toEmail: any,
      mailSubject: any,
      fullMessage: any,
      email: Email,
      fromSelection: string
    ): Promise<boolean> {
      const emailMessage: Email = {
        body: fullMessage,
        mandantUuid: !["user", "company"].includes(fromSelection)
          ? fromSelection
          : undefined,
        from: ["user", "company"].includes(fromSelection)
          ? fromSelection
          : undefined,
        pdf: toRaw(email.pdf),
        pdfId: this.emailObject.pdfId,
        subject: mailSubject,
        to: toEmail,
      };
      const emailService = new EmailService();
      const result = await emailService.sendMail(emailMessage, this.file);
      if (result.error) {
        ToastService.showError(
          "Fehler beim Senden der Email. Bitte kontrollieren Sie Ihre Konfiguration bzw. Email Zugangsdaten"
        );
        return false;
      }
      return true;
    },
    getCompanyMail(): string {
      const company = this.$store.state.company;
      return (
        "Firma: " +
        (company.accessTokens?.office365?.email || company.mailServer?.from)
      );
    },
    getUserMail(): string {
      const userMailServerConfigFrom =
        this.$store.state.company.loggedInUser?.config?.mailServer?.from;
      const office365Mail =
        this.$store.state.company.loggedInUser?.config.accessTokens?.office365
          ?.email;
      const userEmail = office365Mail || userMailServerConfigFrom;
      if (!userEmail) return "";

      return `Benutzer: ${userEmail}`;
    },
    getSenderMailAddresses() {
      let senderMailAddresses = [];
      const userMandantsWithMailServerConfig =
        this.getUserMandantsWithMailServerConfig(
          this.$store.state.company.mandants,
          this.$store.state.company.loggedInUser.config.loggedInMandants
        );
      const companyMail = this.getCompanyMail();
      const userMail = this.getUserMail();

      senderMailAddresses.push({ email: companyMail, data: "company" });
      senderMailAddresses = senderMailAddresses.concat(
        userMandantsWithMailServerConfig
      );

      if (userMail) {
        senderMailAddresses.push({
          email: userMail,
          data: EmailFrom.user,
        });
      }

      return senderMailAddresses;
    },
    async sendMessage() {
      let result = false;
      enum EmailConfig {
        server = "server",
        mailTo = "mailTo",
      }
      const emailConfig: EmailConfig = EmailConfig.server as EmailConfig;
      switch (emailConfig) {
        case EmailConfig.server:
          result = await this.sendMail(
            this.selectedEmailObject.mailaddress,
            this.mailSubject,
            this.fullMessage,
            this.emailObject as Email,
            this.senderModel.data ?? ""
          );
          break;
        case EmailConfig.mailTo:
          result = this.openMailTo(
            this.selectedEmailObject.mailaddress,
            this.mailSubject,
            this.fullMessage
            //TODO add attachment link
          );
          break;
        default:
          break;
      }
      if (result) {
        this.$emit(MailClientEmit.collapseParentItem);
        this.closeModal();
        this.submitEntry(
          `Mail gesendet an ${this.selectedEmailObject.mailaddress}: ${this.fullMessage}`
        );
      }
    },
    showLanguageMenu(event: MouseEvent) {
      this.menuTranslationVisible = true;
      this.menuPosition.x = event.clientX;
      this.menuPosition.y = 100;
    },
    showAiModelMenu(event: MouseEvent, awaitSelection?: boolean) {
      if (awaitSelection) this.awaitSelection = true;
      this.menuAiModelVisible = true;
      const menuHeight = 150;
      const windowHeight = window.innerHeight;

      let menuY = event.clientY;

      if (menuY + menuHeight > windowHeight) {
        menuY = windowHeight - menuHeight;
      }

      this.menuPosition.x = event.clientX;
      this.menuPosition.y = menuY;
    },
    submitEntry(noteText: string) {
      const mandants = this.$store.state.company.mandants;

      let timelineData = {
        note: noteText,
        entryType: "",
        mandant: "",
        customer: undefined as
          | undefined
          | { name: string; contact: string; customerId: string | undefined },
        participant: undefined as
          | undefined
          | { name: string; uuid: string; employeeId: string },
      };

      if (this.candidate) {
        const participantName = this.candidateName;
        const mandantUuid = this.candidate?.mandants[0]
          ? this.candidate?.mandants[0]
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.emailCandidate.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: this.candidate.candidateUuid,
          employeeId: "",
        };
      } else if (this.customer) {
        let entryType =
          this.$store.state.company.timelineEntryTypes.eMailCustomer
            .description;
        if (this.isProfileEmail) {
          entryType =
            this.$store.state.company.timelineEntryTypes.candidateSuggestion
              .description;
          timelineData.participant = {
            name: this.profileSendDocumentation.name,
            uuid: this.profileSendDocumentation.uuid,
            employeeId: this.profileSendDocumentation.employee_id,
          };
          this.setICM({
            message: InterComponentMessage.profileSend,
            payload: {
              candidateId: this.profileSendDocumentation.candidateId,
              customerId: this.profileSendDocumentation.customerId,
            },
          });
        }
        const customerName = this.customer.generalData.name;
        const customerContact = this.selectedEmailObject.mailaddress
          ? this.selectedEmailObject.mailaddress
          : "";
        const customerId = this.customer._id;
        const mandantUuid = this.customer.mandants[0] || "";

        timelineData.entryType = entryType;
        timelineData.mandant = mandantUuid;
        timelineData.customer = {
          name: customerName,
          contact: customerContact,
          customerId: customerId,
        };
      } else if (this.employee) {
        const participantName = `${this.employee.firstName} ${this.employee.lastName}`;
        const matchedMandant = mandants.find(
          (m: Mandant) => m.branchNumber === this.employee?.branchOfficeId
        );
        const mandantUuid = matchedMandant
          ? matchedMandant.uuid
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.eMailEmployee.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: "",
          employeeId: this.employee._id ?? "",
        };
      }

      const timelineHelperService = new TimelineHelperService();
      timelineHelperService
        .timelineAutoDocu(timelineData)
        .then(() => {
          ToastService.show("E-Mail Eintrag erfolgreich hinzugefügt.");
        })
        .catch((error: any) => {
          console.error(
            "Fehler beim Hinzufügen des E-Mail Timeline-Eintrags:",
            error
          );
          ToastService.showError("Fehler beim Dokumentieren der E-Mail.");
        });
    },
    updateFullMessage(email?: Email) {
      let message = this.cleanMessage();
      if (email && email.body) {
        message = email.body;
      }
      this.fullMessage = `${this.selectedEmailObject.salutation}<br>${message}<br><br>${this.mailSignature}`;
      this.lastSalutation = this.selectedEmailObject.salutation;
    },
  },
  provide() {
    return {
      openMailClientModal: this.openModal,
    };
  },
});
</script>

<style scoped>
.translation-menu {
  position: fixed;
  top: 0%;
  left: 50%;
  z-index: 200;
}

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  font-family: var(--font-standard);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.ck-content {
  font-family: var(--font-standard);
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  border: 1px solid var(--border-medium);
  border-radius: var(--border-radius-elements);
  max-width: 100%;
}
.companyEmail {
  background-color: red;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: white;
}
</style>
