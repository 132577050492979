import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-body-1" }
const _hoisted_2 = { class: "text-body-1 mb-2" }
const _hoisted_3 = { class: "text-body-1 mb-2" }
const _hoisted_4 = { class: "text-body-2 text-medium-emphasis" }
const _hoisted_5 = { class: "text-body-1" }
const _hoisted_6 = { class: "text-body-1 mb-2" }
const _hoisted_7 = { class: "text-body-1 mb-2" }
const _hoisted_8 = { class: "text-body-2 text-medium-emphasis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    "show-dialog": _ctx.showDialog,
    width: 'var(--d-w-l)',
    onCloseDialog: _ctx.closeDialog
  }, {
    title: _withCtx(() => _cache[2] || (_cache[2] = [
      _createTextVNode("Es existiert bereits ein Kandidat mit diesen dieser externen Id")
    ])),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, { class: "text-medium-emphasis mr-1" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("fa-solid fa-circle-question")
            ])),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-body-2" }, " Die Kandidaten werden zusammengeführt. Lebenslaufdaten und Skills bleiben von beiden erhalten. Von welchem Datensatz sollen die restlichen Daten (Name, Adresse, etc.) übernommen werden? ", -1))
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              (_ctx.candidateExisting)
                ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({ key: 0 }, _ctx.vStyle?.card, {
                    class: "pa-4 h-100 candidate-card",
                    hover: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectExisting()))
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        style: {"color":"var(--color-primary)"},
                        class: "text-h6 mb-2"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" aus Datenbank ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.candidateExisting?.firstName) + " " + _toDisplayString(_ctx.candidateExisting?.lastName), 1),
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.candidateExisting?.addressStreet) + " " + _toDisplayString(_ctx.candidateExisting?.addressHouseNumber), 1),
                          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.candidateExisting?.addressPostalCode) + " " + _toDisplayString(_ctx.candidateExisting?.addressCity), 1),
                          _createElementVNode("div", _hoisted_4, " Geburtsdatum: " + _toDisplayString(_ctx.FormatDate.germanStandard(_ctx.candidateExisting?.birthDate)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 16))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              (_ctx.candidateCurrent)
                ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({ key: 0 }, _ctx.vStyle?.card, {
                    class: "pa-4 h-100 candidate-card",
                    hover: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectCurrent()))
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, {
                        style: {"color":"var(--color-secondary)"},
                        class: "text-h6 mb-2"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Aktuell ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.candidateCurrent?.firstName) + " " + _toDisplayString(_ctx.candidateCurrent?.lastName), 1),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.candidateCurrent?.addressStreet) + " " + _toDisplayString(_ctx.candidateCurrent?.addressHouseNumber), 1),
                          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.candidateCurrent?.addressPostalCode) + " " + _toDisplayString(_ctx.candidateCurrent?.addressCity), 1),
                          _createElementVNode("div", _hoisted_8, " Geburtsdatum: " + _toDisplayString(_ctx.FormatDate.germanStandard(_ctx.candidateCurrent?.birthDate)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 16))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            variant: "text",
            color: "error",
            onClick: _ctx.closeDialog
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Abbrechen ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show-dialog", "onCloseDialog"]))
}