import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "ml-0 pl-0 header-dispo-column"
}
const _hoisted_2 = { class: "mandants-options" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "ma-0 pa-0 dispatcher-board-draggable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CvDropField = _resolveComponent("CvDropField")!
  const _component_DialogAddCandidateToAts = _resolveComponent("DialogAddCandidateToAts")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_FilterMenu = _resolveComponent("FilterMenu")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_CandidateItem = _resolveComponent("CandidateItem")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_infinite_scroll = _resolveComponent("v-infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", {
    class: "column",
    onDragover: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
    onDragleave: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args))),
    onDrop: _cache[19] || (_cache[19] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createVNode(_component_CvDropField, {
      showDropField: _ctx.showDropField,
      parentRole: _ctx.Role.candidate,
      fieldText: _ctx.dropFieldText,
      parseWithPersonalData: true,
      onParsedCandidate: _ctx.receiveParsedCandidate,
      onHandleDrop: _ctx.handleDrop
    }, null, 8, ["showDropField", "parentRole", "fieldText", "onParsedCandidate", "onHandleDrop"]),
    (_ctx.showAddCandidateToAtsDialog)
      ? (_openBlock(), _createBlock(_component_DialogAddCandidateToAts, {
          key: 0,
          showDialog: _ctx.showAddCandidateToAtsDialog,
          candidate: _ctx.candidateFromCvParser,
          candidateToSendToAts: _ctx.candidateToSendToAts,
          droppedCv: _ctx.droppedCv,
          onCloseDialog: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddCandidateToAtsDialog = false)),
          onCandidateCreatedOrUpdated: _ctx.addCandidate
        }, null, 8, ["showDialog", "candidate", "candidateToSendToAts", "droppedCv", "onCandidateCreatedOrUpdated"]))
      : _createCommentVNode("", true),
    (!_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(
        _ctx.mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      )
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_FilterMenu, null, {
              "filter-menu-left-column": _withCtx(() => [
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  density: "compact",
                  label: "Suchbegriff",
                  onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.getCandidatesBySearchTerm(_ctx.searchTerm)), ["enter"]))
                }), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "top"
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("Einen oder mehrere Suchbegriffe eingeben und mit Enter bestätigen")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 16, ["modelValue"]),
                _createVNode(_component_v_divider, { class: "mb-4" }),
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                  type: "date",
                  label: "Beworben nach",
                  density: "compact",
                  modelValue: _ctx.dateAfter,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dateAfter) = $event)),
                  clearable: "",
                  "max-width": "10rem"
                }), null, 16, ["modelValue"]),
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.selectedStatus,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedStatus) = $event)),
                  onChange: _ctx.selectStatusFilter
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptionsRecruit, (status) => {
                      return (_openBlock(), _createBlock(_component_v_radio, {
                        key: status.value,
                        label: status.text,
                        value: status.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_switch, {
                    label: "immer ATS synchronisieren",
                    color: "primary",
                    modelValue: _ctx.shouldAllwaysSyncWithAts,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shouldAllwaysSyncWithAts) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "top left"
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("Kandidaten werden bei jedem Abruf mit dem ATS-System synchronisiert (langsamer)")
                    ])),
                    _: 1
                  })
                ])
              ]),
              "filter-menu-right-column": _withCtx(() => [
                _createVNode(_component_v_select, _mergeProps(_ctx.vStyle?.input, {
                  modelValue: _ctx.filterCandidatesCurrent,
                  "onUpdate:modelValue": [
                    _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterCandidatesCurrent) = $event)),
                    _ctx.updateItemsOrder
                  ],
                  density: "compact",
                  items: _ctx.FilterCandidates,
                  label: "sortieren nach ...",
                  "item-title": "label",
                  "item-value": "label"
                }), null, 16, ["modelValue", "items", "onUpdate:modelValue"]),
                _createVNode(_component_v_divider, { class: "mb-4" }),
                _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle?.input, {
                  type: "date",
                  label: "Beworben vor",
                  density: "compact",
                  modelValue: _ctx.dateBefore,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dateBefore) = $event)),
                  clearable: "",
                  "max-width": "10rem"
                }), null, 16, ["modelValue"]),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mandants, (mandant) => {
                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                      density: "compact",
                      key: mandant.uuid,
                      modelValue: _ctx.selectedMandants,
                      "onUpdate:modelValue": [
                        _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedMandants) = $event)),
                        _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateMandantsFilter(_ctx.selectedMandants)))
                      ],
                      label: mandant.name,
                      value: mandant.uuid,
                      class: "ma-0 pa-0"
                    }, null, 8, ["modelValue", "label", "value"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_v_slider, {
            class: "mt-4 mr-2 text-caption",
            modelValue: _ctx.sliderCandidateStatusValue,
            "onUpdate:modelValue": [
              _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sliderCandidateStatusValue) = $event)),
              _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleSliderChange()))
            ],
            max: 4,
            step: "1",
            "track-color": _ctx.sliderCandidateStatusValue !== null ? 'primary' : 'grey',
            color: _ctx.sliderCandidateStatusValue !== null ? 'primary' : 'secondary',
            "tick-size": "10",
            "show-ticks": "always"
          }, {
            "tick-label": _withCtx(({ index }) => [
              (_ctx.mdAndDown)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: index,
                    color: "primary-darken-1",
                    class: "mt-3"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.sliderCandidateStateIcons[
                index as keyof typeof sliderCandidateStateIcons
              ]), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.sliderCandidateStatesLabels[
              index as keyof typeof sliderCandidateStatesLabels
            ]), 1))
            ]),
            _: 1
          }, 8, ["modelValue", "track-color", "color"]),
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isDebounceActive ? "fa-regular fa-clock" : "none"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            icon: "",
            variant: "text",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.loadCandidates(true)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "large",
                class: "filter-icon"
              }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode("fas fa-cloud-arrow-down")
                ])),
                _: 1
              }),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "top left"
              }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("Kandidaten neu laden, auf neue Kandidaten im ATS prüfen und alle Stati vollständig mit ATS synchronisieren")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.candidateOrdered.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          onDrop: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["prevent"])),
          onDragover: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "dispatcher-board-empty-column-spacer" }, null, -1)),
          _createVNode(_component_v_card, _mergeProps(_ctx.vStyle.card, { border: "dashed" }), {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("Keine Kandidaten ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_subtitle, null, {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createTextVNode("unter diesem Filter")
                ])),
                _: 1
              }),
              (!_ctx.mdAndDown)
                ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" Mitarbeiter Karte auf dieses Feld ziehen um den ATS Datensatz anzuzeigen oder den Mitarbeiter in das ATS zu übertragen "),
                      _createElementVNode("br", null, null, -1),
                      _createElementVNode("h4", null, "Kandidaten erstellen:", -1),
                      _createTextVNode(" Lebenslauf - Datei (*.docs, *.pdf, *.jpg oder *.png) auf dieses Feld ziehen um einen Kandidaten anzulegen ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode(" CV Parser auf Mobilgeräten derzeit nicht verfügbar ")
                    ])),
                    _: 1
                  }))
            ]),
            _: 1
          }, 16)
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.candidateOrdered.length > 0)
      ? (_openBlock(), _createBlock(_component_v_infinite_scroll, {
          key: 4,
          onLoad: _ctx.loadMoreCandidates,
          disabled: _ctx.isLoadingCandidates,
          class: _normalizeClass(["infinite-scroll-hidden-scrollbar pa-0", _ctx.mdAndDown ? 'mt-3' : 'ma-0']),
          height: _ctx.columnHeight - _ctx.DispatcherBoardColumnHeight.iLoaderSubtract,
          distance: _ctx.DispatcherBoardColumnHeight.iLoaderDistance
        }, _createSlots({
          empty: _withCtx(() => [
            _createVNode(_component_v_alert, {
              variant: "tonal",
              closable: "",
              color: "secondary"
            }, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode(" Alle Kandidaten wurden geladen! ")
              ])),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.candidateOrdered, (element) => {
                return (_openBlock(), _createBlock(_component_CandidateItem, {
                  key: element.parentObjectid ?? element.candidateUuid,
                  ref_for: true,
                  ref: 
            (el) => {
              if (el) _ctx.candidateItemRefs.push(el);
            }
          ,
                  candidate: element,
                  interComponentMessage: _ctx.interComponentMessage,
                  isActive: _ctx.isActive,
                  isFullyCollapsed: _ctx.minimizeAllItems,
                  softwareIntegration: _ctx.softwareIntegration,
                  lastUpdateTimeline: _ctx.lastUpdateTimeline,
                  user: _ctx.user,
                  onLoadCandidates: _ctx.loadCandidates,
                  onLoadCandidatesFull: _cache[15] || (_cache[15] = ($event: any) => (
            _ctx.loadCandidatesFull(_ctx.AllItemUpdate.checkForFullLoad)
          )),
                  onLoadCandidatesFromAts: _cache[16] || (_cache[16] = ($event: any) => (
            _ctx.loadCandidatesFull(_ctx.AllItemUpdate.loadCandidateFromAts)
          )),
                  onSendCandidateToAts: _ctx.sendCandidateToAts,
                  onToggleItemsMinimized: _ctx.toggleItemsMinimized,
                  onUpdateCandidate: _ctx.updateCandidate,
                  onUpdatePostcode: _ctx.updatePostcodeFromItem
                }, null, 8, ["candidate", "interComponentMessage", "isActive", "isFullyCollapsed", "softwareIntegration", "lastUpdateTimeline", "user", "onLoadCandidates", "onSendCandidateToAts", "onToggleItemsMinimized", "onUpdateCandidate", "onUpdatePostcode"]))
              }), 128))
            ])
          ]),
          _: 2
        }, [
          (_ctx.candidatesAll.length > 0)
            ? {
                name: "loading",
                fn: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "spinner-icon" }, {
                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createTextVNode("fas fa-spinner")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" lade weitere " + _toDisplayString(_ctx.iLoader.incrementBy) + " Kandidaten ... ", 1)
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["onLoad", "disabled", "class", "height", "distance"]))
      : _createCommentVNode("", true)
  ], 32))
}