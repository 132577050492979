import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showDialog: _ctx.showDialog,
    width: 'var(--d-w-l)',
    showActions: true,
    onCloseDialog: _ctx.closeDialog
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.editMode ? "Firmenkunden bearbeiten" : "Neuen Firmenkunden anlegen"), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "form",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.valid) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.newCompany.tradeRegisterEntry,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCompany.tradeRegisterEntry) = $event)),
                        disabled: _ctx.editMode,
                        label: "Handelsregister",
                        required: ""
                      }), null, 16, ["modelValue", "disabled"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                        modelValue: _ctx.newCompany.name,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCompany.name) = $event)),
                        rule: _ctx.rule20,
                        label: "Firmenname",
                        required: ""
                      }), null, 16, ["modelValue", "rule"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "8"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.newCompany.address)
                        ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.newCompany.address.street,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCompany.address.street) = $event)),
                            rules: _ctx.rule20,
                            label: "Strasse",
                            required: ""
                          }), null, 16, ["modelValue", "rules"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.newCompany.address)
                        ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.newCompany.address.street_number,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCompany.address.street_number) = $event)),
                            rules: _ctx.rule15,
                            label: "Hausnummer",
                            required: ""
                          }), null, 16, ["modelValue", "rules"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.newCompany.address)
                        ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.newCompany.address.postcode,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newCompany.address.postcode) = $event)),
                            label: "PLZ",
                            required: ""
                          }), null, 16, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "5"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.newCompany.address)
                        ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.newCompany.address.city,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newCompany.address.city) = $event)),
                            rules: _ctx.rule20,
                            label: "Ort",
                            required: ""
                          }), null, 16, ["modelValue", "rules"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.newCompany.address)
                        ? (_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ key: 0 }, _ctx.vStyle.input, {
                            modelValue: _ctx.newCompany.address.country,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newCompany.address.country) = $event)),
                            label: "Land",
                            required: ""
                          }), null, 16, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (!_ctx.editMode)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "ml-0 mr-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Administrator")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                modelValue: _ctx.newUser.email,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newUser.email) = $event)),
                                label: "Email",
                                required: ""
                              }), null, 16, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                modelValue: _ctx.newUser.username,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.username) = $event)),
                                label: "Benutzername",
                                required: ""
                              }), null, 16, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, _mergeProps(_ctx.vStyle.input, {
                                modelValue: _ctx.newUser.password,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newUser.password) = $event)),
                                label: "Passwort",
                                required: ""
                              }), null, 16, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      (_ctx.saveError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.saveError), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_v_btn, {
        onClick: _ctx.closeDialog,
        color: "abort"
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Abbrechen")
        ])),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        onClick: _ctx.saveCompany,
        color: "success",
        disabled: !_ctx.valid
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode(" Speichern ")
        ])),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _: 1
  }, 8, ["showDialog", "onCloseDialog"]))
}