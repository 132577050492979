import { StatusResponse } from "@/enums/dialog.enum";
import { CandidateList } from "@/models/candidate-list.model";
import { AtsRecruitService } from "@/services/api/api-integration-one.service";
import ToastService from "@/services/toast.service";
import { ZorstService } from "@/services/zorst.service";
import store from "@/store/store";
import { TimelineTypes } from "../models/timeline.model";
import { Employee } from "../models/employee.model";
import { BackendMessage } from "../enums/backend-messages.enum";
import {
  AtsRecruitApplication,
  NewAtsRecruitCandidate,
} from "../models/external/zvoove-candidate.model";
import { Candidate } from "../models/candidate.model";
import { FormatDate } from "../helper/format-date.helper";
import { ZorstMessageType } from "../enums/zorst.enum";

export class AllAtsAdapter {
  static atsCandidateLink(
    candidateUuid: string,
    currentApplicationUuid?: string
  ): string {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit:
        return (
          `${softwareIntegration.zvooveRecruitLink}/recruiting/bewerber/${candidateUuid}/dashboard` +
          (currentApplicationUuid ? `?bewerbung=${currentApplicationUuid}` : "")
        );

      default:
        return "#";
    }
  }
  static async changeAtsStatus(
    candidateUuid: string,
    candidateApplicationUuid: string,
    targetStatus: string
  ): Promise<string | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    const candidateLink = this.atsCandidateLink(
      candidateUuid,
      candidateApplicationUuid
    );

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          if (!softwareIntegration.zorst || !store.state.zorstAlive) {
            return StatusResponse.manual;
          }

          const result =
            await ZorstService.getInstance().ZorstChangeRecruitCandidateStatus(
              candidateLink,
              targetStatus
            );

          if (result.success) {
            return StatusResponse.atsSuccess;
          } else if (result.error) {
            ToastService.show(result.error);
            return StatusResponse.manual;
          } else {
            return null;
          }
        } catch (error) {
          console.error("Fehler bei der ATS Recruit Statusänderung:", error);
          ToastService.showError((error as any)?.error);
          return StatusResponse.manual;
        }
        break;
      }

      default:
        return StatusResponse.manual;
    }
  }
  static async addTimelineEntry(
    candidateUuid: string,
    subject: string,
    text: string
  ): Promise<string | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    const candidateLink = this.atsCandidateLink(candidateUuid);

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          if (!softwareIntegration.zorst || !store.state.zorstAlive) {
            return "Zorst-option no activated or not alive";
          }
          let docuType = "note";

          const timelineTypes = store.state.company
            .timelineEntryTypes as TimelineTypes;

          const contactDescriptions = [
            timelineTypes.phoneCallCandidate.description,
            timelineTypes.phoneCallCustomer.description,
            timelineTypes.emailCandidate.description,
            timelineTypes.eMailCustomer.description,
            timelineTypes.whatsAppCandidate.description,
            timelineTypes.whatsAppEmployee.description,
          ];

          if (contactDescriptions.includes(subject)) {
            docuType = "contact";
          }
          const payload = {
            type: ZorstMessageType.adAtsRecruitTimelineEntry,
            zvooveRecruitLink: candidateLink,
            docuType: docuType,
            subject: subject,
            text: text,
          };
          const result =
            await ZorstService.getInstance().ZorstAddZvooveRecruitDocu(payload);
          if (result.success) {
            return result.success;
          } else if (result.error) {
            ToastService.show(result.error);
            return null;
          } else {
            return null;
          }
        } catch (error) {
          console.error("Fehler bei der ATS Recruit Dokumentation:", error);
          ToastService.showError(
            "Fehler bei der ATS Recruit Dokumentation: " + error
          );
          return StatusResponse.manual;
        }
        break;
      }

      default:
        return StatusResponse.manual;
    }
  }
  static async getAtsStatusForCandidate(
    candidate: any,
    applicationUuid: string
  ): Promise<{
    error?: string;
    externalAtsStatus?: {
      status: string;
      statusUuid: string;
      internalStatusSystemName: string;
    };
  }> {
    const softwareIntegration = store.getters.softwareIntegration;
    let externalAtsStatus = {
      statusUuid: "",
      status: "",
      internalStatusSystemName: "",
    };

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          const atsRecruitService = new AtsRecruitService();
          const atsCandidate = await atsRecruitService.getById(
            candidate?.candidateUuid,
            candidate?.applicationId,
            candidate?.applicationUuid
          );

          const matchedApplication = atsCandidate.applications.find(
            (application) => application.uuid === applicationUuid
          );

          if (matchedApplication) {
            externalAtsStatus = matchedApplication.ats;
            return { externalAtsStatus: externalAtsStatus };
          }
        } catch (error) {
          console.error("Error fetching ATS status from zvooveRecruit:", error);
        }
        break;
      }
      default:
        return { error: "no Ats selected" };
    }

    return { error: "null" };
  }
  static async getCandidateAvatar(
    avatarUuid: string
  ): Promise<Blob | undefined> {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          const atsRecruitService = new AtsRecruitService();
          const response = await atsRecruitService.getAvatar(avatarUuid);
          return response;
        } catch (error: any) {
          console.error(
            `Fehler beim Abrufen des Kandidaten-Avatars #${avatarUuid} von zvooveRecruit`,
            error
          );
          return undefined;
        }
      }
      default:
        ToastService.show("Kein unterstütztes ATS-System ausgewählt!");
        return undefined;
    }
  }
  static async getCandidateData(candidate: any): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let atsCandidate = null;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          const atsRecruitService = new AtsRecruitService();
          atsCandidate = await atsRecruitService.getById(
            candidate?.candidateUuid,
            candidate?.applicationId,
            candidate?.applicationUuid
          );
          return atsCandidate;
        } catch (error) {
          console.error(
            `Fehler beim Abrufen des Kandidaten #${candidate?.applicationId} von zvooveRecruit`,
            error
          );
          throw error;
        }
      }
      default:
        ToastService.show("Kein unterstütztes ATS-System ausgewählt!");
        return null;
    }
  }
  static async getCandidateList(
    searchterm: string,
    mandants?: string[]
  ): Promise<{ error?: string; atsCandidateList?: CandidateList[] }> {
    const softwareIntegration = store.getters.softwareIntegration;
    let candidateList: CandidateList[] = [];

    try {
      switch (true) {
        case !!softwareIntegration.zvooveRecruit: {
          const atsRecruitService = new AtsRecruitService();
          candidateList = await atsRecruitService.getBewerbungenFiltered(
            searchterm,
            mandants
          );
          return { atsCandidateList: candidateList };
        }

        default:
          return { error: "no Ats selected" };
      }
    } catch (error) {
      console.error("Error fetching candidates from ATS:", error);
      return { error: "Error fetching candidates" };
    }
  }
  static isAtsSystem(): boolean {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit:
        return true;

      default:
        return false;
    }
  }
  static async getCandidateDataFromEmployee(
    employee: Employee
  ): Promise<any | null> {
    if (!employee) return undefined;
    const checkEntry = {
      searchTerm: employee.lastName,
      firstName: employee.firstName,
      lastName: employee.lastName,
      birthDate: employee.birthDate,
      addressPostalCode: employee.address.postalCode,
    };

    const result = await this.getCandidateDataAndCheckForMatch(checkEntry);
    return result;
  }
  static async getCandidateDataFromCandidate(
    candidate: Candidate
  ): Promise<any | null> {
    if (!candidate) return undefined;
    const checkEntry = {
      searchTerm: candidate.lastName,
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      birthDate: candidate.birthDate,
      addressPostalCode: candidate.addressPostalCode,
    };

    const result = await this.getCandidateDataAndCheckForMatch(checkEntry);
    return result;
  }
  static async getCandidateDataAndCheckForMatch(checkEntry: {
    searchTerm: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    addressPostalCode: string;
  }): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    try {
      switch (true) {
        case !!softwareIntegration.zvooveRecruit: {
          const atsRecruitService = new AtsRecruitService();
          const atsCandidateList =
            await atsRecruitService.getBewerbungenFilteredBySearchterm(
              checkEntry.searchTerm
            );

          const matchingAtsUuids: {
            atsUuid: string;
            atsId: string;
            atsApplicationUuid: string;
          }[] = atsCandidateList
            .filter(
              (atsCandidate) =>
                atsCandidate.Vorname === checkEntry.firstName &&
                atsCandidate.Nachname === checkEntry.lastName
            )
            .map((atsCandidate) => {
              return {
                atsUuid: atsCandidate.MitarbeiterUuid,
                atsId: atsCandidate.BewerbungID,
                atsApplicationUuid: atsCandidate.BewerbungUuid,
              };
            });

          const matchingCandidates = [];

          for (const matchedUuid of matchingAtsUuids) {
            try {
              const candidateDetails = await atsRecruitService.getById(
                matchedUuid.atsUuid,
                matchedUuid.atsId,
                matchedUuid.atsApplicationUuid
              );

              const candidateBirthDate = FormatDate.standard(
                candidateDetails.birthDate
              );
              const employeeBirthDate = FormatDate.standard(
                checkEntry.birthDate
              );

              if (
                candidateBirthDate === employeeBirthDate &&
                candidateDetails.addressPostalCode ===
                  checkEntry.addressPostalCode
              ) {
                matchingCandidates.push(candidateDetails);
              }
            } catch (error) {
              console.error(
                `Fehler beim Abrufen der Kandidatendetails für UUID ${matchedUuid.atsUuid}:`,
                error
              );
              continue;
            }
          }

          return matchingCandidates.length > 0
            ? { response: matchingCandidates }
            : { error: BackendMessage.employee.candidateNotFound };
        }

        default:
          return { error: "no Ats selected" };
      }
    } catch (error) {
      console.error("Error fetching candidates from ATS:", error);
      return { error: "Error fetching candidates" };
    }
  }
  static async getAtsNewCandidateDefaults(): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        const atsRecruitService = new AtsRecruitService();
        const response = await atsRecruitService.getNeuanlageDefaults();
        return response;
      }
      default:
        return { error: "no Ats selected" };
    }
  }
  static async addCandidateToAts(
    newCandidate: NewAtsRecruitCandidate | any
  ): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        const atsRecruitService = new AtsRecruitService();
        const response = await atsRecruitService.createBewerber(newCandidate);
        return response;
      }
      default:
        return { error: "no Ats selected" };
    }
  }
  static async postApplicationToAts(
    atsApplicationObject: AtsRecruitApplication,
    attachments?: File[]
  ): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        const atsRecruitService = new AtsRecruitService();
        const response = await atsRecruitService.postApplicationCreate(
          atsApplicationObject,
          attachments
        );
        return response.response;
      }
      default:
        return { error: "no Ats selected" };
    }
  }
}
