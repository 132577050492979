import axios from "axios";
import { Api } from "./api.class";
import { AppConfig } from "../../models/app-config.model";

export class AppConfigService {
  private static instance: AppConfigService;
  private api;
  private urlPath;

  public static getInstance(backendUri?: string): AppConfigService {
    if (!AppConfigService.instance) {
      AppConfigService.instance = new AppConfigService(backendUri);
    }
    return AppConfigService.instance;
  }

  constructor(backendUri?: string) {
    this.api = Api.getInstance();
    if (backendUri) this.urlPath = backendUri;
    else this.urlPath = this.api.getBackendUri();
  }

  async getFrontend() {
    const urlPath = `${this.urlPath}/app-config`;
    return (await axios.get(urlPath)).data.response;
  }

  async getBackend() {
    return (await this.api.backendApi.get(`/app-config/backend`)).data.response;
  }

  async save(config: AppConfig) {
    return (await this.api.backendApi.post(`/app-config/backend`, { config }))
      .data.response;
  }
}
