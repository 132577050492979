<template>
  <BaseDialog
    :showDialog="show"
    :showActions="true"
    @closeDialog="handleAbort"
    :width="'var(--d-w-sm)'"
  >
    <template #title>
      {{ title }}
    </template>
    <template #content>
      <v-card-text class="pa-4 mb-5 text-dialog">
        <span v-html="question"></span>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn :color="btnAbortColor" @click="handleAbort">{{
        btnAbortText
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn :color="btnConfirmColor" @click="handleConfirm">{{
        btnConfirmText
      }}</v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseDialog from "./BaseDialog.vue";
import { SpinnerService } from "../../services/spinner.service";

export enum ConfirmDialogEmit {
  confirm = "confirm",
}

export default defineComponent({
  name: "ConfirmDialog",
  components: {
    BaseDialog,
  },
  emits: [ConfirmDialogEmit.confirm],
  props: {
    question: { type: String, required: true },
    btnAbortText: { type: String, default: "Abbrechen" },
    btnConfirmText: { type: String, default: "Bestätigen" },
    title: { type: String, default: "Bitte bestätigen" },
    btnAbortColor: { type: String, default: "error" },
    btnConfirmColor: { type: String, default: "success" },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    SpinnerService.removeSpinner();
  },
  methods: {
    handleConfirm() {
      this.show = false;
      this.$emit(ConfirmDialogEmit.confirm, true);
    },
    handleAbort() {
      this.show = false;
      this.$emit(ConfirmDialogEmit.confirm, false);
    },
  },
});
</script>
