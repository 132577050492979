<!--src/components/config/software-integration/ConfigReminderSettings.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="reminder-icon">fa-solid fa-bell</v-icon>
      Erinnerungen und Deadlines
    </h2>

    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(reminder, index) in localConfig.reminderSettings"
          :key="index"
        >
          <v-card v-bind="vStyle.card" class="mb-4 pa-0">
            <v-card-title>{{
              getEventNameByCandidateStatus(reminder.reminderFor)
            }}</v-card-title>
            <v-card-text class="mt-4">
              <v-slider
                v-model="reminder.hoursAfter"
                :label="'Stunden danach'"
                :min="0"
                :max="24"
                step="1"
                thumb-label="always"
              ></v-slider>
              <v-slider
                v-model="reminder.daysAfter"
                :label="'Tage danach'"
                :min="0"
                :max="30"
                step="1"
                thumb-label="always"
              ></v-slider>
              <v-switch
                v-model="reminder.showPrompt"
                :label="'Meldung einfordern?'"
              ></v-switch>
              <v-switch
                v-model="reminder.needToContact"
                :label="'Kandidat blinkt?'"
              ></v-switch>
              <v-text-field
                v-bind="vStyle.input"
                v-model="reminder.message"
                :label="'Message'"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig, ReminderSetting } from "@/models/company-config.model";
import { mapGetters } from "vuex";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ConfigReminderSettings",
  components: {
    ConfigSaveButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      localConfig: cloneDeep(this.modelValue),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEventNameByCandidateStatus"]),
  },
  watch: {
    modelValue: {
      handler(newValue: CompanyConfig) {
        this.localConfig = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localConfig: {
      handler(newValue: CompanyConfig) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.syncReminderSettings();
  },
  methods: {
    //if there are new reminders in store raw data => add them to configt
    syncReminderSettings() {
      const storeReminderSettings = this.$store.state.company.reminderSettings;
      const localReminderSettings = this.localConfig.reminderSettings;
      const missingReminders = storeReminderSettings.filter(
        (storeReminder: ReminderSetting) =>
          !localReminderSettings.some(
            (localReminder) =>
              localReminder.reminderFor === storeReminder.reminderFor
          )
      );
      if (missingReminders.length > 0) {
        this.localConfig.reminderSettings.push(...missingReminders);
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
      this.syncReminderSettings();
    },
  },
});
</script>

<style scoped>
.reminder-icon {
  animation: blinkAnimation 1s infinite, colorBlinkAnimation 1s infinite,
    shakeAnimation 0.3s infinite;
  cursor: pointer;
}

@keyframes blinkAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colorBlinkAnimation {
  0% {
    color: #cb0000;
  }
  25% {
    color: #8d0000;
  }
  50% {
    color: #cb0000;
  }
  75% {
    color: #ff2929;
  }
  100% {
    color: #cb0000;
  }
}

@keyframes shakeAnimation {
  0% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
