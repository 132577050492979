<template>
  <v-container>
    <v-btn icon variant="text" @click="toggleFilters">
      <v-icon size="xlarge">
        {{
          showFilters
            ? "fa-solid fa-arrow-up filter-icon filter-close"
            : "fa-solid fa-sliders filter-icon"
        }}
      </v-icon>
    </v-btn>
    <v-scroll-y-transition
      class="filter-menu"
      :class="
        layout === AppLayout.classic || layout === AppLayout.persontobusiness
          ? 'filter-menu-top-classic'
          : 'filter-menu-top'
      "
    >
      <v-card v-if="showFilters" class="rounded-b-lg">
        <v-card-title class="d-flex">
          Filtern nach ...
          <v-spacer></v-spacer>
          <v-btn icon variant="text" @click="toggleFilters">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <!-- Slots for columns-->
            <v-col cols="12" md="6" class="pl-5 pt-2 pb-2">
              <slot name="filter-menu-left-column"></slot>
            </v-col>
            <v-col cols="12" md="6" class="pr-5 pt-2 pb-2">
              <slot name="filter-menu-right-column"></slot>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-scroll-y-transition>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AppLayout } from "../../../enums/app-layout.enum";

export default defineComponent({
  name: "FilterMenu",
  data() {
    return {
      layout: this.$store.getters.appLayout,
      AppLayout: AppLayout,
      showFilters: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
  },
});
</script>
<style scoped>
.filter-menu {
  position: absolute;
  width: 40rem;
  z-index: 100;
  border-right: 0.1rem solid var(--color-primary-darken-1);
  border-left: 0.1rem solid var(--color-primary-darken-1);
  border-bottom: 0.1rem solid var(--color-primary-darken-1);
  margin-left: 3rem;
  margin-right: 5px;
  padding: 5px;
}
.filter-menu-top-classic {
  top: 12.3rem;
}
.filter-menu-top {
  top: 5.6rem;
}
@media (max-width: 600px) {
  .filter-menu {
    width: 85vw;
  }
}
</style>
