<!--src/components/config/software-integration/ConfigEmail.vue-->
<template>
  <v-container v-if="hasConfigEmailAccess">
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-envelope</v-icon
      >Email-Konfiguration
    </h2>
    <EmailConfiguration
      :office365AccessToken="isExistingAccessToken"
      :mailServerConfig="localConfig.mailServer"
      :office365Config="isExistingAccessToken"
      :emailScope="EmailScope.company"
      @emailValidationResult="handleEmailTest"
      @office365Logout="office365Logout"
      @removeMailserverConfig="removeMailserverConfig"
    >
    </EmailConfiguration>
  </v-container>
  <ConfigSaveButton v-if="isTestSuccessful" @save="handleSaveConfig" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import { UserHelperService } from "@/services/user-helper.service";
import { EmailScope } from "../../../../enums/email-scope.enum";
import { UserService } from "../../../../services/api/user.service";
import { UserRoleHelper } from "../../../../helper/user-role.helper";
import { AccessRule } from "../../../../enums/access-rule.enum";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import EmailConfiguration from "../../../email/email-configuration/EmailConfiguration.vue";
import { MailServer } from "../../../../models/mailserver.model";

export enum ConfigEmailEmits {
  delete = "delete",
  updateModelValue = "update:modelValue",
  save = "save",
}
export default defineComponent({
  name: "ConfigEmail",
  emits: [
    ConfigEmailEmits.updateModelValue,
    ConfigEmailEmits.delete,
    ConfigEmailEmits.save,
  ],
  components: {
    ConfigSaveButton,
    EmailConfiguration,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  computed: {
    isExistingAccessToken() {
      return this.localConfig?.accessTokens?.office365?.accessToken;
    },
    localConfig(): Record<string, any> {
      return { ...this.modelValue };
    },
    hasConfigEmailAccess() {
      return UserRoleHelper.hasAccess(
        this.$store.getters.user,
        AccessRule.configEmail
      );
    },
  },
  data() {
    return {
      EmailScope,
      isEmailConfigVerified: false,
      isTestSuccessful: false,
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    addMailserverConfig() {
      this.localConfig.mailServer = {
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      };
      this.$emit(ConfigEmailEmits.updateModelValue, this.localConfig);
    },
    getUserRole() {
      const userHelperService = UserHelperService.getInstance();
      const userRole = userHelperService.getUserRole();
      return userRole;
    },
    handleEmailTest(mailServerConfig: MailServer) {
      this.localConfig.mailServer = mailServerConfig;
      this.isTestSuccessful = true;
    },
    handleSaveConfig() {
      this.$emit(ConfigEmailEmits.updateModelValue, this.localConfig);
      this.$emit(ConfigEmailEmits.save);
      this.isTestSuccessful = false;
    },
    office365Logout() {
      if (this.localConfig.accessTokens?.office365) {
        this.localConfig.accessTokens.office365 = undefined;
        this.handleSaveConfig();
        //this.$store.commit(MutationType.setCompanyConfig, this.localConfig);
        //this.userService.updateModelStore();
      }
    },
    removeMailserverConfig() {
      this.localConfig.mailServer = null;
      this.$emit(ConfigEmailEmits.updateModelValue, this.localConfig);
      this.$emit(ConfigEmailEmits.save);
    },
  },
});
</script>
