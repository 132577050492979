<template>
  <BaseDialog
    :showDialog="showModal"
    :showActions="true"
    @closeDialog="closeModal"
    :width="'var(--d-w-l)'"
    class="fill-height"
  >
    <template #title>
      <h2><i class="fa-solid fa-users-viewfinder"></i> JOB Leads</h2>
      manuelle Suche in Anzeigendaten
    </template>
    <template #content>
      <v-card-text class="mb-10">
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-title class="d-flex justify-space-between"
              >Nach Schlagwörtern im Anzeigentext suchen
              <div>
                <v-btn icon variant="text" @click="restoreSkills">
                  <v-icon>fa-solid fa-rotate-right</v-icon>
                  <v-tooltip activator="parent" location="left">
                    Alle wiederherstellen
                  </v-tooltip>
                </v-btn>
                <v-btn icon variant="text" @click="clearSkills">
                  <v-icon>fa-solid fa-circle-xmark</v-icon>
                  <v-tooltip activator="parent" location="left">
                    Alle entfernen
                  </v-tooltip></v-btn
                >
              </div>
            </v-card-title>
            <v-chip-group class="px-4" column>
              <v-chip
                v-for="skill in localDataForQuery.skills"
                class="mb-2"
                :key="skill"
                color="primary"
                closable
                @click:close="removeSkill(skill)"
              >
                {{ skill }}
              </v-chip>
              <v-text-field
                v-bind="vStyle.input"
                rounded="pill"
                class="skill-tag-input"
                label="Neues Schlagwort"
                v-model="newSkill"
                @keyup.enter="addSkill"
              ></v-text-field>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-title class="d-flex justify-space-between"
              >Nach Berufen/Tags suchen
              <div>
                <v-btn icon variant="text" @click="restoreTags">
                  <v-icon>fa-solid fa-rotate-right</v-icon>
                  <v-tooltip activator="parent" location="left">
                    Alle wiederherstellen
                  </v-tooltip>
                </v-btn>
                <v-btn icon variant="text" @click="clearTags">
                  <v-icon>fa-solid fa-circle-xmark</v-icon>
                  <v-tooltip activator="parent" location="left">
                    Alle entfernen
                  </v-tooltip></v-btn
                >
              </div>
            </v-card-title>
            <v-chip-group class="px-4" multiple column>
              <v-chip
                v-for="tag in localDataForQuery.tags"
                :key="tag"
                color="primary"
                closable
                @click:close="removeTag(tag)"
              >
                {{ tag }}
              </v-chip>
              <v-text-field
                v-bind="vStyle.input"
                rounded="pill"
                class="skill-tag-input"
                label="Neuer Beruf"
                v-model="newTag"
                @keyup.enter="addTag"
              ></v-text-field>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-title>Nach Karriere Level suchen</v-card-title>
            <v-container class="d-flex">
              <v-icon
                class="mx-3"
                icon="fa-solid fa-person-arrow-down-to-line"
              ></v-icon>
              <v-range-slider
                class="mx-2"
                :max="12"
                :min="0"
                :step="1"
                v-model="careerLevel"
              ></v-range-slider>
              <v-icon class="mx-1" icon="fa-solid fa-user-tie"></v-icon>
            </v-container>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Umkreissuche</v-card-title>
            <v-slider
              class="mx-4"
              :max="100"
              :min="0"
              :step="1"
              v-model="localDataForQuery.radius"
              thumb-label
            ></v-slider>
          </v-col>
          <v-col cols="12" md="3">
            <v-card-title>{{ localDataForQuery.radius }} km um</v-card-title>
            <v-text-field
              v-bind="vStyle.input"
              label="Postleitzahl"
              density="compact"
              v-model="localDataForQuery.postcode"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Suche im Zeitraum</v-card-title>
            <v-slider
              class="mx-4"
              :max="360"
              :min="0"
              :step="1"
              v-model="localDataForQuery.period"
              thumb-label
            ></v-slider>
          </v-col>
          <v-col cols="12" md="3">
            <v-card-title>Zeitraum</v-card-title>
            <v-text-field
              v-bind="vStyle.input"
              label="Tage"
              density="compact"
              v-model="localDataForQuery.period"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-switch
        class="mt-6"
        color="primary"
        v-model="replaceJobAds"
        :label="
          replaceJobAds
            ? 'Ergebnisse überschreiben bestehende Job Leads'
            : 'Ergebnisse unten zu Job Leads hinzufügen'
        "
      />
      <v-spacer></v-spacer>
      <v-btn color="success" @click="submitQuery(replaceJobAds)"
        >Ausführen</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { DataForJobAdLeadsQuery } from "@/models/external/index-anzeigendaten.model";
import { careerLevel, IaCareerLevel } from "@/enums/anzeigendaten.enum";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default defineComponent({
  name: "DialogManualAnzeigendatenSearch",
  components: {
    BaseDialog,
  },
  props: {
    dataForQuery: {
      type: Object as PropType<DataForJobAdLeadsQuery>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown,
      careerLevel: [0, 12],
      localDataForQuery: {} as DataForJobAdLeadsQuery,
      newSkill: "",
      newTag: "",
      replaceJobAds: true,
      showModal: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    careerLevel: {
      handler() {
        this.localDataForQuery.careerLevel = this.careerLevelIndexNumbers;
      },
      deep: true,
    },
  },
  computed: {
    careerLevelIndexNumbers() {
      const labels: IaCareerLevel[] = [];
      for (let i = this.careerLevel[0]; i <= this.careerLevel[1]; i++) {
        const careerLevelValue = careerLevel[i as keyof typeof careerLevel];
        if (careerLevelValue !== undefined) {
          labels.push(careerLevelValue);
        }
      }
      return labels;
    },
  },
  mounted() {
    this.initializeLocalData();
  },
  methods: {
    addSkill() {
      if (
        this.newSkill &&
        !this.localDataForQuery.skills.includes(this.newSkill)
      ) {
        const updatedSkills = [...this.localDataForQuery.skills];
        updatedSkills.push(this.newSkill);
        this.localDataForQuery.skills = updatedSkills;
        this.newSkill = "";
      }
    },
    addTag() {
      if (this.newTag && !this.localDataForQuery.tags.includes(this.newTag)) {
        const updatedTags = [...this.localDataForQuery.tags];
        updatedTags.push(this.newTag);
        this.localDataForQuery.tags = updatedTags;
        this.newTag = "";
      }
    },
    clearSkills() {
      this.localDataForQuery.skills = [];
    },
    clearTags() {
      this.localDataForQuery.tags = [];
    },
    closeModal() {
      this.showModal = false;
    },
    initializeLocalData() {
      this.localDataForQuery = {
        ...this.dataForQuery,
        skills: [...(this.dataForQuery.skills || [])],
        tags: [...(this.dataForQuery.tags || [])],
      };
      this.setSliderFromCareerLevel(this.dataForQuery?.careerLevel || []);
    },
    openModal() {
      this.showModal = true;
    },
    removeSkill(skillToRemove: string) {
      const index = this.localDataForQuery?.skills?.indexOf(skillToRemove);
      if (index > -1) {
        const updatedSkills = [...this.localDataForQuery.skills];
        updatedSkills.splice(index, 1);
        this.localDataForQuery = {
          ...this.localDataForQuery,
          skills: updatedSkills,
        };
      }
    },
    removeTag(tagToRemove: string) {
      const index = this.localDataForQuery?.tags?.indexOf(tagToRemove);
      if (index > -1) {
        const updatedTags = [...this.localDataForQuery.tags];
        updatedTags.splice(index, 1);
        this.localDataForQuery = {
          ...this.localDataForQuery,
          tags: updatedTags,
        };
      }
    },
    restoreSkills() {
      this.localDataForQuery.skills = [...(this.dataForQuery?.skills || [])];
    },
    restoreTags() {
      this.localDataForQuery.tags = [...(this.dataForQuery?.tags || [])];
    },
    submitQuery(replace: boolean) {
      if (replace) {
        this.$emit("searchAndReplace", this.localDataForQuery);
      } else {
        this.$emit("searchAndAdd", this.localDataForQuery);
      }
      this.closeModal();
    },
    setSliderFromCareerLevel(indexLevels: number[]) {
      if (!indexLevels.length) {
        this.careerLevel = [0, 12];
        return;
      }
      const careerLevelToIndex: Record<number, number> = {};
      Object.entries(careerLevel).forEach(([index, enumValue]) => {
        careerLevelToIndex[enumValue] = parseInt(index);
      });

      const sliderValues = indexLevels.map((level) => {
        if (careerLevelToIndex[level] !== undefined) {
          return careerLevelToIndex[level];
        }
        throw new Error(
          `Karrierelevel ${level} ist im Mapping nicht definiert.`
        );
      });

      this.careerLevel = [Math.min(...sliderValues), Math.max(...sliderValues)];
    },
  },
});
</script>

<style scoped>
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.skill-tag-input {
  margin-top: 0.25rem;
  min-width: 12rem;
  max-width: 12rem;
}

.skill-tag-input :deep(.v-field__input) {
  min-height: 2rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.skill-tag-input :deep(.v-field__field) {
  min-height: 2rem !important;
}
</style>
