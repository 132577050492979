<!--src/components/disposition/EmployeeItem.vue-->
<template>
  <GeneralItem
    ref="generalItemComponent"
    :isFullyCollapsed="isFullyCollapsed"
    :isDialog="false"
    :isActive="isActive"
    :color="'card'"
    :hoverColor="'cardHover'"
    :parentRole="Role.employee"
    :dragOverIcon="dragOver.icon"
    :dragOverText="dragOver.text"
    :statusColor="getEmployeeStatusCreds().color"
    :statusBlinking="getEmployeeStatusCreds().blinking"
    @isExpanded="updateComponent()"
    @dragStart="handleDragStart"
    @dragOver="handleDragOver"
    @openContextMenu="$emit(EmployeeItemEmit.openContextMenu, $event)"
  >
    <template #collapsed-view>
      <v-card-title class="d-flex justify-space-between pa-0">
        <span class="pa-0 text-body-2">
          <span
            class="ml-1 font-weight-light text-caption text-medium-emphasis"
          >
            {{ employee.employeeNumber }}</span
          >
          {{ employee.firstName }} {{ employee.lastName }}
        </span>
        <span class="mx-1 font-weight-light text-caption text-medium-emphasis">
          {{ employee.address.city }}</span
        ></v-card-title
      >
      <v-divider class="my-1"></v-divider>
    </template>
    <template #item-collapsed>
      <v-card-title class="pa-0 text-body-2 d-flex">
        {{ employee?.firstName }} {{ employee?.lastName }}
        <v-spacer></v-spacer>
        <div
          v-if="getAssignmentExpiringText()"
          class="ml-1 text-medium-emphasis font-weight-bold"
        >
          {{ getAssignmentExpiringText() }}
        </div>
      </v-card-title>
      <v-card-subtitle class="pl-0">
        {{ employee?.address?.postalCode }}
        {{ employee?.address?.city }} ({{ employee?.employeeNumber }})
      </v-card-subtitle>
    </template>
    <template #hide-on-inactive-column>
      <div class="employee-status">
        {{ getEmployeeState(employee?.employeeStatus) }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              @click="saveStatusSelection(index.toString())"
              v-for="(item, index) in employeeStates"
              :key="index"
              :value="index"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="text-caption text-medium-emphasis">
        {{ $store.getters.getMandantNameByUuid(employee.mandants[0]) }}
      </div>
    </template>
    <template #communication-icons-container>
      <div>
        <img
          v-if="!employee.candidateUuid"
          :src="getAssetIconPath('icon-ats-unknown.svg')"
          alt="pd hub upload"
          class="communication-container-img-icon"
          @click="searchForCandidateDataInAts()"
        />
        <v-tooltip activator="parent" location="bottom">
          Kein passender Kandidatendatensatz gespeichert! Klicken um im ATS nach
          Daten zu suchen.
        </v-tooltip>
      </div>
      <div>
        <img
          v-if="employee.candidateUuid && !employee.candidateObjectId"
          :src="getAssetIconPath('icon-ats-disconnected.svg')"
          alt="pd hub upload"
          class="communication-container-img-icon"
          @click="checkLinkToCandidate()"
        />
        <v-tooltip activator="parent" location="bottom">
          Kandidatennummer in den Daten vorhanden! Klicken um Kandidat und
          Mitarbeiter zu verlinken bzw. aus dem ATS abzurufen.
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <ErpOrAtsLinkMenu
        v-if="employee.candidateUuid"
        :atsRecruitUuid="employee.candidateUuid"
      ></ErpOrAtsLinkMenu>
      <ErpOrAtsLinkMenu
        :erpEmployeeNumber="employee.employeeNumber"
        @getErpData="updateEmployeeFromErp()"
        @putErpData="updateErpFromEmployee()"
      ></ErpOrAtsLinkMenu>
      <PhoneClient
        :employee="employee"
        :phoneNumbers="filteredPhoneNumbers"
        @collapseParentItem="collapseItem"
      ></PhoneClient>
      <WhatsAppClient
        :AiMessageType="AiMessageType.whatsAppEmployee"
        :employee="employee"
        :message="message.body"
        :phoneNumbers="filteredPhoneNumbers"
        :receiverName="`${employee?.firstName} ${employee.lastName}`"
        :salutation="employee.letterSalutation"
        :signature="signatureWhatsApp()"
        @collapseParentItem="collapseItem"
      ></WhatsAppClient>
      <MailClient
        :employee="employee"
        :emailAddresses="filteredEmail"
        :AiMessageType="AiMessageType.mailEmployee"
        :emailObject="message"
        :signature="signatureMail()"
        @collapseParentItem="collapseItem"
        @editContacts="editContact"
      ></MailClient>
    </template>
    <template #checklist-container>
      <Checklist
        :checklist="employee.checklist || []"
        :employee="employee"
        @updateChecklist="updateChecklist"
      />
    </template>
    <template #info-container>
      <Assignments
        :isEmployee="true"
        :assignments="employee?.assignments"
        @updateAssignments="getPlacements()"
      ></Assignments>
    </template>
    <template #payflow-container>
      <PayFlowEmployee
        v-if="softwareIntegration.payFlow"
        :employeeNumber="employee.employeeNumber"
        :firstName="employee.firstName"
        :lastName="employee.lastName"
        :mandants="employee.mandants"
      />
    </template>
    <template #timeline-container>
      <Timeline :employee="employee" :mandant="employee.mandants[0]"></Timeline>
    </template>
    <template #append-container>
      <DialogSelectAtsCandidates
        :showDialog="showResultsDialog"
        :resultsCandidateSearch="resultsCandidateSearch"
        @closeDialog="showResultsDialog = false"
        @selectCandidateSearchResult="selectCandidateSearchResult"
        @abort="showAddCandidateToAtsDialog = true"
      ></DialogSelectAtsCandidates>
      <DialogAddCandidateToAts
        v-if="showAddCandidateToAtsDialog"
        :showDialog="showAddCandidateToAtsDialog"
        :employee="employee"
        @candidateCreatedOrUpdated="linkNewCandidateToEmployee"
        @closeDialog="showAddCandidateToAtsDialog = false"
        @receivedCandidateUuidFromLink="receiveCandidateUuidFromLink"
      ></DialogAddCandidateToAts>
    </template>
  </GeneralItem>
</template>

<script lang="ts">
import { AiMessageType } from "../../enums/ai-options.enum";
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import { CheckList } from "@/models/checklist.model";
import { defineComponent, PropType } from "vue";
import { Employee } from "@/models/employee.model";
import { EmployeeService } from "@/services/api/employee.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters } from "vuex";
import { MessageContent } from "@/enums/empty-message.enum";
import { ModalMode } from "@/enums/dialog.enum";
import { PdHubService } from "@/services/api/pd-hub.service";
import { Salutation } from "@/enums/salutation.model";
import { User } from "@/models/user.model";
import Assignments from "./elements/Assignments.vue";
import Checklist from "./elements/Checklist.vue";
import DialogService from "@/services/dialog.service";
import MailClient from "./elements/MailClient.vue";
import PayFlowEmployee from "../payflow/PayFlowEmployee.vue";
import PhoneClient from "./elements/PhoneClient.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import WhatsAppClient from "./elements/wab/WhatsAppClient.vue";
import ErpOrAtsLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import { Role } from "../../enums/dependency.enum";
import { AllItemUpdate } from "../../enums/board-actions.enum";
import {
  EmployeeStates,
  getEmployeeStateLabel,
} from "../../enums/employee-states.enum";
import GeneralItem from "./elements/GeneralItem.vue";
import { AllAtsAdapter } from "../../adapter/all-ats.adapter";
import { Candidate } from "../../models/candidate.model";
import { BackendMessage } from "../../enums/backend-messages.enum";
import { CandidateService } from "../../services/api/candidate.service";
import { SpinnerService } from "../../services/spinner.service";
import { EmployeeCandidateLinkService } from "../../services/employee-candidate-link.service";
import { getAssetIconPath } from "../../helper/get-assets.helper";
import { ItemDragOverInfo } from "../../models/app-messages.model";
import { MutationType } from "../../enums/vuex-types.enum";
import moment from "moment";
import { useDisplay } from "vuetify";
import DialogAddCandidateToAts from "./elements/DialogAddCandidateToAts.vue";
import { CandidateDataHelper } from "../../helper/candidate-data.helper";
import DialogSelectAtsCandidates from "./elements/DialogSelectAtsCandidates.vue";
import { VuetifyColor } from "../../plugins/vuetify";

enum EmployeeItemEmit {
  editContact = "editContact",
  insertUpdatedEmployee = "insertUpdatedEmployee",
  openContextMenu = "openContextMenu",
}

export default defineComponent({
  name: "EmployeeItem",
  emits: [
    EmployeeItemEmit.editContact,
    EmployeeItemEmit.insertUpdatedEmployee,
    EmployeeItemEmit.openContextMenu,
  ],
  components: {
    DialogAddCandidateToAts,
    DialogSelectAtsCandidates,
    Assignments,
    Checklist,
    ErpOrAtsLinkMenu,
    GeneralItem,
    MailClient,
    PayFlowEmployee,
    PhoneClient,
    Timeline,
    WhatsAppClient,
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      AiMessageType,
      dragOver: {} as ItemDragOverInfo,
      candidateData: undefined as Candidate | undefined,
      EmployeeItemEmit: EmployeeItemEmit,
      employeeService: new EmployeeService(),
      filteredEmail: [
        {
          mailaddress: this.employee.email,
          salutation: `${this.employee.letterSalutation},`,
        },
      ] as any,
      filteredPhoneNumbers: [
        { label: "Telefon1", value: this.employee.address.phone1 },
        { label: "Telefon2", value: this.employee.address.phone1 },
      ] as any,
      message: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      },
      pdHubService: PdHubService.getInstance(),
      resultsCandidateSearch: [] as Candidate[],
      Role: Role,
      showResultsDialog: false,
      showAddCandidateToAtsDialog: false,
      vStyle: this.$store.state.vStyle,
      //helper methods
      getAssetIconPath,
    };
  },
  computed: {
    ...mapGetters(["employeeStates", "mandants", "softwareIntegration"]),
    matchedMandant() {
      const matchedMandant = this.mandants.find(
        (mandant: Mandant) =>
          mandant.branchNumber === this.employee?.branchOfficeId
      );
      return matchedMandant || { name: "", uuid: "" };
    },
    ModalMode() {
      return ModalMode;
    },
  },
  methods: {
    async allItemUpdate(methode: AllItemUpdate): Promise<void> {
      const employeeNumber = this.employee.employeeNumber;

      try {
        switch (methode) {
          case AllItemUpdate.getPlacements:
            if (this.employee.employeeStatus === EmployeeStates.Employee) {
              await this.getPlacements();
            }
            break;
          case AllItemUpdate.updateEmployeeFromErp:
            await this.updateEmployeeFromErp();
            break;

          case AllItemUpdate.checkEmployeeStatus:
            this.checkEmployeeStatus();
            break;

          default:
            console.warn(`Methode ${methode} ist nicht implementiert.`);
            break;
        }
        return Promise.resolve();
      } catch (error) {
        console.error(
          `Fehler beim Ausführen der Methode ${methode} für Personalnummer ${employeeNumber}:`,
          error
        );
        return Promise.reject(error);
      }
    },
    collapseItem() {
      (
        this.$refs.generalItemComponent as InstanceType<typeof GeneralItem>
      ).collapseItem();
    },
    checkEmployeeStatus() {
      const employeeStatus = this.employee?.employeeStatus;
      const employeeNumber = this.employee?.employeeNumber;
      const employeeListStatus =
        this.$store.getters.getStatusByEmployeeNumber(employeeNumber);
      const employeeStatusLabel = getEmployeeStateLabel(
        parseInt(employeeListStatus)
      );
      if (employeeListStatus === null) return;
      if (employeeStatus !== employeeListStatus) {
        DialogService.confirm(
          `${this.employee?.firstName} ${this.employee?.lastName} (${employeeNumber}) hatte bei der letztenSynchronisation mit dem ERP System den Status '${employeeStatusLabel}'! Soll der Status angepasst werden?`,
          "Abbrechen",
          `auf Status '${employeeListStatus}' setzen`,
          `Status auf '${employeeStatusLabel}' ändern?`,
          VuetifyColor.error,
          VuetifyColor.success
        ).then((confirmed) => {
          if (confirmed) {
            const employee = this.employee;
            employee.employeeStatus = employeeListStatus;
            this.updateEmployee(employee);
          }
        });
      }
    },
    async checkForCandidateData(): Promise<Candidate | undefined> {
      if (!this.candidateData) {
        const candidate = await this.loadCandidateData();
        if (candidate) this.candidateData = candidate;
        return candidate;
      } else {
        return this.candidateData;
      }
    },
    async checkLinkToCandidate() {
      if (!this.employee || !this.employee._id || !this.employee?.candidateUuid)
        return;
      try {
        SpinnerService.showSpinner();
        const result = await this.employeeService.checkLinkToCandidate(
          this.employee._id
        );
        if (result.error === BackendMessage.employee.candidateNotFound) {
          ToastService.show("Suche nach Kandidaten im ATS ...");
          const employeeCandidateLinkService =
            new EmployeeCandidateLinkService();
          const result =
            await employeeCandidateLinkService.getCandidateFromAtsAndLinkToEmployee(
              this.employee
            );
          if (result?.employee) this.updateEmployee(result?.employee);
        } else if (
          result.response.message === BackendMessage.employee.noUpdatesNeeded
        ) {
          this.updateEmployee(result.response.employee);
        } else if (
          result.response.message ===
          BackendMessage.employee.employeeAndCandidateLinked
        ) {
          this.updateEmployee(result.response.employee);
          ToastService.showSuccess(
            "Kandidat und Mitarbeiter erfolgreich verlinkt"
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async dialogCheckForCandidateData() {
      if (this.employee?.candidateUuid) {
        this.checkLinkToCandidate();
      } else {
        const confirmed = await DialogService.confirm(
          "Es ist keine Kandidatendatensatz zu diesem ERP Mitarbeiter gespeichert! Soll er im ATS gesucht werden?",
          "Abbrechen",
          "Suchen",
          "Kandidat im ATS suchen?",
          VuetifyColor.abort,
          VuetifyColor.success
        );
        if (confirmed) {
          this.searchForCandidateDataInAts();
        }
      }
    },
    editContact() {
      this.$emit(EmployeeItemEmit.editContact, this.employee);
    },
    getEmployeeStatusCreds() {
      const employeeStatusColor =
        this.$store.state.company?.employeeStatusColor;
      // check all roles except employee
      const statusResult =
        this.getStatusColorByEmployeeState(employeeStatusColor);
      if (statusResult) return statusResult;

      const assignments = this.employee.assignments;
      if (!assignments || assignments.length === 0) {
        return {
          color: employeeStatusColor.noAssignment,
          blinking: false,
        };
      }

      const exceeded = moment();
      const employeeAssignmentExpiration =
        this.$store.state.company?.employeeAssignmentExpiration;

      const latestAssignment = assignments.reduce((latest, current) => {
        const currentDate = moment(current.to, "YYYY.MM.DD");
        return currentDate.isAfter(moment(latest.to, "YYYY.MM.DD"))
          ? current
          : latest;
      });

      const latestAssignmentDate = moment(latestAssignment.to, "YYYY.MM.DD");
      const daysUntilExpiration = latestAssignmentDate.diff(exceeded, "days");

      switch (true) {
        case daysUntilExpiration > employeeAssignmentExpiration?.attention:
          return {
            color: employeeStatusColor?.assignmentActive,
            blinking: false,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration >=
          employeeAssignmentExpiration?.attentionBlink:
          return {
            color: employeeStatusColor?.assignmentAttention,
            blinking: false,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration >= employeeAssignmentExpiration?.expiring:
          return {
            color: employeeStatusColor?.assignmentAttention,
            blinking: true,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration >= employeeAssignmentExpiration?.expiringBlink:
          return {
            color: employeeStatusColor?.assignmentExpiring,
            blinking: false,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration >= employeeAssignmentExpiration?.nearExpiring:
          return {
            color: employeeStatusColor?.assignmentExpiring,
            blinking: true,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration >=
          employeeAssignmentExpiration?.nearExpiringBlink:
          return {
            color: employeeStatusColor?.assignmentNearExpiring,
            blinking: false,
            assignmentExpiring: daysUntilExpiration,
          };
        case daysUntilExpiration > 0:
          return {
            color: employeeStatusColor?.assignmentNearExpiring,
            blinking: true,
            assignmentExpiring: daysUntilExpiration,
          };
        default:
          return {
            color: employeeStatusColor?.noAssignment,
            blinking: false,
          };
      }
    },
    getStatusColorByEmployeeState(employeeStatusColor: any) {
      switch (this.employee.employeeStatus) {
        case EmployeeStates.Applicant:
          return {
            color: employeeStatusColor.applicant,
            blinking: false,
          };
        case EmployeeStates.DesignatedEmployee:
          return {
            color: employeeStatusColor.designatedEmployee,
            blinking: false,
          };
        case EmployeeStates.RejectedApplicant:
          return {
            color: employeeStatusColor.rejectedApplicant,
            blinking: false,
          };
        case EmployeeStates.RetiredEmployee:
          return {
            color: employeeStatusColor.retiredEmployee,
            blinking: false,
          };
        default:
          return null;
      }
    },
    getAssignmentExpiringText() {
      const assignmentStatus = this.getEmployeeStatusCreds();
      if (
        "assignmentExpiring" in assignmentStatus &&
        assignmentStatus.assignmentExpiring < 28
      ) {
        return (
          "(Einsatzende in " + assignmentStatus.assignmentExpiring + " Tagen!)"
        );
      }
      return "";
    },
    getEmployeeState(id: number) {
      return this.employeeStates[id] || "Unbekannter Status";
    },
    async getPlacements() {
      const employee = this.employee;
      try {
        const placements = await AllErpAdapter.getEmployeePlacements(
          employee.employeeNumber,
          employee.mandants[0]
        );

        if (placements && placements.length > 0) {
          employee.assignments = placements;
          await this.updateEmployee(employee);
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Abrufen der Einsatzdaten: " + error
        );
      }
    },
    handleDragStart(event: DragEvent) {
      this.$store.commit(MutationType.clearDraggingItem);
      this.checkForCandidateData().then((candidate) => {
        if (!candidate) {
          this.dialogCheckForCandidateData();
          event.preventDefault();
          return;
        }
        this.$store.commit(MutationType.setDraggingItem, {
          type: Role.employee,
          data: {
            employeeData: this.employee,
            candidate: CandidateDataHelper.candidateToReducedCandidate(
              candidate,
              candidate.applications.length - 1
            ),
            candidateData: candidate,
          },
        });
      });
    },
    handleDragOver(event: DragEvent) {
      event.preventDefault();
      const originComponent = this.$store.state.dragOriginComponent;

      switch (originComponent) {
        case Role.demand:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "Verlinkung Anfrage - Mitarbeiter nicht möglich",
          };
          break;

        case Role.customer:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "Um ein Profil zu generieren\nbitte Mitarbeiter auf Firma ziehen",
          };
          break;

        case Role.candidate:
          this.dragOver = {
            icon: "fa-solid fa-circle-plus",
            text: "Kandidat im ERP anlegen und in Mitarbeiterspalte kopieren",
          };
          break;

        default:
          this.dragOver = {
            icon: "fa-solid fa-ban",
            text: "Leider nicht möglich",
          };
          break;
      }
    },
    async linkNewCandidateToEmployee(newOrUpdatedCandidate: Candidate) {
      if (!this.employee || !newOrUpdatedCandidate._id) return;
      const employee = this.employee;
      employee.candidateUuid = newOrUpdatedCandidate.uuid;
      employee.candidateObjectId = newOrUpdatedCandidate._id;
      await this.updateEmployee(employee);
      this.showAddCandidateToAtsDialog = false;
    },
    async loadCandidateData(): Promise<Candidate | undefined> {
      if (!this.employee.candidateObjectId) return;
      try {
        const candidate = await new CandidateService().getCandidateById(
          this.employee.candidateObjectId
        );
        if (candidate) this.candidateData = candidate;
        return candidate;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    },
    async receiveCandidateUuidFromLink(uuid: string) {
      if (!this.employee || !uuid) return;
      const employee = this.employee;
      employee.candidateUuid = uuid;
      this.updateEmployee(employee).then(() => {
        this.$nextTick().then(() => {
          this.checkLinkToCandidate();
        });
      });
    },
    async saveStatusSelection(selectedStatusIndex: string): Promise<void> {
      if (!this.employee) return;
      const employee = this.employee;
      employee.employeeStatus = parseInt(selectedStatusIndex);
      await this.updateEmployee(employee);
    },
    async searchForCandidateDataInAts() {
      if (!this.employee) return;
      try {
        SpinnerService.showSpinner();
        const employee = this.employee;
        const result = await AllAtsAdapter.getCandidateDataFromEmployee(
          employee
        );
        if (result.error === BackendMessage.employee.candidateNotFound) {
          this.showAddCandidateToAtsDialog = true;
          return;
        } else if (result && result.response && result.response.length > 0) {
          this.resultsCandidateSearch = result.response;
          this.showResultsDialog = true;
        }
      } catch (error) {
        console.error("Error searching for candidate data in ATS:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async selectCandidateSearchResult(selectedCandidate: Candidate | null) {
      this.resultsCandidateSearch = [];
      if (!this.employee._id || !selectedCandidate) return;
      selectedCandidate.employeeObjectId = this.employee._id;
      const savedCandidate = await new CandidateService().postCandidate(
        selectedCandidate
      );
      const employee = this.employee;
      employee.candidateUuid = savedCandidate.uuid;
      employee.candidateObjectId = savedCandidate._id;
      await this.updateEmployee(employee);
    },
    signatureMail() {
      const user = this.user;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    signatureWhatsApp() {
      const user = this.user;
      return `Viele Grüße\n${Salutation[user.salutation]} ${user.forename} ${
        user.lastname
      }\n${this.$store.state.company.name}`;
    },
    updateComponent() {
      this.checkForCandidateData();
      if (!this.employee.assignments || this.employee.assignments.length === 0)
        this.getPlacements();
      this.checkEmployeeStatus();
    },
    async updateEmployee(employee: Employee) {
      this.employeeService.editEmployee(employee).then(() => {
        this.$emit(EmployeeItemEmit.insertUpdatedEmployee, employee);
      });
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.employee) return;
      const employee = this.employee;
      employee.checklist = checklist;
      this.updateEmployee(employee);
    },
    async updateEmployeeFromErp() {
      if (this.employee) {
        const employee = this.employee;
        try {
          const employeeData = await AllErpAdapter.updateEmployeeFromErp(
            employee.employeeNumber,
            employee.mandants[0]
          );
          if (employeeData) {
            Object.assign(employee, employeeData);
            await this.updateEmployee(employee);
          }
        } catch (error) {
          ToastService.showError(
            "Fehler beim Abrufen der Mitarbeiterdaten aus dem ERP-System: " +
              error
          );
        }
      }
    },
    async updateErpFromEmployee() {
      if (this.employee) {
        try {
          await AllErpAdapter.updateErpFromEmployee(this.employee);
        } catch (error) {
          ToastService.showError(
            "Fehler beim Aktualisieren des Mitarbeiters im ERP-System: " + error
          );
        }
      }
    },
    updateFilteredEmail() {
      this.filteredEmail = [
        {
          mailaddress: this.employee.email,
          salutation: `${this.employee.letterSalutation},`,
        },
      ] as any;
    },
  },
});
</script>
<style scoped>
.employee-status {
  cursor: pointer;
  font-size: 0.8rem;
}
</style>
