<template>
  <div>
    <v-badge
      :color="zorstStatus().color"
      :content="zorstStatus().content"
      overlap
      offset-x="4"
      offset-y="1"
      class="mr-5"
    >
      <v-img
        class="mr-2 zorst-icon"
        :src="zorstIcon"
        alt="Zorst Icon"
        width="1.7rem"
        @click="openSoftwareIntegration()"
      ></v-img>
    </v-badge>
    <v-tooltip activator="parent" location="end">
      {{ zorstStatus().tooltip }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ToastService from "@/services/toast.service";
import { Routes } from "../../enums/routes.enum";
import { getAssetIconPath } from "../../helper/get-assets.helper";
import { ZorstMessageType } from "../../enums/zorst.enum";
export default defineComponent({
  name: "ZorstListener",
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      zorstIcon: getAssetIconPath("zorst-logo.png"),
      checkInterval: null as ReturnType<typeof setInterval> | null,
      retryCount: 0,
      maxRetries: 3,
    };
  },
  mounted() {
    window.addEventListener("message", this.handleZorstMessage);
    if (this.$store.state.zorstAlive === "") {
      this.startCheckingZorst();
    }
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleZorstMessage);
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
  methods: {
    handleZorstMessage(event: any) {
      if (event.data && event.data.type === ZorstMessageType.load) {
        if (event.data.version) {
          this.$store.state.zorstAlive = event.data.version;
        }

        this.retryCount = 0;

        if (this.checkInterval) {
          clearInterval(this.checkInterval);
          this.checkInterval = null;
        }
      }
    },
    openSoftwareIntegration() {
      this.$router.push(Routes.configApi);
    },
    startCheckingZorst() {
      if (this.$store.state.zorstAlive !== "") {
        return;
      }

      this.checkInterval = setInterval(() => {
        if (this.$store.state.zorstAlive === "") {
          this.retryCount++;
          window.postMessage(
            { type: ZorstMessageType.check, message: "Checking ZORST" },
            "*"
          );

          if (this.retryCount >= this.maxRetries && this.checkInterval) {
            ToastService.showReminder("Kein ZORST im Browser aktiviert");
            clearInterval(this.checkInterval);
            this.checkInterval = null;
          }
        }
      }, 500);
    },
    zorstStatus() {
      const installedVersion = this.$store.state.zorstAlive;
      const neededVersion = this.$store.getters.getEnv.zorstVersion;

      if (installedVersion === "") {
        return {
          color: "abort",
          content: "✘",
          tooltip: "Keine Verbindung zu ZORST",
        };
      } else if (parseFloat(installedVersion) < parseFloat(neededVersion)) {
        return {
          color: "warning",
          content: "!",
          tooltip: `ZORST-Version ${installedVersion} installiert (benötigt: ${neededVersion})`,
        };
      } else if (parseFloat(installedVersion) > parseFloat(neededVersion)) {
        return {
          color: "primary",
          content: "+",
          tooltip: `Streber: ZORST-Version ${installedVersion} ist höher als die Offizielle Version ${neededVersion}`,
        };
      } else {
        return {
          color: "success",
          content: "✔",
          tooltip: "Verbindung zu ZORST hergestellt",
        };
      }
    },
  },
});
</script>

<style scoped>
.zorst-icon {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.zorst-icon:hover {
  transform: scale(1.1);
}
</style>
