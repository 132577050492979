<template>
  <v-app
    :style="{ backgroundColor: funnelData?.design?.background || '#ffffff' }"
  >
    <v-container style="max-width: 40rem">
      <v-card
        v-if="funnelData?.design"
        :rounded="funnelData?.design.rounding"
        class="mb-5"
      >
        <v-img :src="funnelData?.design?.funnelHeader" width="100%" />
        <v-sheet
          v-if="funnelData?.design"
          height="1rem"
          class="d-flex align-center justify-start"
          :color="funnelData?.design?.primary"
        >
          <v-sheet
            height="0.7rem"
            :width="`${progressBar}%`"
            :color="funnelData?.design?.secondary"
          ></v-sheet>
        </v-sheet>
      </v-card>
      <v-card
        v-if="funnelData?.design"
        :rounded="funnelData?.design.rounding"
        :color="funnelData?.design.bgQuestionForm"
        class="pa-5"
      >
        <div v-if="isCurrentStage(FunnelStage.mainQuestions)">
          <v-card-title>{{ funnelData.funnelHeader }}</v-card-title>
          <v-row>
            <v-col
              v-for="(question, index) in funnelData.questions"
              :key="index"
              cols="6"
              md="4"
            >
              <v-card
                class="pa-4 my-2 text-center"
                :color="funnelData.design.primary || 'primary'"
                @click="handleQuestionSelection(question)"
                :style="{
                  cursor: 'pointer',
                  minHeight: '11rem',
                  maxHeight: '11rem',
                }"
              >
                <v-icon size="4rem">{{ question.icon }}</v-icon>
                <v-card-text>
                  {{ question.text }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="isCurrentStage(FunnelStage.subQuestions)">
          <v-btn variant="text" icon @click="goToMainQuestions"
            ><v-icon :color="funnelData.design.primary" size="3rem"
              >far fa-hand-point-left</v-icon
            ></v-btn
          >
          zurück
          <v-divider class="mb-2"></v-divider>
          <v-card-title>{{ selectedQuestion.header }}</v-card-title>
          <v-row>
            <v-col
              v-for="(subQuestion, index) in selectedQuestion.subQuestions"
              :key="index"
              cols="6"
              md="4"
            >
              <v-card
                class="pa-4 my-2 text-center"
                :color="funnelData.design.primary || 'primary'"
                @click="handleSubQuestionSelection(subQuestion)"
                :style="{
                  cursor: 'pointer',
                  minHeight: '11rem',
                  maxHeight: '11rem',
                }"
              >
                <v-icon size="4rem">{{ subQuestion.icon }}</v-icon>
                <v-card-text>{{ subQuestion.text }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="isCurrentStage(FunnelStage.skills)">
          <v-btn variant="text" icon @click="goToSubQuestions"
            ><v-icon :color="funnelData.design.primary" size="3rem"
              >far fa-hand-point-left</v-icon
            ></v-btn
          >
          zurück
          <v-divider class="mb-2"></v-divider>
          <v-card-title>{{ selectedSubQuestion.skillsHeader }}</v-card-title>
          <v-col cols="12">
            <div
              v-for="(skill, index) in selectedSubQuestion.skills"
              :key="index"
              class="my-2 d-flex align-center"
            >
              <v-switch
                density="compact"
                :color="funnelData.design.primary"
                v-model="selectedSkills[index]"
                :label="skill"
                @change="toggleSkill(index, skill)"
                inset
                class="large-checkbox"
              />
              <v-spacer></v-spacer>
              <v-slider
                v-if="isSkillSliderVisible(index)"
                :color="funnelData.design.primary"
                v-model="atsSkills[index].Auspraegung"
                :min="1"
                :max="3"
                step="1"
                class="ml-4"
                show-ticks
                tick-size="10"
                width="2rem"
                thumb-label="always"
              >
                <template v-slot:thumb-label="{ modelValue }">
                  {{ getSkillLevelLabel(modelValue) }}
                </template>
              </v-slider>
            </div>
          </v-col>
          <v-btn
            :color="funnelData?.design.primary || 'primary'"
            :rounded="funnelData.design.rounding || 'md'"
            @click="completeSkills"
            >Weiter</v-btn
          >
        </div>
        <div v-else-if="isCurrentStage(FunnelStage.contactForm)">
          <v-form @submit.prevent="handleSubmit" ref="form">
            <v-select
              :rounded="funnelData?.design.rounding"
              :items="salutation"
              item-title="label"
              item-value="value"
              variant="solo"
              v-model="atsApplicationObject.Mitarbeiter.SalutationCatalogId"
              :rules="[salutationRule]"
              label="Anrede"
              required
            ></v-select>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="atsApplicationObject.Mitarbeiter.Vorname"
              label="Vorname"
              :rules="[requiredRule]"
              required
            ></v-text-field>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="atsApplicationObject.Mitarbeiter.Nachname"
              label="Nachname"
              :rules="[requiredRule]"
              required
            ></v-text-field>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="
                atsApplicationObject.Mitarbeiter.Kommunikationsmittel[0].Wert
              "
              label="E-Mail"
              type="email"
              :rules="[requiredRule, emailRule]"
              required
            ></v-text-field>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="
                atsApplicationObject.Mitarbeiter.Kommunikationsmittel[1].Wert
              "
              label="Telefon"
              type="tel"
              :rules="[requiredRule, phoneRule]"
              required
            ></v-text-field>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="atsApplicationObject.Mitarbeiter.Adressen[0].Plz"
              label="Postleitzahl"
              :rules="[requiredRule, postalCodeRule]"
              required
            ></v-text-field>
            <v-text-field
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="atsApplicationObject.Mitarbeiter.Adressen[0].Ort"
              label="Ort"
              :rules="[requiredRule]"
              required
            ></v-text-field>
            <v-file-input
              :rounded="funnelData?.design.rounding"
              variant="solo"
              v-model="attachments"
              chips
              multiple
              clearable
              label="Optional: Dokumente wie Lebenslauf etc. anfügen"
            ></v-file-input>
            <v-checkbox v-model="isGDPRaccepted" :rules="[gdprRule]">
              <template v-slot:label>
                <div>
                  Ich akzeptiere die
                  <span
                    @click="open(ExternalLink.siteGDPR)"
                    style="
                      cursor: pointer;
                      font-weight: bold;
                      text-decoration: underline;
                    "
                  >
                    Datenschutzrichtlinien
                  </span>
                  und die Verarbeitung meiner Daten.
                </div>
              </template>
            </v-checkbox>

            <v-btn
              :color="funnelData?.design.primary || 'primary'"
              :rounded="funnelData.design.rounding || 'md'"
              :disabled="!formValid"
              type="submit"
              >Absenden</v-btn
            >
          </v-form>
        </div>
        <div v-else-if="isCurrentStage(FunnelStage.thankYou)">
          <v-card-title>{{ funnelData.thankYouPage.title }}</v-card-title>
          <v-card-subtitle class="mb-8">{{
            funnelData.thankYouPage.subtitle
          }}</v-card-subtitle>
          <v-list-item
            v-for="(step, index) in visibleSteps"
            :key="index"
            class="mb-5"
          >
            <div class="d-flex align-center">
              <v-icon
                size="x-large"
                :color="funnelData.design.primary"
                class="mr-3 my-1 pop-animation"
              >
                {{ step.icon }}
              </v-icon>
              <div class="text-medium-emphasis fade-in-animation">
                {{ step.text }}
              </div>
            </div>
          </v-list-item>
        </div>
        <div v-else-if="isCurrentStage(FunnelStage.noWay)">
          <v-card-title>{{ funnelData.noWayPage.title }}</v-card-title>
          <v-card-subtitle class="mb-8">{{
            funnelData.noWayPage.subtitle
          }}</v-card-subtitle>

          <v-list-item
            v-for="(step, index) in visibleSteps"
            :key="index"
            class="mb-5"
          >
            <v-slide-x-transition mode="out-in">
              <div class="d-flex align-center">
                <v-icon
                  size="x-large"
                  :color="funnelData.design.primary"
                  class="mr-3 my-1 pop-animation"
                >
                  {{ step.icon }}
                </v-icon>
                <div class="text-medium-emphasis fade-in-animation">
                  {{ step.text }}
                </div>
              </div>
            </v-slide-x-transition>
          </v-list-item>
        </div>
      </v-card>
      <div v-if="funnelData?.design">
        <v-btn
          class="my-15"
          :color="funnelData?.design.primary || 'primary'"
          :rounded="funnelData.design.rounding || 'md'"
          @click="open(ExternalLink.jobBoard)"
          block
        >
          Alle Stellenanzeigen</v-btn
        >
        <v-card-subtitle
          @click="open(ExternalLink.companySite)"
          style="text-align: right; cursor: pointer"
          >{{ funnelData.companyConfig.copyright }}</v-card-subtitle
        >
      </div>
      <v-dialog v-model="isSpinner">
        <div class="d-flex justify-center align-center">
          <v-progress-circular
            v-if="isSpinner"
            indeterminate
            :color="funnelData?.design.secondary || 'secondary'"
            size="300"
            width="30"
          >
          </v-progress-circular>
        </div>
      </v-dialog>
      <v-dialog v-model="isPopActive">
        <div class="d-flex justify-center align-center">
          <v-img
            :src="funnelData?.design?.effectImage"
            width="100%"
            max-width="20rem"
            class="pop-animation"
          />
        </div>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { JobFunnel } from "@/models/job-funnel.model";

enum FunnelStage {
  mainQuestions,
  subQuestions,
  skills,
  noWay,
  contactForm,
  thankYou,
}

enum ExternalLink {
  jobBoard,
  companySite,
  siteGDPR,
}

interface AtsRecruitSkill {
  Auspraegung: number;
  ChildEntity: {
    Art: number;
    Bezeichnung: string;
  };
}

export default defineComponent({
  name: "PublicFunnel",
  data() {
    return {
      atsApplicationObject: this.createApplicationObject(),
      atsSkills: [] as AtsRecruitSkill[],
      attachments: [] as File[],
      currentStage: FunnelStage.mainQuestions,
      funnelData: {} as JobFunnel | null,
      ExternalLink,
      FunnelStage,
      isGDPRaccepted: false,
      isSpinner: false,
      isPopActive: false,
      salutation: [
        { label: "", value: 0 },
        { label: "Herr", value: 1 },
        { label: "Frau", value: 2 },
        { label: "Divers", value: 3 },
      ],
      selectedQuestion: null as any,
      selectedSubQuestion: null as any,
      selectedSkills: [] as boolean[],
      skillLevel: ["", "⭐", "⭐⭐", "⭐⭐⭐"],
      stepIndex: 0,
      progressBar: 0,
      visibleSteps: [] as { icon: string; text: string }[],
      salutationRule: (v: any) =>
        [1, 2, 3].includes(v) || "Bitte wählen Sie eine gültige Anrede aus.",
      requiredRule: (v: any) => !!v || "Dieses Feld ist erforderlich.",
      emailRule: (v: any) =>
        /.+@.+\..+/.test(v) ||
        "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      phoneRule: (v: any) =>
        /^\+?[0-9]{10,15}$/.test(v) ||
        "Bitte geben Sie eine gültige Telefonnummer ein.",
      postalCodeRule: (v: any) =>
        /^[0-9]{5}$/.test(v) ||
        "Bitte geben Sie eine gültige Postleitzahl ein.",
      gdprRule: (v: any) =>
        !!v || "Bitte akzeptieren Sie die Datenschutzrichtlinien.",
    };
  },
  computed: {
    formValid() {
      return (
        [1, 2, 3].includes(
          this.atsApplicationObject.Mitarbeiter.SalutationCatalogId
        ) &&
        !!this.atsApplicationObject.Mitarbeiter.Vorname &&
        !!this.atsApplicationObject.Mitarbeiter.Nachname &&
        !!this.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[0].Wert &&
        !!this.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[1].Wert &&
        !!this.atsApplicationObject.Mitarbeiter.Adressen[0].Plz &&
        !!this.atsApplicationObject.Mitarbeiter.Adressen[0].Ort &&
        !!this.isGDPRaccepted &&
        /.+@.+\..+/.test(
          this.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[0].Wert
        ) &&
        /^\+?[0-9]{10,15}$/.test(
          this.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[1].Wert
        ) &&
        /^[0-9]{5}$/.test(this.atsApplicationObject.Mitarbeiter.Adressen[0].Plz)
      );
    },
  },
  async mounted() {
    const route = useRoute();
    const id = route.query.id as string;
    if (id) {
      try {
        const response = await axios.get<{ response: JobFunnel }>(
          `${this.$store.getters.getEnv.VUE_APP_BACKEND_URI}/funnel/public/${id}`
        );
        this.funnelData = response.data.response;
        document.title =
          this.funnelData?.companyConfig.title || "Jetzt bewerben!";
      } catch (error) {
        console.error("Error fetching funnel:", error);
      }
    }
  },
  methods: {
    toggleSkill(index: number, skill: string) {
      if (this.selectedSkills[index]) {
        this.atsSkills[index] = {
          Auspraegung: 2,
          ChildEntity: {
            Art: 1,
            Bezeichnung: skill,
          },
        };
      } else {
        this.atsSkills.splice(index, 1);
      }
    },
    createApplicationObject() {
      const atsApplicationObject = {
        Bewerbung: {
          Bewerbungsdatum: new Date().toISOString(),
          BeworbenAls: "",
          IstInitiativ: true,
          Mandant: {
            ObjectUuid: "",
            Bezeichnung: "",
          },
          Quelle: {
            ObjectUuid: "",
            Bezeichnung: "",
          },
        },
        Mitarbeiter: {
          Adressen: [
            {
              Ort: "",
              Plz: "",
            },
          ],
          Kommunikationsmittel: [
            {
              Beschreibung: "Email",
              Typ: 4,
              Wert: "",
            },
            {
              Beschreibung: "Mobil",
              Typ: 2,
              Wert: "",
            },
          ],
          Nachname: "",
          SalutationCatalogId: 0,
          Vorname: "",
        },
        Skills: [],
      };
      return atsApplicationObject;
    },
    completeSkills() {
      this.currentStage = FunnelStage.contactForm;
      this.progressBar = 75;
      this.triggerPopEffect();
    },
    getSkillLevelLabel(value: number): string {
      return this.skillLevel[Math.min(Math.floor(value), 3)];
    },
    goToMainQuestions() {
      this.currentStage = FunnelStage.mainQuestions;
      this.progressBar = 0;
    },
    goToSubQuestions() {
      this.currentStage = FunnelStage.subQuestions;
      this.progressBar = 25;
    },
    finalizeApplicationObjekt() {
      const atsApplicationObject = this.atsApplicationObject;
      const applicantPostcode =
        this.atsApplicationObject.Mitarbeiter.Adressen[0].Plz;

      const matchedMandant = this.funnelData?.atsRecruit.mandants.find(
        (mandant) => mandant.plzs.includes(applicantPostcode)
      );

      if (matchedMandant) {
        atsApplicationObject.Bewerbung.Mandant.ObjectUuid = matchedMandant.uuid;
        atsApplicationObject.Bewerbung.Mandant.Bezeichnung =
          matchedMandant.name;
      } else {
        atsApplicationObject.Bewerbung.Mandant.ObjectUuid =
          this.funnelData?.atsRecruit.defaultMandant.uuid;
        atsApplicationObject.Bewerbung.Mandant.Bezeichnung =
          this.funnelData?.atsRecruit.defaultMandant.name;
      }
      atsApplicationObject.Skills = this.atsSkills;

      atsApplicationObject.Bewerbung.Quelle.ObjectUuid =
        this.funnelData?.atsRecruit.sourceUuid;
      atsApplicationObject.Bewerbung.Quelle.Bezeichnung =
        this.funnelData?.atsRecruit.sourceName;
      return atsApplicationObject;
    },
    handleQuestionSelection(question: any) {
      if (question.exitsFunnel) {
        this.triggerPopEffect();
        this.currentStage = FunnelStage.noWay;
        this.revealSteps();
      } else {
        this.triggerPopEffect();
        this.selectedQuestion = question;
        this.currentStage = FunnelStage.subQuestions;
        this.progressBar = 25;
      }
    },
    handleSubQuestionSelection(subQuestion: any) {
      if (subQuestion.exitsFunnel) {
        this.currentStage = FunnelStage.noWay;
        this.triggerPopEffect();
        this.revealSteps();
      } else {
        this.atsApplicationObject.Bewerbung.BeworbenAls = subQuestion.text;
        this.selectedSubQuestion = subQuestion;
        this.currentStage = FunnelStage.skills;
        this.progressBar = 50;
        this.selectedSkills = subQuestion.skills.map(() => false);
        this.triggerPopEffect();
      }
    },
    async handleSubmit() {
      if (!this.formValid) return;
      const atsApplicationObject = this.finalizeApplicationObjekt();
      this.isSpinner = true;

      try {
        this.submitApplication(atsApplicationObject).then((response) => {
          if (response === 200) {
            this.currentStage = FunnelStage.thankYou;
            this.progressBar = 100;
            this.triggerPopEffect();
            this.revealSteps();
          } else {
            alert(response);
          }
          this.isSpinner = false;
        });
      } catch (error) {
        alert(error);
      }
    },
    isCurrentStage(stage: FunnelStage): boolean {
      return this.currentStage === stage && !!this.funnelData;
    },
    isSkillSliderVisible(index: number): boolean {
      return (
        this.selectedSkills[index] &&
        this.atsSkills[index] &&
        !!this.atsSkills[index].Auspraegung
      );
    },
    open(type: ExternalLink) {
      let url = "" as string;
      switch (type) {
        case ExternalLink.jobBoard:
          url = this.funnelData?.companyConfig.companyJobBoard ?? "";
          break;
        case ExternalLink.companySite:
          url = this.funnelData?.companyConfig.domain ?? "";
          break;
        case ExternalLink.siteGDPR:
          url = this.funnelData?.companyConfig.GDPRlink ?? "";
          break;
        default:
          console.warn("Unrecognized link type:", type);
          return;
      }
      if (url) {
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          url = `https://${url}`;
        }
        window.open(url, "_blank");
      } else {
        console.warn("No URL defined for link type:", type);
      }
    },
    revealSteps() {
      let stepsArray: string | any[] = [];
      if (
        this.currentStage === FunnelStage.noWay &&
        this.funnelData?.noWayPage?.steps
      ) {
        stepsArray = this.funnelData.noWayPage.steps;
      } else if (
        this.currentStage === FunnelStage.thankYou &&
        this.funnelData?.thankYouPage?.steps
      ) {
        stepsArray = this.funnelData.thankYouPage.steps;
      }

      if (stepsArray.length > 0 && this.stepIndex < stepsArray.length) {
        if (this.stepIndex === 0) {
          setTimeout(() => {
            this.visibleSteps.push(stepsArray[this.stepIndex]);
            this.stepIndex++;
            setTimeout(this.revealSteps, 800);
          }, 1000);
        } else {
          this.visibleSteps.push(stepsArray[this.stepIndex]);
          this.stepIndex++;
          setTimeout(this.revealSteps, 800);
        }
      }
    },
    async submitApplication(atsApplicationObject: any) {
      const apiUrl = `${this.funnelData?.atsRecruit.apiUrl}/api/public/v2/Bewerbung/Create`;
      const apiKey = this.funnelData?.atsRecruit.apiKey;
      const formData = new FormData();

      formData.append("bewerbung", JSON.stringify(atsApplicationObject));

      if (this.attachments && this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          formData.append("attachments", this.attachments[i]);
        }
      } else {
        formData.append("attachments", "");
      }
      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            "X-ApiKey": apiKey,
            "Content-Type": "multipart/form-data",
            Accept: "text/plain",
          },
        });
        return response.status;
      } catch (error: any) {
        console.error("Error submitting application:", error);
        return error.response.data.Message;
      }
    },
    triggerPopEffect() {
      this.isPopActive = true;
      setTimeout(() => {
        this.isPopActive = false;
      }, 600);
    },
  },
});
</script>

<style scoped>
.v-container {
  padding: 16px;
}
@keyframes pop {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    transform: scale(1.2);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pop-animation {
  animation: pop 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-animation {
  animation: fadeIn 0.6s ease;
}
</style>
