<template>
  <v-btn
    :disabled="!isFormComplete"
    v-bind="vStyle.btn"
    :color="testSuccessful ? 'secondary' : 'primary'"
    @click="testMailConnection"
  >
    EMail testen
  </v-btn>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { MailServer } from "../../../models/mailserver.model";
import { EmailService } from "../../../services/api/email.service";
import { ApiResponse } from "../../../models/api-response.model";
import { SpinnerService } from "../../../services/spinner.service";

export enum EmailTestEmit {
  testConfig = "testConfig",
}

export default defineComponent({
  name: "EmailTestButton",
  emits: [EmailTestEmit.testConfig],
  props: {
    modelValue: {
      type: Object as () => MailServer,
      required: true,
      default: () => ({
        server: "",
        port: "",
        from: "",
        secure: false,
        username: "",
        password: "",
      }),
    },
  },
  data() {
    return {
      testSuccessful: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    isFormComplete() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.testSuccessful = false;
      const fields: Array<keyof MailServer> = [
        "server",
        "port",
        "from",
        "username",
        "password",
      ];
      const result = fields.every((field) => {
        const value = this.modelValue[field].toString();
        return typeof value === "string" && value.trim() !== "";
      });
      return result;
    },
  },
  methods: {
    testMailConnection() {
      try {
        const emailService = new EmailService();
        SpinnerService.showSpinner();
        emailService.verifyEmail(this.modelValue).then(
          (apiResponse: ApiResponse) => {
            if (apiResponse?.response) {
              this.$emit(EmailTestEmit.testConfig, "1");
              this.testSuccessful = true;
            } else {
              this.$emit(EmailTestEmit.testConfig, apiResponse?.code);
            }
            SpinnerService.removeSpinner();
          },
          () => {
            SpinnerService.removeSpinner();
            this.$emit(EmailTestEmit.testConfig, "0");
          }
        );
      } catch (error) {
        if (error) {
          SpinnerService.removeSpinner();
          this.$emit(EmailTestEmit.testConfig, "0");
        }
      }
    },
  },
});
</script>
