<!--src/components/config/software-integration/ConfigWordPressPlugin.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-globe</v-icon>Endpunkt zu
      Wordpressplugin für Kandidaten + Stellenanzeigen
    </h2>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-bind="vStyle.input"
          v-model="localConfig.apiKeys.wpPluginBaseUrl"
          label="Base URL WordPress Plugin"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-bind="vStyle.input"
          v-model="localConfig.apiKeys.wpPlugin"
          label="API Key"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ConfigWordPressPlugin",
  components: {
    ConfigSaveButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      localConfig: cloneDeep(this.modelValue),
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue: {
      handler(newValue: CompanyConfig) {
        this.localConfig = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localConfig: {
      handler(newValue: CompanyConfig) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
