<!--src/components/config/software-integration/ConfigStatusColor.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-palette</v-icon>Farbsystem
      für Status der Kanbankarten
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(color, status) in filteredCandidateStatusColor"
          :key="status"
        >
          <v-card v-bind="vStyle.card" class="mb-4 pa-4">
            <v-card-title>{{ getReadableName(status) }}</v-card-title>
            <v-color-picker
              v-model="localConfig.candidateStatusColor[status]"
              :modes="['hex']"
              elevation="0"
            ></v-color-picker>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <ConfigSaveButton v-if="isDirty" @save="handleSubmit" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import ConfigSaveButton from "../../elements/ConfigSaveButton.vue";
import { cloneDeep } from "lodash";
type CandidateStatusColor = {
  [key: string]: string;
};

export default defineComponent({
  name: "ConfigStatusColor",
  components: {
    ConfigSaveButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      localConfig: cloneDeep(this.modelValue),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    filteredCandidateStatusColor(): CandidateStatusColor {
      const candidateStatusColor = this.localConfig
        .candidateStatusColor as CandidateStatusColor;
      return Object.keys(candidateStatusColor)
        .filter((status) => status !== "_id")
        .reduce((acc: CandidateStatusColor, status: string) => {
          acc[status] = candidateStatusColor[status];
          return acc;
        }, {});
    },
  },
  watch: {
    modelValue: {
      handler(newValue: CompanyConfig) {
        this.localConfig = cloneDeep(newValue);
        this.isDirty = false;
      },
      deep: true,
    },
    localConfig: {
      handler(newValue: CompanyConfig) {
        this.isDirty =
          JSON.stringify(newValue) !== JSON.stringify(this.modelValue);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    getReadableName(status: any) {
      const statusNames: { [key: string]: string } = {
        noneProfile: "Kein Profil mehr Offen",
        openProfile: "Profil Offen",
        interviewExternal: "Interview beim Kunden",
        trailWorkExternal: "Probearbeiten",
        jobofferExternal: "Jobangebot beim Kunden",
        jobofferAcceptedExternal: "Jobangebot Angenommen",
        interview: "Interview in der NL",
        interviewCanceled: "Interview Abgesagt",
        interviewNotShownUp: "Nicht Erschienen",
        interviewCompleted: "Interview erfolgreich",
        pool: "Pool",
        rejected: "Absage",
      };
      return statusNames[status] || status;
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
