import moment from "moment";
import { Salutation } from "../enums/salutation.model";
import { CandidateList } from "../models/candidate-list.model";
import {
  Candidate,
  CareerStep,
  CareerStepType,
} from "../models/candidate.model";
import { AnonymizedResumeObject } from "../models/profile.model";
import { ArrayUtils } from "./remove-duplicates.helper";
import {
  EmploymentType,
  EmploymentTypeSystemName,
} from "../enums/employment-types.enum";

export class CandidateDataHelper {
  static calculateAge(birthdayISO: string) {
    const birthday = moment(birthdayISO);
    const today = moment();
    const age = today.diff(birthday, "years");
    if (!birthday.isValid()) {
      console.warn(`Invalid date format: ${birthdayISO}`);
      return null;
    }

    return age;
  }
  static candidateToReducedCandidate(
    candidateData: Candidate,
    applicationsIndex: number
  ): CandidateList | null {
    try {
      const application = candidateData.applications[applicationsIndex];
      if (!application || !candidateData) return null;
      return {
        addressCity: candidateData?.addressCity,
        addressPostalCode: candidateData?.addressPostalCode,
        applicationDate: application?.applicationDate,
        applicationId: application?.applicationId,
        applicationUuid: application?.uuid,
        appliedAs: application?.appliedAs,
        atsStatus: application?.ats?.status,
        avatarUuid: candidateData?.avatar?.uuid ?? "",
        birthDate: candidateData?.birthDate,
        candidateUuid: candidateData?.uuid,
        firstName: candidateData?.firstName,
        lastName: candidateData?.lastName,
        mandants: [application?.mandantUuid],
        mobilityIcon: candidateData?.mobilityIcon ?? undefined,
        mobilityRadius: candidateData?.mobilityRadius ?? undefined,
        shiftIcon: candidateData?.shiftIcon ?? undefined,
        parentObjectid: candidateData?._id,
        status: application?.status,
        tags: candidateData?.tags,
      };
    } catch (error) {
      console.error("Error extracting candidate data:", error);
      return null;
    }
  }
  static extractLicensesAndSkillOfCandidate(data: Candidate) {
    let licences = "";
    const skills = [] as string[];
    if (data?.skills) {
      for (const skill of data?.skills) {
        if (skill?.groupDescription === "Führerscheine") {
          if (licences.length > 0) {
            licences += "<br>";
          }
          licences += skill?.description;
        } else {
          skills.push(skill?.description);
        }
      }
    }
    return { licences, skills };
  }
  static matchedApplication(
    candidate: CandidateList,
    candidateData?: Candidate
  ) {
    if (!candidateData || Object.keys(candidateData).length === 0) {
      return {
        applicationDate: "",
        appliedAs: "",
        ats: { status: "" },
        mandantUuid: "",
        operationalRadius: null,
        status: "",
      };
    }
    if (
      candidateData?.applications &&
      candidateData?.applications?.length > 0
    ) {
      const matchedApplication = candidateData?.applications?.find(
        (application) => application?.uuid === candidate?.applicationUuid
      );

      if (matchedApplication) {
        const extractedData = {
          applicationDate: matchedApplication?.applicationDate,
          appliedAs: matchedApplication?.appliedAs,
          ats: { status: matchedApplication?.ats?.status },
          mandantUuid: matchedApplication?.mandantUuid,
          operationalRadius: matchedApplication?.operationalRadius,
          status: matchedApplication?.status
            ? matchedApplication?.status
            : null,
        };

        return extractedData;
      } else {
        return {
          applicationDate: "",
          appliedAs: "",
          ats: { status: "" },
          mandantUuid: "",
          operationalRadius: null,
          status: "",
        };
      }
    } else {
      return {
        applicationDate: "",
        appliedAs: "",
        mandantUuid: "",
        operationalRadius: null,
        status: "",
      };
    }
  }

  static prepareAnonymizedResume(candidate: Candidate) {
    if (!candidate) return "";
    const salutation = Salutation[candidate.salutationCatalogId];

    const anonymizedResume = {
      tags: candidate?.tags || [],
      addressCity: candidate?.addressCity,
      addressPostalCode: candidate?.addressPostalCode,
      addressStreet: candidate?.addressStreet,
      maritalStatus: candidate?.maritalStatus ? candidate?.maritalStatus : "",
      birthDate: candidate?.birthDate,
      birthPlace: candidate?.birthPlace,
      nationality: candidate?.nationality ? candidate?.nationality : "",
      salutation: salutation,
      skills: ArrayUtils.getUniqueSkillsDescriptionsArray(candidate?.skills),
      careerSteps: candidate?.careerSteps?.map((careerStep: CareerStep) => ({
        title: careerStep?.title,
        from: careerStep?.from,
        until: careerStep?.until,
        at: careerStep?.at,
        degree: careerStep?.degree,
        type: careerStep?.type ? careerStep?.type?.description : "",
        sortOrder: careerStep?.type?.sortOrder,
        details: careerStep?.details,
      })),
    } as AnonymizedResumeObject;
    const anonymizedResumeMarkdown =
      CandidateDataHelper.sortAndFormatAnonymizedResumeToMarkdown(
        anonymizedResume
      );
    return anonymizedResumeMarkdown;
  }
  static sortAndFormatAnonymizedResumeToMarkdown(
    anonymizedResume: AnonymizedResumeObject
  ) {
    if (!anonymizedResume) return "";
    const formatDate = (date?: string) => {
      if (!date) return "";
      const d = moment(date);
      if (!d.isValid()) {
        return "";
      }
      if (d.isBefore(moment().subtract(100, "years"))) {
        return "";
      }

      return d.format("DD.MM.YYYY");
    };

    const birthDateFormatted = formatDate(anonymizedResume.birthDate);

    let age = "";
    if (birthDateFormatted) {
      const diffYears = moment().diff(
        moment(anonymizedResume.birthDate),
        "years"
      );
      if (!isNaN(diffYears)) {
        age = diffYears.toString();
      }
    }

    const careerSteps = anonymizedResume.careerSteps || [];

    // sortOrder != 1 => Education and Miscellaneous
    const educationAndMisc = careerSteps
      .filter((cs) => cs.sortOrder !== 1)
      .sort((a, b) => {
        const aVal = a.from ? moment(a.from).valueOf() : 0;
        const bVal = b.from ? moment(b.from).valueOf() : 0;
        // newest first
        return bVal - aVal;
      });

    // sortOrder == 1 => Career Steps
    const careerStepsWorkplaces = careerSteps
      .filter((cs) => cs.sortOrder === 1)
      .sort((a, b) => {
        const aVal = a.from ? moment(a.from).valueOf() : 0;
        const bVal = b.from ? moment(b.from).valueOf() : 0;
        return bVal - aVal;
      });

    let md = "# Anonymized Resume\n\n";

    md += "## Über den Kandidaten\n";

    if (anonymizedResume.salutation) {
      md += `Anrede: ${anonymizedResume.salutation}\n`;
    }
    if (age) {
      md += `Alter: ${age}\n`;
    }
    if (anonymizedResume.maritalStatus) {
      md += `Familienstand: ${anonymizedResume.maritalStatus}\n`;
    }
    if (anonymizedResume.nationality) {
      md += `Nationalität: ${anonymizedResume.nationality}\n`;
    }
    if (anonymizedResume.addressPostalCode || anonymizedResume.addressCity) {
      md += `PLZ Wohnort: ${anonymizedResume.addressPostalCode || ""} ${
        anonymizedResume.addressCity || ""
      }\n`;
    }

    md += "\n## Skills\n";
    const skills = anonymizedResume.skills || [];

    if (skills.length > 0) {
      skills.forEach((skill) => {
        md += `- ${skill}\n`;
      });
    }
    md += "\n## Ausbildung\n";
    if (educationAndMisc.length > 0) {
      educationAndMisc.forEach(
        (step: {
          title?: string;
          from?: string;
          until?: string;
          at?: string;
          type?: string;
          details?: string;
        }) => {
          const lines: string[] = [];
          if (step.title) lines.push(`### Titel: ${step.title}`);

          const fromDate = formatDate(step.from);
          const untilDate = formatDate(step.until);

          if (fromDate && untilDate) {
            lines.push(`Zeitraum: von ${fromDate} bis ${untilDate}`);
          } else if (fromDate) {
            lines.push(`Zeitraum: ab ${fromDate}`);
          } else if (untilDate) {
            lines.push(`Zeitraum: bis ${untilDate}`);
          }

          if (step.at) lines.push(`Bei: ${step.at}`);
          if (step.type) lines.push(`Art: ${step.type}`);
          if (step.details) lines.push(`Details:\n${step.details}`);

          if (lines.length) {
            md += "- " + lines.join("\n  ") + "\n";
          }
        }
      );
    }
    md += "\n## Werdegang\n";
    if (careerStepsWorkplaces.length > 0) {
      careerStepsWorkplaces.forEach(
        (step: {
          title?: string;
          from?: string;
          until?: string;
          at?: string;
          type?: string;
          details?: string;
        }) => {
          const lines: string[] = [];
          if (step.title) lines.push(`### Titel: ${step.title}`);

          const fromDate = formatDate(step.from);
          const untilDate = formatDate(step.until);

          if (fromDate && untilDate) {
            lines.push(`Zeitraum: von ${fromDate} bis ${untilDate}`);
          } else if (fromDate) {
            lines.push(`Zeitraum: ab ${fromDate}`);
          } else if (untilDate) {
            lines.push(`Zeitraum: bis ${untilDate}`);
          }

          if (step.at) lines.push(`Bei: ${step.at}`);
          if (step.type) lines.push(`Art: ${step.type}`);
          if (step.details) lines.push(`Details:\n${step.details}`);

          if (lines.length) {
            md += "- " + lines.join("\n  ") + "\n";
          }
        }
      );
    }

    return md;
  }
  static careerStepTypeObjects = [
    {
      description: "Anstellung",
      systemName: EmploymentTypeSystemName.employed,
      sortOrder: EmploymentType.employed,
    },
    {
      description: "Berufsausbildung",
      systemName: EmploymentTypeSystemName.professional_training,
      sortOrder: EmploymentType.professional_training,
    },
    {
      description: "Weiterbildung",
      systemName: EmploymentTypeSystemName.further_training,
      sortOrder: EmploymentType.further_training,
    },
    {
      description: "Studium",
      systemName: EmploymentTypeSystemName.study,
      sortOrder: EmploymentType.study,
    },
    {
      description: "Wehr-/Zivildienst",
      systemName: EmploymentTypeSystemName.public_service,
      sortOrder: EmploymentType.public_service,
    },
    {
      description: "Schulbildung",
      systemName: EmploymentTypeSystemName.schooling,
      sortOrder: EmploymentType.schooling,
    },
    {
      description: "Sonstiges",
      systemName: EmploymentTypeSystemName.miscellaneous,
      sortOrder: EmploymentType.miscellaneous,
    },
    {
      description: "Kurs",
      systemName: EmploymentTypeSystemName.course,
      sortOrder: EmploymentType.course,
    },
  ] as CareerStepType[];
}
