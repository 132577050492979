<!--src/components/disposition/CandidateColumn.vue-->
<template>
  <div
    class="column"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent
  >
    <CvDropField
      :showDropField="showDropField"
      :parentRole="Role.candidate"
      :fieldText="dropFieldText"
      :parseWithPersonalData="true"
      @parsedCandidate="receiveParsedCandidate"
      @handleDrop="handleDrop"
    />
    <DialogAddCandidateToAts
      v-if="showAddCandidateToAtsDialog"
      :showDialog="showAddCandidateToAtsDialog"
      :candidate="candidateFromCvParser"
      :candidateToSendToAts="candidateToSendToAts"
      :droppedCv="droppedCv"
      @closeDialog="showAddCandidateToAtsDialog = false"
      @candidateCreatedOrUpdated="addCandidate"
    />
    <div
      v-if="!isActive"
      :class="
        mdAndDown ? 'spacer-dispo-column-mdAndDown' : 'spacer-dispo-column'
      "
    ></div>
    <div v-if="isActive" class="ml-0 pl-0 header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <v-text-field
              v-bind="vStyle?.input"
              v-model="searchTerm"
              density="compact"
              label="Suchbegriff"
              @keydown.enter="getCandidatesBySearchTerm(searchTerm)"
            >
              <v-tooltip activator="parent" location="top"
                >Einen oder mehrere Suchbegriffe eingeben und mit Enter
                bestätigen</v-tooltip
              >
            </v-text-field>
            <v-divider class="mb-4"></v-divider>
            <v-text-field
              v-bind="vStyle?.input"
              type="date"
              label="Beworben nach"
              density="compact"
              v-model="dateAfter"
              clearable
              max-width="10rem"
            />

            <v-radio-group
              v-model="selectedStatus"
              @change="selectStatusFilter"
            >
              <v-radio
                v-for="status in statusOptionsRecruit"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              ></v-radio>
            </v-radio-group>
            <div>
              <v-switch
                label="immer ATS synchronisieren"
                color="primary"
                v-model="shouldAllwaysSyncWithAts"
              >
              </v-switch>
              <v-tooltip activator="parent" location="top left"
                >Kandidaten werden bei jedem Abruf mit dem ATS-System
                synchronisiert (langsamer)</v-tooltip
              >
            </div>
          </template>
          <template #filter-menu-right-column>
            <v-select
              v-bind="vStyle?.input"
              v-model="filterCandidatesCurrent"
              density="compact"
              :items="FilterCandidates"
              label="sortieren nach ..."
              item-title="label"
              item-value="label"
              @update:model-value="updateItemsOrder"
            >
            </v-select>
            <v-divider class="mb-4"> </v-divider>
            <v-text-field
              v-bind="vStyle?.input"
              type="date"
              label="Beworben vor"
              density="compact"
              v-model="dateBefore"
              clearable
              max-width="10rem"
            />

            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
                class="ma-0 pa-0"
                @update:model-value="updateMandantsFilter(selectedMandants)"
              ></v-checkbox>
            </div>
          </template>
        </FilterMenu>
      </div>
      <v-slider
        class="mt-4 mr-2 text-caption"
        v-model="sliderCandidateStatusValue"
        :max="4"
        step="1"
        :track-color="sliderCandidateStatusValue !== null ? 'primary' : 'grey'"
        :color="sliderCandidateStatusValue !== null ? 'primary' : 'secondary'"
        tick-size="10"
        show-ticks="always"
        @update:model-value="handleSliderChange()"
      >
        <template v-slot:tick-label="{ index }">
          <v-icon
            v-if="mdAndDown"
            :key="index"
            color="primary-darken-1"
            class="mt-3"
            >{{
              sliderCandidateStateIcons[
                index as keyof typeof sliderCandidateStateIcons
              ]
            }}</v-icon
          >
          <span v-else>{{
            sliderCandidateStatesLabels[
              index as keyof typeof sliderCandidateStatesLabels
            ]
          }}</span>
        </template>
      </v-slider>

      <v-icon>{{ isDebounceActive ? "fa-regular fa-clock" : "none" }}</v-icon>
      <v-btn icon variant="text" @click="loadCandidates(true)">
        <v-icon size="large" class="filter-icon"
          >fas fa-cloud-arrow-down</v-icon
        >
        <v-tooltip activator="parent" location="top left"
          >Kandidaten neu laden, auf neue Kandidaten im ATS prüfen und alle
          Stati vollständig mit ATS synchronisieren</v-tooltip
        >
      </v-btn>
    </div>
    <div v-if="candidateOrdered.length === 0" @drop.prevent @dragover.prevent>
      <div class="dispatcher-board-empty-column-spacer"></div>
      <v-card v-bind="vStyle.card" border="dashed">
        <v-card-title>Keine Kandidaten </v-card-title>
        <v-card-subtitle>unter diesem Filter</v-card-subtitle>
        <v-card-text v-if="!mdAndDown">
          Mitarbeiter Karte auf dieses Feld ziehen um den ATS Datensatz
          anzuzeigen oder den Mitarbeiter in das ATS zu übertragen
          <br />
          <h4>Kandidaten erstellen:</h4>
          Lebenslauf - Datei (*.docs, *.pdf, *.jpg oder *.png) auf dieses Feld
          ziehen um einen Kandidaten anzulegen
        </v-card-text>
        <v-card-text v-else>
          CV Parser auf Mobilgeräten derzeit nicht verfügbar
          <!-- TODO: Add file input for CV upload and parse CV -->
        </v-card-text>
      </v-card>
    </div>
    <v-infinite-scroll
      v-if="candidateOrdered.length > 0"
      :onLoad="loadMoreCandidates"
      :disabled="isLoadingCandidates"
      class="infinite-scroll-hidden-scrollbar pa-0"
      :class="mdAndDown ? 'mt-3' : 'ma-0'"
      :height="columnHeight - DispatcherBoardColumnHeight.iLoaderSubtract"
      :distance="DispatcherBoardColumnHeight.iLoaderDistance"
    >
      <div class="ma-0 pa-0 dispatcher-board-draggable">
        <CandidateItem
          v-for="element in candidateOrdered"
          :key="element.parentObjectid ?? element.candidateUuid"
          :ref="
            (el: any) => {
              if (el) candidateItemRefs.push(el);
            }
          "
          :candidate="element"
          :interComponentMessage="interComponentMessage"
          :isActive="isActive"
          :isFullyCollapsed="minimizeAllItems"
          :softwareIntegration="softwareIntegration"
          :lastUpdateTimeline="lastUpdateTimeline"
          :user="user"
          @loadCandidates="loadCandidates"
          @loadCandidatesFull="
            loadCandidatesFull(AllItemUpdate.checkForFullLoad)
          "
          @loadCandidatesFromAts="
            loadCandidatesFull(AllItemUpdate.loadCandidateFromAts)
          "
          @sendCandidateToAts="sendCandidateToAts"
          @toggleItemsMinimized="toggleItemsMinimized"
          @updateCandidate="updateCandidate"
          @updatePostcode="updatePostcodeFromItem"
        />
      </div>
      <template v-if="candidatesAll.length > 0" v-slot:loading>
        <v-icon class="spinner-icon">fas fa-spinner</v-icon>
        <v-card-subtitle>
          lade weitere {{ iLoader.incrementBy }} Kandidaten ...
        </v-card-subtitle>
      </template>
      <template v-slot:empty>
        <v-alert variant="tonal" closable color="secondary">
          Alle Kandidaten wurden geladen!
        </v-alert>
      </template>
    </v-infinite-scroll>
  </div>
</template>

<script lang="ts">
import { AllAtsAdapter } from "@/adapter/all-ats.adapter";
import { CandidateList } from "@/models/candidate-list.model";
import { CandidateService } from "@/services/api/candidate.service";
import { defineComponent, PropType } from "vue";
import {
  AllItemUpdate,
  FilterCandidates,
  UserConfigFilterBoard,
  UserConfigFilterType,
} from "@/enums/board-actions.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { ActionType, MutationType } from "@/enums/vuex-types.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import { ZvooveCandidateList } from "@/models/external/zvoove-candidate-list.model";
import CandidateItem from "./CandidateItem.vue";
import debounce from "lodash/debounce";
import FilterMenu from "./elements/FilterMenu.vue";
import { DispatcherBoardColumnHeight } from "../../enums/app-layout.enum";

import {
  Candidate,
  CandidateFromCvParser,
  CandidateSearchCriteria,
} from "../../models/candidate.model";
import { LinkingStatus, Role } from "../../enums/dependency.enum";
import ToastService from "../../services/toast.service";
import CvDropField from "./elements/drag-and-drop/DropField.vue";
import { EmployeeToCandidate } from "../../helper/employee-to-candidate.helper";
import { CandidateDataHelper } from "../../helper/candidate-data.helper";
import DialogAddCandidateToAts from "./elements/DialogAddCandidateToAts.vue";
import { DragEventSpec } from "../../enums/drag-event-spec.enum";
import { IdPrefix } from "../../enums/id-prefix.enum";
import { StatusResponse } from "../../enums/dialog.enum";

export default defineComponent({
  name: "CandidateColumn",
  components: {
    CandidateItem,
    CvDropField,
    DialogAddCandidateToAts,
    FilterMenu,
  },
  props: {
    columnHeight: {
      type: Number,
      default: DispatcherBoardColumnHeight.standard,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      AllItemUpdate,
      bewerbungenFiltered: [] as ZvooveCandidateList[],
      candidatesAll: [] as CandidateList[],
      candidateFromCvParser: undefined as Candidate | undefined,
      candidateOrdered: [] as CandidateList[],
      candidateService: new CandidateService(),
      candidateToSendToAts: undefined as Candidate | undefined,
      dateAfter: null,
      dateBefore: null,
      drag: false,
      dragOverTimer: null as number | null,
      droppedCv: null as File | null,
      DispatcherBoardColumnHeight,
      dropFieldText: {
        icon: "",
        title: "",
        subtitle: "",
        text: "",
      },
      FilterCandidates: getEnumOptions(FilterCandidates),
      filterCandidatesCurrent: this.getInitialFilterCandidates(),
      iLoader: {
        candidatesCountOnInit: parseInt(this.$store.getters.getEnv.itemCount),
        candidatesCount: parseInt(this.$store.getters.getEnv.itemCount),
        incrementBy: parseInt(this.$store.getters.getEnv.itemIncrement),
      },
      isDebounceActive: false,
      isLoadingCandidates: false,
      minimizeAllItems: false,
      mouse: {
        x: 0,
        y: 0,
      },
      Role,
      searchTerm: "",
      selectedMandants:
        this.user.config.dispatcherBoard.columnCandidate.filterMandants,
      selectedStatus:
        this.user.config.dispatcherBoard.columnCandidate.filterStatus[0], // [0] = single Status in this case
      shouldAllwaysSyncWithAts: false,
      showAddCandidateToAtsDialog: false,
      showDropField: false,
      showPlaceholder: false,
      sliderCandidateStatesLabels: this.$store.getters.getSliderCandidateLabels,
      sliderCandidateStateIcons: {
        0: "fas fa-phone",
        1: "fas fa-calendar-days",
        2: "fas fa-users-gear",
        3: "fas fa-magnifying-glass",
        4: "fas fa-clock",
      },
      sliderCandidateStatusValue: 0,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
      candidateItemRefs: [] as InstanceType<typeof CandidateItem>[],
    };
  },
  computed: {
    ...mapGetters({
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
  },
  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
    this.loadCandidates(false);
    this.setSliderBySelectedStatus(this.selectedStatus);
  },
  beforeUpdate() {
    this.candidateItemRefs = [];
  },
  beforeUnmount() {
    this.candidateItemRefs = [];
  },
  watch: {
    dateAfter() {
      this.loadCandidates(false);
    },
    dateBefore() {
      this.loadCandidates(false);
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.searchCandidate:
            this.loadCandidates(true, newVal.payload.searchterm);
            break;
          case InterComponentMessage.deepSearchCandidate:
            this.loadCandidatesDetailSearch(
              newVal.payload.candidateDetailSearchObject
            );
            break;
          case InterComponentMessage.preloadDispatcherBoardColumn:
            if (newVal.payload.candidateColumn) {
              this.candidateOrdered = this.candidatesAll;
            }
            break;
          /* FIXME: Enable when backend matching is fixed
          case InterComponentMessage.matchMeFromCustomerOrDemand:
            this.loadCandidatesMatching(newVal.payload);
            break;
          */
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      addPostcode: MutationType.addPostcodeCandidates,
      clearPostcodeList: MutationType.clearPostcodesCandidates,
    }),
    async addCandidate(newCandidate: Candidate) {
      try {
        SpinnerService.showSpinner();
        const candidateData = await this.candidateService.postCandidate(
          newCandidate
        );
        const candidate = CandidateDataHelper.candidateToReducedCandidate(
          candidateData,
          0
        );
        if (candidate) {
          this.candidatesAll.unshift(candidate);
          this.candidateOrdered.unshift(candidate);
          this.showAddCandidateToAtsDialog = false;
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Erstellen des Kandidatenendatensatzes"
        );
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async addDroppedEmployee(candidate: CandidateList) {
      if (candidate) {
        const existingIndex = this.candidatesAll.findIndex(
          (c) => c.parentObjectid === candidate.parentObjectid
        );
        if (existingIndex !== -1) {
          this.candidatesAll.splice(existingIndex, 1);
        }

        this.candidatesAll.unshift(candidate);

        const existingOrderedIndex = this.candidateOrdered.findIndex(
          (c) => c.parentObjectid === candidate.parentObjectid
        );
        if (existingOrderedIndex !== -1) {
          this.candidateOrdered.splice(existingOrderedIndex, 1);
        }

        this.candidateOrdered.unshift(candidate);
      } else {
        ToastService.showError(
          "Es gibt kein Kandidatendatensatz zu diesem ERP Mitarbeiter!"
        );
      }
    },
    filterCandidates(
      candidateList: CandidateList[],
      filterOption?: FilterCandidates
    ) {
      // newest on top
      candidateList = candidateList.reverse();
      // Filter-logic based on application date
      if (this.dateAfter || this.dateBefore) {
        candidateList = this.filterCandidatesByDate(candidateList);
      }

      if (filterOption) {
        switch (filterOption) {
          case FilterCandidates.lastName:
            candidateList = this.filterCandidatesLastName(candidateList);
            break;
          case FilterCandidates.applicationDate:
            candidateList = this.filterCandidatesApplicationDate(candidateList);
            break;
          case FilterCandidates.applicationId:
            candidateList = this.filterCandidatesApplicationId(candidateList);
            break;
          case FilterCandidates.appliedAs:
            candidateList = this.filterCandidatesAppliedAs(candidateList);
            break;
          case FilterCandidates.postcode:
            candidateList = this.filterCandidatesPostcode(candidateList);
            break;
          case FilterCandidates.placementStatus:
            candidateList = this.filterCandidatesLinkingStatus(candidateList);
            break;
          case FilterCandidates.openProfile:
            candidateList = this.filterCandidatesOpenProfile(candidateList);
            break;
          case FilterCandidates.trailWorkExternal:
            candidateList =
              this.filterCandidatesTrailWorkExternal(candidateList);
            break;
          case FilterCandidates.interviewExternal:
            candidateList =
              this.filterCandidatesInterviewExternal(candidateList);
            break;
          default:
            break;
        }
      }

      this.candidateOrdered = candidateList;
      return candidateList.map((item: any, index: any) => ({
        ...item,
        id: index,
      }));
    },
    filterCandidatesByDate(candidateList: CandidateList[]) {
      return candidateList.filter((candidate: CandidateList) => {
        const applicationDate = new Date(candidate.applicationDate);
        const afterDate = this.dateAfter ? new Date(this.dateAfter) : null;
        const beforeDate = this.dateBefore ? new Date(this.dateBefore) : null;
        return (
          (!afterDate || applicationDate >= afterDate) &&
          (!beforeDate || applicationDate <= beforeDate)
        );
      });
    },
    filterCandidatesLastName(candidateList: CandidateList[]) {
      return candidateList.sort(
        (a, b) => a.lastName?.localeCompare(b.lastName || "") || 0
      );
    },
    filterCandidatesApplicationDate(candidateList: CandidateList[]) {
      return candidateList.sort(
        (a, b) =>
          new Date(b.applicationDate).getTime() -
          new Date(a.applicationDate).getTime()
      );
    },
    filterCandidatesApplicationId(candidateList: CandidateList[]) {
      return candidateList.sort((a, b) => {
        const appIdA = a.applicationId || "";
        const appIdB = b.applicationId || "";

        const isNumericA = !isNaN(Number(appIdA));
        const isNumericB = !isNaN(Number(appIdB));

        if (!isNumericA && isNumericB) return -1;
        if (isNumericA && !isNumericB) return 1;

        if (!isNumericA && !isNumericB) {
          return appIdA.localeCompare(appIdB);
        }

        return Number(appIdB) - Number(appIdA);
      });
    },
    filterCandidatesAppliedAs(candidateList: CandidateList[]) {
      return candidateList.sort((a, b) => {
        if (!a.appliedAs) return -1;
        if (!b.appliedAs) return 1;
        return a.appliedAs.localeCompare(b.appliedAs);
      });
    },
    filterCandidatesPostcode(candidateList: CandidateList[]) {
      return candidateList.sort((a, b) => {
        if (!a.addressPostalCode) return -1;
        if (!b.addressPostalCode) return 1;
        const postalCodeA = parseInt(a.addressPostalCode, 10);
        const postalCodeB = parseInt(b.addressPostalCode, 10);

        if (isNaN(postalCodeA)) return -1;
        if (isNaN(postalCodeB)) return 1;

        return postalCodeA - postalCodeB;
      });
    },
    filterCandidatesLinkingStatus(candidateList: CandidateList[]) {
      const statusOrder = [
        LinkingStatus.hired,
        LinkingStatus.contractDate,
        LinkingStatus.jobofferAcceptedExternal,
        LinkingStatus.jobofferExternal,
        LinkingStatus.trailWorkSuggestionExternal,
        LinkingStatus.trailWorkExternal,
        LinkingStatus.interviewSuggestionExternal,
        LinkingStatus.interviewExternal,
        LinkingStatus.openProfile,
        LinkingStatus.rejected,
        LinkingStatus.generateProfile,
        LinkingStatus.noneProfile,
      ];

      return candidateList.sort((a, b) => {
        // if no status, sort to the end
        if (!a.linkingStatus) return -1;
        if (!b.linkingStatus) return 1;

        const indexA = statusOrder.indexOf(a.linkingStatus);
        const indexB = statusOrder.indexOf(b.linkingStatus);

        // if status not in order list, sort to the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });
    },
    filterCandidatesOpenProfile(candidateList: CandidateList[]) {
      return candidateList.filter(
        (candidate: CandidateList) =>
          candidate.linkingStatus === LinkingStatus.openProfile
      );
    },
    filterCandidatesTrailWorkExternal(candidateList: CandidateList[]) {
      return candidateList.filter(
        (candidate: CandidateList) =>
          candidate.linkingStatus === LinkingStatus.trailWorkExternal ||
          candidate.linkingStatus === LinkingStatus.trailWorkSuggestionExternal
      );
    },
    filterCandidatesInterviewExternal(candidateList: CandidateList[]) {
      return candidateList.filter(
        (candidate: CandidateList) =>
          candidate.linkingStatus === LinkingStatus.interviewExternal ||
          candidate.linkingStatus === LinkingStatus.interviewSuggestionExternal
      );
    },
    getCandidatesBySearchTerm(searchTerm: string) {
      this.loadCandidates(true, searchTerm);
    },
    getInitialFilterCandidates() {
      const initialFilterCandidates = this.user.config.dispatcherBoard
        .columnCandidate.orderDraggable[0] as FilterCandidates | undefined;
      if (
        !initialFilterCandidates ||
        initialFilterCandidates === FilterCandidates.placementStatus ||
        initialFilterCandidates === FilterCandidates.openProfile ||
        initialFilterCandidates === FilterCandidates.interviewExternal ||
        initialFilterCandidates === FilterCandidates.trailWorkExternal
      ) {
        return FilterCandidates.applicationDate;
      }
      return initialFilterCandidates;
    },
    handleDragOver() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.handleShowDropField();
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleDragLeave() {
      if (this.dragOverTimer) {
        clearTimeout(this.dragOverTimer);
      }
      this.dragOverTimer = window.setTimeout(() => {
        this.showDropField = false;
        this.dragOverTimer = null;
      }, 500);
    },
    handleShowDropField() {
      const originComponent = this.$store.state.dragOriginComponent;
      if (
        originComponent === Role.candidate ||
        originComponent === Role.demand ||
        originComponent === Role.customer ||
        originComponent === Role.jobLead ||
        originComponent === Role.jobAd
      ) {
        this.showDropField = false;
      } else if (originComponent === Role.employee) {
        this.dropFieldText = {
          icon: "fa-solid fa-user-tie",
          title: "Personalie",
          subtitle: "oben in dieser Spalte anzeigen",
          text: "Öffnet den ATS Kandidatendatensatz, der mit diesem ERP Personaldatensatz verknüpft ist",
        };
        this.showDropField = true;
      } else {
        this.dropFieldText = {
          icon: "fa-solid fa-file-circle-plus",
          title: "Lebenslauf hier ablegen",
          subtitle: "Dateiformat *.docx *.pdf *.png *.jpg möglich",
          text: "oder einen Text markieren und auf per Drag & Drop dieses Feld ziehen",
        };
        this.showDropField = true;
      }
    },
    async handleDrop(event: DragEvent) {
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          DragEventSpec.originComponent
        );
        if (originComponent === Role.employee) {
          const draggedCandidate =
            (this.$store.state.isDraggingItem.employee
              .candidate as CandidateList) ?? undefined;
          if (draggedCandidate) {
            this.addDroppedEmployee(draggedCandidate);
            this.$store.commit(MutationType.clearDraggingItem);
          } else {
            ToastService.showError(
              "Der verknüpfte Kandidatendatensatz konnte nicht geladen werden! Prüfe die Verknüpfung im Bearbeiten-Modus des ERP Personaldatensatzes."
            );
          }
          return;
        }
      }
    },
    handleSelectedMandantsChange(newValue: string) {
      this.loadCandidates(false);
      this.$store
        .dispatch("updateDispatcherBoardFilter", {
          columnName: "columnCandidate",
          property: "filterMandants",
          value: newValue,
        })
        .then(() => {
          this.userService.updateUserConfig(
            this.$store.state.company.loggedInUser.config
          );
        });
    },
    handleSliderChange() {
      this.isDebounceActive = true;
      this.updateSelectedStatus(this.sliderCandidateStatusValue);
    },
    async iLoaderInitCards() {
      if (this.candidatesAll.length > 0) {
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCountOnInit
        );
      }
    },
    async loadCandidates(syncWithAts: boolean, searchtermInput?: string) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;
      let mandantsFilter = undefined as undefined | string[];
      if (this.selectedMandants) mandantsFilter = this.selectedMandants;
      if (this.isLoadingCandidates) return;
      let selectedStatus = this.selectedStatus;
      if (selectedStatus && selectedStatus.length > 2) {
        this.clearPostcodeList();
        try {
          SpinnerService.showSpinner();
          this.isLoadingCandidates = true;
          let candidateList: CandidateList[] = [];
          const atsDeterminesStatus =
            this.softwareIntegration.atsDeterminesStatus;

          if (!syncWithAts && !this.shouldAllwaysSyncWithAts) {
            const backendCandidateList = await this.loadCandidatesFromBackend(
              selectedStatus,
              mandantsFilter,
              searchtermInput
            );

            candidateList = backendCandidateList || [];
          } else if (this.softwareIntegration.zvooveRecruit) {
            try {
              // Get candidates from ats
              let statusOrSearchTerm = selectedStatus;
              if (searchtermInput) {
                statusOrSearchTerm = searchtermInput;
              }
              const { error, atsCandidateList } =
                await AllAtsAdapter.getCandidateList(
                  statusOrSearchTerm,
                  mandantsFilter
                );

              if (atsCandidateList) {
                // Send ats candidate list to the backend
                const response =
                  await this.candidateService.updateCandidatesStatusAts(
                    atsCandidateList,
                    atsDeterminesStatus,
                    selectedStatus
                  );
                // Get backend candidate list with updated statuses
                const backendCandidateList =
                  await this.loadCandidatesFromBackend(
                    selectedStatus,
                    mandantsFilter,
                    searchtermInput
                  );
                // Compare lists and update atsStatus
                backendCandidateList?.forEach((candidate) => {
                  if (
                    !candidate.candidateUuid.startsWith(
                      IdPrefix.dispositioner
                    ) &&
                    !atsCandidateList.some(
                      (atsCandidate) =>
                        atsCandidate.applicationUuid ===
                        candidate.applicationUuid
                    ) &&
                    candidate.atsStatus !== StatusResponse.unknown
                  ) {
                    candidate.atsStatus = StatusResponse.unknown;
                  }
                });

                candidateList = backendCandidateList || [];

                // Add new ats candidates to the candidate list
                if (response && response !== "Update successful") {
                  if (Array.isArray(response)) {
                    candidateList = [...candidateList, ...response];
                  }
                }
              } else if (error) {
                // Get backend candidate list if the is no other ats
                const backendCandidateList =
                  await this.loadCandidatesFromBackend(
                    selectedStatus,
                    mandantsFilter,
                    searchtermInput
                  );
                candidateList = backendCandidateList || [];
              }
            } catch (error) {
              console.error(
                "Error fetching and synchronizing with the ATS:",
                error
              );
            }
          }
          if (this.filterCandidatesCurrent)
            this.candidatesAll = this.filterCandidates(
              candidateList,
              this.filterCandidatesCurrent
            );

          // for vuetify infinite loader
          this.candidateOrdered = this.candidatesAll.slice(
            0,
            this.iLoader.candidatesCount
          );
        } catch (error) {
          console.error("Error fetching the candidate list:", error);
        } finally {
          SpinnerService.removeSpinner();
          this.isLoadingCandidates = false;
        }
      }
    },
    async loadCandidatesFull(methode: AllItemUpdate) {
      if (this.candidateItemRefs.length === 0) {
        console.warn("Keine Candidate Items gefunden");
        return;
      }

      for (const candidateItem of this.candidateItemRefs) {
        if (candidateItem && candidateItem.allItemUpdate) {
          try {
            await candidateItem.allItemUpdate(methode);
          } catch (error) {
            console.error(
              `Fehler bei der Verarbeitung von Kunde ${candidateItem.candidate?.lastName}:`,
              error
            );
          }
        }
      }
    },
    async loadCandidatesDetailSearch(
      candidateDetailSearchObject: CandidateSearchCriteria
    ) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;

      try {
        SpinnerService.showSpinner();
        const backendCandidateList =
          await this.candidateService.getCandidateListByDeepSearch(
            candidateDetailSearchObject
          );
        const candidateList = backendCandidateList || [];
        this.candidatesAll = this.filterCandidates(
          candidateList,
          this.filterCandidatesCurrent
        );

        // for vuetify infinite loader
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCount
        );
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    /* FIXME: Enable when backend matching is fixed
    async loadCandidatesMatching(
      matchCandidatesRequest: MatchCandidatesRequest
    ) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;

      try {
        SpinnerService.showSpinner();
        const backendCandidateList =
          await this.candidateService.matchCandidates(matchCandidatesRequest);
        const candidateList = backendCandidateList || [];
        this.candidatesAll = this.filterCandidates(
          candidateList,
          this.filterCandidatesCurrent
        );

        // for vuetify infinite loader
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCount
        );
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    */
    async loadCandidatesFromBackend(
      selectedStatus: string,
      mandantsFilter: string[] | undefined,
      searchtermInput?: string
    ) {
      let backendCandidateList: CandidateList[] = [];
      try {
        SpinnerService.showSpinner();
        if (searchtermInput) {
          backendCandidateList =
            await this.candidateService.getCandidateListBySearchTerm(
              searchtermInput,
              mandantsFilter
            );
        } else {
          backendCandidateList = await this.candidateService.getCandidateList(
            selectedStatus,
            mandantsFilter
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
      return backendCandidateList;
    },
    async loadMoreCandidates({ done }: any) {
      // debounce for complete scroll
      setTimeout(async () => {
        if (this.iLoader.candidatesCount < this.candidatesAll.length) {
          this.iLoader.candidatesCount += this.iLoader.incrementBy;
          this.candidateOrdered = this.candidatesAll.slice(
            0,
            this.iLoader.candidatesCount
          );
        } else if (
          this.candidateOrdered.length === this.candidatesAll.length &&
          this.candidatesAll.length > 0
        ) {
          done("empty");
          return;
        }
        done("ok");
      }, 100);
    },
    async receiveParsedCandidate(
      parsedCv: CandidateFromCvParser,
      file: File | null
    ) {
      const candidateData = EmployeeToCandidate.parsedCvToCandidate(parsedCv);
      const newCandidate = await this.candidateService.postCandidate(
        candidateData
      );
      if (file) {
        this.droppedCv = file;
      }
      this.candidateFromCvParser = newCandidate;
      this.showAddCandidateToAtsDialog = true;
    },
    selectStatusFilter() {
      this.updateStatusFilter();
    },
    sendCandidateToAts(candidate: Candidate) {
      this.candidateToSendToAts = candidate;
      this.showAddCandidateToAtsDialog = true;
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.selectedMandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCandidate.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.loggedInMandantUuids;
      }
    },
    setSliderBySelectedStatus(status: string) {
      const sliderValue = this.$store.getters.getSliderNumberByValue(status);
      this.sliderCandidateStatusValue = sliderValue;
    },
    async toggleItemsMinimized() {
      if (this.minimizeAllItems) {
        await this.iLoaderInitCards();
      }
      this.minimizeAllItems = !this.minimizeAllItems;
    },

    updateCandidate(candidate: CandidateList) {
      const index = this.candidatesAll.findIndex(
        (c) => c.candidateUuid === candidate.candidateUuid
      );

      if (index !== -1) {
        this.candidatesAll[index] = candidate;
      }
    },

    updatePostcodeFromItem(plz: string) {
      this.addPostcode(plz);
    },
    updateSelectedStatus(sliderValue: number) {
      // Clear the current status
      this.selectedStatus = "";

      const matchingOption = this.statusOptionsRecruit.find(
        (option: any) => option.slider === sliderValue
      );
      if (matchingOption) {
        this.selectedStatus = matchingOption.value;
        this.updateStatusFilter();
      }
    },
    updateStatusFilter: debounce(function (this: any) {
      this.loadCandidates();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnCandidate,
          property: UserConfigFilterType.filterStatus,
          value: [this.selectedStatus], // Has to be in Array cause its a single status
          validArray: this.statusOptionsRecruit.map((item: any) => item.value),
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state?.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
      this.isDebounceActive = false;
      this.setSliderBySelectedStatus(this.selectedStatus);
    }, 1500),
    updateMandantsFilter: debounce(function (this: any, newValue: string[]) {
      this.loadCandidates();
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnCandidate,
          property: UserConfigFilterType.filterMandants,
          value: newValue,
          validArray: this.$store.getters.mandantUuids,
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state?.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
    }, 1500),
    updateItemsOrder: debounce(function (
      this: any,
      newValue: FilterCandidates
    ) {
      this.filterCandidates(this.candidatesAll, newValue);
      this.$store
        .dispatch(ActionType.updateDispatcherBoardFilter, {
          columnName: UserConfigFilterBoard.columnCandidate,
          property: UserConfigFilterType.orderDraggable,
          value: [newValue],
        })
        .then(() => {
          this.userService.updateUserConfigDispatcherBoard(
            this.$store.state.company?.loggedInUser?.config?.dispatcherBoard
          );
        });
    },
    1500),
  },
});
</script>

<style scoped>
/* Stil für Labels */
label {
  cursor: pointer;
  margin: 5px;
}

/* Trennen Sie die Spalten */
.filter-menu-left-column,
.filter-menu-right-column {
  padding: 10px;
}

.filter-menu-left-column {
  width: 50%;
  border-right: 1px solid var(--border-medium);
}
.filter-menu-right-column {
  width: 50%;
  border-left: 1px solid var(--border-medium);
}

.infinite-scroll-hidden-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer & Edge */
}

.infinite-scroll-hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari & Opera */
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
