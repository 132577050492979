import { VuetifyColor } from "@/plugins/vuetify";
import { DialogType } from "../enums/dialog.enum";
import { DialogState } from "../models/dialog.model";

export default class DialogService {
  private static instance: DialogService;
  private static dialogState: DialogState;

  private constructor() {
    // private constructor to prevent instantiation
  }

  static getInstance(): DialogService {
    if (!DialogService.instance) {
      DialogService.instance = new DialogService();
    }
    return DialogService.instance;
  }

  static init(dialogState: DialogState) {
    this.dialogState = dialogState;
    this.dialogState.queue = [];
    this.dialogState.activeType = DialogType.none;
    this.dialogState.props = {
      resolve: null,
    };
  }

  private static processQueue() {
    // when no dialog is active and there are items in the queue
    if (
      this.dialogState.activeType === DialogType.none &&
      this.dialogState.queue.length > 0
    ) {
      const nextDialog = this.dialogState.queue.shift();
      if (nextDialog) {
        this.dialogState.activeType = nextDialog.type;
        this.dialogState.props = nextDialog.props;
      }
    }
  }

  static completeDialog() {
    this.dialogState.activeType = DialogType.none;
    // show next dialog after a small delay
    setTimeout(() => this.processQueue(), 0);
  }

  static alert(
    message: string,
    buttonText = "OK",
    btnColor = VuetifyColor.primary
  ): Promise<void> {
    return new Promise((resolve) => {
      this.dialogState.queue.push({
        type: DialogType.alert,
        props: {
          message,
          buttonText,
          btnColor,
          resolve,
        },
      });
      this.processQueue();
    });
  }

  static confirm(
    question: string,
    btnAbortText = "Abbrechen",
    btnConfirmText = "Bestätigen",
    title = "Bitte bestätigen",
    btnAbortColor = VuetifyColor.error,
    btnConfirmColor = VuetifyColor.success
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.dialogState.queue.push({
        type: DialogType.confirm,
        props: {
          question,
          btnAbortText,
          btnConfirmText,
          title,
          btnAbortColor,
          btnConfirmColor,
          resolve,
        },
      });
      this.processQueue();
    });
  }

  static prompt(
    title: string,
    promptQuestion: string,
    inputLabelText = "Bitte Eingabe machen",
    btnAbortText = "Abbrechen",
    btnConfirmText = "Bestätigen",
    btnAbortColor = VuetifyColor.error,
    btnConfirmColor = VuetifyColor.success
  ): Promise<string> {
    return new Promise((resolve) => {
      this.dialogState.queue.push({
        type: DialogType.prompt,
        props: {
          title,
          promptQuestion,
          inputLabelText,
          btnAbortText,
          btnConfirmText,
          btnAbortColor,
          btnConfirmColor,
          resolve,
        },
      });
      this.processQueue();
    });
  }
}
