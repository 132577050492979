<template>
  <!-- Modal for changing pwd -->
  <BaseDialog
    :showDialog="showDialog"
    :showActions="true"
    @closeDialog="closeDialog()"
    :width="'var(--d-w-xs)'"
  >
    <template #title> Passwort ändern </template>
    <template #content>
      <v-card-text>
        <v-form v-model="passwordValid">
          <v-text-field
            v-bind="vStyle.input"
            v-model="password.oldPassword"
            label="Altes Passwort"
            type="password"
            :rules="[rules.required]"
            @update:model-value="$emit(PasswordDialogEmits.dirty)"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="password.newPassword"
            label="Neues Passwort"
            type="password"
            :rules="[rules.required, rules.min(8)]"
            @update:model-value="$emit(PasswordDialogEmits.dirty)"
          ></v-text-field>
          <v-text-field
            v-bind="vStyle.input"
            v-model="password.confirmPassword"
            label="Passwort bestätigen"
            type="password"
            :rules="[
              rules.required,
              () =>
                password.newPassword === password.confirmPassword ||
                'Passwörter stimmen nicht überein',
            ]"
            @update:model-value="$emit(PasswordDialogEmits.dirty)"
          ></v-text-field>
          <ErrorChip class="mb-5" :label="requestError"></ErrorChip>
        </v-form>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn color="abort" @click="closeDialog">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save">Speichern</v-btn>
    </template>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formRules } from "../../../../helper/form-rules.helper";
import { PasswordChange } from "@/models/password-change.model";
import { useDisplay } from "vuetify/lib/framework.mjs";
import ErrorChip from "@/helper/ErrorChip.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

enum PasswordDialogEmits {
  cancel = "cancel",
  changePassword = "changePassword",
  dirty = "dirty",
}

export default defineComponent({
  name: "PasswordDialog",
  components: {
    BaseDialog,
    ErrorChip,
  },
  emits: [
    PasswordDialogEmits.cancel,
    PasswordDialogEmits.changePassword,
    PasswordDialogEmits.dirty,
  ],
  props: {
    requestError: String,
    showDialog: Boolean,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      } as PasswordChange,
      PasswordDialogEmits: PasswordDialogEmits,
      passwordValid: false,
      rules: formRules,
      smAndDown,
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    save() {
      if (this.passwordValid) {
        this.$emit(PasswordDialogEmits.changePassword, this.password);
      }
    },
    closeDialog() {
      this.$emit(PasswordDialogEmits.cancel);
    },
  },
});
</script>
